import { Fragment } from 'react'

import PropTypes from 'prop-types'

import AddNewRole from '../AddNewRole'
import { ShareSelect } from '../../../../components/ShareSelect'
import DeleteSelect from '../DeleteSelect'
import { TlyntSidebar } from '../../../../components'
import { CreateSelect } from '../../../../components/CreateSelect'

const Sidebars = props => {
  const {
    // parent select list
    activeTabData,
    // changing the parent active tab data
    // child select list
    childSelectList,
    // props for refetching the PARENT select list when new PARENT select created
    handleRefetchSelectList,
    // props for create new select sidebar
    isCreateNewSelectSidebarModalVisible,
    toggleCreateNewSelectSidebarModalVisible,
    // props for edit select sidebar
    // handleRefetchChildSelectList,
    isEditSelectSidebarModalVisible,
    toggleEditSelectSidebarModalVisible,
    // props for share select
    isShareSelectSidebarModalVisible,
    toggleShareSelectSidebarModalVisible,
    // for delete select
    isDeleteSelectSidebarModalVisible,
    toggleDeleteSelectSidebarModalVisible,
    resetActiveParentSelectData,
    // for add new select
    isAddNewChildSelectSidebarModalVisible,
    toggleAddNewChildSelectSidebarModalVisible,
    refetchChildSelectList,
    // for duplicate modal
    isDuplicateSelectSidebarModalVisible,
    toggleDuplicateSelectSidebarModalVisible
  } = props

  // const { refetch: refetchChildSelectList } = useChildSelectListQuery({
  //   parentSelectId: activeTabData?.id
  // })

  return (
    <Fragment>
      {/* CREATE NEW SELECT */}
      <CreateSelect isOpen={isCreateNewSelectSidebarModalVisible} handleClose={toggleCreateNewSelectSidebarModalVisible} refetch={handleRefetchSelectList} />
      {/* EDIT SELECT */}
      <CreateSelect isEdit isOpen={isEditSelectSidebarModalVisible} handleClose={toggleEditSelectSidebarModalVisible} roleData={childSelectList} refetch={handleRefetchSelectList} />
      {/* SHARE SELECT */}
      {isShareSelectSidebarModalVisible && (
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <ShareSelect
            selectId={activeTabData?.id}
            isVisibleModal={isShareSelectSidebarModalVisible}
            toggleModal={toggleShareSelectSidebarModalVisible}
            childSelectData={childSelectList}
          />
        </TlyntSidebar>
      )}
      {/* DELETE SELECT */}
      {isDeleteSelectSidebarModalVisible && (
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <DeleteSelect
            parentActiveSelectData={activeTabData}
            handleRefetchSelectList={handleRefetchSelectList}
            isVisibleModal={isDeleteSelectSidebarModalVisible}
            toggleModal={toggleDeleteSelectSidebarModalVisible}
            resetActiveMenu={resetActiveParentSelectData}
            childSelectData={childSelectList}
          />
        </TlyntSidebar>
      )}
      {/* ADD NEW CHILD SELECT */}
      {isAddNewChildSelectSidebarModalVisible && (
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <AddNewRole
            selectId={childSelectList?.id}
            parentActiveTabData={activeTabData}
            parentId={activeTabData?.id}
            handelUpdateTabData={refetchChildSelectList}
            isVisibleModal={isAddNewChildSelectSidebarModalVisible}
            toggleModal={toggleAddNewChildSelectSidebarModalVisible}
          />
        </TlyntSidebar>
      )}
      {/* DUPLICATE SELECT */}
      <CreateSelect isDuplicate isEdit isOpen={isDuplicateSelectSidebarModalVisible} handleClose={toggleDuplicateSelectSidebarModalVisible} roleData={childSelectList} refetch={handleRefetchSelectList} />
    </Fragment>
  )
}

Sidebars.propTypes = {
  isCreateNewSelectSidebarModalVisible: PropTypes.bool,
  toggleCreateNewSelectSidebarModalVisible: PropTypes.func,
  handleRefetchSelectList: PropTypes.func,
  isEditSelectSidebarModalVisible: PropTypes.bool,
  toggleEditSelectSidebarModalVisible: PropTypes.func,
  activeTabData: PropTypes.object,
  isShareSelectSidebarModalVisible: PropTypes.bool,
  toggleShareSelectSidebarModalVisible: PropTypes.func,
  childSelectList: PropTypes.array,
  isDeleteSelectSidebarModalVisible: PropTypes.bool,
  toggleDeleteSelectSidebarModalVisible: PropTypes.func,
  resetActiveParentSelectData: PropTypes.func,
  isAddNewChildSelectSidebarModalVisible: PropTypes.bool,
  toggleAddNewChildSelectSidebarModalVisible: PropTypes.func,
  refetchChildSelectList: PropTypes.func,
  isDuplicateSelectSidebarModalVisible: PropTypes.bool,
  toggleDuplicateSelectSidebarModalVisible: PropTypes.func,
  handleRefetchChildSelectList: PropTypes.func
}

export default Sidebars
