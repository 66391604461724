import { Box } from '@mui/material'
import { ThemeProvider } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import classNames from 'classnames'

import Advertisers from './Components/Advertisers'
import BulkUpload from './Components/BulkUpload'
import Footer from './Components/Footer'
import Logo from '../Logo'
import LogoShort from '../Logo/logoShort'
import Projects from './Components/Projects'
import Selects from './Components/Selects'
import Talent from './Components/Talent'
import Users from './Components/Users'
import { Collapsable } from '../Collapsable/'

import { getMenuDetails } from '../../redux/actions/SidebarActions'
import { RenderIf } from '../../utils/Helpers'
import { setActiveSidebarRoute } from '../../redux/actions/DashBoardActions'

import { Wrapper, LogoImgDiv } from './Styles'
import style from './Sidebar.module.scss'

const OpenSidebar = {
  menuWidth: '250px',
  logoWidth: '130px',
  menuTextVisiblity: 1,
  iconMarginRight: '16px',
  iconMarginLeft: '0px',
  textAlign: 'left',
  rotateIcon: '1'
}
const CloseSidebar = {
  menuWidth: '96px',
  logoWidth: 'auto',
  menuTextVisiblity: 0,
  iconMarginRight: 'auto',
  iconMarginLeft: 'auto',
  textAlign: 'center',
  rotateIcon: '-1'
}

const ArrowIcon = ({ isActive = false, isOpen = false }: { isActive?: boolean, isOpen?: boolean }): JSX.Element => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' className={classNames(style.Sidebar__MenuArrow, isOpen ? style['Sidebar__MenuArrow--open'] : '')}>
      <path d='M10 17.1963L15 12.1963L10 7.19629L10 17.1963Z' fill={isActive ? 'var(--black)' : 'var(--black-inactive'} />
    </svg>
  )
}

const SettingsIcon = ({ isActive = false, isCollapsed = true }: { isActive?: boolean, isCollapsed?: boolean }): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={classNames(style.Sidebar__LinkIcon, isCollapsed ? style['Sidebar__LinkIcon--collapsed'] : '')}>
      <path d='M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.653V16.347L12 20.689L19.5 16.347V7.653L12 3.311ZM12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14Z' fill={isActive ? 'var(--black)' : 'var(--black-inactive'} />
    </svg>
  )
}

interface SettingsProps {
  pathname: string
  isCollapsed?: boolean
}

const Settings = ({ pathname, isCollapsed = true }: SettingsProps): JSX.Element => {
  const isActive = pathname.startsWith('/settings/')
  const [trigger, setTrigger] = useState(isActive)

  useEffect(() => {
    if (isActive) {
      setTrigger(true)
    }
  }, [isActive])

  return (
    <>
      <div className={classNames(style.Sidebar__ParentLink, isActive ? style['Sidebar__ParentLink--active'] : '')} onClick={() => { setTrigger(!trigger) }}>
        <SettingsIcon isActive={isActive} isCollapsed={isCollapsed} />
        {!isCollapsed && <>Settings<ArrowIcon isActive={isActive} isOpen={trigger} /></>}
        </div>
      {!isCollapsed && (
          <Collapsable trigger={trigger}>
            <ul className={style.Sidebar__MenuList}>
              <li className={style.Sidebar__MenuListItem}><NavLink to='/settings/account/' className={style.Sidebar__Link}>Account</NavLink></li>
              <li className={style.Sidebar__MenuListItem}><NavLink to='/settings/user/' className={style.Sidebar__Link}>Personal</NavLink></li>
            </ul>
          </Collapsable>
      )}
    </>
  )
}

export const SideBar = (): JSX.Element => {
  // hooks
  const dispatch = useDispatch()
  const location = useLocation()

  const sidebarStatus = useSelector((state: any) => state.SidebarReducer) // @TOFIX change any type once we migrate to RTK on TS
  const user = useSelector((state: any) => state.UserReducer) // @TOFIX change any type once we migrate to RTK on TS
  const { isOpen, isSubMenuOpen, width } = sidebarStatus

  // states
  const [pathname, setPathname] = useState('/talent')

  useEffect(() => {
    setPathname(location.pathname)
  }, [location])

  useEffect(() => {
    dispatch(getMenuDetails())
  }, [dispatch])

  const resetActiveSidebarRoute = (): void => {
    dispatch(setActiveSidebarRoute(''))
  }

  return (
    <ThemeProvider theme={isOpen === true ? OpenSidebar : CloseSidebar}>
      <Wrapper>
        <Box>
          <LogoImgDiv>
            {isOpen === true ? <Logo color='#286EB4' /> : <LogoShort color='#286EB4' />}
          </LogoImgDiv>
          <Box sx={{ height: 'calc(100vh - 200px)', overflowX: 'hidden', overflowY: 'auto' }}>
            <Talent pathname={pathname} resetActiveSidebarRoute={resetActiveSidebarRoute} />
            <Projects pathname={pathname} />
            <Selects pathname={pathname} isOpen={isOpen} />
            <RenderIf isTrue={user.isAdvertiser !== true}>
              <Advertisers pathname={pathname} />
            </RenderIf>
            <Users isOpen={isOpen} width={width} isSubMenuOpen={isSubMenuOpen} pathname={pathname} />
            <RenderIf isTrue={user.isAdvertiser !== true}>
              <BulkUpload pathname={pathname} />
            </RenderIf>
            { false && <Settings pathname={pathname} isCollapsed={isOpen === false} /> }
          </Box>
        </Box>
        <Footer isOpen={isOpen} isSubMenuOpen={isSubMenuOpen} />
      </Wrapper>
    </ThemeProvider>
  )
}
