import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { Colors, API } from '../../../../constants'
import { RenderIf, formatPhoneNumber, formatPSTDateWithtime, seperatePhoneAndCountryCode } from '../../../../utils/Helpers'
import { ShimmerContentBox } from '../../../../components/shimmer/Shimmer'
import { Typography } from '../../../../components'
import { useGetMediaFull } from '../../Hooks/useGetMediaById'

const AvatarView = styled.img`
  position: static;
  width: 96px;
  height: 96px;
  border-radius: 12px;
`
const UserDetail = styled.p`
  font-size: 16px;
  opacity: 0.4;
  margin: 10px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const AvatarPlaceholder = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primaryGray};
`
const UserInfoShimmer = styled(ShimmerContentBox)`
  height: ${props => props.height};
  width: ${props => props.width};
  margin-top: ${props => props.marginTop};
  border-radius: ${props => props.borderRadius ?? '0'};
  margin-bottom: ${props => props.marginBottom};
`

const DownloadButton = styled.a`
  background: transparent;
  border: 1px solid;
  height: 42px;
  border-color: ${Colors.inactiveBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-inline: 22px;
  box-shadow: 0 3px 5px -2px ${Colors.inactiveBlack};
  opacity: ${props => props.disabled ? '0.85' : '1'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${Colors.inactiveBlack};
  text-decoration: none;
  text-transform: uppercase;
`

const UserInfoData = props => {
  // props
  const { profileData, loading, isPublic } = props

  // constants
  const user = useSelector(state => state.UserReducer)
  const { getMedia } = useGetMediaFull()

  // states
  const [isCopied, setIsCopied] = useState(false)
  const [profileVideo, setProfileVideo] = useState({})

  /// /// EFFECTS
  // WHEN PROFILE INFO IS LOADED, SET PROFILE VIDEO ID
  useEffect(() => {
    if (loading === false && profileData?.videoProfile?.id) {
      setProfileVideo({ id: profileData?.videoProfile?.id })
    }
  }, [profileData, loading])

  // WHEN PROFILE VIDEO ID IS SET, FETCH MEDIA INFO
  useEffect(() => {
    async function fetchMedia () {
      const res = await getMedia({
        variables: {
          id: profileVideo.id
        }
      })

      const videoDetails = res?.data?.mediaGet

      if (videoDetails?.file !== null && videoDetails?.processing === 'complete') {
        setProfileVideo({ ...res?.data?.mediaGet, loaded: true })
      }
    }

    if (profileVideo?.id && profileVideo?.loaded !== true) {
      fetchMedia()
    }
  }, [profileVideo])

  // functions
  const TalentPersonalInformation = props => {
    const { isPublic } = props
    if (!profileData?.countryCode) {
      const { phone, countryCode } = seperatePhoneAndCountryCode(
        profileData?.phone ?? null
      )
      return (
        !isPublic && (
          <>
            <UserDetail>
              {countryCode} {phone}
            </UserDetail>
          </>
        )
      )
    } else {
      return (
        !isPublic && (
          <>
            <UserDetail>
              {profileData?.countryCode} {formatPhoneNumber(profileData?.phone)}
            </UserDetail>
          </>
        )
      )
    }
  }

  const userName = (fname, lname) => {
    return (fname || lname) && (fname ?? '') + ' ' + (lname ? user?.isAdmin ? lname : `${lname?.charAt(0).toUpperCase()}.` : '')
  }
  const clipboardCopyHandler = (id) => {
    navigator.clipboard.writeText(id)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  if (loading) {
    return (
      <>
        <UserInfoShimmer height={'96px'} width={'96px'} borderRadius={'12px'} />
        <UserInfoShimmer marginTop={'10px'} height={'25px'} borderRadius={'4px'} marginBottom={isPublic ? '10px' : '0'} />
        <RenderIf isTrue={!isPublic}>
          <UserInfoShimmer marginTop={'10px'} height={'25px'} borderRadius={'4px'} />
          <UserInfoShimmer marginTop={'10px'} height={'25px'} borderRadius={'4px'} marginBottom={'10px'} />
        </RenderIf>
      </>
    )
  }

  return (
    <>
      {profileData?.mediaProfile?.uris?.profilePic
        ? <AvatarView src={profileData?.mediaProfile?.uris.profilePic} />
        : <AvatarPlaceholder>
            <Typography fontSize='36px' fontWeight='bold' textTransform={'uppercase'}>
              {profileData?.firstname?.charAt(0) + profileData?.lastname?.charAt(0)}
            </Typography>
        </AvatarPlaceholder>}
      <Typography fontSize={20} fontWeight='bold' lineHeight='24px' textTransform='capitalize'>
        {userName(profileData?.firstname, profileData?.lastname)}
      </Typography>
      <RenderIf isTrue={profileData && user?.isAdmin}>
        <UserDetail>{profileData?.email}</UserDetail>
        {profileData?.phone && <TalentPersonalInformation isPublic={isPublic} />}
        {profileData?.countryCodeLocation ? <UserDetail>{`${profileData?.countryCodeLocation}, ${profileData?.postalCode}`}</UserDetail> : <Box mt={1} />}
        <RenderIf isTrue={!isPublic}>
          <Stack direction='column' alignItems='left'>
            <UserDetail style={{ margin: 0 }}>Talent ID:</UserDetail>
            <Stack direction='row' alignItems='center'>
              <UserDetail style={{ margin: 0 }}>{profileData?.id}</UserDetail>
              <ContentCopyIcon sx={{ fontSize: 18, marginLeft: 0.5, marginRight: 0.5, cursor: 'pointer', opacity: 0.6 }} onClick={() => clipboardCopyHandler(profileData?.id)} />
              {isCopied ? <span style={{ opacity: 0.6 }}>Copied!</span> : ''}
            </Stack>
          </Stack>

          { profileVideo.loaded && <UserDetail><DownloadButton href={`${API.cdn}/${profileVideo.file}`} download>Download Profile Video</DownloadButton></UserDetail> }
        </RenderIf>
        {profileData?.createdAt && <UserDetail>Created At: {formatPSTDateWithtime(profileData?.createdAt)}</UserDetail>}
        {profileData?.updatedAt && <UserDetail>Updated At: {formatPSTDateWithtime(profileData?.updatedAt)}</UserDetail>}
      </RenderIf>
    </>
  )
}

UserInfoData.propTypes = {
  profileData: PropTypes.object,
  loading: PropTypes.bool,
  isPublic: PropTypes.bool
}

export default UserInfoData
