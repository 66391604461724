import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type { Dispatch } from 'react'

import { DeliverablesContext } from '../../contexts/deliverables'
import { directionOptions, stillImageOptions } from '../../constants/UGCConstants'
import { GetUGCProject, ListProjectDeliverables } from '../../apis/ugc'
import type { IDeliverables, IDeliverablesActions } from '../../contexts/deliverables'

interface deliverableType {
  id: string
  mediaType: string
  projectId: string
  script: string[]
  type: string
}

const isEmpty = (data: any): boolean => {
  if (data === null || data === undefined || data[0] === '') {
    return true
  }
  if (Array.isArray(data) && data?.length === 0) {
    return true
  }
  if (typeof data === 'object' && Object.keys(data).length === 0) {
    return true
  }
  return false
}

const getPhotoSpecs = (direction: string[], id: string, photoOptions: string[]): any => {
  const value = isEmpty(direction) ? '' : direction.length > 0 && photoOptions.includes(direction[0]) ? direction[0] : 'Other'
  const additionalNote = value === 'Other' ? direction.join('\n') : direction?.length > 1 && value !== "Talent's choice" ? direction.slice(1).join('\n') : ''

  return { value, additionalNote, id }
}

const wordExistsInArray = (word: string | undefined, arrayOfArrays: Array<Array<{ id: string, value: string, label: string }>>): boolean => {
  // Use nested loops to iterate through each sub-array
  for (const array of arrayOfArrays) {
    for (const item of array) {
      // Check if the 'label' property of the item matches the given word
      if (item.label === word) {
        return true
      }
    }
  }
  return false
}

const getDirectionSpecs = (specs: string[], availableSpecs: Array<Array<{ id: string, value: string, label: string }>>): { id: null, value: string, additionalNote: string } => {
  const firstElementExistInArray = wordExistsInArray(specs?.[0], availableSpecs)
  const value = isEmpty(specs) ? '' : specs.length > 0 && firstElementExistInArray ? specs[0] : 'Other'
  const additionalNote = value === 'Other' ? specs.join('\n') : specs?.length > 1 && value !== 'No Preference' ? specs.slice(1).join('\n') : ''

  return { value, additionalNote, id: null }
}

export const useInitializeDeliverables = (): { loading: boolean } => {
  // init
  const { id, activeTab } = useParams()
  const updateDeliverableData = useContext<[IDeliverables, Dispatch<IDeliverablesActions>]>(DeliverablesContext)[1]

  // api calls
  const { data, refetch, loading: deliverablesLoading } = useQuery(ListProjectDeliverables, { variables: { projectId: id, limit: 1000, offset: 0 }, skip: id == null, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })
  const { data: projectData, loading: projectDataLoading } = useQuery(GetUGCProject, { variables: { getProjectId: id }, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })

  // constants
  const photoOptions = stillImageOptions.map((item) => item.label)

  const handleRefetchListProjectDeliverables = async (): Promise<void> => { await refetch() }

  const loadDeliverablesData = (): void => {
    updateDeliverableData({ initialState: false })

    //  ---------------- main ----------------
    const deliverableMain = data?.listProjectDeliverables.filter((item: deliverableType) => item.type === 'main')

    const deliverableMainScripts = deliverableMain?.length > 0
      ? deliverableMain.map((item: any) => ({
        isChecked: item?.script !== null && item?.script?.[0] !== '',
        value: item?.script !== null && item?.script?.[0] !== '' ? [item.script.join('\n')] : [''],
        id: item.id
      }))
      : []

    updateDeliverableData({
      main: {
        videoCount: deliverableMain?.length,
        projectDeliverable: {
          type: 'main',
          mediaType: 'video',
          script: deliverableMainScripts
        },
        deliverableSpec: {
          deliverableType: 'main',
          duration: deliverableMain[0]?.specs?.duration ?? null,
          orientation: deliverableMain[0]?.specs?.orientation ?? 'portrait',
          specs: deliverableMain[0]?.specs?.specs !== null ? [deliverableMain[0]?.specs?.specs.join('\n')] : ['']
        }
      }
    })

    // ---------------- audition ----------------
    const deliverableAudition = data?.listProjectDeliverables.filter((item: deliverableType) => item.type === 'audition')
    const isAuditionChecked = deliverableAudition.length > 0 && deliverableAudition[0]?.specs?.specs !== null && deliverableAudition[0]?.specs?.specs?.[0] !== ''

    updateDeliverableData({
      audition: {
        id: deliverableAudition[0]?.id ?? null,
        specs: [{ isChecked: isAuditionChecked, value: isAuditionChecked ? [deliverableAudition[0]?.specs?.specs.join('\n')] : [''], id: deliverableAudition[0]?.id === undefined ? null : deliverableAudition[0].id }]
      }
    })

    // ---------------- reference_links ----------------
    updateDeliverableData({
      referenceLinks: projectData?.getProject?.referenceLinks ?? []
    })

    // ---------------- intro_hook ----------------
    const deliverableIntroHooks = data?.listProjectDeliverables.filter((item: deliverableType) => item.type === 'intro_hook')

    const deliverableIntroHooksScripts = deliverableIntroHooks.map((item: any) => ({
      isChecked: item?.script !== null && item?.script?.[0] !== '',
      value: item?.script !== null && item?.script?.[0] !== '' ? [item.script.join('\n')] : [''],
      id: item.id
    })) ?? []

    updateDeliverableData({
      introHooks: {
        introHooksCount: deliverableIntroHooksScripts?.length ?? 0,
        projectDeliverable: {
          type: 'intro_hooks',
          mediaType: 'video',
          script: deliverableIntroHooksScripts
        }
      }
    })
    // ---------------- photo ----------------
    const deliverablePhoto = data?.listProjectDeliverables.filter((item: deliverableType) => item.type === 'photo')

    updateDeliverableData({
      photo: {
        photoCount: deliverablePhoto?.length,
        projectDeliverable: {
          type: 'photo',
          mediaType: 'photo',
          specs: deliverablePhoto.map((item: any) => getPhotoSpecs(item?.stillSpecs?.directions, item.id, photoOptions))
        }
      }
    })

    // ---------------- b_roll ----------------
    const deliverableBRoll = data?.listProjectDeliverables.filter((item: deliverableType) => item.type === 'b_roll')
    const isBRollChecked = deliverableBRoll.length > 0 && deliverableBRoll[0]?.specs?.specs !== null && deliverableBRoll[0]?.specs?.specs?.[0] !== ''

    updateDeliverableData({
      bRoll: {
        projectDeliverable: {
          type: 'b_roll',
          id: deliverableBRoll?.length > 0 ? deliverableBRoll?.[0]?.id : null,
          mediaType: 'video',
          specs: { isChecked: isBRollChecked, value: isBRollChecked ? [deliverableBRoll[0]?.specs?.specs.join('\n')] : [''], id: deliverableBRoll[0]?.id ?? null }
        }
      }
    })
  }

  const loadDirectionsData = (): void => {
    // ---------------- directions ----------------
    updateDeliverableData({
      directions: {
        wardrobeSpecs: getDirectionSpecs(projectData?.getProject?.wardrobeSpecs, directionOptions.wardrobe),
        locationSpecs: getDirectionSpecs(projectData?.getProject?.locationSpecs, directionOptions.location),
        lightingSpecs: getDirectionSpecs(projectData?.getProject?.lightingSpecs, directionOptions.lighting),
        framingSpecs: getDirectionSpecs(projectData?.getProject?.framingSpecs, directionOptions.framing)
      }
    })
  }

  const loadInitialData = (): void => {
    updateDeliverableData({
      initialState: true,
      main: {
        videoCount: 1,
        projectDeliverable: {
          type: 'main',
          mediaType: 'video',
          script: [{ isChecked: true, value: [''], id: null }]
        },
        deliverableSpec: {
          deliverableType: 'main',
          duration: null,
          orientation: 'portrait',
          specs: ['']
        }
      },
      audition: {
        id: null,
        specs: [{ isChecked: false, value: [''], id: null }]
      },
      introHooks: {
        introHooksCount: 3,
        projectDeliverable: {
          type: 'intro_hooks',
          mediaType: 'video',
          script: [{ isChecked: true, value: [''], id: null }, { isChecked: true, value: [''], id: null }, { isChecked: true, value: [''], id: null }]
        }
      },
      bRoll: {
        projectDeliverable: {
          id: null,
          type: 'b_roll',
          mediaType: 'video',
          specs: { isChecked: true, value: [''], id: null }
        }
      },
      photo: {
        photoCount: 3,
        projectDeliverable: {
          type: 'photo',
          mediaType: 'photo',
          specs: [{ value: '', additionalNote: '', id: null }, { value: '', additionalNote: '', id: null }, { value: '', additionalNote: '', id: null }]
        }
      },
      directions: {
        wardrobeSpecs: { value: '', additionalNote: '', id: null },
        lightingSpecs: { value: '', additionalNote: '', id: null },
        locationSpecs: { value: '', additionalNote: '', id: null },
        framingSpecs: { value: '', additionalNote: '', id: null }
      }
    })
  }

  // effects
  useEffect(() => {
    void handleRefetchListProjectDeliverables()
    if (data?.listProjectDeliverables?.length > 0 && !projectDataLoading && (id != null) && (Boolean((projectData?.getProject)))) {
      loadDeliverablesData()
    } else if (data?.listProjectDeliverables?.length === 0) {
      loadInitialData()
    }
  }, [projectDataLoading, activeTab, data?.listProjectDeliverables?.length, projectData?.getProject])

  useEffect(() => {
    if (projectData?.getProject !== undefined && !deliverablesLoading) {
      loadDirectionsData()
    }
  }, [deliverablesLoading])

  return { loading: projectDataLoading || deliverablesLoading }
}
