import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Stack, Typography } from '@mui/material'

import UGCIconBlack from '../../../../assets/images/UGCProject/UGCProjectIconBlack.svg'
import UGCIconWhite from '../../../../assets/images/UGCProject/UGCProjectIconWhite.svg'
import CastingIconWhite from '../../../../assets/images/Sidebar/ProjectWhiteIcon.svg'
import CastingIconBlack from '../../../../assets/images/Sidebar/ProjectBlackIcon.svg'

const ChipWrapper = styled.div`
  height: 68px;
  width: 100%;
  background: ${props => (props.isActive ? 'black' : 'white')};
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 10px;
`

const IconWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  background: white;
  display: grid;
  place-items: center;
  overflow: hidden;
`
const Icon = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`
const SelectInfo = styled.div`
  flex: 4 1 80%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

const EllipsesTextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const SelectChip = props => {
  const { data, onClick = () => null, isActive = false } = props
  const projectIcon = data?.project?.type === 'ugc' ? UGCIconBlack : CastingIconBlack
  const projectIconActive = data?.project?.type === 'ugc' ? UGCIconWhite : CastingIconWhite
  const brandId = data?.project?.brandId
  const brandLogo = brandId ? data?.project?.owner?.brands?.find(brand => brand.id === brandId)?.logo?.uris?.logo : data.owner?.logo?.uris?.logo ?? null
  const brandName = brandId ? data?.project?.owner?.brands?.find(brand => brand.id === brandId)?.name : data.owner?.name

  return (
    <ChipWrapper onClick={!isActive ? onClick : undefined} isActive={isActive}>
      <Box mr={1.5}>
        <IconWrapper>
          {brandLogo
            ? <Icon src={brandLogo} />
            : <Typography fontSize={22}
                fontWeight={'bold'}>
                {brandName?.charAt(0)?.toUpperCase() ??
                data?.name?.charAt(0)?.toUpperCase() ?? '-'}
              </Typography>}
        </IconWrapper>
      </Box>
      <SelectInfo>
        <EllipsesTextWrapper>
          {data?.project !== null
            ? <Stack direction='row'
                gap={0.5} alignItems='center'
                style={{ cursor: 'pointer' }}>
                <img
                  height={16}
                  src={isActive ? projectIconActive : projectIcon}
                />
              <Typography fontSize={12} whiteSpace='nowrap'
                  textOverflow='ellipsis' overflow='hidden'
                  color={isActive ? 'white' : 'black'}>
                {data.project?.name}</Typography>
            </Stack>
            : <Typography fontSize={10} whiteSpace='nowrap'
                color={isActive ? 'white' : 'black'}
                textOverflow='ellipsis' overflow='hidden'>
              {brandName}</Typography>}
          <Typography
            fontSize={16}
            fontWeight={700}
            whiteSpace='nowrap'
            color={isActive ? 'white' : 'black'}
            textOverflow='ellipsis' overflow='hidden'>
            {data?.name}
          </Typography>
        </EllipsesTextWrapper>
      </SelectInfo>
    </ChipWrapper>
  )
}

SelectChip.propTypes = {
  isActive: PropTypes.bool,
  data: PropTypes.object,
  onClick: PropTypes.func
}

export default SelectChip
