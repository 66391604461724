import PropTypes from 'prop-types'
import { useFeature } from '@growthbook/growthbook-react'
import InterestedTalentsV1 from '../InterestedTalentsV1'
import InterestedTalentsV2 from '../InterestedTalentsV2'

export default function ProjectTalent (props) {
  const { projectId, selectId, roles } = props

  const magicLinkFeatureStatus = useFeature('admin:project-role-interest')

  return magicLinkFeatureStatus.on
    ? <InterestedTalentsV2
      projectId={projectId}
      selectId={selectId}
      roles={roles}
    />
    : <InterestedTalentsV1
      roles={roles}
      projectId={projectId}
      selectId={selectId}
    />
}

ProjectTalent.propTypes = {
  projectId: PropTypes.string,
  selectId: PropTypes.string,
  roles: PropTypes.array
}
