import { useQuery } from '@apollo/client'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

function useAdvertiserListQuery (props) {
  const { offset, limit, sort } = props

  const payload = {
    offset,
    limit,
    sort: sort === 'createdAt' ? null : JSON.stringify({ name: sort })
  }

  const { data, loading, refetch, fetchMore } = useQuery(AdvertiserAPI.getAdvertiserList(), {
    variables: Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null)),
    fetchPolicy: 'no-cache'
  })

  return { data, loading, refetch, fetchMore }
}

export default useAdvertiserListQuery
