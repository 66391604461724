import styled from 'styled-components'

import Logo from '../../../../components/Logo'
import { Colors } from '../../../../constants'

const Container = styled.div`
  height: 83px;
  width: 100%;
  background: ${Colors.brightGray};
  display: grid;
  grid-template-column: 1fr;
  place-items:  center;
`

const NavBar = () => {
  return <Container>
    <Logo color={Colors.mediumSeaGreen} />
  </Container>
}

export default NavBar
