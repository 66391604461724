import { useQuery } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useOrganizationListQuery (id) {
  const { data, loading } = useQuery(ProjectAPI.getOrganizationList(), {
    variables: {
      offset: 0,
      limit: 1000,
      sort: JSON.stringify({ name: 'asc' })
    },
    skip: !!id,
    fetchPolicy: 'no-cache'
  })

  return {
    data,
    loading
  }
}

export default useOrganizationListQuery
