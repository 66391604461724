import { Fragment, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, CircularProgress, Menu, MenuItem } from '@mui/material'

import { Colors } from '../../../../constants'
import { RenderIf } from '../../../../utils/Helpers'
import { Button, Typography } from '../../../../components'
import useDuplicateProjectFeature from '../../../Projects/Hooks/useDuplicateProjectFeature'

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`
const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  bottom: 0;
  padding: 40px;
  background-color: white;
  position: sticky;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 10;
`
function Footer (props) {
  const {
    detail,
    loading,
    isCancel,
    toggleEditModal,
    updateProjectFunc,
    setToggleEditModal,
    duplicateProject,
    addProjectLoading,
    handleToggleDeleteProjectSidebar
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const openState = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const duplicateProjectFeature = useDuplicateProjectFeature()

  return (
  <FooterWrapper>
    <ButtonWrapper>
      <RenderIf isTrue={detail?.status !== 'completed' && detail?.status !== 'cancelled'}>
        <Button
          outline
          id="basic-button"
          aria-controls={openState ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openState ? 'true' : undefined}
          onClick={handleClick}>
          <Fragment>
            <Box mr={2} mt={1}>
             <MoreHorizIcon />
            </Box>
           <Typography
             fontWeight="bold"
             textTransform="upperCase"
             fontSize={15}>
              More
           </Typography>
          </Fragment>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openState}
          onClose={handleCloseMenu}>
          <MenuItem onClick={() => {
            updateProjectFunc('cancelled', true, handleCloseMenu)
          }}>Cancel Project
          <CircularProgress size={15} thickness={(isCancel && loading) ? 5 : 0} sx={{ marginLeft: 1, color: 'black' }} />
          </MenuItem>
          <RenderIf isTrue={duplicateProjectFeature}>
            <MenuItem onClick={() => duplicateProject(handleCloseMenu)}>Duplicate Project
              <CircularProgress size={15} thickness={(addProjectLoading) ? 5 : 0} sx={{ marginLeft: 1, color: 'black' }} />
            </MenuItem>
          </RenderIf>
          <MenuItem onClick={() => {
            handleCloseMenu()
            handleToggleDeleteProjectSidebar()
          }}>Delete Project</MenuItem>
        </Menu>
      </RenderIf>
    </ButtonWrapper>
    <ButtonWrapper>
        <RenderIf isTrue={duplicateProjectFeature && (detail?.status === 'completed' || detail?.status === 'cancelled')}>
          <Button
            outline
            loading={addProjectLoading}
            onClick={() => duplicateProject(handleCloseMenu)}>
            <Typography
              fontWeight="bold"
              textTransform="upperCase"
              fontSize={15}>
              Duplicate
            </Typography>
          </Button>
          <Button
            outline
            backgroundColor={Colors.spanishOrange}
            onClick={() => handleToggleDeleteProjectSidebar()}>
            <Typography
              fontSize={15}
              fontWeight="bold"
              color={Colors.spanishOrange}>
              DELETE PROJECT
            </Typography>
          </Button>
        </RenderIf>
        {detail?.status !== 'completed' && detail?.status !== 'cancelled' &&
          <Button onClick={() => setToggleEditModal(!toggleEditModal)} outline>
            <Typography
                fontWeight="bold"
                textTransform="upperCase"
                fontSize={15}>
                EDIT PROJECT
              </Typography>
          </Button>}
        <RenderIf
          isTrue={detail?.status !== 'completed' && detail?.status !== 'cancelled'}>
          <Button
            loading={!isCancel && loading}
            bg={Colors.black}
            color={Colors.white}
            border={Colors.blackOpacity}
            disabled={detail?.status === 'draft'}
            onClick={() =>
              updateProjectFunc(
                detail?.status === 'draft' ? 'active' : 'completed',
                false
              )}>
            <Typography
              color={Colors.white}
              fontWeight="bold"
              textTransform="upperCase"
              fontSize={15}>
              {detail?.status === 'draft' ? 'Publish' : 'Complete'} Project
            </Typography>
          </Button>
        </RenderIf>
    </ButtonWrapper>
  </FooterWrapper>
  )
}

Footer.propTypes = {
  detail: PropTypes.object,
  isCancel: PropTypes.bool,
  loading: PropTypes.bool,
  toggleEditModal: PropTypes.bool,
  isTikTokProject: PropTypes.bool,
  duplicateProject: PropTypes.func,
  updateProjectFunc: PropTypes.func,
  addProjectLoading: PropTypes.bool,
  setToggleEditModal: PropTypes.func,
  handleToggleDeleteProjectSidebar: PropTypes.func
}

export default Footer
