import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import DemographicAPI from '../../../services/DemographicAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'
import { convertFeetAndInchToHeight, convertValue, formatDate } from '../../../utils/Helpers'

function useCreateDemographicMutation () {
  const dispatch = useDispatch()
  const [createTalent, { loading }] = useMutation(
    DemographicAPI.talentDemographicCreate(),
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const CreateDemographicMutation = async (props, cb) => {
    const {
      id,
      values,
      copyEthnicity,
      copyRace
    } = props
    const {
      birthdate,
      bodytype,
      haircolor,
      eyecolor,
      gender,
      genderexpression,
      race,
      ethnicity,
      sexualorientation,
      hfeet,
      hinch,
      customsexualorientation,
      country
    } = values
    const modifiedRaceData = {}
    const modifiedEthnicityData = {}
    if (ethnicity?.length > 0) {
      for (const item of ethnicity) {
        const idx = copyEthnicity.findIndex((ethnicity) => ethnicity?.value === item?.value)
        modifiedEthnicityData[copyEthnicity[idx].id] = copyEthnicity[idx]?.value
      }
    }
    if (race?.length > 0) {
      for (const item of race) {
        const idx = copyRace.findIndex((race) => race?.value === item?.value)
        modifiedRaceData[copyRace[idx].id] = copyRace[idx]?.value
      }
    }
    try {
      const payload = {
        talentId: id,
        bodyType: !bodytype ? undefined : convertValue(bodytype, 'INTEGER'),
        height: !hfeet
          ? undefined
          : convertFeetAndInchToHeight({
            feet: hfeet,
            inch: hinch
          }),
        birthDate: !birthdate ? undefined : formatDate(birthdate, 'YYYY-MM-DD'),
        hairColor: !haircolor ? undefined : convertValue(haircolor, 'INTEGER'),
        eyeColor: !eyecolor ? undefined : convertValue(eyecolor, 'INTEGER'),
        gender: !gender ? undefined : convertValue(gender, 'INTEGER'),
        genderExpression: !genderexpression ? undefined : convertValue(genderexpression, 'INTEGER'),
        sexualOrientation: !sexualorientation ? undefined : convertValue(sexualorientation, 'INTEGER'),
        ethnicity: Object.values((modifiedEthnicityData)).length === 0 ? undefined : JSON.stringify(modifiedEthnicityData),
        race: Object.values((modifiedRaceData)).length === 0 ? undefined : JSON.stringify(modifiedRaceData),
        sexualOrientationOther: !customsexualorientation ? undefined : customsexualorientation ?? '',
        country: !country ? undefined : convertValue(country, 'INTEGER')
      }

      await createTalent({
        variables: Object.fromEntries(Object.entries(payload).filter(([_, v]) => v !== -1))
      })
      await cb()
      dispatch(showToast({
        message: 'Personal information updated',
        isError: false
      }))
    } catch (error) {
      dispatch(showToast({
        message: error?.message,
        isError: true
      }))
    }
  }

  return [CreateDemographicMutation, { loading }]
}

export default useCreateDemographicMutation
