import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fonts } from '../../constants'
const Text = styled.p`
  margin: 0;
  padding: 0;
`
export const Typography = props => {
  const { fontSize, color, style, letterSpacing, fontWeight, lineHeight, textTransform, children } = props
  return (
    <Text
      style={{
        fontSize,
        color,
        fontFamily: Fonts.degular,
        fontWeight,
        letterSpacing,
        lineHeight,
        textTransform,
        ...style
      }}>
      {children}
    </Text>
  )
}
Typography.propTypes = {
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  color: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  letterSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  textTransform: PropTypes.string
}
