import types from '../types/user'

export const setUserInfo = payload => ({
  type: types.SET_USER_INFO,
  payload
})

export const setUserType = payload => ({
  type: types.SET_USER_TYPE,
  payload
})

export const setUserRole = payload => ({
  type: types.SET_USER_ROLE,
  payload
})
