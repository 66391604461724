export default {
  SHOW_TOAST: 'SHOW_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
  GET__MENU_DETAILS: 'GET__MENU_DETAILS',
  ON_SELECT_TAB: 'ON_SELECT_TAB',
  ON_SELECT_PARENT_TAB: 'ON_SELECT_PARENT_TAB',
  SET_MEDIA_LIST: 'SET_MEDIA_LIST',
  SET_MEDIA_UPLOAD_IN_PROGRESS: 'SET_MEDIA_UPLOAD_IN_PROGRESS',
  CLEAR_STATE: 'CLEAR_STATE',
  SET_SIDEBAR_STATUS: 'SET_SIDEBAR_STATUS',
  SET_BULK_UPLOAD_LIST: 'SET_BULK_UPLOAD_LIST',
  SET_SUCCESS_COUNT: 'SET_SUCCESS_COUNT',
  SET_FAIL_COUNT: 'SET_FAIL_COUNT',
  SET_TKT_PROJECT_OVERVIEW: 'SET_TKT_PROJECT_OVERVIEW',
  SET_TKT_INSPIRATION: 'SET_TKT_INSPIRATION',
  SET_TKT_PRODUCT: 'SET_TKT_PRODUCT',
  SET_TKT_PAYMENT: 'SET_TKT_PAYMENT',
  RESET_TKT_PROJECT: 'RESET_TKT_PROJECT',
  TKT_IMAGES_UPLOADED_COUNT: 'TKT_IMAGES_UPLOADED_COUNT',
  SET_SELECT_SUB_MENU_TOGGLE: 'SET_SELECT_SUB_MENU_TOGGLE',
  SET_VIDEO_LIST: 'SET_VIDEO_LIST',
  SET_VIDEO_PROGRESS: 'SET_VIDEO_PROGRESS',
  APPEND_VIDEO_LIST: 'APPEND_VIDEO_LIST',
  SET_VIDEO_UPLOAD_STATUS: 'SET_VIDEO_UPLOAD_STATUS',
  SET_FINAL_VIDEO_LIST: 'SET_FINAL_VIDEO_LIST',
  SET_VIDEO_ERROR: 'SET_VIDEO_ERROR'
}
