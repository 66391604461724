import types from '../types/types'

export const setTalentMediaList = mediaList => ({
  type: types.SET_MEDIA_LIST,
  payload: mediaList
})

export const setMediaUploadInProgress = isMediaUploadInProgress => ({
  type: types.SET_MEDIA_UPLOAD_IN_PROGRESS,
  payload: isMediaUploadInProgress
})
