import { Fragment, useState, useRef } from 'react'

import * as Yup from 'yup'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapse } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'

import { Images } from '../../../../../assets'
import { Button, Typography } from '../../../../../components'
import { formatPhoneNumber, RenderIf } from '../../../../../utils/Helpers'

import MemberForm from './MemberForm'
import { Colors, Fonts } from '../../../../../constants'

import useBulkUserCreateMutation from '../../../Hooks/useBulkUserCreateMutation'
import useBulkUserUpdateMutation from '../../../Hooks/useBulkUserUpdateMutation'
import { ShimmerGeneral } from '../../../../../components/shimmer/ShimmerGeneral'
import useDeleteUserMutation from '../../../../AdvertiserDetail/Hooks/useDeleteUserMutation'
import useGetUserRoleListQuery from '../../../../AdvertiserDetail/Hooks/useGetUserRoleListQuery'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-inline: 40px;
  overflow: auto;
`

const FormikFormWrapper = styled.div`
`
// --- Member header start ---
const CollapseHeader = styled.div`
  padding: 24px 0;
  border-bottom: 1px ${Colors.inputGray}
    ${props => (!props.isLast ? 'solid' : '')};
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const RoleHeaderTileWrapper = styled.div`
 display: flex;
 align-items: center;
`
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 160%;
`
// --- Member header end ---
const MoreRoleButton = styled.span`
  font-family: ${Fonts.degular};
  font-size: 13px;
  font-weight: 700;
  color: ${Colors.cerise};
  line-height: 22px;
  letter-spacing: 0.46px;
  border: 1px solid ${Colors.cerise};
  padding: 5px 15px;
  margin-top: 20px;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const PrimaryButtonText = styled.span`
  font-family: ${Fonts.degular};
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: ${props => (props.white ? Colors.white : Colors.black)};
`

const BottomContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
// open/close/delete icons
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 40px;
  cursor: pointer;
  display: ${props => props.hide ? 'none' : ''}
`
const CollapsIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

Yup.addMethod(
  Yup.array,
  'unique',
  function (
    mapper = (a) => a,
    message = 'Error text write here'
  ) {
    return this.test('unique', message, function (list) {
      const isUnique = list?.length === new Set(list?.map(mapper)).size
      if (isUnique) {
        return true
      }
      const idx = list.findIndex((l, i) => mapper(l) !== [...new Set(list.map(mapper))][i])
      return this.createError({
        path: `members.${idx}.email`,
        message: 'Email should be unique'
      })
    })
  }
)

const MemberDetails = (props) => {
  // props
  const { handleToggle, advertiserId, reFetchData, membersData, editKey, refetchMember } = props
  // hooks
  const formikRef = useRef()
  const scrollViewRef = useRef()

  // api calls
  const [submitRole, { loading: submitRoleLoading }] = useBulkUserCreateMutation()

  // const [SubmitUpdateMember, { loading: updateMemberLoading }] = useUpdateMemberMutation()
  const [updateRole, { loading: updateMemberLoading }] = useBulkUserUpdateMutation()

  const { deleteUser } = useDeleteUserMutation()
  const { data: roleData, loading: roleLoading } = useGetUserRoleListQuery()

  // states
  const [membersCollapseData, setMembersCollapseData] = useState(membersData
    ? membersData.map((item, index) => ({ show: index === editKey, name: item?.name, isEditFocused: false }))
    : [{ show: true, isEditFocused: false }])

  // ---------------------------- functions -------------------------------
  const handleAddMoreMember = arrayHelpers => {
    setMembersCollapseData([
      ...membersCollapseData,
      {
        show: true,
        name: `Member ${membersCollapseData.length + 1}`,
        isEditFocused: false
      }
    ])
    arrayHelpers.push({
      name: `Member ${membersCollapseData?.length + 1}`,
      firstname: '',
      lastname: '',
      email: '',
      title: [],
      countryCode: 0,
      ownerId: advertiserId,
      phone: ''
    })
  }

  const handleSubmit = async data => {
    if (membersData?.length) {
      const withIdList = data.members.filter((role) => role.id)
      const withoutIdList = data.members.filter((role) => !role?.id)

      const withIdData = {
        members: withIdList
      }
      const withoutIdData = {
        members: withoutIdList
      }

      const toggleModal = () => {
        reFetchData()
        handleToggle()
        refetchMember()
      }

      await updateRole({
        data: withIdData, toggleModal: withoutIdData.members.length ? () => null : toggleModal
      })
      if (withoutIdData.members.length) {
        submitRole({
          data: withoutIdData,
          toggleModal
        })
      }
    } else {
      submitRole({
        data,
        toggleModal: () => {
          handleToggle()
          reFetchData()
          refetchMember()
        }
      })
    }
  }

  const handleDeleteMember = (arrayHelpers, member, index) => {
    const remainingItems = membersCollapseData.filter((item, idx) => { return idx !== index })
    setMembersCollapseData([...remainingItems])
    arrayHelpers.remove(index)
    if (membersData && member?.id) {
      deleteUser(member?.id, () => null, () => console.log('deletion failed'))
    }
  }
  const handleErrorCollapsableHeader = (formikData) => {
    if (formikData.errors.members?.length > 0) {
      const dirtyRoleIndexArray = formikData.errors.members.map((item, index) => item ? { dirtyArrayIndex: index } : undefined)

      for (const item of dirtyRoleIndexArray) {
        if (item) {
          membersCollapseData[item.dirtyArrayIndex].show = true
          setMembersCollapseData(membersCollapseData)
          return
        }
      }
    }
  }

  // -------------------- constants ----------------------------
  const defaultValues = membersData?.map(member => ({
    id: member.id,
    name: member?.firstname + ' ' + member?.lastname,
    firstname: member?.firstname,
    lastname: member?.lastname,
    email: member?.email,
    title: member?.roles,
    countryCode: 0,
    ownerId: advertiserId,
    phone: member?.phone ? formatPhoneNumber(member?.phone) : ''
  })) ?? {
    name: 'Member 1',
    firstname: '',
    lastname: '',
    email: '',
    title: [],
    countryCode: 0,
    ownerId: advertiserId,
    phone: ''
  }

  // ---------------------- components ----------------------------
  const Loader = () => {
    return (
      <div style={{ paddingInline: 40 }} >
      <ShimmerGeneral style={{ marginBottom: 15 }} />
      <ShimmerGeneral style={{ marginBottom: 15 }} />
      <ShimmerGeneral />
     </div>
    )
  }

  return (
    <Fragment>
      <RenderIf isTrue={roleLoading}>
        <Loader />
      </RenderIf>
      <RenderIf isTrue={roleData}>
        <Wrapper ref={scrollViewRef}>
          <Formik
            enableReinitialize
            initialValues={{
              members: membersData ? defaultValues : [defaultValues]
            }}
            validationSchema={ Yup.object().shape({
              members: Yup.array()
                .of(Yup.object().shape({
                  firstname: Yup.string().trim()
                    .min(1, 'First name should be atleast 1 character long')
                    .max(250, 'First name should be atmost 250 character long')
                    .required('First name is required'),
                  lastname: Yup.string().trim()
                    .min(1, 'Last name should be atleast 1 character long')
                    .max(250, 'Last name should be atmost 250 character long')
                    .required('Last name is required'),
                  email: Yup.string()
                    .email('Invalid email')
                    .max(255)
                    .required('Email is required'),
                  title: Yup.array().of(Yup.string()).required('Title is required'),
                  phone: Yup.string().min(8, 'Phone number must be atleast 7 character long')
                    .max(12, 'Phone number must be atmost 10 character long')
                    .nullable()
                })).unique(s => s.email)
            }) }
            innerRef={formikRef}
            onSubmit={handleSubmit}>
            {formik => (
              <Form style={{ width: '100%' }}>
                <FieldArray
                  name="members"
                  render={arrayHelpers => (
                    <Fragment>
                      {formik.values.members &&
                        formik.values.members.length > 0 &&
                        formik.values.members.map((role, index) => (
                          <Fragment key={index}>
                            <CollapseHeader>
                              <RoleHeaderTileWrapper>
                                <HeaderTitle>
                                  {formik.values.members[index]?.name}
                                </HeaderTitle>
                              </RoleHeaderTileWrapper>
                              <IconWrapper>
                                <DeleteIcon hide={formik.values.members === 1}
                                onClick={ () => handleDeleteMember(arrayHelpers, formik.values.members[index], index)} src={Images.delete} />
                                <CollapsIcon src={membersCollapseData[index]?.show ? Images.minusCollaps : Images.plusCollaps}
                                  onClick={() => {
                                    membersCollapseData[index].show = !membersCollapseData[index].show
                                    setMembersCollapseData([...membersCollapseData])
                                  }} />
                              </IconWrapper>
                            </CollapseHeader>
                            <Collapse in={membersCollapseData[index]?.show}>
                              <FormikFormWrapper key={index}>
                                <MemberForm index={index} roleData={roleData?.userRoleList} />
                              </FormikFormWrapper>
                            </Collapse>
                          </Fragment>
                        ))}
                      <ButtonWrapper>
                        <MoreRoleButton onClick={() => handleAddMoreMember(arrayHelpers)} backgroundColor={Colors.cerise} outline>
                          <Typography color={Colors.cerise} fontWeight='bold'>+ ADD ANOTHER MEMBER</Typography>
                        </MoreRoleButton>
                      </ButtonWrapper>
                    </Fragment>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
        <BottomContainer>
          <Button
            minWidth="80px"
            backgroundColor={Colors.white}
            onClick={handleToggle}>
            <Typography fontSize='15px' fontWeight='bold' lineHeight='26px' letterSpacing='0.46px'>CANCEL</Typography>
          </Button>
          <Button
            style={{ marginRight: 40 }}
            loading={submitRoleLoading || updateMemberLoading}
            onClick={() => {
              handleErrorCollapsableHeader(formikRef.current)
              formikRef?.current?.submitForm()
            }}>
            <PrimaryButtonText white>SUBMIT</PrimaryButtonText>
          </Button>
        </BottomContainer>
        </RenderIf>
    </Fragment>
  )
}

MemberDetails.propTypes = {
  handleToggle: PropTypes.func,
  advertiserId: PropTypes.string,
  reFetchData: PropTypes.func,
  membersData: PropTypes.array,
  editKey: PropTypes.number,
  refetchMember: PropTypes.func,
  setIsRefetch: PropTypes.func
}

export default MemberDetails
