// TODO: Remove styled components and convert code to TS
import {useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from '../../constants'
import { ShimmerContentBox } from '../shimmer/Shimmer'
import { showToast } from '../../redux/actions/DashBoardActions'
import { SpinnerSmall } from '../Progress'
import { Typography } from '../Typography'
import { AddTalentSubMenu } from '../AddTalentToSelectSubMenu'
import SelectAPI from '../../services/Select/SelectAPI'
import style from './AddTalentToSelects.module.scss'


const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  align-items: center;
  width: 100%;
  height: 50px;
`

const MenuWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0 30px 20px 40px;
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: ${Colors.white};
    margin-left: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.lightSilver};
    border: 4px solid white;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px ${Colors.white};
  }
  ::-webkit-scrollbar-corner {
    background: ${Colors.white};
  }
`

const Shimmer = styled(ShimmerContentBox)`
  height: 45px;
  margin-bottom: 10px;
`

const loadingStatus = {
  done: 1,
  loading: 2,
  limited: 3,
  error: 4
}

export const AddTlyntToSelects = (props) => {
  // init
  const { gridList, isVisibleModal, toggleModal, setSelectionMode, toggleCreateNewSelects, resetSelection, selectListQuery } = props
  const dispatch = useDispatch()

  // hooks
  const scrolldivRef = useRef(null)
  const { data, loading, fetchMore } = selectListQuery
  const [UpdateSelect, { loading: updateSelectLoading }] = useMutation(SelectAPI.updateSelect(), { notifyOnNetworkStatusChange: true })

  // states
  const selectListRef = useRef(null)
  const [selectList, setSelectList] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [selectedSubSelectCount, setSelectedSubSelectCount] = useState(0)
  const [loadingMoreStatus, setLoadingMoreStatus] = useState(loadingStatus.done)
  const [updateSelectResponseCount, setUpdateSelectResponseCount] = useState(null)


  // manupulating states via API
  useEffect(() => {
    if (!loading) {
      const modifiedSelectList = data?.listSelects.map(item => {
        return {
          ...item,
          isSelected: !!selectList.find((select) => select.id === item.id)?.isSelected,
          subSelect: selectList.find((select) => select.id === item.id)?.subSelect?.length > 0 ? selectList.find((select) => select.id === item.id)?.subSelect : []
        }
      })
      if (modifiedSelectList?.length > 0) {
        setSelectList(modifiedSelectList)
      }
    }
  }, [loading, data])

  useEffect(() => {
    if (updateSelectResponseCount === selectedSubSelectCount) {
      toggleModal(false)

      if (typeof setSelectionMode === 'function') {
        setSelectionMode(false)
      }

      if (typeof resetSelection === 'function') {
        resetSelection()
      }
      dispatch(showToast({ message: "You've successfully added talent to selects." }))
    }
  }, [updateSelectResponseCount, selectedSubSelectCount])

  // toggling disabled button
  useEffect(() => {
    const statusOfSubSelect = []
    selectList.forEach(item => {
      if (item?.subSelect) {
        item.subSelect.forEach(subSelect => {
          statusOfSubSelect.push(subSelect.isSelected)
        })
      }
    })
    if (statusOfSubSelect.includes(true)) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [selectList])

  const handleSubmit = () => handelAddTelentsIntoSelects()

  const onScroll = () => {
    if (selectListRef.current && loadingMoreStatus === loadingStatus.done) {
      const { scrollTop, scrollHeight, clientHeight } = selectListRef.current
      if (parseInt(scrollTop + clientHeight) === parseInt(scrollHeight)) {
        fetchMoreSelects()
      }
    }
  }

  const fetchMoreSelects = async () => {
    try {
      setLoadingMoreStatus(loadingStatus.loading)
      const { data: newData } = await fetchMore({ variables: { offset: selectList.length + 1 } })

      if (newData && newData.listSelects.length > 0) {
        setLoadingMoreStatus(loadingStatus.done)
        setSelectList([...selectList, ...newData.listSelects])

      } else {
        setLoadingMoreStatus(loadingStatus.limited)
      }
    } catch (error) {
      setLoadingMoreStatus(loadingStatus.error)
    }
  }

  const handelToggelSubList = index => {
    selectList[index].isSelected = !selectList[index].isSelected
    setSelectList([...selectList])
  }

  const createNewEntryForSubselect = index => {
    selectList[index].subSelect.push({ name: '' })
    setSelectList([...selectList])
  }

  const handelAddTelentsIntoSelects = async () => {
    const selectedTalents = gridList?.length === 1 ? [gridList[0]?.id] : gridList.filter(talent => talent.isSelected === true).map(talent => talent.id)

    const parentsData = []
    const subSlectData = []

    for (const item of selectList) {
      if (item?.subSelect?.length > 0) {
        parentsData.push(item)
        for (const subSelect of item?.subSelect) {
          if (subSelect?.isSelected) {
            subSlectData.push({ ...subSelect, parentInfo: item })
          }
        }
      }
    }
    setSelectedSubSelectCount(subSlectData.length)
    let apiCallCount = 0

    for (const subSelect of subSlectData) {
      await UpdateSelect({
        variables: {
          id: subSelect.id,
          name: subSelect.name,
          talents: [...new Set(selectedTalents.concat(subSelect?.talents ?? []))]
        }
      })
      apiCallCount = apiCallCount + 1
    }
    setUpdateSelectResponseCount(apiCallCount)
  }

  return (
    <>
      <div className={style.Drawer}>
        <Box paddingX={'40px'} paddingTop={'40px'}>
          <Typography fontSize={32} fontWeight={'bold'} letterSpacing={0.15}>
            Add Talent To Selects
          </Typography>
          <Typography
            fontSize={14}
            letterSpacing={0.25}
            color={Colors.headingBlack}>
            Add your selection of talent to a new or existing Select.
          </Typography>
          <SubHeaderWrapper>
            <Typography fontSize={16} fontWeight={'bold'} letterSpacing={0.15}>
              List Of Selects
            </Typography>
            <button className='outline-primary-button' onClick={() => { setLoadingMoreStatus(loadingStatus.done); toggleCreateNewSelects()}}>
              <span className={style.Drawer__AddNewSelectText}>Add new select</span>
            </button>
          </SubHeaderWrapper>
        </Box>

        <MenuWrapper onScroll={onScroll} ref={selectListRef}>
          <div ref={scrolldivRef} />
          {loading
            ? new Array(8).fill(0).map((_, index) => <Shimmer key={index} />)
            : selectList?.length > 0 &&
            selectList?.map((item, index) => {
              return (
                <AddTalentSubMenu
                  key={index}
                  select={item}
                  selectList={selectList}
                  setSelectList={setSelectList}
                  parentIndex={index}
                  handelToggelSubList={() => handelToggelSubList(index)}
                  addNewSubselect={() => createNewEntryForSubselect(index)}
                />
              )
            })}
          {loadingMoreStatus === loadingStatus.loading && <Shimmer />}
        </MenuWrapper>
      </div>
      <div className={style.Drawer__BottomCTA}>
        <button className='primary-text-button' onClick={() => toggleModal(!isVisibleModal)}>Cancel</button>
        <button className={classNames('primary-button', isDisabled ? 'primary-button--Disabled' : '')} onClick={isDisabled ? undefined : handleSubmit}>{updateSelectLoading && <SpinnerSmall />}Add</button>
      </div>
    </>
  )
}

AddTlyntToSelects.propTypes = {
  gridList: PropTypes.array,
  isVisibleModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  setSelectionMode: PropTypes.func,
  toggleCreateNewSelects: PropTypes.func,
  resetSelection: PropTypes.func,
  selectListQuery: PropTypes.object
}
