import { combineReducers } from 'redux'

import SidebarReducer from './SidebarReducer'
import DashBoardReducer from './DashBoardReducer'

import UserReducer from './user'
import BulkUploadReducer from './BulkUploadReducer'
import TalentVideoReducer from './TalentVideoReducer'
import TalentDetailReducer from './TalentDetailReducer'
import { DemographicReducer } from './DemographicReducer'
import UGCDownloadAssetsReducer from '../slices/ugcDownloadAssetSlice'

export default combineReducers({
  DashBoardReducer,
  SidebarReducer,
  TalentDetailReducer,
  BulkUploadReducer,
  TalentVideoReducer,
  UserReducer,
  DemographicReducer,
  UGCDownloadAssetsReducer
})
