/* eslint-disable react/prop-types */
import { useRef, useState } from 'react'
import styled from 'styled-components'

import { Images } from '../../../assets'
import { Typography } from '../../../components'
import { useOutsideHandler } from '../../../hooks'
import { RenderIf } from '../../../utils/Helpers'

const Container = styled.div`
 height: 100%;
 position: relative;
`

const ResizeContainer = styled.div`
  border: 1px solid black;
  padding: 0px 20px 0px 10px;
  width: 80px;
  margin-left: 20px;
  height: 100%;
  box-sizing: border-box;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`

const MenuPopupBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  background: white;
  z-index: 1000;
  width: 178px;
  height: 126px;
  right: 25px;
  top: ${props => (props.isUserTalents ? '150px' : '30px')};
  padding-left: 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
`

const ResizeListItem = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
`

const RESIZE_DATA = [
  {
    id: 0,
    key: 'SM',
    img: Images.resizeMinimal,
    title: 'Minimal'
  },
  {
    id: 1,
    key: 'MD',
    img: Images.resizeCompact,
    title: 'Compact'
  },
  {
    id: 2,
    key: 'LG',
    img: Images.resizeMaximum,
    title: 'Maximum'
  }
]

const TalentCardSizeModal = props => {
  // props
  const { handleTalentCardSize, resizeType } = props

  // hooks
  const popupRef = useRef(null)

  // state
  const [showResizeModal, setShowResizeModal] = useState(false)
  useOutsideHandler(popupRef, () => setShowResizeModal(false))

  // functions
  const getResizeIcon = () => {
    switch (resizeType) {
      case 'SM':
        return Images.resizeMinimal
      case 'MD':
        return Images.resizeCompact
      case 'LG':
        return Images.resizeMaximum
    }
  }

  return (
    <Container>
      <ResizeContainer onClick={() => setShowResizeModal(!showResizeModal)}>
        <img height={14} src={getResizeIcon()} />
        <img
          src={Images.resizeArrowdown}
          height={5}
          style={{
            rotate: showResizeModal ? '180deg' : '0deg'
          }}
        />
      </ResizeContainer>
      <RenderIf isTrue={showResizeModal}>
       <MenuPopupBox ref={popupRef}>
        {RESIZE_DATA.map(item => (
          <ResizeListItem
          key={item.id}
          onClick={() => handleTalentCardSize(item.key)}>
            <img src={item.img} height={16} style={{ marginRight: 10 }} />
            <Typography fontSize={16}>{item.title}</Typography>
          </ResizeListItem>
        ))}
       </MenuPopupBox>
      </RenderIf>
    </Container>
  )
}

export default TalentCardSizeModal
