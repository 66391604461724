import { useParams } from 'react-router-dom'

import useGetSelectionData from './useGetSelectionData'
import useGetTalentDemographic from './useGetTalentDemographic'
import { convertHeightToFeetAndInch } from '../../../utils/Helpers'

function useGetInitialPersonalInfoValue () {
  // hooks
  const { id } = useParams()
  const { data } = useGetSelectionData()
  // api calls
  const { data: talentDemographicData, refetch } = useGetTalentDemographic(id)

  // api data destructuring
  const race = data?.getTalentDemographicSelectionData?.race
  const ethnicity = data?.getTalentDemographicSelectionData?.ethnicity
  const sexualorientation = [{
    id: null,
    value: 'Select Sexual Orientation'
  }].concat(data?.getTalentDemographicSelectionData?.sexualOrientation).concat([{
    id: data?.getTalentDemographicSelectionData?.sexualOrientation?.length + 1,
    value: 'Others'
  }
  ])

  const copyRace = race
  const copyEthnicity = ethnicity

  const initialValues = {
    birthdate: talentDemographicData?.talentDemographicGet?.birthDate
      ? new Date(talentDemographicData?.talentDemographicGet?.birthDate)
      : null,
    weight: talentDemographicData?.talentDemographicGet?.weight?.toString() ?? 0,
    bodytype: talentDemographicData?.talentDemographicGet?.bodyType?.toString() ?? null,
    haircolor: talentDemographicData?.talentDemographicGet?.hairColor?.toString() ?? null,
    eyecolor: talentDemographicData?.talentDemographicGet?.eyeColor?.toString() ?? null,
    gender: talentDemographicData?.talentDemographicGet?.gender?.toString() ?? null,
    genderexpression: talentDemographicData?.talentDemographicGet?.genderExpression?.toString() ?? null,
    ethnicity: copyEthnicity
      ?.filter((ethnicity) => talentDemographicData?.talentDemographicGet?.ethnicity?.includes(ethnicity.id))
      ?.map((item) => ({ label: item?.value, value: item?.value })),
    sexualorientation: talentDemographicData?.talentDemographicGet?.sexualOrientation?.toString()
      ? talentDemographicData?.talentDemographicGet?.sexualOrientation?.toString()
      : talentDemographicData?.talentDemographicGet?.sexualOrientationOther ? sexualorientation.length - 1 : null,
    race: copyRace
      ?.filter((race) => talentDemographicData?.talentDemographicGet?.race?.includes(race.id))
      ?.map((item) => ({ label: item?.value, value: item?.value })),
    hfeet: convertHeightToFeetAndInch(talentDemographicData?.talentDemographicGet?.height)?.feet,
    hinch: convertHeightToFeetAndInch(talentDemographicData?.talentDemographicGet?.height)?.inch,
    customsexualorientation: talentDemographicData?.talentDemographicGet?.sexualOrientationOther ?? null,
    country: talentDemographicData?.talentDemographicGet?.country ?? null

  }
  return {
    initialValues,
    refetch
  }
}

export default useGetInitialPersonalInfoValue
