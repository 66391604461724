import { useState, useRef } from 'react'

import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector, useStore } from 'react-redux'
import Dropzone, { useDropzone } from 'react-dropzone'
import styled, { createGlobalStyle } from 'styled-components'

import { Images } from '../../assets'
import { Colors, Fonts, PORTFOLIO_ALLOWED_DRAGZONE_IMAGE_EXTENSION, PORTFOLIO_ALLOWED_IMAGE_EXTENSION, PORTFOLIO_MAX_IMG_SIZE } from '../../constants'
import { setTalentMediaList } from '../../redux/actions/TalentDetailActions'
import { useParams } from 'react-router-dom'
import { setTalentsWithoutPictures, setTalentsWithPictures } from '../../redux/actions/DashBoardActions'

const GlobalStyle = createGlobalStyle`
 Dropzone {
  width: 100%;
  height: 180px;
  margin-top: 2%;
  margin-left: 222%;
  margin-right: 22%;
  display: flex;
  flex-direction: column;
  background: red;
  color: #bdbdbd;
  border: 1px dashed grey;
  box-sizing: border-box;
}
`

const OuterWrapper = styled.div`
   display: ${props => props.modalVisible ? 'flex' : 'none'};  
   position: ${props => props.totalImages === 0 ? 'relative' : 'absolute'};
   top: 0px;
   left: 0px;
   z-index: 9;
   height:100%;
   width: 100%;
   background: ${props => props.totalImages === 0 ? Colors.white : Colors.blackOpacity}
`

const Wrapper = styled.div`
  flex-direction: column;
  flex:1;
  display: flex ;
  align-items: center;
  padding-top: 100px;
`

const UploadBox = styled.div`
  padding: 35px 170px;
  background: ${Colors.backgroundGray};
  border: 1px dashed ${Colors.dashedBorderGray};
  display: grid;
  place-items: center;
`

const ImgView = styled.img`
  height: 50px;
`

const BrowseText = styled.div`
 font-family: ${Fonts.degular};
 font-style: normal;
 font-weight: normal;
 font-size: 14px;
 line-height: 20px;
 color: ${Colors.black};
`

const BrowseTextStyled = styled.div`
  font-family: ${Fonts.degular}
  font-style: underline;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${Colors.black};
  margin-right: 6px;
  cursor: pointer;
`

const BrowseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 30px;
  width: 40px;
  top: 30px;
  height: 40px;
  background: ${Colors.white};
  opacity: 0.8;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: ${props => props.totalImages === 0 && 'none'}
`

const CloseButton = styled.img`
  width: 60%;
  height: 60%;
`

const BrowseImagePicker = styled.input`
  display: none;
`

const propTypes = {
  imageUploadProgress: PropTypes.number,
  acceptedFileTypes: PropTypes.string,
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  gridImages: PropTypes.array,
  uploadMediaData: PropTypes.func
}

const defaultProps = {
  modalVisible: false
}

export const UploadMedia = ({
  modalVisible,
  setModalVisible,
  uploadMediaData
}) => {
  const { id: talentId } = useParams()
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)
  const videoList = useSelector(state => state.TalentVideoReducer)
  const mediaList = useSelector(state => state.TalentDetailReducer?.mediaList)
  // hooks
  const store = useStore()
  const ref = useRef(null)
  const rTalentsDataWithoutPictures = dashboardReduxData.talentsWithoutPictures

  const { open } = useDropzone({
    noClick: true,
    accept: PORTFOLIO_ALLOWED_DRAGZONE_IMAGE_EXTENSION,
    noDrag: false,
    noKeyboard: true,
    onDrop: (acceptedFiles, rejectedFiles) => handleDrop(acceptedFiles, rejectedFiles)
  })

  const dispatch = useDispatch()
  const setImageUploadErrors = useState([])[1]

  const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
  const totalImages = mediaStoreList?.length

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    setImageUploadErrors([])
    if (rejectedFiles && rejectedFiles.length) {
      rejectedFiles.forEach((rejectedFile) => {
        let errorMsg = ''
        switch (rejectedFile.errors[0].code) {
          case 'file-invalid-type':
            errorMsg = `Incorrect File Format, File(${rejectedFile.file.name}) type must be PNG or JPG.`
            break
          default:
            errorMsg = rejectedFile.errors[0].message
        }
        setImageUploadErrors((prevErrors) => [...prevErrors, { message: errorMsg }])
      })
    }
    const fileArray = []
    let counter = 0
    for (const file of acceptedFiles) {
      const image = new Image()
      image.src = URL.createObjectURL(file)
      counter += 1
      if (file.name.split('.')[1] === 'heic' || file.name.split('.')[1] === 'HEIC') {
        fileArray.push({
          file,
          preview: '',
          title: `${Math.ceil((Math.random() * 10000000))}${file.name}`,
          isUploading: !(file.size > PORTFOLIO_MAX_IMG_SIZE),
          uploadProgress: 0,
          isUploaded: (file.size > PORTFOLIO_MAX_IMG_SIZE),
          isExceedSizeLimit: file.size > PORTFOLIO_MAX_IMG_SIZE
        })
      } else {
        image.addEventListener('load', () => {
          const reader = new FileReader()
          reader.onload = () => {
            fileArray.push({
              file,
              preview: reader.result,
              title: `${Math.ceil((Math.random() * 10000000))}${file.name}`,
              isUploading: !(file.size > PORTFOLIO_MAX_IMG_SIZE),
              uploadProgress: 0,
              isUploaded: (file.size > PORTFOLIO_MAX_IMG_SIZE),
              isExceedSizeLimit: file.size > PORTFOLIO_MAX_IMG_SIZE
            })
          }
          reader.readAsDataURL(file)
        })
      }
    }
    setTimeout(() => {
      if (counter === acceptedFiles.length) {
        dispatch(setTalentMediaList([...mediaStoreList, ...fileArray]))
        setModalVisible()
        uploadMediaData(fileArray)
        if (mediaList?.length === 0 && videoList?.length === 0) {
          dispatch(setTalentsWithoutPictures([...rTalentsDataWithoutPictures.filter((tlnt) => tlnt.id !== talentId)]))
          dispatch(setTalentsWithPictures([]))
        }
      }
    }, 700)
  }

  return (
    <OuterWrapper modalVisible={ modalVisible } totalImages={totalImages}>
      <Wrapper modalVisible={modalVisible}>
        <CloseButtonWrapper onClick={setModalVisible} totalImages={totalImages}>
          <CloseButton src={Images.closeVector} />
        </CloseButtonWrapper>
        <Typography fontSize={34} fontWeight={400} letterSpacing='0.25px' color={totalImages !== 0 ? 'white' : 'black'}>
            Upload your Images
          </Typography>
        <Typography fontSize={16} letterSpacing='0.25px' fontWeight='bold' color={totalImages !== 0 ? 'white' : Colors.inactiveBlack} style={{ margin: '5px 0 10px 0' }}>
          { totalImages === 0 ? 'Max 5 MB - Max 5000px' : 'PNG and JPG files are allowed' }
        </Typography>
        <GlobalStyle />
        <Box mt={2} />
        <Dropzone accept={PORTFOLIO_ALLOWED_DRAGZONE_IMAGE_EXTENSION} onDrop={handleDrop} noClick>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <UploadBox
              {...getRootProps({ onClick: evt => evt.preventDefault() })}>
              <input {...getInputProps()} />
                <ImgView src={Images.uploadIcon} />
                <>
                   <Typography mt={1.3} fontSize={14} lineHeight={'20px'}>
                     Drag &amp; drop your files or
                   </Typography>
                  <Dropzone accept={PORTFOLIO_ALLOWED_DRAGZONE_IMAGE_EXTENSION} onDrop={handleDrop}>
                    {({
                      getRootProps: getRootPropsButton,
                      getInputProps: getInputPropsButton
                    }) => (
                      <BrowseWrapper>
                        <div
                         {...getRootPropsButton({
                           onClick: () => ref.current.click()
                         })}>
                          <BrowseTextStyled>
                            <BrowseImagePicker
                             {...getInputPropsButton()}
                             onClick={(e) => {
                               open()
                               e.stopPropagation()
                             }} type='file' ref={ref} accept={PORTFOLIO_ALLOWED_IMAGE_EXTENSION} />
                            Browse
                          </BrowseTextStyled>
                        </div>
                          <BrowseText>from your computer</BrowseText>
                      </BrowseWrapper>
                    )}
                  </Dropzone>
                </>
            </UploadBox>
          )}
        </Dropzone>
      </Wrapper>
    </OuterWrapper>
  )
}

UploadMedia.propTypes = propTypes
UploadMedia.defaultProps = defaultProps
