import { createSlice } from '@reduxjs/toolkit'

export interface AssetState {
  talentId: string
  status: string
  jobId: string
}

export interface DownloadAssetsState {
  downloadAssetsJobs: AssetState[]
  showBottomBanner: boolean
  isAnyJobFailed: boolean
}

const INITIAL_STATE: DownloadAssetsState = {
  downloadAssetsJobs: [],
  showBottomBanner: false,
  isAnyJobFailed: false
}

const ugcDownloadAssetSlice = createSlice({
  name: 'ugcAssetDownload',
  initialState: INITIAL_STATE,
  reducers: {
    setUGCAssetsDownloadJobs: (state, action) => {
      const isAnyJobFailed = (action.payload.filter((job: AssetState) => job?.status === 'errored') ?? []).length > 0
      state.downloadAssetsJobs = isAnyJobFailed ? [] : action.payload
      state.showBottomBanner = action.payload.length > 0
      state.isAnyJobFailed = isAnyJobFailed
    }
  }
})
export const { setUGCAssetsDownloadJobs } = ugcDownloadAssetSlice.actions
export default ugcDownloadAssetSlice.reducer
