import { useQuery } from '@apollo/client'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

function useGetUserRoleListQuery () {
  const { data, loading } = useQuery(AdvertiserAPI.getUserRoleList(), {
    fetchPolicy: 'no-cache'
  })

  return {
    data,
    loading
  }
}

export default useGetUserRoleListQuery
