import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { SELECTION_VOTE_LIST } from '../../../services/API'

const useGetTalentLikesQuery = props => {
  // props
  const { activeSelectId } = props
  // api calls
  const [fetchVoteListQuery, { data, loading, refetch }] = useLazyQuery(SELECTION_VOTE_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (activeSelectId) {
      fetchVoteList()
    }
  }, [activeSelectId])

  const fetchVoteList = async () => {
    await fetchVoteListQuery({
      variables: {
        selectId: activeSelectId
      }
    })
  }

  return {
    voteData: data?.selectionVoteList ?? [],
    loading,
    refetch
  }
}

export default useGetTalentLikesQuery
