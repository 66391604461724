import { gql } from '@apollo/client'

export const createUGCProject = gql`
  mutation CreateUgcProject($name: String!, $ownerId: ID!, $brandId: ID, $description: String, $status: ProjectStatus, $endDate: String, $hasProduct: Boolean) {
    createUgcProject(name: $name, ownerId: $ownerId, brandId: $brandId, description: $description, status: $status, endDate: $endDate, hasProduct: $hasProduct) {
      id
      name
      owner {
        name
        logo {
          uris {
            ... on PhotoURLS {
              logo
            }
          }
        }
      }
    }
  }
`

export const updateProject = gql`
  mutation ProjectUpdate($projectUpdateId: ID!, $name: String, $ownerId: ID, $endDate: String, $description: String) {
    projectUpdate(id: $projectUpdateId, name: $name, ownerId: $ownerId, endDate: $endDate, description: $description) {
      id
    }
  }
`

export const createProjectDeliverable = gql`
  mutation CreateProjectDeliverable($projectId: ID!, $type: DeliverableType!, $mediaType: Type!, $script: [String!], $adSpecs: AdSpecsInput, $brollSpecs: DirectionSpecsInput, $introSpecs: DirectionSpecsInput, $mainSpecs: DirectionSpecsInput, $stillSpecs: DirectionSpecsInput) {
    createProjectDeliverable(projectId: $projectId, type: $type, mediaType: $mediaType, script: $script, adSpecs: $adSpecs, brollSpecs: $brollSpecs, introSpecs: $introSpecs, mainSpecs: $mainSpecs, stillSpecs: $stillSpecs) {
      id
      projectId
      type
      mediaType
      script
      specs {
        projectId
        deliverableType
        duration
        orientation
        specs
        createdAt
        updatedAt
      }
      adSpecs {
        adType
        hasGraphics
        audioLinks
      }
      brollSpecs {
        directions
      }
      introSpecs {
        directions
      }
      mainSpecs {
        directions
      }
      stillSpecs {
        directions
      }
      updatedBy
      createdAt
      updatedAt
    }
  }
`

export const updateDeliverableSpecs = gql`
  mutation UpdateDeliverableSpecs($projectId: ID!, $deliverableType: DeliverableType!, $duration: Int, $orientation: Orientation, $specs: [String!]) {
    updateDeliverableSpecs(projectId: $projectId, deliverableType: $deliverableType, duration: $duration, orientation: $orientation, specs: $specs) {
      createdAt
      specs
      orientation
      duration
    }
  }
`

export const updateUGCProject = gql`
  mutation UpdateUgcProject($updateUgcProjectId: ID!, $brandId: ID, $wardrobeSpecs: [String!], $lightingSpecs: [String!], $locationSpecs: [String!], $framingSpecs: [String!], $referenceLinks: [String!], $name: String, $description: String, $notes: String, $hasProduct: Boolean, $compensation: Int, $endDate: String, $status: ProjectStatus, $hasTalentProfile: Boolean) {
    updateUgcProject(id: $updateUgcProjectId, brandId: $brandId wardrobeSpecs: $wardrobeSpecs, lightingSpecs: $lightingSpecs, locationSpecs: $locationSpecs, framingSpecs: $framingSpecs, referenceLinks: $referenceLinks, name: $name, description: $description, notes: $notes, hasProduct: $hasProduct, compensation: $compensation, endDate: $endDate, status: $status, hasTalentProfile: $hasTalentProfile) {

      owner {
        name
        logo {
          uris {
            ... on PhotoURLS {
              logo
            }
          }
        }
      }
      
      id
      name
      status
      ownerId
      brandId
      selectId
      endDate
      description
      notes
      type
      compensation
      roles {
        id
        name
        projectId
        selectId
        startDate
        endDate
        rate
        createdAt
        criteria {
          age {
            min
            max
          }
          ageRanges {
            min
            max
          }
          weight {
            min
            max
          }
          height {
            min
            max
          }
          hairColor
          eyeColor
          ethnicity
          race
          gender
          genderExpression
          sexualOrientation
          bodyType
        }
      }
      hasProduct
      hasTalentProfile
      numberOfCreators
      numberOfVideos
      createdAt
      updatedAt
    }
  }
`

export const ListProjectDeliverables = gql`
  query ListProjectDeliverables($projectId: ID!, $limit: Int, $offset: Int) {
    listProjectDeliverables(projectId: $projectId, limit: $limit, offset: $offset) {
      id
      mediaType
      projectId
      script
      type
      specs {
        duration
        orientation
        deliverableType
        specs
      }
      stillSpecs {
        directions
      }
    }
  }
`

export const DeleteProjectDeliverable = gql`
  mutation DeleteProjectDeliverable($deleteProjectDeliverableId: ID!, $projectId: ID!) {
    deleteProjectDeliverable(id: $deleteProjectDeliverableId, projectId: $projectId) {
      message
      status
    }
}
`

export const UpdateProjectDeliverable = gql`
  mutation UpdateProjectDeliverable($updateProjectDeliverableId: ID!, $projectId: ID!, $script: [String!], $adSpecs: AdSpecsInput, $stillSpecs: DirectionSpecsInput) {
    updateProjectDeliverable(id: $updateProjectDeliverableId, projectId: $projectId, script: $script, adSpecs: $adSpecs, stillSpecs: $stillSpecs) {
      id
      projectId
      type
      mediaType
      script
      adSpecs {
        adType
        hasGraphics
        audioLinks
      }
      updatedBy
      createdAt
      updatedAt
      brollSpecs {
        directions
      }
      introSpecs {
        directions
      }
      mainSpecs {
        directions
      }
      stillSpecs {
        directions
      }
  }
}
`

export const GetUGCProject = gql`
  query UGCProject($getProjectId: ID!, $withBrand: Boolean) {
    getProject(id: $getProjectId) {
      ... on UGCProject {
        deliverables {
          id
          mediaType
          projectId
          specs {
            createdAt
            deliverableType
            duration
            orientation
            projectId
            specs
            updatedAt
          }
          stillSpecs {
            directions
          }
          type
          updatedAt
          script
          updatedBy
          createdAt
        }
        roles {
          id
          name
          projectId
          selectId
          quantity
          description
          startDate
          endDate
          rate
          createdAt
          criteria {
            ageRanges {
              min
              max
            }
            ethnicity
            gender
          }
        }
        brandId
        numberOfCreators
        compensation
        createdAt
        description
        endDate
        framingSpecs
        hasProduct
        hasTalentProfile
        id
        iteration
        lightingSpecs
        locationSpecs
        referenceLinks
        name
        notes
        owner(withBrand: $withBrand) {
          id
          logo {
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
          brands {
            id
            name
            logo {
              uris {
                ... on PhotoURLS {
                  logo
                }
              }
            }
          }
          name
        }
        ownerId
        selectId
        status
        color
        type
        updatedAt
        wardrobeSpecs
      }
    }
  }
`

export const ListBrands = gql`
  query ListBrands($orgId: ID!) {
    listBrands(orgId: $orgId) {
      id
      name
      logo {
        id
        type
        uris {
          ... on PhotoURLS {
            logo
          }
        }
      }
    }
  }
`
export const CreateProjectRole = gql`
  mutation ProjectRoleCreate($projectId: ID!, $name: String, $endDate: String, $rate: Int, $criteria: RoleCriteriaInput, $description: String, $notes: String, $quantity: Int) {
    projectRoleCreate(id: $projectId, name: $name, endDate: $endDate, rate: $rate, criteria: $criteria, description: $description, notes: $notes, quantity: $quantity) {
      id
      name
      projectId
      endDate
      rate
      criteria {
        age {
          min
          max
        }
        weight {
          min
          max
        }
        height {
          min
          max
        }
        ageRanges {
          min
          max
        }
        hairColor
        eyeColor
        ethnicity
        race
        gender
        genderExpression
        sexualOrientation
        bodyType
      }
      description
      notes
      quantity
      createdAt
      updatedAt
    }
  }
`

export const CreateProjectProduct = gql`
  mutation CreateProjectProduct($ownerId: ID!, $projectId: ID!, $name: String!, $provisioningMethod: ProvisioningMethod!, $url: String, $description: [String!], $directions: [String!]) {
    createProjectProduct(ownerId: $ownerId, projectId: $projectId, name: $name, provisioningMethod: $provisioningMethod, url: $url, description: $description, directions: $directions) {
      id
      ownerId
      projectId
      url
      directions
      description
      provisioningMethod
    }
  }
`
export const UpdateProjectProduct = gql`
  mutation UpdateProjectProduct($projectId: ID!, $provisioningMethod: ProvisioningMethod, $directions: [String!], $productId: ID!) {
    updateProjectProduct(projectId: $projectId, provisioningMethod: $provisioningMethod, directions: $directions, productId: $productId) {
      id
      name
      ownerId
      projectId
      provisioningMethod
      url
    }
  }
`

export const UpdateProduct = gql`
  mutation UpdateProduct($updateProductId: ID!, $ownerId: ID!, $name: String, $url: String, $description: [String!]) {
    updateProduct(id: $updateProductId, ownerId: $ownerId, name: $name, url: $url, description: $description) {
      description
      directions
      id
      name
      ownerId
      provisioningMethod
      url
    }
  }
`

export const UpdateProjectRole = gql`
  mutation UpdateProjectRole ($id: ID!, $name: String, $projectId: ID, $selectId: ID, $startDate: String, $endDate: String, $rate: Int, $criteria: RoleCriteriaInput, $description: String, $notes: String, $quantity: Int) {
    roleUpdate (id: $id, name: $name, projectId: $projectId, selectId: $selectId, startDate: $startDate, endDate: $endDate, rate: $rate, criteria: $criteria, description: $description, notes: $notes, quantity: $quantity) {
      id
      name
      projectId
      endDate
      rate
      criteria {
        ageRanges {
          min
          max
        }
        ethnicity
        gender
      }
      description
      notes
      quantity
      createdAt
      updatedAt
    }
  }
`

export const GetProductByProject = gql`
  query GetProductByProject($projectId: ID!) {
    getProductByProject(projectId: $projectId) {
      id
      ownerId
      projectId
      url
      name
      directions
      description
      provisioningMethod
    }
  }
`

export const DeleteProjectProduct = gql`
  mutation DeleteProjectProduct($projectId: ID!) {
    deleteProjectProduct(projectId: $projectId) {
      message
      status
    }
  }
`

export const SendNotification = gql`
  mutation SendProjectNotification($orgName: String!, $logoUrl: String!, $projectId: ID!, $projectName: String!, $userInfo: String!, $projectUrl: String!, $action: NotificationAction!) {
    sendProjectNotification(orgName: $orgName, logoUrl: $logoUrl, projectId: $projectId, projectName: $projectName, userInfo: $userInfo, projectUrl: $projectUrl, action: $action) {
      message
      status
    }
  }
`

export const GetSelect = gql`
  query GetSelect($getSelectId: ID!) {
    getSelect(id: $getSelectId) {
      id
      parentId
      name
      description
      talents
      ownerId
      archived
      mediaType
      createdAt
      updatedAt
    }
  }
`

export const GetProjectTalentSubmissions = gql`
  query GetProjectTalentSubmissions($projectId: ID!, $talentId: ID!, $deliverableType: [DeliverableType!]) {
    getProjectTalentSubmissions(projectId: $projectId, talentId: $talentId, deliverableType: $deliverableType) {
      deliverableId
      version
      updatedAt
      createdAt
      feedback
      media {
        uris {
          ... on VideoURLS {
            poster
            stream
          }
          ... on PhotoURLS {
            full
          }
        }
      }
      status
      mediaId
    }
  }
`

export const UpdateProjectTalentSubmission = gql`
  mutation UpdateTalentSubmission($talentId: ID!, $deliverableVersion: Int!, $status: DeliverableTalentStatus!, $deliverableId: ID!, $feedback: [String!]) {
    updateTalentSubmission(talentId: $talentId, deliverableVersion: $deliverableVersion, status: $status, deliverableId: $deliverableId, feedback: $feedback) {
      feedback
      version
    }
  }
`

export const PatchUGCProjectTalentStatus = gql`
  mutation PatchUGCProjectTalentStatus($projectId: ID!, $talentId: ID!, $status: ProjectTalentStatus!) {
    patchUGCProjectTalentStatus(projectId: $projectId, talentId: $talentId, status: $status) {
      status
    }
  }
`
export const RequestDownloadSubmission = gql`
  mutation RequestDownloadSubmissions($projectId: ID!, $talentId: ID!) {
    requestDownloadSubmissions(projectId: $projectId, talentId: $talentId) {
      status
      filepath
      jobId
      createdAt
      talentId
    }
  }
`

export const GetProjectSubmissionJob = gql`
  query GetProjectJob($projectId: ID!, $jobId: ID!) {
    getProjectJob(projectId: $projectId, jobId: $jobId) {
      filepath
      jobId
      talentId
      status
    }
  }
`
