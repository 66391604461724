import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { Box, Skeleton, Stack, Typography } from '@mui/material'

import { Colors } from '../../../constants'
import { formatDate } from '../../../utils/Helpers'

const shimmerCount = new Array(4).fill(0).map((_, index) => index)

const ProjectOverview = props => {
  const { detail, setIsFooter } = props

  useEffect(() => {
    setIsFooter(!!detail)
  }, [detail])

  // functions
  const handleMediaValue = array => {
    return array?.length > 0 ? array.join(', ') : '-'
  }
  const FieldData = (title, value, isValue = true) => {
    return (
      <Stack direction='row' padding='12px 0'>
        <Typography color={Colors.blackOpacity} minWidth='160px'>{title}</Typography>
        {isValue ? <Typography textTransform='capitalize'>{value ?? '-'}</Typography> : value}
      </Stack>
    )
  }

  const CastingProjectOverview = () => {
    return (
      <Box padding={'40px'}>
        {FieldData('Description', !detail.description ? '-' : detail.description)}
        {FieldData('Start Date', formatDate(detail.startDate, 'MM/DD/YYYY'))}
        {FieldData('End Date', formatDate(detail.endDate, 'MM/DD/YYYY'))}
        {FieldData('Location', `${detail?.countryCodeLocation}, ${detail?.postalCode}`)}
        {FieldData('Media Type', handleMediaValue(detail.mediaType))}
        {FieldData(
          'Usages',
          <Stack direction='row'>
            {detail.usage
              ? detail.usage.map((e, index) => (
                <Typography key={index} mr={2}>{e}</Typography>
              ))
              : '-'}
            </Stack>,
          false
        )}
        {FieldData('Notes', !detail.notes ? '-' : detail.notes)}
      </Box>
    )
  }

  if (detail && !detail?.isLoading) {
    return <CastingProjectOverview />
  } else if (detail?.isLoading) {
    return <Box padding={'40px'}>{shimmerCount.map((e, index) => <Skeleton key={index} variant="rectangular" width={'100%'} sx={{ marginBottom: 2 }} height={60} />)}</Box>
  }
}

ProjectOverview.propTypes = {
  detail: PropTypes.object,
  setIsFooter: PropTypes.func,
  isTikTokProject: PropTypes.bool
}

export default ProjectOverview
