import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { showToast } from '../../redux/actions/DashBoardActions'

export const useListBrands = (orgID: string): { data: any, loading: boolean } => {
  const { data, loading } = useQuery(
    gql`
      query ListBrands($orgId: ID!) {
        listBrands(orgId: $orgId) {
          id
          name
          logo {
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
        }
      }
    `,
    {
      variables: { orgId: orgID },
      fetchPolicy: 'no-cache'
    }
  )

  return { data, loading }
}

type MutationHookOutput = [
  (variables: any, onSuccess: (data: any) => void) => void,
  { loading: boolean }
]

export const useCreateBrand = (): MutationHookOutput => {
  const dispatch = useDispatch()

  const [createBrandMutation, { loading }] = useMutation(gql`
    mutation CreateBrand($orgId: ID!, $name: String!, $logo: ID) {
      createBrand(orgId: $orgId, name: $name, logo: $logo) {
        id
        name
        logo {
          uris {
            ... on PhotoURLS {
              logo
            }
          }
        }
        owner {
          name
        }
      }
    }
  `, { notifyOnNetworkStatusChange: true })

  const createBrand = (variables: any, onSuccess: (data: any) => void): void => {
    createBrandMutation({ variables: { ...variables } })
      .then(res => {
        onSuccess(res.data.createBrand)
      })
      .catch(err => {
        console.error(err)
        dispatch(showToast({ message: 'Internal Server Error.', isError: true }))
      })
  }

  return [createBrand, { loading }]
}
