import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import type { Dispatch } from 'react'

import { DeleteProjectDeliverable, ListProjectDeliverables, SendNotification, UpdateProjectDeliverable, createProjectDeliverable, updateDeliverableSpecs, updateUGCProject } from '../../apis/ugc'
import { DeliverablesContext } from '../../contexts/deliverables'
import { showToast } from '../../redux/actions/DashBoardActions'
import { UGCProjectDetailContext } from '../../contexts/projectDetail'
import { URL } from '../../constants/URL'
import type { IProjectDetail, IProjectDetailActions } from '../../contexts/projectDetail'
import type { UserState } from '../../redux/reducers/user'
import { stillImageDirection } from '../../utils/Helpers'

interface useUpdateDeliverableType {
  handleUpdateDeliverable: (close: boolean) => void
  isLoading: boolean
}

export const useUpdateDeliverable = (close: boolean): useUpdateDeliverableType => {
  // init
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [projectDetailData] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)
  const [deliverableData, updateDeliverableHandler] = useContext(DeliverablesContext)

  const { brandId, owner } = projectDetailData

  const brandDetails = useMemo(() => {
    return owner?.brands?.find((brand: { id: string }) => brand?.id === brandId)
  }, [owner?.brands, brandId])

  // states
  const [isLoading, setIsLoading] = useState(false)

  // constants
  const user: UserState = useSelector((state: any) => state.UserReducer)
  const lastName = String(user?.info?.name?.last)
  const firstName = String(user?.info?.name?.first)
  const userInfo = (firstName?.length > 0) || (lastName?.length > 0) ? `${firstName} ${lastName}` : user?.info?.email
  const orgName = brandId?.length > 0 ? brandDetails?.name ?? '' : projectDetailData.owner?.name ?? ''
  const orgLogoUrl = brandId?.length > 0 ? brandDetails?.logo?.uris?.logo ?? '' : projectDetailData.owner?.logo?.uris?.logo ?? ''
  const sendNotificationPayload = {
    orgName,
    logoUrl: orgLogoUrl,
    projectId: projectDetailData.id,
    projectName: projectDetailData.name,
    userInfo,
    action: 'update'
  }

  // api calls
  const [createProjectDeliverableMutation] = useMutation(createProjectDeliverable, { notifyOnNetworkStatusChange: true })
  const [deleteProjectDeliverableMutation] = useMutation(DeleteProjectDeliverable, { notifyOnNetworkStatusChange: true })
  const [sendNotificationMutation] = useMutation(SendNotification, { notifyOnNetworkStatusChange: true })
  const [updateDeliverableSpecsMutation] = useMutation(updateDeliverableSpecs, { notifyOnNetworkStatusChange: true })
  const [updateProjectDeliverableMutation] = useMutation(UpdateProjectDeliverable, { notifyOnNetworkStatusChange: true })
  const [updateUGCProjectMutation] = useMutation(updateUGCProject, { notifyOnNetworkStatusChange: true })
  const { data: listDeliverablesData } = useQuery(ListProjectDeliverables, { variables: { projectId: id, limit: 100, offset: 0 }, skip: id == null, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })

  const handleUpdateDeliverable = async (close: boolean): Promise<void> => {
    const listDeliverables: any = {}

    for (const iterator of listDeliverablesData?.listProjectDeliverables) {
      if (typeof listDeliverables[iterator.type] === 'undefined') {
        listDeliverables[iterator.type] = [iterator]
      } else {
        listDeliverables[iterator.type].push(iterator)
      }
    }

    setIsLoading(true)
    try {
      // --------------- main ---------------
      const currentMainScript = deliverableData.main.projectDeliverable.script.map((item, index) => ({ ...item, index }))
      const deliverableMain = listDeliverables?.main ?? []
      const oldMainScript = deliverableMain?.map((item: any) => ({ isChecked: true, value: item.script?.[0], id: item.id }))

      await updateDeliverableSpecsMutation({ variables: { projectId: id, ...deliverableData.main.deliverableSpec, specs: deliverableData.main.deliverableSpec.specs[0] === '' ? null : deliverableData.main.deliverableSpec.specs[0].split('\n') } })

      for (const iterator of currentMainScript) {
        if (iterator.id === null) {
          const index = iterator.index
          const resopnse = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'main', mediaType: 'video', script: iterator.value[0] === '' ? undefined : iterator.value[0].split('\n') } })

          const deliverableId = resopnse.data?.createProjectDeliverable?.id
          deliverableData.main.projectDeliverable.script[index].id = deliverableId
          updateDeliverableHandler({ ...deliverableData })
        }
      }

      // delete
      for (const iterator of oldMainScript) {
        const found = currentMainScript.find((item) => item.id === iterator.id)
        if (found === undefined && (Boolean(iterator.id))) {
          await deleteProjectDeliverableMutation({ variables: { deleteProjectDeliverableId: iterator.id, projectId: id } })
        }
      }

      // update
      for (const iterator of oldMainScript) {
        const found = currentMainScript.find((item) => item.id === iterator.id)
        if ((found?.value?.[0] !== iterator.value?.[0] || found?.isChecked === false) && (Boolean(iterator.id)) && found !== undefined) {
          const updatedScript = currentMainScript.find((item) => item.id === iterator.id)?.isChecked === false ? null : currentMainScript.find((item) => item.id === iterator.id)?.value[0].split('\n')
          await updateProjectDeliverableMutation({ variables: { projectId: id, updateProjectDeliverableId: currentMainScript.find((item: any) => item.id === iterator.id)?.id, type: 'main', mediaType: 'video', script: updatedScript } })
        }
      }

      // ------------ audition ------------
      const deliverableAudition = deliverableData.audition

      // delete
      if ((deliverableAudition.id != null) && !deliverableAudition.specs[0].isChecked) {
        await deleteProjectDeliverableMutation({ variables: { deleteProjectDeliverableId: deliverableAudition.id, projectId: id } })
      } else if ((deliverableAudition.id == null) && deliverableAudition.specs[0].isChecked && deliverableAudition.specs[0].value[0] !== '') {
        // create
        const response = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'audition', mediaType: 'video', script: deliverableAudition.specs[0].value[0].split('\n') } })

        const deliverableId = response.data?.createProjectDeliverable?.id
        deliverableData.audition.id = deliverableId
        updateDeliverableHandler({ ...deliverableData })
      }

      await updateDeliverableSpecsMutation({ variables: { projectId: id, deliverableType: 'audition', specs: deliverableAudition.specs[0].isChecked ? deliverableAudition.specs[0].value[0].split('\n') : null } })

      // ----------------- reference_links ----------------
      const referenceLinks = deliverableData.referenceLinks
      await updateUGCProjectMutation({ variables: { updateUgcProjectId: id, referenceLinks } })

      // --------------- intro_hook ---------------
      const deliverableIntroHook = listDeliverables?.intro_hook ?? []
      const oldIntroScripts = deliverableIntroHook.map((item: any) => ({ isChecked: item.script !== '', value: item.script === '' || item.script === null ? '' : item.script.join('\n'), id: item.id }))

      const currentIntroHookScript = deliverableData.introHooks.projectDeliverable.script.map((item, index) => ({ ...item, index }))

      // add
      for (const iterator of currentIntroHookScript) {
        if (iterator?.id === null) {
          const index = iterator.index
          const response = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'intro_hook', mediaType: 'video', script: iterator.value[0] === '' ? undefined : iterator.value[0].split('\n') } })

          const deliverableId = response.data?.createProjectDeliverable?.id
          deliverableData.introHooks.projectDeliverable.script[index].id = deliverableId
          updateDeliverableHandler({ ...deliverableData })
        }
      }

      // delete
      for (const iterator of oldIntroScripts) {
        const found = currentIntroHookScript.find((item) => item.id === iterator.id)
        if (found === undefined && (Boolean(iterator.id))) {
          await deleteProjectDeliverableMutation({ variables: { deleteProjectDeliverableId: iterator.id, projectId: id } })
        }
      }

      // update
      for (const iterator of oldIntroScripts) {
        const found = currentIntroHookScript.find((item) => item.id === iterator.id)
        if (((found?.value !== iterator.value || found?.isChecked === false)) && (Boolean(iterator.id) && found !== undefined)) {
          const updatedScript = currentIntroHookScript.find((item) => item.id === iterator.id)?.isChecked === false ? null : currentIntroHookScript.find((item) => item.id === iterator.id)?.value[0]?.split('\n')
          await updateProjectDeliverableMutation({ variables: { projectId: id, updateProjectDeliverableId: currentIntroHookScript.find((item: any) => item.id === iterator.id)?.id, type: 'intro_hook', mediaType: 'video', script: updatedScript } })
        }
      }

      // --------------- b_roll ---------------
      const deliverableBRoll = deliverableData.bRoll.projectDeliverable.specs
      if (deliverableData.bRoll.projectDeliverable.id !== null && !deliverableData.bRoll.projectDeliverable.specs.isChecked) {
        // delete
        await deleteProjectDeliverableMutation({ variables: { deleteProjectDeliverableId: deliverableData.bRoll.projectDeliverable.id, projectId: id } })
      } else if ((deliverableBRoll.id == null) && deliverableBRoll.isChecked && deliverableBRoll.value[0] !== '') {
        // add
        const response = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'b_roll', mediaType: 'video', script: deliverableBRoll.value[0].split('\n') } })

        const deliverableId = response.data?.createProjectDeliverable?.id
        deliverableData.bRoll.projectDeliverable.id = deliverableId
        updateDeliverableHandler({ ...deliverableData })
      }
      await updateDeliverableSpecsMutation({ variables: { projectId: id, deliverableType: 'b_roll', specs: deliverableBRoll.isChecked ? deliverableBRoll.value[0].split('\n') : null } })

      // --------------- photo ---------------
      const oldPhotoSpecs = listDeliverables?.photo ?? []
      const photoSpecs = deliverableData.photo.projectDeliverable.specs.map((item, index) => ({ ...item, index }))

      for (const photoSpec of photoSpecs) {
        // add
        if (photoSpec.id === null) {
          const response = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'photo', mediaType: 'photo', stillSpecs: { directions: stillImageDirection(photoSpec.value, photoSpec.additionalNote) } } })

          const deliverableId = response.data?.createProjectDeliverable?.id
          deliverableData.photo.projectDeliverable.specs[photoSpec.index].id = deliverableId
          updateDeliverableHandler({ ...deliverableData })
        } else {
          // update
          await updateProjectDeliverableMutation({ variables: { projectId: id, updateProjectDeliverableId: photoSpec.id, type: 'photo', mediaType: 'photo', stillSpecs: { directions: stillImageDirection(photoSpec.value, photoSpec.additionalNote) } } })
        }
      }

      // delete
      for (const iterator of oldPhotoSpecs) {
        const found = photoSpecs.find((item) => item.id === iterator.id)
        if (found === undefined && (Boolean(iterator.id))) {
          await deleteProjectDeliverableMutation({ variables: { deleteProjectDeliverableId: iterator.id, projectId: id } })
        }
      }

      if (id !== undefined && close && user?.isAdvertiser) {
        await sendNotificationMutation({ variables: { ...sendNotificationPayload, projectUrl: `${URL}/projects/ugc/view/${id}/overview` } })
      }

      setIsLoading(false)
      if (close) {
        navigate('/projects')
      } else if (id != null) {
        navigate(`/projects/ugc/${id}/talent`)
      }
    } catch (error) {
      setIsLoading(false)
      dispatch(showToast({ isError: true, message: 'Something went wrong. Please try again.' }))
    }
  }

  return { handleUpdateDeliverable: (close: boolean) => { void handleUpdateDeliverable(close) }, isLoading }
}
