import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from '../../../../constants'

const TabItemsWrapper = styled.div`
  display: flex;
  justify-content: flex-start; 
  width: 100%;
`

const TabItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-right: 20px;
  cursor: pointer;
`

const TabTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  opacity: ${props => props.isActive ? '1' : '0.5'}
`
const ActiveTabBar = styled.div`
  background-color: ${Colors.black};
  height: 2px;
  visibility: ${props => props.isActive ? 'visible' : 'hidden'};
  width: 100%;
`

const TabBar = ({ data, activeTabIndex, handleActiveTabIndex }) => {
  return (
    <TabItemsWrapper>
        {data.map((tab) => {
          return (
            <TabItem key={tab.key} onClick={() => handleActiveTabIndex(tab.key)}>
              <TabTitle isActive = {tab.key === activeTabIndex}>{tab.name}</TabTitle>
              <ActiveTabBar isActive = {tab.key === activeTabIndex} />
            </TabItem>
          )
        })}
    </TabItemsWrapper>)
}

export default TabBar

TabBar.propTypes = {
  data: PropTypes.array,
  activeTabIndex: PropTypes.number,
  handleActiveTabIndex: PropTypes.func
}
