import PropTypes from 'prop-types'
import { RenderIf } from '../../../../utils/Helpers'
import style from './PortfolioTab.module.scss'
import classNames from 'classnames'
const PortfolioTab = ({ index, handleTabIndex, isPublic, isAuditionAvailable }) => {
  return (
    <div className={style.PortfolioTab__Wrapper}>
      <div className={classNames(style.PortfolioTab__Box, style[`PortfolioTab__Box${index === 0 ? '--active' : ''}`])} onClick={() => handleTabIndex(0)}>
        <span>Profile Video</span>
      </div>
      <RenderIf isTrue={isPublic && isAuditionAvailable}>
        <div className={classNames(style.PortfolioTab__Box, style[`PortfolioTab__Box${index === 3 ? '--active' : ''}`])} onClick={() => handleTabIndex(3)}>
          <span>Audition Video</span>
        </div>
      </RenderIf>
      <div className={classNames(style.PortfolioTab__Box, style[`PortfolioTab__Box${index === 1 ? '--active' : ''}`])} onClick={() => handleTabIndex(1)}>
        <span>Photos</span>
      </div>
      <div className={classNames(style.PortfolioTab__Box, style[`PortfolioTab__Box${index === 2 ? '--active' : ''}`])} onClick={() => handleTabIndex(2)}>
        <span>Videos</span>
      </div>
    </div>
  )
}

export default PortfolioTab

PortfolioTab.propTypes = {
  index: PropTypes.number,
  handleTabIndex: PropTypes.func,
  isPublic: PropTypes.bool,
  isAuditionAvailable: PropTypes.bool
}
