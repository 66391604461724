import PropTypes from 'prop-types'

const LogoShort = ({ color = 'black' }) => {
  return (
    <svg height="33" viewBox="0 0 746 606" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M443.974 0H0V55.9073H443.974V0Z" fill={color} />
      <path d="M285.939 143.803H229.989V606H285.939V143.803Z" fill={color} />
      <path d="M443.974 71.9016H0V127.816H443.974V71.9016Z" fill={color} />
      <path d="M213.992 143.803H158.042V606H213.992V143.803Z" fill={color} />
      <path d="M746 550.093H302.026V606H746V550.093Z" fill={color} />
      <path d="M516.011 0H460.061V462.197H516.011V0Z" fill={color} />
      <path d="M746 478.191H302.026V534.105H746V478.191Z" fill={color} />
      <path d="M587.966 0H532.016V462.197H587.966V0Z" fill={color} />
    </svg>
  )
}

LogoShort.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string
}

export default LogoShort
