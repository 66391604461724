/* eslint-disable no-unused-vars */

// Created by Adnaan - 30 June
import Select from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ErrorMessage, Field } from 'formik'
import ListItemText from '@mui/material/ListItemText'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect
} from '@mui/material'

import { TextError } from '../textError'
import { Images } from '../../../assets'
import { Colors, Fonts } from '../../../constants'
import { FullWidth, LabelText, Wrapper } from './styles'

const ModifiedSelect = styled(Select)`
  padding: 0;
  min-height: 48px;
  width: 100%;
  border: none;
  background: ${Colors.antiFlashWhite};
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: ${Fonts.degular};
  border: 1px solid ${props => (props.error ? Colors.errorRed : 'transparent')};
`

const ModifiedBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
  background: transparent;
`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const MultiSelect = props => {
  const { label, name, options, placeholder, isStandard, data, isMulti = true, onChange, loading = false, ...rest } = props
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      borderColor: 'transparent',
      boxShadow: state.isFocused ? null : null,
      height: '100%',
      '&:hover': {
        borderColor: 'transparent'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontFamily: Fonts.degular
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      fontSize: 16
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: 'min-content'
    })
  }
  if (isStandard) {
    return (
      <FullWidth>
        <Field id={name} name={name} {...rest}>
          {fieldProps => {
            const { field, meta, form } = fieldProps
            const { value } = field
            const { setFieldValue, setValues } = form
            const { error, touched } = meta
            return (
              <FormControl style={{ marginBottom: 24 }} fullWidth>
                <InputLabel>{label}</InputLabel>
                <MuiSelect
                  multiple
                  variant='standard'
                  label={label}
                  value={value ?? []}
                  onChange={event => {
                    const {
                      target: { value }
                    } = event
                    setFieldValue(name, value)
                  }}
                  renderValue={selected => (
                    <ModifiedBox>
                      {selected?.map(value => {
                        return (
                          <Chip
                            clickable
                            style={{
                              background: Colors.black,
                              color: Colors.white,
                              marginRight: 10,
                              marginBottom: 5
                            }}
                            deleteIcon={
                              <img
                                onMouseDown={e => e.stopPropagation()}
                                src={Images.grayCross}
                                style={{ height: 20, width: 20 }}
                              />
                            }
                            key={value}
                            label={value}
                            onDelete={e => {
                              e.preventDefault()
                              setFieldValue(
                                name,
                                selected?.filter(
                                  im => im !== value
                                )
                              )
                            }}
                          />
                        )
                      })}
                    </ModifiedBox>
                  )}
                  MenuProps={MenuProps}>
                  {data?.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        style={{
                          color: '#000'
                        }}
                        checked={value?.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            )
          }}
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </FullWidth>
    )
  }
  return (
    <Wrapper>
      <LabelText htmlFor={name}>{label}</LabelText>
      <FullWidth>
        <Field id={name} name={name} {...rest}>
          {fieldProps => {
            const { field, meta, form } = fieldProps
            const { value } = field
            const { setFieldValue } = form
            const { error, touched } = meta
            return (
              <ModifiedSelect
                isMulti={isMulti}
                isSearchable
                isLoading={loading}
                variant='standard'
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                styles={customStyles}
                placeholder={placeholder}
                options={options.map(item => ({
                  value: item.value,
                  label: item?.label || item?.value
                }))}
                error={error && touched}
                value={value}
                onChange={val => setFieldValue(name, val)}
                onInputChange={(e) => onChange && onChange(e) }
              />
            )
          }}
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </FullWidth>
    </Wrapper>
  )
}

MultiSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isStandard: PropTypes.bool,
  data: PropTypes.array,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool
}

export default MultiSelect
