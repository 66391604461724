import { useEffect, useReducer, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Drawer, Skeleton } from '@mui/material'
import * as z from 'zod'

import style from './CreateSelect.module.scss'
import { InputBox } from '../InputBox'
import { Select } from '../Select'
import ProjectAPI from '../../services/Project/ProjectAPI'
import { DELETE_SELECT } from '../../services/API'
import { useParams } from 'react-router-dom'
import { type UserState } from '../../redux/reducers/user'
import { useSelector, useDispatch } from 'react-redux'
import { SpinnerSmall } from '../Progress'
import { Images } from '../../assets'
import { RenderIf } from '../../utils/Helpers'
import { showToast } from '../../redux/actions/DashBoardActions'
import SelectAPI from '../../services/Select/SelectAPI'

type IValidationError = Record<string, string | undefined>

interface CreateSelectProps {
  isOpen: boolean
  handleClose: () => void
  isEdit?: boolean
  isDuplicate?: boolean
  roleData?: IRole[]
  refetch?: () => void
}

interface organizationList {
  id: string
  name: string
  contact: string
  logo: Logo
  type: string
  __typename: string
}

interface Logo {
  id: string
  file: string
  uris: Uris
  __typename: string
}

interface Uris {
  card: string
  thumbnail: string
  logo: string
  __typename: string
}

interface IRole {
  id: string
  name: string
  parentId: string
  talents: string[]
}

interface IINITIAL_STATE {
  selectName: string
  description: string
  roles: Array<{ value: string, id: null | string, isDeleted: boolean, talents: string[] }>
  advertiser: string
}

const INITIAL_STATE: IINITIAL_STATE = {
  selectName: '',
  description: '',
  roles: [{ value: '', id: null, isDeleted: false, talents: [] }],
  advertiser: ''
}

const selectValidationSchema = z.object({
  selectName: z.string().nonempty('Select Name is required').min(3, 'Name must be 3 character long').max(255, 'Select name must be less than 255 character'),
  description: z.string().max(255, 'Description must be less than 255 character.'),
  roles: z.array(z.object({
    value: z.string().min(3, 'Role name must be atleast 3 characters long').nonempty('Role name is required').max(255, 'Role name must be less than 255 character')
  }))
})

interface IAddRoleAction {
  type: 'ADD_ROLE'
}

interface IDeleteRoleAction {
  type: 'DELETE_ROLE'
  payload: number
}

interface IOnChangeSelectNameAction {
  type: 'ON_CHANGE_SELECT_NAME'
  payload: string
}

interface IOnChangeDescriptionAction {
  type: 'ON_CHANGE_DESCRIPTION'
  payload: string
}

interface IOnChangeRoleAction {
  type: 'ON_CHANGE_ROLE'
  payload: { index: number, value: string }
}

interface IOnChangeAdvertiserAction {
  type: 'ON_CHANGE_ADVERTISER'
  payload: string
}

interface ISetRolesAction {
  type: 'SET_ROLES'
  payload: Array<{
    value: string
    id: null | string
    isDeleted: boolean
    talents: string[]
  }>
}

interface IResetAction {
  type: 'RESET'
}

type IAction =
  | IAddRoleAction
  | IDeleteRoleAction
  | IOnChangeSelectNameAction
  | IOnChangeDescriptionAction
  | IOnChangeRoleAction
  | IOnChangeAdvertiserAction
  | ISetRolesAction
  | IResetAction

function reducer (state = INITIAL_STATE, action: IAction): IINITIAL_STATE {
  switch (action.type) {
    case 'ON_CHANGE_SELECT_NAME': {
      const newValue = action.payload
      return {
        ...state,
        selectName: newValue
      }
    }
    case 'ON_CHANGE_DESCRIPTION': {
      const newValue = action.payload
      return {
        ...state,
        description: newValue
      }
    }
    case 'ADD_ROLE': {
      return {
        ...state,
        roles: state.roles.concat({ value: '', id: null, isDeleted: false, talents: [] })
      }
    }

    case 'DELETE_ROLE': {
      const roleIndex = action.payload
      state.roles[roleIndex].isDeleted = true
      return {
        ...state
      }
    }
    case 'ON_CHANGE_ROLE': {
      const { index, value } = action.payload
      const roleIndex = state.roles.findIndex((_, i: number) => i === index)
      state.roles[roleIndex].value = value
      return {
        ...state
      }
    }

    case 'ON_CHANGE_ADVERTISER': {
      const advertiserId = action.payload
      return {
        ...state,
        advertiser: advertiserId
      }
    }

    case 'SET_ROLES': {
      const roles = action.payload
      return {
        ...state,
        roles
      }
    }

    case 'RESET': {
      return {
        selectName: '',
        description: '',
        roles: [{ value: '', id: null, isDeleted: false, talents: [] }],
        advertiser: ''
      }
    }
    default: {
      return state
    }
  }
}

export const CreateSelect = (props: CreateSelectProps): JSX.Element => {
  const { isOpen, handleClose, isEdit = false, isDuplicate = false, refetch, roleData } = props
  const { id: selectId } = useParams()

  const user: UserState = useSelector((state: any) => state.UserReducer)

  const reduxDispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<IValidationError>({})
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const [CreateSelectMutation] = useMutation(SelectAPI.createSelect(), { notifyOnNetworkStatusChange: true })
  const [DeleteChildSelect] = useMutation(DELETE_SELECT, { notifyOnNetworkStatusChange: true })
  const [UpdateSelect] = useMutation(SelectAPI.updateSelect(), { notifyOnNetworkStatusChange: true })
  const { data: selectInfoData, loading: selectInfoLoading } = useQuery(SelectAPI.getSelectForCRUD(), { variables: { id: selectId }, notifyOnNetworkStatusChange: true, skip: !isEdit })
  const { data: organisationListData, loading: organisationListLoading } = useQuery(ProjectAPI.getOrganizationList(), { variables: { offset: 0, limit: 1000, sort: JSON.stringify({ name: 'asc' }) }, fetchPolicy: 'no-cache' })

  useEffect(() => {
    if ((isEdit || isDuplicate) && !selectInfoLoading && Object.values(selectInfoData?.getSelect ?? {}).length > 0 && selectId !== undefined && isOpen) {
      const { name, description, ownerId } = selectInfoData?.getSelect
      dispatch({ type: 'ON_CHANGE_SELECT_NAME', payload: isDuplicate ? `Duplicate - ${String(name)}` : name })
      dispatch({ type: 'ON_CHANGE_DESCRIPTION', payload: description })
      dispatch({ type: 'ON_CHANGE_ADVERTISER', payload: ownerId === null ? '' : ownerId })
      if ((roleData !== null && roleData !== undefined) && roleData?.length > 0) {
        dispatch({ type: 'SET_ROLES', payload: roleData.map((item: IRole) => ({ value: item?.name, id: item.id, isDeleted: false, talents: item.talents })) })
      }
    }
    if (!isEdit) {
      dispatch({ type: 'RESET' })
    }
  }, [isEdit, selectInfoLoading, roleData, isDuplicate, isOpen])

  const toggleDrawer = (event: KeyboardEvent | MouseEvent): void => {
    if (event.type === 'keydown' && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')) return
    dispatch({ type: 'RESET' })
    handleClose()
  }

  const getOwnerId = (): string | undefined => {
    if (user.isAdmin) {
      return state.advertiser === '' ? undefined : state.advertiser
    } else {
      return user.info.org
    }
  }

  const handleCreateChildSelect = async (parentSelectId: string): Promise<void> => {
    for (const iterator of state.roles) {
      if (!iterator.isDeleted) {
        await CreateSelectMutation({
          variables: {
            parentId: parentSelectId,
            talents: iterator.talents,
            name: iterator.value,
            ownerId: getOwnerId(),
            mediaType: 'photo'
          }
        })
      }
    }
  }

  // creating new select and subSelect
  const handleSubmitSelect = async (): Promise<void> => {
    const result = selectValidationSchema.safeParse({
      ...state,
      roles: state.roles.filter((item) => !item.isDeleted)
    })
    if (result.success) {
      setErrors({})
      try {
        setIsSubmitting(true)
        const response = await CreateSelectMutation({
          variables: {
            name: state.selectName,
            description: state.description,
            ownerId: getOwnerId(),
            mediaType: 'photo'
          }
        })
        if (state.roles.length > 0) {
          await handleCreateChildSelect(response?.data?.createSelect?.id)
        }
        dispatch({ type: 'RESET' })
        typeof refetch === 'function' && refetch()
        reduxDispatch(showToast({ message: 'Select created successfully', isError: false }))
        handleClose()
      } catch (error: any) {
        reduxDispatch(showToast({ message: error?.message ?? 'Try again later', isError: true }))
      }
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          errValues[item.path.join('.')] = item.message
        })
        setErrors({ ...errors, ...errValues })
      }
    }
    setIsSubmitting(false)
  }

  // editing select and subSelect
  const handleEditSelect = async (): Promise<void> => {
    const result = selectValidationSchema.safeParse(state)
    if (result.success) {
      try {
        setErrors({})
        setIsSubmitting(true)
        await UpdateSelect({
          variables: {
            id: selectId,
            name: state.selectName,
            description: state.description,
            ownerId: getOwnerId()
          }
        })
        for (const iterator of state.roles) {
          if (iterator.id !== null && !iterator.isDeleted) {
            await UpdateSelect({
              variables: { id: iterator.id, name: iterator.value }
            })
          } else if (iterator.id === null && !iterator.isDeleted) {
            await CreateSelectMutation({
              variables: { parentId: selectId, name: iterator.value, ownerId: getOwnerId() }
            })
          } else if (iterator.id !== null && iterator.isDeleted) {
            await DeleteChildSelect({
              variables: { id: iterator.id }
            })
          }
        }
        reduxDispatch(showToast({ message: 'Select updated successfully', isError: false }))
        typeof refetch === 'function' && refetch()
        handleClose()
      } catch (error: any) {
        reduxDispatch(showToast({ message: error?.message ?? 'Try again later', isError: true }))
      }
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          errValues[item.path.join('.')] = item.message
        })
        setErrors({ ...errors, ...errValues })
      }
    }
    setIsSubmitting(false)
  }

  const handleSubmit = (): void => {
    if (isEdit && !isDuplicate) {
      void handleEditSelect()
    } else {
      void handleSubmitSelect()
    }
  }

  const handleDeleteRole = (roleIndex: number): void => { dispatch({ type: 'DELETE_ROLE', payload: roleIndex }) }

  const handleInputBlur = (errorValue: string): void => {
    setErrors({ ...errors, [errorValue]: undefined })
  }

  const DeleteRoleEndAdornment = ({ onClick }: { onClick: () => void }): JSX.Element => <div className={style.Drawer__RoleDeleteAdornment} onClick={onClick}><img className={style.Drawer__RoleDeleteAdornmentImg} src={Images.closeVectorBlack} /></div>

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={toggleDrawer}>
      <div className={style.Drawer}>
        <h1 className={style.Drawer__HeaderText}>{ isEdit ? isDuplicate ? 'Duplicate Select' : 'Edit Selects' : 'Create New Selects'}</h1>
        <p className={style.Drawer__HeaderDescription}>{ isEdit ? 'Edit' : 'Add' } basic details of Selects.</p>
        <div className={style.Drawer__SelectInfo}>
          <InputBox name='selectName' onBlur={() => { handleInputBlur('selectName') }} value={state?.selectName} isError={errors?.selectName !== undefined} errorText={errors?.selectName} onChange={event => { dispatch({ type: 'ON_CHANGE_SELECT_NAME', payload: event.target.value }) }} placeholder='Selects Name' />
          <InputBox name='description' onBlur={() => { handleInputBlur('description') }} value={state?.description} isError={errors?.description !== undefined} errorText={errors?.description} onChange={event => { dispatch({ type: 'ON_CHANGE_DESCRIPTION', payload: event.target.value }) }} placeholder='Description' isMultiline rows={4} />
        </div>
        <div className={style.Drawer__Role}>
          <h2 className={style.Drawer__RoleHeaderText}>Roles</h2>
          <div className={style.Drawer__Roles}>
            {state.roles.map((role: { isDeleted: boolean, value: string }, index: number) => !role.isDeleted
              ? <InputBox key={index} isError={errors[`roles.${index}.value`] !== undefined} onBlur={() => { handleInputBlur(`roles.${index}.value`) }} errorText={errors[`roles.${index}.value`]} value={role.value} endAdornment={state.roles.filter(e => !e.isDeleted).length > 1 ? <DeleteRoleEndAdornment onClick={() => { handleDeleteRole(index) }} /> : <></>} onChange={(event) => { dispatch({ type: 'ON_CHANGE_ROLE', payload: { index, value: event.target.value } }) }} placeholder='Role Name' />
              : null)}
          </div>
          <p onClick={() => { dispatch({ type: 'ADD_ROLE' }) } } className={style.Drawer__AddNewRoleText}>+ Add New Role</p >
        </div>
        <RenderIf isTrue={user?.isAdmin}>
          <div>
            <h2 className={style.Drawer__RoleHeaderText}>Advertiser</h2>
            {organisationListLoading
              ? <div className={style.Drawer__AdvertiserDropdownShimmer}><Skeleton variant="rectangular" /></div>
              : <Select value={state?.advertiser} name='ownerId' onChange={(event) => { dispatch({ type: 'ON_CHANGE_ADVERTISER', payload: event.target.value }) } } label='Select' options={organisationListData?.organizationList.map((item: organizationList) => ({ id: item.id, value: item.name, logo: item.logo?.uris?.logo, type: item?.type })) ?? []} />}
          </div>
        </RenderIf>
      </div>
      <footer className={style.Footer}>
        <button onClick={() => {
          dispatch({ type: 'RESET' })
          handleClose()
        }} className={classNames(style.Button, style['Button--Transparent'])}><p className={ classNames(style.Button__Text, style['Button__Text--Black'])}>Cancel</p></button>
        <button onClick={handleSubmit} className={style.Button}><p className={style.Button__Text}> { isEdit ? 'Save' : 'Create Select' } </p>{ isSubmitting && <SpinnerSmall />}</button>
      </footer>
    </Drawer>
  )
}
