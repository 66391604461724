import { useQuery } from '@apollo/client'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

function useGetAdvertiserQuery ({ id }) {
  const { data, refetch, loading } = useQuery(AdvertiserAPI.getAdvertiserData(), {
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  })

  return {
    data,
    refetch,
    loading
  }
}

export default useGetAdvertiserQuery
