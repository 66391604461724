import { DemographicTypes } from '../types/DemographicTypes'

const INITIAL_STATE = {
  ethnicity: []
}

export const DemographicReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case DemographicTypes.SET_ETHNICITY:
      return {
        ...state,
        ethnicity: [...payload]
      }
    default:
      return state
  }
}
