import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import FilterSidebar from './FilterSidebar'
import useSelectListQuery from '../Hooks/useSelectLIstQuery'

import AddNewTlynt from '../Components/AddTalent/AddNewTlynt'
import { showToast } from '../../../redux/actions/DashBoardActions'
import { CreateNewSelects, TlyntSidebar, AddTlyntToSelects } from '../../../components'

const RightSidebars = props => {
  // props
  const {
    data,
    setData,
    setSelectionMode,
    toggleCreateNewSelects,
    isVisibleModal,
    selectionMode,
    toggleModal,
    isCreateNewSelects,
    showFilterModal,
    toggleFilterModal
  } = props
  // hooks
  const dispatch = useDispatch()
  // apis
  const selectListQuery = useSelectListQuery({
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache'
  })
  return (
    <div>
      {isVisibleModal && !selectionMode && (
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <AddNewTlynt
            isVisibleModal={isVisibleModal}
            toggleModal={toggleModal}
          />
        </TlyntSidebar>
      )}
      {isVisibleModal && selectionMode && (
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <AddTlyntToSelects
            newSelectCreated={false}
            selectListQuery={selectListQuery}
            gridList={data}
            resetSelection={() =>
              setData(
                data.map(talent => ({
                  ...talent,
                  isSelected: false
                }))
              )
            }
            isVisibleModal={isVisibleModal}
            toggleModal={toggleModal}
            toggleSuccessPopup={() =>
              dispatch(
                showToast({
                  message: "You've successfully added talent into selects",
                  isError: false
                })
              )
            }
            setSelectionMode={setSelectionMode}
            toggleCreateNewSelects={() =>
              toggleCreateNewSelects(!isCreateNewSelects)
            }
          />
        </TlyntSidebar>
      )}
      {isCreateNewSelects && (
        <TlyntSidebar direction={'right'} opacityLevel={0}>
          <CreateNewSelects
            isBackArrow={true}
            toggleSuccessPopup={() =>
              dispatch(
                showToast({
                  message: 'Select created successfully',
                  isError: false
                })
              )
            }
            isVisibleModal={isCreateNewSelects}
            toggleModal={() => {
              selectListQuery.refetch()
              toggleCreateNewSelects()
            }}
          />
        </TlyntSidebar>
      )}
      {showFilterModal && <FilterSidebar toggleSidebar={toggleFilterModal} />}
    </div>
  )
}

RightSidebars.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
  setSelectionMode: PropTypes.func,
  toggleCreateNewSelects: PropTypes.func,
  isVisibleModal: PropTypes.bool,
  selectionMode: PropTypes.bool,
  toggleModal: PropTypes.func,
  isCreateNewSelects: PropTypes.bool,
  showFilterModal: PropTypes.bool,
  toggleFilterModal: PropTypes.func
}

export default RightSidebars
