import types from '../types/types'

const INITIAL_STATE = {
  uploadList: [],
  successCount: 0,
  failCount: 0,
  isMediaUploadInProgress: false
}

const BulkUploadReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_BULK_UPLOAD_LIST:
      return {
        ...state,
        uploadList: payload
      }
    case types.SET_SUCCESS_COUNT:
      return {
        ...state,
        successCount: state.successCount + 1

      }
    case types.SET_FAIL_COUNT:
      return {
        ...state,
        failCount: state.failCount + 1
      }
    default:
      return state
  }
}

export default BulkUploadReducer
