import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ErrorMessage, Field } from 'formik'
import {
  Select as MuiSelect,
  InputLabel,
  FormControl,
  MenuItem,
  Skeleton
} from '@mui/material'

import { styled as MuiStyled } from '@mui/system'

import { TextError } from '../textError'
import { Colors, Fonts } from '../../../constants'
import { FullWidth, LabelText, Wrapper } from './styles'
import { RenderIf } from '../../../utils/Helpers'

const ModifiedSelect = styled.select`
  padding: 0;
  height: 48px;
  width: 100%;
  border: none;
  background: ${Colors.antiFlashWhite};
  box-sizing: border-box;
  padding-left: 10px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  font-family: ${Fonts.degular};
  font-size: 16px;
  border: 1px solid ${props => (props.error ? Colors.errorRed : 'transparent')};
`
const SlectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ModifiedOption = styled.option`
  font-family: ${Fonts.degular};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`

const MuiModifiedSelect = MuiStyled(MuiSelect)({
  '& .MuiSelect-select': {
    backgroundColor: 'transparent'
  }
})

function Select (props) {
  const { label, name, options, isStandard = false, hidelabel, style, loading = false, containerStyle, selectProps, ...rest } = props

  if (isStandard) {
    return (
      <SlectWrapper>
        <Field name={name}>
          {fieldProps => {
            const { field, meta, form } = fieldProps
            const { error, touched } = meta
            const { setFieldValue, handleBlur } = form
            const { value } = field
            return (
              <FormControl style={{ width: '100%', ...containerStyle }}>
                <RenderIf isTrue={!loading}>
                {
                  label && <InputLabel
                    style={{
                      fontFamily: Fonts.degular,
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.15
                    }}
                  variant='standard'
                  error={error && touched}
                  id="select-label">
                  {label}
                </InputLabel>
                }
                  <MuiModifiedSelect
                    style={{ ...style }}
                    id={name}
                    name={name}
                    {...selectProps}
                    value={value}
                    variant='standard'
                    onBlur={handleBlur}
                    error={error && touched}
                    labelId="select-label"
                    label={label}
                    fullWidth
                    onChange={e => setFieldValue(name, e.target.value)}>
                    {options.map((item, index) => {
                      return (
                        <MenuItem key={index} value={JSON.stringify(item.id)}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </MuiModifiedSelect>
                </RenderIf>
                <RenderIf isTrue={loading}>
                 <Skeleton width='100%' height={60} />
                </RenderIf>
              </FormControl>
            )
          }}
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </SlectWrapper>
    )
  } else {
    return (
      <Wrapper>
      { !hidelabel && <LabelText htmlFor={name}>{label}</LabelText> }
       <FullWidth style={{ ...style }} >
          <Field id={name} name={name} variant='standard' {...rest}>
            {fieldProps => {
              const { field, meta } = fieldProps
              const { error, touched } = meta
              return (
                <ModifiedSelect error={error && touched} {...field}>
                  {options?.map(option => {
                    return (
                      <ModifiedOption key={option.id} value={option.id}>
                        {option.value}
                      </ModifiedOption>
                    )
                  })}
                </ModifiedSelect>
              )
            }}
          </Field>
          <ErrorMessage name={name} component={TextError} />
        </FullWidth>
      </Wrapper>
    )
  }
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  hidelabel: PropTypes.bool,
  isStandard: PropTypes.bool,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  loading: PropTypes.bool,
  selectProps: PropTypes.object
}

export default Select
