import { useLazyQuery } from '@apollo/client'

import TalentAPI from '../../../services/Talent/TalentAPI'

export function useGetMediaById () {
  const [getMedia, { data, loading }] = useLazyQuery(TalentAPI.getMediaById(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  return {
    getMedia,
    data,
    loading
  }
}

export const useGetMediaFull = () => {
  const [getMedia, { data, loading }] = useLazyQuery(TalentAPI.getMediaByIdFull(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  return {
    getMedia,
    data,
    loading
  }
}

export default useGetMediaById
