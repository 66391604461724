import styled from 'styled-components'
import { Colors, Fonts } from '../../../constants'

export const TalentContainer = styled.div`
  cursor: pointer;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    height: 80%;
    background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.64) 92%
    );
  }
`
export const TalentImage = styled.img`
  object-fit: contain;
  width: 100%;
  cursor: pointer;
`

export const CheckboxWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
  display: ${props => (props.show ? 'none' : 'flex')};
`

export const CheckboxGrayWrapper = styled.div`
 height: 28px;
 width: 28px;
 background-color: #919191;
 border-radius: 4px;
 display: flex;
 justify-content: center;
 margin-top: 10px;
 margin-right: 10px;
`

export const BottomWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 4px;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.64) 92%
    );
  z-index: 8;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
`

export const BottomLeft = styled.div`
  width: 75%;
  margin-bottom: 15px;
`

export const BottomRight = styled.div`
  width: 25%;
  margin-bottom: 10px;

  place-items: center;
  display: ${props => (props.visible ? 'grid' : 'none')};
`

export const TalentDetail = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding-left: 16px;
`

export const TalentTitle = styled.p`
  margin: 0;
  width: auto;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  color: ${Colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 24px;
        
  text-transform: capitalize;
  @media (min-width: 1400px) {
    font-size: 24px;
    max-height: 36px;
  }
`

export const TalentLocation = styled.p`
  margin: 0;
  font-family: ${Fonts.degular};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: ${Colors.white};
`

export const MediaToggleBox = styled.div`
  box-sizing: border-box;
  height: 52px;
  width: 28px;
  background: ${Colors.backgroundGrayOpacity};
  backdrop-filter: blur(44px);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 0;
`

export const ToggleArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`

export const ToggleArrow = styled.img`
  opacity: ${props => (!props.isAllowed ? 0.2 : 1)};
  height: 6px;
  cursor: ${props => (!props.isAllowed ? 'not-allowed' : 'pointer')};
`

export const DeleteTalent = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  position: absolute;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  top: 10px;
  display: grid;
  place-items: center;
  z-index: 9;
  cursor: pointer;
`
