import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import ProjectAPI from '../../../services/Project/ProjectAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'
import useAddRolesWithSelectMutation from './useAddRolesWithSelectMutation'

function useAddProjectMutation () {
  // hooks
  const dispatch = useDispatch()
  // state
  const [loading, setLoading] = useState(false)
  // mutation
  const [AddProjectMutation, { data: projectData, loading: projectDataLoading }] = useMutation(ProjectAPI.addProjectWithSelect(),
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all'
    }
  )
  const [SubmitRole, { loading: submitRoleLoading }] = useAddRolesWithSelectMutation()

  useEffect(() => {
    setLoading(projectDataLoading || submitRoleLoading)
  }, [projectDataLoading, submitRoleLoading])

  const AddProjectWithSelect = async (formData, toggleModal) => {
    AddProjectMutation({
      variables: formData.project
    }).then(
      async ({ data }) => {
        const projectId = data.addProjectWithSelect.id
        const ownerId = data.addProjectWithSelect.ownerId
        const selectId = data.addProjectWithSelect.selectId
        const finalRoles = { roles: formData.role, projectId, selectId, ownerId }
        await SubmitRole(finalRoles, () => toggleModal(projectId))
      }
    ).catch((error) => {
      dispatch(
        showToast({
          message: error?.networkError?.result?.errors[0]?.extensions?.response?.body?.error ?? error?.message,
          isError: true
        })
      )
    })
  }

  return [AddProjectWithSelect, { projectData, loading }]
}

export default useAddProjectMutation
