import { memo, useState } from 'react'

import VideoJS from '../VideoJS'
import style from './PortfolioProfileVideo.module.scss'
import { UGCVideoCard } from '../TalentCardV2'

interface PropsType {
  poster: string
  stream: string
}

export const PortfolioProfileVideo = memo(function PortfolioProfileVideo (props: PropsType): JSX.Element {
  const { poster, stream } = props
  const [isPlaying, setIsPlaying] = useState(false)

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: false,
    fill: true,
    preload: 'auto',
    controlBar: {
      pictureInPictureToggle: false
    },
    sources: [
      {
        src: stream,
        type: 'application/x-mpegURL'
      }
    ],
    bigPlayButton: false
  }

  if (!isPlaying) {
    return <UGCVideoCard isLandscape={false} onClick={() => { setIsPlaying(true) }} videoThumbnail={poster} />
  }

  return <div className={style.Video__Player}><VideoJS options={videoJsOptions} /></div>
})
