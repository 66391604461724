import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import Cropper from 'react-cropper'
import { useMutation } from '@apollo/client'
import ToggleButton from 'react-toggle-button'
import { CircularProgress, Box, Typography } from '@mui/material'
import { ArrowForwardIos, ArrowBackIos, ArrowBack } from '@mui/icons-material'

import { RenderIf } from '../../../../utils/Helpers'
import { Button } from '../../../../components/buttonV2'

import LocalStorage from '../../../../utils/LocalStorage'
import TalentAPI from '../../../../services/Talent/TalentAPI'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { Colors, UIConstants, TalentConstants } from '../../../../constants'

import './CropperStyle.css'
import 'cropperjs/dist/cropper.css'

const PreviewWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  background: ${Colors.blackgroundBlackOpacity};
`
const PreviewContainer = styled.div`
  display: flex;
  height: calc(100vh - 100px);
`
const PreviewSidebarLeft = styled.div`
  width: 200px;
  height: 100%;
  background: ${Colors.PreviewSideBarblackgroundBlackOpacity};
  display: flex;
  align-items: center;
`
const PreviewSidebarRight = styled.div`
  width: 200px;
  height: 100%;
  background: ${Colors.PreviewSideBarblackgroundBlackOpacity};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const BackButtonView = styled.button`
  flex-direction: row;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
  margin-left: 40px;
  align-items: center;
  background: transparent;
  border: none;
`
const OptionsView = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-Right: 40px;
  align-items: center;
`
const DeleteButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: solid;
  border-width: 2px;
  border-color: ${Colors.white};
  color: white;
  padding: 10px ${props => props?.isPublic ? '100px' : '40px'};
  margin-left: 30px;
  text-transform: uppercase;
`

const SliderButton = styled.button`
  background: ${Colors.black};
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  display: grid;
  place-items: center;
`

const SliderImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 400px);
  height: 100%;
  text-align: center;
  background: ${Colors.PreviewSideBarblackgroundBlackOpacity};
`

const SliderImage = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`

const DummyImage = styled.img`
  height: 0;
`
const ButtonWrapper = styled.div`
 opacity: ${props => props.disable ? '0.6' : '1'};
`
const ToggleButtonWrapper = styled.div`
pointer-events: ${props => props.disable ? 'none' : 'visible'};
margin-right: 20px;
`

const PersonalPortfolioPreview = (props) => {
  const {
    talentId,
    isPublic,
    mediaList,
    onNextHandler,
    onBackHandler,
    onUpdateHandler,
    profileImageId,
    onPreviousHandler,
    selectedMediaIndex,
    refetchTalentDetail,
    handleToggleDeleteImageSidebar
  } = props
  // hooks
  const dispatch = useDispatch()

  // constants
  const userInfo = LocalStorage.getItem('userInfo')

  // api calls

  const [onTalentUpdateHandler, { loading }] = useMutation(TalentAPI.createProfilePicture(), {
    notifyOnNetworkStatusChange: true
  })

  // states
  const [cropper, setCropper] = useState()
  const [originalImage, setOriginalImage] = useState('')
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isFullImagedLoaded, setIsFullImageLoaded] = useState(false)
  const [fullImageURL, setFullImageURL] = useState('')
  const [toggleProfileImageCrop, setToggleProfileImageCrop] = useState(false)

  const [toggleValue, setToggleValue] = useState(mediaList[selectedMediaIndex]?.disabled)

  useEffect(() => {
    if (mediaList[selectedMediaIndex]) {
      setToggleValue(mediaList[selectedMediaIndex]?.disabled)
    }
  }, [mediaList[selectedMediaIndex]])

  useEffect(() => {
    if (mediaList[selectedMediaIndex]?.uris?.full) {
      setIsFullImageLoaded(false)
      setFullImageURL(mediaList[selectedMediaIndex]?.uris?.full)
    }
  }, [mediaList[selectedMediaIndex]?.uris?.full])

  const handelSetAsProfile = async () => {
    const { imageData, cropBoxData, canvasData } = cropper

    const relativeLeft = cropBoxData.left - canvasData.left
    const relativeTop = cropBoxData.top

    const width = (imageData.naturalWidth * cropBoxData.width) / imageData.width
    const height = width

    const left = (imageData.naturalWidth * relativeLeft) / imageData.width
    const top = (imageData.naturalHeight * relativeTop) / imageData.height

    const cropProps = {
      height: parseInt(height),
      width: parseInt(width),
      offsetX: parseInt(left),
      offsetY: parseInt(top)
    }

    onTalentUpdateHandler({
      variables: {
        talentId,
        mediaId: mediaList[selectedMediaIndex].id,
        cropProps
      }
    }).then(res => {
      refetchTalentDetail()
      dispatch(showToast({
        message: TalentConstants.UpdateProfilePictureSuccess
      }))
      setToggleProfileImageCrop(false)
    }).catch(() => dispatch(showToast({
      message: 'Try again later.',
      isError: true
    })))
  }

  const cropperToggleHandler = () => {
    setToggleProfileImageCrop((prev) => !prev)
  }

  return (
    <PreviewWrapper>
      <Header>
        <BackButtonView onClick={onBackHandler}>
          <ArrowBack sx={{ color: 'white' }} size={'14'} />
          <Typography fontSize={16} color={Colors.white} style={{ marginLeft: '10px' }}>{UIConstants.Back}</Typography>
        </BackButtonView>
        <RenderIf isTrue={!isPublic && userInfo?.type !== 'advertiser' && !toggleProfileImageCrop}>
          <OptionsView>
            <Typography color={Colors.white} fontSize={18} fontWeight='bold' style={{ marginRight: 20 }}>{UIConstants.hidden}</Typography>
            <ButtonWrapper disable={profileImageId?.id === mediaList[selectedMediaIndex]?.id} title={profileImageId?.id === mediaList[selectedMediaIndex]?.id ? 'You cannot set this image as a hidden' : ''}>
              <ToggleButtonWrapper disable={profileImageId?.id === mediaList[selectedMediaIndex]?.id}>
                <ToggleButton value={toggleValue}
                  disable={true}
                  onToggle={() => {
                    setToggleValue(!toggleValue)
                    onUpdateHandler(mediaList[selectedMediaIndex]?.id, mediaList[selectedMediaIndex].disabled)
                  }}
                />
              </ToggleButtonWrapper>
            </ButtonWrapper>
            <ButtonWrapper disable={toggleValue || !isFullImagedLoaded} title={toggleValue ? 'You cannot set this image as a profile picture' : ''}>
              <Button disabled={toggleValue || !isFullImagedLoaded} onClick={cropperToggleHandler} width='335px' loading={loading ?? loading}>
                <Typography fontSize={15} fontWeight='bold' lineHeight='26px' letterSpacing='0.46px' color={Colors.white} textTransform='uppercase'>Make as a profile picture</Typography>
              </Button>
            </ButtonWrapper>
            <DeleteButton onClick={handleToggleDeleteImageSidebar}>{UIConstants.deleteImage}</DeleteButton>
          </OptionsView>
        </RenderIf>
        <RenderIf isTrue={toggleProfileImageCrop}>
        <OptionsView>
            <ButtonWrapper disable={toggleValue} title={toggleValue ? 'You cannot set this image as a profile picture' : ''}>
              <Button disable={toggleValue} onClick={handelSetAsProfile} loading={loading} width='150px'>
                <Typography fontSize={15} fontWeight='bold' lineHeight='26px' letterSpacing='0.46px' color={Colors.white} textTransform='uppercase'>SAVE</Typography>
              </Button>
            </ButtonWrapper>
            <DeleteButton onClick={() => setToggleProfileImageCrop(false)}>CANCEL</DeleteButton>
          </OptionsView>
        </RenderIf>
      </Header>
      <PreviewContainer onClick={onBackHandler}>
        <PreviewSidebarLeft>
          <SliderButton onClick={(e) => {
            e.stopPropagation()
            onPreviousHandler()
            setIsImageLoaded(false)
            setOriginalImage('')
          }}>
            <ArrowBackIos sx={{ color: 'white', fontSize: 20, ml: 0.5 }} />
          </SliderButton>
        </PreviewSidebarLeft>
        <SliderImageContainer>
          <DummyImage src={fullImageURL} onLoad={() => setIsFullImageLoaded(true)} />
          <RenderIf isTrue={selectedMediaIndex >= 0}>
            {toggleProfileImageCrop &&
              <Box height={'100%'} onClick={(e) => e.stopPropagation()}>
                <Cropper
                  zoomOnWheel={false}
                  background={false}
                  src={mediaList[selectedMediaIndex]?.uris?.full}
                  checkCrossOrigin={false}
                  style={{ height: '100%', objectFit: 'contain' }}
                  aspectRatio={1}
                  guides={false}
                  viewMode={2}
                  onInitialized={(instance) => {
                    setCropper(instance)
                  }}
                />
              </Box>
            }
            <RenderIf isTrue={!isImageLoaded}>
              <Box height={'100%'} display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress size={50} sx={{ color: 'white' }} />
              </Box>
            </RenderIf>
            <RenderIf isTrue={!toggleProfileImageCrop}>
               <SliderImage
                key={mediaList[selectedMediaIndex]}
                onError={() => {
                  if (typeof mediaList[selectedMediaIndex]?.file === 'object') {
                    // handling local image
                    if (mediaList[selectedMediaIndex]?.file?.name?.includes('.heic')) {
                      setOriginalImage(mediaList[selectedMediaIndex]?.uris?.full)
                    } else setOriginalImage(mediaList[selectedMediaIndex]?.preview)
                  } else {
                    // handling external image
                    setOriginalImage(mediaList[selectedMediaIndex]?.uris?.full)
                  }
                }}
                style={{ display: isImageLoaded ? 'block' : 'none' }}
                src={ originalImage || mediaList[selectedMediaIndex]?.uris?.full}
                onLoad={() => setIsImageLoaded(true) }
                onClick={(e) => e.stopPropagation()}
               />
            </RenderIf>
          </RenderIf>
        </SliderImageContainer>
        <PreviewSidebarRight>
          <SliderButton onClick={(e) => {
            e.stopPropagation()
            onNextHandler()
            setIsImageLoaded(false)
            setOriginalImage('')
          }}>
            <ArrowForwardIos sx={{ color: 'white', fontSize: 20 }} />
        </SliderButton>
        </PreviewSidebarRight>
      </PreviewContainer>
    </PreviewWrapper>
  )
}

PersonalPortfolioPreview.propTypes = {
  profileImageId: PropTypes.string,
  talentId: PropTypes.string,
  isPublic: PropTypes.bool,
  selectedMediaIndex: PropTypes.number,
  mediaList: PropTypes.array,
  onBackHandler: PropTypes.func,
  handleToggleDeleteImageSidebar: PropTypes.func,
  onUpdateHandler: PropTypes.func,
  onPreviousHandler: PropTypes.func,
  onNextHandler: PropTypes.func,
  disable: PropTypes.bool,
  refetchTalentDetail: PropTypes.func
}

export default PersonalPortfolioPreview
