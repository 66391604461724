import moment from 'moment'
import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { RenderIf } from '../../../utils/Helpers'
import style from '../UGCTalentCard/UGCTalentCard.module.scss'
import assetCardStyle from './UGCAssetTalentCard.module.scss'
import UGCVideoEmpty from '../../../assets/images/UGCProject/VideoEmpty.svg'
import UGCImageEmpty from '../../../assets/images/UGCProject/ImageEmpty.svg'
import images from '../../../assets/images'

/**
  * Used to render the status tag on the talent card
  * @param {string} status - status of the talent
  * @returns {JSX.Element} - JSX Element to render the status tag
*/
const DisplayTag = (props: { status: string }): JSX.Element => {
  const { status } = props

  switch (status) {
    case 'submitted':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Blue'])}><span className={style.Card__TagText}>In Review</span></div>
    case 'approved':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Green'])}><span className={style.Card__TagText}>Approved</span></div>
    case 'rejected':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Orange'])}><span className={style.Card__TagText}>Revision Requested</span></div>
    case 'not_submitted':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Gold'])}><span className={style.Card__TagText}>Not Submitted</span></div>
    default:
      return <></>
  }
}

interface TalentCardThumbnailProps {
  mediaThumbnail?: string
  hasVideo?: boolean
  isLandscape: boolean
}

const TalentCardThumbnail = (props: TalentCardThumbnailProps): JSX.Element => {
  // INIT
  const { mediaThumbnail, hasVideo = true, isLandscape } = props
  const [isLoaded, setIsLoaded] = useState(false)
  const [imgSource, setImgSource] = useState(mediaThumbnail)

  useEffect(() => {
    setImgSource(mediaThumbnail)
  }, [mediaThumbnail])

  if (imgSource === undefined) {
    return (
      <>
        <div className={classNames(style.Card__Overlay, style['Card__Overlay--Center'], isLandscape ? assetCardStyle['Card__Overlay--Landscape'] : '')}>
          <img src={hasVideo ? UGCVideoEmpty : UGCImageEmpty} alt='empty' />
        </div>
      </>
    )
  }

  return (
    <picture>
      <div className={classNames(style.Card__Overlay, isLandscape ? assetCardStyle['Card__Overlay--Landscape'] : '')}>
        <RenderIf isTrue={hasVideo}>
          <div className={style.Card__Play}>
            <div className={style.Card__PlayTriangle} />
          </div>
        </RenderIf>
      </div>
      <source srcSet={isLoaded ? imgSource : images.imagePlaceholder} type='image/webp' />
      <img alt='' loading='lazy' onLoad={() => { setIsLoaded(true) }} className={classNames(assetCardStyle.Card__Photo, isLandscape ? assetCardStyle['Card__Overlay--Landscape'] : '')} />
    </picture>
  )
}

interface UGCTalentCardProps {
  lastUpdated?: string
  mediaThumbnail?: string
  status: string | null
  isLandscape?: boolean
  onClick?: () => void
  hasVideo?: boolean
  version: number
  title: string
  photoCaption?: string
}

export const UGCAssetsTalentCard = (props: UGCTalentCardProps): JSX.Element => {
  const { lastUpdated, mediaThumbnail, status, isLandscape = false, onClick, hasVideo, version, title, photoCaption } = props

  const assetLastUpdated = moment(lastUpdated).format('MMM DD, YYYY')

  const assetVersion = version !== undefined ? `• Revision ${version}` : ''
  const caption = version !== undefined && lastUpdated !== undefined ? `${assetLastUpdated}${photoCaption !== undefined ? ` • ${photoCaption}` : ''} ${assetVersion}` : ''

  return (
    <article onClick={typeof onClick === 'function' && mediaThumbnail !== undefined ? onClick : undefined} className={classNames(style.Card, isLandscape ? assetCardStyle['Card--Landscape'] : '',
      mediaThumbnail === undefined ? assetCardStyle['Card--NoMedia'] : ''
    )}>
      <TalentCardThumbnail isLandscape={isLandscape} mediaThumbnail={mediaThumbnail} hasVideo={hasVideo} />
      {status !== null && <DisplayTag status={status} />}
      <div className={style.Card__Link}>
        <div className={classNames(style.Card__Footer)}>
          <div className={style.Card__FooterTalentInfo}>
            <span className={style.Card__FooterTitleText}>{title}</span>
            <span className={style.Card__FooterSubtitleText}>{caption}</span>
          </div>
        </div>
      </div>
    </article>
  )
}
