import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../../../../components/button'
import { Colors, Fonts } from '../../../../constants'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px;
    height: 100%;
`

const Text = styled.span`
    font-family: ${Fonts.degular};
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    display: flex;
    align-items: center;
    align-self: flex-end;
    color: ${Colors.black};
`

const BottomWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
`

const ButtonTray = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const ButtonText = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.color};
`

const DeleteTalentImage = ({
  handleToggleDeleteImageSidebar,
  onDeleteHandler,
  loading
}) => {
  return (
        <Wrapper>
            <Text>Are you sure you want to delete this Image?</Text>
            <BottomWrapper>
                <ButtonTray>
                    <Button
                        height='40px'
                        minWidth='96px'
                        backgroundColor={Colors.white}
                        onClick={handleToggleDeleteImageSidebar} >
                        <ButtonText>CANCEL</ButtonText>
                    </Button>
                    <Button
                        height='40px'
                        minWidth='96px'
                        onClick={onDeleteHandler}
                        loading={loading} >
                        <ButtonText color={Colors.white}>DELETE</ButtonText>
                    </Button>
                </ButtonTray>
            </BottomWrapper>
        </Wrapper>
  )
}

export default DeleteTalentImage

DeleteTalentImage.propTypes = {
  handleToggleDeleteImageSidebar: PropTypes.func,
  loading: PropTypes.bool,
  onDeleteHandler: PropTypes.func
}
