import { useMutation } from '@apollo/client'
import { DELETE_SELECT } from '../../../services/API'

function useDeleteProjectMutation () {
  // mutation
  const [DeleteSelectMutation, { loading }] = useMutation(DELETE_SELECT, {
    fetchPolicy: 'no-cache'
  })

  const deleteProject = async (id, onSuccess, onError) => {
    await DeleteSelectMutation({
      variables: {
        id
      }
    })
      .then(res => onSuccess(res))
      .catch(e => onError())
  }
  return {
    deleteProject,
    loading
  }
}

export default useDeleteProjectMutation
