import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import DemographicAPI from '../../../services/DemographicAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'
import { convertFeetAndInchToHeight, convertValue, formatDate } from '../../../utils/Helpers'

function useUpdateDemographicMutation () {
  const dispatch = useDispatch()
  const [updateTalentDemographic, { loading }] = useMutation(
    DemographicAPI.talentDemographicUpdate(),
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )
  const UpdateDemographicMutation = async (props, cb) => {
    const {
      id,
      values,
      copyEthnicity,
      copyRace
    } = props
    const {
      birthdate,
      weight,
      haircolor,
      eyecolor,
      gender,
      genderexpression,
      race,
      ethnicity,
      sexualorientation,
      hfeet,
      hinch,
      customsexualorientation,
      country,
      bodytype
    } = values
    const modifiedRaceData = {}
    const modifiedEthnicityData = {}
    if (ethnicity?.length > 0) {
      for (const item of ethnicity) {
        const idx = copyEthnicity.findIndex(
          ethnicity => ethnicity?.value === item?.value
        )
        modifiedEthnicityData[copyEthnicity[idx].id] =
          copyEthnicity[idx]?.value
      }
    }
    if (race?.length > 0) {
      for (const item of race) {
        const idx = copyRace.findIndex(race => race?.value === item?.value)
        modifiedRaceData[copyRace[idx].id] = copyRace[idx]?.value
      }
    }

    try {
      const payload = {
        talentId: id,
        weight: weight ? convertValue(weight, 'FLOAT') : null,
        height: !hfeet
          ? null
          : convertFeetAndInchToHeight({
            feet: hfeet,
            inch: hinch
          }),
        birthDate: !birthdate ? null : formatDate(birthdate, 'YYYY-MM-DD'),
        hairColor: !haircolor ? null : isNaN(convertValue(haircolor, 'INTEGER')) ? null : convertValue(haircolor, 'INTEGER'),
        eyeColor: !eyecolor ? undefined : convertValue(eyecolor, 'INTEGER'),
        gender: !gender ? null : isNaN(convertValue(gender, 'INTEGER')) ? null : convertValue(gender, 'INTEGER'),
        genderExpression: !genderexpression ? null : isNaN(convertValue(genderexpression, 'INTEGER')) ? null : convertValue(genderexpression, 'INTEGER'),
        sexualOrientation: !sexualorientation
          ? null
          : customsexualorientation && !isNaN(convertValue(sexualorientation, 'INTEGER')) && convertValue(sexualorientation, 'INTEGER') === 5
            ? 0
            : convertValue(sexualorientation, 'INTEGER') < 0 ? null : convertValue(sexualorientation, 'INTEGER'),
        ethnicity: Object.values((modifiedEthnicityData)).length === 0 ? null : JSON.stringify(modifiedEthnicityData),
        race: Object.values((modifiedRaceData)).length === 0 ? null : JSON.stringify(modifiedRaceData),
        sexualOrientationOther: !customsexualorientation ? null : convertValue(sexualorientation, 'INTEGER') !== 5 ? '' : customsexualorientation ?? '',
        country: !country ? null : isNaN(convertValue(country, 'INTEGER')) ? null : convertValue(country, 'INTEGER'),
        bodyType: bodytype ? (isNaN(convertValue(bodytype, 'INTEGER')) ? null : convertValue(bodytype, 'INTEGER')) : undefined
      }
      await updateTalentDemographic({
        variables: payload
      })
      await cb()
      dispatch(
        showToast({
          message: 'Personal information updated',
          isError: false
        })
      )
    } catch (error) {
      dispatch(
        showToast({
          message: error?.message,
          isError: true
        })
      )
    }
  }

  return [UpdateDemographicMutation, { loading }]
}

export default useUpdateDemographicMutation
