import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import classNames from 'classnames'

import { getAssetsListProjectTalentStatus } from '../../apis/project'
import { GetProjectTalentSubmissions, RequestDownloadSubmission, UpdateProjectTalentSubmission, PatchUGCProjectTalentStatus } from '../../apis/ugc'
import { ProjectSubmissionCompressJobStatus, UGCTalentStatus } from '../../utils/ProjectHelper'
import { RenderIf } from '../../utils/Helpers'
import { SET_ASSETS_BOOKED_TALENTS, UGCDetailContext } from '../../contexts/project/ugcDetail'
import { setUGCAssetsDownloadJobs } from '../../redux/slices/ugcDownloadAssetSlice'
import { showToast } from '../../redux/actions/DashBoardActions'
import { stillImageOptions } from '../../constants/UGCConstants'
import { UGCAssetDetailDrawer } from '../UGCAssetDetailDrawer'
import { UGCAssetsTalentCard } from '../TalentCardV2'
import DownloadIcon from '../../assets/images/UGCProject/DownloadIcon.svg'
import style from './UGCAssetsMain.module.scss'

interface Specs {
  createdAt: string
  deliverableType: string
  duration?: number
  orientation: string
  projectId: string
  specs: string[]
  updatedAt?: string
}

interface Deliverable {
  id: string
  mediaType: string
  projectId: string
  specs?: Specs
  type: string
  updatedAt: string
  script?: null | string[]
  updatedBy: string
  createdAt: string
}

interface DeliverableSubmission {
  deliverableId: string
  version: number
  updatedAt: string
  feedback: string[] | null
  createdAt: string
  media: Media
  status: string
  mediaId: string
}

interface Media {
  uris: Uris
}

interface Uris {
  poster: string
  stream: string
  full: string
}

interface IhandleUpdateDeliverableStatusParams {
  deliverableVersion: number
  status: string
  deliverableId: string
  feedback?: string[]
}

const sortByAssetVersion = (a: DeliverableSubmission, b: DeliverableSubmission): number => a?.version > b?.version ? -1 : 1

const tabData = [
  {
    title: 'Intro Videos',
    route: 'intro_hook'
  },
  {
    title: 'Primary Videos',
    route: 'main'
  },
  {
    title: 'Still Images',
    route: 'photo'
  },
  {
    title: 'B-Roll',
    route: 'b_roll'
  }
]

const Shimmers = ({ loading, count, isLandscape = false }: { loading: boolean, count: number, isLandscape?: boolean }): JSX.Element => {
  if (!loading) {
    return <></>
  }

  const shimmers = []
  for (let i = 0; i < count; i++) {
    shimmers.push(<Shimmer isLandscape={isLandscape} key={`shimmer-${i}`} />)
  }

  return <>{shimmers}</>
}

const Shimmer = ({ isLandscape = false }: { isLandscape: boolean }): JSX.Element => {
  return (
    <div className={classNames(style.Main__Skeleton, isLandscape ? style['Main__Skeleton--Landscape'] : '')}>
      <Skeleton variant="rectangular" />
    </div>
  )
}

const RenderDeliverableTab = (): JSX.Element => {
  // INIT
  const { innerTab, talentId, id } = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.UserReducer)
  const [{ overview, assetsBookedTalents }, contextDispatch] = useContext(UGCDetailContext)

  // API CALLS
  const { data, loading } = useQuery(GetProjectTalentSubmissions, { variables: { projectId: id, talentId, deliverableType: [innerTab] }, notifyOnNetworkStatusChange: true })
  const [fetchTalentAllSubmissions] = useLazyQuery(GetProjectTalentSubmissions)
  const [patchUGCProjectTalentStatus, { loading: patchUGCProjectTalentStatusLoading }] = useMutation(PatchUGCProjectTalentStatus)

  const [updateProjectTalentSubmission, { loading: updateProjectTalentSubmissionLoading }] = useMutation(UpdateProjectTalentSubmission, {
    refetchQueries: [
      { query: GetProjectTalentSubmissions, variables: { projectId: id, talentId, deliverableType: [innerTab] } },
      { query: getAssetsListProjectTalentStatus, variables: { projectId: id, statuses: ['booking_confirmed', 'wrapped'], offset: 0, limit: 100 } }
    ]
  })

  // STATES
  const [clubbedDeliverables, setClubbedDeliverables] = useState<DeliverableSubmission[][]>([])
  const [isAssetDetailOpen, setIsAssetDetailOpen] = useState(false)
  const [clickedDeliverableIndex, setClickedDeliverableIndex] = useState<number | null>(null)

  // CONSTANTS
  const { iteration, deliverables } = overview
  const isLandscape = overview.deliverables.find((item) => item.type === 'main')?.specs?.orientation === 'landscape'
  const audition = overview?.deliverables?.filter((item) => item.type === 'audition')
  const totalProjectAssetCount = audition.length > 0 ? overview?.deliverables.length - audition.length : overview?.deliverables.length
  const imageOptions = stillImageOptions.map((item) => item.label)

  const introHookDeliverable = deliverables?.filter((item) => item.type === 'intro_hook')
  const mainDeliverable = deliverables?.filter((item) => item.type === 'main')
  const photoDeliverable = deliverables?.filter((item) => item.type === 'photo')
  const bRollDeliverable = deliverables?.filter((item) => item.type === 'b_roll')

  // Effect that responsible for clubbing the deliverables
  useEffect(() => {
    setClubbedDeliverables([])
    if (!loading && !overview?.loading) {
      handleClubbedDeliverables([...data?.getProjectTalentSubmissions ?? []])
    }
  }, [loading, data?.getProjectTalentSubmissions, overview?.loading])

  /**
   * @description Function that responsible for clubbing the deliverables because each delibverable has multiple versions
   * @param array Deliverables[]
   */
  const handleClubbedDeliverables = (array: DeliverableSubmission[]): void => {
    const result: any = getActiveDeliverable() ?? {}

    for (const obj of array) {
      const { deliverableId } = obj
      if (result[deliverableId] !== undefined) {
        result[deliverableId].push(obj)
      } else {
        result[deliverableId] = [obj]
      }
    }

    // Sorting the deliverables by version
    const sortedDeliverabled = Object.values(result).map((item: any) => (item ?? [])?.sort(sortByAssetVersion))
    setClubbedDeliverables([...sortedDeliverabled])
  }

  /**
  * @description Function that responsible for getting the active deliverable
  * @returns Deliverable
  * */
  const getActiveDeliverable = (): Record<string, []> => {
    const formatDeliverable = (deliverables: Deliverable[]): Record<string, []> => {
      return deliverables.sort((a: { createdAt: string }, b: { createdAt: string }) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .reduce((acc: any, item: Deliverable) => {
          acc[item.id] = []
          return acc
        }, {})
    }

    switch (innerTab) {
      case 'main':
        return formatDeliverable(mainDeliverable)
      case 'intro_hook':
        return formatDeliverable(introHookDeliverable)
      case 'photo':
        return formatDeliverable(photoDeliverable)
      case 'b_roll':
        return formatDeliverable(bRollDeliverable)
      default:
        return formatDeliverable([])
    }
  }

  /**
   * @description Function that responsible for updating the deliverable status
   * @param deliverableId string
   * @param status string
   */
  const handleDeliverableOnClick = (clickedDeliverableIndex: number): void => {
    setClickedDeliverableIndex(clickedDeliverableIndex)
    setIsAssetDetailOpen(true)
  }

  const countUniqueApprovedDeliverables = (deliverables: DeliverableSubmission[]): number => {
    // Create an object to store the counts of each unique deliverableId
    const deliverableCounts: Record<string, number> = {}

    // Iterate through the array of deliverables
    for (const deliverable of deliverables) {
      const deliverableId = deliverable.deliverableId
      const status = deliverable.status

      // Check if the deliverable has an "approved" status
      if (status === 'approved') {
        // Check if the deliverableId already exists in the object, if not, initialize its count to 1
        if (!Object.prototype.hasOwnProperty.call(deliverableCounts, deliverableId)) {
          deliverableCounts[deliverableId] = 1
        } else {
          // If the deliverableId exists, increment its count by 1
          deliverableCounts[deliverableId]++
        }
      }
    }

    // Filter out the deliverables that have at least one approved status
    const approvedDeliverableIds = Object.keys(deliverableCounts)

    // Calculate the total count of unique deliverables with at least one approved status
    const uniqueApprovedDeliverablesCount = approvedDeliverableIds.length

    return uniqueApprovedDeliverablesCount
  }

  const handleUpdateAssetsTalentsMetadata = (assetsBookedTalents: any[], status: string): any[] => {
    return assetsBookedTalents.map((bookedTalent) => {
      if (bookedTalent.id === talentId && status === 'approved') {
        bookedTalent.assetApprovedCount = Number(bookedTalent.assetApprovedCount) + 1
        if (Number(bookedTalent.inReviewCount) > 0) {
          bookedTalent.inReviewCount = Number(bookedTalent.inReviewCount) - 1
        }

        if (bookedTalent.inReviewCount === 0 && bookedTalent.assetApprovedCount === totalProjectAssetCount) {
          bookedTalent.allApproved = true
        }
      } else if (bookedTalent.id === talentId && status === 'rejected') {
        if (Number(bookedTalent.inReviewCount) > 0) {
          bookedTalent.inReviewCount = Number(bookedTalent.inReviewCount) - 1
        }
      }
      return bookedTalent
    })
  }

  /**
   * @description Function that responsible for updating the deliverable status
   * @param IhandleUpdateDeliverableStatusParams
   * @param cb function
   * @returns void
   */
  const handleUpdateDeliverable = async (params: IhandleUpdateDeliverableStatusParams, cb?: () => void): Promise<void> => {
    let uniqueApprovedDeliverablesCount = 0
    try {
      const talentAllSubmissions = await fetchTalentAllSubmissions({ variables: { projectId: id, talentId }, fetchPolicy: 'no-cache' })

      uniqueApprovedDeliverablesCount = countUniqueApprovedDeliverables(talentAllSubmissions?.data?.getProjectTalentSubmissions ?? [])

      if ((uniqueApprovedDeliverablesCount + 1 === totalProjectAssetCount) && params?.status === 'approved') {
        // marking talent status wrapped when last deliverable is approved
        const currentTalentIndex = assetsBookedTalents.findIndex((item: any) => item.id === talentId)
        assetsBookedTalents[currentTalentIndex].status = UGCTalentStatus.wrapped
        await patchUGCProjectTalentStatus({ variables: { projectId: id, talentId, status: UGCTalentStatus.wrapped } })
      }

      await updateProjectTalentSubmission({
        variables: { ...params, talentId },
        onCompleted: () => {
          // handling sidebar deliverables count
          const updatedAssetsBookedTalentsMetadata = handleUpdateAssetsTalentsMetadata(assetsBookedTalents, params?.status)

          contextDispatch({ type: SET_ASSETS_BOOKED_TALENTS, payload: [...updatedAssetsBookedTalentsMetadata] })

          if (typeof cb === 'function') {
            cb()
          }
        }
      })
    } catch (error: any) {
      dispatch(showToast({ message: error?.message ?? 'Something went wrong', isError: true }))

      // reverting back the status if error occurs
      if ((uniqueApprovedDeliverablesCount + 1 === totalProjectAssetCount) && params?.status === 'approved') {
        await patchUGCProjectTalentStatus({ variables: { projectId: id, talentId, status: UGCTalentStatus.bookingConfirmed } })
      }
    }
  }

  return (
    <>
      <div className={classNames(style.Main__VideoContent, isLandscape && (innerTab !== 'photo') ? style['Main__VideoContent--Landscape'] : '')}>
        <Shimmers count={4} loading={loading} isLandscape={innerTab === 'photo' ? false : isLandscape} />
        {clubbedDeliverables?.map((item: DeliverableSubmission[], index: number) => {
          const approvedDeliverableIndex = item?.findIndex((item: DeliverableSubmission) => item.status === 'approved') === -1 ? 0 : item?.findIndex((item: DeliverableSubmission) => item.status === 'approved')
          const photoDirection = photoDeliverable.find((deliverable) => deliverable?.id === item[0]?.deliverableId)?.stillSpecs?.directions ?? []

          const photoCaption = photoDirection !== undefined
            ? imageOptions.includes(photoDirection?.[0] ?? '') ? photoDirection?.[0] : 'Other'
            : undefined

          return (
            <UGCAssetsTalentCard
              key={item?.[approvedDeliverableIndex]?.deliverableId ?? index}
              hasVideo={innerTab !== 'photo'}
              photoCaption={photoCaption}
              lastUpdated={item[approvedDeliverableIndex]?.updatedAt}
              mediaThumbnail={innerTab === 'photo' ? item[approvedDeliverableIndex]?.media?.uris?.full : item[approvedDeliverableIndex]?.media?.uris?.poster}
              onClick={() => { handleDeliverableOnClick(index) }}
              status={item[approvedDeliverableIndex]?.status !== undefined ? item[approvedDeliverableIndex]?.status : 'not_submitted'}
              isLandscape={innerTab === 'photo' ? false : isLandscape}
              title={(innerTab === 'photo' ? `Photo ${(index + 1)}` : `Video ${(index + 1)}`)}
              version={item[approvedDeliverableIndex]?.version}
            />
          )
        })}
      </div>
      {/* Passing key to the component is important because it will re-render the component when the key changes as it reset the states which is inside this component */}
      <UGCAssetDetailDrawer
        key={clickedDeliverableIndex}
        deliverableData={clubbedDeliverables[clickedDeliverableIndex ?? 0]} open={isAssetDetailOpen}
        handleCloseDrawer={() => { setClickedDeliverableIndex(null); setIsAssetDetailOpen(false) }}
        handleUpdateDeliverable={(payload, cb) => { void handleUpdateDeliverable(payload, cb) }}
        isAdmin={user?.isAdmin}
        isLandscape={innerTab === 'photo' ? false : isLandscape}
        isPhoto={innerTab === 'photo'}
        assetNumber={Number(clickedDeliverableIndex) + 1}
        iteration={iteration}
        updateProjectTalentSubmissionLoading={updateProjectTalentSubmissionLoading || patchUGCProjectTalentStatusLoading}
      />
    </>
  )
}

/**
  * @description Component that responsible for rendering the tab
  * @returns JSX.Element
*/
const AssetsTab = (): JSX.Element => {
  // init
  const { id, activeTab, talentId, innerTab } = useParams()
  const dispatch = useDispatch()
  const [{ overview, assetsBookedTalents }] = useContext(UGCDetailContext)
  const { downloadAssetsJobs } = useSelector((state: any) => state.UGCDownloadAssetsReducer)
  const isCurrentTalentAssetJobPending = downloadAssetsJobs?.find((item: any) => item.talentId === talentId)?.status === ProjectSubmissionCompressJobStatus.pending

  const activeTalentInfo = assetsBookedTalents?.find((item: any) => item.id === talentId)

  // api calls
  const [requestDownloadSubmission, { loading: requestDownloadSubmissionLoading }] = useMutation(RequestDownloadSubmission, { notifyOnNetworkStatusChange: true })

  const deliverableIntroHookCount = overview?.deliverables?.filter((item) => item.type === 'intro_hook')?.length
  const deliverableMainCount = overview?.deliverables?.filter((item) => item.type === 'main')?.length
  const deliverablePhotoCount = overview?.deliverables?.filter((item) => item.type === 'photo')?.length
  const deliverableBRollCount = overview?.deliverables?.filter((item) => item.type === 'b_roll')?.length

  const deliverableCount: Record<string, number> = {
    intro_hook: deliverableIntroHookCount,
    main: deliverableMainCount,
    photo: deliverablePhotoCount,
    b_roll: deliverableBRollCount
  }

  const TabShimmer = []

  for (let i = 0; i < 4; i++) {
    TabShimmer.push(<Skeleton variant='rectangular' key={`shimmer-${i}`} />)
  }

  const handleStartDownload = async (): Promise<void> => {
    try {
      const isCurrentJobExist = downloadAssetsJobs?.find((item: any) => item.talentId === talentId) !== undefined

      // inserting it into redux if job does not exist
      if (!isCurrentJobExist) {
        const response = await requestDownloadSubmission({ variables: { projectId: id, talentId } })
        const { jobId } = response?.data?.requestDownloadSubmissions

        const payload = { talentId, status: ProjectSubmissionCompressJobStatus.pending, jobId }
        const updatedDownloadAssetsJobs = [...downloadAssetsJobs, payload]
        dispatch(setUGCAssetsDownloadJobs([...updatedDownloadAssetsJobs]))
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (overview?.loading) {
    return (
      <div className={style.AssetsTab}>
        {TabShimmer}
      </div>
    )
  }

  return (
    <div className={style.AssetsTab}>
      <div className={style.AssetsTab__ItemContainer}>
        {tabData.map((tab) => {
          if (deliverableCount[tab.route] > 0) {
            return <Link replace to={id !== undefined && activeTab !== undefined ? `/projects/ugc/view/${id}/${activeTab}/${talentId ?? ''}/${tab.route}` : '#'} key={tab.route} className={classNames(style.AssetsTab__Item, innerTab === tab.route ? style['AssetsTab__Item--Active'] : '')}>{tab.title}</Link>
          } else {
            return <Fragment key={tab.route} />
          }
        })}
      </div>
      <RenderIf isTrue={activeTalentInfo?.status === UGCTalentStatus.wrapped}>
        <button onClick={() => { void handleStartDownload() }} className={classNames('primary-button', requestDownloadSubmissionLoading || isCurrentTalentAssetJobPending ? 'primary-button--Disabled' : '')}><img src={DownloadIcon} /> {requestDownloadSubmissionLoading ? 'Download Pending' : 'Download assets'}</button>
      </RenderIf>
    </div>
  )
}

export const UGCAssetsMain = (): JSX.Element => {
  return (
    <main className={style.Main}>
      <AssetsTab />
      <RenderDeliverableTab />
    </main>
  )
}
