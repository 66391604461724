import { type ReactNode, createContext, type Dispatch, useState } from 'react'

export interface IUGCSidebar {
  isCollapsable: boolean
  title: string
  isError: boolean
  route: string
  isCompleted: boolean
  collapsableData?: Array<{ title: string, isActive: boolean, route: string }>
  isVisible: boolean
}

export interface IUGCSidebarActions {
  isCollapsable?: boolean
  title?: string
  isError?: boolean
  route?: string
  isCompleted?: boolean
  collapsableData?: Array<{ title: string, isActive: boolean, route: string }> | undefined
  isVisible?: boolean
}

export const UGCSidebarInitialData = [
  {
    isCollapsable: false,
    title: 'Project Details',
    isError: false,
    route: 'details',
    isCompleted: false,
    isVisible: true
  },
  {
    isCollapsable: false,
    title: 'Directions',
    isError: false,
    route: 'directions',
    isCompleted: false,
    isVisible: true
  },
  {
    isCompleted: false,
    isCollapsable: true,
    title: 'Deliverables',
    route: 'deliverables',
    isVisible: true,
    isError: false,
    collapsableData: [
      {
        title: 'Videos',
        isActive: false,
        route: 'deliverables#videos'
      },
      {
        title: 'Additionals',
        isActive: true,
        route: 'deliverables#additionals'
      }
    ]
  },
  {
    isCollapsable: false,
    title: 'Talent',
    isError: false,
    isCompleted: false,
    route: 'talent',
    isVisible: true
  },
  {
    isCollapsable: false,
    isCompleted: false,
    title: 'Product',
    isError: false,
    route: 'product',
    isVisible: false

  }
]
export const UGCSidebarContext = createContext<[IUGCSidebar[], Dispatch<IUGCSidebar[]>]>([UGCSidebarInitialData, () => {}])

interface SidebarContextProps {
  children: ReactNode
}

export const UGCSidebarProvider = ({ children }: SidebarContextProps): JSX.Element => {
  const [sidebarData, updateSidebarData] = useState(UGCSidebarInitialData)

  return (
    <UGCSidebarContext.Provider value={[sidebarData, updateSidebarData]}>
      {children}
    </UGCSidebarContext.Provider>
  )
}
