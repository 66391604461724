import { useState } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Box, Slider } from '@mui/material'

import { Button } from '../buttonV2'
import { Typography } from '../Typography'
import { RenderIf } from '../../utils/Helpers'
import { Colors, Fonts } from '../../constants'

const useStyles = makeStyles({
  root: {
    '&>.MuiSlider-rail': {
      background: '#9E9E9E'
    },
    '&>.MuiSlider-track': {
      background: '#9E9E9E',
      border: 'none'
    },
    '&>.MuiSlider-thumb': {
      background: '#000',
      height: 12,
      width: 12
    },
    '&>.MuiSlider-mark': {
      background: '#000'
    },
    '&>.MuiSlider-markLabel': {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: Fonts.degular
    },
    '&>.MuiSlider-valueLabel': {
      left: 'calc(-50% + 12px)',
      top: '-22px',
      '& *': {
        background: 'transparent',
        color: '#000'
      }
    }
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: '-22px',
    '& *': {
      background: 'transparent',
      color: '#000'
    }
  }
})

export const CustomizedSlider = props => {
  // mui style class
  const classes = useStyles()

  // states
  const [isRst, setIsRst] = useState(false)

  // props
  const {
    label,
    marks,
    max,
    defaultSelection,
    setSelection,
    isReset,
    handleClearClick,
    showClear,
    handleShowClear
  } = props

  // functions
  const getNewHeight = data => {
    const realFeet = data
    const feet = Math.floor(realFeet)
    const inches = Math.round((realFeet - feet) * 11)
    return `${feet}.${inches}`
  }

  const handleChange = (event, newValue) => {
    if (label === 'Height (Feet)') {
      const leftValue = newValue[0]
      const rightValue = newValue[1]
      setSelection([getNewHeight(leftValue), getNewHeight(rightValue)])
    } else {
      setSelection(newValue)
    }
  }

  const getModifiedLabel = (title, value) => {
    switch (title) {
      case 'Age (Years)':
        return value === 100 ? '100+' : value
      case 'Weight (Pounds)':
        return value === 220 ? '220+' : value
      case 'Height (Feet)':
        return value === 8 ? '8+' : value
      default:
        return value
    }
  }

  const convertHeightValue = (data) => {
    if (data) {
      const realValue = data.split('.')[0]
      const decimalValue = data.split('.')[1]

      if (decimalValue === '5') {
        return `${realValue}.48`
      } else if (decimalValue === '6') {
        return `${realValue}.58`
      } else if (decimalValue === '7') {
        return `${realValue}.68`
      } else if (decimalValue === '8') {
        return `${realValue}.76`
      } else if (decimalValue === '9') {
        return `${realValue}.88`
      } else if (decimalValue === '10') {
        return `${realValue}.93`
      } else if (decimalValue === '11') return `${realValue}.96`
      else return data
    }
  }

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography fontSize={16} lineHeight='24px' letterSpacing='0.15px' style={{ marginBottom: '15px' }}>{label}</Typography>
      <RenderIf isTrue={showClear}>
        <Button
          outline
          backgroundColor='white'
          width='fit-content'
          height='15px'
          style={{ padding: 0, marginTop: 2 }}
          onClick={() => {
            handleClearClick()
            setIsRst(!isRst)
          }}>
          <Typography
          fontSize={14}
          lineHeight='21px'
          letterSpacing='0.15px'
          color={Colors.errorRed}
          style={{ textDecoration: 'underline' }}
          >Clear</Typography>
        </Button>
      </RenderIf>
      </Box>
      <Slider
        defaultValue={ label === 'Height (Feet)' ? [convertHeightValue(String(defaultSelection[0])), convertHeightValue(String(defaultSelection[1])) ?? ''] : defaultSelection}
        step={label === 'Height (Feet)' ? 0.01 : 1}
        valueLabelDisplay="auto"
        min={label === 'Height (Feet)' ? 1 : 0}
        max={max}
        size="small"
        className={classes.root}
        onChange={handleChange}
        marks={marks}
        key={isReset || isRst}
        onChangeCommitted={() => handleShowClear()}
        scale={x => {
          if (label === 'Height (Feet)') {
            const realFeet = x
            const feet = Math.floor(realFeet)
            const inches = Math.round((realFeet - feet) * 11)
            return `${feet}.${inches}`
          } else return x
        }}
        valueLabelFormat={value => <div>{getModifiedLabel(label, value)}</div>}
      />
    </div>
  )
}

CustomizedSlider.propTypes = {
  label: PropTypes.string,
  marks: PropTypes.array,
  max: PropTypes.number,
  defaultSelection: PropTypes.array,
  setSelection: PropTypes.func,
  isReset: PropTypes.bool,
  handleClearClick: PropTypes.func,
  showClear: PropTypes.bool,
  handleShowClear: PropTypes.func
}
