export const PORTFOLIO_ALLOWED_IMAGE_EXTENSION = '.heic, .png, .jpeg, .jpg'

export const PORTFOLIO_ALLOWED_DRAGZONE_IMAGE_EXTENSION = {
  'image/*': ['.png', '.jpeg', '.jpg', '.heic']
}

export const PORTFOLIO_ALLOWED_VIDEO_EXTENSION = ['.mp4', '.mov', '.mkv', '.webm']

// 5mb
export const PORTFOLIO_MAX_IMG_SIZE = 5000000
export const MULTIPART_MIN_CHUNK_SIZE = 5242880

export const POSTER_PROCESSING_STATUS = {
  PENDING: 'pending',
  ERROR: 'errored',
  COMPLETE: 'complete',
  PROCESSING: 'processing'
}
