import { Skeleton } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { getListSelects, getListProjectTalentStatus, listTalentSubmissions, selectUpdate } from '../../../../apis/project'
import { InterestedTalentSubmissionDrawer } from '../../../InterestedTalentSubmissionDrawer'
import { RenderIf } from '../../../../utils/Helpers'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { type IProjectTalentStatus, UGCDetailContext, SET_INTERESTED_TALENTS } from '../../../../contexts/project/ugcDetail'
import { UGCAddTalentToSelect } from '../../../UGCAddTalentToSelect'
import { whatThumbnailToUse, UGCTalentCard } from '../../../TalentCardV2'
import style from './InterestedTalent.module.scss'
import { UGCTalentStatus } from '../../../../utils/ProjectHelper'

const INTERESTED_STATUS_TALENT_LIMIT = 40

const createdAtSort = (a: { createdAt: string }, b: { createdAt: string }): number => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()

const minMaxSort = (a: { min: number, max: number }, b: { min: number, max: number }): number => { return (a?.min + a?.max) - (b?.min + b?.max) }

const formatProjectRoles = (item: any, index: number, selectListData: { listSelects: Array<{ id: string, talents: string[] }> }): any => ({
  // ROLE INFORMATION
  title: `Profile ${index + 1}`,
  subData: [
    { title: 'Age ranges', chips: item.criteria?.ageRanges === null ? ['Any'] : item.criteria?.ageRanges?.slice().sort(minMaxSort).map((ageRange: { min: number, max: number }) => `${ageRange.min} - ${ageRange.max}`) ?? ['-'] },
    { title: 'One or more genders', chips: item?.criteria?.gender === null ? ['Any'] : item?.criteria?.gender ?? ['-'] },
    { title: 'One or more ethnicities', chips: item?.criteria?.ethnicity === null ? ['Any'] : item?.criteria?.ethnicity ?? ['-'] }
  ],
  selectId: item.selectId,
  exisitingTalents: selectListData?.listSelects?.find((select: { id: string }) => select.id === item?.selectId)?.talents ?? [],

  // INTERFACE STATE
  isOpen: false,
  isSelected: false,
  selectedTalentId: null
})

const Shimmers = ({ loading, count, isLandscape = false }: { loading: boolean, count: number, isLandscape?: boolean }): JSX.Element => {
  if (!loading) {
    return <></>
  }

  const shimmers = []
  for (let i = 0; i < count; i++) {
    shimmers.push(<Shimmer isLandscape={isLandscape} key={`shimmer-${i}`} />)
  }

  return <>{shimmers}</>
}

interface IProfileData {
  isOpen: boolean
  isSelected: boolean
  title: string
  selectId: string
  selectedTalentId: string | null
  exisitingTalents: string[]
  subData: Array<{
    title: string
    chips: string[]
  }>
}

const Shimmer = ({ isLandscape }: { isLandscape: boolean }): JSX.Element => {
  return (
    <div className={classNames(style.InterestedTalent__Shimmer, isLandscape ? style['InterestedTalent__Shimmer--Landscape'] : '')}>
      <Skeleton variant="rectangular" />
    </div>
  )
}

export const sortTalents = (data: IProjectTalentStatus[]): any => {
  const interested = []
  const bookingConfirmed = []
  const bookingRequested = []
  const wrapped = []
  const paid = []
  const selected = []

  for (const item of data) {
    switch (item.status) {
      case 'wrapped':
        wrapped.push(item)
        break
      case 'booking_requested':
        bookingRequested.push(item)
        break
      case 'selected':
        selected.push(item)
        break
      case 'booking_confirmed':
        bookingConfirmed.push(item)
        break
      case 'interested':
        interested.push(item)
        break
      case 'paid':
        paid.push(item)
        break
      default:
        break
    }
  }

  return [...wrapped, ...bookingConfirmed, ...bookingRequested, ...selected, ...interested, ...paid]
}
export const InterestedTalent = (): JSX.Element => {
  // INIT
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // PROJECT INFO
  const { id } = useParams()
  const [{ overview, interestedTalents }, UGCContextDispatch] = useContext(UGCDetailContext)

  // AUDITION INFO
  const hasAudition = overview.deliverables.find((item) => item.type === 'audition') !== undefined
  const auditionId = overview.deliverables.find((item) => item.type === 'audition')?.id
  const isLandscape = overview.deliverables.find((item) => item.type === 'audition') !== undefined ? overview.deliverables.find((item) => item.type === 'main')?.specs?.orientation === 'landscape' : false

  // LOCAL STATES
  const [hasNextPageCalled, setHasNextPageCalled] = useState<boolean>(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [islistProjectTalentStatusFetched, setIslistProjectTalentStatusFetched] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [activeProfileId, setActiveProfileId] = useState<string>('')
  const [profileData, setProfileData] = useState<IProfileData[]>([])
  const [activeProfileIndex, setActiveProfileIndex] = useState<number>(1)
  const [isAddToSelectSidebarVisible, setIsAddToSelectSidebarVisible] = useState(false)
  const [selectedTalents, setSelectedTalents] = useState<string[]>([])
  const [unusedTalentSubmission, setUnusedTalentSubmission] = useState<any[]>([])

  // API CALLS
  const [getListProjectTalentData] = useLazyQuery(getListProjectTalentStatus, { fetchPolicy: 'no-cache' })
  const [updateSelectMutation, { loading: updateSelectLoading }] = useMutation(selectUpdate, { notifyOnNetworkStatusChange: true })
  const [getTalentSubmission] = useLazyQuery(listTalentSubmissions, { fetchPolicy: 'no-cache', variables: { deliverableId: auditionId, offset: 0, limit: INTERESTED_STATUS_TALENT_LIMIT } })
  const { data: selectListData, loading: selectedListLoading } = useQuery(getListSelects, { variables: { parentId: overview?.selectId }, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache', skip: (overview?.selectId) === '' })

  // Format project role into actionable state
  useEffect(() => {
    if (overview?.roles?.length > 0 && !selectedListLoading) {
      const rolesCopy = overview?.roles?.slice()

      const projectProfiles = rolesCopy.sort(createdAtSort).map((item, index) => formatProjectRoles(item, index, selectListData))
      setProfileData(projectProfiles)
    }
  }, [overview.roles, selectedListLoading])

  // Fetching talent status after list of selected talents is fetched
  useEffect(() => {
    if (!selectedListLoading && overview?.selectId !== '') {
      void fetchProjectTalentStatus()
    }
  }, [selectedListLoading, selectedTalents])

  // Initialise the list of selected talent in the state after api call
  useEffect(() => {
    if (!selectedListLoading && selectListData?.listSelects?.length > 0) {
      const talentsList: string[] = []
      selectListData?.listSelects.forEach((item: { talents: string[] }) => {
        for (const iterator of item.talents) {
          talentsList.push(iterator)
        }
      })
      setSelectedTalents(talentsList)
    }
  }, [selectedListLoading])

  // Calling next page automatically after fetching the initial data
  useEffect(() => {
    if (hasNextPageCalled) {
      void fetchNextBatch()
    }
  }, [interestedTalents.length])

  useEffect(() => {
    if (islistProjectTalentStatusFetched && unusedTalentSubmission?.length > 0) {
      const listData = interestedTalents?.map((item: IProjectTalentStatus) => {
        const auditionSubmissions = unusedTalentSubmission?.find((submission: any) => submission?.talentId === item.talentId)?.media
        return ({ ...item, status: selectedTalents.includes(item.talentId) ? 'selected' : item.status, talent: item?.talent === null ? null : { ...item.talent, auditionSubmissions: auditionSubmissions ?? item?.talent?.auditionSubmissions } })
      }) ?? []
      UGCContextDispatch({ type: SET_INTERESTED_TALENTS, payload: sortTalents(listData) })
    }
  }, [islistProjectTalentStatusFetched])

  const handleToggleProfile = (index: number): void => {
    profileData[index].isOpen = !profileData[index].isOpen
    setProfileData([...profileData])
  }

  const handleChecboxChange = (index: number): void => {
    profileData[index].isSelected = !profileData[index].isSelected
    setProfileData([...profileData])
  }

  const getTalentStatus = (isSelected: boolean, talentStatus: string): string => {
    const isTalentInterested = talentStatus === UGCTalentStatus.interested
    if (isSelected && isTalentInterested) {
      return 'selected'
    } else {
      return talentStatus
    }
  }

  /**
   * @description Fetching talents status first batch via useeffect and this called next batch automatically
   */
  const fetchProjectTalentStatus = async (): Promise<void> => {
    setInitialLoading(true)
    try {
      const projectTalentStatus = await getListProjectTalentData({ variables: { projectId: id, offset: 0, limit: INTERESTED_STATUS_TALENT_LIMIT }, fetchPolicy: 'no-cache' })
      let listData = []
      if (hasAudition) {
        const talentSubmissionResponse = await getTalentSubmission({ variables: { deliverableId: auditionId, offset: 0, limit: INTERESTED_STATUS_TALENT_LIMIT } })
        listData = projectTalentStatus?.data?.listProjectTalentStatus?.map((item: IProjectTalentStatus) => {
          const auditionSubmissions = talentSubmissionResponse?.data?.listTalentSubmission?.find((submission: any) => submission?.talentId === item?.talentId)?.media ?? null
          return ({ ...item, status: getTalentStatus(selectedTalents.includes(item.talentId), item.status), talent: item.talent !== null ? { ...item.talent, auditionSubmissions } : null })
        }) ?? []
        const listProjectTalentStatusTalentIds = projectTalentStatus?.data?.listProjectTalentStatus?.map((item: IProjectTalentStatus) => item.talentId) ?? []
        const unusedTalentSubmissionData = talentSubmissionResponse?.data?.listTalentSubmission?.filter((submission: { talentId: string }) => listProjectTalentStatusTalentIds.includes(submission?.talentId) === false) ?? []
        if (unusedTalentSubmissionData?.length > 0) {
          setUnusedTalentSubmission(unusedTalentSubmissionData)
        }
      } else {
        listData = projectTalentStatus?.data?.listProjectTalentStatus?.map((item: IProjectTalentStatus) => ({ ...item, status: getTalentStatus(selectedTalents.includes(item.talentId), item.status), auditionSubmissions: null })) ?? []
      }

      UGCContextDispatch({ type: SET_INTERESTED_TALENTS, payload: sortTalents(listData) })
      if (listData.length === INTERESTED_STATUS_TALENT_LIMIT) {
        setHasNextPageCalled(true)
      } else {
        setIslistProjectTalentStatusFetched(true)
      }
    } catch (error: any) {
      dispatch(
        showToast({
          isError: true,
          message: error?.message ?? 'Something went wrong'
        })
      )
    }
    setInitialLoading(false)
  }

  /**
   * @description Fetching next batch of talents
   */
  const fetchNextBatch = async (): Promise<void> => {
    try {
      const projectTalentStatus = await getListProjectTalentData({ variables: { projectId: id, offset: interestedTalents.length, limit: INTERESTED_STATUS_TALENT_LIMIT }, fetchPolicy: 'no-cache' })
      let listData = []
      if (hasAudition) {
        const talentSubmissionResponse = await getTalentSubmission({ variables: { deliverableId: auditionId, offset: interestedTalents.length, limit: INTERESTED_STATUS_TALENT_LIMIT } })
        listData = projectTalentStatus?.data?.listProjectTalentStatus?.map((item: IProjectTalentStatus) => {
          const auditionSubmissions = talentSubmissionResponse?.data?.listTalentSubmission?.find((submission: any) => submission?.talentId === item?.talentId)?.media ?? null
          return ({ ...item, status: getTalentStatus(selectedTalents.includes(item.talentId), item.status), talent: item.talent !== null ? { ...item.talent, auditionSubmissions } : null })
        }) ?? []
        const listProjectTalentStatusTalentIds = projectTalentStatus?.data?.listProjectTalentStatus?.map((item: IProjectTalentStatus) => item.talentId) ?? []
        const unusedTalentSubmissionData = talentSubmissionResponse?.data?.listTalentSubmission?.filter((submission: { talentId: string }) => listProjectTalentStatusTalentIds.includes(submission?.talentId) === false) ?? []
        if (unusedTalentSubmissionData?.length > 0) {
          setUnusedTalentSubmission([...unusedTalentSubmission, ...unusedTalentSubmissionData])
        }
      } else {
        listData = projectTalentStatus?.data?.listProjectTalentStatus?.map((item: IProjectTalentStatus) => ({ ...item, status: getTalentStatus(selectedTalents.includes(item.talentId), item.status), auditionSubmissions: null })) ?? []
      }
      if (listData.length === INTERESTED_STATUS_TALENT_LIMIT) {
        setHasNextPageCalled(true)
      } else {
        setHasNextPageCalled(false)
        setIslistProjectTalentStatusFetched(true)
      }
      UGCContextDispatch({ type: SET_INTERESTED_TALENTS, payload: sortTalents([...interestedTalents, ...listData]) })
    } catch (error: any) {
      dispatch(
        showToast({
          isError: true,
          message: error?.message ?? 'Something went wrong'
        })
      )
    }
  }

  const handleNavigateToSelects = (): void => { navigate(`/selects/${overview.archived ? 'archive' : 'active'}/${overview.selectId}/sub-select`) }

  const handleAddToSelect = async (): Promise<void> => {
    const { talentId } = interestedTalents[activeProfileIndex]
    try {
      for (const iterator of profileData) {
        if (iterator.isSelected && iterator.selectedTalentId !== null) {
          await updateSelectMutation({
            variables: {
              selectUpdateId: iterator.selectId,
              talents: [...iterator.exisitingTalents, iterator.selectedTalentId]
            }
          })
          const profileToBeUpdateIndex = profileData.findIndex((item) => item.selectId === iterator.selectId)
          profileData[profileToBeUpdateIndex].exisitingTalents.push(iterator.selectedTalentId)
          setProfileData(profileData)
        } else if (iterator.selectedTalentId !== null) {
          const isTalentAlreadyExist = iterator.exisitingTalents.includes(iterator.selectedTalentId)
          if (isTalentAlreadyExist) {
            const updatedExistingTalent = iterator.exisitingTalents.filter((talent) => talent !== iterator.selectedTalentId)
            await updateSelectMutation({
              variables: {
                selectUpdateId: iterator.selectId,
                talents: updatedExistingTalent
              }
            })
            const profileToBeUpdateIndex = profileData.findIndex((item) => item.selectId === iterator.selectId)
            profileData[profileToBeUpdateIndex].exisitingTalents = updatedExistingTalent
            setProfileData(profileData)
          }
        }
      }
      const updatedTalents = interestedTalents.map(talent => {
        if (talent.talentId === talentId) {
          return { ...talent, status: talent.status === UGCTalentStatus.interested ? 'selected' : talent.status }
        }
        return talent
      })
      UGCContextDispatch({ type: SET_INTERESTED_TALENTS, payload: sortTalents(updatedTalents) })
      setIsOpen(false)
      dispatch(
        showToast({
          isError: false,
          message: <p>You&apos; successfully added {interestedTalents[activeProfileIndex]?.talent?.firstname} to select. <span onClick={handleNavigateToSelects} className={style.Toast__ViewSelectText}>View Select</span></p>
        })
      )
      setIsAddToSelectSidebarVisible(false)
    } catch (error: any) {
      dispatch(
        showToast({
          isError: true,
          message: error?.message ?? 'Something went wrong'
        })
      )
    }
  }

  const updateProfileData = (talentId?: string): void => {
    if (talentId !== undefined) {
      const updatedData = profileData.map((data) => ({
        ...data,
        isSelected: data.exisitingTalents?.includes(talentId),
        selectedTalentId: talentId
      }))
      setProfileData([...updatedData])
    }
  }

  /**
   * @description This function is called when we need to add talent to select
   */
  const handleToggleAddToSelectSidebar = async (): Promise<void> => {
    if (profileData?.length === 1 && interestedTalents?.length > 0) {
      const nonDeleted = interestedTalents.filter((item) => item.talent !== null)
      const deletedTalent = interestedTalents.filter((item) => item.talent === null)?.map((item) => item.talentId) ?? []
      const { talentId } = nonDeleted[activeProfileIndex]

      const finalTalentIds = [...profileData[0]?.exisitingTalents, talentId].filter((element) => !deletedTalent.includes(element))

      await updateSelectMutation({
        variables: {
          selectUpdateId: profileData[0]?.selectId,
          talents: finalTalentIds
        }
      })

      const profileToBeUpdateIndex = profileData.findIndex((item) => item.selectId === profileData[0]?.selectId)
      profileData[profileToBeUpdateIndex].exisitingTalents.push(talentId)
      updateProfileData(talentId)

      const updatedTalents = interestedTalents.map(talent => {
        if (talent.talentId === talentId) {
          return { ...talent, status: talent.status === UGCTalentStatus.interested ? 'selected' : talent.status }
        }
        return talent
      })
      UGCContextDispatch({ type: SET_INTERESTED_TALENTS, payload: sortTalents(updatedTalents) })
      dispatch(
        showToast({
          isError: false,
          message: <p>You&apos; successfully added {interestedTalents[activeProfileIndex]?.talent?.firstname} to select. <span onClick={handleNavigateToSelects} className={style.Toast__ViewSelectText}>View Select</span></p>
        })
      )
      setIsOpen(false)
    } else {
      setIsAddToSelectSidebarVisible(!isAddToSelectSidebarVisible)
    }
  }

  return (
    <>
      <InterestedTalentSubmissionDrawer
        isOpen={isOpen}
        key={isOpen ? 'open' : 'close'}
        selectedTalents={selectedTalents}
        isLoading={updateSelectLoading}
        handleActiveProfileIndex={[activeProfileIndex, setActiveProfileIndex]}
        isSelected={profileData.find((item) => item.isSelected) !== undefined}
        handleToggleSidebar={setIsOpen} isLandscape={isLandscape} hasAudition={hasAudition}
        activeProfileId={activeProfileId} updateProfileData={updateProfileData} handleToggleAddToSelectSidebar={() => { void handleToggleAddToSelectSidebar() }}
      />
      {profileData?.length > 0 && (
        <UGCAddTalentToSelect
          open={isAddToSelectSidebarVisible} isLoading={updateSelectLoading}
          isAddToRoleDisabled={profileData.filter((item) => item.isSelected).length === 0}
          handleToggleProfile={handleToggleProfile} handleCheckboxChange={handleChecboxChange} accordianData={profileData}
          handleAddToSelect={() => { void handleAddToSelect() }} handleToggleAddToSelectSidebar={() => { void handleToggleAddToSelectSidebar() }}
          handleToggleSidebar={setIsOpen}
        />
      )}
      {!initialLoading && interestedTalents.length === 0 && <p className={style.NotFoundText}>No Interested talent found</p>}
      <div className={classNames(style.InterestedTalent, isLandscape ? style['InterestedTalent--Landscape'] : '')}>
        <Shimmers count={10} loading={initialLoading} isLandscape={isLandscape} />
        <RenderIf isTrue={!initialLoading}>
          {interestedTalents.length > 0 && interestedTalents.map((item: IProjectTalentStatus) => {
            return (
              <UGCTalentCard
                key={item.talentId}
                firstname={item?.talent?.firstname}
                lastname={item?.talent?.lastname}
                showTag={((item?.status) != null)}
                status={item.status ?? ''}
                lastUpdated={item.updatedAt}
                isLandscape={isLandscape}
                isTalentDeleted={item.talent === null}
                onClick={() => { setIsOpen(true); setActiveProfileId(item.talentId) }}
                profilePhoto={item.talent?.mediaProfile?.uris?.profilePic ?? null}
                videoThumbnail={whatThumbnailToUse(hasAudition, item.talent?.auditionSubmissions?.uris, item.talent?.videoProfile?.uris, item.talent?.mediaProfile?.uris)}
                hasVideos={item.talent?.auditionSubmissions?.uris?.stream !== undefined || item.talent?.videoProfile?.uris?.stream !== undefined}
              />
            )
          })}
        </RenderIf>
      </div>
    </>
  )
}
