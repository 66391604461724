import { useQuery } from '@apollo/client'
import TalentAPI from '../../../services/Talent/TalentAPI'

const useTalentDetailQuery = (id) => {
  const { loading, error, data, refetch } = useQuery(
    TalentAPI.getTalentDetail(),
    {
      variables: {
        id
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: false
    }
  )
  return { loading, error, data, refetch }
}

export default useTalentDetailQuery
