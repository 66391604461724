/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Field } from 'formik'

import { makeStyles } from '@mui/styles'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { Autocomplete, Chip, createFilterOptions } from '@mui/material'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import { FullWidth } from './styles'
import { Images } from '../../../assets'
import { Colors } from '../../../constants'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const MultiSelectWithOther = props => {
  const filter = createFilterOptions()

  const {
    label,
    name,
    options,
    placeholder,
    isStandard,
    data,
    isMulti = true,
    onChange,
    loading = false,
    freeSolo = true,
    showPlaceholder = true,
    ...rest
  } = props

  return (
    <div >
      <Field id={name} name={name} {...rest}>
        {fieldProps => {
          const { field, meta, form } = fieldProps
          const { value } = field
          const { setFieldValue, setValues } = form
          const { error, touched } = meta
          return (
            <Autocomplete
              multiple
              id="tags-filled"
              value={value ?? []}
              onChange={(x, emails) => setFieldValue(name, emails)}
              options={data}
              defaultValue={undefined}
              freeSolo={freeSolo}
              style={{ marginBottom: 20 }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={`${label}-${index}`}
                    clickable
                    style={{
                      background: Colors.black,
                      color: Colors.white,
                      marginRight: 10,
                      marginBottom: 5
                    }}
                    deleteIcon={
                      <img
                        onMouseDown={e => e.stopPropagation()}
                        src={Images.grayCross}
                        style={{ height: 20, width: 20 }}
                      />
                    }
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  placeholder={showPlaceholder ? label : ''}
                  error={error && touched}
                  helperText={error}
                />
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options?.some(
                  option => inputValue === option
                )
                if (inputValue !== '' && !isExisting) {
                  filtered.push(`${inputValue}`)
                }
                return filtered
              }}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                   sx={{
                     color: 'black',
                     '&.Mui-checked': {
                       color: 'black'
                     }
                   }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
            />
          )
        }}
      </Field>
    </div>
  )
}

export default MultiSelectWithOther
