import { useEffect, useState } from 'react'

import classNames from 'classnames'
import style from './Counter.module.scss'
import { FormHelperText } from '@mui/material'
import { RenderIf } from '../../utils/Helpers'

interface CounterButtonProps {
  onClick: () => void
  type: string
  isDisabled: boolean
}

const CounterButton = (props: CounterButtonProps): JSX.Element => {
  const { type, onClick, isDisabled } = props

  return (
    <div onClick={onClick} className={classNames(style.CounterButton, isDisabled ? style['CounterButton--Disabled'] : '')}>
      <p className={classNames(style.CounterButton__Text)}>{type === 'increment' ? '+' : '-'}</p>
    </div>
  )
}

interface CounterProps {
  value: number
  handleIncrement: () => void
  handleDecrement: () => void
  min?: number
  max?: number
  isError?: boolean
  errorText?: string
}

function Counter (props: CounterProps): JSX.Element {
  const { value, handleDecrement, handleIncrement, min = 1, max = 3, isError = false, errorText = '' } = props
  const [counterValue, setCounterValue] = useState(value ?? 1)

  useEffect(() => {
    setCounterValue(value)
  }, [value])

  const handleCounterValue = (type: string): void => {
    if (type === 'increment' && counterValue < max) {
      setCounterValue(counterValue + 1)
      handleIncrement()
    } else if (type === 'decrement' && counterValue > min) {
      setCounterValue(counterValue - 1)
      handleDecrement()
    }
  }
  return (
    <div className={classNames(style.Counter)}>
      <div className={classNames(style.Counter__Wrapper)}>
        <CounterButton isDisabled={counterValue === min} onClick={() => { handleCounterValue('decrement') } } type='decrement' />
        <div className={classNames(style.Counter__Main)}>
          <p className={classNames(style.Counter__MainText)}>{counterValue}</p>
        </div>
        <CounterButton isDisabled={counterValue === max} onClick={() => { handleCounterValue('increment') } } type='increment' />
      </div>
      <RenderIf isTrue={isError}>
        <FormHelperText error>{errorText}</FormHelperText>
      </RenderIf>
    </div>
  )
}

export default Counter
