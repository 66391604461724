import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress, Typography as TypographyMUI } from '@mui/material'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Images } from '../../assets'
import { RenderIf } from '../../utils/Helpers'
import MainSearch from './Components/MainSearch'
import useDebounce from '../../hooks/Common/useDebounce'

import useSelectListQuery from '../TalentMain/Hooks/useSelectLIstQuery'
import useCombineWithMedia from './Hooks/useCombineWithMedia'
import { setTalentCardResizeData, showToast } from '../../redux/actions/DashBoardActions'

import HeaderBottom from './Components/HeaderBottom'
import useWindowDimensions from '../../hooks/Common/useWindowDimension'
import AddNewTlynt from '../TalentMain/Components/AddTalent/AddNewTlynt'
import FilterSidebar from '../../pages/TalentMain/Components/FilterSidebar'
import { HeaderV2, Layout, TalentCard, TlyntSidebar, Typography, CreateNewSelects, AddTlyntToSelects } from '../../components'

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`

const DescriptionWrapper = styled.div`
 height: 14px;
 display: flex;
 flex-direction: row;
`

const DescriptionText = styled.p`
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.38);
  letter-spacing: 0.15px;
  text-align: left;
`

const DescriptionModifiedText = styled.p`
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.38);
  letter-spacing: 0.15px;
  text-align: left;
  font-style: italic;
`

const TalentContainer = styled.div`
  padding: 0 40px 40px;
  display: grid;
  grid-gap: ${props => `calc(24px * ${props['data-size']}/ 220)`} ;
  grid-template-columns: ${props =>
    `repeat(auto-fill, minmax(${props['data-size']}px, 1fr))`};
  list-style: none;
`

const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`

const ShimmerBox = styled.div`
  flex: 0 1 70%;
  height: 250px;
  cursor: pointer;

  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const HeaderTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const HeaderTopBackImage = styled.img`
  height: 15px;
  cursor: pointer;
`

const StickyHeader = styled.div`
  position: sticky !important;
  z-index: 9;
  top: 0;
  padding: 24px 40px 20px 40px;
  background-color: white;
`

const CircularWrapper = styled.div`
  display: grid;
  place-items: center;
  padding: 10px 40px 40px 40px;
  .MuiCircularProgress-root {
    width: 24px !important;
    height: 24px !important;
    margin-bottom: 10px;
  }
`

const NonSticky = styled.div``

const TalentSearch = ({ isUserTalent = false }) => {
  // hooks
  const { width } = useWindowDimensions()

  // constants
  const searchLocation = useLocation().search
  const searchQuery = new URLSearchParams(useLocation().search)
  const query = searchQuery.get('q')
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)
  const { talentCardResizeData: { resolutionName, resolutionSize } } = dashboardReduxData
  const shimmerCount = useState(new Array(12).fill(0).map((_, index) => ({ id: index })))[0]

  // states
  const [headerTop, setHeaderTop] = useState()
  const [isSticky, setIsSticky] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [search, setSearch] = useState(query ?? '')
  const [showFilters, setShowFilters] = useState(false)
  const [isDataFetched, setIsDataFetched] = useState(false)
  const [isSelectionMode, setIsSelectionMode] = useState(false)
  const [showAddTalentModal, setShowAddTalentModal] = useState(false)
  const [showFilterHighlight, setShowFilterHighLight] = useState(false)
  const [loadingMoreTalentStatus, setLoadingMoreTalentStatus] = useState('done')
  const [showCreateNewSelectModal, setShowCreateNewSelectModal] = useState(false)
  const [showAddTalentToSelectModal, setShowAddTalentToSelectModal] = useState(false)

  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setSearchParams = useSearchParams()[1]
  const scrollViewRef = useRef(null)
  const selectListQuery = useSelectListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })
  const debouncedSearchTerm = useDebounce(search, 500)
  // apis
  const { data, setData, loading, fetchMore, isRouteEmpty, total } =
  useCombineWithMedia({
    search: debouncedSearchTerm,
    resetToDone: () => setLoadingMoreTalentStatus('done'),
    setIsDataFetched,
    isUserTalent
  })

  // lifecycle hook
  useEffect(() => {
    const headerElement = document.querySelector('.nonStickyHeader').getBoundingClientRect()
    setHeaderTop(headerElement.top)
  }, [])

  useEffect(() => {
    if (isRouteEmpty) {
      if (searchLocation.includes('&') || query || (!query && window.location.pathname.split('/')?.[window.location.pathname.split('/').length - 1] === 'search')) {
        setShowFilterHighLight(true)
      } else {
        setShowFilterHighLight(false)
      }
    } else {
      setShowFilterHighLight(false)
    }
  }, [isRouteEmpty])

  // functions
  const handleRightButtonClick = () => {
    switch (isSelectionMode) {
      case false:
        return setShowAddTalentModal(!showAddTalentModal)
      case true:
        return setShowAddTalentToSelectModal(!showAddTalentToSelectModal)
      default:
        return null
    }
  }

  const handleTalentCardSize = type => {
    const handleTalentCardResizeNumber = (data) => {
      dispatch(setTalentCardResizeData(data))
    }

    if (width > 1920) {
      switch (type) {
        case 'SM':
          return handleTalentCardResizeNumber({
            resolutionName: 'SM',
            resolutionSize: 150
          })
        case 'MD':
          return handleTalentCardResizeNumber({
            resolutionName: 'MD',
            resolutionSize: 256
          })
        case 'LG':
          return handleTalentCardResizeNumber({
            resolutionName: 'LG',
            resolutionSize: 312
          })
      }
    } else {
      switch (type) {
        case 'SM':
          return handleTalentCardResizeNumber({
            resolutionName: 'SM',
            resolutionSize: 150
          })
        case 'MD':
          return handleTalentCardResizeNumber({
            resolutionName: 'MD',
            resolutionSize: 180
          })
        case 'LG':
          return handleTalentCardResizeNumber({
            resolutionName: 'LG',
            resolutionSize: 220
          })
      }
    }
  }

  const handelTalentSelection = talentId => {
    const idx = data.findIndex(talent => talent.id === talentId)
    data[idx].isSelected = !data[idx].isSelected
    setData([...data])
  }
  const handleSearchInput = e => {
    e.preventDefault()
    setSearch(e.target.value)
    setSearchParams({ q: e.target.value }, { replace: true })
  }
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current
    if (loadingMoreTalentStatus === 'done' && scrollViewRef.current) {
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        fetchMoreTalents()
      }
    }

    const headerElement = document.querySelector('.nonStickyHeader')
    if (headerElement?.classList) {
      if (scrollTop >= (headerTop)) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }

  useEffect(() => {
    scrollViewRef?.current?.scrollTo(0, 0)
  }, [search])

  // pre load all image and cache them for fast loading
  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const mediaData = data.map((e) => e?.media)
    if (mediaData) {
      const allMedia = [].concat(...mediaData)
      const chunkSize = 200
      if (!isLoaded && allMedia.length) {
        for (let i = 0; i < allMedia.length; i += chunkSize) {
          const chunk = allMedia.slice(i, i + chunkSize)
          cacheImages(chunk?.map((e) => e?.uris?.card))
            .catch(e => console.error(e))
          if (i >= (allMedia.length - 1 - chunkSize)) {
            setIsLoaded(true)
          }
        }
      }
    }
  }, [data])

  const fetchMoreTalents = async () => {
    try {
      setLoadingMoreTalentStatus('loading')
      await fetchMore(() => setLoadingMoreTalentStatus('limited'), () => setLoadingMoreTalentStatus('done'))
    } catch (error) {
      setLoadingMoreTalentStatus('error')
      console.error(error)
    }
  }

  const handleClearSearch = () => {
    setSearch('')
    setSearchParams('')
  }

  const OutOfTalentCounts = () => {
    if (!loading) {
      return <Typography
        fontSize={12}
        fontWeight={400}
        lineHeight={'166%'}
        letterSpacing={'0.4px'}
        style={{ marginTop: 23 }} >
          Showing {data?.length} out of {total}
      </Typography>
    } else return <Box height={'23px'} />
  }

  // header
  const HeaderDescription = () => (
    <>
      {
        search && <DescriptionWrapper>
        <DescriptionText>
          {search && 'Search result for:'}
        </DescriptionText>
        <span style={{ width: 5 }} />
        <DescriptionModifiedText>
          {search}
        </DescriptionModifiedText>
      </DescriptionWrapper>
      }
    <OutOfTalentCounts />
    </>
  )

  return (
    <Layout>
      <MainContainer
        ref={scrollViewRef}
        onScroll={handleScroll}>
        <HeaderV2
          top={<HeaderTopWrapper onClick={() => navigate(isUserTalent ? '/users/talent' : '/talent') } >
            <HeaderTopBackImage src={Images.backArrowBlack} />
              <Typography fontSize={16} style={{ marginLeft: 10, lineHeight: '42px' }}>Back</Typography>
            </HeaderTopWrapper>}
          title={<Typography fontSize={34} fontWeight='bold'>TLYNT</Typography>}
          titleRight={ <MainSearch isLarge={false} handleSearchInput={handleSearchInput} search={search} handleClearSearch={handleClearSearch} />}
          description={<HeaderDescription />}
          bottom={
          <NonSticky className='nonStickyHeader'>
            {!isSticky &&
            <HeaderBottom
              isUserTalent={isUserTalent}
              setShowFilters = {setShowFilters}
              showFilters = {showFilters}
              showFilterHighlight = {showFilterHighlight}
              handleTalentCardSize = {handleTalentCardSize}
              resizeType = {resolutionName}
              setIsSelectionMode = {setIsSelectionMode}
              isSelectionMode = {isSelectionMode}
              selectedTalentlength={data.filter(talent => talent.isSelected === true)?.length}
              handleRightButtonClick ={handleRightButtonClick}
            />}
            </NonSticky>}
          stickyBottom={true}
        />
        {isSticky && <StickyHeader>
          <div style={{ display: 'flex' }}>
           <MainSearch isLarge={true} handleSearchInput={handleSearchInput}
            search={search} handleClearSearch={handleClearSearch} />
            <HeaderBottom
              isUserTalent={isUserTalent}
              isSticky={true}
              setShowFilters = {setShowFilters}
              showFilters = {showFilters}
              showFilterHighlight = {showFilterHighlight}
              handleTalentCardSize = {handleTalentCardSize}
              resizeType = {resolutionName}
              setIsSelectionMode = {setIsSelectionMode}
              isSelectionMode = {isSelectionMode}
              handleRightButtonClick ={handleRightButtonClick}
             />
          </div>
            <OutOfTalentCounts />
          </StickyHeader>}
        <TalentContainer isEmpty={data.length === 0} data-size={resolutionSize}>
          {loading && shimmerCount.map((item) => <ShimmerBox key={item.id} />)}
          {!loading && data.length > 0 &&
            data.map(talent => (
              <TalentCard
                key={talent?.id}
                isSelectionMode={isSelectionMode}
                data={talent}
                handelTalentSelection={handelTalentSelection}
                isUserTalent={isUserTalent}
              />
            ))}
        </TalentContainer>
          { data.length === 0 && !loading && isDataFetched && <div style={{ display: 'grid', placeItems: 'center' }}><TypographyMUI fontSize={34} fontWeight='bold' letterSpacing='0.25px'>No talent found.</TypographyMUI></div> }
        {loadingMoreTalentStatus === 'loading' &&
            !loading &&
            <CircularWrapper>
              <CircularProgress color="inherit" size={25} /> <Typography fontSize={14}>Loading more Talents</Typography>
            </CircularWrapper>}
      </MainContainer>
      {/* ------------- Right Sidebars  ------------------- */}
      <RenderIf isTrue={showAddTalentModal}>
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <AddNewTlynt
            isVisibleModal={showAddTalentModal}
            toggleModal={setShowAddTalentModal}
          />
        </TlyntSidebar>
      </RenderIf>
      <RenderIf isTrue={showAddTalentToSelectModal}>
        <TlyntSidebar direction={'right'} opacityLevel={1}>
          <AddTlyntToSelects
            newSelectCreated={false}
            selectListQuery={selectListQuery}
            gridList={data}
            resetSelection={() => setData(data.map((talent) => ({
              ...talent,
              isSelected: false
            })))}
            isVisibleModal={true}
            toggleModal={setShowAddTalentToSelectModal}
            setSelectionMode={setIsSelectionMode}
            toggleSuccessPopup={() => dispatch(showToast({
              message: 'You\'ve successfully added talent into selects',
              isError: false
            }))}
            toggleCreateNewSelects={() =>
              setShowCreateNewSelectModal(!showCreateNewSelectModal)
            }
          />
        </TlyntSidebar>
      </RenderIf>
      <RenderIf isTrue={showCreateNewSelectModal}>
        <TlyntSidebar direction={'right'} opacityLevel={0}>
          <CreateNewSelects
            isBackArrow={true}
            toggleNewSelectCreated={() =>
              setShowCreateNewSelectModal(!showCreateNewSelectModal)
            }
            selectListQuery={selectListQuery}
            isVisibleModal={showCreateNewSelectModal}
            toggleModal={() => {
              setShowCreateNewSelectModal()
              selectListQuery.refetch()
            }}
          />
        </TlyntSidebar>
      </RenderIf>
      { showFilters && <FilterSidebar toggleSidebar={ () => setShowFilters(!showFilters) } /> }
    </Layout>
  )
}

TalentSearch.propTypes = {
  isUserTalent: PropTypes.bool
}

export default TalentSearch
