import { useQuery } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useProjectDetailQuery (projectId) {
  const { data, loading, refetch } = useQuery(ProjectAPI.getProjectDetail(), {
    variables: {
      id: projectId
    },
    skip: !projectId,
    fetchPolicy: 'network-only'
  })
  return { data, loading, refetch }
}

export default useProjectDetailQuery
