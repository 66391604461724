import { Accordion as AccordionMui, AccordionSummary, AccordionDetails, Button, Checkbox } from '@mui/material'
import { useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import classNames from 'classnames'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import type { ChangeEvent, MouseEvent } from 'react'

import { RenderIf } from '../../utils/Helpers'
import ArrowDropDownIcon from '../../assets/images/UGCProject/ArrowDropDown.svg'
import CloseIcon from '../../assets/images/UGCProject/CloseBlack.svg'
import style from './Accordion.module.scss'

interface AccordionProps {
  label?: string
  isRemovable?: boolean
  onRemove?: () => void
  children: JSX.Element | JSX.Element[]
  expanded?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void
  externalMainClasses?: string[]
  showCheckbox?: boolean
  onCheckboxClick?: () => void
  isChecked?: boolean
  isEditableLabel?: boolean
  handleChangeLabel?: (name: string, value: string) => void
  isError?: boolean
  errorText?: string
  withRemoveText?: boolean
}

export const Accordion = (props: AccordionProps): JSX.Element => {
  const { label, isRemovable = false, withRemoveText = true, onRemove, children, expanded, onClick, externalMainClasses = [], showCheckbox = false, onCheckboxClick, isChecked, isEditableLabel = false, handleChangeLabel, isError = false, errorText = '' } = props
  const [isEditFocused, setIsEditFocused] = useState<boolean>(false)
  const [prevInputValue, setPrevInputValue] = useState(label ?? '')

  const handelRemove = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    if (typeof onRemove === 'function') {
      onRemove()
    }
  }

  const handleToggleEditButton = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setIsEditFocused(true)
  }

  const handleToggleCollapsable = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    onClick?.(e)
  }

  const onChangeLabel = (e: ChangeEvent<HTMLInputElement>): void => {
    handleChangeLabel?.(e.target.name, e.target.value)
  }

  const handleCloseInput = (key: string): void => {
    if (key === 'Escape') {
      setIsEditFocused(false)
      handleChangeLabel?.('name', prevInputValue)
    }
  }

  const handleBlur = (): void => {
    setIsEditFocused(isError ?? false)
    setPrevInputValue(label ?? '')
  }

  return (
    <div className={classNames([style.Accordion, ...externalMainClasses])}>
      <AccordionMui expanded={expanded}>
        <AccordionSummary expandIcon={<img onClick={onClick} src={ArrowDropDownIcon} />} focusVisibleClassName={style.Accordion__Summary}>
          {showCheckbox && <Checkbox checkedIcon={<CheckBoxIcon sx={{ color: 'black' }} />} icon={<CheckBoxOutlineBlankIcon />} checked={isChecked} onClick={onCheckboxClick} />}
          <div className={style.Accordion__Header} onClick={handleToggleCollapsable}>
            <RenderIf isTrue={!isEditFocused}>
              <span className={style.Accordion__HeaderText}>{label}</span>
              <RenderIf isTrue={isEditableLabel}>
                <span onClick={handleToggleEditButton}>Edit</span>
              </RenderIf>
            </RenderIf>
            <RenderIf isTrue={isEditFocused}>
              <div onClick={(e) => { e.stopPropagation() }}>
                <input name='name' value={label} className={classNames(style.Accordion__Input, isError ? style['Accordion__Input--error'] : '')}
                  onChange={onChangeLabel} onKeyDown={(e) => { handleCloseInput(e.key) }} onBlur={handleBlur} autoFocus />
                <p className={style.Accordion__ErrorText}>{errorText}</p>
              </div>
            </RenderIf>
          </div>
          <RenderIf isTrue={isRemovable}>
            <div className={style.Accordion__HeaderCTA}>
              <Button onClick={handelRemove}>{withRemoveText ? <><img src={CloseIcon} /><span>Remove</span></> : <DeleteOutlineOutlinedIcon /> }</Button>
            </div>
          </RenderIf>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </AccordionMui>
    </div>
  )
}
