import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import SelectAPI from '../../../services/Select/SelectAPI'

function useGetSelectById (props) {
  // props
  const { id } = props

  const [getSelectDataQuery, { data, loading, refetch }] = useLazyQuery(SelectAPI.getSelectById())

  useEffect(() => {
    if (id) {
      getSelectData(id)
    }
  }, [id])

  const getSelectData = async id => {
    try {
      await getSelectDataQuery({
        variables: {
          id,
          withBrand: true
        }
      })
    } catch (error) {
      console.log(error, 'error raised')
    }
  }

  return {
    data,
    loading,
    refetch
  }
}

export default useGetSelectById
