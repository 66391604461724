import type { ReactNode } from 'react'

import { Dialog as MuiDialog } from '@mui/material'

interface DrawerProps {
  open: boolean
  children?: ReactNode
  handleClose?: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export const Dialog = (props: DrawerProps): JSX.Element => {
  const { open, handleClose, children, size = 'xs' } = props

  return (
    <MuiDialog open={open} onClose={handleClose} PaperProps={{ square: true }} maxWidth={size}>
      {children}
    </MuiDialog>
  )
}
