import { Close, InsertLink } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import style from './ReferenceLinksList.module.scss'

interface ReferenceLinksListProps {
  referenceLinks: string[]
  hasCloseButton?: boolean
  onCloseClick?: (index: number) => void
}

export const ReferenceLinksList = (props: ReferenceLinksListProps): JSX.Element => {
  const { referenceLinks, hasCloseButton = false, onCloseClick } = props

  const handleOnClose = (index: number): void => {
    if (typeof onCloseClick === 'function') {
      onCloseClick(index)
    }
  }

  return (
    <div className={style.ReferenceLinksList}> {
      referenceLinks.map((link: string, index: number) => {
        return (
          <div className={style.ReferenceLinksList__ReferenceLinkItem} key={index}>
            <span className={style.ReferenceLinksList__LinkIcon}>
              <InsertLink fontSize='inherit' />
            </span>
            <Link className={style.ReferenceLinksList__Link} to={link} target='_blank'>{link}</Link>
            {hasCloseButton && <span className={style.ReferenceLinksList__CloseIcon}>
              <Close onClick={() => { handleOnClose(index) }} fontSize='inherit' />
            </span>}
          </div>
        )
      })}
    </div>)
}
