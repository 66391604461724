import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import SelectAPI from '../../../services/Select/SelectAPI'

function useSelectListQuery (props) {
  // props
  const {
    notifyOnNetworkStatusChange = true,
    fetchPolicy = 'network-only',
    isArchived
  } =
    props
  // api calls
  const {
    data: selectListData,
    loading: selectListLoading,
    error,
    fetchMore: fetchMoreSelects,
    refetch
  } = useQuery(SelectAPI.getListSelect(), {
    variables: { offset: 0, limit: 20, fetchParentSelects: true, archived: isArchived, withBrand: true },
    notifyOnNetworkStatusChange,
    fetchPolicy,
    nextFetchPolicy: 'cache-first'
  })

  // states
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    refetch()
    setData([])
    setLoading(true)
  }, [isArchived])

  // loading select list inside state
  useEffect(() => {
    if (!selectListLoading && !error && data.length === 0) {
      setLoading(false)
      setData([...selectListData.listSelects])
    }
  }, [selectListLoading, error])

  const fetchMore = async (cb, cb2) => {
    if (data.length >= 20) {
      setLoading(true)
      const { data: selectListFetchMoreData } = await fetchMoreSelects({
        variables: {
          offset: data.length,
          limit: 20,
          fetchParentSelects: true
        }
      })
      if (
        selectListFetchMoreData &&
        selectListFetchMoreData?.listSelects.length > 0
      ) {
        cb2()
        setData([...data, ...selectListFetchMoreData?.listSelects])
      } else cb()
      setLoading(false)
    }
  }

  const handleRefetchSelectList = async () => {
    const response = await refetch()
    const refetchedSelectListData = response.data?.listSelects
    setData([...refetchedSelectListData])
  }

  return { data, loading, fetchMore, refetch, handleRefetchSelectList }
}

export default useSelectListQuery
