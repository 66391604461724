import { decodeJwt } from 'jose'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { CREATE_VOTE } from '../../../services/API'
import { showToast } from '../../../redux/actions/DashBoardActions'

const getToken = () => {
  const publicToken = localStorage.getItem('public_token')
  const privateToken = localStorage.getItem('access_token')

  return privateToken ?? publicToken
}

const isExternal = () => {
  const publicToken = localStorage.getItem('public_token')
  const privateToken = localStorage.getItem('access_token')

  return !privateToken && !!publicToken
}

function useCreateVoteMutation (selectId) {
  // mutation
  const [CreateVoteMutation, { loading }] = useMutation(CREATE_VOTE, {
    notifyOnNetworkStatusChange: true
  })

  const dispatch = useDispatch()

  const createVote = (onSuccess, talentId) => {
    // specifiy if user logged in as advertiser that time pass advertiser ID instade of public ID
    const userId = decodeJwt(getToken())?.sub

    CreateVoteMutation({
      variables: {
        selectId,
        talentId,
        userId,
        external: isExternal()
      }
    })
      .then(res => onSuccess())
      .catch(e => {
        dispatch(showToast({
          message: e.message ?? 'Try again later',
          isError: true
        }))
      })
  }
  return { createVote, loading }
}

export default useCreateVoteMutation
