import LocalStorage from '../../utils/LocalStorage'
import types from '../types/user'

const user = LocalStorage.getItem('user') ?? {}

export interface UserState {
  info: Record<string, any>
  type: 'admin' | 'advertiser'
  role: string
  isAdmin: boolean
  isAdvertiser: boolean
}

const INITIAL_STATE: UserState = {
  info: user.info ?? {},
  type: user.type ?? null,
  role: user.role ?? null,
  isAdmin: user.type === 'admin',
  isAdvertiser: user.type === 'advertiser'
}

interface UserAction {
  type: string
  payload: any
}

const UserReducer = (state = INITIAL_STATE, action: UserAction): UserState => {
  const { type, payload } = action
  switch (type) {
    case types.SET_USER_INFO:
      return {
        ...state,
        info: payload
      }
    case types.SET_USER_TYPE:
      return {
        ...state,
        type: payload,
        isAdmin: payload === 'admin',
        isAdvertiser: payload === 'advertiser'
      }
    case types.SET_USER_ROLE:
      return {
        ...state,
        role: payload
      }
    default:
      return state
  }
}

export default UserReducer
