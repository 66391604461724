import PropTypes from 'prop-types'
import Select from './controls/select'
import { Input } from './controls/input'
import Checkbox from './controls/Checkbox'
import CheckboxChips from './controls/checkboxChips'
import SliderCtrl from './controls/slider'
import DatePicker from './controls/datePicker'
import MultiSelect from './controls/multiSelect'
import AutoComplete from './controls/autocomplete'
import MultiSelectForTags from './controls/multiSelectForTags'
import MultiSelectWithOther from './controls/multiSelectWithOther'

export const FormikControl = (props) => {
  const { control, ...rest } = props
  switch (control) {
    case 'input': return <Input { ... rest} />
    case 'textarea':
    case 'select': return <Select { ... rest} />
    case 'multiselect': return <MultiSelect { ... rest} />
    case 'multiselectother': return <MultiSelectWithOther { ... rest} />
    case 'multiselectfortag': return <MultiSelectForTags { ... rest} />
    case 'radio':
    case 'checkbox': return <Checkbox {...rest} />
    case 'chipscheckbox': return <CheckboxChips {...rest} />
    case 'date': return <DatePicker { ... rest } />
    case 'slider': return <SliderCtrl { ... rest} />
    case 'autocomplete': return <AutoComplete { ... rest } />
    default: return null
  }
}

FormikControl.propTypes = {
  control: PropTypes.string
}
