import { useEffect, useState, Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import { Stepper, Step, StepLabel } from '@mui/material'

import { Colors, Fonts } from '../../../../constants'
import MemberDetails from './Components/MemberDetails'
import CompanyDetail from './Components/CompanyDetail'
import { HeaderV2, Typography } from '../../../../components'

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 0',
    '& .Mui-active': { color: Colors.black },
    '& .Mui-completed': { color: '#9E9E9E' },
    '& .Mui-disabled .MuiStepIcon-root': { color: '#9E9E9E' },
    '& .MuiStepLabel-labelContainer  span ': {
      fontFamily: Fonts.degular,
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0.1
    },
    '& .MuiStepLabel-active': {
      color: '#000'
    }
  }
}))

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${Fonts.degular};
  font-style: normal;
  height: 100%;
`

const steps = [
  <Typography key='step-company'>Company Details</Typography>,
  <Fragment key='step-team'>
    <Typography>
      Team Member Details
      <br />
    </Typography>
    <Typography fontWeight="normal" fontSize={'10px'}>
      (Optional)
    </Typography>
  </Fragment>
]

const AddNewAdvertiser = props => {
  const {
    handleToggle,
    reFetchData,
    advertiserData = null,
    memberData = null,
    preActiveStep = false,
    editKey = 0,
    refetchMember = () => null,
    setIsRefetch
    // refetchMemberData: refetchMemberData
  } = props
  // mui classes
  const classes = useStyles()
  // states
  const [activeStep, setActiveStep] = useState(preActiveStep ? 1 : 0)
  const [advertiserId, setAdvertiserId] = useState(null)

  useEffect(() => {
    if (advertiserData) {
      setAdvertiserId(advertiserData?.id)
    }
  }, [advertiserData])

  return (
    <Wrapper>
      <HeaderV2
        stickey={false}
        title={
          <Typography fontSize={34} fontWeight="bold">
            {advertiserData ? 'Edit Advertiser' : 'Add New Advertiser'}
          </Typography>
        }
        description={
          <Typography fontSize={14} style={{ opacity: 0.5 }}>
            {advertiserData
              ? 'Edit compnay details of Advertiser & Team members.'
              : 'Add compnay details of Advertiser & Team members.'}
          </Typography>
        }
        bottom={
          <Stepper
            className={classes.root}
            activeStep={activeStep}
            alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step
                  onClick={e => {
                    if (advertiserData) {
                      setActiveStep(index)
                    }
                  }}
                  key={index}>
                  <StepLabel active={String(activeStep === index)}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        }
      />
      {activeStep === 0 && (
        <CompanyDetail
          handleToggle={handleToggle}
          handleActiveStep={setActiveStep}
          setAdvertiserId={setAdvertiserId}
          reFetchData={reFetchData}
          setIsRefetch={setIsRefetch}
          advertiserData={advertiserData}
        />
      )}
      {activeStep === 1 && (
        <MemberDetails
          handleToggle={handleToggle}
          advertiserId={advertiserId}
          reFetchData={reFetchData}
          membersData={advertiserData?.users ?? memberData}
          editKey={editKey}
          setIsRefetch={setIsRefetch}
          refetchMember={refetchMember}
        />
      )}
    </Wrapper>
  )
}

export default AddNewAdvertiser

AddNewAdvertiser.propTypes = {
  handleToggle: PropTypes.func,
  reFetchData: PropTypes.func,
  advertiserData: PropTypes.object,
  memberData: PropTypes.array,
  preActiveStep: PropTypes.bool,
  editKey: PropTypes.number,
  refetchMember: PropTypes.func,
  setIsRefetch: PropTypes.func,
  refetchMemberData: PropTypes.func
}
