import type { KeyboardEvent, ReactNode } from 'react'

import MuiDrawer from '@mui/material/Drawer'

interface DrawerProps {
  open: boolean
  children?: ReactNode
  handleNavigate?: (e: KeyboardEvent<HTMLImageElement>) => void
  onClose: () => void
}

export const Drawer = (props: DrawerProps): JSX.Element => {
  const { open, children, handleNavigate, onClose } = props

  const handelKeyUp = (e: KeyboardEvent<HTMLImageElement>): void => {
    if (typeof handleNavigate === 'function') {
      handleNavigate(e)
    }
  }
  return (
    <MuiDrawer anchor='right' open={open} onKeyDown={handelKeyUp} onClose={onClose}>
      {children}
    </MuiDrawer>
  )
}
