import { gql } from '@apollo/client'

class DemographicAPI {
  talentDemographicSelectionData () {
    return gql`
      query getTalentDemographicSelectionData {
        getTalentDemographicSelectionData {
          hairColor {
            id
            value
          }
          ethnicity {
            id
            value
          }
          country {
            id
            value
          }
          eyeColor {
            id
            value
          }
          race {
            id
            value
          }
          gender {
            id
            value
          }
          genderExpression {
            id
            value
          }
          sexualOrientation {
            id
            value
          }
          bodyType {
            id
            value
          }
        }
      }
    `
  }

  talentDemographicCreate () {
    return gql`
      mutation TalentDemographicCreate(
        $birthDate: String
        $bodyType: Int
        $height: Int
        $hairColor: Int
        $eyeColor: Int
        $ethnicity: String
        $race: String
        $gender: Int
        $genderExpression: Int
        $sexualOrientation: Int
        $talentId: ID!
        $sexualOrientationOther: String
        $country: Int
      ) {
        createTalentDemographic(
          birthDate: $birthDate
          bodyType: $bodyType
          height: $height
          hairColor: $hairColor
          eyeColor: $eyeColor
          ethnicity: $ethnicity
          country: $country
          race: $race
          gender: $gender
          genderExpression: $genderExpression
          sexualOrientation: $sexualOrientation
          talentId: $talentId
          sexualOrientationOther: $sexualOrientationOther
        ) {
          id
          talentId
          birthDate
          height
          hairColor
          eyeColor
          ethnicity
          country
          race
          gender
          genderExpression
          sexualOrientation
          sexualOrientationOther
        }
      }
    `
  }

  getTalentDemographicData () {
    return gql`
      query GetTalentDemographicData($talentId: ID!) {
        talentDemographicGet(talentId: $talentId) {
            id
            talentId
            bodyType
            birthDate
            height
            hairColor
            country
            eyeColor
            race
            gender
            genderExpression
            sexualOrientation
            sexualOrientationOther
            ethnicity
        }
      }
    `
  }

  talentDemographicUpdate () {
    return gql`
      mutation TalentDemographicUpdate(
        $birthDate: String
        $bodyType: Int
        $height: Int
        $hairColor: Int
        $eyeColor: Int
        $ethnicity: String
        $country: Int
        $race: String
        $gender: Int
        $genderExpression: Int
        $sexualOrientation: Int
        $talentId: ID!
        $sexualOrientationOther: String
      ) {
        updateTalentDemographic(
          birthDate: $birthDate
          bodyType: $bodyType
          height: $height
          hairColor: $hairColor
          eyeColor: $eyeColor
          ethnicity: $ethnicity
          country: $country
          race: $race
          gender: $gender
          genderExpression: $genderExpression
          sexualOrientation: $sexualOrientation
          talentId: $talentId
          sexualOrientationOther: $sexualOrientationOther
        ) {
          id
          talentId
          birthDate
          height
          hairColor
          eyeColor
          ethnicity
          country
          race
          gender
          genderExpression
          sexualOrientation
        }
      }
    `
  }

  addSexualOrientation () {
    return gql`
    mutation AddSexualOrientation(
      $value: String!
    ) {
      addSexualOrientation(
        value: $value
      ) {
        id
        value
      }
    }
  `
  }
}

export default new DemographicAPI()
