import { useDispatch, useSelector } from 'react-redux'
import { useRef, useState, useEffect, Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'

import { HeaderV2, TalentCard, Typography, SideBar, FooterLoader } from '../../components'
import { RenderIf } from '../../utils/Helpers'
import { setActiveSidebarRoute, setActiveTalentTabIndex, setTalentCardResizeData, setTalentsWithoutPictures, setTalentsWithPictures, setTalentsWithVideos } from '../../redux/actions/DashBoardActions'
import { TalentContainer, Wrapper, TitleText, DescriptionWrapper, DescriptionText, ShimmerBox, NonSticky, StickyHeader, NoRecordText, SidebarWrapper, BodyWrapper } from './Styles'
import HeaderBottom from './Components/HeaderBottom'
import RightSidebars from './Components/RightSidebars'
import SearchBox from './Components/SearchBox'
import useAllTalentsWithMedia from '../../hooks/Talent/useAllTalentsWithMedia'
import useAllTalentsWithoutMedia from '../../hooks/Talent/useAllTalentsWithoutMedia'
import useWindowDimensions from '../../hooks/Common/useWindowDimension'
import useAllTalentsWithVideo from '../../hooks/Talent/useAllTalentsWithVideo'

const Talent = props => {
  // destructuring props
  const { title, description, isUserTalent = false, showHeaderButtons } = props

  // constants
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)
  const user = useSelector(state => state.UserReducer)
  const { activeSidebarRoute, talentCardResizeData: { resolutionName, resolutionSize } } = dashboardReduxData
  const { userTalentsActiveTabIndex, talentsActiveTabIndex } = dashboardReduxData.activeTalentTabIndex

  // hooks
  const dispatch = useDispatch()
  const scrollViewRef = useRef(null)
  const { width } = useWindowDimensions()

  // states
  const [isLoaded, setIsLoaded] = useState(false)
  const shimmerCount = new Array(9).fill(0).map((_, index) => ({ id: index }))

  const [isSticky, setIsSticky] = useState(false)
  const [isVisibleModal, toggleModal] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [selectionMode, setSelectionMode] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [isCreateNewSelects, toggleCreateNewSelects] = useState(false)

  useEffect(() => {
    if (activeSidebarRoute === '') {
      window.scrollTo(0, 0)
      dispatch(setActiveSidebarRoute('talent'))
    }
  }, [location])

  // lifecycle hooks
  useEffect(() => {
    dispatch(setActiveTalentTabIndex({ userTalentsActiveTabIndex, talentsActiveTabIndex: activeTabIndex }))
  }, [activeTabIndex])

  useEffect(() => {
    setActiveTabIndex(talentsActiveTabIndex)
  }, [])

  useEffect(() => {
    const headerElement = document.querySelector('.nonStickyHeader')
    if (headerElement?.classList) {
      if (window.scrollY >= 200) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', setScroll)
    return () => {
      window.removeEventListener('scroll', setScroll)
    }
  }, [])

  useEffect(() => {
    const parameter = {}
    if (isUserTalent) {
      parameter.hasMedia = activeTabIndex === 0
    }
  }, [])

  // api calls
  const {
    allTalentsWithoutMediaData,
    setAllTalentsWithoutMediaData,
    allTalentWithoutMediaLoading,
    totalAllTalentsWithoutMedia,
    allTalentsWithoutMediaToggleNextPageCall
  } = useAllTalentsWithoutMedia({ activeTabIndex })

  const {
    allTalentsWithMediaData,
    setAllTalentsWithMediaData,
    allTalentWithMediaLoading,
    totalAllTalentsWithMedia,
    allTalentsWithMediaToggleNextPageCall
  } = useAllTalentsWithMedia({ activeTabIndex })

  const {
    allTalentsWithVideoData,
    allTalentWithVideoLoading,
    totalAllTalentsWithVideo,
    setAllTalentsWithVideoData,
    allTalentsWithVideoToggleNextPageCall
  } = useAllTalentsWithVideo({ activeTabIndex })

  // functions
  const handleActiveTabIndex = index => setActiveTabIndex(index)

  const getActiveTabData = () => {
    switch (activeTabIndex) {
      case 0:
        return allTalentsWithMediaData
      case 1:
        return allTalentsWithoutMediaData
      case 2:
        return allTalentsWithVideoData
      default:
        return []
    }
  }

  const handelTalentSelection = talentId => {
    if (activeTabIndex === 0) {
      const idx = allTalentsWithMediaData.findIndex(talent => talent.id === talentId)
      allTalentsWithMediaData[idx].isSelected = !allTalentsWithMediaData[idx].isSelected
      dispatch(setTalentsWithPictures([...allTalentsWithMediaData]))

    } else if (activeTabIndex === 1) {
      const idx = allTalentsWithoutMediaData.findIndex(talent => talent.id === talentId)
      allTalentsWithoutMediaData[idx].isSelected = !allTalentsWithoutMediaData[idx].isSelected
      dispatch(setTalentsWithoutPictures([...allTalentsWithoutMediaData]))

    } else {
      const idx = allTalentsWithVideoData.findIndex(talent => talent.id === talentId)
      allTalentsWithVideoData[idx].isSelected = !allTalentsWithVideoData[idx].isSelected
      dispatch(setTalentsWithVideos([...allTalentsWithVideoData]))
    }
  }

  const setScroll = () => {
    const headerElement = document.querySelector('.nonStickyHeader')
    if (headerElement?.classList) {
      if (window.scrollY >= 200) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }

  const handleResetSelection = () => {
    if (activeTabIndex === 0) {
      const unselectedData = allTalentsWithMediaData.map(item => ({ ...item, isSelected: false }))
      dispatch(setTalentsWithPictures([...unselectedData]))
      
    } else {
      const unselectedData = allTalentsWithoutMediaData.map(item => ({ ...item, isSelected: false }))
      dispatch(setTalentsWithoutPictures([...unselectedData]))
    }
  }

  const getTabTalentsLoading = () => {
    switch (activeTabIndex) {
      case 0:
        return allTalentWithMediaLoading
      case 1:
        return allTalentWithoutMediaLoading
      case 2:
        return allTalentWithVideoLoading
      default:
        return false
    }
  }

  const getActiveTabTotalData = () => {
    switch (activeTabIndex) {
      case 0:
        return totalAllTalentsWithMedia
      case 1:
        return totalAllTalentsWithoutMedia
      case 2:
        return totalAllTalentsWithVideo
      default:
        return 0
    }
  }

  const NumberOfTalents = () => {
    if (!getTabTalentsLoading()) {
      return (
        <RenderIf isTrue={user.isAdmin}>
          <Typography
            fontSize={12}
            fontWeight={400}
            lineHeight={'166%'}
            letterSpacing={'0.4px'}
            style={{ paddingTop: 15 }}>
            Showing {getActiveTabData().length} out of {getActiveTabTotalData()}</Typography>
        </RenderIf>
      )
    } else return null
  }

  const handleTalentCard = type => {
    const handleTalentCardResizeNumber = data => { dispatch(setTalentCardResizeData(data)) }

    if (width > 1920) {
      switch (type) {
        case 'SM':
          return handleTalentCardResizeNumber({
            resolutionName: 'SM',
            resolutionSize: 150
          })
        case 'MD':
          return handleTalentCardResizeNumber({
            resolutionName: 'MD',
            resolutionSize: 256
          })
        case 'LG':
          return handleTalentCardResizeNumber({
            resolutionName: 'LG',
            resolutionSize: 312
          })
      }
    } else {
      switch (type) {
        case 'SM':
          return handleTalentCardResizeNumber({
            resolutionName: 'SM',
            resolutionSize: 150
          })
        case 'MD':
          return handleTalentCardResizeNumber({
            resolutionName: 'MD',
            resolutionSize: 180
          })
        case 'LG':
          return handleTalentCardResizeNumber({
            resolutionName: 'LG',
            resolutionSize: 220
          })
      }
    }
  }
  // pre load all image and cache them for fast loading
  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const mediaData = getActiveTabData().map((e) => e.media)
    if (mediaData) {
      const allMedia = [].concat(...mediaData)
      const chunkSize = 200
      if (!isLoaded && allMedia.length) {
        for (let i = 0; i < allMedia.length; i += chunkSize) {
          const chunk = allMedia.slice(i, i + chunkSize)
          cacheImages(chunk?.map((e) => e?.uris?.card))
            .catch(e => console.error(e))
          if (i >= (allMedia.length - 1 - chunkSize)) {
            setIsLoaded(true)
          }
        }
      }
    }
  }, [activeTabIndex])

  useEffect(() => {
    setIsLoaded(true)
  }, [getActiveTabData().length])

  const handleNextPageCall = () => {
    switch (activeTabIndex) {
      case 0:
        return allTalentsWithMediaToggleNextPageCall()
      case 1:
        return allTalentsWithoutMediaToggleNextPageCall()
      case 2:
        return allTalentsWithVideoToggleNextPageCall()
    }
  }

  const getActiveTabSetTalentData = () => {
    switch (activeTabIndex) {
      case 0:
        return setAllTalentsWithMediaData
      case 1:
        return setAllTalentsWithoutMediaData
      case 2:
        return setAllTalentsWithVideoData
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <SidebarWrapper>
        <SideBar />
      </SidebarWrapper>
      <BodyWrapper showFilterModal={showFilterModal}>
        <Wrapper ref={scrollViewRef}>
          <HeaderV2
            title={<TitleText>{title}</TitleText>}
            titleRight={<SearchBox isUserTalent={isUserTalent} />}
            description={<DescriptionWrapper><DescriptionText>{description}</DescriptionText></DescriptionWrapper>}
            bottom={
              <NonSticky className="nonStickyHeader">
                <RenderIf isTrue={!isSticky}>
                  <Fragment>
                    <HeaderBottom
                      activeTabIndex={activeTabIndex}
                      handleActiveTabIndex={handleActiveTabIndex}
                      setSelectionMode={setSelectionMode}
                      selectionMode={selectionMode}
                      toggleModal={toggleModal}
                      isVisibleModal={isVisibleModal}
                      selectedTalentlength={getActiveTabData().filter(talent => talent.isSelected === true)?.length}
                      toggleFilterModal={() => setShowFilterModal(!showFilterModal)}
                      handleResetSelection={handleResetSelection}
                      showHeaderButtons={showHeaderButtons}
                      handleTalentCard={handleTalentCard}
                      resizeType={resolutionName}
                    />
                    <NumberOfTalents />
                  </Fragment>
                </RenderIf>
              </NonSticky>
            }
            stickyBottom={true}
          />
          {isSticky && (
            <StickyHeader>
              <HeaderBottom
                activeTabIndex={activeTabIndex}
                handleActiveTabIndex={handleActiveTabIndex}
                setSelectionMode={setSelectionMode}
                selectionMode={selectionMode}
                toggleModal={toggleModal}
                isVisibleModal={isVisibleModal}
                selectedTalentlength={getActiveTabData().filter(talent => talent.isSelected === true)?.length}
                toggleFilterModal={() => setShowFilterModal(!showFilterModal)}
                showHeaderButtons={showHeaderButtons}
                handleResetSelection={handleResetSelection}
                isSticky={true}
                handleTalentCard={handleTalentCard}
                resizeType={resolutionName}
              />
              <NumberOfTalents />
            </StickyHeader>
          )}
          <InfiniteScroll
            dataLength={getActiveTabData()?.length}
            next={() => handleNextPageCall()}
            hasMore={true}
            scrollThreshold={0.4}>
            <TalentContainer key={isUserTalent} data-size={resolutionSize}>
              <RenderIf isTrue={getTabTalentsLoading()}>
                {shimmerCount.map(item => <ShimmerBox key={item.id} />)}
              </RenderIf>
              <RenderIf
                isTrue={getActiveTabData() && getActiveTabData()?.length > 0}>
                {getActiveTabData().map(talent => (
                  <TalentCard
                    key={talent?.id}
                    isSelectionMode={selectionMode}
                    data={talent}
                    handelTalentSelection={handelTalentSelection}
                    isUserTalent={isUserTalent}
                  />
                ))}
              </RenderIf>
            </TalentContainer>
          </InfiniteScroll>

          <RenderIf isTrue={!getTabTalentsLoading() && getActiveTabData()?.length === 0}>
            <NoRecordText>No Talents Found..!</NoRecordText>
          </RenderIf>

          <RenderIf isTrue={getActiveTabData().length < getActiveTabTotalData()}>
            <FooterLoader loadMessage="Loading more talents" />
          </RenderIf>

        </Wrapper>
      </BodyWrapper>
      <RightSidebars
        data={getActiveTabData()}
        setData={getActiveTabSetTalentData}
        setSelectionMode={setSelectionMode}
        toggleCreateNewSelects={toggleCreateNewSelects}
        isVisibleModal={isVisibleModal}
        selectionMode={selectionMode}
        toggleModal={toggleModal}
        isCreateNewSelects={isCreateNewSelects}
        showFilterModal={showFilterModal}
        toggleFilterModal={() => setShowFilterModal(!showFilterModal)}
      />
    </div>
  )
}

Talent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tabTitleList: PropTypes.array,
  isUserTalent: PropTypes.bool,
  showHeaderButtons: PropTypes.bool
}

export default Talent
