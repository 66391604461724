import { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useDispatch } from 'react-redux'

import Style from '../../Styles'
import MultiSelectForTags from '../TagField'
import { Colors } from '../../../../constants'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { Button, HeaderV2, Typography } from '../../../../components'

import useTagListQuery from '../../Hooks/useTagListQuery'
import { BulkUploadTagsValidationSchema } from '../../../../services/ValidationServices'

const Addtags = ({ setIsVisible, setTags }) => {
  // initial value for form
  const initialValues = {
    tags: []
  }
  // hooks
  const formikRef = useRef(null)
  const dispatch = useDispatch()
  const { data: tagData, loading } = useTagListQuery()
  // local state
  const [tagOption, setTagOption] = useState([])

  const handleFormSubmit = data => {
    const finalTags = data.tags.length > 1
      ? data.tags.join('|')
      : data.tags.toString() + '|'
    setTags(finalTags)
    setIsVisible(false)
    dispatch(
      showToast({
        message: "You've successfully applied Tags."
      })
    )
  }
  useEffect(() => {
    if (tagData?.getTalentTag?.length > 0) {
      setTagOption(tagData?.getTalentTag?.map(e => { return e.tag }))
    }
  }, [tagData])

  return (
    <Style.AddTagWrapper>
      <HeaderV2
        stickey={false}
        title={<Typography fontSize={34} fontWeight='bold'>Add tags</Typography>}
        description={
          <Typography style={{ opacity: 0.5 }}>
            Add tag and press enter to apply the tags.
          </Typography>
        }
      />
      {
       !loading
         ? <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={BulkUploadTagsValidationSchema}
            onSubmit={handleFormSubmit}>
            {formik => {
              return (
              <Form style={{ height: '100%', marginInline: '40px' }}>
                <MultiSelectForTags
                  name='tags'
                  isStandard
                  control='multiselectfortag'
                  label='Tags'
                  data={tagOption}
                  required
                  showPlaceholder={false}
                  freeSolo={false}
                />
              </Form>
              )
            }}
          </Formik>
         : <>
            <Style.AddtagShimmer />
            <Style.AddtagShimmer />
          </>
          }
      <Style.AddtagBottom>
        <Button outline backgroundColor='white' onClick={() => setIsVisible(false)}>
          <Typography fontWeight='bold' fontSize={15}>Cancel</Typography>
        </Button>
        <Button
          onClick={() => formikRef?.current.submitForm()}
          minWidth={'10px'}>
          <Typography fontSize='15px'
            fontWeight='bold'
            lineHeight='26px'
            letterSpacing='0.46px'
            color={Colors.white}>APPLY TAGS</Typography>
        </Button>
      </Style.AddtagBottom>
    </Style.AddTagWrapper>
  )
}

Addtags.propTypes = {
  setIsVisible: PropTypes.func,
  setTags: PropTypes.func
}

export default Addtags
