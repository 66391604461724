import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FormikControl } from '../../../../../components/formik/formikControl'

const Column = styled.div`
  width: 47%;
`

const ProjectOverviewRightForm = props => {
  const { index, demographicData } = props
  // api destructured data
  const raceData = demographicData?.race
  const genderData = demographicData?.gender
  const eyeColorData = demographicData?.eyeColor

  const hairColorData = demographicData?.hairColor
  const genderExpressionData = demographicData?.genderExpression
  const sexualOrientationData = demographicData?.sexualOrientation
  const ethnicityData = demographicData?.ethnicity
  return (
    <Column>
      <FormikControl
        name={`roles.${index}.hairColor`}
        isStandard
        control="multiselectother"
        label="Hair Color"
        required
        data={['Any'].concat(hairColorData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
      <FormikControl
        name={`roles.${index}.eyeColor`}
        isStandard
        control="multiselectother"
        label="Eye Color"
        required
        data={['Any'].concat(eyeColorData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
      <FormikControl
        name={`roles.${index}.race`}
        isStandard
        control="multiselectother"
        label="Race"
        required
        data={['Any'].concat(raceData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
      <FormikControl
        name={`roles.${index}.ethnicity`}
        isStandard
        control="multiselectother"
        label="Ethnicity"
        required
        data={['Any'].concat(ethnicityData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
      <FormikControl
        name={`roles.${index}.gender`}
        isStandard
        control="multiselectother"
        label="Gender"
        required
        data={['Any'].concat(genderData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
      <FormikControl
        name={`roles.${index}.genderExpression`}
        isStandard
        control="multiselectother"
        label="Gender Expression"
        required
        data={['Any'].concat(genderExpressionData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
      <FormikControl
        name={`roles.${index}.sexualOrientation`}
        isStandard
        control="multiselectother"
        label="Sexual Orientation"
        required
        data={['Any'].concat(sexualOrientationData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
       <FormikControl
          name={`roles.${index}.notes`}
          isStandard
          control="input"
          label="Notes"
          required
          multiline
          rows={5}
        />
    </Column>
  )
}

ProjectOverviewRightForm.propTypes = {
  index: PropTypes.number,
  demographicData: PropTypes.object
}

export default ProjectOverviewRightForm
