
import { useMutation } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useUpdateProjectMutation () {
  // mutation
  const [UpdateProjectMutation, { loading }] = useMutation(ProjectAPI.updateProject(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const updateProject = (id, status, onSuccess, onError, type) => {
    UpdateProjectMutation({
      variables: {
        id,
        status,
        type
      }
    })
      .then(res => onSuccess())
      .catch(e => onError(e))
  }
  return {
    updateProject,
    loading
  }
}

export default useUpdateProjectMutation
