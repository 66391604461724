import { Link, useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useFeature } from '@growthbook/growthbook-react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useState, useContext, useEffect, useRef, useMemo } from 'react'
import classNames from 'classnames'
import type { Dispatch, SetStateAction, ReactNode, KeyboardEvent } from 'react'

import { Button } from '../buttonV2'
import { Drawer } from '../Drawer'
import { formatProjectTalentDateAndTime } from '../../utils/ProjectHelper'
import { getAssetsListProjectTalentStatus, listProjectTalentStatus, updateUGCProjectTalentStatus } from '../../apis/project'
import { RenderIf, isMoreThan24HoursApart } from '../../utils/Helpers'
import { SET_INTERESTED_TALENTS, UGCDetailContext } from '../../contexts/project/ugcDetail'
import { showToast } from '../../redux/actions/DashBoardActions'
import { sortTalents } from '../pages/UGC/InterestedTalent'
import type { UserState } from '../../redux/reducers/user'
import { UGCConfirmationModal } from '../UGCConfirmationModal'
import { whatThumbnailToUse, UGCVideoCard } from '../TalentCardV2'
import ArrowBackward from '../../assets/images/Projects/ArrowBackward.svg'
import CrossWhiteIcon from '../../assets/images/UGCProject/CrossWhiteIcon.svg'
import style from './InterestedTalentSubmissionDrawer.module.scss'
import TimerIcon from '../../assets/images/UGCProject/TimerIcon.svg'
import type { IProjectTalentStatus } from '../../contexts/project/ugcDetail'
import VideoJS from '../VideoJS'
import WhiteTick from '../../assets/images/UGCProject/WhiteTick.svg'

interface DrawerBodyProps {
  activeTab: string
  interestedTalents: IProjectTalentStatus[]
  activeProfile: number
  loading: boolean
  isLandscape: boolean
  setIsVideoPlaying: (isPlaying: boolean) => void
  isVideoPlaying: boolean
  videoPlayerRef: any
  setActiveTab: (activeTab: string) => void
  hasAudition: boolean
  handleTimeLeft: [string | null, Dispatch<SetStateAction<null | string>>]
}

const DrawerBody = (props: DrawerBodyProps): JSX.Element => {
  const { activeTab, interestedTalents, activeProfile, setActiveTab, loading, isLandscape, isVideoPlaying, setIsVideoPlaying, videoPlayerRef, hasAudition, handleTimeLeft } = props

  const [showTimerToast, setShowTimerToast] = useState(true)
  const [timeleft, setTimeleft] = handleTimeLeft

  // TIMER LOGIC FOR OFFER EXPIRY
  const targetDate = new Date(interestedTalents[activeProfile]?.updatedAt).getTime()
  const currentTime = new Date().getTime()
  const milliseconds = currentTime - targetDate
  let seconds = 86400 - Math.floor(milliseconds / 1000)

  useEffect(() => {
    if (interestedTalents[activeProfile]?.status === 'booking_requested') {
      const timer = setInterval(function () {
        seconds--

        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = seconds % 60

        if (hours < 0 || minutes < 0 || remainingSeconds < 0) {
          setTimeleft('expired')
          clearInterval(timer)
          return
        }

        setTimeleft(hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + remainingSeconds.toString().padStart(2, '0'))
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [activeProfile])

  // VIDEO JS CONFIG
  const videoURL = activeTab === 'profile' ? interestedTalents[activeProfile]?.talent?.videoProfile?.uris?.stream : interestedTalents[activeProfile]?.talent?.auditionSubmissions?.uris?.stream ?? ''
  const videoJsOptions = useMemo(() => ({
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: false,
    fill: true,
    preload: 'auto',
    controlBar: {
      pictureInPictureToggle: false
    },
    sources: [
      {
        src: videoURL,
        type: 'application/x-mpegURL'
      }
    ],
    bigPlayButton: false
  }), [videoURL])

  return (
    <div className={style.Drawer__Body}>
      <RenderIf isTrue={interestedTalents[activeProfile]?.status === 'booking_requested' && showTimerToast && timeleft !== null}>
        <div className={classNames([style.Drawer__BodyTimerBox, timeleft === 'expired' ? style['Drawer__BodyTimerBox--Expired'] : ''])}>
          <img src={TimerIcon} />
            <p className={style.Drawer__BodyTimerText}>{timeleft === 'expired' ? 'Offer expired' : `Offer will expire in ${timeleft ?? ''}`} </p>
          <div className={style.Drawer__BodyTimerBoxClose} onClick={() => { setShowTimerToast(!showTimerToast) }}>
            <img src={CrossWhiteIcon} />
          </div>
        </div>
      </RenderIf>
      <RenderIf isTrue={timeleft === null && interestedTalents[activeProfile]?.status === 'booking_requested'}>
        <div className={classNames(style.Drawer__BodyTimerBoxShimmer)} >
          <Skeleton variant='rectangular' width={'100%'} height={50} />
        </div>
      </RenderIf>
      <div className={style.Drawer__UserInfoWrapper}>
        <RenderIf isTrue={loading}>
          <div className={style.Drawer__UserInfoData}>
            <Skeleton variant='rectangular' height={52} width={52} />
            <div className={style.Drawer__UserInfoHeader}>
              <Skeleton variant='text' width={130} height={20} />
            </div>
          </div>
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <div className={style.Drawer__UserInfoData}>
            <div className={style.Drawer__UserIconBox}>
              {interestedTalents[activeProfile]?.talent?.mediaProfile === null
                ? <span>{`${interestedTalents[activeProfile]?.talent?.firstname?.charAt(0) ?? ''}${interestedTalents[activeProfile]?.talent?.lastname?.charAt(0) ?? ''}`}</span>
                : <img className={style.Drawer__UserIcon} src={interestedTalents[activeProfile]?.talent?.mediaProfile?.uris.profilePic} />}
            </div>
            <div className={style.Drawer__UserInfoHeader}>
              <h3>{`${interestedTalents[activeProfile]?.talent?.firstname ?? ''} ${interestedTalents[activeProfile]?.talent?.lastname ?? ''}`}</h3>
              <span>{formatProjectTalentDateAndTime(interestedTalents[activeProfile]?.createdAt)}</span>
            </div>
          </div>
          <Link to={`/talent/${interestedTalents[activeProfile]?.talentId}`} target='_blank' className={style.Link__Button}>
            <div className={style.Button__Tag}><span className={style.Button__Text}>See profile</span></div>
          </Link>
        </RenderIf>
      </div>
      <div className={style.Drawer__TabWrapper}>
        <RenderIf isTrue={loading}>
          <Skeleton variant='text' width={150} height={52} />
          <Skeleton variant='text' width={150} height={52} />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <div onClick={() => { setActiveTab('profile') }} className={classNames(style.Drawer__TabItem, style[`Drawer__TabItem${activeTab === 'profile' ? '--active' : ''}`])}>
            <span>Profile Video</span>
          </div>
          <RenderIf isTrue={hasAudition}>
            <div onClick={() => { setActiveTab('audition') }} className={classNames(style.Drawer__TabItem, style[`Drawer__TabItem${activeTab === 'audition' ? '--active' : ''}`])}>
              <span>Audition Video</span>
            </div>
          </RenderIf>
        </RenderIf>
      </div>
      <div className={classNames(style.Drawer__TabContainer, style[`Drawer__TabContainer${activeTab === 'profile' ? '' : (isLandscape ? '--landscape' : '')}`])}>
        <RenderIf isTrue={!loading}>
          {!isVideoPlaying
            ? <UGCVideoCard isLandscape={activeTab !== 'profile' ? isLandscape : false} onClick={() => { setIsVideoPlaying(true) }} videoThumbnail={activeTab === 'profile' ? whatThumbnailToUse(false, undefined, interestedTalents[activeProfile]?.talent?.videoProfile?.uris, undefined) : whatThumbnailToUse(true, interestedTalents[activeProfile]?.talent?.auditionSubmissions?.uris, undefined, undefined)} hasVideos={activeTab === 'profile' ? interestedTalents[activeProfile]?.talent?.videoProfile?.uris !== undefined : interestedTalents[activeProfile]?.talent?.auditionSubmissions?.uris !== undefined } />
            : <div className={classNames(style.Drawer__VideoContainer, style[`Drawer__VideoContainer${activeTab === 'profile' ? '' : (isLandscape ? '--landscape' : '')}`])}><VideoJS ref={videoPlayerRef} options={videoJsOptions} /></div>}
        </RenderIf>
        <RenderIf isTrue={loading}>
          <Skeleton variant='rectangular' height={350} width={250} />
        </RenderIf>
      </div>
    </div>
  )
}

interface getButtonConfigReturnType {
  isButtonDisabled: boolean
  functionToCall: () => void
  buttonText: 'Cancel offer' | 'Offer booking'
}

/**
 * @description Used to get the button config for the offer booking or cancel button - for admin button is always enabled for offer booking
 * @param status string
 * @param timeleft string
 * @param remainingSpots number
 * @param toggleOfferedModal function to toggle the offered or cancel modal
 * @description Used to get the button config for the offer booking or cancel button
 * @returns {object} - isButtonDisabled, functionToCall, buttonText
 */

const getButtonConfig = (status: string, timeleft: string, remainingSpots: number, toggleOfferedModal: (arg0: string) => void, isAdmin: boolean): getButtonConfigReturnType => {
  const isButtonDisabled = ((status !== 'booking_requested' && remainingSpots < 1) || status === 'booking_confirmed' || (status === 'booking_requested' && timeleft === 'expired'))
  const buttonText = (status === 'booking_requested' && timeleft !== 'expired') ? 'Cancel offer' : 'Offer booking'

  const functionToCall = (): void => {
    if (status === 'booking_requested' && timeleft !== 'expired') {
      toggleOfferedModal('cancel')
    } else {
      toggleOfferedModal('offerBooking')
    }
  }

  return {
    isButtonDisabled: isAdmin ? false : isButtonDisabled,
    functionToCall,
    buttonText
  }
}
interface DrawerProps {
  isOpen: boolean
  isSelected: boolean
  handleToggleSidebar: (isOpen: boolean) => void
  handleToggleAddToSelectSidebar: () => void
  updateProfileData: (talentId: string) => void
  children?: ReactNode
  activeProfileId?: string
  isLandscape: boolean
  isLoading: boolean
  hasAudition: boolean
  selectedTalents: string[]
  handleActiveProfileIndex: [number, (index: number) => void]
}

export const InterestedTalentSubmissionDrawer = (props: DrawerProps): JSX.Element => {
  // INITIALIZATION
  const { id: projectId } = useParams()
  const dispatch = useDispatch()
  const user: UserState = useSelector((state: any) => state.UserReducer)

  // PROPS
  const { isOpen, handleToggleSidebar, activeProfileId, isLandscape, hasAudition, handleToggleAddToSelectSidebar, isSelected, handleActiveProfileIndex, updateProfileData, isLoading, selectedTalents } = props

  // HOOKS
  const BookTalentFeature = useFeature('admin:ugc-book-talent')
  const videoPlayerRef = useRef<any>(null)
  const [{ overview, interestedTalents: interestedTalentsFromContext }, UGCContextDispatch] = useContext(UGCDetailContext)

  // STATES
  const [open, setOpen] = useState(false)
  const [loading] = useState<boolean>(false)
  const [timeleft, setTimeleft] = useState<string | null>(null)
  const [activeTab, setActiveTab] = useState('profile')
  const [statusLoading, setStatusLoading] = useState(false)
  const [activeProfile, setActiveProfile] = handleActiveProfileIndex
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  const [remainingSpots, setRemainingSpots] = useState<number>(0)
  const [interestedTalents, setInterestedTalents] = useState<IProjectTalentStatus[]>([])
  const [isOfferBooking, setIsOfferBooking] = useState(false)

  // API CALLS
  const [getBookingStatusCount] = useLazyQuery(listProjectTalentStatus, { fetchPolicy: 'network-only' })
  const [updateTalentStatus] = useMutation(updateUGCProjectTalentStatus, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: getAssetsListProjectTalentStatus,
        variables: { projectId, statuses: ['wrapped', 'booking_requested', 'booking_confirmed'], limit: 100, offset: 0 }
      }
    ]
  })

  const fetchTotalSpot = async (): Promise<void> => {
    try {
      const bookingRequestedResponse = await getBookingStatusCount({ variables: { projectId, offset: 0, limit: 150 } })

      const bookingConfirmed = []
      const bookingRequested = []
      const data = bookingRequestedResponse?.data?.listProjectTalentStatus ?? []

      for (const item of data) {
        const isMoreThan24Hours: boolean = isMoreThan24HoursApart(item.updatedAt)
        switch (item.status) {
          case 'booking_requested':
            if (!isMoreThan24Hours) {
              bookingRequested.push(item)
            }
            break
          case 'booking_confirmed':
            bookingConfirmed.push(item)
            break
          default:
            break
        }
      }
      const offeredCount = bookingRequested?.length ?? 0
      const confirmedCount = bookingConfirmed?.length ?? 0

      setRemainingSpots(overview?.numberOfCreators !== undefined ? overview.numberOfCreators - confirmedCount - offeredCount : 0)
    } catch (e) {
      console.error(e, 'err')
    }
  }

  useEffect(() => {
    if (interestedTalentsFromContext.length > 0) {
      updateInterestedTalentsValue()
    }
  }, [interestedTalentsFromContext.length > 0])

  const handleProfile = (action: string): void => {
    updateInterestedTalentsValue()
    if (action === 'next') {
      if (activeProfile === interestedTalents.length - 1) {
        return
      }
      setActiveProfile(activeProfile + 1)
    } else {
      setActiveProfile(activeProfile > 0 ? activeProfile - 1 : 0)
    }
  }

  // fetching the total spots
  useEffect(() => {
    if (overview?.numberOfCreators > 0 && interestedTalents?.length > 0) {
      void fetchTotalSpot()
    }
  }, [overview?.numberOfCreators, interestedTalents?.length > 0])

  const handleKeyboardKeyPressed = (e: KeyboardEvent<HTMLImageElement>): void => {
    if (e.code === 'ArrowRight') {
      handleProfile('next')
    } else if (e.code === 'ArrowLeft') {
      handleProfile('previous')
    } else if (e.code === 'Space') {
      !isVideoPlaying && setIsVideoPlaying(true)
      if (videoPlayerRef?.current !== null) {
        videoPlayerRef.current.togglePlay()
      }
    } else if (e.code === 'Escape') {
      handleToggleSidebar(false)
    }
  }

  const updateInterestedTalentsValue = (): void => {
    if (JSON.stringify(interestedTalents) !== JSON.stringify(interestedTalentsFromContext)) {
      setInterestedTalents(interestedTalentsFromContext?.filter((item) => item?.talent !== null))
    }
  }
  const findIndex = interestedTalents.findIndex((item) => item?.talentId === activeProfileId)
  const focusedTalentId = interestedTalents[activeProfile]?.talentId

  // resetting the video playing state when the drawer is closed
  useEffect(() => {
    setIsVideoPlaying(false)
  }, [activeProfile, activeTab])

  // updating the profile data when the drawer is open
  useEffect(() => {
    if (focusedTalentId?.length > 0 && isOpen) {
      updateProfileData(focusedTalentId)
    }
  }, [focusedTalentId, isOpen])

  // setting the active tab based on the talent status
  useEffect(() => {
    hasAudition ? setActiveTab('audition') : setActiveTab('profile')
  }, [activeProfile])

  useEffect(() => {
    if (findIndex !== -1) {
      setActiveProfile(findIndex)
    }
  }, [findIndex])

  const toggleOfferedModal = (status?: string): void => {
    if (status !== undefined) {
      status === 'offerBooking' ? setIsOfferBooking(true) : setIsOfferBooking(false)
    }
    setOpen(!open)
  }

  const handleBookingStatus = async (status: string): Promise<void> => {
    const { talentId, talent } = interestedTalents[activeProfile]

    try {
      setStatusLoading(true)
      const response = await updateTalentStatus({ variables: { projectId, talentId, status } })
      const updatedRecord = response.data.updateUGCProjectTalentStatus
      const updatedTalents = interestedTalentsFromContext.map(talent => {
        if (talent.talentId === updatedRecord.talentId) {
          const talentSelectedStatus = selectedTalents.includes(talentId) ? 'selected' : updatedRecord.status
          return { ...talent, status: status === 'interested' ? talentSelectedStatus : updatedRecord.status, updatedAt: updatedRecord.updatedAt }
        }
        return talent
      })

      UGCContextDispatch({ type: SET_INTERESTED_TALENTS, payload: sortTalents(updatedTalents) })
      toggleOfferedModal()
      handleToggleSidebar(!isOpen)
      await fetchTotalSpot()
      dispatch(
        showToast({
          message: isOfferBooking ? `You've successfully made an offer to ${talent?.firstname ?? ''} ${talent?.lastname ?? ''}.` : 'Offer cancelled successfully'
        })
      )
      setStatusLoading(false)
    } catch (error: any) {
      setStatusLoading(false)
      dispatch(
        showToast({
          isError: true,
          message: error?.message ?? 'Something went wrong'
        })
      )
    }
  }

  const interestedTalentStatus = interestedTalents[activeProfile]?.status

  /**
   * Used to check if the offer booking button should be hidden or not
   * @returns {boolean} - true if the button should be hidden
   */
  const isOfferBookingButtonHidden = (): boolean => {
    switch (interestedTalentStatus) {
      case 'wrapped':
        return true
      case 'paid':
        return true
      case 'booking_confirmed':
        return true
      case 'booking_offered':
      default:
        return false
    }
  }
  /**
   * Used to get the button config for the offer booking button
   */
  const buttonConfig = getButtonConfig(interestedTalentStatus ?? '', timeleft ?? '', remainingSpots, toggleOfferedModal, user?.isAdmin)

  const handleClose = (): void => {
    if (typeof handleToggleSidebar === 'function') {
      handleToggleSidebar(false)
    }
  }

  return (
    <div className={style.Drawer}>
      <Drawer open={isOpen} handleNavigate={handleKeyboardKeyPressed} onClose={handleClose}>
        <div className={style.Drawer__Wrapper}>
          <div className={style.Drawer__Header}>
            <h1 className={style.Drawer__Heading}>Submission Details</h1>
            <p className={style.Drawer__Description}>Have a look at an Audition / Profile Video to make a choice of best talent for your UGC project.</p>
            <div className={style.Drawer__Controlles}>
              <RenderIf isTrue={!loading}>
                <img className={style.Drawer__ActionArrow} src={ArrowBackward} onClick={() => { handleProfile('previous') }} />
                <span>{activeProfile + 1} / {interestedTalents.length}</span>
                <img className={classNames(style.Drawer__ActionArrow, style['Drawer__ActionArrow--right'])} src={ArrowBackward} onClick={() => { handleProfile('next') }} />
              </RenderIf>
              <RenderIf isTrue={loading}>
                <Skeleton variant='rounded' height={40} width='100%' />
              </RenderIf>
            </div>
          </div>
          <DrawerBody key={focusedTalentId} handleTimeLeft={[timeleft, setTimeleft]} activeTab={activeTab} interestedTalents={interestedTalents} activeProfile={activeProfile} loading={loading} isLandscape={isLandscape}
            setActiveTab={setActiveTab} setIsVideoPlaying={setIsVideoPlaying} isVideoPlaying={isVideoPlaying} videoPlayerRef={videoPlayerRef} hasAudition={hasAudition} />
          <RenderIf isTrue={!loading}>
            <div className={style.Drawer__Footer}>
              <Button outline backgroundColor={'white'} onClick={() => { typeof handleToggleSidebar === 'function' && handleToggleSidebar(!isOpen) }}><b className={style.Button__Text}>Cancel</b></Button>
              <div>
                <RenderIf isTrue={BookTalentFeature.on && !isOfferBookingButtonHidden()}>
                  <Button outline disabled={buttonConfig.isButtonDisabled} onClick={buttonConfig.functionToCall}>
                    <b className={style.Button__Text}>{interestedTalentStatus === 'booking_requested' && timeleft !== 'expired' ? 'Cancel offer' : 'Offer booking'}</b>
                  </Button>
                </RenderIf>
                <Button disabled={isSelected || isLoading} onClick={() => { handleToggleAddToSelectSidebar() }}>
                  <>{isSelected && <img src={WhiteTick} />}<b className={classNames(style.Text__White, style.Button__Text, isSelected ? style['Button__Text--Selected'] : '')}>{isSelected ? 'Selected' : 'Add to select'}</b></>
                </Button>
              </div>
            </div>
          </RenderIf>
        </div>
      </Drawer>
      <UGCConfirmationModal
        open={open} closeOfferedModal={toggleOfferedModal} title={isOfferBooking ? 'Offer to book?' : 'Cancel Offer'}
        handleBookingStatus={isOfferBooking ? () => { void handleBookingStatus('booking_requested') } : () => { void handleBookingStatus('interested') }}
        loading={statusLoading}
        description={
          isOfferBooking
            ? `Great choice! We just want to confirm that you want to offer to book ${interestedTalents[activeProfile]?.talent?.firstname ?? ''}, after which you will have ${Math.max(remainingSpots - 1, 0)} spots remaining.`
            : 'After canceling, the talent will no longer be able to respond to the offer, but will remain in your Talent tab.  '
        }
      />
    </div>
  )
}
