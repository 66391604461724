import { useDispatch } from 'react-redux'
import { gql, useMutation } from '@apollo/client'

import { showToast } from '../../redux/actions/DashBoardActions'



export function useCreateShortLink () {
  // Hook
  const dispatch = useDispatch()
  // Mutation
  const [createShortLinkMutation, { loading }] = useMutation(gql`
      mutation createShortLink($href: String!, $slug: String, $overwrite: Boolean) {
        createShortLink(href: $href, slug: $slug, overwrite: $overwrite) {
          url
        }
      }
    `, {
    notifyOnNetworkStatusChange: true
  })

  const createShortLink = (variables, onSuccess) => {
    createShortLinkMutation({
      variables: {
        ...variables
      }
    })
      .then(res => {
        onSuccess(res.data.createShortLink?.url)
      })
      .catch(e => dispatch(showToast({
        message: 'Internal server Error.',
        isError: true
      })))
  }
  return [createShortLink, { loading }]
}
