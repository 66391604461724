import styled from 'styled-components'

import Logo from '../../../../components/Logo'
import { Colors } from '../../../../constants'

const Container = styled.div`
  height: 83px;
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-column: 1fr;
  background: ${Colors.brightGray};
`

const NavBar = () => {
  return (
    <div>
      <Container>
        <Logo color={Colors.mediumSeaGreen} />
      </Container>
    </div>
  )
}

export default NavBar
