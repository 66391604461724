import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import TalentAPI from '../../../services/Talent/TalentAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'

export function useUpdateTalentMutation () {
  // hooks
  const dispatch = useDispatch()
  const [UpdateTalentMutation, { loading }] = useMutation(
    TalentAPI.updateTalent(),
    { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true, errorPolicy: 'all' }
  )

  const updateTalent = (id, updatedValue, onSuccess) => {
    UpdateTalentMutation({
      variables: {
        talentUpdateId: id,
        ...updatedValue
      }
    })
      .then(res => {
        onSuccess(res)
        dispatch(
          showToast({
            message: 'Talent updated successfully.',
            isError: false
          })
        )
      })
      .catch(e => {
        dispatch(
          showToast({
            message: e?.networkError?.result?.errors[0]?.message ?? e?.message,
            isError: true
          })
        )
      })
  }
  return {
    updateTalent,
    loading
  }
}

export function useUpdateTalentProfileVideo () {
  const dispatch = useDispatch()
  const [updateProfileVideoMutation, { loading }] = useMutation(TalentAPI.updateProfileVideo, { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true, errorPolicy: 'all' })

  const updateProfileVideo = (talentId, videoId) => {
    updateProfileVideoMutation({ variables: { talentId, videoId } })
      .then(res => {
        dispatch(
          showToast({
            message: 'Profile video updated successfully.',
            isError: false
          })
        )
      })
      .catch(e => {
        dispatch(
          showToast({
            message: e?.networkError?.result?.errors[0]?.message ?? e?.message,
            isError: true
          })
        )
      })
  }

  return [
    updateProfileVideo,
    loading
  ]
}

export default useUpdateTalentMutation
