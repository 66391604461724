import types from '../types/types'

export const setTalentVideoList = videoList => ({
  type: types.SET_VIDEO_LIST,
  payload: videoList
})

export const appendTalentVideoList = videoList => {
  return {
    type: types.APPEND_VIDEO_LIST,
    payload: videoList
  }
}

export const setFinalVideoList = videoList => {
  return {
    type: types.SET_FINAL_VIDEO_LIST,
    payload: videoList
  }
}

export const setVideoProgress = progress => ({
  type: types.SET_VIDEO_PROGRESS,
  payload: progress
})

export const setUploadedVideoStatus = payload => ({
  type: types.SET_VIDEO_UPLOAD_STATUS,
  payload
})

export const setVideoError = payload => ({
  type: types.SET_VIDEO_ERROR,
  payload
})
