/* eslint-disable no-unused-vars */
import { ErrorMessage, Field } from 'formik'
import styled from 'styled-components'

import Chip from '@mui/material/Chip'
import { Box, Skeleton, Stack } from '@mui/material'

import { RenderIf } from '../../../utils/Helpers'
import { Fragment } from 'react'
import { TextError } from '../textError'

const LabelText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 10px;
`
const CheckBoxesWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.length > 2 ? 'space-between' : '')};
`

const checkbox = props => {
  const { label, items, name, loading = false } = props
  const shimmerCount = new Array(4).fill(0).map((_, index) => index)
  return (
    <Box sx={{ width: '100%' }}>
      <LabelText>{label}</LabelText>
      <RenderIf isTrue={!loading}>
        <CheckBoxesWrapper length={items.length}>
          <Box>
            <Field name={name}>
              {fieldProps => {
                const { field, form, meta } = fieldProps
                const { setFieldValue } = form

                return (
                  <Fragment>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {items.map((item, index) => {
                        return (
                          <Chip
                            key={index}
                            variant={
                              field.value.includes(item.label)
                                ? 'filled'
                                : 'outlined'
                            }
                            onClick={() => {
                              field.value.includes(item.label)
                                ? setFieldValue(name, [
                                  ...field.value.filter(
                                    data => data !== item.label
                                  )
                                ])
                                : setFieldValue(name, [
                                  ...field.value,
                                  item?.label
                                ])
                            }}
                            label={item?.label}
                          />
                        )
                      })}
                    </Box>
                    <Box mt={2}>
                      <ErrorMessage name={name} component={TextError} />
                    </Box>
                  </Fragment>
                )
              }}
            </Field>
          </Box>
        </CheckBoxesWrapper>
      </RenderIf>
      <RenderIf isTrue={loading}>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {shimmerCount.map((e, i) => (
            <Skeleton
              key={`shimmer-${i}`}
              variant="rectangular"
              width={50}
              height={30}
              sx={{ borderRadius: 10 }}
            />
          ))}
        </Stack>
      </RenderIf>
    </Box>
  )
}

export default checkbox
