/* eslint-disable no-unused-vars */
// import React from 'react'
import { Fragment } from 'react'

import PropTypes from 'prop-types'

import EditUser from '../EditUser'
import DeleteUser from '../DeleteUser'
import { TlyntSidebar } from '../../../../components'
import { RenderIf } from '../../../../utils/Helpers'

const Sidebars = props => {
  const {
    activeUserTab,
    isDeleteUserSidebarVisible,
    toggleDeleteUserSidebarVisible,
    handleDeleteData,
    // props for edit
    handleMasterData,
    masterData,
    isEditUserSidebarVisible,
    toggleEditUserSidebarVisible
  } = props
  return (
    <Fragment>
      <RenderIf isTrue={isDeleteUserSidebarVisible}>
        <TlyntSidebar>
          <DeleteUser
            activeUserTab={activeUserTab}
            handleDeleteData={handleDeleteData}
            toggleDeleteUserSidebarVisible={toggleDeleteUserSidebarVisible}
          />
        </TlyntSidebar>
      </RenderIf>
      <RenderIf isTrue={isEditUserSidebarVisible}>
        <TlyntSidebar>
        <EditUser
          masterData={masterData}
          activeUserTab={activeUserTab}
          handleMasterData={handleMasterData}
          isEditUserSidebarVisible={isEditUserSidebarVisible}
          toggleEditUserSidebarVisible={toggleEditUserSidebarVisible}
          />
        </TlyntSidebar>
      </RenderIf>
    </Fragment>
  )
}

Sidebars.propTypes = {
  activeUserTab: PropTypes.object,
  isDeleteUserSidebarVisible: PropTypes.bool,
  toggleDeleteUserSidebarVisible: PropTypes.func,
  handleDeleteData: PropTypes.func,
  isEditUserSidebarVisible: PropTypes.bool,
  toggleEditUserSidebarVisible: PropTypes.func,
  handleMasterData: PropTypes.func,
  masterData: PropTypes.array
}

export default Sidebars
