import { useState, createContext, useContext } from 'react'

import { decodeJwt } from 'jose'
import PropTypes from 'prop-types'
import { useLocation, Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import analytics from '../utils/Analytics'
import { API } from '../constants'
import { logout, getPublicToken } from './RestAPI'

export const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

let fetchTokenPromise = null

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const signin = async ({ access, refresh, id }, callback) => {
    localStorage.setItem('access_token', access)
    localStorage.setItem('refresh_token', refresh)
    localStorage.setItem('id_token', id)

    try {
      const idToken = await decodeJwt(id)
      const accessToken = await decodeJwt(access)
      if (idToken && accessToken) {
        Sentry.setUser({
          id: idToken.sub,
          role: accessToken.role,
          type: accessToken.type,
          segment: accessToken.type
        })
        analytics.setUserId(idToken.sub)
        analytics.setUserProperties({
          role: accessToken.role,
          type: accessToken.type
        })
        if (accessToken.type === 'admin') {
          analytics.setInternalTraffic()
        }
        setUser(idToken)
        analytics.logEvent('login')
        callback()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const updateTokens = (access, refresh) => {
    localStorage.setItem('access_token', access)
    localStorage.setItem('refresh_token', refresh)
  }

  const checkTokens = async (callback = () => {}) => {
    const refreshToken = decodeJwt(localStorage.getItem('refresh_token'))
    if (refreshToken?.exp < Math.floor(new Date().getTime() / 1000)) {
      signout()
    }
    try {
      const payload = decodeJwt(localStorage.getItem('access_token'))
      if (payload?.exp < Math.floor(new Date().getTime() / 1000 + 300)) {
        throw Error('Token will expire in less than 5 minutes')
      }
    } catch (warning) {
      try {
        const { accessToken, refreshToken } = await refreshTokens(localStorage.getItem('refresh_token'))
        if (accessToken && refreshToken) {
          updateTokens(accessToken, refreshToken)
        } else {
          throw Error('No tokens found')
        }
      } catch (err) {
        console.error(warning, err)
      }
      fetchTokenPromise = null
    }
  }

  const checkPublicToken = async () => {
    const payload = await decodeJwt(localStorage.getItem('public_token'))
    try {
      if (payload?.exp < Math.floor(new Date().getTime() / 1000 + 300)) {
        throw Error('Token will expire in less than 5 minutes')
      }
    } catch (warning) {
      console.warn(warning)
      getPublicToken(payload.sub, undefined, payload.scope, payload.password)
        .then((data) => {
          localStorage.setItem('public_token', data.accessToken)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  const reload = async (callback) => {
    const access = localStorage.getItem('access_token')
    const refresh = localStorage.getItem('refresh_token')
    const id = localStorage.getItem('id_token')

    if (access && refresh && id) {
      signin({ access, refresh, id }, callback)
    }
  }

  const signout = async (callback) => {
    await logout()
    localStorage.clear()

    Sentry.setUser(null)
    setUser(null)

    analytics.logEvent('logout')

    callback()
  }

  const refreshTokens = async (refreshToken) => {
    if (fetchTokenPromise) {
      return fetchTokenPromise
    } else {
      if (!window.location.href.includes('public')) {
        fetchTokenPromise = fetch(`${API.auth}/token?refresh_token=${refreshToken}`)
          .then(r => r.json())
          .catch(() => signout())
      }
      return fetchTokenPromise
    }
  }
  const value = { user, checkTokens, signin, signout, reload, checkPublicToken }

  return <AuthContext.Provider value={value}>{ children }</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node
}

export const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth?.user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.node
}
