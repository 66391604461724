import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Images } from '../../../assets'
import { RenderIf } from '../../../utils/Helpers'
import { Button, Typography } from '../../../components'
import TalentCardSizeModal from './TalentCardSizeCTA'

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 42px;
`

const FilterImage = styled.img`
  height: 16px;
  margin-right: 12px;
`

const FilterContainer = styled.div`
  display: flex;
  position: relative;
`

const FilterAppliedIndicator = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #4caf50;
  position: absolute;
  margin: -3px 0 0 10px;
  display: ${props => (props.hide ? 'none' : '')};
`

const FilterWrapper = styled.div``

const HeaderBottom = props => {
  const {
    isUserTalent,
    setShowFilters,
    showFilters,
    showFilterHighlight,
    handleTalentCardSize,
    resizeType,
    setIsSelectionMode,
    isSelectionMode,
    handleRightButtonClick,
    selectedTalentlength
  } = props
  return (
    <ButtonView>
      <FilterContainer>
       <RenderIf isTrue={!isUserTalent}>
        <Button
          onClick={() => setShowFilters(!showFilters)}
          backgroundColor='white'
          style={{ marginRight: 20 }}>
          <FilterWrapper>
            <FilterAppliedIndicator hide={showFilterHighlight} />
            <FilterImage src={Images.filter} />
          </FilterWrapper>
          <Typography
            fontWeight={'bold'}
            fontSize={15}
            letterSpacing={0.46}>FILTERS</Typography>
        </Button>
      </RenderIf>
      </FilterContainer>
      <TalentCardSizeModal
        handleTalentCardSize={handleTalentCardSize}
        resizeType={resizeType}
      />
      <RenderIf isTrue={!isUserTalent}>
       <Button
         onClick={() => setIsSelectionMode(!isSelectionMode)}
         backgroundColor={isSelectionMode ? 'white' : 'black'}
         outline={!isSelectionMode}
         style={{ margin: '0 16px' }}>
         <Typography
          fontWeight={'bold'}
          fontSize={15}
          letterSpacing={0.46}>{isSelectionMode ? 'CANCEL' : 'SELECT TALENT'} </Typography>
       </Button>
       <Button
          onClick={handleRightButtonClick}
         disabled={isSelectionMode ? selectedTalentlength < 1 : false}>
         <Typography
          color='white'
          fontWeight={'bold'}
          fontSize={15}
          letterSpacing={0.46}>
           {isSelectionMode ? 'ADD TO SELECT' : 'ADD TALENT'}
         </Typography>
       </Button>
       </RenderIf>
    </ButtonView>
  )
}

HeaderBottom.propTypes = {
  isUserTalent: PropTypes.bool,
  setShowFilters: PropTypes.func,
  showFilters: PropTypes.bool,
  showFilterHighlight: PropTypes.bool,
  handleTalentCardSize: PropTypes.func,
  resizeType: PropTypes.string,
  setIsSelectionMode: PropTypes.func,
  isSelectionMode: PropTypes.bool,
  handleRightButtonClick: PropTypes.func,
  selectedTalentlength: PropTypes.number
}

export default HeaderBottom
