import PropTypes from 'prop-types'

const Logo = ({ color = 'black', background = 'white' }) => {
  return (
    <svg width="108" height="33" viewBox="0 0 108 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2180_37697)">
        <path d="M31.157 0H28.192V32.1962H31.157V0Z" fill={ color }/>
        <path d="M27.344 0H24.379V32.1962H27.344V0Z" fill={ color }/>
        <path d="M43.563 29.226H32.006V32.1962H43.563V29.226Z" fill={ color }/>
        <path d="M43.563 25.4052H32.006V28.3754H43.563V25.4052Z" fill={ color }/>
        <path d="M23.531 0H0V2.97028H23.531V0Z" fill={ color }/>
        <path d="M15.1549 7.64062H12.1899V32.1963H15.1549V7.64062Z" fill={ color }/>
        <path d="M23.531 3.81982H0V6.7901H23.531V3.81982Z" fill={ color }/>
        <path d="M11.341 7.64062H8.37598V32.1963H11.341V7.64062Z" fill={ color }/>
        <path d="M44.411 32.1962V17.462L33.363 0H36.874L47.376 16.5995V32.1962H44.411Z" fill={ color }/>
        <path d="M48.2241 32.1962V16.3531L37.8781 0H41.3881L51.1891 15.5306V32.1962H48.2241Z" fill={ color }/>
        <path d="M47.613 8.2697L52.832 0H56.341L49.361 11.0316L47.613 8.2697Z" fill={ color }/>
        <path d="M99.6239 7.64062H96.6589V32.1963H99.6239V7.64062Z" fill={ color }/>
        <path d="M95.81 7.64062H92.845V32.1963H95.81V7.64062Z" fill={ color }/>
        <path d="M108 0H84.469V2.97028H108V0Z" fill={ color }/>
        <path d="M108 3.81982H84.469V6.7901H108V3.81982Z" fill={ color }/>
        <path d="M49.863 11.826L57.341 0V5.54886L51.619 14.6L49.863 11.826Z" fill={ color }/>
        <path d="M64.9669 0H62.002V32.1962H64.9669V0Z" fill={ color }/>
        <path d="M61.154 0H58.189V32.1962H61.154V0Z" fill={ color }/>
        <path d="M75.8409 32.1962L65.8149 14.033V7.89404L79.2289 32.1962H75.8409Z" fill={ color }/>
        <path d="M80.1989 32.1962L65.8149 6.13791V0L83.5869 32.1962H80.1989Z" fill={ color }/>
        <path d="M80.656 25.0615V0H83.621V30.4331L80.656 25.0615Z" fill={ color }/>
        <path d="M76.843 18.1523V0H79.808V23.5248L76.843 18.1523Z" fill={ color }/>
      </g>
      <defs>
        <clipPath id="clip0_2180_37697">
          <rect width="108" height="32.1962" fill={ background } />
        </clipPath>
      </defs>
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string
}

export default Logo
