import { Fragment, useState, useRef, useEffect } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Collapse } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'

import { Images } from '../../../../../assets'
import { RenderIf } from '../../../../../utils/Helpers'

import { Colors } from '../../../../../constants'
import ProjectOverviewLeftForm from './ProjectOverviewLeftForm'
import ProjectOverviewRightForm from './ProjectOverviewRightForm'
import { showToast } from '../../../../../redux/actions/DashBoardActions'
import { Typography, Button, ShimmerContentBox } from '../../../../../components'

import { ProjectRolesValidationSchema } from '../../../../../services/ValidationServices'

import useGetSelectionData from '../../../../TalentDetailMain/Hooks/useGetSelectionData'
import useProjectRoleListQuery from '../../../../ProjectDetail/Hooks/useProjectRoleListQuery'
import useDuplicateRoleFeature from '../../../Hooks/useDuplicateRoleFeature'
import { useMutation } from '@apollo/client'
import ProjectAPI from '../../../../../services/Project/ProjectAPI'
import { DELETE_SELECT } from '../../../../../services/API'
import { SpinnerSmall } from '../../../../../components/Progress'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-inline: 40px;
  overflow: auto;
`

const FormikFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
// --- Role header start ---
const CollapseHeader = styled.div`
  padding: 24px 0;
  border-bottom: 1px ${Colors.inputGray}
    ${props => (!props.isLast ? 'solid' : '')};
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`
const RoleHeaderTileWrapper = styled.div`
 display: flex;
 align-items: center;
`

const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 160%;
`
const HeaderTitleInput = styled.input`
  font-size: 20px;
  font-weight: 700;
  line-height: 160%;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  outline: none;
`

// --- Role header end ---

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const BottomContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
// Role open/close/delete icons
const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 30px;
  }
`
const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const CollapsIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const ShimmerRowWrapper = styled.div`
  display: flex;
  flexDirection: row;
  width: 100%;
  margin: 15px 0 30px 0;
`

const RolesDetails = (props) => {
  // props
  const {
    resetProjectList,
    handleToggle,
    roleDates,
    finalSubmit,
    finalUpdatedSubmit,
    setProjectStatus,
    dataLoading
  } = props

  // hooks
  const { id } = useParams()
  const formikRef = useRef(null)
  const deletedRoleIndexRef = useRef(null)
  const dispatch = useDispatch()
  const scrollViewRef = useRef()
  const duplicateRoleFeature = useDuplicateRoleFeature()

  // API CALLS
  const { data: demographicData } = useGetSelectionData()
  const { data: projectRoleList, loading, refetch } = useProjectRoleListQuery(id)
  const [deleteRoleMutation, { loading: deleteRoleLoading }] = useMutation(ProjectAPI.deleteRoleProject(), { notifyOnNetworkStatusChange: true })
  const [selectDeleteMutation, { loading: deleteSelectLoading }] = useMutation(DELETE_SELECT, { notifyOnNetworkStatusChange: true })

  // states
  const [rolesCollapseData, setRolesCollapseData] = useState(id
    ? []
    : [{ show: true, isEditFocused: false }])

  const [isDraft, setIsDraft] = useState(false)

  useEffect(() => {
    if (!loading && projectRoleList?.projectRoleList?.length > 0) {
      setRolesCollapseData(projectRoleList?.projectRoleList.map((item, index) => ({
        show: index === 0,
        name: item?.name,
        isEditFocused: false
      })))
    }
  }, [loading])

  // ----------------------------- functions ----------------------------
  const handleAddMoreRole = arrayHelpers => {
    setRolesCollapseData([
      ...rolesCollapseData,
      {
        show: true,
        name: `Role ${rolesCollapseData.length + 1}`,
        isEditFocused: false
      }
    ])
    arrayHelpers.push({
      name: `Role ${rolesCollapseData?.length + 1}`,
      rate: '',
      startDate: roleDates.startDate,
      endDate: roleDates.endDate,
      age: [0, 100],
      weight: [0, 450],
      bodyType: ['Any'],
      height: ['1.0', '8.0'],
      hairColor: ['Any'],
      eyeColor: ['Any'],
      race: ['Any'],
      gender: ['Any'],
      genderExpression: ['Any'],
      sexualOrientation: ['Any'],
      ethnicity: ['Any'],
      isAgeAllowed: true,
      isWeightAllowed: true,
      isHeightAllowed: true,
      description: '',
      notes: '',
      quantity: 1
    })
  }

  const handleCopyRole = (arrayHelpers, formikV, index) => {
    setRolesCollapseData([
      ...rolesCollapseData,
      {
        show: true,
        name: `${formikV.name} - Copy`,
        isEditFocused: false
      }
    ])

    const updatedVal = { ...formikV }
    delete updatedVal.id
    updatedVal.name = `${formikV.name} - Copy`
    arrayHelpers.insert((parseInt(index) + 1), { ...updatedVal })
  }
  const handleSubmit = async data => {
    if (id) {
      const withIdList = data.roles.filter((role) => role.id)
      const withoutIdList = data.roles.filter((role) => !role?.id)
      const updatedRoles = { roles: withIdList }
      const newRoles = { roles: withoutIdList }
      finalUpdatedSubmit({
        updatedRoles,
        newRoles,
        toggleModal: async () => {
          handleToggle()
          dispatch(
            showToast({
              message: 'Project updated successfully',
              isError: false
            })
          )
          await refetch()
        },
        isUpdate: true
      })
    } else {
      finalSubmit({
        ...data,
        toggleModal: async () => {
          handleToggle()
          dispatch(
            showToast({
              message: 'Project created successfully',
              isError: false
            })
          )
          await resetProjectList()
        },
        isUpdate: false
      })
    }
  }

  const handleRoleNameClick = (e, index) => {
    e.stopPropagation()
    rolesCollapseData[index].isEditFocused =
      !rolesCollapseData[index]?.isEditFocused
    setRolesCollapseData([...rolesCollapseData])
  }

  const handleDeleteRole = async (arrayHelpers, role, index) => {
    deletedRoleIndexRef.current = index
    if (id && role?.id) {
      try {
        await Promise.all([deleteRoleMutation({ variables: { id: role?.id } }), selectDeleteMutation({ variables: { id: role?.selectId } })])
        dispatch(showToast({
          message: 'Role deleted successfully.',
          isError: false
        }))
        const remainingItems = rolesCollapseData.filter((item, idx) => { return idx !== index })
        setRolesCollapseData([...remainingItems])
        arrayHelpers.remove(index)
      } catch (error) {
        dispatch(showToast({
          message: error?.message ?? 'Internal server error.',
          isError: true
        }))
      }
      deletedRoleIndexRef.current = null
    } else {
      const remainingItems = rolesCollapseData.filter((item, idx) => { return idx !== index })
      setRolesCollapseData([...remainingItems])
      arrayHelpers.remove(index)
    }
  }

  const handleErrorCollapsableHeader = (formikData) => {
    if (formikData.errors.roles?.length > 0) {
      const dirtyRoleIndexArray = formikData.errors.roles.map((item, index) => item ? { dirtyArrayIndex: index } : undefined)

      for (const item of dirtyRoleIndexArray) {
        if (item) {
          rolesCollapseData[item.dirtyArrayIndex].show = true
          setRolesCollapseData(rolesCollapseData)
          return
        }
      }
    }
  }

  // ------------------------------- constants --------------------------------

  const defaultValues = projectRoleList?.projectRoleList.map((item) => ({
    name: item?.name,
    id: item.id,
    selectId: item?.selectId,
    rate: item.rate,
    startDate: item.startDate,
    endDate: item.endDate,
    age: [item.criteria?.age?.min ?? 0, item.criteria?.age?.max ?? 100],
    weight: [item.criteria.weight?.min ?? 0, item.criteria.weight?.max ?? 450],
    bodyType: item.criteria.bodyType,
    height: [item.criteria.height?.min.toString() ?? '1.0', item.criteria.height?.max.toString() ?? '8.0'],
    hairColor: item.criteria.hairColor,
    eyeColor: item.criteria.eyeColor,
    race: item.criteria.race,
    gender: item.criteria.gender,
    genderExpression: item.criteria.genderExpression,
    sexualOrientation: item.criteria.sexualOrientation,
    ethnicity: item.criteria.ethnicity,
    isAgeAllowed: item.criteria?.age !== null,
    isWeightAllowed: item.criteria?.weight !== null,
    isHeightAllowed: item.criteria?.height !== null,
    description: item.description,
    notes: item.notes,
    quantity: item?.quantity
  })) ?? {
    name: 'Role 1',
    rate: '',
    startDate: roleDates?.startDate ?? '',
    endDate: roleDates?.endDate ?? '',
    age: [0, 100],
    weight: [0, 450],
    bodyType: ['Any'],
    height: ['1.0', '8.0'],
    hairColor: ['Any'],
    eyeColor: ['Any'],
    race: ['Any'],
    gender: ['Any'],
    genderExpression: ['Any'],
    sexualOrientation: ['Any'],
    ethnicity: ['Any'],
    isAgeAllowed: true,
    isWeightAllowed: true,
    isHeightAllowed: true,
    description: '',
    quantity: 1,
    notes: ''
  }

  //  --------------------------------- components ------------------------------------

  const Loader = () => {
    return (
      <div style={{ paddingInline: 40 }} >
        <ShimmerContentBox style={{ height: 50 }}/>
        <ShimmerRowWrapper>
          <ShimmerContentBox style={{ height: 50 }} />
          <div style={{ width: 50 }} />
          <ShimmerContentBox style={{ height: 50 }} />
        </ShimmerRowWrapper>
     </div>
    )
  }

  return (
    <Fragment>
      <RenderIf isTrue={loading}>
        <Loader />
        <Loader />
      </RenderIf>
      <RenderIf isTrue={!loading}>
        <Wrapper ref={scrollViewRef}>
          <Formik
            enableReinitialize
            initialValues={{
              roles: projectRoleList?.projectRoleList ? defaultValues : [defaultValues]
            }}
            validationSchema={ ProjectRolesValidationSchema }
            innerRef={formikRef}
            onSubmit={handleSubmit}>
            {formik => (
              <Form style={{ width: '100%' }}>
                <FieldArray
                  name="roles"
                  render={arrayHelpers => (
                    <Fragment>
                      {formik.values.roles &&
                        formik.values.roles.length > 0 &&
                        formik.values.roles.map((role, index) => (
                          <Fragment key={index}>
                          <CollapseHeader onClick={() => {
                            rolesCollapseData[index].show = !rolesCollapseData[index].show
                            setRolesCollapseData([...rolesCollapseData])
                          }}>
                              <RenderIf isTrue={rolesCollapseData[index]?.isEditFocused}>
                               <div>
                                <HeaderTitleInput
                                  style={{
                                    borderColor: formik.errors?.roles?.[index]?.name ? Colors.errorRed : Colors.black
                                  }}
                                  name={`roles.${index}.name`}
                                  value={formik.values.roles[index]?.name}
                                  onChange={e => formik.setFieldValue(`roles.${index}.name`, e.target.value)}
                                  onBlur={e => {
                                    if (!formik.errors?.roles?.[index]?.name) {
                                      handleRoleNameClick(e, index)
                                    }
                                  } }
                                  />
                                <RenderIf isTrue={formik.errors?.roles?.length > 0}>
                                  <Typography color={Colors.errorRed} fontWeight={300} fontSize={14} style={{ marginTop: 10 }} >
                                  {formik.errors && formik.errors?.roles?.length > 0 ? formik.errors?.roles[index]?.name : null }
                                  </Typography>
                                </RenderIf>
                              </div>
                              </RenderIf>
                              <RenderIf isTrue={!rolesCollapseData[index]?.isEditFocused}>
                              <div>
                                <RoleHeaderTileWrapper>
                                  <HeaderTitle>
                                    {formik.values.roles[index]?.name}
                                  </HeaderTitle>
                                  <Box onClick={e => handleRoleNameClick(e, index)}>
                                    <Typography fontSize={16} style={{ marginLeft: 15, cursor: 'pointer' }} >
                                      Edit
                                    </Typography>
                                  </Box>
                                </RoleHeaderTileWrapper>
                                <RenderIf isTrue={formik.errors?.roles?.length > 0}>
                                  <Typography color={Colors.errorRed} fontWeight={300} fontSize={14} style={{ marginTop: 10 }} >
                                  {formik.errors && formik.errors?.roles?.length > 0 ? formik.errors?.roles[index]?.name : null }
                                  </Typography>
                                </RenderIf>
                              </div>
                              </RenderIf>
                              <IconWrapper>
                                <RenderIf isTrue={duplicateRoleFeature}>
                                  <DeleteIcon src={Images.copy}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleCopyRole(arrayHelpers, formik.values.roles[index], index)
                                    }}
                                  />
                                </RenderIf>
                                <RenderIf isTrue={formik.values.roles.length > 1}>
                                {(deleteRoleLoading || deleteSelectLoading) && deletedRoleIndexRef.current === index ? <SpinnerSmall /> : <DeleteIcon src={Images.delete} onClick={(e) => { e.stopPropagation(); handleDeleteRole(arrayHelpers, formik.values.roles[index], index) } } /> }
                                </RenderIf>
                                <CollapsIcon src={rolesCollapseData[index]?.show ? Images.minusCollaps : Images.plusCollaps} />
                              </IconWrapper>
                            </CollapseHeader>
                            <Collapse in={rolesCollapseData[index]?.show}>
                              <FormikFormWrapper key={index}>
                                <ProjectOverviewLeftForm key={loading} index={index} values={formik.values.roles} demographicData={demographicData?.getTalentDemographicSelectionData} />
                                <ProjectOverviewRightForm index={index} demographicData={demographicData?.getTalentDemographicSelectionData} />
                              </FormikFormWrapper>
                            </Collapse>
                          </Fragment>
                        ))}
                      <ButtonWrapper>
                        <Button outline backgroundColor={Colors.greenBlue} onClick={() => handleAddMoreRole(arrayHelpers)}>
                          <Typography color={Colors.greenBlue} fontWeight='bold'>+ ADD ANOTHER ROLE</Typography>
                        </Button>
                      </ButtonWrapper>
                    </Fragment>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
        <BottomContainer>
          <Button
            backgroundColor={Colors.white}
            onClick={handleToggle}>
            <Typography fontWeight='bold' fontSize={15} letterSpacing='0.46px'>CANCEL</Typography>
          </Button>
          <Button
          outline
          loading={isDraft && dataLoading}
          style={{ marginRight: 20 }}
          onClick={() => {
            setIsDraft(true)
            formikRef?.current?.submitForm()
            setProjectStatus('draft')
          }}>
            <Typography
              fontSize={15}
              fontWeight='bold'
              letterSpacing='0.45px'>
              { id ? 'UPDATE DRAFT' : 'SAVE AS DRAFT'}
            </Typography>
          </Button>
          <Button
          style={{ marginRight: 40 }}
          loading={!isDraft && dataLoading}
          onClick={() => {
            handleErrorCollapsableHeader(formikRef.current)
            formikRef?.current?.submitForm()
            if (formikRef?.current?.isValid) {
              setIsDraft(false)
              setProjectStatus('active')
            }
          }}>
            <Typography
              fontSize={15}
              fontWeight='bold'
              letterSpacing='0.45px'
              color={Colors.white}>
              { id ? 'UPDATE PROJECT' : 'PUBLISH PROJECT'}
            </Typography>
          </Button>
        </BottomContainer>
        </RenderIf>
    </Fragment>
  )
}

RolesDetails.propTypes = {
  resetProjectList: PropTypes.func,
  handleToggle: PropTypes.func,
  roleDates: PropTypes.object,
  firstData: PropTypes.object,
  finalSubmit: PropTypes.func,
  finalUpdatedSubmit: PropTypes.func,
  setProjectStatus: PropTypes.func,
  dataLoading: PropTypes.bool
}

export default RolesDetails
