import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: 32px;
  padding-inline: 40px;
  margin-bottom: 15px;
  position: ${props => (props?.stickey ? 'stickey' : '')};
  top: 0;
  background: white;
  z-index: 10;
  padding-bottom: 15px;
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DescriptionContainer = styled.div``
const BottomContainer = styled.div``

export const HeaderV2 = props => {
  const { title, titleRight, description, bottom, top, stickey = true, style } = props
  return (
    <Container style={{ ...style }} stickey={stickey}>
      {top}
      <TitleContainer>
        {title}
        {titleRight}
      </TitleContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
      <BottomContainer>{bottom}</BottomContainer>
    </Container>
  )
}

HeaderV2.propTypes = {
  title: PropTypes.node,
  titleRight: PropTypes.node,
  description: PropTypes.node,
  bottom: PropTypes.node,
  top: PropTypes.node,
  stickey: PropTypes.bool,
  style: PropTypes.object
}
