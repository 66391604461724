import types from '../types/types'

export const setBulkUploadList = uploadList => ({
  type: types.SET_BULK_UPLOAD_LIST,
  payload: uploadList
})

export const setSuccessUploadCount = () => ({
  type: types.SET_SUCCESS_COUNT
})

export const setFailUploadCount = () => ({
  type: types.SET_FAIL_COUNT
})
