import PropTypes from 'prop-types'

import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { Images } from '../../../../assets'
import { Colors } from '../../../../constants'
import { Typography } from '../../../../components'
import NavBar from '../../../PublicSelect/Components/Navbar'

const BackImage = styled.img`
  height: 16px;
  width: 16px;
`

const TabData = [
  {
    id: 0,
    title: 'Portfolio',
    value: 'portfolio'
  },
  {
    id: 1,
    title: 'Account Info',
    value: 'accountinfo'
  },
  {
    id: 2,
    title: 'Personal Info',
    value: 'personalinfo'
  }
]

const HeroSection = props => {
  // destructuring props
  const { isPublic, updateActiveTab, activeTabIndex } = props

  // getting url Params
  const { selectId } = useParams()

  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector(state => state.UserReducer)

  return (
    <Box>
      {isPublic && <NavBar />}
      {isPublic
        ? <Fragment>
            <Box ml='125px' borderBottom='1px solid #f0f0f0'>
              <Stack
                direction='row'
                margin='40px 0 20px 0'
                alignItems='center'
                width='fit-content'
                style={{ cursor: 'pointer' }} onClick={() => navigate(`/public/selects/${selectId}`)}>
                <BackImage src={Images.backArrowBlack} />
                <Typography style={{ marginLeft: 10 }}>Back to List</Typography>
              </Stack>
            </Box>
        </Fragment>
        : <Fragment>
          <Stack
            direction='row'
            margin='40px 0 20px 40px'
            alignItems='center'
            width='fit-content'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(!location.state?.isFromTalent ? '/talent' : -1)}>
            <BackImage src={Images.backArrowBlack} />
            <Typography style={{ marginLeft: 10 }}>Back</Typography>
          </Stack>
          <Stack flexDirection='row' mt='12px' pl='40px' borderBottom='1px solid #eeeeee'>
            {TabData.slice(0, user.isAdvertiser ? 1 : TabData.length).map((item, index) => (
              <Box
                borderBottom={`2px solid ${item?.id === activeTabIndex ? Colors.black : 'transprent'}`}
                sx={{ cursor: 'pointer', padding: '10px 8px' }}
                onClick={() => updateActiveTab(item?.id, index)}
                key={item.id}>
                <Typography
                  fontSize={16}
                  textTransform='capitalize'
                  color={item?.id === activeTabIndex ? Colors.black : Colors.spanishGray}
                >{item?.title}</Typography>
              </Box>
            ))}
          </Stack>
        </Fragment>
      }
    </Box>
  )
}

HeroSection.propTypes = {
  isPublic: PropTypes.bool,
  updateActiveTab: PropTypes.func,
  activeTabIndex: PropTypes.number
}

export default HeroSection
