import PropTypes from 'prop-types'
import { CircularProgress, LinearProgress as LinearProgressMUI } from '@mui/material'

export const SpinnerXtraSmall = () => {
  return <CircularProgress size={'10px'} sx={{ color: 'black' }} />
}

export const SpinnerSmall = () => {
  return <CircularProgress size={'20px'} sx={{ color: 'black' }} />
}

export const LinearProgress = (props) => {
  const { value } = props
  return (
    <LinearProgressMUI
      value={value}
      variant='determinate'
      sx={{ width: '85%', margin: 'auto', background: 'white', borderRadius: 10, height: 3 }}
    />
  )
}

LinearProgress.propTypes = {
  value: PropTypes.number.isRequired
}
