import { useQuery } from '@apollo/client'
import SelectAPI from '../../../services/Select/SelectAPI'
function useSelectListQuery (props) {
  const {
    notifyOnNetworkStatusChange = true,
    fetchPolicy = 'network-only'
  } = props
  const selectQuery = useQuery(SelectAPI.getListSelect(), {
    variables: { offset: 0, limit: 15, fetchParentSelects: true },
    notifyOnNetworkStatusChange,
    fetchPolicy
  })

  return selectQuery
}

export default useSelectListQuery
