import types from '../types/types'
import { UIConstants } from '../../constants/UIConstants'

export const getMenuDetails = () => ({
  type: types.GET__MENU_DETAILS,
  payload: { topMenu: UIConstants.TopMenu, bottomMenu: UIConstants.BottomMenu }
})

export const getSidebarStatus = data => ({
  type: types.SET_SIDEBAR_STATUS,
  payload: data
})

export const toggleSelectSubMenu = data => ({
  type: types.SET_SELECT_SUB_MENU_TOGGLE,
  payload: data
})
