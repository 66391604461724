import { gql } from '@apollo/client'

class SelectAPI {
  getChildSelectList () {
    return gql`
      query ChildSelectList($offset: Int, $limit: Int, $parentId: ID, $sort: String, $archived: Boolean){
        listSelects(offset: $offset, limit: $limit, parentId: $parentId, sort: $sort, archived: $archived){
        id
        name
        parentId
        talents 
        }
      }`
  }

  getSelectById () {
    return gql`
      query GetSelectById ( $id: ID!, $withBrand: Boolean ){
        getSelect (id: $id) {
          id
          name
          description
          parentId
          archived
          ownerId
          talents
          project {
            ... on CastingProject {
              id
              name
              type
              owner(withBrand: $withBrand) {
                id
                name
                logo {
                  uris {
                    ... on PhotoURLS {
                      logo
                    }
                  }
                }
                brands {
                  id
                  name
                  logo {
                    uris {
                      ... on PhotoURLS {
                        logo
                      }
                    }
                  }
                }
              }
              brandId
            }
            ... on UGCProject {
              id
              name
              type
              brandId
              deliverables {
                id
                type
                mediaType
                specs {
                  deliverableType
                  orientation
                }
              }
              owner(withBrand: $withBrand) {
                id
                name
                logo {
                  uris {
                    ... on PhotoURLS {
                      logo
                    }
                  }
                }
                brands {
                  id
                  name
                  logo {
                    uris {
                      ... on PhotoURLS {
                        logo
                      }
                    }
                  }
                }
              }
            }
          }
          owner {
            id
            name
            logo {
              uris {
                ... on PhotoURLS {
                  logo
                }
              }
            }
          }
        }
      }`
  }

  getSelectForCRUD () {
    return gql`
      query GetSelectById ( $id: ID! ){
        getSelect (id: $id) {
          id
          name
          description
          parentId
          archived
          ownerId
        }
      }`
  }

  getPublicSelectByIdPublic () {
    return gql`
      query GetSelectById ( $id: ID! ) {
        getSelect (id: $id) {
          id
          name
          description
          parentId
          ownerId
          mediaType
          project {
            ... on CastingProject {
              id
              name
              type
            }
            ... on UGCProject {
              id
              name
              type
              deliverables {
                id
                type
                mediaType
              }
            }
          }
        }
      }`
  }

  getPublicSelectById () {
    return gql`
      query GetSelectById ( $id: ID! ) {
        getSelect (id: $id) {
          id
          name
          description
          parentId
          ownerId
          mediaType
          project {
            ... on CastingProject {
              id
              name
              type
            }
            ... on UGCProject {
              id
              name
              type
              deliverables {
                id
                type
                mediaType
                specs {
                  deliverableType
                  orientation
                }
              }
            }
          }
        }
      }`
  }

  updateSelect () {
    return gql`
      mutation UpdateSelect ( $id: ID!, $name: String, $description: String, $talents: [ID!], $archived: Boolean  ){
        updateSelect(id: $id, name: $name, description: $description, talents: $talents, archived: $archived ){
        id
        name
      }
    }`
  }

  secureSelect () {
    return gql`
      mutation SecureSelect ( $secureSelectId: ID!, $password: String!, $protect: Boolean! ){
        secureSelect(id: $secureSelectId, password: $password, protect: $protect){
          status
          message
      }
    }`
  }

  getListSelect () {
    return gql`
    query ListSelects($parentId: ID, $offset: Int, $limit: Int, $sort: String, $ids: [ID!], $archived: Boolean, $fetchParentSelects: Boolean, $withBrand: Boolean) {
      listSelects(parentId: $parentId, offset: $offset, limit: $limit, sort: $sort, ids: $ids, archived: $archived, fetchParentSelects: $fetchParentSelects) {
        id
        name
        owner {
          logo {
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
        }
        project {
          ... on CastingProject {
            id
            name
            type
            brandId
            status
            owner(withBrand: $withBrand) {
              id
              name
              logo {
                uris {
                  ... on PhotoURLS {
                    logo
                  }
                }
              }
            }
          }
          ... on UGCProject {
            id
            name
            type
            brandId
            status
            owner(withBrand: $withBrand) {
              id
              name
              logo {
                uris {
                  ... on PhotoURLS {
                    logo
                  }
                }
              }
              brands {
                id
                name
                logo {
                  uris {
                    ... on PhotoURLS {
                      logo
                    }
                  }
                }
              }
            }
          }
        }
        parentId
        description
        ownerId
        talents
      }
    }`
  }

  getChildListSelect () {
    return gql`
    query ListSelects($parentId: ID, $offset: Int, $limit: Int, $sort: String, $ids: [ID!], $archived: Boolean, $fetchParentSelects: Boolean) {
      listSelects(parentId: $parentId, offset: $offset, limit: $limit, sort: $sort, ids: $ids, archived: $archived, fetchParentSelects: $fetchParentSelects) {
        id
        name
        parentId
        talents
      }
    }`
  }

  createSelect () {
    return gql`
    mutation CreateSelect($parentId: ID, $name: String, $description: String, $talents: [ID!], $ownerId: ID, $mediaType: Type) {
      createSelect(parentId: $parentId, name: $name, description: $description, talents: $talents, ownerId: $ownerId, mediaType: $mediaType) {
        id
        ownerId
      }
    }`
  }
}

export default new SelectAPI()
