import { useState } from 'react'

import { RenderIf } from '../../utils/Helpers'
import { URL } from '../../constants/URL'
import Formcomponent from './FormComponent'
import style from './ShareSelect.module.scss'

interface Props {
  selectId: string
  isVisibleModal: boolean
  toggleModal: (value: boolean) => void
}

export const ShareSelect = (props: Props): JSX.Element => {
  // props
  const { selectId, toggleModal, isVisibleModal } = props

  // state
  const [isSuccess, setIsSuccess] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const publicSelectURL = `${URL}/public/selects/${selectId}`

  return (
    <>
      <div className={style.Drawer__Container}>
        <h1 className={style.Drawer__HeaderText}>Share Select</h1>
        <p className={style.Drawer__HeaderDescriptionText}>You can copy the link and share it with anyone normally or with password protection.</p>

        <Formcomponent
          selectId={selectId}
          publicSelectURL={publicSelectURL}
          isCopied={isCopied}
          setIsCopied={setIsCopied}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
        />

        <RenderIf isTrue={isSuccess}>
          <div className={style.Drawer__ShareLinkContainer}>
            <p className={style.Drawer__ShareLinkText}>{publicSelectURL}</p>
          </div>
        </RenderIf>

      </div>
      <div className={style.Drawer__Footer}>
        <button onClick={() => { toggleModal(!isVisibleModal) }} className='primary-text-button'>Cancel</button>
      </div>
    </>
  )
}
