import { Provider } from 'react-redux'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { BrowserRouter as Router } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import { setContext } from '@apollo/client/link/context'
import { PersistGate } from 'redux-persist/integration/react'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'

import './App.css'
import './utils/Analytics'
import createStore from './redux/store'
import { GlobalToast } from './components'
import { URL } from './constants/URL'
import { AppRoutes } from './constants/routes'
import { API, Colors, Fonts } from './constants'
import { AuthProvider, useAuth } from './services/Auth'
import GrowthBookProvider from './services/Growthbook'

const ENV = process.env.REACT_APP_ENV

const httpLink = createHttpLink({
  uri: API.graphql
})

const client = authLink => new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          selectList: offsetLimitPagination(),
          projectList: offsetLimitPagination()

        }
      }
    }
  })
})

// create global theme for MUI components
const theme = createTheme({
  typography: {
    fontFamily: Fonts.degular
  },
  palette: {
    error: {
      main: Colors.errorRed
    }
  }
})

export const { store, persistor } = createStore()

const getToken = () => {
  const publicToken = localStorage.getItem('public_token')
  const privateToken = localStorage.getItem('access_token')

  return privateToken ?? publicToken

}

const AuthorizedApp = () => {
  const auth = useAuth()
  const authLink = setContext(async (_, { headers }) => {
    localStorage.getItem('access_token') && await auth.checkTokens(() => window.location.reload())
    localStorage.getItem('public_token') && await auth.checkPublicToken()

    return {
      headers: {
        ...headers,
        authorization: getToken()
      }
    }
  })

  return (
    <ApolloProvider client={client(authLink)}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
        {/* Applying theme for fonts */}
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <GlobalToast />
            <Router>
              <AppRoutes />
            </Router>
          </LocalizationProvider>
          </ThemeProvider>
          </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}

const App = () => {
  const urlInfo = window.location
  
  if( ENV === 'prod' && urlInfo.origin !== URL){
    urlInfo.replace(`${URL}${urlInfo.pathname}`)
  }

  if (!urlInfo.href.includes('public')) {
    localStorage.removeItem('public_token')
    localStorage.removeItem('public_user_id')
    sessionStorage.removeItem('protected_token')
  }

  return (
    <AuthProvider>
      <GrowthBookProvider>
        <AuthorizedApp />
      </GrowthBookProvider>
   </AuthProvider>
  )
}

export default App
