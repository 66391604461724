import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import { showToast } from '../../redux/actions/DashBoardActions'
import { useCreateShortLink } from '../../hooks/shortlink/'

import style from './link.module.scss'

/**
 * Handler to blindly update React State when a select is changed
 * @param value The current value of the boolean
 * @param setter React SetStateAction to save the selected option
 */
const handleCheckboxChange = (value: boolean, setter: (data: boolean) => void): void => {
  setter(!value)
}

/**
 * Handler to blindly update React State when a text input is changed
 * @param e Original HTML Input Change Event
 * @param setter React SetStateAction to save the updated value
 */
const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, setter: (data: any) => void): void => {
  setter(e.target.value)
}

interface ShortURLHandlers {
  showError: () => void
  showSuccess: () => void
  createShortURL: (data: any, onSuccess: any) => void
  setShortURL: (data: string) => void
}

/**
 * Handler that create a FormData object and calls the uploadMedia service on form submit
 * @param e Original HTML Form Submit Event
 * @param url ReactState representing the url to shorten
 * @param slug ReactState representing the desired slug in the short url
 * @param overwrite ReactState representing whether to overwrite an existing short url
 * @param handlers Object containing functions to show error and success toasts, create the short url
 */
const handleSubmit = (e: React.FormEvent<HTMLFormElement>, url: string, slug: string, overwrite: boolean, handlers: ShortURLHandlers): void => {
  e.preventDefault()
  e.stopPropagation()

  const opts: {
    href: string
    slug?: string
    overwrite?: boolean
  } = {
    href: url,
    slug,
    overwrite
  }

  if (url === '') {
    throw Error('No URL provided')
  }
  if (slug === '') {
    delete opts.slug
  }

  handlers.createShortURL(opts, (data: any) => {
    console.log('create short url', data)
    handlers.setShortURL(data)
    handlers.showSuccess()
  })
}

/**
 * Handler to copy the short url to clipboard
 */
const copyText = async (text: string, success: () => void): Promise<void> => {
  await navigator.clipboard.writeText(text).then(success)
}

export const ToolsLink = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.UserReducer)

  if (user.isAdmin === false) {
    navigate('/404/')
    return <></>
  }

  const [createShortURL, { loading: shortURLCreating }] = useCreateShortLink() as unknown as [any, { loading: boolean }]

  const [url, setURL] = useState<string>('')
  const [slug, setSlug] = useState<string>('')
  const [overwrite, toggleOverwrite] = useState<boolean>(false)

  const [shortURL, setShortURL] = useState<string>('')

  const showError = (): void => { dispatch(showToast({ isError: true, message: 'Something went wrong.' })) }
  const showSuccess = (): void => { dispatch(showToast({ message: 'Short URL created successfully.' })) }
  const copySuccess = (): void => { dispatch(showToast({ message: 'URL Copied.' })) }

  return (
    <Layout>
      <form onSubmit={(e) => { handleSubmit(e, url, slug, overwrite, { showError, showSuccess, createShortURL, setShortURL }) }}>
        <fieldset>
          <input type='text' name='url' placeholder='The destination URL' onChange={(e) => { handleTextChange(e, setURL) }} value={url} required />
        </fieldset>

        <fieldset>
          <input type='text' name='slug' placeholder='The short url slug to use (leave empty if want random one)' onChange={(e) => { handleTextChange(e, setSlug) }} value={slug} />
        </fieldset>

        <fieldset>
          <input type='checkbox' name='url' onChange={(e) => { handleCheckboxChange(overwrite, toggleOverwrite) }} checked={overwrite} /> Overwrite Slug
        </fieldset>

        <fieldset>
          <input type='submit' value='Create' />
        </fieldset>
      </form>

      { shortURLCreating && <div className={style.Loading}>Creating...</div> }

      { shortURL !== '' && <div className={style.ShortURL}>Short URL: <span className={style.Copiable} onClick={() => { void copyText(shortURL, copySuccess) }}>{shortURL}</span></div> }
    </Layout>
  )
}
