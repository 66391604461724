import classNames from 'classnames'

import { useListBrands } from '../../../../hooks/organization/brand'

import style from './Brands.module.scss'

const Brand = ({ brand }: any): JSX.Element => {
  return (
    <li className={classNames(style.Brands__Item, style.Brand)}>
      <figure className={style.Brand__Logo}>
        { brand.logo !== null && <img src={brand.logo?.uris?.logo} alt={brand.name} /> }
      </figure>
      <article className={style.Brand__Info}>
        { brand.name }
      </article>
    </li>
  )
}

interface Props {
  orgID: string
}

export const Brands = ({ orgID }: Props): JSX.Element => {
  const { data, loading } = useListBrands(orgID)

  if (loading) {
    return (<>Loading...</>)
  }

  return (
    <ul className={style.Brands}>
      { data?.listBrands?.map((brand: any) => (<Brand brand={brand} key={brand.id} />)) }
    </ul>
  )
}
