import { type Dispatch, useContext, type ChangeEvent, useState, useEffect } from 'react'

import { Skeleton, type SelectChangeEvent } from '@mui/material'
import moment, { type Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import classNames from 'classnames'

import { DatePicker } from '../../../DatePicker'
import { InputBox } from '../../../InputBox'
import { RadioInput } from '../../../RadioInput'
import { RenderIf } from '../../../../utils/Helpers'
import { Select } from '../../../Select'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { SpinnerSmall } from '../../../Progress'
import { type ApolloCache, ApolloError, type DefaultContext, type MutationTuple, type OperationVariables } from '@apollo/client'
import { type IProjectDetail, type IProjectDetailActions, UGCProjectDetailContext } from '../../../../contexts/projectDetail'
import { UGCSidebarContext } from '../../../../contexts/ugcSidebar'
import style from './ProjectDetail.module.scss'
import { SectionHeader } from '../../../UGCSectionHeader'
import type { UserState } from '../../../../redux/reducers/user'

import { URL } from '../../../../constants/URL'

const validationSchemaAdvertiser = z.object({
  dueDate: z.date().min(new Date(), 'Please enter a valid date').optional(),
  ownerId: z.string().nonempty('Organization is required').optional()
})

const validationSchemaAgency = z.object({
  dueDate: z.date().min(new Date(), 'Date must be in future').optional(),
  ownerId: z.string().nonempty('Organization is required').optional(),
  brandId: z.string().nonempty('Brand is required').optional()
})

const radioOptions = [
  {
    id: '1',
    value: true,
    label: 'Yes'
  },
  {
    id: '2',
    value: false,
    label: 'No'
  }
]

interface IListBrand {
  id: string
  name: string
  logo: {
    id: string
    uris: {
      logo: string
    }
    createdAt: string
  }
}

interface IProjectProduct {
  id: string
  ownerId: string
  projectId: string
  name: string
  url: string
  description: string[]
  provisioningMethod: string
  directions: string[]
}

interface ProjectDetailProps {
  organizationList: Array<{ id: string, value: string, logo: string, type: string }>
  dueDdate?: string
  hasProduct?: boolean
  productDescription?: string
  projectDetailQueryMutationObj: {
    useUpdateProjectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useCreateUGCProjectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useListBrandQuery: () => ({ data: { listBrands: IListBrand[] } | undefined, loading: boolean })
    useGetProductByProjectQuery: () =>({ data: { getProductByProject: IProjectProduct | null } | undefined })
    useDeleteProjectProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useSendNotificationMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
  }
  organisationListLoading: boolean
}

type IValidationError = Record<string, string | undefined>

// advertiser dropdown
interface AdvertiserDropdownProps {
  organizationList: Array<{ id: string, value: string, logo: string, type: string }>
  loading: boolean
  error?: string
  ownerId: string
  disabled?: boolean
  handleChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void
}

const AdvertiserDopdown = (props: AdvertiserDropdownProps): JSX.Element => {
  const { organizationList, loading, error, ownerId, handleChange, disabled = false } = props

  if (organizationList?.length > 0 && !loading) {
    return (
      <Select name='ownerId' isError={error !== undefined} errorText={error} value={ownerId ?? ''} onChange={(e) => { handleChange(e) } } label='Select' options={organizationList} disabled={disabled} />
    )
  } else {
    return <div className={style.ProjectDetail__DropdownShimmer}><Skeleton variant="rectangular" /></div>
  }
}
// brand dropdown
interface BrandDropdownProps {
  loading: boolean
  error?: string
  brandId: string
  listBrandsData?: { listBrands: IListBrand[] }
  handleChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void
}

const BrandDropdown = (props: BrandDropdownProps): JSX.Element => {
  const { loading, error, brandId, listBrandsData, handleChange } = props
  if (loading) {
    return (
      <div className={style.ProjectDetail__DropdownShimmer}><Skeleton variant="rectangular" /></div>
    )
  } else {
    return (
      <Select name='brandId' isError={ error !== undefined} errorText={error} key={brandId} value={brandId ?? ''} onChange={(e) => { handleChange(e) } } label='Brand override' options={((listBrandsData?.listBrands) != null) && listBrandsData?.listBrands?.length > 0 ? listBrandsData?.listBrands.map((item: IListBrand) => ({ id: item.id, logo: item?.logo?.uris?.logo, value: item?.name })) : []} />
    )
  }
}

const HeroShimmer = (): JSX.Element => {
  return (
    <>
     <div className={style.Skeleton__Heading}>
        <Skeleton variant='rectangular' />
      </div>
      <div className={style.Skeleton__Description}>
        <Skeleton variant='rectangular' />
      </div>
      <div className={style.Skeleton__Input}>
        <Skeleton variant='rectangular' />
      </div>
    </>
  )
}

export function ProjectDetail (props: ProjectDetailProps): JSX.Element {
  const { organizationList, projectDetailQueryMutationObj, organisationListLoading } = props
  const { useUpdateProjectMutation, useCreateUGCProjectMutation, useListBrandQuery, useGetProductByProjectQuery, useDeleteProjectProductMutation, useSendNotificationMutation } = projectDetailQueryMutationObj

  const user: UserState = useSelector((state: any) => state.UserReducer)

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [sidebarData, handleSidebarData] = useContext(UGCSidebarContext)
  const [projectDetailData, updateHandler] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)

  const [isClose, setIsClose] = useState(false)
  const [errors, setErrors] = useState<IValidationError>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [advertiserType, setAdvertiserType] = useState('advertiser')

  const { data: productData } = useGetProductByProjectQuery()
  const [deleteProjectProductMutation] = useDeleteProjectProductMutation()
  const { data: listBrandsData, loading: listBrandLoading } = useListBrandQuery()
  const [updateUGCProjectMutation] = useUpdateProjectMutation()
  const [createUGCProjectMutation] = useCreateUGCProjectMutation()
  const [sendNotificationMutation] = useSendNotificationMutation()

  const validationSchema = advertiserType === 'advertiser' ? validationSchemaAdvertiser : validationSchemaAgency

  useEffect(() => {
    if (projectDetailData.ownerId !== '' && organizationList.length > 0) {
      const org = organizationList.find((item) => item.id === projectDetailData.ownerId)
      setAdvertiserType(org?.type ?? 'advertiser')
    }
  }, [projectDetailData.ownerId, organizationList])

  useEffect(() => {
    if (user?.isAdvertiser) {
      updateHandler({ ownerId: user?.info?.org })
    }
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>): void => {
    const { name, value } = e.target
    updateHandler({ [name]: value })
    handleValidation(name, value)
  }

  const handleDateChange = (name: string, value: Moment | null): void => {
    if (value !== null) {
      updateHandler({ [name]: new Date(value.format('MM/DD/YYYY')) })
      handleValidation(name, new Date(value.format('MM/DD/YYYY')))
    }
  }

  const handleValidation = (name: string, value: string | Date): void => {
    const result = validationSchema.safeParse({ [name]: value })

    if (result.success) {
      setErrors({ ...errors, [name]: undefined })
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          if (item.path[0] === name) {
            errValues[String(item.path[0])] = item.message
          }
        })
        setErrors({ ...errors, ...errValues })
      }
    }
  }

  const handleSubmit = async (isClose: boolean): Promise<void> => {
    const result = validationSchema.safeParse(projectDetailData)

    const firstName = String(user?.info?.name?.first)
    const lastName = String(user?.info?.name?.last)
    const userInfo = (firstName?.length > 0) || (lastName?.length > 0) ? `${firstName} ${lastName}` : user?.info?.email

    setIsClose(isClose)

    if (result.success) {
      setIsSubmitting(true)
      const payload = {
        ...projectDetailData,
        endDate: moment(projectDetailData.dueDate).utcOffset(0, true).format(),
        hasProduct: projectDetailData.hasProduct === null ? undefined : String(projectDetailData.hasProduct) === 'true',
        dueDate: undefined,
        status: id != null ? undefined : 'draft',
        updateUgcProjectId: id ?? undefined,
        brandId: advertiserType === 'advertiser' ? listBrandsData?.listBrands[0]?.id : projectDetailData.brandId,
        description: projectDetailData.description === '' ? undefined : projectDetailData.description
      }
      try {
        let createProjectId = ''

        if (id !== undefined) {
          // updating project
          payload.updateUgcProjectId = id
          // checking project product exisits and if no is selected then deleting the project product
          if ((productData?.getProductByProject?.id) != null && String(projectDetailData.hasProduct) === 'false') {
            await deleteProjectProductMutation({ variables: { projectId: id } })
          }
          const response = await updateUGCProjectMutation({
            variables: Object.fromEntries(
              Object.entries(payload).filter(([_, value]) => value !== null)
            )
          })
          const projectId: string = response?.data.updateUgcProject.id
          if (isClose) {
            if (user?.isAdvertiser) {
              await sendNotificationMutation({
                variables: {
                  orgName: response?.data.updateUgcProject.owner.name ?? '',
                  logoUrl: response?.data.updateUgcProject.owner.logo?.uris?.logo ?? '',
                  projectId: response?.data.updateUgcProject.id,
                  projectUrl: `${URL}/projects/ugc/view/${projectId}/overview`,
                  projectName: response?.data.updateUgcProject.name,
                  userInfo,
                  action: 'update'
                }
              })
            }
          }
          String(projectDetailData.hasProduct) === 'true' ? sidebarData[4].isVisible = true : sidebarData[4].isVisible = false
          handleSidebarData([...sidebarData])
        } else {
          // creating project
          String(projectDetailData.hasProduct) === 'true' ? sidebarData[4].isVisible = true : sidebarData[4].isVisible = false
          handleSidebarData([...sidebarData])
          const response = await createUGCProjectMutation({ variables: payload })
          const projectId: string = response?.data.createUgcProject.id
          updateHandler({ id: projectId })
          if (user?.isAdvertiser) {
            await sendNotificationMutation({
              variables: {
                orgName: response?.data.createUgcProject.owner.name ?? '',
                logoUrl: response?.data.createUgcProject.owner.logo?.uris?.logo ?? '',
                projectId: response?.data.createUgcProject.id,
                projectUrl: `${URL}/projects/ugc/view/${projectId}/overview`,
                projectName: response?.data.createUgcProject.name,
                userInfo,
                action: 'create'
              }
            })
          }
          createProjectId = projectId
        }
        // handling sidebar status
        sidebarData[0].isError = false
        sidebarData[0].isCompleted = true
        handleSidebarData([...sidebarData])

        // navigating the user to next page
        setIsSubmitting(false)
        isClose ? navigate('/projects', { replace: true }) : navigate(`/projects/ugc/${id ?? createProjectId}/directions`, { replace: true })
      } catch (error) {
        if (error instanceof ApolloError) {
          dispatch(showToast({
            message: error?.message,
            isError: true
          }))
        }
        setIsSubmitting(false)
      }
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          errValues[String(item.path[0])] = item.message
        })
        setErrors({ ...errors, ...errValues })
      }
      sidebarData[0].isError = true
      sidebarData[0].isCompleted = false
      handleSidebarData([...sidebarData])
    }
  }

  if (projectDetailData.loading) {
    return (
      <>
        <section className={style.Skeleton}>
         <HeroShimmer />
         <HeroShimmer />
        </section>
      </>
    )
  }

  return (
    <>
      <section className={style.ProjectDetail}>
        <SectionHeader headerText='Project Description' descriptionText='How would you describe your project to prospective talent?' />
        <InputBox name='description' value={projectDetailData.description} onChange={handleChange} placeholder='Add one or more lines/bullets' isMultiline withLengthSuffix acceptedLength={2048} />
        <div className={style.ProjectDetail__Spacer} />
        <div className={style.ProjectDetail__SubSection}>
          <RenderIf isTrue={user.isAdmin}>
            <SectionHeader headerText='Advertiser' descriptionText='Who&apos;s paying TLYNT for this project?' />
            <AdvertiserDopdown error={errors?.ownerId} loading={organisationListLoading} organizationList={organizationList} ownerId={projectDetailData.ownerId} handleChange={handleChange} disabled={id !== undefined} />
            <div className={style.ProjectDetail__Spacer} />
          </RenderIf>
          <RenderIf isTrue={advertiserType !== 'advertiser'}>
            <SectionHeader headerText='Brand' descriptionText='Choose the brand associated with this project' />
            <BrandDropdown loading={listBrandLoading} error={errors?.brandId} brandId={projectDetailData.brandId} listBrandsData={listBrandsData} handleChange={handleChange} />
            <div className={style.ProjectDetail__Spacer} />
          </RenderIf>
          <SectionHeader headerText='Campaign Due Date' descriptionText='Is there a target date to complete this project?' />
          <DatePicker minDate={moment().add(1, 'day')} isError={errors?.dueDate !== undefined} errorText={errors?.dueDate} value={projectDetailData?.dueDate === '' ? undefined : projectDetailData?.dueDate} onChange={(e, v) => { handleDateChange(e, v) } } label='MM / DD / YYYY' name='dueDate' disablePast />
          <div className={style.ProjectDetail__Spacer} />
          <SectionHeader headerText='Physical Product' descriptionText='Will this project feature a physical product?' />
          <RadioInput onChange={handleChange} name='hasProduct' options={radioOptions} value={String(projectDetailData.hasProduct)} />
        </div>
      </section>
      <footer className={style.Footer}>
        <div className={style.Footer__ButtonRow}>
          <button disabled={isSubmitting} className={classNames(style.Footer__Button, projectDetailData.name === '' ? style['Footer__Button--Disabled'] : '')} onClick={projectDetailData.name === '' || isSubmitting ? undefined : () => { void handleSubmit(true) }}><p>{ (id != null) ? 'Update & Close' : 'Save & Close'}</p> { isSubmitting && isClose && <SpinnerSmall /> } </button>
          <button disabled={isSubmitting} className={classNames(style.Footer__Button, style['Footer__Button--Filled'], projectDetailData.name === '' ? style['Footer__Button--Disabled'] : '')} onClick={projectDetailData.name === '' || isSubmitting ? undefined : () => { void handleSubmit(false) }}>Next: Directions { isSubmitting && !isClose && <SpinnerSmall /> }  </button>
        </div>
      </footer>
    </>
  )
}
