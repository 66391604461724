// TODO: Need to create a storybook for this component
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { Button } from '../buttonV2'
import { Typography } from '..'
import { RenderIf } from '../../utils/Helpers'
import { Colors } from '../../constants'

import { TALENT_DELETE } from '../../services/API'
import { setAllTalents, setDisabledTalents, setFlaggedTalents, setTalentsWithoutPictures, setTalentsWithPictures, showToast } from '../../redux/actions/DashBoardActions'

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  justify-content: space-between;
  border-left: 1px solid ${Colors.primaryGray};
`
const UserInfoContainer = styled.div`
  flex: 1;
  flex-direction: column;
`
const AvatarView = styled.img`
  position: static;
  width: 96px;
  height: 96px;
  border-radius: 12px;
  background: url(${props => props.imageUrl});
  background-size: cover;
`
const UserName = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${Colors.black};
  text-transform: capitalize;
`
const UserDetail = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.black};
  opacity: 0.4;
  margin: 10px 0px;
`
const AvatarPlaceholder = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primaryGray};
`
const AvatarText = styled.p`
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
`
const Header = styled.div``

const ButtonText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${props => props.color};
`

const BottomActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const RenderAvatar = (props) => {
  const { img, firstname, lastname } = props
  if (img) {
    return <AvatarView imageUrl={img} />
  }
  return (
    <RenderIf isTrue={firstname || lastname} >
      <AvatarPlaceholder>
        <AvatarText> {firstname?.charAt(0) + lastname?.charAt(0)}</AvatarText>
      </AvatarPlaceholder>
    </RenderIf>
  )
}

RenderAvatar.propTypes = {
  loading: PropTypes.bool,
  img: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string
}

export const DeleteTalent = (props) => {
  // props
  const { profileData, loading, error, handelToggleDeleteSidebar } = props

  // constants
  const { firstname, lastname, email, phone, location, id } = profileData

  // hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // queries
  const [DeleteTalent, { loading: deleteLoading }] = useMutation(TALENT_DELETE, { notifyOnNetworkStatusChange: true })

  const handelDeleteTalent = async () => {
    try {
      await DeleteTalent({ variables: { talentId: id } })
      dispatch(setTalentsWithPictures([]))
      dispatch(setTalentsWithoutPictures([]))
      dispatch(setAllTalents([]))
      dispatch(setFlaggedTalents([]))
      dispatch(setDisabledTalents([]))

      dispatch(showToast({
        message: !firstname || !lastname ? 'You\'ve successfully deleted talent' : `You've successfully deleted ${firstname} ${lastname?.charAt(0)}.`,
        isError: false
      }))
      handelToggleDeleteSidebar()
      navigate('/users/talent')
    } catch (error) {

    }
  }

  if (loading) {
    return (
      <Wrapper>
        <p>Loading...</p>
      </Wrapper>
    )
  }
  if (error) {
    return (
      <Wrapper>
        <p>Error :(</p>
      </Wrapper>
    )
  }

  return (
    <Fragment>
      <Wrapper>
        <Header>
          <Typography fontSize={34} fontWeight={'bold'}>Are you sure you want to delete this talent?</Typography>
          <Typography fontSize={14} color={Colors.spanishGray} style={{ marginTop: 10, marginBottom: 30 }}>
            By doing this action talent will be deleted permanently.
          </Typography>
        </Header>
        <UserInfoContainer>
          <RenderAvatar firstname={firstname} lastname={lastname} img={profileData?.mediaProfile?.uris?.profilePic} />
          <RenderIf isTrue={firstname || lastname}>
            <UserName>{(firstname || '') + ' ' + (lastname?.charAt(0) ?? '') + '.'}</UserName>
          </RenderIf>
          <UserDetail>{email}</UserDetail>
          <UserDetail>{phone && '+1 ' + phone}</UserDetail>
          <UserDetail>{location}</UserDetail>
        </UserInfoContainer>
        <BottomActionWrapper>
          <Button onClick={handelToggleDeleteSidebar} height="40px" minWidth="96px" backgroundColor={Colors.white}>
            <ButtonText>CANCEL</ButtonText>
          </Button>
          <Button onClick={handelDeleteTalent} loading={deleteLoading} height="40px" minWidth="96px">
            <ButtonText color={Colors.white}>DELETE</ButtonText>
          </Button>
        </BottomActionWrapper>
      </Wrapper>
    </Fragment>
  )
}

DeleteTalent.propTypes = {
  profileData: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  handelToggleDeleteSidebar: PropTypes.func
}
