import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from '../../constants'
import { RenderIf } from '../../utils/Helpers'

const ButtonContainer = styled.button`
  background: ${props => props.backgroundColor};
  height: ${props => props.height};
  width: ${props => props.width};
  border: 1px solid;
  border-color: ${props => props.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-inline: 22px;
  box-shadow: 0 3px 5px -2px ${props => props.backgroundColor};
  opacity: ${props => props.disabled ? '0.85' : '1'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`

const Spinner = styled.div`
  border: 3px solid ${props => props.color};
  border-top: 3px solid ${Colors.buttonGrayOpacity};
  border-radius: 50%;
  width: ${props => props.spinnerSize};
  height: ${props => props.spinnerSize};
  margin-left: 10px;
  align-self: center;
  animation: spin 1.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

// backgroundColor: for background color or border color
// outline: for button style
export const Button = props => {
  const {
    style = {},
    outline,
    children,
    onClick,
    height = '42px',
    width = 'auto',
    backgroundColor = Colors.black,
    loading = false,
    disabled = false,
    spinnerSize = '16px',
    type = ''
  } = props
  return (
    <ButtonContainer
      onClick={!disabled ? onClick : () => null}
      width={width}
      height={height}
      backgroundColor={outline ? Colors.white : backgroundColor}
      borderColor={disabled ? '' : outline ? backgroundColor : 'transparent'}
      outline
      style={style}
      disabled={loading || disabled}
      type={type}>
      {children}
      <RenderIf isTrue={loading}>
        <Spinner color={ outline ? backgroundColor : Colors.white } spinnerSize={spinnerSize} />
      </RenderIf>
    </ButtonContainer>
  )
}

Button.propTypes = {
  style: PropTypes.object,
  outline: PropTypes.bool,
  children: PropTypes.element,
  backgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  spinnerSize: PropTypes.string,
  type: PropTypes.string
}
