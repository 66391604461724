import { useRef } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'

import { Images } from '../../../../assets'
import style from './ProjectsWrapper.module.scss'
import { Typography } from '../../../../components/Typography'
import { ProjectCard } from '../../../../components/ProjectCard'

const PlaceholderImage = styled.img`
  height: 250px;
  width: 250px;
  margin-top: 40px;
`
const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
const ShimmerBox = styled.div`
  min-width: 230px;
  max-height: 455px;
  aspect-ratio: 2 / 3.45;
  height: 100%;
  width: 100%;
  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const ProjectsWrapper = props => {
  // Props
  const { data, loading, handleScroll, loadingMoreProjectStatus } = props
  // Hooks
  const cardViewRef = useRef(null)
  const user = useSelector(state => state.UserReducer)

  const shimmerCount = new Array(5).fill(0).map((_, index) => ({ id: index }))

  return (
    <>
      {data?.length === 0 && !loading &&
        <div className={style.ProjectsWrapper__NoData}>
          <PlaceholderImage src={Images.noProjectFound} />
          <Typography fontSize={34} fontWeight='bold' letterSpacing='0.25px' style={{ marginTop: 15 }}>
            No projects.
          </Typography>
          <Typography fontSize={14} letterSpacing='0.25px' style={{ marginTop: 10, opacity: 0.4 }}>
            Add your first project to get started.
          </Typography>
        </div>
      }
      <div className={style.ProjectsWrapper} ref={cardViewRef} onScroll={() => handleScroll(cardViewRef)}>
        {loading && data?.length < 1 && shimmerCount.map(item => <ShimmerBox key={item.id} />)}
        {data?.map((item, index) => <ProjectCard key={item?.id} {...item} bgColor={item.color} isAdmin={user.isAdmin} />)}
        {loadingMoreProjectStatus && shimmerCount.map(item => <ShimmerBox key={item.id} />)}
      </div>
    </>
  )
}

ProjectsWrapper.propTypes = {
  selectId: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  handleScroll: PropTypes.func,
  loadingMoreProjectStatus: PropTypes.bool
}

export default ProjectsWrapper
