import { useDispatch } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import Collapse from '@mui/material/Collapse'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { Colors, ProjectConstants } from '../../../constants'
import { formatDate, RenderIf } from '../../../utils/Helpers'
import { handleMinMax, handleArrayValue, handleRateValue } from '../../../utils/ProjectHelper'
import { Images } from '../../../assets'
import { ShimmerGeneral } from '../../../components/shimmer/ShimmerGeneral'
import { showToast } from '../../../redux/actions/DashBoardActions'
import { Typography } from '../../../components'
import ProjectAPI from '../../../services/Project/ProjectAPI'
import { DELETE_SELECT } from '../../../services/API'
import { SpinnerSmall } from '../../../components/Progress'

const Wrapper = styled.div`
  max-height: 100%;
  max-width: 100%;
  padding: 40px;
`
const CollapseHeader = styled.div`
  padding: 24px 0;
  border-bottom: 1px ${Colors.inputGray} ${props => !props.isLast ? 'solid' : ''};
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`
const CollapseBody = styled.div`
  border-bottom: 1px ${Colors.inputGray} ${props => (props.show && !props.isLast) ? 'solid' : ''};
  width: 100%;
`
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 160%;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 20px;
  }
`
const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const CollapsIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const FieldWrapper = styled.div`
  border-top: 1px ${Colors.inputGray} ${props => (props.show && props.isLast) ? 'solid' : ''};
  padding: 15px 0;
  display: flex;
  flex-direction: row;
`
const InsideFieldWrapper = styled.div`
  width: 50%;
`
const Field = styled.div`
  display: flex;
  padding: 12px 0;
`
const FieldValue = styled.div`
  display: flex;
`

const FieldData = props => {
  const { title, value } = props
  return (
    <Field>
      <Typography color={Colors.blackOpacity} style={{ flex: '0 0 160px' }}>{title}</Typography>
      <FieldValue>{value ?? '-'}</FieldValue>
    </Field>
  )
}
const RoleDetail = props => {
  // INIT
  const dispatch = useDispatch()
  const { projectId, setIsFooter, roleList, refetch } = props

  // HOOKS
  const deletedRoleIdRef = useRef(null)
  // STATE
  const [isChecked, setIsChecked] = useState([])

  // API CALLS
  const [deleteRoleMutation, { loading: deleteRoleLoading }] = useMutation(ProjectAPI.deleteRoleProject(), { notifyOnNetworkStatusChange: true })
  const [selectDeleteMutation, { loading: deleteSelectLoading }] = useMutation(DELETE_SELECT, { notifyOnNetworkStatusChange: true })

  const totalCount = new Array(3).fill(0).map((_, index) => (index))

  useEffect(() => {
    const statusArray = []
    setIsFooter(!!roleList)
    if (roleList) {
      roleList.map(e => statusArray.push(false))
      statusArray[0] = true
      setIsChecked(statusArray)
    }
  }, [roleList])

  const updateStatus = (index) => {
    setIsChecked(prevState => {
      const newState = prevState.map((obj, i) => {
        if (i === index) {
          return !obj
        }
        return obj
      })
      return newState
    })
  }

  const handleDeleteRoleWithSelect = async (id, selectId) => {
    deletedRoleIdRef.current = id
    try {
      await Promise.all([deleteRoleMutation({ variables: { id } }), selectDeleteMutation({ variables: { id: selectId } })])
      dispatch(showToast({
        message: ProjectConstants.Project.roleDeleteStatus,
        isError: false
      }))
      refetch(projectId)
    } catch (error) {
      dispatch(showToast({
        message: error?.message ?? 'Internal server error.',
        isError: true
      }))
    }
    deletedRoleIdRef.current = null
  }

  return (
    <Wrapper>
      {roleList
        ? roleList.map((role, index) =>
          <div key={index}>
            <CollapseHeader isLast={index === (roleList.length - 1)} onClick={() => updateStatus(index)}>
              <HeaderTitle>{role?.name ?? '-'}</HeaderTitle>
              <IconWrapper>
                <RenderIf isTrue={roleList.length > 1}>
                  {(deleteRoleLoading || deleteSelectLoading) && deletedRoleIdRef.current === role.id ? <SpinnerSmall /> : <DeleteIcon src={Images.deleteOutline} onClick={(e) => { e.stopPropagation(); handleDeleteRoleWithSelect(role.id, role.selectId) } } /> }
                </RenderIf>
                <CollapsIcon src={isChecked[index] ? Images.minusCollaps : Images.plusCollaps} />
              </IconWrapper>
            </CollapseHeader>
            <CollapseBody show={isChecked[index]} isLast={index === (roleList.length - 1)}>
              <Collapse in={isChecked[index]}>
                <FieldWrapper show={isChecked[index]} isLast={index === (roleList.length - 1)}>
                  <InsideFieldWrapper>
                    <FieldData title='Rate' value={handleRateValue(role.rate)} />
                    <FieldData title='Start Date' value={formatDate(role.startDate, 'MM/DD/YYYY')} />
                    <FieldData title='End Date' value={formatDate(role.endDate, 'MM/DD/YYYY')} />
                    <FieldData title='Age' value={handleMinMax(role.criteria?.age?.min, role.criteria?.age?.max, ' Years')} />
                    <FieldData title='Body Type' value={handleArrayValue(role.criteria?.bodyType)} />
                    <FieldData title='Height' value={handleMinMax(role.criteria?.height?.min, role.criteria?.height?.max, ' Feet')} />
                    <FieldData title='Hair Color' value={handleArrayValue(role.criteria?.hairColor)} />
                    <FieldData title='Description' value={!role?.description ? '-' : role.description} />
                  </InsideFieldWrapper>
                  <InsideFieldWrapper>
                    <FieldData title='Number of positions' value={!role?.quantity ? '-' : role?.quantity} />
                    <FieldData title='Eye Color' value={handleArrayValue(role.criteria?.eyeColor)} />
                    <FieldData title='Ethnicity' value={handleArrayValue(role.criteria?.ethnicity)} />
                    <FieldData title='Race' value={handleArrayValue(role.criteria?.race)} />
                    <FieldData title='Gender' value={handleArrayValue(role.criteria?.gender)} />
                    <FieldData title='Gender Expression' value={handleArrayValue(role.criteria?.genderExpression)} />
                    <FieldData title='Sexual Orientation' value={handleArrayValue(role.criteria?.sexualOrientation)} />
                    <FieldData title='Notes' value={!role?.notes ? '-' : role?.notes} />
                  </InsideFieldWrapper>
                </FieldWrapper>
              </Collapse>
            </CollapseBody>
          </div>
        )
        : totalCount.map((e, index) => <ShimmerGeneral key={index} count={1} height={70} style={{ marginBottom: 20 }} />)}
      <RenderIf isTrue={roleList && !roleList.length}>
        <Typography fontSize={36} fontWeight='bold' style={{ textAlign: 'center' }}>No Roles found..!</Typography>
      </RenderIf>
    </Wrapper>
  )
}
FieldData.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
RoleDetail.propTypes = {
  projectId: PropTypes.string,
  setIsFooter: PropTypes.func,
  roleList: PropTypes.array,
  roleLoading: PropTypes.bool,
  refetch: PropTypes.func
}

export default RoleDetail
