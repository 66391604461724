import Branch from 'branch-sdk'
import moment from 'moment'

import { BRANCH_URL, PUBLIC_SHAREABLE_URL } from '../constants/URL'

export const handleMinMax = (min, max, string) => {
  if (typeof min === 'number' || typeof min === 'string') {
    return min + ' - ' + max + string
  } else {
    return 'Any'
  }
}
export const handleArrayValue = (array) => {
  return array?.length && array.join(', ')
}
export const handleRateValue = (rate) => {
  return rate ? '$ ' + handlePriceFormat(rate) + '  /Day' : '-'
}

export const handlePriceFormat = price => {
  if (price) {
    const withoutDecimals = parseInt(price).toString()
    return withoutDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const handlePriceRange = (item) => {
  const minPrice = Math.min(...item).toFixed(2)
  const maxPrice = Math.max(...item).toFixed(2)
  if (minPrice === maxPrice) {
    return item.length ? '$ ' + handlePriceFormat(minPrice) : '-'
  } else {
    return item.length ? '$ ' + handlePriceFormat(minPrice) + ' - $ ' + handlePriceFormat(maxPrice) : '-'
  }
}

export const handleProjectCardDate = (sDate, eDate) => {
  const difference = (moment(eDate)).diff(moment(sDate), 'days') + 1
  const finalDate = new Date(sDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
  return sDate ? finalDate + ' - ' + difference + (difference > 1 ? ' Days' : ' Day') : ''
}

export const formatProjectTalentDateAndTime = (date) => {
  const now = moment.utc()
  const target = moment.utc(date)
  const diff = moment.duration(now.diff(target))
  if (diff.asMinutes() < 60) {
    return `${Math.round(diff.asMinutes())} minutes ago`
  } else if (diff.asHours() < 24) {
    return `${Math.round(diff.asHours())} hours ago`
  } else if (diff.asDays() < 10) {
    return `${Math.round(diff.asDays())} days ago`
  } else {
    return target.format('MMM D, YYYY')
  }
}

export const UGCTalentStatus = {
  interested: 'interested',
  bookingRequested: 'booking_requested',
  bookingConfirmed: 'booking_confirmed',
  wrapped: 'wrapped',
  paid: 'paid'
}


export const ProjectSubmissionCompressJobStatus = {
  pending: 'pending',
  processing: 'processing',
  complete: 'complete',
  errored: 'errored'
}

export const UGCProjectStatus = {
  draft: 'draft',
  active: 'active',
  cancelled: 'cancelled',
  completed: 'completed',
  production: 'production'
}

/**
 * @description This function is used to generate the deeplink using the branch.io SDK to share the project
 * @param id - project id 
 */
export const generateProjectBranchLink = async (id) => {
  const linkData = {
    alias: `project/${id}`,
    data: {
      '$fallback_url': `${PUBLIC_SHAREABLE_URL}/project/${id}`,
      '$deeplink_path': `project/${id}`
    }
  }

  const shareProjectlink = await new Promise((resolve, reject) => {
    Branch.link(linkData, (err, link) => {
      const isAliasAlreadyCreated = err?.message === 'Error in API: 409'

      const params = new URLSearchParams({
        channel: 'portal',
        feature: 'sharing'
      })

      if (isAliasAlreadyCreated) {
        resolve(`${BRANCH_URL}/project/${id}?${params.toString()}`)
      } else if (link !== null) {
        resolve(`${link}?${params.toString()}`)
      } else {
        reject(err)
      }
    })
  })

  if (shareProjectlink === null) {
    throw new Error('Link generation failed')
  }

  return shareProjectlink

}
