import { useEffect, useState, type ChangeEvent } from 'react'

import { FormControl, FormLabel, FormHelperText, FormGroup, Checkbox } from '@mui/material'

import { RenderIf } from '../../utils/Helpers'
import style from './TagCheckbox.module.scss'
import classNames from 'classnames'

interface TagCheckboxProps {
  label?: string
  name: string
  errorText?: string
  isError?: boolean
  value?: string[]
  isMultiSelection?: boolean
  onChange?: (name: string, value: string[]) => void
  options: Array<{
    id: string | number
    name: string
    label: string
  }>
}
const Tag = (props: { text: string, checked: boolean }): JSX.Element => {
  return <div className={classNames(style.TagCheckbox__Chip, style[`TagCheckbox__Chip--${props.checked ? 'selected' : ''}`])}>{props.text}</div>
}
export const TagCheckbox = (props: TagCheckboxProps): JSX.Element => {
  const { label, isError = false, errorText = '', value = [], name, options, onChange, isMultiSelection = true } = props
  const [checkedValue, setCheckedValue] = useState<string[]>(value)

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === 'any') {
      setCheckedValue(['any'])
    } else if (isMultiSelection) {
      checkedValue.includes(event.target.name)
        ? setCheckedValue(checkedValue.filter((item) => item !== event.target.name).filter((item) => item !== 'any'))
        : setCheckedValue([...checkedValue.filter((item) => item !== 'any'), event.target.name])
    } else {
      setCheckedValue([event.target.name])
    }
  }
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(name, checkedValue)
    }
  }, [checkedValue])

  return (
    <div className={style.TagCheckbox}>
      <FormControl error={isError} component='fieldset' variant='standard'>
        <RenderIf isTrue={!(label == null)}>
          <FormLabel component='legend'>{label}</FormLabel>
        </RenderIf>
        <FormGroup row>
          {options.map((option) =>
          <Checkbox key={option.id} name={option.name} onChange={handleChange} checked={checkedValue.includes(option.name)}
            icon={<Tag text={option.label} checked={false} />} checkedIcon={<Tag text={option.label} checked />} disableRipple />
          )}
        </FormGroup>
        <RenderIf isTrue={isError}>
          <FormHelperText error>{errorText}</FormHelperText>
        </RenderIf>
      </FormControl>
    </div>
  )
}
