import { Fragment } from 'react'

import PropTypes from 'prop-types'
import { ErrorMessage, Field } from 'formik'
import { TextField } from '@mui/material'

import { TextError } from '../textError'
import { Fonts } from '../../../constants'
import { formatPhoneNumber } from '../../../utils/Helpers'
import { FloatRight, FullWidth, LabelText, ModifiedInput, Wrapper } from './styles'

export const Input = props => {
  const { label, name, placeholder, right, isStandard, children, style, multiline = false, rows = 1, disabled = false, type = 'text', inputProps, ...rest } = props

  if (isStandard) {
    return (
        <Field
         name={name} {...rest}>
          {fieldProps => {
            const { field, meta, form } = fieldProps
            const { error, touched } = meta
            const { setFieldValue } = form
            const errorText = error && touched ? error : ''
            const { value, onBlur } = field
            return (
              <TextField
               id={name}
               value={value}
               multiline={multiline}
               inputProps={{ ...inputProps }}
               rows={rows}
               disabled={disabled}
               variant='standard'
               style={{
                 ...style,
                 fontWeight: 400,
                 fontFamily: Fonts.degular
               }}
               InputLabelProps={{
                 style: {
                   fontFamily: Fonts.degular,
                   fontSize: 16,
                   fontWeight: 400
                 }
               }}
                 onChange={(e) => {
                   if (name === 'phone' || name.includes('phone')) {
                     setFieldValue(name, formatPhoneNumber(e.target.value))
                   } else if (type === 'number') {
                     if (/^[0-9]+$/.test(e.target.value) || e.target.value === '') {
                       setFieldValue(name, e.target.value)
                     }
                   } else {
                     setFieldValue(name, e.target.value)
                   }
                 }}
                 error={error && touched}
                 helperText={errorText}
                fullWidth
                label={label}
                onBlur={onBlur}
              />
            )
          }}
        </Field>
    )
  } else {
    return (
      <Wrapper>
        {Array.isArray(name) && name.length > 0
          ? <Fragment>
            <LabelText htmlFor={name}>{label}</LabelText>
            <FullWidth>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                {name.map((item, index) => (
                  <Field key={index} name={item?.name} {...rest}>
                    {fieldProps => {
                      const { field, meta } = fieldProps
                      const { error, touched } = meta
                      const { value } = field
                      return (
                        <div style={{ width: '48%' }} >
                          <div style={{ position: 'relative' }}>
                            <FloatRight visible={value?.length}>
                              {right[index]}
                            </FloatRight>
                            <ModifiedInput
                              error={error && touched}
                              id={item.name}
                              name={item?.name}
                              placeholder={item?.placeholder}
                              {...field}
                            />
                          </div>
                          <ErrorMessage name={item?.name} component={TextError} />
                        </div>
                      )
                    }}
                  </Field>
                ))}
              </div>
            </FullWidth>
          </Fragment>
          : <Fragment>
        <LabelText htmlFor={name}>{label}</LabelText>
        <FullWidth >
          <Field name={name} {...rest}>
            {fieldProps => {
              const { field, meta, form } = fieldProps
              const { error, touched } = meta
              const { value, onBlur } = field
              const { setFieldValue } = form
              return (
                <div style={{ display: 'flex', width: '100%' }} >
                {children}
                <div style={{ position: 'relative', width: '100%' }}>
                  <FloatRight visible={value?.length} >
                    {right}
                  </FloatRight>
                  <ModifiedInput
                    error={error && touched}
                    id={name}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => {
                      if (name === 'phone') {
                        setFieldValue(name, formatPhoneNumber(e.target.value))
                      } else {
                        setFieldValue(name, e.target.value)
                      }
                    } }
                    onBlur={onBlur}
                    />
                </div>
                </div>
              )
            }}
          </Field>
          <ErrorMessage name={name} component={TextError} />
        </FullWidth>
      </Fragment>
      }
      </Wrapper>
    )
  }
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    placeholder: PropTypes.string
  }))]),
  placeholder: PropTypes.string,
  right: PropTypes.any,
  children: PropTypes.node,
  isStandard: PropTypes.bool,
  style: PropTypes.object,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  type: PropTypes.string
}
