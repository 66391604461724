import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { GET_MEDIA_LIST } from '../../../services/API'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useGetMediaQuery (props) {
  // props
  const { talentIds } = props
  // hooks
  const dispatch = useDispatch()
  // states
  const [mediaData, setMediaData] = useState([])
  // api calls
  const [fetchMediaQuery, { loading }] = useLazyQuery(GET_MEDIA_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (talentIds === '' || talentIds === null) {
      setMediaData([])
    } else {
      fetchMedia()
    }
  }, [talentIds])

  const fetchMedia = async () => {
    try {
      const response = await fetchMediaQuery({
        variables: {
          talentIds,
          disabled: false
        }
      })
      setMediaData([...response.data.mediaList])
    } catch (error) {
      dispatch(showToast({
        message: error?.message
      }))
    }
  }

  return {
    mediaData,
    loading
  }
}

export default useGetMediaQuery
