import styled from 'styled-components'

import { Images } from '../../../../assets'
import { Typography } from '../../../../components'
import { useAuth } from '../../../../services/Auth'

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

const GreetingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: min(622px, 100% - 20px);
`
const GreetingSymbol = styled.img`
  height: 45px;
  width: 40px;
  margin-left: 57px;
`
const DashedVector = styled.img`
  display: flex;
`
const GreetingsFont1Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const GreetingNameWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`

const SelectGreeting = () => {
  const auth = useAuth()
  return (
    <Section>
      <GreetingBoxWrapper>
        <GreetingsFont1Wrapper>
          <DashedVector src={Images.dashedVector} />
          <GreetingNameWrapper>
            <GreetingSymbol src={Images.greetings} />
            <Typography fontSize={34} fontWeight={'bold'} letterSpacing={0.25} style={{ marginLeft: 10 }}>
              {auth.user?.name?.first},
            </Typography>
          </GreetingNameWrapper>
        </GreetingsFont1Wrapper>
        <Typography
          fontSize={34}
          fontWeight={'bold'}
          letterSpacing={0.25}
          style={{ alignSelf: 'center' }}
        >
        what are you working on today?
        </Typography>
        <Typography
          fontSize={14}
          letterSpacing={0.25}
          style={{ opacity: 0.4, alignSelf: 'center', marginTop: 8 }}
        >
          Click on a select in the list to see and edit its content
        </Typography>
      </GreetingBoxWrapper>
    </Section>
  )
}

export default SelectGreeting
