import { type ChangeEvent } from 'react'

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, type RadioProps } from '@mui/material'

import style from './Radio.module.scss'

interface RadioDefaultProps {
  label?: string
  name?: string
  value?: boolean | string | null
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  options: Array<{
    id: string | number
    value: boolean | string
    label: string
  }>
}

export const RadioDefault = (props: RadioDefaultProps): JSX.Element => {
  const { label, value, onChange, name, options } = props

  const StyledRadio = (props: RadioProps): JSX.Element => {
    return (
      <Radio
        color='default'
        checkedIcon={<RadioButtonCheckedIcon className={style.Radio__Icon} />}
        icon={<RadioButtonUncheckedIcon className={style.Radio__Icon} />}
        {...props}
      />
    )
  }
  return (
    <FormControl className={style.Radio}>
      <FormLabel className={style.Radio__Label}>{label}</FormLabel>
      <RadioGroup row name={name} onChange={onChange} value={value}>
        {options.map((e, index) => <FormControlLabel key={index} value={e.value} onChange={() => { }} control={<StyledRadio />} label={e.label} />)}
      </RadioGroup>
    </FormControl>
  )
}
