import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'

import SelectAPI from '../../services/Select/SelectAPI'
import NavBar from './Components/Navbar'
import { PublicSelectTalents } from '../../components/PublicSelectTalents'
import SelectDetail from './Components/SelectDetail'
import TalentAPI from '../../services/Talent/TalentAPI'

const Container = styled.div`
  height: 100vh;
  width: 100%;
`

const Main = styled.main`
  width: min(100% - 40px, 1120px);
  height: 100%;
  margin-inline: auto;
`

const PublicSelect = (props) => {
  // hooks
  const { id } = useParams()

  // states
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [childSelectData, setChildSelectData] = useState([])

  const { selectData, selectLoading } = props

  // queries
  const { data: childSelects, loading: childSelectsLoading, error: childSelectsError } = useQuery(SelectAPI.getChildListSelect(), {
    variables: { offset: 0, limit: 12, parentId: id },
    skip: !selectData,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  })

  const [talentList, { data: talentListData, loading: talentListLoading }] = useLazyQuery(TalentAPI.getTalentList())

  useEffect(() => {
    if (childSelectData[activeTabIndex]?.talents.length > 0) {
      talentList({
        variables: { offset: 0, limit: 1000, ids: childSelectData[activeTabIndex]?.talents?.join() ?? '' },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
      })
    }
  }, [activeTabIndex, childSelectData[activeTabIndex]?.talents])

  // manipulating states
  useEffect(() => {
    if (!childSelectsError && !childSelectsLoading && childSelects && childSelects?.listSelects?.length > 0) {
      const data = childSelects?.listSelects?.map((item, index) => {
        return {
          ...item,
          isSelected: index === 0
        }
      })
      setChildSelectData(data)
    }
  }, [childSelectsLoading])

  /**
   * @description This function is used to change the active tab index
   * @param {number} index
   */
  const handleChangeTab = index => {
    setActiveTabIndex(index)
  }
  const mediaType = selectData?.getSelect?.mediaType

  return (
    <Container>
      <NavBar />
      {selectLoading
        ? (<div>
          <Skeleton sx={{ margin: 'auto', marginTop: '30px', width: 'min(100% - 40px, 1120px)' }} height={75} animation='wave' />
          <Skeleton sx={{ margin: 'auto', width: 'min(100% - 40px, 1120px)' }} height={75} animation='wave' />
          <Skeleton sx={{ margin: 'auto', width: 'min(100% - 40px, 1120px)' }} height={75} animation='wave' />
        </div>)
        : (<Main>
          <SelectDetail
            data={selectData}
            childSelectList={childSelectData}
            childSelectsLoading={childSelectsLoading}
            loading={selectLoading}
            handelChildSelectData={updatedData => setChildSelectData(updatedData)}
            handelActiveTabIndex={handleChangeTab}
          />
          <PublicSelectTalents
            selectId={id}
            key={talentListData?.listTalent.talents?.length > 0 ? talentListData?.listTalent.talents?.join(',') : String(activeTabIndex)}
            childSelectId={childSelectData[activeTabIndex]?.id}
            mediaType={mediaType}
            data={childSelectData[activeTabIndex]?.talents?.length > 0 ? talentListData?.listTalent.talents : []}
            projectDetail={selectData?.getSelect?.project}
            loading={talentListLoading || selectLoading || childSelectsLoading}
          />
        </Main>)}
    </Container>
  )
}

PublicSelect.propTypes = {
  selectData: PropTypes.object,
  selectLoading: PropTypes.bool
}

export default PublicSelect
