import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useStore } from 'react-redux'

import Masonry from '../Masonry'
import { ImageContainer } from '..'
import { setTalentMediaList } from '../../redux/actions/TalentDetailActions'

const Wrapper = styled.section`
  padding-bottom: 10px;
  padding-left: ${props => props.isPublic ? '100px' : '0px'};
`

export const StackGridView = ({ isPublic, imageData, onPreviewClicked, handleRetryMedia, incImageLoadedCount }) => {
  const store = useStore()
  const dispatch = useDispatch()

  const revertStatus = async (fileName) => {
    const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
    const mediaListClone = [...mediaStoreList]
    const mediaIndex = mediaListClone.findIndex((item) => item?.title === fileName)
    if (mediaIndex > -1) {
      mediaListClone[mediaIndex].isError = false
      mediaListClone[mediaIndex].isUploading = true
    }
    dispatch(setTalentMediaList([...mediaListClone]))
  }

  const removeImage = (fileName) => {
    const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
    const mediaListClone = [...mediaStoreList]
    const mediaIndex = mediaListClone.findIndex((item) => item?.title === fileName)
    mediaListClone.splice(mediaIndex, 1)
    dispatch(setTalentMediaList([...mediaListClone]))
  }

  return (
    <Wrapper isPublic={isPublic}>
      <Masonry gutter='10px' columnsCount={5}>
        {imageData.map((item, index) => (
          <ImageContainer
            key={index.toString()}
            data={item}
            onClick={() => onPreviewClicked(item?.id)}
            isError={item?.isError}
            handleRetryMedia={() => {
              revertStatus(item.title)
              handleRetryMedia([item])
            }}
            handleRemoveImage = {() => removeImage(item?.title) }
            isUploading={item?.uris ? false : item.isUploading}
            isUploaded={item?.isUploaded}
            progressValue={item?.uploadProgress}
            images={ item?.title?.includes('.heic') ? item?.uris?.grid ?? '' : item.preview ?? item?.uris?.grid }
            isExceedSizeLimit={ item?.isExceedSizeLimit}
            incImageLoadedCount={incImageLoadedCount}
          />
        ))}
      </Masonry>
    </Wrapper>
  )
}

StackGridView.propTypes = {
  isPublic: PropTypes.bool,
  imageData: PropTypes.array,
  onPreviewClicked: PropTypes.func,
  handleRetryMedia: PropTypes.func,
  incImageLoadedCount: PropTypes.func
}
