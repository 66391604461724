import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import style from '../../Sidebar.module.scss'
import { Images } from '../../../../assets'
import { useAuth } from '../../../../services/Auth'
import { RenderIf } from '../../../../utils/Helpers'
import { getSidebarStatus } from '../../../../redux/actions/SidebarActions'

interface FooterProps {
  isOpen: boolean
  isSubMenuOpen: boolean
}

const Footer = (props: FooterProps): JSX.Element => {
  // props
  const { isOpen, isSubMenuOpen } = props
  // hooks
  const auth = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isSelectSubMenuOpen } = useSelector((state: any) => state.SidebarReducer)

  // functions
  const toggle = (): void => {
    dispatch(
      getSidebarStatus({
        isOpen: !isOpen,
        width: !isOpen ? '250px' : '96px',
        isSubMenuOpen,
        isSelectSubMenuOpen
      })
    )
  }

  const logoutHandler = (): void => auth.signout(() => { navigate('/login') })

  return (
    <>
      <div className={style.Sidebar__Footer}>
        <RenderIf isTrue={isOpen}>
          {auth.user.picture === null ? <img className={style.Sidebar__FooterAvatarImg} src={auth.user?.picture} /> : <div className={classNames(style.Sidebar__FooterAvatarImg, style['Sidebar__FooterAvatarImg--Initials'])}>{auth.user?.name?.first.charAt(0).toUpperCase()}</div>}
          <p className={style.Sidebar__FooterUsernameText}>{auth.user?.name?.first} {auth.user?.name?.last}</p>
        </RenderIf>
        <div className={classNames(style.Sidebar__FooterLogout, isOpen ? style['Sidebar__FooterLogout--Open'] : '')}>
          <img className={style.Sidebar__FooterLogoutImg} onClick={logoutHandler} src={Images.logout} />
        </div>
      </div>
      <button className={style.Sidebar__TogglerButton} onClick={() => { toggle() }}>
        <img className={classNames(style.Sidebar__TogglerImage, !isOpen ? style['Sidebar__TogglerImage--Open'] : '')} src={Images.doubleArrowLeft} />
      </button>
    </>
  )
}

export default Footer
