import { type ReactNode, useReducer, createContext, type Dispatch } from 'react'
import { useFeatureValue } from '@growthbook/growthbook-react'

interface IProfile {
  selectId?: string
  id: number | string
  name: string
  projectId?: string
  quantity: number
  ageRanges?: string[] | null
  gender?: string[] | null
  ethnicity?: string[] | null
  description?: string
  isRemoved?: boolean
  isEdited?: boolean
}
export interface ITalent {
  numberOfCreators: number
  compensation: number
  hasTalentProfile: string
  profile: IProfile[]
  [key: string]: any
}

export interface ITalentActions {
  numberOfCreators?: number
  compensation?: number
  hasTalentProfile?: string
  profile?: IProfile[]
}

const createTalentInitialData = {
  numberOfCreators: 1,
  hasTalentProfile: 'true',
  compensation: 0,
  profile: [{
    id: Date.now(),
    name: 'Profile 1',
    projectId: '',
    quantity: 1,
    ageRanges: ['any'],
    gender: ['any'],
    ethnicity: ['any'],
    description: '',
    isRemoved: false,
    isEdited: false,
    selectId: ''
  }]
}

export const TalentContext = createContext<[ITalent, Dispatch<ITalentActions>]>([createTalentInitialData, () => {}])

interface DeliverableContextDecoratorProps {
  children: ReactNode
}

export const TalentProvider = ({ children }: DeliverableContextDecoratorProps): JSX.Element => {
  const defaultUGCCompensation = useFeatureValue('portal:ugc:default-compensation', 300)
  const [talentData, updateTalentData] = useReducer((prev: ITalent, next: ITalentActions) => ({ ...prev, ...next }), { ...{ ...createTalentInitialData, compensation: defaultUGCCompensation } })

  return (
    <TalentContext.Provider value={[talentData, updateTalentData]}>
      {children}
    </TalentContext.Provider>
  )
}
