import { useMemo, type Dispatch } from 'react'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { DeliverablesContext } from '../../contexts/deliverables'
import { showToast } from '../../redux/actions/DashBoardActions'
import { stillImageDirection } from '../../utils/Helpers'
import { UGCProjectDetailContext } from '../../contexts/projectDetail'
import { UGCSidebarContext } from '../../contexts/ugcSidebar'
import { updateDeliverableSpecs, createProjectDeliverable, SendNotification, updateUGCProject } from '../../apis/ugc'
import { URL } from '../../constants/URL'
import type { IProjectDetail, IProjectDetailActions } from '../../contexts/projectDetail'
import type { UserState } from '../../redux/reducers/user'

interface useSubmitDeliverablesType {
  handleSubmitProject: (close: boolean) => void
  isLoading: boolean
}

export const useSubmitDeliverables = (close: boolean): useSubmitDeliverablesType => {
  // init
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const user: UserState = useSelector((state: any) => state.UserReducer)
  const [deliverableData, updateDeliverableHandler] = useContext(DeliverablesContext)
  const [sidebarData, updateSidebarData] = useContext(UGCSidebarContext)
  const [projectDetailData] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)

  // api calls
  const [updateUGCProjectMutation] = useMutation(updateUGCProject, { notifyOnNetworkStatusChange: true })
  const [updateDeliverableSpecsMutation] = useMutation(updateDeliverableSpecs, { notifyOnNetworkStatusChange: true })
  const [createProjectDeliverableMutation] = useMutation(createProjectDeliverable, { notifyOnNetworkStatusChange: true })
  const [sendNotificationMutation] = useMutation(SendNotification, { notifyOnNetworkStatusChange: true })

  const { brandId, owner } = projectDetailData

  const brandDetails = useMemo(() => {
    return owner?.brands?.find((brand: { id: string }) => brand?.id === brandId)
  }, [owner?.brands, brandId])

  // constants
  const firstName = String(user?.info?.name?.first)
  const lastName = String(user?.info?.name?.last)
  const userInfo = (firstName?.length > 0) || (lastName?.length > 0) ? `${firstName} ${lastName}` : user?.info?.email
  const orgName = brandId?.length > 0 ? brandDetails?.name ?? '' : projectDetailData.owner?.name ?? ''
  const orgLogoUrl = brandId?.length > 0 ? brandDetails?.logo?.uris?.logo ?? '' : projectDetailData.owner?.logo?.uris?.logo ?? ''
  const sendNotificationPayload = {
    orgName,
    logoUrl: orgLogoUrl,
    projectId: projectDetailData.id,
    projectName: projectDetailData.name,
    userInfo,
    action: 'update'
  }

  const handleSubmitProject = async (close: boolean): Promise<void> => {
    const { photo, audition, bRoll } = deliverableData
    setIsLoading(true)
    try {
      // ------------------- main -------------------
      const projectDeliverableArr = new Array(deliverableData.main.videoCount).fill(0).map((_, index) => ({
        index,
        projectId: id,
        type: 'main',
        mediaType: 'video',
        script: deliverableData.main.projectDeliverable.script[index].value[0] === '' ? undefined : deliverableData.main.projectDeliverable.script[index].value[0].split('\n'),
        isChecked: deliverableData.main.projectDeliverable.script[index].isChecked,
        id: deliverableData.main.projectDeliverable.script[index].id
      }))

      for (const iterator of projectDeliverableArr) {
        if (iterator.id === null) {
          const index = iterator.index
          const response = await createProjectDeliverableMutation({ variables: { ...iterator, isChecked: undefined, index: undefined } })

          const deliverableId = response.data.createProjectDeliverable.id
          deliverableData.main.projectDeliverable.script[index].id = deliverableId
          updateDeliverableHandler({ ...deliverableData })
        }
      }

      await updateDeliverableSpecsMutation({ variables: { projectId: id, ...deliverableData.main.deliverableSpec, specs: deliverableData.main.deliverableSpec.specs[0] === '' ? undefined : deliverableData.main.deliverableSpec.specs[0].split('\n') } })

      // ----------------- audition ----------------
      if (audition.specs[0].isChecked && audition.id === null) {
        // creating deliverable
        const response = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'audition', mediaType: 'video' } })

        const deliverableId = response.data.createProjectDeliverable.id
        deliverableData.audition.id = deliverableId
        updateDeliverableHandler({ ...deliverableData })
      }
      // creating specs
      await updateDeliverableSpecsMutation({ variables: { projectId: id, deliverableType: 'audition', specs: deliverableData.audition.specs[0].value[0] === '' ? undefined : deliverableData.audition.specs[0].value[0].split('\n') } })

      // ----------------- reference_links ----------------
      const referenceLinks = deliverableData.referenceLinks
      if (referenceLinks.length > 0) {
        await updateUGCProjectMutation({ variables: { updateUgcProjectId: id, referenceLinks } })
      }

      // ---------------- intro_hooks -----------------
      const projectAdditionalArr = deliverableData.introHooks.projectDeliverable.script.map((script, index) => ({ projectId: id, type: 'intro_hook', mediaType: 'video', script: script.isChecked ? script.value[0].split('\n') : undefined, index, id: script.id }))

      for (const iterator of projectAdditionalArr) {
        if (iterator.id === null) {
          const index = iterator.index
          const response = await createProjectDeliverableMutation({ variables: iterator })

          const deliverableId = response.data.createProjectDeliverable.id
          deliverableData.introHooks.projectDeliverable.script[index].id = deliverableId
          updateDeliverableHandler({ ...deliverableData })
        }
      }

      // ----------------- photo -----------------------
      const { projectDeliverable } = photo
      const photoDeliverables = projectDeliverable.specs.map((spec, index) => ({ projectId: id, type: 'photo', mediaType: 'photo', index, stillSpecs: { directions: stillImageDirection(spec.value, spec.additionalNote) }, id: spec.id }))

      if (deliverableData.photo.photoCount > 0) {
        // creating deliverable
        for (const iterator of photoDeliverables) {
          if (iterator.id === null) {
            const index = iterator.index
            const response = await createProjectDeliverableMutation({ variables: iterator })

            const deliverableId = response.data.createProjectDeliverable.id
            deliverableData.photo.projectDeliverable.specs[index].id = deliverableId
            updateDeliverableHandler({ ...deliverableData })
          }
        }
      }

      // ----------------- b_roll -----------------------
      if (bRoll.projectDeliverable.specs.isChecked && bRoll.projectDeliverable.id === null) {
        // creating deliverable
        const response = await createProjectDeliverableMutation({ variables: { projectId: id, type: 'b_roll', mediaType: 'video' } })

        const deliverableId = response.data.createProjectDeliverable.id
        bRoll.projectDeliverable.id = deliverableId
        updateDeliverableHandler({ ...deliverableData })
      }

      // creating specs
      await updateDeliverableSpecsMutation({ variables: { projectId: id, deliverableType: 'b_roll', specs: deliverableData.bRoll.projectDeliverable.specs.value[0] === '' ? undefined : deliverableData.bRoll.projectDeliverable.specs.value[0].split('\n') } })

      if (id !== undefined && close && user?.isAdvertiser) {
        await sendNotificationMutation({
          variables: { ...sendNotificationPayload, projectUrl: `${URL}/projects/ugc/view/${id}/overview` }
        })
      }

      setIsLoading(false)
      sidebarData[2].isCompleted = true

      if (close) {
        navigate('/projects')
      } else if (id != null) {
        navigate(`/projects/ugc/${id}/talent`)
      }

      updateSidebarData([...sidebarData])
    } catch (error) {
      setIsLoading(false)
      dispatch(showToast({ isError: true, message: 'Something went wrong. Please try again.' }))
    }
  }

  return { handleSubmitProject: (close: boolean) => { void handleSubmitProject(close) }, isLoading }
}
