import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { showToast } from '../../../redux/actions/DashBoardActions'

import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

function useDeleteUserMutation () {
  // Hook
  const dispatch = useDispatch()
  // mutation
  const [DeleteUserMutation, { loading }] = useMutation(AdvertiserAPI.deleteAdvertiserUser(), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const deleteUser = async (id, onSuccess) => {
    await DeleteUserMutation({
      variables: {
        id
      }
    })
      .then(res => {
        onSuccess()
        dispatch(showToast({
          message: 'Advertiser member deleted successfully.',
          isError: false
        }))
      })
      .catch(e => dispatch(showToast({
        message: e?.message,
        isError: true
      })))
  }
  return {
    deleteUser,
    loading
  }
}

export default useDeleteUserMutation
