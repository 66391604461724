import { type Dispatch, useContext, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { UGCSidebarContext } from '../../contexts/ugcSidebar'
import { GetProductByProject, GetUGCProject, ListProjectDeliverables } from '../../apis/ugc'
import { type IProjectDetail, type IProjectDetailActions, UGCProjectDetailContext } from '../../contexts/projectDetail'

export const useInitilizeSidebarStatus = (): void => {
  const { id } = useParams()
  const { data: projectGetData, loading: projectGetLoading } = useQuery(GetUGCProject, { variables: { getProjectId: id, withBrand: true }, skip: !!(id == null), fetchPolicy: 'no-cache' })
  const { data: listDeliverablesData } = useQuery(ListProjectDeliverables, { variables: { projectId: id }, skip: id == null, notifyOnNetworkStatusChange: true })
  const { data: productData, loading: productLoading } = useQuery(GetProductByProject, { variables: { projectId: id }, skip: id === undefined })

  const [sidebarData, updateSidebarData] = useContext(UGCSidebarContext)

  const [,updateHandler] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)

  const isEmpty = (data: any): boolean => {
    if (data === null || data === undefined || data[0] === '') {
      return true
    }
    if (Array.isArray(data) && data.length === 0) {
      return true
    }
    if (typeof data === 'object' && Object.keys(data).length === 0) {
      return true
    }
    return false
  }

  // talent
  useEffect(() => {
    if (!projectGetLoading && (Boolean((projectGetData?.getProject?.compensation)))) {
      sidebarData[3].isCompleted = true
      updateSidebarData([...sidebarData])
    }
  }, [projectGetLoading])

  // deliverable
  useEffect(() => {
    if (((listDeliverablesData?.listProjectDeliverables) != null) && listDeliverablesData?.listProjectDeliverables?.length > 0) {
      sidebarData[2].isCompleted = true
      updateSidebarData([...sidebarData])
    }
  }, [listDeliverablesData])

  // project detail
  useEffect(() => {
    if ((!projectGetLoading) && (id != null) && Object.values(projectGetData?.getProject ?? {}).length > 0) {
      updateHandler({
        ...projectGetData?.getProject,
        loading: false,
        dueDate: new Date(projectGetData?.getProject?.endDate ?? '')
      })
      if (!isEmpty(projectGetData?.getProject?.wardrobeSpecs) && !isEmpty(projectGetData?.getProject?.lightingSpecs) && !isEmpty(projectGetData?.getProject?.locationSpecs) && !isEmpty(projectGetData?.getProject?.framingSpecs)) {
        sidebarData[1].isCompleted = true
      }
      sidebarData[0].isCompleted = true
      sidebarData[4].isVisible = projectGetData?.getProject?.hasProduct
      updateSidebarData([...sidebarData])
    } else if (projectGetData?.getProject == null && !projectGetLoading) {
      updateHandler({
        loading: false
      })
    }
  }, [projectGetLoading])

  // product
  useEffect(() => {
    if ((Boolean((productData?.getProductByProject?.id))) && !productLoading) {
      sidebarData[4].isCompleted = true
      updateSidebarData([...sidebarData])
    }
  }, [productLoading])
}
