import { useQuery } from '@apollo/client'

import TagAPI from '../../../services/Tag/TagAPI'

const useTagListQuery = () => {
  const { data, loading } = useQuery(TagAPI.getTagList(), {
    fetchPolicy: 'no-cache'
  })
  return { data, loading }
}

export default useTagListQuery
