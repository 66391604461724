import types from '../types/types'

const INITIAL_STATE = []

const TalentVideoReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_VIDEO_LIST: {
      return payload
    }
    case types.SET_FINAL_VIDEO_LIST: {
      return [...payload]
    }

    case types.APPEND_VIDEO_LIST:
      return [...state, ...payload]

    case types.SET_VIDEO_PROGRESS: {
      const newState = [...state]
      const { filename, progress } = payload
      const index = newState.findIndex(video => video.name === filename)
      newState[index].progress = progress
      return newState
    }

    case types.SET_VIDEO_UPLOAD_STATUS: {
      const newState = [...state]
      const { id, filename, uris, processing } = payload
      const index = newState.findIndex(video => video.name === filename)
      newState[index] = {
        ...newState[index],
        id,
        uris,
        processing,
        file: undefined,
        isUploading: false
      }

      return newState
    }

    case types.SET_VIDEO_ERROR: {
      const newState = [...state]
      const index = newState.findIndex(video => video.name === payload.filename)
      newState[index].isError = true
      newState[index].errorMessage = payload?.errorMessage ?? 'Internal server error'
      return newState
    }

    default:
      return state
  }
}

export default TalentVideoReducer
