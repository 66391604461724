import { type ChangeEvent, useEffect, useState } from 'react'

import classNames from 'classnames'
import style from './InputBox.module.scss'
import { InputAdornment, TextField } from '@mui/material'

interface InputBoxProps {
  label?: string
  name?: string
  errorText?: string
  placeholder?: string
  isError?: boolean
  value?: string | null
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  variant?: string
  withPrefix?: boolean
  prefixText?: string
  withLabel?: boolean
  isMultiline?: boolean
  withLengthSuffix?: boolean
  acceptedLength?: number
  rows?: number
  endAdornment?: JSX.Element
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const InputBox = ({
  label,
  name,
  placeholder = '',
  isError = false,
  errorText = '',
  value,
  onChange,
  onBlur,
  onKeyPress,
  disabled = false,
  rows,
  withLabel = true,
  variant = 'normal',
  withPrefix = false,
  prefixText = '',
  withLengthSuffix = false,
  isMultiline = false,
  acceptedLength = 2046,
  endAdornment
}: InputBoxProps): JSX.Element => {
  const [localValue, setLocalValue] = useState<string>('')

  useEffect(() => {
    if (value != null) {
      setLocalValue(value)
    }
  }, [value])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > acceptedLength) {
      event.target.value = event.target.value.substring(0, acceptedLength)
    }
    if (typeof onChange === 'function') {
      onChange(event)
    }
    setLocalValue(event.target.value)
  }
  return (
    <TextField
      name={name}
      label={withLabel ? label : ''}
      error={isError}
      value={value ?? localValue}
      onChange={handleChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      disabled={disabled}
      placeholder={placeholder}
      type='text'
      helperText={isError ? errorText : ''}
      variant='standard'
      multiline={isMultiline}
      rows={rows}
      className={classNames(style.InputBox, style[`InputBox--${variant}`])}
      InputProps={{
        startAdornment: withPrefix
          ? <span className={classNames(style.InputBox, style[`InputBox--${variant}`], style['InputBox--Bold'])}>{prefixText}&nbsp;</span>
          : '',
        endAdornment: withLengthSuffix ? <InputAdornment position='end'>{value?.length ?? 0}/{acceptedLength}</InputAdornment> : endAdornment ?? ''
      }}
      fullWidth />
  )
}
