
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from '../../constants'
import { RenderIf } from '../../utils/Helpers'

const ButtonContainer = styled.div`
  box-sizing: border-box;
  min-width: ${props => props.minWidth};
  background: ${props => props.backgroundColor};
  margin-left: ${props => props.marginLeft};
  cursor: pointer;
  height: ${props => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 22px;
  cursor: pointer;
  pointer-events: ${props => props.disable ? 'none' : 'visible'};
`

const Spinner = styled.div`
  border: 3px solid ${props => props.color};
  border-top: 3px solid ${Colors.buttonGrayOpacity};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  align-self: center;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Button = props => {
  const {
    outline,
    style = {},
    children,
    minWidth = '100%',
    height = '42px',
    backgroundColor = Colors.black,
    loading = false,
    spinnerColor = Colors.white,
    onClick,
    marginLeft,
    disable = false
  } = props
  return (
    <ButtonContainer
      style={{ ...style, backgroundColor: outline && 'transparent', border: outline && '1px solid black' }}
      onClick={onClick}
      color={Colors.black}
      minWidth={minWidth}
      height={height}
      backgroundColor={backgroundColor}
      marginLeft={marginLeft}
      disable={disable}>
      {children}
      <RenderIf isTrue={loading} >
       <Spinner color={ outline ? Colors.black : spinnerColor }/>
      </RenderIf>
    </ButtonContainer>
  )
}

Button.propTypes = {
  outline: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.element,
  minWidth: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  spinnerColor: PropTypes.string,
  onClick: PropTypes.func,
  marginLeft: PropTypes.string,
  disable: PropTypes.bool
}

export default Button
