import { useQuery } from '@apollo/client'
import DemographicAPI from '../../../services/DemographicAPI'

function useGetSelectionData () {
  const { data, loading } = useQuery(DemographicAPI.talentDemographicSelectionData(), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  return {
    data,
    loading
  }
}

export default useGetSelectionData
