import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'

import { Typography } from '..'

const CircularProgressContainer = styled.div`
  height: 50px;
  width: 100%;
  display: grid;
  place-items: center;
`

export const FooterLoader = ({ loadMessage }) => {
  return (
    <CircularProgressContainer>
      <CircularProgress color="inherit" size={25} />
      <Typography fontSize={14} style={{ marginTop: 20 }}>
        {loadMessage}
      </Typography>
    </CircularProgressContainer>
  )
}

FooterLoader.propTypes = {
  loadMessage: PropTypes.string
}
