import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from '../../../../constants'
import { Button, Typography } from '../../../../components'
import GeneralInfo from '../../../AdvertiserDetail/Component/AdvertiserInfo/GeneralInfo'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  height: 100%;
`
const BottomWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
`

const ButtonTray = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DeleteAdvertiser = ({
  handleDeleteAdvertiserSidebar,
  advertiserData,
  loading = false,
  onDeleteHandler,
  deleteLoading
}) => {
  return (
    <Wrapper>
      <Typography fontWeight="bold" fontSize="34px">
        Do you want to delete this Advertiser?
      </Typography>
      <Typography
        fontSize="14px"
        color={Colors.errorRed}
        style={{ margin: '5px 0 24px 0' }}>
        By doing this action Advertiser will be deleted permanently.
      </Typography>
      <GeneralInfo data={advertiserData} loading={loading} />
      <BottomWrapper>
        <ButtonTray>
          <Button
            height="40px"
            backgroundColor={Colors.white}
            onClick={handleDeleteAdvertiserSidebar}>
            <Typography fontWeight="bold" fontSize={15}>
              CANCEL
            </Typography>
          </Button>
          <Button
            height="40px"
            onClick={onDeleteHandler}
            loading={deleteLoading}>
            <Typography color={Colors.white} fontWeight="bold" fontSize={15}>
              DELETE
            </Typography>
          </Button>
        </ButtonTray>
      </BottomWrapper>
    </Wrapper>
  )
}

export default DeleteAdvertiser

DeleteAdvertiser.propTypes = {
  handleDeleteAdvertiserSidebar: PropTypes.func,
  advertiserData: PropTypes.object,
  loading: PropTypes.bool,
  onDeleteHandler: PropTypes.func,
  deleteLoading: PropTypes.bool
}
