import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@mui/material'

import { FormikControl } from '../../../../../components/formik/formikControl'

const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
`
const PhoneContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: flex-start;
`
const ProjectOverviewRightForm = ({ index, roleData }) => {
  return (
    <>
      <FieldContainer>
       <FormikControl
          name={`members.${index}.firstname`}
          isStandard
          control="input"
          label="First Name"
          required
        />
        </FieldContainer>
        <FieldContainer>
        <FormikControl
          name={`members.${index}.lastname`}
          isStandard
          control="input"
          label="Last Name"
          required
        />
        </FieldContainer>
        <FieldContainer>
        <FormikControl
          name={`members.${index}.email`}
          isStandard
          control="input"
          label="Email"
          required
        />
        </FieldContainer>
        {roleData &&
        <Box marginTop={2}>
          <FormikControl
            control="multiselectfortag"
            label="Title"
            name={`members.${index}.title`}
            isStandard
            placeholder="Title"
            freeSolo={false}
            containerStyle={{ height: 50 }}
            style={{ height: 100 }}
            options={roleData.map(role => ({ id: role.id, label: role.name }))}
            data={roleData.map(role => role.name)}
          />
        </Box>}
        <PhoneContainer>
          <FormikControl
            control="select"
            label=""
            name={`members.${index}.countryCode`}
            isStandard
            placeholder="CountryCode"
            containerStyle={{ marginRight: 10, display: 'flex', height: 50, width: 100 }}
            style={{ fontSize: 12, height: 100 }}
            options={[{ id: 0, name: '+1 (US)' }]}
            hidelabel
          />
          <div style={{ width: '100%', marginTop: '1.5px' }}>
          <FormikControl
            name={`members.${index}.phone`}
            isStandard
            control="input"
            label="Phone"
          />
          </div>
        </PhoneContainer>
    </>
  )
}

ProjectOverviewRightForm.propTypes = {
  index: PropTypes.number,
  roleData: PropTypes.array
}

export default ProjectOverviewRightForm
