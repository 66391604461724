import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'

import Style from '../../Styles'
import { Images } from '../../../../assets'
import CardWrapper from '../Card/CardWrapper'
import { Colors } from '../../../../constants'
import { Typography } from '../../../../components'
import { setBulkUploadList } from '../../../../redux/actions/BulkUploadAction'

// Prompt for preventing reload or change page
import { usePrompt } from '../../../../hooks/Common/usePrompt'

const getColor = (props) => {
  if (props.isDragAccept) {
    return 'green'
  }
  if (props.isDragReject) {
    return 'green'
  }
  return 'white'
}

const Container = styled.div`
  height: 100%;
  border-radius: 5px;
  border: 1px dashed ${props => getColor(props)};
  outline: none;
  box-shadow: inset 0 0 35px -25px ${props => getColor(props)};
  transition: border .24s ease-in-out;
  margin: 0 30px 30px;
`

const UploadMedia = ({ setData, data, isUploading }) => {
  // hooks
  const dispatch = useDispatch()
  // state
  const [fileArray, setFileArray] = useState([])
  const [fileLength, setFileLength] = useState(0)
  const [fileCounter, setFileCounter] = useState(0)
  const [uploadLoader, setUploadLoader] = useState(false)

  // custome Prompt message for prevent route change or reload page
  usePrompt('Uploading is in progress. Are you sure you want to navigate away?', !!(isUploading !== 'pending'))

  // for handle drop images
  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    setFileArray([])
    setFileCounter(0)
    setFileLength(acceptedFiles.length)
    if (rejectedFiles && rejectedFiles.length) {
      rejectedFiles.forEach((rejectedFile) => {
        let errorMsg = ''
        switch (rejectedFile.errors[0].code) {
          case 'file-invalid-type':
            errorMsg = `Incorrect File Format, File(${rejectedFile.file.name}) type must be PNG or JPG.`
            break
          default:
            errorMsg = rejectedFile.errors[0].message
        }
        console.log(errorMsg, 'err')
      })
    }
    for (const file of acceptedFiles) {
      setUploadLoader(true)
      const image = new Image()
      image.src = URL.createObjectURL(file)
      if (file.name.split('.')[1] === 'heic' || file.name.split('.')[1] === 'HEIC') {
        setFileArray(prevData => [...prevData, {
          file,
          title: file.name,
          isUploading: false,
          isValid: !(file.size > 5000000),
          uploadProgress: 0,
          isUploaded: (file.size > 5000000),
          isExceedSizeLimit: file.size > 5000000,
          src: Images.UploadPlaceholder
        }])
        setFileCounter(preState => preState + 1)
      } else {
        image.addEventListener('load', () => {
          setFileArray(prevData => [...prevData, {
            file,
            title: file.name,
            isUploading: false,
            isValid: !(file.size > 5000000),
            uploadProgress: 0,
            isUploaded: (file.size > 5000000),
            isExceedSizeLimit: file.size > 5000000,
            src: image.src
          }])
          setFileCounter(preState => preState + 1)
        })
      }
    }
    if (fileCounter === acceptedFiles.length) {
      const filteredData = []
      const combineData = [...fileArray, ...data]
      combineData.forEach(function (item) {
        const i = filteredData.findIndex(x => x.title === item.title)
        if (i <= -1) {
          filteredData.push(item)
        }
      })
      setData(filteredData)
      dispatch(setBulkUploadList(filteredData))
    }
  }

  useEffect(() => {
    if (fileCounter === fileLength) {
      const filteredData = []
      const combineData = [...fileArray, ...data]
      combineData.forEach(function (item) {
        const i = filteredData.findIndex(x => x.title === item.title)
        if (i <= -1) {
          filteredData.push(item)
        }
      })
      dispatch(setBulkUploadList(filteredData))
      setData(filteredData)
      setUploadLoader(false)
    }
  }, [fileCounter, fileArray])

  useEffect(() => {
    if (data.length < 1) {
      setFileArray([])
      setFileCounter(0)
    }
  }, [data.length])

  // dropzone hook component
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noDrag: !(isUploading === 'pending'),
    onDrop: (acceptedFiles, rejectedFiles) => handleDrop(acceptedFiles, rejectedFiles)
  })
  const { getRootProps: getRootPropsChild, getInputProps: getChildInputProps, open } = useDropzone({
    noClick: true,
    accept: 'image/*',
    noDrag: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, rejectedFiles) => handleDrop(acceptedFiles, rejectedFiles)
  })

  return (
    <>
      <Container
      {...getRootProps({
        className: 'dropzone',
        isFocused,
        isDragAccept,
        isDragReject,
        onClick: evt => evt.preventDefault()
      })}>
        <input {...getInputProps()} />
        {data.length > 0
          ? <CardWrapper data={data} uploadLoader={uploadLoader} />
          : <Style.UploadContainerChild>
              <div>
                <Typography fontSize={34} fontWeight='bold' letterSpacing='0.25px'>
                  Upload your Images
                </Typography>
                <Typography fontSize={14} letterSpacing='0.25px' color={Colors.inactiveBlack} style={{ margin: '5px 0 10px 0' }}>
                  Max 5 MB for each Image
                </Typography>
                <div>
                  <Style.ImgView src={Images.uploadIcon} />
                  <Typography fontSize={14} lineHeight={'20px'}>
                    Drag &amp; drop your files or
                  </Typography>
                  <Style.BrowseWrapper>
                    <div {...getRootPropsChild({ className: 'dropzone' })}>
                      <input {...getChildInputProps()} />
                       <div type="button" onClick={open}>
                          <Typography
                          fontWeight='bold'
                          fontSize={14}
                          style={{
                            marginRight: 5,
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}>
                          Browse
                        </Typography>
                      </div>
                    </div>
                    <Typography fontSize={14}>from your computer</Typography>
                  </Style.BrowseWrapper>
                </div>
              </div>
            </Style.UploadContainerChild>}
      </Container>
    </>
  )
}

export default UploadMedia

UploadMedia.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.array,
  isUploading: PropTypes.string
}
