import styled from 'styled-components'
import { Colors } from '../../constants'
import PropTypes from 'prop-types'
const OpacityObject = { 1: Colors.blackOpacity, 2: Colors.black, 3: 'none' }

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  position: fixed;
  justify-content: ${props => props.direction === 'right' ? 'flex-end' : 'flex-start'};
  background: ${props => props.opacityColor};
  z-index: ${props => props.zIndex};
  top: 0;
  left: 0;
`

const SideView = styled.div`
  width: ${props => props.width};
  display: flex;
  background: ${Colors.white};
  flex-direction: column;
`

export const TlyntSidebar = ({ width, opacityLevel, direction, children, zIndex }) => {
  const opacityColor = OpacityObject[opacityLevel]
  return (
    <Wrapper direction={direction} opacityColor={opacityColor} zIndex={zIndex}>
      <SideView width={width}>{children}</SideView>
    </Wrapper>
  )
}

TlyntSidebar.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node,
  direction: PropTypes.string,
  opacityLevel: PropTypes.number,
  zIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

TlyntSidebar.defaultProps = {
  width: '402px',
  direction: 'right',
  opacityLevel: 1,
  zIndex: '10'
}
