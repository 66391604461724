import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import AdvertiserUserAPI from '../../../services/AdvertiserUser/AdvertiserUserAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useUserSearchListQuery (props) {
  // props
  const { order, searchQuery } = props
  const dispatch = useDispatch()

  const payload = {
    offset: 0,
    limit: 15,
    sort: order === 'createdAt' ? null : JSON.stringify({ firstname: order, lastname: order }),
    search: searchQuery
  }

  const { data, loading, fetchMore, refetch } = useQuery(
    AdvertiserUserAPI.getSearchUserList(),
    {
      variables: Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null)),
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        dispatch(showToast({ message: error?.message ?? "Something went wrong", isError: true }))
      }
    },
  )
  return {
    data,
    loading,
    fetchMore,
    refetch
  }
}

export default useUserSearchListQuery
