import { Fragment, useEffect, useState } from 'react'

import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Select, MenuItem, Typography } from '@mui/material'

import { Images } from '../../assets'
import { RenderIf } from '../../utils/Helpers'
import { Wrapper, MiddleContainer } from '../../ThemeStyle'
import { Colors, ProjectConstants } from '../../constants'
import { SideBar, TlyntSidebar } from '../../components'
import { Detail, ProjectInfo, TeamInfo, AdvertiserInfo, Brands } from './Component'

import useCombineWithRole from './Hooks/useCombineWithRole'
import useGetAdvertiserQuery from './Hooks/useGetAdvertiserQuery'
import AddNewAdvertiser from '../Advertiser/Components/AddAdvertiser'
import { ProjectListProvider } from '../../contexts/project/projectList'

const BackImage = styled.img`
  height: 16px;
  width: 16px;
`
const TabWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding-left: 45px;
  border-bottom: ${props => (props.isLoading ? '0px' : '1px')} solid #eeeeee;
`
const TabItem = styled.div`
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.15s ease-in;
  border-bottom: 2px solid
    ${props => (props?.isActive ? Colors.black : 'transprent')};
`
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 40px;
  margin-bottom: 20px;
  width: fit-content;
  cursor: pointer;
`
const SelectDesign = styled(Select)`
  padding: 8px;
  width: 200px;
  height: 40px;
  margin-left: auto;
  margin-right: 40px;
  background: ${Colors.antiFlashWhite};
  cursor: pointer !important;
  border-radius: 0 !important;
  border-bottom: none !important;
  text-transform: capitalize;
  ::after, ::before {
    border-bottom: none !important;
  }
  div {
    padding: initial;
    height: 40px;
    display: flex;
    align-items: center;
    background: transprent !important;
    :focus {
    background: transprent !important;
    }
  }
  input {
    border: none !important;
  }
`

const tabs = {
  details: {
    id: 'details',
    title: 'Advertiser Details',
    enabled: true
  },
  brands: {
    id: 'brands',
    title: 'Brands',
    enabled: false
  },
  projects: {
    id: 'projects',
    title: 'Projects',
    enabled: true
  },
  team: {
    id: 'team',
    title: 'Team',
    enabled: true
  }
}

const ActiveTabComponent = (activeTabComponentProps) => {
  const {
    id,
    data,
    loading,
    refetch,
    projectStatus,
    activeTabID,
    isEditAdvertiserModal,
    setIsEditAdvertiserModal
  } = activeTabComponentProps
  switch (activeTabID) {
    case 'details':
      return (
        <Detail
          loading={loading}
          data={data?.organizationGet}
          refetchAdvertiser={refetch}
          isEditAdvertiserModal={isEditAdvertiserModal}
          setIsEditAdvertiserModal={setIsEditAdvertiserModal}
        />
      )
    case 'brands':
      return (
        <Brands orgID={id} />
      )
    case 'projects':
      return (
        <ProjectListProvider>
          <ProjectInfo
            advertiserId={id}
            projectStatus={projectStatus}
          />
        </ProjectListProvider>
      )
    case 'team':
      return (
        <TeamInfo
          advertiserId={id}
          refetchAdvertiser={refetch}
          advertiserData={data?.organizationGet}
        />
      )
    default: return null
  }
}

const AdvertiserDetail = () => {
  // hooks
  const navigate = useNavigate()
  const { id } = useParams()
  // api calls
  const { data, refetch, loading } = useGetAdvertiserQuery({ id })
  const { data: membersData, refetch: refetchMemberData } = useCombineWithRole({ advertiserId: id })
  // states
  const [enabledTabs, setEnabledTabs] = useState(Object.values(tabs).filter(i => i.enabled))
  const [activeTabID, setActiveTabID] = useState('details')
  const [projectStatus, setProjectStatus] = useState('all')
  const [isEditAdvertiserModal, setIsEditAdvertiserModal] = useState(false)

  // constants
  const ProjectMenu = ProjectConstants.Project.tabBarMenu

  // functions
  const handleEditAdvertiserSidebar = () =>
    setIsEditAdvertiserModal(!isEditAdvertiserModal)

  const projectFilter = (e) => {
    setProjectStatus(e.target.value)
  }

  // EFFECTS

  // Enables brands tab if the advertiser is an agency
  useEffect(() => {
    if (data?.organizationGet?.type === 'agency') {
      tabs.brands.enabled = true
      setEnabledTabs(Object.values(tabs).filter(i => i.enabled))
    }
  }, [data])

  return (
    <>
      <Wrapper>
        <SideBar />
        <MiddleContainer>
          <Fragment>
            <HeaderWrapper onClick={() => navigate(-1)}>
              <BackImage src={Images.backArrowBlack} />
              <Typography ml={1}>
                Back
              </Typography>
            </HeaderWrapper>
            <TabWrapper>
              {enabledTabs.map((item) => (
                <TabItem
                  onClick={() => setActiveTabID(item?.id)}
                  isActive={item?.id === activeTabID}
                  key={`org-tab-${item.id}`}>
                  <Typography
                    fontSize={16}
                    marginInline={1}
                    letterSpacing={'0.15px'}
                    textTransform="capitalize"
                    color={item?.id === activeTabID ? Colors.black : Colors.spanishGray}>
                    {item?.title}
                  </Typography>
                </TabItem>
              ))}
              {activeTabID === 'projects' && (
                <SelectDesign variant="filled" value={projectStatus} onChange={(e) => projectFilter(e)}>
                  {ProjectMenu.map((e, i) => <MenuItem value={e.name} key={i} sx={{ textTransform: 'capitalize' }}>{e.name.replaceAll('_', ' ')}</MenuItem>)}
                </SelectDesign>
              )}
            </TabWrapper>
            <ActiveTabComponent
              id={id}
              data={data}
              loading={loading}
              refetch={refetch}
              projectStatus={projectStatus}
              activeTabID={activeTabID}
              isEditAdvertiserModal={isEditAdvertiserModal}
              setIsEditAdvertiserModal={setIsEditAdvertiserModal}
            />
          </Fragment>
        </MiddleContainer>
        <AdvertiserInfo data={data?.organizationGet} loading={loading} />
        <Fragment>
          <RenderIf isTrue={isEditAdvertiserModal}>
            <TlyntSidebar>
              <AddNewAdvertiser
                handleToggle={handleEditAdvertiserSidebar}
                reFetchData={() => {
                  refetch()
                  refetchMemberData()
                }}
                advertiserData={data?.organizationGet}
                // members props
                advertiserId={id}
                memberData={membersData}
                refetchMemberData={refetchMemberData}
              />
            </TlyntSidebar>
          </RenderIf>
        </Fragment>
      </Wrapper>
    </>
  )
}

export default AdvertiserDetail
