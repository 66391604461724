import { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { Box, Divider, Typography } from '@mui/material'

import Notes from '../Notes'
import { Colors } from '../../../../constants'
import UserGeneralInfo from './UserGeneralInfo'
import { RenderIf } from '../../../../utils/Helpers'

import PublicTalentInfo from '../PublicTalentInfo'
import { Button } from '../../../../components'
import useVoteByUserQuery from '../../../PublicSelect/Hooks/useVoteByUserQuery'
import useCreateVoteMutation from '../../../PublicSelect/Hooks/useCreateVoteMutation'
import useRemoveVoteMutation from '../../../PublicSelect/Hooks/useRemoveVoteMutation'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  width: 400px;
  flex-shrink: 0;
  box-sizing: border-box;
  justify-content: space-between;
  border-left: 1px solid ${Colors.primaryGray};
`
const ManageTalentButton = styled(Link)`
  width: 100%;
  display: flex;
  height: inherit;
  align-items: center;
  text-decoration: none;
  justify-content: center;
`

export const UserInfo = (props) => {
  // destructuring props
  const {
    parentId,
    profileData,
    loading,
    error,
    handelToggleSelectSidebar,
    handelToggleDeleteSidebar,
    handelToggleDisableSidebar,
    isPublic,
    isUserTalent,
    updateTalentFunc,
    updateTalentLoading
  } = props

  // hooks
  const { talentId } = useParams()
  const user = useSelector((state) => state.UserReducer)
  // states
  const [isLiked, setIsLiked] = useState(false)
  const [isFlagged, setIsFlagged] = useState(false)
  // constants
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  // api's
  const { createVote, loading: createVoteLoading } = useCreateVoteMutation(parentId)
  const { removeVote, loading: removeVoteLoading } = useRemoveVoteMutation(parentId)
  const { data: userVotesData, loading: userVotesLoading } = useVoteByUserQuery(parentId, !isPublic)

  // manipulating states
  useEffect(() => {
    if (!userVotesLoading) {
      setIsLiked(!!userVotesData?.selectionVoteByUser.find(select => select.talentId === talentId))
    }
  }, [userVotesLoading])

  const handleDisableTalent = (isDisable) => {
    updateTalentFunc(profileData.id, !isDisable, false)
    setIsFlagged(false)
  }
  // ***** Public Methods starts *******
  const handleSuccess = () => setIsLiked(!isLiked)

  const handleLike = () => {
    !isLiked
      ? createVote(handleSuccess, talentId)
      : removeVote(handleSuccess, talentId)
  }

  // ***** Public methods ends *******

  if (error) {
    return (
      <Wrapper>
        <p>Error :(</p>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Box>
        <Box backgroundColor='white' position='sticky' top={0} width='100%' boxSizing='border-box'>
          <Box paddingInline='35px' pt='40px'>
            <UserGeneralInfo loading={loading} isPublic={isPublic} profileData={profileData} />
            <RenderIf isTrue={!loading && !isPublic && !isUserTalent}>
              <Button outline style={{ width: 256 }} onClick={handelToggleSelectSidebar}>
                <Typography fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>
                  ADD TO SELECT
                </Typography>
              </Button>
            </RenderIf>
            <RenderIf isTrue={!loading && isUserTalent}>
              <Button outline={!profileData?.flagged} style={{ width: 256 }} backgroundColor={Colors.black} onClick={() => { updateTalentFunc(profileData.id, !profileData.flagged, true); setIsFlagged(true) }} loading={isFlagged && updateTalentLoading}>
                <Typography color={profileData?.flagged ? Colors.white : Colors.black} fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>
                  {profileData?.flagged ? 'UNFLAG TALENT' : 'FLAG TALENT'}
                </Typography>
              </Button>
            </RenderIf>
            <RenderIf isTrue={!loading && isPublic}>
              <Button outline={!isLiked} disabled={user?.isAdmin} onClick={handleLike} loading={createVoteLoading || removeVoteLoading} style={{ width: 256, marginBottom: 20, marginTop: 10 }}>
                <Typography color={isLiked ? Colors.white : Colors.black} fontSize={15} fontWeight={'bold'} sx={{ opacity: user?.isAdmin ? 0.5 : 1 }} letterSpacing={0.46}>
                  {isLiked ? 'LIKED' : 'LIKE'}
                </Typography>
              </Button>
            </RenderIf>
          </Box>
          <Divider sx={{ marginTop: '30px', marginBottom: '30px' }} />
        </Box>
        {((isPublic || userInfo?.type === 'advertiser') && profileData?.onboardFlag?.hasCompletedPersonalInfo) && <PublicTalentInfo profileData={profileData} />}
      </Box>
      <RenderIf isTrue={userInfo?.type !== 'advertiser'}>
        <Fragment>
          {!isPublic && <Notes data={profileData} />}
          {!isPublic && profileData && (
            <Box padding='40px'>
              <RenderIf isTrue={isUserTalent}>
                <Button
                  outline={!profileData?.disabled}
                  style={{ width: 256, marginBottom: 20, marginTop: 40 }}
                  backgroundColor={Colors.spanishOrange}
                  onClick={() => profileData?.disabled ? handleDisableTalent(profileData?.disabled) : handelToggleDisableSidebar()}>
                  <Typography color={profileData?.disabled ? Colors.white : Colors.spanishOrange} fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>
                    {profileData?.disabled ? 'ENABLE TALENT' : 'DISABLE TALENT'}
                  </Typography>
                </Button>
              </RenderIf>
              {isUserTalent
                ? <Button outline style={{ width: 256 }} backgroundColor={Colors.pink} onClick={handelToggleDeleteSidebar}>
                  <Typography color={Colors.pink} fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>DELETE TALENT</Typography>
                </Button>
                : <Button outline style={{ width: 256, marginTop: 40, padding: 0 }} backgroundColor={Colors.pink}>
                  <ManageTalentButton to={`/users/talent/${profileData?.id}`}>
                    <Typography color={Colors.pink} fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>MANAGE TALENT</Typography>
                  </ManageTalentButton>
                </Button>}
            </Box>
          )}
        </Fragment>
      </RenderIf>
    </Wrapper>
  )
}

UserInfo.propTypes = {
  parentId: PropTypes.string,
  profileData: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  handelToggleSelectSidebar: PropTypes.func,
  handelToggleDeleteSidebar: PropTypes.func,
  isPublic: PropTypes.bool,
  mediaProfile: PropTypes.string,
  isUserTalent: PropTypes.bool,
  handelToggleDisableSidebar: PropTypes.func,
  updateTalentFunc: PropTypes.func,
  updateTalentLoading: PropTypes.bool
}
