import { useQuery } from '@apollo/client'
import TalentAPI from '../../../services/Talent/TalentAPI'

function useTalentMediaQuery (props) {
  const { talentId, disabled } = props
  const {
    loading: talentMediaLoading,
    error: talentMediaError,
    data: talentMediaData,
    fetchMore: talentMediaFetchMore
  } = useQuery(
    TalentAPI.getTalentMediaListData(),
    {
      variables: {
        talentId,
        disabled,
        offset: 0,
        limit: 15,
        type: 'photo'
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    }
  )

  return { talentMediaLoading, talentMediaError, talentMediaData, talentMediaFetchMore }
}

export default useTalentMediaQuery
