import { type ReactNode, useReducer, createContext, type Dispatch } from 'react'

export interface IProjectDetail {
  description: string
  ownerId: string
  dueDate: string | Date
  hasProduct: string | null
  brandId: string
  name: string
  id: string
  loading: boolean
  status: string
  owner?: IOwner
}

interface IOwner {
  id: string
  logo: Logo
  brands?: any
  name: string
}

interface Logo {
  uris: Uris
}

interface Uris {
  logo: string
}

export interface IProjectDetailActions {
  description?: string
  ownerId?: string
  dueDate?: string | Date
  hasProduct?: string | null
  brandId?: string
  name?: string
  id?: string
  status?: string
  loading?: boolean
  owner?: IOwner
}

export const projectDetailInitialData = {
  description: '',
  ownerId: '',
  dueDate: '',
  hasProduct: null,
  brandId: '',
  name: '',
  id: '',
  loading: true,
  status: '',
  owner: {
    id: '',
    logo: {
      uris: {
        logo: ''
      }
    },
    brands: null,
    name: ''
  }
}
export const UGCProjectDetailContext = createContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>([projectDetailInitialData, () => {}])

interface UGCProjectDetailProviderProps {
  children: ReactNode
}

export const UGCProjectDetailProvider = ({ children }: UGCProjectDetailProviderProps): JSX.Element => {
  const [projectDetailData, updateProjectDetailData] = useReducer((prev: IProjectDetail, next: IProjectDetailActions) => ({ ...prev, ...next }), { ...projectDetailInitialData })

  return (
    <UGCProjectDetailContext.Provider value={[projectDetailData, updateProjectDetailData]}>
      {children}
    </UGCProjectDetailContext.Provider>
  )
}
