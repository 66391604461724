import { useState } from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Box, Checkbox, CircularProgress } from '@mui/material'

import { Images } from '../../assets'
import images from '../../assets/images'
import { Colors } from '../../constants'
import { RenderIf } from '../../utils/Helpers'

import {
  CheckboxWrapper,
  BottomWrapper,
  BottomLeft,
  BottomRight,
  TalentDetail,
  TalentTitle,
  TalentLocation,
  MediaToggleBox,
  ToggleArrowWrapper,
  TalentImage,
  ToggleArrow,
  DeleteTalent,
  CheckboxGrayWrapper
} from './Styles'
import { VoteList } from '../VoteList'

export const TalentCard = props => {
  // props
  const {
    isSelectionMode,
    data,
    handelTalentSelection,
    isUserTalent = false,
    isSelect = false,
    handleActionOnCard = data => null
  } = props
  const { firstname, lastname, isSelected, media, location, mediaProfile } = data

  // constants
  const mediaWithProfile = mediaProfile ? [mediaProfile].concat(media) : media
  const copyMediaWithProfile = mediaProfile ? [mediaProfile].concat(mediaWithProfile?.filter(item => item?.id !== mediaProfile?.id)) : mediaWithProfile?.filter(item => item?.id !== mediaProfile?.id)
  const mediaCount = copyMediaWithProfile?.length

  // hooks
  const user = useSelector(state => state.UserReducer)
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)

  // constants
  const {
    talentCardResizeData: {
      resolutionName
    }
  } = dashboardReduxData

  // states
  const [loading, setLoading] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const [activeImageIndex, setActiveImageIndex] = useState(0)

  // functions
  const handleTalentDetail = queryString => isUserTalent ? `/users/talent/${queryString}` : `/talent/${queryString}`

  // handel talent selection
  const handelTalentSelectionMain = (e, id) => {
    e.preventDefault()
    handelTalentSelection(id)
  }

  // retirn the select vote list with advertiser name

  return (
    <Box position='relative'>
      <TalentImage
        onLoad={() => setIsImageLoaded(true)}
        onError={(e) => { e.target.src = Images.tlyntPlaceholder }}
        src={mediaCount && isImageLoaded ? copyMediaWithProfile[activeImageIndex]?.uris?.card : Images.tlyntPlaceholder }
      />
      <CheckboxWrapper show={!isSelectionMode}>
        <CheckboxGrayWrapper>
          <Checkbox
            checked={isSelected}
            sx={{
              color: Colors.white,
              '&.Mui-checked': {
                color: isSelected ? Colors.white : Colors.black
              }
            }}
          />
        </CheckboxGrayWrapper>
      </CheckboxWrapper>

    <RenderIf isTrue={isSelect}>
      <VoteList voteData={data.voteData} />
      <DeleteTalent
        onClick={e => {
          e.stopPropagation()
          handleActionOnCard(data, () => setLoading(false))
          setLoading(true)
        }}>
        {loading ? <CircularProgress size='15px' color='inherit' /> : <img src={images.crossWhite} height={14} />}
      </DeleteTalent>
    </RenderIf>

    <Link to={isSelectionMode ? 'javascript:void(0)' : handleTalentDetail(data.id)} target='_blank' state={isUserTalent ? null : { isFromTalent: true }}>
      <BottomWrapper onClick={e => isSelectionMode ? handelTalentSelectionMain(e, data.id) : null}>
      <BottomLeft>
        <TalentDetail>
          <RenderIf isTrue={firstname}>
            <TalentTitle title={`${firstname} ${user?.isAdmin ? lastname || '' : `${lastname?.charAt(0).toUpperCase()}.`}`}>
              { `${firstname} ${user?.isAdmin ? lastname ?? '' : `${lastname?.charAt(0).toUpperCase()}.`}` }
            </TalentTitle>
          </RenderIf>
          <RenderIf isTrue={user?.isAdmin}>
            {resolutionName !== 'SM' && <TalentLocation>{location}</TalentLocation>}
          </RenderIf>
        </TalentDetail>
      </BottomLeft>
      <BottomRight visible={mediaCount}>
      <MediaToggleBox>
        <ToggleArrowWrapper
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              activeImageIndex > 0 &&
                setActiveImageIndex(activeImageIndex - 1)
            }}>
            <ToggleArrow
              isAllowed={activeImageIndex > 0}
              src={Images.chevronUp}
            />
          </ToggleArrowWrapper>
          <ToggleArrowWrapper
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              activeImageIndex < mediaCount - 1 &&
                setActiveImageIndex(activeImageIndex + 1)
            }}>
            <ToggleArrow
              isAllowed={activeImageIndex < mediaCount - 1}
              src={Images.chevronDown}
            />
          </ToggleArrowWrapper>
        </MediaToggleBox>
      </BottomRight>
      </BottomWrapper>
    </Link>
   </Box>
  )
}

TalentCard.propTypes = {
  isSelectionMode: PropTypes.bool,
  data: PropTypes.object,
  handelTalentSelection: PropTypes.func,
  isUserTalent: PropTypes.bool,
  isSelect: PropTypes.bool,
  handleActionOnCard: PropTypes.func
}
