import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch, useStore } from 'react-redux'

import { GetProjectSubmissionJob } from '../../apis/ugc'
import { API } from '../../constants'
import { setUGCAssetsDownloadJobs } from '../../redux/slices/ugcDownloadAssetSlice'
import type { AssetState, DownloadAssetsState } from '../../redux/slices/ugcDownloadAssetSlice'
import { ProjectSubmissionCompressJobStatus } from '../../utils/ProjectHelper'

export const useDownloadAssets = (): void => {
  // init
  const intervalRef = useRef<any>([])
  const dispatch = useDispatch()
  const store: any = useStore()
  const { id } = useParams()

  const { showBottomBanner, downloadAssetsJobs }: DownloadAssetsState = useSelector((state: any) => state.UGCDownloadAssetsReducer)

  const [getProjectSubmissionJob] = useLazyQuery(GetProjectSubmissionJob, { notifyOnNetworkStatusChange: true })

  const isAnyFailedDownloadJobs = downloadAssetsJobs?.filter((job: AssetState) => job?.status === ProjectSubmissionCompressJobStatus.errored) ?? []

  useEffect(() => {
    if (showBottomBanner) {
      const intervalId = setInterval(() => {
        void handleUpdateDownloadAssetsJobs()
      }, 5000)
      intervalRef.current.push(intervalId)
    } else {
      for (const intervalId of intervalRef.current) {
        clearInterval(intervalId)
      }
      intervalRef.current = []
    }

    return () => {
      for (const intervalId of intervalRef.current) {
        clearInterval(intervalId)
      }
      intervalRef.current = []
    }
  }, [showBottomBanner])

  useEffect(() => {
    if (isAnyFailedDownloadJobs.length > 0) {
      for (const intervalId of intervalRef.current) {
        clearInterval(intervalId)
      }
      intervalRef.current = []
    }
  }, [isAnyFailedDownloadJobs])

  const handleUpdateDownloadAssetsJobs = async (): Promise<void> => {
    try {
      const downloadAssetsJobs = store.getState().UGCDownloadAssetsReducer.downloadAssetsJobs
      for (const downloadJob of downloadAssetsJobs) {
        await handleDownloadSubmission(downloadJob?.jobId)
      }
    } catch (error) {

    }
  }

  const handleDownloadSubmission = async (jobId: string): Promise<void> => {
    try {
      const downloadAssetsJob = store.getState().UGCDownloadAssetsReducer.downloadAssetsJobs ?? []

      const response = await getProjectSubmissionJob({ variables: { jobId, projectId: id }, fetchPolicy: 'no-cache' })
      const isJobExist = downloadAssetsJob.find((job: AssetState) => job.jobId === jobId) !== undefined
      const currentJob = response?.data?.getProjectJob

      const currentJobObj = {
        status: currentJob?.status,
        jobId: currentJob?.jobId,
        talentId: currentJob?.talentId
      }

      if (currentJob?.status === ProjectSubmissionCompressJobStatus.complete && currentJob?.filepath !== undefined && currentJob?.filepath !== null && isJobExist) {
        // initiate download
        let downloadUrl = `${String(API.adminCDNBaseUrl)}/${String(currentJob?.filepath)}`
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        downloadUrl = ''
        const updatedJobs = downloadAssetsJob.filter((job: AssetState) => job.jobId !== jobId)

        dispatch(setUGCAssetsDownloadJobs([...updatedJobs]))
      } else if ((currentJob?.status === ProjectSubmissionCompressJobStatus.processing || currentJob?.status === ProjectSubmissionCompressJobStatus.pending) && isJobExist) {
        const updatedJobs = downloadAssetsJob.map((job: AssetState) => job.jobId === jobId ? currentJobObj : job)

        dispatch(setUGCAssetsDownloadJobs([...updatedJobs]))
      } else if (currentJob?.status === ProjectSubmissionCompressJobStatus.errored && isJobExist) {
        const updatedJobs = downloadAssetsJob.map((job: AssetState) => job.jobId === jobId ? currentJobObj : job)

        dispatch(setUGCAssetsDownloadJobs([...updatedJobs]))
      }
    } catch (error) {
      console.error('Error while requesting download submission:', error)
    }
  }
}
