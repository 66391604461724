import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Images } from '../../assets'
import { Fonts, Colors } from '../../constants'
import { hideToast } from '../../redux/actions/DashBoardActions'
import { useEffect } from 'react'

const Wrapper = styled.div`
  background: ${props => props.isError ? Colors.errorRed : Colors.successGreen};
  position: absolute;
  right: 50px;
  top: 40px;
  width: 320px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  z-index: 2000;
`
const ToastIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 13px;
`
const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
const Title = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: ${Colors.white};
`
const LeftContainer = styled.div`
  align-items: center;
  padding-left: 18px;
  display: flex;
  width: 80%;
`
const RightContainer = styled.div`
  padding-right: 28px;
`

export const GlobalToast = () => {
  // hooks
  const dispatch = useDispatch()
  const dashboard = useSelector(state => state.DashBoardReducer)
  const { isToastVisible, toastMessage, isError } = dashboard

  const handelCloseModal = () => dispatch(hideToast())

  useEffect(() => {
    if (isToastVisible) {
      setTimeout(() => {
        handelCloseModal()
      }, 5000)
    }
  }, [isToastVisible])

  return (
    isToastVisible && (
      <Wrapper isError={isError}>
        <LeftContainer>
          <ToastIcon
            src={isError ? Images.errorRoundedIcon : Images.successCheck}
          />
          <Title> {toastMessage} </Title>
        </LeftContainer>
        <RightContainer>
          <CloseIcon src={Images.closeSuccessIcon} onClick={handelCloseModal} />
        </RightContainer>
      </Wrapper>
    )
  )
}
