import { decodeJwt } from 'jose'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Typography } from '../../../../components'
import { Colors } from '../../../../constants'
import { getPublicToken } from '../../../../services/RestAPI'
import { PublicSelectValidationSchema } from '../../../../services/ValidationServices'
import { showToast } from '../../../../redux/actions/DashBoardActions'


const Main = styled.main`
  width: min(100% - 40px, 1120px);
  min-height: 80vh;
  text-align: center;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
`
const FieldInput = styled.input`
  width: 290px;
  height: 34px;
  font-style: normal;
  font-weight: normal;
  outline: none;
  overflow:hidden;
  resize: none;
  font-size: 18px;
  line-height: 30px;
  color: ${Colors.labelBlack};
  border: 0;
  display:block;
  padding: 3px 10px;
`
const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const InputBar = styled.div`
  display: flex;
  justify-content: flex-start;
  border: ${props => `1px solid ${props.isError ? Colors.errorRed : Colors.inputGray}`}

`

const AuthScreen = (props) => {
  // init
  const { handleRefetchSelect } = props

  // hooks
  const formikRef = useRef(null)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)


  const handleError = (err) => {
    if (err?.message?.includes('Incorrect Password')) {
      formikRef.current.setFieldError('password', 'Incorrect Password')
    } else {
      dispatch(showToast({ message: err?.message ?? 'Something went wrong', isError: true }))
    }
  }

  const handelSubmitPassword = (values) => {
    const token = decodeJwt(localStorage.getItem('public_token'))
    setLoading(true)
    getPublicToken(token.sub, undefined, token.scope, values.password)
      .then((data) => {
        setLoading(false)
        localStorage.setItem('public_token', data.accessToken)
        handleRefetchSelect(handleError)
      })
      .catch((err) => console.error(err))
  }
  return (
    <Main>
      <div>
        <Typography fontWeight='bold' fontSize={32}>Enter the password</Typography>
        <Typography fontSize={16} color={Colors.labelBlack}>
          This select is password protected,<br />
          So please enter the password to access the selects.
        </Typography>
      </div>
      <FormWrapper>
        <Formik
          innerRef={formikRef}
          initialValues={{
            password: ''
          }}
          validationSchema={PublicSelectValidationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={values => handelSubmitPassword(values)}>
          {({ errors, touched, handleSubmit, handleChange, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Typography
                  color={(touched.password && errors.password) ? Colors.errorRed : Colors.black}
                  fontSize={16}
                  style={{ textAlign: 'left', paddingBottom: '10px' }}>
                  Enter the Password
                </Typography>
                <InputBar isError={touched.password && errors.password}>
                  <FieldInput
                    isValid={ touched.password && errors.password}
                    type='password'
                    name={'password'}
                    value={values.password}
                    onChange={handleChange}
                    valid={touched.password && !errors.password}
                    error={touched.password && errors.password}
                  />
                  <Button type="submit" width='fit-content' height='42px' loading={loading}>
                    <Typography color='white' fontSize={15} fontWeight='bold'>Submit</Typography>
                  </Button>
                </InputBar>
                {errors.password &&
                  touched.password && (
                    <Typography fontSize={12} color={Colors.errorRed} style={{ textAlign: 'left' }}>
                      {errors.password}
                    </Typography>
                  )}
              </Form>
            )
          }}
        </Formik>
      </FormWrapper>
    </Main>
  )
}

export default AuthScreen

AuthScreen.propTypes = {
  secPassword: PropTypes.func,
  loading: PropTypes.bool,
  redirectUrl: PropTypes.string,
  handleRefetchSelect: PropTypes.func
}
