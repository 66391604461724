import PropTypes from 'prop-types'
import { Images } from '../../../../assets'
import { MenuSpan, NavItemImg, StyledNavLink } from '../../Styles'

const Projects = props => {
  const { pathname } = props
  return (
    <StyledNavLink to='/projects'>
      <NavItemImg
        src={
          pathname === '/projects' || pathname.includes('/projects/')
            ? Images.projectsIconActive
            : Images.projectsIconInActive
        }
      />
      <MenuSpan>Projects</MenuSpan>
    </StyledNavLink>
  )
}

Projects.propTypes = {
  pathname: PropTypes.string
}

export default Projects
