import { useState, type KeyboardEvent, type MouseEvent } from 'react'

import classNames from 'classnames'
import Drawer from '@mui/material/Drawer'

import { SpinnerSmall } from '../Progress'
import Play from '../../assets/images/UGCProject/Play.svg'
import style from './UGCPublishProject.module.scss'
import PhotoIcon from '../../assets/images/UGCProject/PhotoIcon.svg'

interface ProjectSpecificationRowProps {
  title: string
  value: string | number
  imgSource: string
}

const ProjectSpecificationRow = (props: ProjectSpecificationRowProps): JSX.Element => {
  const { title, value, imgSource } = props
  return (
    <div className={style.Drawer__SpecificationRow}>
      <img src={imgSource} />
      <p className={style.Drawer__SpecificationRowText}>{title}: {value}</p>
    </div>
  )
}
interface UGCPublishProjectProps {
  advertiser: string
  projectName: string
  handlePublish: (cb: () => void) => void
  loading: boolean
  published: boolean
  primaryVideoCount: number
  introVideoCount: number
  stillPhotoCount: number
  bRoll: number
  audition: boolean
}

export function UGCPublishProject (props: UGCPublishProjectProps): JSX.Element {
  const { advertiser, projectName, handlePublish, loading, published, primaryVideoCount, introVideoCount, stillPhotoCount, bRoll, audition } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = (event: KeyboardEvent | MouseEvent): void => {
    if (event.type === 'keydown' && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) { return }
    setIsOpen(!isOpen)
  }

  return (
    <>
      <button className={classNames(style.Button, published ? style['Button--Disabled'] : '')} onClick={published ? undefined : toggleDrawer}><p className={style.Button__Text}>{published ? 'Published' : 'Publish'}</p></button>
      <Drawer anchor={'right'} open={isOpen} onClose={toggleDrawer}>
        <div className={style.Drawer}>
          <header className={style.Drawer__Header}>
            <h1 className={style.Drawer__HeaderText}>Ready to go Live ?</h1>
            <p className={style.Drawer__HeaderDescription}>After publishing, you won&apos;t be able to change the deliverables in your brief and how those are presented to creators.</p>
          </header>
          <p className={style.Drawer__AdvertiserText}>{advertiser}</p>
          <p className={style.Drawer__ProjectNameText}>{projectName}</p>
          <section className={style.Drawer__ProjectSpecifications}>
            <ProjectSpecificationRow imgSource={Play} title="Primary Video" value={primaryVideoCount} />
            <ProjectSpecificationRow imgSource={Play} title="Intro Videos" value={introVideoCount} />
            <ProjectSpecificationRow imgSource={PhotoIcon} title="Still Photos" value={stillPhotoCount} />
            <ProjectSpecificationRow imgSource={Play} title="B-Roll" value={bRoll} />
            <ProjectSpecificationRow imgSource={Play} title="Audition" value={audition ? 'Yes' : 'No'} />
          </section>
        </div>
        <footer className={style.Footer}>
          <button onClick={toggleDrawer} className={classNames('primary-text-button')}>Cancel</button>
          <button onClick={(e) => { handlePublish(() => { toggleDrawer(e) }) }} className={'primary-button'}>{loading && <SpinnerSmall />}Publish Project</button>
        </footer>
      </Drawer>
    </>
  )
}
