import { gql } from '@apollo/client'

class AdvertiserUserAPI {
  getUserRoleList () {
    return gql`
      query RoleList {
        userRoleList {
          id
          name
        }
      }
    `
  }

  getOrganizationList () {
    return gql`
      query OrganizationList($ids: [ID!]) {
        organizationList(ids: $ids) {
          id
          name
        }
      }
    `
  }

  deleteUser () {
    return gql`
      mutation UserDelete($id: ID!) {
        userDelete(id: $id) {
          message
          status
        }
      }
    `
  }

  updateUser () {
    return gql`
      mutation UserUpdate($userUpdateId: ID!, $firstname: String, $lastname: String, $email: String, $roles: [String]) {
        userUpdate(id: $userUpdateId, firstname: $firstname, lastname: $lastname, email: $email, roles: $roles) {
          firstname
          lastname
          email
          ownerId
          roles
          createdAt
          updatedAt
          id
        }
      }
    `
  }

  getSearchUserList () {
    return gql`
      query SearchUserList($offset: Int, $limit: Int, $sort: String, $search: String) {
        searchUsers(offset: $offset, limit: $limit, sort: $sort, search: $search) {
          id
          firstname
          lastname
          email
          ownerId
          roles
        }
      }
    `
  }
}

export default new AdvertiserUserAPI()
