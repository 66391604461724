import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Box,
  Chip,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Images } from '../../assets'
import { Colors, Fonts } from '../../constants'

const useStyles = makeStyles({
  root: {
    '&>.Mui-checked': {
      color: 'green'
    },
    wordBreak: 'break-word'
  },
  customLabel: {
    '& .MuiInputLabel-root': {
      fontFamily: Fonts.degular,
      transform: 'translate(0, 16px) scale(1)'
    },
    '& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-shrink': {
      transform: 'translate(0, -9px) scale(0.75)'
    }
  }
})

const ModifiedBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
  background: transparent;
`

const LabelText = styled(InputLabel)`
  font-family: ${Fonts.degular};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  text-align: left;
`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function ModifiedMultiSelect (props) {
  const { label, data, selection, setSelection } = props
  const classes = useStyles()

  const handleChange = event => {
    const {
      target: { value }
    } = event
    setSelection(typeof value === 'string' ? value.split(',') : value)
  }

  const handleDelete = (e, value) => {
    e.preventDefault()
    setSelection(selection.filter(name => name !== value))
  }

  return (
    <FormControl style={{ marginTop: 30 }} fullWidth classes={{ root: classes.customLabel }}>
      <LabelText>{label}</LabelText>
      <Select
        multiple
        value={selection}
        variant='standard'
        onChange={handleChange}
        renderValue={selected => (
          <ModifiedBox>
            {selected.map(value => (
              <Chip
                clickable
                style={{
                  background: Colors.black,
                  color: Colors.white,
                  marginRight: 10,
                  marginBottom: 5
                }}
                deleteIcon={
                  <img
                    onMouseDown={e => e.stopPropagation()}
                    src={Images.grayCross}
                    style={{ height: 20, width: 20 }}
                  />
                }
                key={value}
                label={value}
                onDelete={e => handleDelete(e, value)}
              />
            ))}
          </ModifiedBox>
        )}
        MenuProps={MenuProps}>
        {data.map(name => (
          <MenuItem classes={{ root: classes.root }} key={name?.value} value={name.value}>
            <Checkbox style={{
              color: '#000'
            }} checked={selection?.indexOf(name.value) > -1} className={classes.root} />
            <ListItemText primary={name.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

ModifiedMultiSelect.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func
}
