import { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

function useConfirmExit(confirmExit, when = true) {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(message, when = true) {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (when && hasChanges) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when, hasChanges]);

  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      return (event.returnValue = 'Are you sure you want to exit?');
    };
    if (when) {
      window.addEventListener('beforeunload', handleTabClose);

      return () => {
        window.removeEventListener('beforeunload', handleTabClose);
      };
    }
  }, [when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);

  const setChanges = useCallback((changes) => {
    setHasChanges(changes);
  }, []);

  useConfirmExit(confirmExit, when);

  return setChanges;
}
