import { Fragment, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, TableSortLabel } from '@mui/material'

import Sidebars from '../Sidebars'
import FooterLoader from '../FooterLoader'
import { Colors, Fonts } from '../../../../constants'
import { ShimmerContentBox, Typography } from '../../../../components'
import useCombineWithListAndRoleSearch from '../../Hooks/useCombineWithListAndRoleSearch'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  justify-content: flex-start;
  padding-bottom: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-inline: 40px;
`

const StyledTableHead = styled(TableHead)`
  font-family: ${Fonts.degular} !important;
  background: ${Colors.primaryGrayOpacity};
`

const StyledCellHeader = styled(TableCell)`
  border-bottom: none !important;
  font-family: ${Fonts.degular} !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: ${Colors.blackOpacity} !important;
`

const StyledTableRow = styled(TableRow)`
  height: 70px !important;
  font-family: ${Fonts.degular} !important;
`

const StyledCellBody = styled(TableCell)`
  border-bottom: 1px solid ${Colors.antiFlashWhite} !important;
  font-family: ${Fonts.degular} !important;
  font-size: 14px !important;
  color: ${Colors.grayLabelBlack} !important;
`

const BaseButton = styled.button`
  color: ${Colors.greenBlue};
  text-decoration: none;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  background: none;

  &:disabled {
    color: ${Colors.grayLabelBlack};
    cursor: unset;
  }
`

const Shimmer = styled(ShimmerContentBox)`
  height: 50px;
  margin-bottom: 20px;
`

const AdvertiserUserMain = (props) => {
  // props
  const { searchText } = props
  // ref
  const scrollViewRef = useRef(null)
  // states
  const [orderKey, setOrderKey] = useState(0)
  const [order, setOrder] = useState('createdAt')
  const shimmerCount = new Array(3).fill(0).map((_, index) => index)
  const [loadMoreAdvertiserStatus, setLoadMoreAdvertiserStatus] = useState('done')

  const [activeUserTab, setActiveUserTab] = useState({})

  // ---------------------------- sidebar visibility ---------------------------------
  const [isDeleteUserSidebarVisible, setIsDeleteUserSidebarVisible] = useState(false)
  const [isEditUserSidebarVisible, setIsEditUserSidebarVisible] = useState(false)

  const user = useSelector(state => state.UserReducer)

  // api calls
  const {
    data: searchData, loading: searchLoading, fetchMore: searchFetchMore, handleDeleteData: searchHandleDeleteData, handleMasterData: searchHandleMasterData
  } = useCombineWithListAndRoleSearch({
    order,
    searchQuery: searchText
  })

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current
    if (loadMoreAdvertiserStatus === 'done' && scrollViewRef.current) {
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        fetchMoreAdvertiser()
      }
    }
  }

  const fetchMoreAdvertiser = async () => {
    try {
      setLoadMoreAdvertiserStatus('loading')
      await searchFetchMore(
        () => setLoadMoreAdvertiserStatus('limited'),
        () => setLoadMoreAdvertiserStatus('done')
      )
    } catch (error) {
      setLoadMoreAdvertiserStatus('error')
    }
  }

  const handleRequestSort = () => {
    switch (orderKey) {
      case 0:
        setOrder('asc')
        setOrderKey(1)
        break
      case 1:
        setOrder('desc')
        setOrderKey(2)
        break
      case 2:
        setOrder('createdAt')
        setOrderKey(0)
        break
      default:
        break
    }
    setLoadMoreAdvertiserStatus('done')
  }

  // -------------------- Components --------------------------
  const TableRowComp = (tableRowCompProps) => {
    const row = tableRowCompProps.user
    return (
      <StyledTableRow>
        <StyledCellBody>
          <Typography fontSize={14} fontWeight={600} color={'black'}>
            {row.firstname} {row.lastname}
          </Typography>
        </StyledCellBody>
        <StyledCellBody>{row.organizationData.name}</StyledCellBody>
        <StyledCellBody>{row.email}</StyledCellBody>
        <StyledCellBody>{row.roleDetail?.join(', ')}</StyledCellBody>
        <StyledCellBody>
          <BaseButton
            onClick={() => {
              setActiveUserTab(row)
              setIsEditUserSidebarVisible(!isEditUserSidebarVisible)
            }}
          >Edit</BaseButton>
          <BaseButton disabled={user.info.id === row.id} onClick={() => {
            setActiveUserTab(row)
            setIsDeleteUserSidebarVisible(!isDeleteUserSidebarVisible)
          }}>Delete</BaseButton>
        </StyledCellBody>
      </StyledTableRow>
    )
  }

  const NoUserFound = () => {
    return (
      <StyledTableRow>
        <StyledCellBody colSpan={5} align="center">
          <Typography
            fontSize={45}
            fontWeight="bold"
            color={Colors.black}
            style={{ textAlign: 'center' }}>
            Add your first user.
          </Typography>
        </StyledCellBody>
      </StyledTableRow>
    )
  }

  return (
    <Fragment>
      <Wrapper ref={scrollViewRef} onScroll={handleScroll}>
        <Table stickyHeader={true}>
          <StyledTableHead>
            <TableRow>
              <StyledCellHeader style={{ width: '20%' }}>
                <Tooltip
                  title={order === 'asc' ? 'Sort by descending' : order === 'createdAt' ? 'Sort by ascending' : 'Sory by createdAt'}
                  placement={'bottom-end'}>
                  <TableSortLabel
                    active={true}
                    direction={order === 'createdAt' ? 'desc' : order === 'asc' ? 'asc' : 'desc'}
                    onClick={handleRequestSort}>
                    Full Name
                  </TableSortLabel>
                </Tooltip>
              </StyledCellHeader>
              <StyledCellHeader style={{ width: '20%' }}>
                Organization
              </StyledCellHeader>
              <StyledCellHeader style={{ width: '30%' }}>Email</StyledCellHeader>
              <StyledCellHeader style={{ width: '20%' }}>Role</StyledCellHeader>
              <StyledCellHeader style={{ width: '13%' }}>Action</StyledCellHeader>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {!searchLoading
              ? searchData?.length > 0 ? searchData.map((user, index) => <TableRowComp key={index} user={user} />) : <NoUserFound />
              : <StyledTableRow>
                <StyledCellBody colSpan={5}>
                  {shimmerCount.map(id => <Shimmer key={id} />)}
                </StyledCellBody>
              </StyledTableRow>
            }
          </TableBody>
        </Table>
        {loadMoreAdvertiserStatus === 'loading' && <FooterLoader />}
      </Wrapper>
      <Sidebars
        activeUserTab={activeUserTab}
        // props for handling deletion of users
        handleDeleteData={searchHandleDeleteData}
        isDeleteUserSidebarVisible={isDeleteUserSidebarVisible}
        toggleDeleteUserSidebarVisible={() => setIsDeleteUserSidebarVisible(!isDeleteUserSidebarVisible)}
        // props for handleing edit of users
        handleMasterData={searchHandleMasterData}
        masterData={searchData}
        isEditUserSidebarVisible={isEditUserSidebarVisible}
        toggleEditUserSidebarVisible={() => setIsEditUserSidebarVisible(!isEditUserSidebarVisible)}
      />
    </Fragment>
  )
}

AdvertiserUserMain.propTypes = {
  searchText: PropTypes.string
}

export default AdvertiserUserMain
