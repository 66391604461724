import PropTypes from 'prop-types'

import {
  Chip,
  TextField,
  FormControl,
  Autocomplete
} from '@mui/material'
import styled from 'styled-components'

import { Images } from '../../assets'
import { Colors } from '../../constants'

const StyledChip = styled(Chip)`
  background: ${Colors.black} !important;
  color: ${Colors.white} !important;
  margin: 5px 10px 5px 0 !important;
`

export function ModifiedAutoComplete (props) {
  const { label, data, selection, setSelection } = props

  return (
    <FormControl style={{ marginTop: 30 }} fullWidth>
      <Autocomplete
        multiple
        options={data}
        value={selection}
        getOptionLabel={(option) => option.tag}
        defaultValue={data}
        onChange={(event, value) => setSelection(value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <StyledChip
              key={`${label}-${index}`}
              clickable
              deleteIcon={
                <img
                  onMouseDown={e => e.stopPropagation()}
                  src={Images.grayCross}
                  style={{ height: 20, width: 20 }}
                />
              }
              label={option.tag}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
          />
        )}
      />
    </FormControl>
  )
}

ModifiedAutoComplete.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func
}
