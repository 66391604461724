import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import ModifiedMultiSelect from '../../../../components/multiSelect'
import {
  Button,
  HeaderV2,
  Typography,
  TlyntSidebar,
  CustomizedSlider,
  ModifiedAutoComplete
} from '../../../../components'

import useTagListQuery from '../../../BulkUpload/Hooks/useTagListQuery'
import useGetSelectionData from '../../../TalentDetailMain/Hooks/useGetSelectionData'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-y: scroll;
`
const LeftContainer = styled.div`
  flex: 0.5;
  box-sizing: border-box;
  padding-top: 30px;
`

const RightContainer = styled.div`
  flex: 0.5;
`

const Wrapper = styled.div`
  padding-inline: 40px;
`

const HeaderWrapper = styled.div`
  width: 50%;
`

const BottomContainer = styled.div`
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 40px;
  box-sizing: border-box;
  justify-content: space-between;
`
const BottomRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const marks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 20,
    label: ''
  },
  {
    value: 40,
    label: ''
  },
  {
    value: 60,
    label: ''
  },
  {
    value: 80,
    label: ''
  },
  {
    value: 100,
    label: '100+'
  }
]
const heightMarks = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: ''
  },
  {
    value: 4,
    label: ''
  },
  {
    value: 6,
    label: ''
  },
  {
    value: 8,
    label: '8+'
  }
]

const FilterSidebar = props => {
  const { toggleSidebar } = props
  const searchQuery = new URLSearchParams(useLocation().search)
  const raceQuery = searchQuery.get('race')

  const heightQuery = searchQuery.get('height')
  const genderQuery = searchQuery.get('gender')
  const hairColorQuery = searchQuery.get('hairColor')

  const eyeColorQuery = searchQuery.get('eyeColor')
  const bodyTypeQuery = searchQuery.get('bodyType')
  const birthdateQuery = searchQuery.get('birthdate')
  const ethinicityQuery = searchQuery.get('ethinicity')
  const tagQuery = searchQuery.get('talentTags')

  const genderExpressionQuery = searchQuery.get('genderExpression')
  const sexualOrientationQuery = searchQuery.get('sexualOrientation')
  const countryOfHeritageQuery = searchQuery.get('countryOfHeritage')

  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const setSearchParams = useSearchParams()[1]
  // api
  const { data, loading } = useGetSelectionData()
  const { data: tagData } = useTagListQuery()
  // api destructured data
  const raceData = data?.getTalentDemographicSelectionData?.race
  const genderData = data?.getTalentDemographicSelectionData?.gender

  const eyeColorData = data?.getTalentDemographicSelectionData?.eyeColor
  const bodyTypeData = data?.getTalentDemographicSelectionData?.bodyType
  const ethnicityData = data?.getTalentDemographicSelectionData?.ethnicity
  const hairColorData = data?.getTalentDemographicSelectionData?.hairColor

  const genderExpressionData = data?.getTalentDemographicSelectionData?.genderExpression
  const sexualOrientationData = data?.getTalentDemographicSelectionData?.sexualOrientation
  const countryOfHeritageData = data?.getTalentDemographicSelectionData?.country

  // states
  const [race, setRace] = useState([])
  const [gender, setGender] = useState([])
  const [eyeColor, setEyeColor] = useState([])
  const [hairColor, setHairColor] = useState([])
  const [bodyType, setBodyType] = useState([])

  const [height, setHeight] = useState(['0', '8'])
  const [birthdate, setBirthdate] = useState([0, 100])

  const [tags, setTags] = useState([])
  const [ethinicity, setEthinicity] = useState([])
  const [genderExpression, setGenderExpression] = useState([])
  const [sexualOrientation, setSexualOrientation] = useState([])
  const [countryOfHeritage, setCountryOfHeritage] = useState([])

  const [isReset, setIsReset] = useState(false)

  const [clearStates, setClearStates] = useState({
    ageClear: !!birthdateQuery,
    heightClear: !!heightQuery
  })

  const getValuedData = (queriedData, originalData) => {
    return originalData?.filter((item) => queriedData?.split(',')?.includes(`${item.id}`))?.map((item) => item.value)
  }
  const getTagData = (queriedData, originalData) => {
    return originalData?.filter((item) => queriedData?.split(',')?.includes(`${item.id}`))?.map((item) => item)
  }
  const getAge = (dateString) => {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const fillSelection = () => {
    birthdateQuery && setBirthdate([getAge(birthdateQuery?.split(',')[1]?.split('-')[0]), getAge(birthdateQuery?.split(',')[0]?.split('-')[0])])
    heightQuery && setHeight(heightQuery?.split(',').map((item) => `${parseInt(item / 12)}.${parseInt(item) % 12}`))
    setEthinicity(getValuedData(ethinicityQuery, ethnicityData))

    setRace(getValuedData(raceQuery, raceData) ?? [])
    setGender(getValuedData(genderQuery, genderData) ?? [])
    setEyeColor(getValuedData(eyeColorQuery, eyeColorData) ?? [])
    setHairColor(getValuedData(hairColorQuery, hairColorData) ?? [])
    setBodyType(getValuedData(bodyTypeQuery, bodyTypeData) ?? [])
    setTags(getTagData(tagQuery, tagData?.getTalentTag) ?? [])
    setSexualOrientation(getValuedData(sexualOrientationQuery, sexualOrientationData))
    setCountryOfHeritage(getValuedData(countryOfHeritageQuery, countryOfHeritageData))
    setGenderExpression(getValuedData(genderExpressionQuery, genderExpressionData) ?? [])
  }

  useEffect(() => {
    if (!loading) fillSelection()
  }, [loading])

  const handleApplyFilters = () => {
    const d = new Date()

    const startBDate = `${d.getFullYear() - birthdate[0]}-01-01`
    const endBDate = `${d.getFullYear() - (birthdate[1] === 100 ? 200 : birthdate[1])}-01-01`

    const hairColorIds = hairColorData?.filter(hc => hairColor.includes(hc.value)).map((item) => item.id)
    const eyeColorIds = eyeColorData?.filter(hc => eyeColor.includes(hc.value)).map((item) => item.id)
    const bodyTypeIds = bodyTypeData?.filter(hc => bodyType.includes(hc.value)).map((item) => item.id)
    const genderIds = genderData?.filter(hc => gender.includes(hc.value)).map((item) => item.id)

    const genderExpressionIds = genderExpressionData.filter(hc => genderExpression.includes(hc.value)).map((item) => item.id)
    const raceIds = raceData?.filter(hc => race.includes(hc.value)).map((item) => item.id)

    const sexualOrientationIds = sexualOrientationData?.filter(hc => sexualOrientation.includes(hc.value)).map((item) => item.id)
    const countryOfHeritageIds = countryOfHeritageData?.filter(hc => countryOfHeritage.includes(hc.value)).map((item) => item.id)
    const ethinicityIds = ethnicityData?.filter(hc => ethinicity.includes(hc.value)).map((item) => item.id)
    const tagIds = tags.map(e => e.id)

    toggleSidebar()

    let newHeight = []

    newHeight = height.map((item) => {
      const realValue = parseInt(item.split('.')[0]) * 12
      const decimalValue = parseInt(item.split('.')[1])
      return (realValue + decimalValue)
    })

    navigate(
      `/talent/search?q=&bodyType=${bodyTypeIds}&height=${!clearStates.heightClear ? '' : newHeight}&hairColor=${hairColorIds}&eyeColor=${eyeColorIds}&gender=${genderIds}&genderExpression=${genderExpressionIds}&race=${raceIds}&sexualOrientation=${sexualOrientationIds}&countryOfHeritage=${countryOfHeritageIds}&ethinicity=${ethinicityIds}&talentTags=${tagIds}&birthdate=${!clearStates.ageClear
        ? ''
        : [
          endBDate,
          startBDate
        ]}`
    )
  }

  const handleResetSelection = () => {
    if (location.pathname.includes('/talent/search')) {
      setSearchParams('q=')
    }
    setBirthdate([0, 100])
    setHeight([1, 8])
    setHairColor([])
    setEyeColor([])
    setGender([])
    setBodyType([])
    setGenderExpression([])
    setEthinicity([])
    setSexualOrientation([])
    setRace([])
    setCountryOfHeritage([])
    setTags([])
    setClearStates({
      ageClear: false,
      weightClear: false,
      heightClear: false
    })

    setIsReset(!isReset)
  }

  return (
    <TlyntSidebar width="764px" >
      <HeaderWrapper>
        <HeaderV2
          title={<Typography fontSize={34} fontWeight={700}>Filters</Typography>}
          description={
            <Typography fontSize={14} letterSpacing='0.15px' style={{ opacity: 0.6, margin: '1em 0' }}>
              Choose your filters and list will be fiter out based on that
              selection.
            </Typography>
          }
        />
      </HeaderWrapper>
      <Container>
        <LeftContainer>
          <Wrapper>
            <CustomizedSlider
              label="Age (Years)"
              marks={marks}
              max={100}
              defaultSelection={ birthdateQuery && clearStates.ageClear ? [getAge(birthdateQuery?.split(',')[1]?.split('-')[0]), getAge(birthdateQuery?.split(',')[0]?.split('-')[0])] : [0, 100]}
              setSelection={setBirthdate}
              isReset={isReset}
              handleClearClick= {() => setClearStates({ ...clearStates, ageClear: !clearStates.ageClear })}
              showClear={ clearStates.ageClear }
              handleShowClear={ () => setClearStates({ ...clearStates, ageClear: true }) }
            />
            <CustomizedSlider
              label="Height (Feet)"
              marks={heightMarks}
              max={8}
              defaultSelection={ heightQuery && clearStates.heightClear ? heightQuery?.split(',')?.map((item) => `${parseInt(item / 12)}.${parseInt(item) % 12}`) : [0, 8]}
              setSelection={setHeight}
              isReset={isReset}
              handleClearClick= {() => setClearStates({ ...clearStates, heightClear: !clearStates.heightClear })}
              showClear={ clearStates.heightClear }
              handleShowClear={ () => setClearStates({ ...clearStates, heightClear: true }) }

            />
            <ModifiedMultiSelect
              label="Hair Color"
              data={hairColorData ?? []}
              selection={ hairColor}
              setSelection={setHairColor}
            />
            <ModifiedMultiSelect
              label="Eye Color"
              data={eyeColorData ?? []}
              selection={eyeColor}
              setSelection={setEyeColor}
            />
            <ModifiedMultiSelect
              label="Body Type"
              data={bodyTypeData ?? []}
              selection={bodyType}
              setSelection={setBodyType}
            />
            <ModifiedAutoComplete
              label="Tags"
              data={tagData?.getTalentTag ?? []}
              selection={tags}
              setSelection={setTags}
            />
          </Wrapper>
        </LeftContainer>
        <RightContainer>
          <Wrapper>
            <ModifiedMultiSelect
              label="Gender"
              data={genderData ?? []}
              selection={gender}
              setSelection={setGender}
            />
            <ModifiedMultiSelect
              label="Gender Expression"
              data={genderExpressionData ?? []}
              selection={genderExpression}
              setSelection={setGenderExpression}
            />
            <ModifiedMultiSelect
              label="Sexual Orientation"
              data={sexualOrientationData ?? []}
              selection={sexualOrientation}
              setSelection={setSexualOrientation}
            />
            <ModifiedMultiSelect
              label="Race"
              data={raceData ?? []}
              selection={race}
              setSelection={setRace}
            />
            <ModifiedMultiSelect
              label="Country of heritage"
              data={countryOfHeritageData ?? []}
              selection={countryOfHeritage}
              setSelection={setCountryOfHeritage}
            />
            <ModifiedMultiSelect
              label="Ethnicity"
              data={ethnicityData ?? []}
              selection={ethinicity}
              setSelection={setEthinicity}
            />
          </Wrapper>
        </RightContainer>
      </Container>
      <BottomContainer>
        <Button onClick={handleResetSelection} outline style={{ paddingInline: '30px' }}>
          <Typography fontSize={15} fontWeight={700} letterSpacing='0.45px'>RESET ALL</Typography>
        </Button>
        <BottomRightWrapper>
          <Button onClick={toggleSidebar} outline backgroundColor='white'>
            <Typography fontSize={15} fontWeight={700} letterSpacing='0.45px'>CANCEL</Typography>
          </Button>
          <Button onClick={handleApplyFilters}>
            <Typography fontSize={15} fontWeight={700} letterSpacing='0.45px' color='white'>APPLY</Typography>
          </Button>
        </BottomRightWrapper>
      </BottomContainer>
    </TlyntSidebar>
  )
}

FilterSidebar.propTypes = {
  toggleSidebar: PropTypes.func
}

export default FilterSidebar
