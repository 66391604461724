import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import styledComponents from 'styled-components'

import Style from '../../Styles'
import Addtags from '../AddTags'
import { Colors } from '../../../../constants'
import { Button, TlyntSidebar, Typography } from '../../../../components'
import { useSelector } from 'react-redux'

const FooterWrapper = styledComponents.div`
  margin-top: auto;
  border-top: 1px solid ${Colors.symbolGray};
  box-shadow: 10px 5px 54px ${Colors.symbolGray};
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: white;
  width: calc(100vw - ${props => props.sidebarWidth} - 80px);
  transition: all .5s ease;
`
const Footer = ({
  totalCount,
  finishUpload,
  setTags,
  successCount,
  failCount,
  setFailCount,
  setUploadCounter,
  isUploading,
  setIsUploading
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const { width: sidebarWidth } = useSelector(state => state.SidebarReducer)

  const uploadingData = () => {
    setUploadCounter(prevState => prevState + 1)
    setIsUploading('start')
  }
  const retryUploadingData = () => {
    setIsUploading('start')
    setFailCount(0)
    setUploadCounter(prevState => prevState + 1)
  }
  const finishUploadLocal = () => {
    finishUpload()
  }

  const footerInfo = (successCount, totalCount) => {
    let message = 'uploading'
    switch (isUploading) {
      case 'pending':
        message = successCount + '/' + totalCount + ' Images are in the bucket, Add tags and click on the upload button to start uploading.'
        break
      case 'start':
        message = successCount + '/' + totalCount + ' Images are uploading'
        break
      case 'fail':
        message = ' Uploading error'
        break
      case 'done':
        message = ' All set, Your images are uploaded successfully.'
        break
      default:
        break
    }
    return message
  }

  useEffect(() => {
    if (totalCount > 0) {
      if (totalCount === (failCount + successCount)) {
        setIsUploading(totalCount === failCount ? 'fail' : 'done')
      }
    }
  }, [failCount, successCount])

  return (
    <>
      <FooterWrapper sidebarWidth={sidebarWidth}>
        <Style.FlexWrapper>
          <Style.ProgressBar>
            <CircularProgressbar
              value={successCount}
              maxValue={totalCount}
              strokeWidth={20}
              styles={buildStyles({ strokeLinecap: 'butt', pathColor: 'black' })} />
          </Style.ProgressBar>
          <Typography>{footerInfo(successCount, totalCount)}</Typography>
          {failCount > 0 && <>
            <Typography
              color={Colors.errorRed}
              style={{ marginLeft: 10 }}>
              {failCount} Image{failCount > 1 && 's'} failed
            </Typography>
            <div onClick={retryUploadingData}>
              <Typography
                color={Colors.errorRed}
                style={{ marginLeft: 10, textDecoration: 'underline', cursor: 'pointer' }}>
                Retry
              </Typography>
            </div>
          </>}
        </Style.FlexWrapper>
        <Style.FlexWrapper>
          {isUploading === 'pending' && <Button onClick={() => setIsVisible(!isVisible)} outline>
              <Typography fontWeight='bold'>ADD TAGS</Typography>
            </Button>}
          {isUploading === 'pending'
            ? <Button style={{ marginLeft: '20px' }} onClick={uploadingData}>
                <Typography color='white' fontWeight='bold'>START UPLOADING</Typography>
              </Button>
            : <Button
                onClick={finishUploadLocal}
                disabled={!(isUploading === 'done' || isUploading === 'fail')}
                backgroundColor={(isUploading === 'done' || isUploading === 'fail') ? Colors.black : Colors.grayLabelBlack}>
                <Typography color='white' fontWeight='bold'>FINISH</Typography>
              </Button>}
        </Style.FlexWrapper>
      </FooterWrapper>
      {isVisible &&
        <TlyntSidebar>
          <Addtags setIsVisible={setIsVisible} setTags={(tags) => setTags(tags)} />
        </TlyntSidebar>}
    </>
  )
}

Footer.propTypes = {
  totalCount: PropTypes.number,
  finishUpload: PropTypes.func,
  setTags: PropTypes.func,
  successCount: PropTypes.number,
  failCount: PropTypes.number,
  setFailCount: PropTypes.func,
  setUploadCounter: PropTypes.func,
  setIsUploading: PropTypes.func,
  isUploading: PropTypes.string
}

export default Footer
