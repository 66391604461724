// TODO: REMOVE STYLED COMPONENTS
import { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import styled, { keyframes } from 'styled-components'
import { Typography } from '@mui/material'

import { GET_MEDIA_LIST } from '../../services/API'
import { listTalentSubmissions } from '../../apis/project'
import { RenderIf } from '../../utils/Helpers'
import { UGCTalentCard } from '../TalentCardV2'
import images from '../../assets/images'
import TalentCard from '../../pages/PublicSelect/Components/TalentCard'
import useCreateVoteMutation from '../../pages/PublicSelect/Hooks/useCreateVoteMutation'
import useRemoveVoteMutation from '../../pages/PublicSelect/Hooks/useRemoveVoteMutation'
import useVoteByUserQuery from '../../pages/PublicSelect/Hooks/useVoteByUserQuery'
import style from './PublicSelectTalents.module.scss'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { showToast } from '../../redux/actions/DashBoardActions'

const TALENT_SUBMISSION_LIMIT = 40

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  @media (min-width: 700px) {
    justify-content: center;
  }
  @media (min-width: 900px) {
    justify-content: flex-start;
  }
`
const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
const ShimmerBox = styled.div`
  flex: 0 1 70%;
  height: 400px;

  @media (min-width: 700px) {
    flex: 0 1 calc(45% - 1em);
  }
  @media (min-width: 900px) {
    flex: 0 1 calc(25% - 0.5em);
  }
  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const PlaceholderWrapperMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

interface TalentSubmission {
  media: {
    uris: {
      poster: string
      stream: string
    }
  }
  talentId: string
}

interface MediaListItem {
  id: string
  talentId: string
  file: string
  disabled: boolean
  uris: Uris
  __typename: string
}

interface Uris {
  card: string
  __typename: string
}

interface TalentData {
  id: string
  firstname: string
  lastname: string
  mediaProfile: MediaProfile
  videoProfile?: VideoProfile
  isLiked?: boolean
}

interface VideoProfile {
  uris: Uris2
}

interface Uris2 {
  poster: string
  stream: string
}

interface MediaProfile {
  id: string
  type: string
  talentId: string
  disabled: boolean
  file: string
  uris: Uris
}

interface Uris {
  profilePic: string
  card: string
}

interface Props {
  selectId: string
  data: TalentData[]
  loading: boolean
  childSelectId: string
  mediaType?: string
  projectDetail: {
    brandId: string
    deliverables: Array<{
      id: string
      type: string
      specs: { orientation: string }
    }>
    id: string
    name: string
    type: string
  }
}

export const PublicSelectTalents = (props: Props): JSX.Element => {
  // init
  const { selectId, data, loading, childSelectId, mediaType = 'photo', projectDetail } = props

  const isAuditionSet = useRef(false)
  const disptach = useDispatch()

  // states
  const [isLoaded, setIsLoaded] = useState(false)
  const [talentData, setTalentData] = useState<TalentData[]>([])
  const [isLandscape, setIsLandscape] = useState(false)
  const [talentSubmission, setTalentSubmission] = useState<TalentSubmission[]>([])

  // constants
  const shimmerCount = new Array(8).fill(0).map((_, index) => ({ id: index }))

  // api calls
  const { createVote, loading: createVoteLoading } = useCreateVoteMutation(childSelectId)
  const { removeVote, loading: removeVoteLoading } = useRemoveVoteMutation(childSelectId)

  const { data: mediaData, error: mediaDataError, loading: mediaDataLoading } = useQuery(GET_MEDIA_LIST, {
    variables: { talentIds: talentData?.map(item => item?.id) },
    skip: !(talentData?.length > 0),
    fetchPolicy: 'no-cache'
  })

  const { data: userVotesData, loading: userVotesLoading } = useVoteByUserQuery(childSelectId, childSelectId === undefined || childSelectId === null)
  const [getTalentSubmission] = useLazyQuery(listTalentSubmissions, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (data?.length > 0) {
      const updatedData = data?.map((item) => ({ ...item, media: item?.mediaProfile?.uris?.card !== undefined ? [item.mediaProfile] : [] }))
      setTalentData(updatedData)
    } else {
      setTalentData([])
    }
  }, [data])

  useEffect(() => {
    if (!mediaDataLoading && (mediaDataError == null) && data?.length > 0 && !userVotesLoading && isAuditionSet.current) {
      mapTalentDataWithMedias()
    }
  }, [mediaDataLoading, mediaDataError, data, userVotesLoading, isAuditionSet.current])

  useEffect(() => {
    if (mediaData?.mediaList?.length > 0 && !isLoaded) {
      setIsLoaded(true)
      cacheImages(mediaData?.mediaList.map((e: MediaListItem) => e?.uris?.card))
        .then(() => { setIsLoaded(true) })
        .catch(e => { console.error(e) })
    }
  }, [mediaData])

  // get talent submission data if project type is ugc
  useEffect(() => {
    void fetchUGCTaentSubmission()
  }, [projectDetail])

  // set video profile in talent data if audition data is available
  useEffect(() => {
    if (talentSubmission.length > 0 && data?.length > 0) {
      const updatedData = data?.map((item) => {
        const submission = talentSubmission.find((submission) => submission.talentId === item.id)
        return {
          ...item,
          videoProfile: submission?.media
        }
      })
      setTalentData(updatedData)
      isAuditionSet.current = true
    } else if (talentSubmission.length === 0 && data?.length > 0) {
      isAuditionSet.current = true
    }
  }, [talentSubmission, data])

  const fetchUGCTaentSubmission = async (): Promise<void> => {
    if (projectDetail?.type === 'ugc') {
      const auditionData = projectDetail?.deliverables.find((item) => item.type === 'audition')
      const hasAudition = auditionData !== undefined
      const auditionId = auditionData?.id
      const isLandscapeStatus = hasAudition ? projectDetail.deliverables.find((item) => item.type === 'main')?.specs?.orientation === 'landscape' : false

      setIsLandscape(isLandscapeStatus)
      if (auditionId !== undefined) {
        await getTalentSubmissionData(talentSubmission.length, auditionId)
      }
    }
  }

  /**
   * @description cache images
   * @param {Array} srcArray
   * @returns {Promise} void
   */
  const cacheImages = async (srcArray: string[]): Promise<void> => {
    const promises = srcArray.map(async (src: any) => {
      return await new Promise(function (resolve, reject) {
        const img = new Image()

        img.src = src
        img.onload = resolve
      })
    })
    await Promise.all(promises)
  }

  /**
   * @description map talent data with media data
   * @returns {Array} talentWithMedia
   */
  const mapTalentDataWithMedias = (): void => {
    const loopData = isAuditionSet.current ? talentData : data
    const talentWithMedia = loopData?.map((item, index) => {
      const medias = mediaData?.mediaList?.filter((media: MediaListItem) => item?.id === media.talentId)
      const isLiked = userVotesData?.selectionVoteByUser.find((select: { talentId: string }) => select.talentId === item?.id) !== undefined
      return {
        ...item,
        media: item?.mediaProfile?.uris?.card !== undefined ? [item.mediaProfile].concat(medias) : medias,
        isLiked: !!isLiked
      }
    })

    setTalentData([...talentWithMedia])
  }

  interface ListTalentSubmission {
    listTalentSubmission: TalentSubmission[]
  }

  /**
   * @description get talent submission data
   * @param {Number} offset
   * @param {String} auditionId
   * @returns {Array} talentSubmission
   */
  const getTalentSubmissionData = async (offset: number, auditionId: string): Promise<void> => {
    try {
      await getTalentSubmission({
        variables: { deliverableId: auditionId, offset, limit: TALENT_SUBMISSION_LIMIT },
        onCompleted: (data) => { void handleTalentSubmission(data, auditionId) }
      })
    } catch (error) {
      disptach(showToast({ message: error ?? 'Error while fetching talent submission data', isError: true }))
    }
  }

  const handleTalentSubmission = async (data: ListTalentSubmission, auditionId: string): Promise<void> => {
    if (data?.listTalentSubmission !== undefined && data?.listTalentSubmission.length > 0) {
      try {
        setTalentSubmission([...data?.listTalentSubmission])

        if (data?.listTalentSubmission.length > TALENT_SUBMISSION_LIMIT) {
          await getTalentSubmissionData(talentSubmission.length, auditionId)
        }
      } catch (error) {
        throw Error('Error while fetching talent submission data')
      }
    }
  }

  /**
   * @description get UGC talent thumbnail
   * @param videoPoster
   * @param profilePic
   * @returns {String} thumbnail
   */
  const getUGCTalentThumbnail = (videoPoster?: string, profilePic?: string): string => {
    if (videoPoster !== undefined) {
      return videoPoster
    } else if (profilePic !== undefined) {
      return profilePic
    } else {
      return images.tlyntPlaceholder
    }
  }

  return (
    <>
      {/* CASTING TALENTS */}
      <RenderIf isTrue={mediaType === 'photo'}>
        <Wrapper>
          {(loading || userVotesLoading) && shimmerCount.map(item => <ShimmerBox key={item.id} />)}
          {talentData?.length === 0 && !loading && (
            <PlaceholderWrapperMain>
              <Typography fontSize={34} fontWeight='bold' letterSpacing='0.25px'>No talent found.</Typography>
            </PlaceholderWrapperMain>
          )}
          <div className={style.CastingTalentsContainer}>
            {talentData?.length > 0 && talentData?.map(item => <TalentCard key={item?.id} item={item} selectId={selectId} childSelectId={childSelectId} />)}
          </div>
        </Wrapper>
      </RenderIf>

      {/* UGC TALENTS */}
      <RenderIf isTrue={mediaType === 'video'}>
        <div className={classNames(style.UGCTalentsContainer, isLandscape ? style['UGCTalentsContainer--Landscape'] : '')}>
          {(loading || userVotesLoading) && shimmerCount.map(item => <ShimmerBox key={item.id} />)}
          {!loading && talentData.length > 0 && talentData?.map((item) => (
            <UGCTalentCard
              key={item.id}
              id={item.id}
              firstname={item.firstname}
              lastname={item.lastname}
              showTag={false}
              isPublicSelect={true} isLandscape={isLandscape} handleAddVote={createVote}
              handleRemoveVote={removeVote} voteLoading={createVoteLoading || removeVoteLoading}
              profilePhoto={item?.mediaProfile?.uris?.profilePic ?? null}
              videoThumbnail={getUGCTalentThumbnail(item?.videoProfile?.uris?.poster, item?.mediaProfile?.uris?.profilePic)} selectId={selectId}
              childSelectId={childSelectId}
              isLiked={item.isLiked}
              hasVideos={item?.videoProfile?.uris?.stream !== undefined}
            />)
          )}
        </div>
        <RenderIf isTrue={talentData?.length === 0 && !loading}>
          <PlaceholderWrapperMain><Typography fontSize={34} fontWeight='bold' letterSpacing='0.25px'>No talent found.</Typography></PlaceholderWrapperMain>
        </RenderIf>
      </RenderIf>
    </>
  )
}
