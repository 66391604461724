import { gql } from '@apollo/client'

class TalentAPI {
  getTalentDetail () {
    return gql`
      query GetTalent($id: ID!) {
        talentGet(id: $id) {
          id
          firstname
          lastname
          email
          phone
          location
          countryCode
          disabled
          flagged
          notes
          countryCodeLocation
          postalCode
          updatedAt
          createdAt
          onboardFlag {
            isSignedin
            hasCompletedAccountInfo
            hasCompletedPersonalInfo
            hasCompletedPortfolio
          }
          socialLinks {
            instagram
            facebook
            tiktok
            twitter
            website
          }
          mediaProfile {
            id
            type
            talentId
            disabled
            file
            uris {
              ... on PhotoURLS {
                card
                thumbnail
                profilePic
              }
            }
            createdAt
          }
          videoProfile {
            id
            uris {
              ... on VideoURLS {
                poster
                stream
              }
            }
          }
        }
      }
    `
  }

  getSearchTalentsData () {
    return gql`
      query SearchTalents(
        $offset: Int
        $limit: Int
        $search: String
        $sort: String
        $filters: String
        $disabled: Boolean
        $flagged: Boolean
      ) {
        searchTalents(
          offset: $offset
          limit: $limit
          search: $search
          sort: $sort
          filters: $filters
          disabled: $disabled
          flagged: $flagged
        ) {
          talents {
            id
            firstname
            lastname
            email
            location
            mediaProfile {
              id
              talentId
              file
              uris {
                ... on PhotoURLS {
                  card
                }
              }
            }
          }
          metadata {
            total
          }
        }
      }
    `
  }

  getMediaListData () {
    return gql`
      query GetMediaList(
        $talentIds: [ID!]
        $talentId: ID
        $disabled: Boolean
        $offset: Int
        $limit: Int
        $type: Type
      ) {
        mediaList(
          talentIds: $talentIds
          talentId: $talentId
          disabled: $disabled
          offset: $offset
          limit: $limit
          type: $type
        ) {
          id
          talentId
          file
          disabled
          position
          processing
          uris {
            ... on PhotoURLS {
             card
            }
            ... on VideoURLS {
             stream
             poster
            }
          }
        }
      }
    `
  }

  getTalentMediaListData () {
    return gql`
      query MediaList(
        $talentId: ID!
        $type: Type
        $offset: Int
        $limit: Int
        $disabled: Boolean
      ) {
        mediaList(
          talentId: $talentId
          type: $type
          offset: $offset
          limit: $limit
          disabled: $disabled
        ) {
          id
          type
          talentId
          file
          disabled
          uris {
            ... on PhotoURLS {
            card
            thumbnail
            full
            grid
            original
          }
        }
          createdAt
        }
      }
    `
  }

  getTalentList () {
    return gql`
      query ListTalents(
        $offset: Int
        $limit: Int
        $ids: [ID!]
        $sort: String
        $disabled: Boolean
        $flagged: Boolean
        $hasPhoto: Boolean
        $hasVideo: Boolean
      ) {
        listTalent(
          offset: $offset
          limit: $limit
          ids: $ids
          sort: $sort
          disabled: $disabled
          flagged: $flagged
          hasPhoto: $hasPhoto
          hasVideo: $hasVideo
        ) {
          talents {
            id
            firstname
            lastname
            mediaProfile {
              id
              type
              talentId
              disabled
              file
              uris {
                ... on PhotoURLS {
                  profilePic
                  card
                }
              }
            }
            videoProfile {
              uris {
                ... on VideoURLS {
                  poster
                  stream
                }
              }
            }
          }
          metadata {
            total
          }
        }
      }
    `
  }

  createTalent () {
    return gql`
      mutation AddTalent(
        $firstname: String
        $lastname: String
        $email: String!
        $phone: String
        $location: String
        $countryCode: String
        $socialLinks: SocialLinksInput
        $countryCodeLocation: String
        $postalCode: String
      ) {
        talentCreate(
          firstname: $firstname
          lastname: $lastname
          email: $email
          phone: $phone
          location: $location
          countryCode: $countryCode
          socialLinks: $socialLinks
          countryCodeLocation: $countryCodeLocation
          postalCode: $postalCode
        ) {
          id
          firstname
          lastname
          email
          phone
          location
          countryCode
          socialLinks {
            instagram
            facebook
            tiktok
            twitter
            website
          }
        }
      }
    `
  }

  updateTalent () {
    return gql`
      mutation UpdateTalent(
        $talentUpdateId: String!
        $firstname: String
        $lastname: String
        $email: String
        $phone: String
        $location: String
        $disabled: Boolean
        $flagged: Boolean
        $notes: String
        $mediaProfile: ID
        $socialLinks: SocialLinksInput
      ) {
        talentUpdate(
          id: $talentUpdateId
          firstname: $firstname
          lastname: $lastname
          email: $email
          phone: $phone
          location: $location
          disabled: $disabled
          flagged: $flagged
          notes: $notes
          mediaProfile: $mediaProfile
          socialLinks: $socialLinks
        ) {
          id
          firstname
          lastname
          email
          phone
          location
          disabled
          flagged
          notes
          mediaProfile {
            type
            talentId
            disabled
            file
            id
            createdAt
          }
        }
      }
    `
  }

  updateProfileVideo = gql`
    mutation UpdateProfileVideo($talentId: String!, $videoId: ID!) {
      talentUpdate(id: $talentId, videoProfile: $videoId) {
        id
      }
    }
  `

  createProfilePicture () {
    return gql`
      mutation createProfilePicture(
        $talentId: ID!
        $mediaId: ID!
        $cropProps: CropPropsInput
      ) {
        createProfilePicture(
          talentId: $talentId
          mediaId: $mediaId
          cropProps: $cropProps
        ) {
          id
          type
          disabled
          talentId
        }
      }
    `
  }

  getMediaById () {
    return gql`
      query GetMediaById ($id: ID!) {
        mediaGet (id: $id) { 
          id
          talentId
          processing
          uris {
              ... on PhotoURLS {
              card
            }
              ... on VideoURLS {
              stream
              poster
            }
          }
        }
      }
    `
  }

  getMediaByIdFull () {
    return gql`
      query GetMediaById ($id: ID!) {
        mediaGet (id: $id) { 
          id
          file
          disabled
          processing
          uris {
              ... on PhotoURLS {
              card
            }
              ... on VideoURLS {
              stream
              poster
            }
          }
        }
      }
    `
  }
}

export default new TalentAPI()
