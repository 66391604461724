import { Route, Routes, Navigate } from 'react-router-dom'
import { useFeature } from '@growthbook/growthbook-react'

import Tlynt from '../pages/Tlynt'
import Login from '../pages/Login'
import Page404 from '../pages/Page404'
import Projects from '../pages/Projects'
import Selects from '../pages/Selects'
import UserTalent from '../pages/UserTalent'
import ProjectsForm from '../pages/ProjectsForm'

import Advertiser from '../pages/Advertiser'
import { RequireAuth } from '../services/Auth'
import TalentDetail from '../pages/TalentDetail'
import TalentSearch from '../pages/TalentSearch'
import PublicSelect from '../pages/PublicSelect'

import PublicAuth from '../hoc/PublicAuth'
import BulkUpload from '../pages/BulkUpload'
import LoaderPage from '../pages/LoaderPage'
import ProjectDetail from '../pages/ProjectDetail'
import AdvertiserUser from '../pages/AdvertiserUser'
import UserTalentDetail from '../pages/UserTalentDetail'
import AdvertiserDetail from '../pages/AdvertiserDetail'
import UserTalentSearch from '../pages/UserTalentSearch'
import UGCProjectWizard from '../pages/UGCProjectWizard/UGCProjectWizard'
import UGCViewProjectWizard from '../pages/UGCViewProjectWizard/UGCProjectWizard'

import { AccountSettings } from '../pages/settings/account'
import { UserSettings } from '../pages/settings/user'

import { ToolsMedia, ToolsPayment, ToolsLink, ToolsBrand } from '../pages/tools/'

import { ProjectListProvider } from '../contexts/project/projectList'
import { UGCDetailViewProvider } from '../contexts/project/ugcDetail'

export const AppRoutes = () => {
  const BulkUploadFeature = useFeature('bulk-upload')

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/talent" replace />} />
      <Route path="/talent" element={<RequireAuth> <Tlynt /> </RequireAuth>} />
      <Route path="/talent/:id" element={<RequireAuth> <TalentDetail/> </RequireAuth>} />
      <Route path="/selects/active/:id/:roleId" element={<RequireAuth><Selects active={true} /></RequireAuth>} />
      <Route path="/selects/archived/:id/:roleId" element={<RequireAuth><Selects active={false} /></RequireAuth>} />
      <Route path="/projects" element={<RequireAuth><ProjectListProvider><Projects /></ProjectListProvider></RequireAuth>} />
      <Route path="/projects/form" element={<RequireAuth> <ProjectsForm /> </RequireAuth>} />
      <Route path="/projects/ugc/create" element={<RequireAuth> <UGCProjectWizard /> </RequireAuth>} />
      <Route path="/projects/ugc/:id/:activeTab" element={<RequireAuth> <UGCProjectWizard /> </RequireAuth>} />

      <Route path="/projects/ugc/view/:id/:activeTab">
        <Route path="/projects/ugc/view/:id/:activeTab/:talentId/:innerTab" element={<RequireAuth> <UGCDetailViewProvider><UGCViewProjectWizard /></UGCDetailViewProvider> </RequireAuth>} />
        <Route path="/projects/ugc/view/:id/:activeTab" element={<RequireAuth> <UGCDetailViewProvider><UGCViewProjectWizard /></UGCDetailViewProvider> </RequireAuth>} />
      </Route>
      <Route path="/projects/ugc/:activeTab" element={<RequireAuth> <UGCProjectWizard /> </RequireAuth>} />

      <Route path="/projects/:id" element={<RequireAuth> <ProjectDetail /> </RequireAuth>} />
      <Route path="/users/talent" element={<RequireAuth> <UserTalent /> </RequireAuth>} />
      <Route path="/users/talent/:id" element={<RequireAuth> <UserTalentDetail /> </RequireAuth>} />
      <Route path="/advertiser" element={<RequireAuth> <Advertiser /> </RequireAuth>} />
      <Route path="/advertiser/:id" element={<RequireAuth> <AdvertiserDetail /> </RequireAuth>} />
      <Route path="/talent/search" element={<RequireAuth> <TalentSearch /> </RequireAuth>} />
      <Route path="/users/talent/search" element={<RequireAuth> <UserTalentSearch /> </RequireAuth>} />
      <Route path="/users/advertiser-user" element={<RequireAuth> <AdvertiserUser /> </RequireAuth>} />

      <Route path='/settings/account/' element={<RequireAuth><AccountSettings /></RequireAuth>} />
      <Route path='/settings/user/' element={<RequireAuth><UserSettings /></RequireAuth>} />

      <Route path='/tools/media/' element={<RequireAuth><ToolsMedia /></RequireAuth>} />
      <Route path='/tools/brand/' element={<RequireAuth><ToolsBrand /></RequireAuth>} />
      <Route path='/tools/payment/' element={<RequireAuth><ToolsPayment /></RequireAuth>} />
      <Route path='/tools/link/' element={<RequireAuth><ToolsLink /></RequireAuth>} />

      <Route path="/bulk-upload"
        element={!BulkUploadFeature.value
          ? <RequireAuth><LoaderPage /></RequireAuth>
          : BulkUploadFeature.on ? <RequireAuth> <BulkUpload /> </RequireAuth> : <Page404 />} />
      <Route path="/login/admin" element={<Login admin />} />
      <Route path="/login" element={<Login />} />
      <Route path="/public/selects/:id" element={<PublicAuth><PublicSelect /></PublicAuth>} />
      <Route path="/public/selects/:selectId/:childSelectId/talent/:talentId" element={<PublicAuth><TalentDetail isPublic /></PublicAuth>} />
      <Route path='/*' element={<Page404 />} />
    </Routes>
  )
}
