import { gql } from '@apollo/client'

class ProjectAPI {
  getProjectList () {
    return gql`
      query ProjectList(
        $projectIds: [ID!]
        $offset: Int
        $limit: Int
        $sort: String
        $status: [String!]
        $organizationId: ID
        $types: [ProjectType!]
      ) {
        projectList(
          projectIds: $projectIds
          offset: $offset
          limit: $limit
          sort: $sort
          status: $status
          organizationId: $organizationId
          types: $types
        ) {
          id
          name
          status
          ownerId
          selectId
          status
          startDate
          endDate
          type
          description
          notes
          countryCodeLocation
          postalCode
          geolocation {
            countryCodeLocation
            postalCode
          }
          mediaType
          roles {
            rate
          }
          productCategoryId
          productDescription
          tiktokHashtags
          tiktokSampleVideos
          hasProduct
          productShipQuantity
          videoQuantity
          videoPrice
          createdAt
          updatedAt
        }
      }
    `
  }

  getProjectDetail () {
    return gql`
      query ProjectDetail($id: ID!) {
        projectGet(id: $id) {
          id
          name
          ownerId
          owner {
            id
            name
            contact
            logo {
              id
              file
              uris {
                ... on PhotoURLS {
                  thumbnail
                  logo
                }
              }
            }
          }
          selectId
          status
          startDate
          endDate
          color
          mediaType
          type
          usage
          description
          notes
          roles {
            id
            name
            selectId
          }
          productCategoryId
          productDescription
          tiktokHashtags
          tiktokSampleVideos
          hasProduct
          productShipQuantity
          videoQuantity
          videoPrice
          createdAt
          updatedAt
          geolocation {
            countryCodeLocation
            postalCode
          }
          countryCodeLocation
          postalCode
        }
      }
    `
  }

  getProjectRoleList () {
    return gql`
      query ProjectRoleList($id: ID!) {
        projectRoleList(id: $id) {
          id
          name
          projectId
          selectId
          startDate
          endDate
          rate
          description
          notes
          quantity
          criteria {
            age {
              min
              max
            }
            weight {
              min
              max
            }
            height {
              min
              max
            }
            bodyType
            hairColor
            eyeColor
            ethnicity
            race
            gender
            genderExpression
            sexualOrientation
          }
        }
      }
    `
  }

  getListRoleInterests () {
    return gql`
      query ListRoleInterests($roleId: ID!, $offset: Int, $limit: Int) {
        listRoleInterests(roleId: $roleId, offset: $offset, limit: $limit) {
          projectId
          talentId
          roleId
          createdAt
          talent {
            id
            firstname
            lastname
            location
            mediaProfile {
              id
              type
              talentId
              disabled
              file
              uris {
                ... on PhotoURLS {
                  thumbnail
                  card
                  grid
                  full
                }
              }
              createdAt
            }
          }
        }
      }
    `
  }

  getListProjectInterests () {
    return gql`
      query ListProjectInterests(
        $projectId: String!
        $offset: Int
        $limit: Int
      ) {
        listProjectInterests(
          projectId: $projectId
          offset: $offset
          limit: $limit
        ) {
          talentId
          talent {
            id
            firstname
            lastname
            location
            mediaProfile {
              id
              type
              talentId
              disabled
              file
              uris {
                ... on PhotoURLS {
                  thumbnail
                  card
                  grid
                  full
                }
              }
              createdAt
            }
          }
        }
      }
    `
  }

  updateProject () {
    return gql`
      mutation ProjectUpdate(
        $id: ID!
        $name: String
        $status: String
        $ownerId: ID
        $selectId: ID
        $startDate: String
        $endDate: String
        $mediaType: [String]
        $usage: [String]
        $description: String
        $notes: String
      ) {
        projectUpdate(
          id: $id
          name: $name
          status: $status
          ownerId: $ownerId
          selectId: $selectId
          startDate: $startDate
          endDate: $endDate
          mediaType: $mediaType
          usage: $usage
          description: $description
          notes: $notes
        ) {
          id
          name
          ownerId
          owner {
            id
            name
            contact
            logo {
              id
              file
            }
          }
          selectId
          status
          startDate
          endDate
          mediaType
          usage
          roles {
            name
            rate
          }
        }
      }
    `
  }

  deleteProject () {
    return gql`
      mutation ProjectDelete($id: ID!) {
        projectDelete(id: $id) {
          status
          message
        }
      }
    `
  }

  deleteRoleProject () {
    return gql`
      mutation RoleDelete($id: ID!) {
        roleDelete(id: $id) {
          status
          message
        }
      }
    `
  }

  updateProjectWithSelect () {
    return gql`
      mutation UpdateProjectWithSelect(
        $id: ID!
        $name: String
        $status: String
        $ownerId: ID
        $selectId: ID
        $startDate: String
        $endDate: String
        $mediaType: [String]
        $usage: [String]
        $description: String
        $notes: String
        $countryCodeLocation: String
        $postalCode: String
      ) {
        updateProjectWithSelect(
          id: $id
          name: $name
          status: $status
          ownerId: $ownerId
          selectId: $selectId
          startDate: $startDate
          endDate: $endDate
          mediaType: $mediaType
          usage: $usage
          description: $description
          notes: $notes
          countryCodeLocation: $countryCodeLocation
          postalCode: $postalCode
        ) {
          id
          name
          status
          ownerId
          selectId
          startDate
          endDate
          mediaType
          usage
          description
          notes
        }
      }
    `
  }

  updateRolesWithSelect () {
    return gql`
      mutation updateRolesWithSelect(
        $updateRoles: [UpdateRole!]!
        $parentId: ID!
      ) {
        updateRolesWithSelect(updateRoles: $updateRoles, parentId: $parentId) {
          id
          name
          projectId
          selectId
          startDate
          endDate
          rate
          criteria {
            age {
              min
              max
            }
            weight {
              min
              max
            }
            height {
              min
              max
            }
            bodyType
            hairColor
            eyeColor
            ethnicity
            race
            gender
          }
        }
      }
    `
  }

  addProjectWithSelect () {
    return gql`
      mutation AddProjectWithSelect(
        $name: String
        $status: String
        $startDate: String
        $endDate: String
        $mediaType: [String]
        $usage: [String]
        $description: String
        $notes: String
        $ownerId: ID
        $countryCodeLocation: String
        $postalCode: String
      ) {
        addProjectWithSelect(
          name: $name
          status: $status
          startDate: $startDate
          endDate: $endDate
          mediaType: $mediaType
          usage: $usage
          description: $description
          notes: $notes
          ownerId: $ownerId
          countryCodeLocation: $countryCodeLocation
          postalCode: $postalCode
        ) {
          id
          name
          status
          ownerId
          selectId
          startDate
          endDate
          mediaType
          usage
          description
          notes
        }
      }
    `
  }

  addRolesWithSelect () {
    return gql`
      mutation addRolesWithSelect(
        $createRoles: [CreateRole!]!
        $parentId: ID!
        $ownerId: ID
      ) {
        addRolesWithSelect(
          createRoles: $createRoles
          parentId: $parentId
          ownerId: $ownerId
        ) {
          id
          name
          projectId
          selectId
          startDate
          endDate
          rate
          criteria {
            age {
              min
              max
            }
            weight {
              min
              max
            }
            height {
              min
              max
            }
            bodyType
            hairColor
            eyeColor
            ethnicity
            race
            gender
          }
        }
      }
    `
  }

  getOrganizationList () {
    return gql`
      query OrganizationList(
        $offset: Int
        $limit: Int
        $sort: String
        $ownerIds: [ID!]
      ) {
        organizationList(
          offset: $offset
          limit: $limit
          sort: $sort
          ids: $ownerIds
        ) {
          id
          name
          contact
          type
          logo {
            id
            file
            uris {
              ... on PhotoURLS {
                card
                thumbnail
                logo
              }
            }
          }
        }
      }
    `
  }

  getLegacyListProjectInterests () {
    return gql`
      query LegacyListProjectInterests(
        $projectId: String!
        $hideRoleInterest: Boolean
        $offset: Int
        $limit: Int
      ) {
        listProjectInterests(
          projectId: $projectId
          hideRoleInterest: $hideRoleInterest
          offset: $offset
          limit: $limit
        ) {
          projectId
          talentId
          roleId
          talent {
            id
            firstname
            lastname
            location
            mediaProfile {
              id
              type
              talentId
              disabled
              file
              uris {
                ... on PhotoURLS {
                  thumbnail
                  card
                  grid
                  full
                }
              }
              createdAt
            }
          }
        }
      }
    `
  }
}

export default new ProjectAPI()
