import { useContext } from 'react'
import classNames from 'classnames'

import { renderBullets } from '../pages/UGC/DetailOverview'
import { RenderIf } from '../../utils/Helpers'
import { SectionHeader } from '../UGCSectionHeader'
import { stillImageOptions } from '../../constants/UGCConstants'
import { UGCDetailContext } from '../../contexts/project/ugcDetail'
import { UGCTooltip } from '../Tooltip'
import Duration15Sec from '../../assets/images/UGCProject/Duration15Sec.svg'
import Duration30Sec from '../../assets/images/UGCProject/Duration30Sec.svg'
import Duration60Sec from '../../assets/images/UGCProject/Duration60Sec.svg'
import Info from '../../assets/images/UGCProject/Info.svg'
import Landscape from '../../assets/images/UGCProject/Landscape.svg'
import Play from '../../assets/images/UGCProject/Play.svg'
import Portrait from '../../assets/images/UGCProject/Portrait.svg'
import Radio from '../ImageRadio/ImageRadio'
import style from './UGCOverviewDeliverables.module.scss'

interface StillImageDeliverableCardProps {
  title: string
  category?: string
  description?: string
}

const StillImageDeliverableCard = (props: StillImageDeliverableCardProps): JSX.Element => {
  const { title, category, description } = props
  return (
    <article className={style.StillImageDeliverableCard}>
      <div className={style.StillImageDeliverableCard__OverviewContainer}>
        <h2 className={style.StillImageDeliverableCard__TitleText}>{title}</h2>
        <p className={style.StillImageDeliverableCard__CategoryText}>{category}</p>
      </div>

      <div className={style.StillImageDeliverableCard__DirectionContainer}>
        <p className={style.StillImageDeliverableCard__DirectionText}>Directions</p>
        <p className={style.StillImageDeliverableCard__DescriptionText}>{description !== '' && description !== undefined ? description : '-'}</p>
      </div>
    </article>
  )
}

const ScriptRow = ({ index, script }: { index: number, script: string | undefined | JSX.Element }): JSX.Element => {
  return (
    <div className={style.ScriptRow}>
      <div className={style.ScriptRow__TitleContainer}>
        <img src={Play} />
        <p className={style.ScriptRow__TitleText}>{`Video ${index + 1}`} Script</p>
      </div>
      <div className={style.ScriptRow__TitleDescription}>{script}</div>
    </div>
  )
}

export const UGCOverviewDeliverables = (): JSX.Element => {
  const [{ overview }] = useContext(UGCDetailContext)

  const { deliverables } = overview

  // video specification
  const videoOrientation = deliverables?.filter((item) => item.type === 'main')[0]?.specs?.orientation ?? null
  const videoDuration = deliverables?.filter((item) => item.type === 'main')[0]?.specs?.duration ?? null

  // video direction
  const videoDirectionData = deliverables?.filter((item) => item.type === 'main')[0]?.specs?.specs
  const videoDirection = videoDirectionData != null && videoDirectionData?.length > 0 ? renderBullets(deliverables?.filter((item) => item.type === 'main')[0]?.specs?.specs ?? []) : '-'
  const deliverableVideos = deliverables?.filter((item) => item.type === 'main')

  // intro hook
  const deliverableIntroHook = deliverables?.filter((item) => item.type === 'intro_hook')

  // still images
  const deliverablePhotos = deliverables?.filter((item) => item.type === 'photo')
  const photoOptions = stillImageOptions.map((item) => item.label)

  // broll
  const deliverableBRollData = deliverables?.filter((item) => item.type === 'b_roll')
  const deliverableBRoll = deliverableBRollData != null && deliverableBRollData?.length > 0 ? renderBullets(deliverables?.filter((item) => item.type === 'b_roll')[0].specs?.specs ?? []) : '-'

  return (
    <section id='deliverables' className={style.Deliverables}>
      <p className={style.Deliverables__Title}>Deliverables</p>

      <section>
        <SectionHeader headerText='Number of Primary Videos' />
        <p className={style.Deliverables__DeliverableVideoCount}>{deliverables?.filter((item) => item.type === 'main').length}</p>
      </section>

      <section className={style.Deliverables__DeliverableVideoSpecs}>
        <div>
          <SectionHeader headerText='Video Orientation' tooltip={<UGCTooltip dataTestId='project-ugc-tooltip-video-orientation' popper={<img src={Info} />}>
            <span>Choose the orientation (portrait or landscape) that suits the platforms you&apos;re targeting for your campaign. Creators will produce all the videos in this orientation.</span>
          </UGCTooltip>} />
          {(videoOrientation != null) ? <Radio img={deliverables?.filter((item) => item.type === 'main')[0].specs?.orientation === 'portrait' ? Portrait : Landscape} title={videoOrientation.charAt(0).toUpperCase() + videoOrientation.slice(1)} isSelected={false} /> : '-'}
        </div>
        {(videoDuration != null) &&
          <div>
            <SectionHeader headerText='Video Duration' tooltip={<UGCTooltip dataTestId='project-ugc-tooltip-video-duration' popper={<img src={Info} />}>
              <span>Choose how long you want the videos to be. Creators will produce each video to this duration.</span>
            </UGCTooltip>} />
            {videoDuration === 15 && <Radio img={Duration15Sec} isSelected={false} />}
            {videoDuration === 30 && <Radio img={Duration30Sec} isSelected={false} />}
            {videoDuration === 60 && <Radio img={Duration60Sec} isSelected={false} />}
          </div>
        }
      </section>

      <section className={style.Deliverables__MainDeliverablesContainer}>
        <SectionHeader extHeaderTextClassNames={[style.Deliverables__SectionHeaderText]} extDescriptionTextClassNames={[style.Deliverables__SectionHeaderDescriptionText]} headerText='Direction for All Videos' isBullets descriptionText={videoDirection} />

        <RenderIf isTrue={deliverableVideos?.length > 0}>
          <section className={style.Deliverables__MainDeliverablesContainer}>
            <SectionHeader headerText='Script Block for Each Video' tooltip={<UGCTooltip dataTestId='project-ugc-tooltip-video-scripts' popper={<img src={Info} />}>
              <span>Add a script or select “creator&apos;s choice” if you&apos;d like the content creator to come up with a script based on your campaign details. Be sure that each hero video script can be read at a normal reading speed in the duration of the videos you&apos;ve selected.</span>
            </UGCTooltip>} />
            <div className={style.Deliverables__DeliverableScripts}>
              {deliverableVideos?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((item, index) => <ScriptRow key={index} index={index} script={(item.script != null && item.script?.length > 0) ? renderBullets(item.script) : 'No, Talent Choice'} />)}
            </div>
          </section>
        </RenderIf>
      </section>

      <section className={style.Deliverables__IntroVideoOverviewContainer}>
        <SectionHeader headerText='Intro Videos' tooltip={<UGCTooltip dataTestId='project-ugc-tooltip-intro-videos' popper={<img src={Info} />}>
          <span>Intro videos give you more creative options in the editing process. For each intro, you can add a script or select “creator&apos;s choice” if you&apos;d like the content creator to come up with intros that can be paired with the hero video(s). Each intro video script should take less than 5 seconds to read at normal reading speed.</span>
        </UGCTooltip>} />

        <p className={style.Deliverables__DeliverableVideoCount}>{deliverableIntroHook?.length}</p>
      </section>

      <RenderIf isTrue={deliverableIntroHook?.length > 0}>
        <section className={style.Deliverables__IntroVideoDeliverables}>
          <SectionHeader headerText='Intro Videos scripts' />
          <div className={style.Deliverables__DeliverableScripts}>
            {deliverableIntroHook?.length > 0 && deliverableIntroHook.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((item, index) => <ScriptRow key={index} index={index} script={item?.script != null && item?.script?.length > 0 ? renderBullets(item.script) : 'No, Talent Choice'} />)}
          </div>
        </section>
      </RenderIf>

      <section className={style.Deliverables__StillImageTitleContainer}>
        <SectionHeader headerText='Still Images' tooltip={<UGCTooltip dataTestId='project-ugc-tooltip-still-images' popper={<img src={Info} />}>
          <span>Still images are useful for thumbnails, promotional stills, banner ads, and more. You can specify notes to ensure creators capture specific angles or compositions.</span>
        </UGCTooltip>} />
        <p className={style.Deliverables__DeliverableVideoCount}>{deliverablePhotos?.length}</p>
      </section>

      <section className={classNames(style.Deliverables__StillImageCardsContainer, deliverablePhotos.length === 0 ? style['Deliverables__StillImageCardsContainer--Empty'] : '')}>
        {deliverablePhotos.length > 0 && deliverablePhotos.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((deliverable, index) => {
          const category = photoOptions.includes(deliverable?.stillSpecs?.directions?.[0] ?? '') ? deliverable?.stillSpecs?.directions?.[0] : 'Other'
          const description = category === 'Other' ? deliverable?.stillSpecs?.directions?.join('\n') : deliverable?.stillSpecs?.directions?.slice(1)?.join('\n')
          return <StillImageDeliverableCard key={index} title={`Image ${index + 1}`} category={category} description={description} />
        })}
      </section>

      <section className={style.Deliverables__BRoll}>
        <SectionHeader extHeaderTextClassNames={[style.Deliverables__SectionHeaderText]} extDescriptionTextClassNames={[style.Deliverables__SectionHeaderDescriptionText]} headerText='B-Roll' descriptionText={deliverableBRoll} isBullets tooltip={<UGCTooltip dataTestId='project-ugc-tooltip-b-roll' popper={<img src={Info} />}>
          <span>B-Roll is recorded video footage that can be used during the editing process. With b-roll, we can cut away from you speaking at points in the ad, and show the B Roll footage while you speak.</span>
        </UGCTooltip>} />
      </section>
    </section>
  )
}
