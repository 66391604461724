import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { useDispatch, useStore, useSelector } from 'react-redux'

import { UGCAssetsSidebar } from '../../../UGCAssetsSidebar/UGCAssetsSidebar'
import style from './Assets.module.scss'
import { UGCAssetsMain } from '../../../UGCAssetsMain'
import { getAssetsListProjectTalentStatus } from '../../../../apis/project'
import { SET_ASSETS_BOOKED_TALENTS, UGCDetailContext } from '../../../../contexts/project/ugcDetail'
import { setUGCAssetsDownloadJobs } from '../../../../redux/slices/ugcDownloadAssetSlice'
import { usePrompt } from '../../../../hooks/Common/usePrompt'

interface ITalentStatus {
  id: string
  name: string
  photo: string
  status: string
  assetApprovedCount: number
  inReviewCount: number
  allApproved: boolean
  isTalentDeleted: boolean
  initials: string
}

interface listProjectTalentStatus {
  talentId: string
  talent: Talent
  metadata: {
    approvedSubmissions: number
    rejectedSubmissions: number
    submittedSubmissions: number
  }
  status: string
  createdAt: string
  updatedAt: string
}

interface Talent {
  firstname: string
  lastname: string
  mediaProfile: MediaProfile
  videoProfile?: any
}

interface MediaProfile {
  uris: Uris
}

interface Uris {
  card: string
  profilePic: string
}

const alphabeticSort = (a: ITalentStatus, b: ITalentStatus): number => a.name.localeCompare(b.name)

export const Assets = (): JSX.Element => {
  // INIT
  const { talentId, id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const reduxDispatch = useDispatch()
  const store: any = useStore()
  const [{ assetsBookedTalents, overview }, dispatch] = useContext(UGCDetailContext)
  const { downloadAssetsJobs } = useSelector((state: any) => state.UGCDownloadAssetsReducer)

  // CONSTANTS
  const processingOrPendingDownloadJobs = downloadAssetsJobs.filter((job: any) => job?.status === 'processing' || job?.status === 'pending')
  const deliverableIntroHookCount = overview?.deliverables?.filter((item) => item.type === 'intro_hook')?.length
  const deliverableMainCount = overview?.deliverables?.filter((item) => item.type === 'main')?.length
  const deliverablePhotoCount = overview?.deliverables?.filter((item) => item.type === 'photo')?.length
  const deliverableBRollCount = overview?.deliverables?.filter((item) => item.type === 'b_roll')?.length

  usePrompt('Your download is still processing and will be lost if you close the browser.', processingOrPendingDownloadJobs?.length > 0)

  // API
  const { data: listProjectTalentStatusData, loading: listProjectTalentStatusLoading } = useQuery(getAssetsListProjectTalentStatus,
    {
      variables: { projectId: id, statuses: ['booking_confirmed', 'wrapped'], limit: 100, offset: 0 },
      skip: id === undefined || id === null
    })

  useEffect(() => {
    return () => {
      const downloadAssetsJobs = store.getState().UGCDownloadAssetsReducer?.downloadAssetsJobs
      // clear the download jobs when the component unmounts
      if (downloadAssetsJobs?.length > 0) {
        reduxDispatch(setUGCAssetsDownloadJobs([]))
      }
    }
  }, [])

  // CALLING THE METHOD WHEN getListProjectTalentStatus API IS CALLED
  useEffect(() => {
    if (!listProjectTalentStatusLoading && listProjectTalentStatusData?.listProjectTalentStatus?.length > 0 && !overview?.loading) {
      handleFormatTalentStatusList()
    }
  }, [listProjectTalentStatusLoading, overview])

  /**
    * Handler that responsible for formatting the getListProjectTalentStatus API response
    * @returns void
  */
  const handleFormatTalentStatusList = (): void => {
    const deliverableCount: Record<string, number> = {
      intro_hook: deliverableIntroHookCount,
      main: deliverableMainCount,
      photo: deliverablePhotoCount,
      b_roll: deliverableBRollCount
    }
    let firstKeyWithCountGreaterThanZero = ''

    // Iterate through the keys of deliverableCount object
    for (const key in deliverableCount) {
      if (deliverableCount[key] > 0) {
        firstKeyWithCountGreaterThanZero = key
        break // Exit the loop as soon as we find the first key with a count greater than 0
      }
    }
    const talentStatusModifier = (talentStatus: listProjectTalentStatus): ITalentStatus => ({
      id: talentStatus.talentId,
      name: `${String(talentStatus.talent?.firstname)} ${String(talentStatus.talent?.lastname)}`,
      initials: `${String(talentStatus.talent?.firstname.charAt(0))}${String(talentStatus.talent?.lastname.charAt(0))}`,
      photo: talentStatus.talent?.mediaProfile?.uris?.profilePic ?? null,
      assetApprovedCount: talentStatus.metadata?.approvedSubmissions ?? 0,
      status: talentStatus.status,
      inReviewCount: talentStatus.metadata?.submittedSubmissions ?? 0,
      isTalentDeleted: talentStatus.talent === null,
      allApproved: talentStatus.status === 'wrapped'
    })
    const formattedTalentStatusList = listProjectTalentStatusData?.listProjectTalentStatus.map(talentStatusModifier).sort(alphabeticSort)
    dispatch({ type: SET_ASSETS_BOOKED_TALENTS, payload: formattedTalentStatusList })

    const queryParamString = encodeURIComponent(formattedTalentStatusList[0].id).toString()
    if (talentId === undefined) {
      navigate(`${location.pathname}/${queryParamString}/${firstKeyWithCountGreaterThanZero}`, { replace: true })
    }
  }

  return (
    <div className={style.Assets}>
      <UGCAssetsSidebar loading={listProjectTalentStatusLoading || overview?.loading} data={assetsBookedTalents ?? []} activeTalentId={talentId} />
      <UGCAssetsMain />
    </div>
  )
}
