import { useEffect, cloneElement } from 'react'

import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { Skeleton } from '@mui/material'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AuthScreen from '../pages/PublicSelect/Components/AuthScreen'
import { getPublicToken } from '../services/RestAPI'
import SelectAPI from '../services/Select/SelectAPI'

const PUBLIC_USER_RANDOM_ID = uuidv4()

const getToken = () => {
  const publicToken = localStorage.getItem('public_token')
  const privateToken = localStorage.getItem('access_token')
  return privateToken ?? publicToken
}

const PublicAuth = (props) => {
  const { id, selectId } = useParams()

  const { data, error: getSelectError, loading, refetch } = useQuery(SelectAPI.getPublicSelectByIdPublic(), {
    skip: !getToken(),
    variables: {
      id: id ?? selectId
    },
    notifyOnNetworkStatusChange: false
  })

  if (getSelectError) {
    console.error('GET SELECT ERROR', getSelectError)
  }

  const handleRefetchSelect = async (cb) => {

    try {
      await refetch()
    } catch (error) {
      if (typeof cb === 'function') {
        cb(error)
      }
    }
  }

  useEffect(() => {

    if (!getToken()) {
      getPublicToken(PUBLIC_USER_RANDOM_ID, id)
        .then((data) => {
          localStorage.setItem('public_token', data.accessToken)
          refetch()
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [])

  if (loading || !getToken()) {
    return (
      <div>
        <Skeleton sx={{ margin: 'auto', marginTop: '30px', width: 'min(100% - 40px, 1120px)' }} height={75} animation='wave' />
        <Skeleton sx={{ margin: 'auto', width: 'min(100% - 40px, 1120px)' }} height={75} animation='wave' />
        <Skeleton sx={{ margin: 'auto', width: 'min(100% - 40px, 1120px)' }} height={75} animation='wave' />
      </div>
    )
  }


  if (['Password required to access this Select', 'Response not successful: Received status code 401', 'Incorrect Password'].includes(getSelectError?.message)) {
    return <AuthScreen handleRefetchSelect={handleRefetchSelect} />
  }

  const children = Array.isArray(props.children) ? props.children.filter(e => e !== '')[0] : props.children

  const ClonedChildren = cloneElement(children, { selectData: data, selectLoading: loading })

  return <div>{ClonedChildren}</div>
}

PublicAuth.propTypes = {
  children: PropTypes.node
}

export default PublicAuth
