import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import ProjectAPI from '../../../services/Project/ProjectAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useAddRolesWithSelectMutation () {
  // hooks
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const [AddRolesWithSelect, { data, loading }] = useMutation(
    ProjectAPI.addRolesWithSelect(),
    {
      notifyOnNetworkStatusChange: true
    }
  )

  const SubmitRole = (props, toggleModal) => {
    const { roles, projectId, selectId, ownerId } = props
    const payload = []
    roles.forEach((role, index) => {
      const {
        name,
        rate,
        startDate,
        endDate,
        age,
        height,
        weight,
        bodyType,
        hairColor,
        eyeColor,
        race,
        gender,
        genderExpression,
        sexualOrientation,
        ethnicity,
        isAgeAllowed,
        isWeightAllowed,
        isHeightAllowed,
        description,
        notes,
        quantity
      } = role
      // passing null when data is not choosed by user
      const payloadObj = {
        name,
        rate: rate ? parseInt(rate) : null,
        startDate: startDate || null,
        endDate: endDate || null,
        projectId,
        description,
        notes,
        quantity: quantity ? parseInt(quantity) : null,
        criteria: {
          age: isAgeAllowed
            ? {
                min: age[0],
                max: age[1]
              }
            : null,
          height: isHeightAllowed
            ? {
                min: height[0],
                max: height[1]
              }
            : null,
          weight: isWeightAllowed
            ? {
                min: weight[0],
                max: weight[1]
              }
            : null,
          hairColor: hairColor?.length > 0 ? hairColor : null,
          bodyType: bodyType?.length > 0 ? bodyType : null,
          eyeColor: eyeColor?.length > 0 ? eyeColor : null,
          race: race?.length > 0 ? race : null,
          gender: gender?.length > 0 ? gender : null,
          genderExpression:
            genderExpression?.length > 0 ? genderExpression : null,
          sexualOrientation:
            sexualOrientation?.length > 0 ? sexualOrientation : null,
          ethnicity: ethnicity?.length > 0
            ? ethnicity
            : null
        }
      }
      const nullFilteredMainObject = Object.fromEntries(
        Object.entries(payloadObj).filter(([_, v]) => v != null)
      )
      const nullFIlteredCriteriaObject = Object.fromEntries(
        Object.entries(payloadObj.criteria).filter(([_, v]) => v != null)
      )
      nullFilteredMainObject.criteria = nullFIlteredCriteriaObject
      payload.push(nullFilteredMainObject)
    })
    AddRolesWithSelect({
      variables: {
        createRoles: payload,
        parentId: selectId,
        ownerId: userInfo?.type === 'advertiser' ? userInfo?.ownerId : ownerId
      }
    })
      .then(async () => await toggleModal(projectId))
      .catch((error) => {
        dispatch(
          showToast({
            message: error?.message,
            isError: true
          })
        )
      })
  }

  return [SubmitRole, { data, loading }]
}

export default useAddRolesWithSelectMutation
