import * as Yup from 'yup'
import { strings } from '../constants'

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size
  })
})

const extractSocialUsername = (url, socialHandle) => {
  let rx = ''
  if (socialHandle === 'facebook') {
    rx = /(?:(?:http|https):\/\/)?(?:www.|m.)?facebook.com\/(?!home.php)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\\.-]+)/gm
  } else if (socialHandle === 'tiktok' || socialHandle === 'twitter' || socialHandle === 'instagram') {
    rx = new RegExp(`^https?://(?:www\\.)?${socialHandle}.com/(?:#!/)?([^?/#]*)(?:[?/#].*)?$`, 'gm')
  }
  const arr = rx.exec(url)
  return arr ? arr[1] : url
}
Yup.addMethod(Yup.string, 'checkValidSocialUser', function (socialHandle) {
  return this.test('checkValidSocialUser', 'Enter valid social link', function (link) {
    let regex = /^[a-zA-Z0-9_.]*$/
    if (link && link?.length > 0) {
      switch (socialHandle) {
        case 'facebook':
          regex = /^[a-zA-Z0-9.]{5,255}$/
          break
        case 'twitter':
          regex = /^[a-zA-Z0-9_]{4,15}$/
          break
        case 'instagram':
          regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{2,29}$/
          break
        default:
          regex = /^[a-zA-Z0-9_.]*$/
          break
      }
      return regex.test(extractSocialUsername(link, socialHandle))
    } else {
      return true
    }
  })
})

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(strings.errors.invalidEmail)
    .max(255)
    .required(strings.errors.emptyEmail)
    .trim(),
  phone: Yup.string().nullable()
    .min(8, 'Phone number must be atleast 7 character long')
    .max(12, 'Phone number must be atmost 10 character long')
    .trim()
})

export const AddTalentValidationSchema = Yup.object().shape({
  firstName: Yup.string().nullable()
    .min(3, 'First name must be 3 character long')
    .max(255, 'First name must be less than 255 character')
    .trim(),
  lastName: Yup.string().nullable()
    .min(3, 'Last name must be 3 character long')
    .max(255, 'Last name must be less than 255 character')
    .trim(),
  email: Yup.string()
    .email(strings.errors.invalidEmail)
    .max(255)
    .required(strings.errors.emptyEmail)
    .trim(),
  phone: Yup.string().nullable()
    .min(8, 'Phone number must be atleast 7 character long')
    .max(12, 'Phone number must be atmost 10 character long')
    .trim(),
  postalCode: Yup.string()
    .max(12, 'Postal code should be max 12 character long')
    .trim(),
  socialLinks: Yup.object({
    instagram: Yup.string().checkValidSocialUser('instagram').nullable(),
    facebook: Yup.string().checkValidSocialUser('facebook').nullable(),
    tiktok: Yup.string().checkValidSocialUser('tiktok').nullable(),
    twitter: Yup.string().checkValidSocialUser('twitter').nullable(),
    website: Yup.string().matches(/(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/, 'Enter valid website URL').nullable()
  })
})

export const UpdateTalentValidationSchema = Yup.object().shape({
  firstname: Yup.string().nullable()
    .required('First name is required')
    .min(1, 'First name must be 1 character long')
    .max(255, 'First name must be less than 255 character')
    .trim(),
  lastname: Yup.string().nullable()
    .min(1, 'Last name must be 1 character long')
    .max(255, 'Last name must be less than 255 character')
    .trim()
    .required('Last name is required'),
  email: Yup.string()
    .email(strings.errors.invalidEmail)
    .max(255)
    .required(strings.errors.emptyEmail)
    .trim(),
  phone: Yup.string().nullable()
    .min(8, 'Phone number must be atleast 7 character long')
    .max(12, 'Phone number must be atmost 10 character long')
    .trim(),
  postalCode: Yup.string()
    .max(12, 'Postal code should be max 12 character long')
    .required('Postal code is required')
    .trim()
    .nullable(),

  socialLinks: Yup.object({
    instagram: Yup.string().checkValidSocialUser('instagram').nullable(),
    facebook: Yup.string().checkValidSocialUser('facebook').nullable(),
    tiktok: Yup.string().checkValidSocialUser('tiktok').nullable(),
    twitter: Yup.string().checkValidSocialUser('twitter').nullable(),
    website: Yup.string().matches(/(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/, 'Enter valid website URL').nullable()
  })
})

export const CreateNewSelectValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required').min(3, 'Name must be 3 character long').max(255, 'Select name must be less than 255 character'),
  roles: Yup.array(Yup.object({
    role_name: Yup.string().min(3, 'Role name must be at least 3 characters').max(255, 'Role name must be less than 255 character').required('Role name is required')
  })).min(1),
  description: Yup.string().max(255, 'Description must be less than 255 character.')
})

export const AddNewRoleValidationSchema = Yup.object().shape({
  roles: Yup.array(Yup.object({
    role_name: Yup.string().min(3, 'Role name must be at least 3 characters').max(255, 'Role name must be less than 255 character').required('Role name is required')
  })).min(1)
})

export const PersonalInfoValidationSchema = Yup.object().shape({
  // Race and Country of heritage is multi select
  birthdate: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .default(undefined),
  bodytype: Yup.string().nullable(),
  haircolor: Yup.string().nullable(),
  eyecolor: Yup.string().nullable(),
  gender: Yup.string().nullable(),
  genderexpression: Yup.string().nullable(),
  sexualorientation: Yup.string().nullable(),
  race: Yup.array().nullable(),
  ethnicity: Yup.array().nullable(),
  hfeet: Yup.number().integer('Feet should be an integer')
    .typeError('You must specify a number.')
    .min(1, 'Min value 1.')
    .max(12, 'Max value 12.')
    .when('hinch', {
      is: (val) => !!val,
      then: () => Yup.number().required('Feet is required').label('Feet').typeError(({ label, type }) => `${label} must be a ${type}`)
    }),
  hinch: Yup.number().integer('Inch(es) should be an integer')
    .typeError('You must specify a number.')
    .min(0, 'Min value 0.')
    .max(11, 'Max value 11.')
    .when('hfeet', {
      is: (val) => !!val,
      then: () => Yup.number().required('Inch is required').label('Inch').typeError(({ label, type }) => `${label} must be a ${type}`)
    }),
  customsexualorientation: Yup.string().min(1)
    .nullable()
    .when('sexualorientation', {
      is: sexualorientation => {
        return sexualorientation === '5'
      },
      then: (schema) => {
        return schema.required('Other sexual orientation is required')
      },
      otherwise: (schema) => schema.optional()
    }),
  country: Yup.string().nullable()
}, ['hfeet', 'hinch'])

export const formatPhoneNumber = (value) => {
  if (!value) return value
  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`
  }
  return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
    3,
    6
  )} ${phoneNumber.slice(6, 10)}`
}

export const ProjectOverviewValidationSchema = Yup.object().shape({
  projectName: Yup.string().required('Project name is required').trim().min(1, 'Project name should be atleast 1 character long').max(250, 'Project name should be atmost 250 character long'),
  advertiser: Yup.string().required('Advertiser is required'),
  description: Yup.string().trim().required('Description is required').max(1000, 'Description should be at most 1000 characters'),
  notes: Yup.string().max(1000, 'Notes should be at most 1000 characters'),
  startDate: Yup.date().nullable().required('Start Date is required').typeError('Invalid date'),
  endDate: Yup.date().nullable().required('End Date is required')
    .typeError('Invalid date')
    .min(
      Yup.ref('startDate'),
      "End date can't be before Start date"
    ),
  postalCode: Yup.string()
    .max(12, 'Postalcode should be max 12 character long')
    .required('Postalcode is required')
    .trim()
})

export const ProjectRolesValidationSchema = Yup.object().shape({
  roles: Yup.array()
    .of(Yup.object().shape({
      rate: Yup.string().matches('^\\d+$', 'Daily Rate should contain digits only').max(10, 'Daily Rate should be at most 10 digits').required('Daily Rate is required'),
      name: Yup.string().required('Role name is required'),
      description: Yup.string().trim().max(1000, 'Description should be at most 1000 characters'),
      quantity: Yup.number().required('Number of positions is required').min(1, 'Number of positions should be atleast 1'),
      notes: Yup.string().max(1000, 'Notes should be at most 1000 characters'),
      startDate: Yup.date().nullable().when('endDate', {
        is: endDate => endDate !== null,
        then: (schema) => schema.required('Start Date is required'),
        otherwise: (schema) => schema.optional()
      }).typeError('Invalid date'),
      endDate: Yup.date().nullable().when('startDate', {
        is: startDate => startDate !== null,
        then: (schema) => schema.required('End Date is required'),
        otherwise: (schema) => schema.optional()
      })
        .typeError('Invalid date')
        .min(
          Yup.ref('startDate'),
          "End date can't be before Start date"
        )
    }, ['startDate', 'endDate']))
})

export const CompanyDetailValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required').trim().min(1, 'Company name should be atleast 1 character long').max(250, 'Company name should be atmost 250 character long'),
  logo: Yup.string().nullable(),
  email: Yup.string()
    .email(strings.errors.invalidEmail)
    .max(255)
    .nullable()
})

export const AdvertiserMembersValidationSchema = Yup.object().shape({
  members: Yup.array()
    .of(Yup.object().shape({
      firstname: Yup.string().trim()
        .min(1, 'First name should be atleast 1 character long')
        .max(250, 'First name should be atmost 250 character long')
        .required('First name is required'),
      lastname: Yup.string().trim()
        .min(1, 'Last name should be atleast 1 character long')
        .max(250, 'Last name should be atmost 250 character long')
        .required('Last name is required'),
      email: Yup.string()
        .email(strings.errors.invalidEmail)
        .max(255)
        .required(strings.errors.emptyEmail),
      title: Yup.string().required('Title is required'),
      phone: Yup.string().min(8, 'Phone number must be atleast 7 character long')
        .max(12, 'Phone number must be atmost 10 character long')
        .nullable()
    }))
})

export const AdvertiserUserValidationSchema = Yup.object().shape({
  firstName: Yup.string().nullable()
    .required('First name is required')
    .max(255, 'First name must be less than 255 character')
    .trim(),
  lastName: Yup.string().nullable()
    .required('Last name is required')
    .max(255, 'Last name must be less than 255 character')
    .trim(),
  email: Yup.string()
    .email('Invalid email')
    .max(255)
    .required('Email is required'),
  title: Yup.array().of(Yup.string())
})

export const SecureSelectValidationSchema = Yup.object().shape({
  password: Yup.string().max(255)
    .required('Password is required for Password Protection.')
})

export const PublicSelectValidationSchema = Yup.object().shape({
  password: Yup.string().max(255)
    .required('Password is required')
})

export const BulkUploadTagsValidationSchema = Yup.object().shape({
  tags: Yup.array().min(1, 'At least one tag is required.')
    .of(Yup.string()
      .matches(/^[a-zA-Z0-9._-]+$/, 'Sorry, only letters (a-z), numbers (0-9), periods (.), underscore (_) and hyphen(-) are allowed.'))
})

export default ValidationSchema
