import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { Button, Typography } from '../../../../components'
import { Colors, UIConstants } from '../../../../constants'
import { DELETE_SELECT } from '../../../../services/API'
import useChildSelectListQuery from '../../Hooks/useChildSelectListQuery'

const Wrapper = styled.div`
  height: 100vh;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: scroll;
  margin-top: 40px;
`

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: end;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  align-self: center;
`

const Bottom = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`

const SelectsTitleWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
  word-break: break-all;
`

const RoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding-top: 15px;
`

const RoleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 12px;
  width: 100%;
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: ${Colors.white};
    margin-left: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.lightSilver};
    border: 4px solid white;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px ${Colors.white};
  }
  ::-webkit-scrollbar-corner {
    background: ${Colors.white};
  }
`

const RoleDiv = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
`

const DeleteSelect = ({ isVisibleModal, toggleModal, parentActiveSelectData, resetActiveMenu, handleRefetchSelectList }) => {
  const [DeleteSelect, { loading }] = useMutation(DELETE_SELECT, {
    notifyOnNetworkStatusChange: true
  })

  // api call - for getting the child selects associated with parent select
  const { data: childSelectsData } = useChildSelectListQuery({ parentSelectId: parentActiveSelectData?.id })

  const handleDeleteSelect = () => {
    DeleteSelect({ variables: { id: parentActiveSelectData?.id } }).then(res => {
      handleRefetchSelectList()
      resetActiveMenu()
      toggleModal(false)
    })
  }
  return (
    isVisibleModal && (
      <Wrapper>
        <InnerWrapper>
          <Typography fontSize={30} fontWeight={700}>
            {UIConstants.isDeleteSelect}
          </Typography>
          <Typography fontSize={14} letterSpacing={0.15} color={Colors.errorRed}>{UIConstants.deleteSelectDescription}</Typography>
          <SelectsTitleWrapper>
            <Typography fontSize={20} fontWeight={'bold'}>{parentActiveSelectData.name}</Typography>
            <Typography fontSize={14} style={{ opacity: 0.8, marginTop: 10, display: 'block' }}>
              {parentActiveSelectData.description}
            </Typography>
          </SelectsTitleWrapper>
          <RoleWrapper>
            <Typography fontSize={14} fontWeight={'bold'} style={{ marginBottom: 20 }} >Role</Typography>
            <RoleListWrapper>
              {childSelectsData?.listSelects?.length > 0 &&
                childSelectsData?.listSelects.map(role => (
                  <RoleDiv key={role?.id}>
                    <Typography
                     fontSize={16}
                     fontWeight={400}
                     style={{ marginBottom: 16 }}
                     letterSpacing={0.15}>{role.name}</Typography>
                  </RoleDiv>
                ))}
            </RoleListWrapper>
          </RoleWrapper>
          <Bottom>
            <RowView>
              <Button onClick={toggleModal} backgroundColor='white'>
                <Typography fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>CANCEL</Typography>
              </Button>
              <Button onClick={handleDeleteSelect} loading={loading}>
                <Typography fontWeight={'bold'} fontSize={15} color={Colors.white}>DELETE</Typography>
              </Button>
            </RowView>
          </Bottom>
        </InnerWrapper>
      </Wrapper>
    )
  )
}
export default DeleteSelect

DeleteSelect.propTypes = {
  isVisibleModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  parentActiveSelectData: PropTypes.object,
  childSelectData: PropTypes.array,
  resetActiveMenu: PropTypes.func,
  handleRefetchSelectList: PropTypes.func
}
