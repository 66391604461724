import { Fragment } from 'react'

import { Field } from 'formik'
import styled from 'styled-components'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'

const LabelText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 0px;
`
const CheckBoxesWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.length > 2 ? 'space-between' : '')};
`

const checkbox = props => {
  const { label, items, name } = props
  return (
    <Fragment>
      <LabelText>{label}</LabelText>
      <CheckBoxesWrapper length={items.length}>
        <Field name={name}>
          {({ field }) => {
            return items.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <MuiCheckbox
                      checked={field?.value?.indexOf(item.value) > -1}
                      style={{ color: 'black' }}
                      value={item.value}
                    />
                  }
                  label={item.label}
                  type={'checkbox'}
                  name={name}
                  {...field}
                />
              )
            })
          }}
        </Field>
      </CheckBoxesWrapper>
    </Fragment>
  )
}

export default checkbox
