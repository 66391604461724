import { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { useQuery } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'

import AddNewProject from './Components/AddNewProject'
import ProjectsWrapper from './Components/ProjectsWrapper'

import { Images } from '../../assets'
import style from './Project.module.scss'
import { getProjectList } from '../../apis/project'
import { UIConstants, Colors, ProjectConstants } from '../../constants'
import { ProjectListContext } from '../../contexts/project/projectList'
import { Button, HeaderV2, Layout, TlyntSidebar, Typography } from '../../components'

const projectTypes = [
  { name: 'all' },
  { name: 'casting' },
  { name: 'ugc' }]

const Projects = () => {
  const user = useSelector(state => state.UserReducer)
  const navigate = useNavigate()

  // state
  const [projectType, setProjectType] = useState('all')
  const [activeTabKey, setActiveTabKey] = useState('active')
  const [isVisibleModal, setIsvisibleModal] = useState(false)
  const [loadingMoreProjectStatus, setLoadingMoreProjectStatus] = useState('done')

  const { data, loading, fetchMore: fetchMoreProjectList, refetch } = useQuery(getProjectList, {
    fetchPolicy: 'no-cache',
    variables: {
      offset: 0,
      limit: UIConstants.projectFetchLimit,
      types: projectType === 'all' ? undefined : [projectType],
      status: activeTabKey === 'all' ? undefined : activeTabKey,
      withBrand: true
    }
  })

  const { list, handleCreateList, handleUpdateList } = useContext(ProjectListContext)
  useEffect(() => {
    if (!loading && data?.listProject?.length > 0) {
      handleCreateList(data.listProject)
    }
  }, [data, loading])

  const activeRouteDetails = ProjectConstants.Project
  const MenuList = activeRouteDetails.tabBarMenu

  const updateActiveSelect = name => {
    if (activeTabKey !== name) {
      handleCreateList([])
    }
    setActiveTabKey(name)
    const selectIndex = MenuList.findIndex(item => item?.name === name)
    MenuList.forEach((element, idx) => {
      if (idx === selectIndex) MenuList[selectIndex].isFocused = true
      else MenuList[idx].isFocused = false
    })
  }

  const handleScroll = cardViewRef => {
    if (loadingMoreProjectStatus === 'done' && cardViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = cardViewRef.current
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        data?.listProject?.length >= UIConstants.projectFetchLimit && fetchMoreProjects()
      }
    }
  }
  const handleToggle = () => setIsvisibleModal(!isVisibleModal)

  const refetchProjectList = () => {
    refetch({
      offset: 0,
      limit: UIConstants.projectFetchLimit,
      types: projectType === 'all' ? undefined : [projectType],
      status: activeTabKey === 'all' ? undefined : activeTabKey
    }).then((data) => {
      handleCreateList(data.data.listProject)
    })
  }
  const fetchMoreProjects = async () => {
    try {
      setLoadingMoreProjectStatus('loading')
      await fetchMoreProjectList(
        {
          variables: {
            offset: list?.length,
            limit: UIConstants.projectFetchLimit,
            types: projectType === 'all' ? undefined : [projectType],
            status: activeTabKey === 'all' ? undefined : activeTabKey
          }
        }
      ).then((data) => {
        setLoadingMoreProjectStatus(data.data?.listProject?.length < UIConstants.projectFetchLimit ? 'limited' : 'done')
        handleUpdateList([...data.data?.listProject])
      })
        .catch((error) => { console.log('error', error) })
    } catch (error) {
      setLoadingMoreProjectStatus('error')
      console.error(error)
    }
  }

  useEffect(() => {
    MenuList.forEach((element, idx) => {
      if (element.name === 'active') MenuList[idx].isFocused = true
      else MenuList[idx].isFocused = false
    })
  }, [])

  useEffect(() => {
    const activeTab = MenuList.find(item => item?.name === activeTabKey)
    const variableObj = {
      offset: 0,
      limit: UIConstants.projectFetchLimit
    }
    variableObj.status = activeTab.name
    setLoadingMoreProjectStatus('done')
  }, [activeTabKey, isVisibleModal])

  const HeaderDescription = () => (
    <Typography
      fontSize={14}
      lineHeight="20px"
      color={Colors.headingBlack}
      letterSpacing="0.15px">
      View all your campaigns
    </Typography>
  )

  const handelStatusChange = status => {
    if (projectType !== status) {
      setProjectType(status)
      setLoadingMoreProjectStatus('done')
      handleCreateList([])
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
  <Layout>
    <HeaderV2
      title={
        <Typography
          fontSize={34}
          fontWeight="bold"
          lineHeight="42px"
          letterSpacing="0.25px">
          Projects
        </Typography>
        }
        titleRight={
          <div className={style.Project__CreateProjectCTAWrapper}>
            <button className='primary-button' onClick={() => navigate('/projects/ugc/create')}>New ugc project</button>
            <button className='primary-button' onClick={handleToggle}>New casting project</button>
          </div>
        }
        description={<HeaderDescription />}
        bottom={
          <div className={style.Project__FilterWrapper}>
            <div className={style.Project__TabWrapper}>
              {MenuList.map(item => (
                <div
                  className={classNames(style.Project__TabItem, style[`Project__TabItem${item?.isFocused ? '--active' : ''}`])}
                  onClick={() => updateActiveSelect(item?.name)}
                  key={item.key}>
                  <span>
                    {item?.name.replaceAll('_', ' ')}
                  </span>
                </div>
              ))}
            </div>
            <div className={style.Project__FilterDropdown}>
              <Button
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                width='120px'
                backgroundColor='#00000080'
                outline>
                <span className={style.Project__FilterButtonText}>{projectType}</span>
                <img src={Images.resizeArrowdown} height={5} alt="arrow" style={{ rotate: open ? '180deg' : '0deg' }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={style.Project__FilterDropdownMenu}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                {projectTypes.map((item, index) => (
                <MenuItem key={index} selected={projectType === item.name} onClick={() => {
                  handleClose()
                  handelStatusChange(item.name)
                }}>{item.name}</MenuItem>)
                )}
              </Menu>
            </div>
          </div>
      }
    />

    <ProjectsWrapper
      data={list}
      loading={loading}
      handleScroll={handleScroll}
      loadingMoreProjectStatus={loadingMoreProjectStatus === 'loading'}
    />
    {isVisibleModal && (
      <TlyntSidebar width={'764px'}>
        <AddNewProject
          resetProjectList={() => refetchProjectList([])}
          handleToggle={handleToggle}
        />
      </TlyntSidebar>
    )}
  </Layout>
  )
}

export default Projects
