import { useMutation } from '@apollo/client'
import SelectAPI from '../../../services/Select/SelectAPI'

function useAddToRoleMutation () {
  // mutation
  const [AddToRoleMutation] = useMutation(SelectAPI.updateSelect(), {
    fetchPolicy: 'no-cache'
  })

  const addTalent = (selectData, setLoading, onSuccess, onError) => {
    setLoading(true)
    const roles = selectData.filter(select => select.talents.length > 0)
    Promise.all(roles.map((role) => AddToRoleMutation({
      variables: {
        id: role.id,
        talents: role.talents
      }
    })
    )).then(res => onSuccess())
      .catch(e => onError())
  }

  return {
    addTalent
  }
}

export default useAddToRoleMutation
