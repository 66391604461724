import { useContext, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { UIConstants } from '../../../../constants'
import { getProjectList } from '../../../../apis/project'
import ProjectsWrapper from '../../../Projects/Components/ProjectsWrapper'
import { ProjectListContext } from '../../../../contexts/project/projectList'

export const ProjectInfo = ({ advertiserId, projectStatus }) => {
  const [loadingMoreProjectStatus, setLoadingMoreProjectStatus] = useState('done')
  const { list, handleCreateList, handleUpdateList } = useContext(ProjectListContext)

  const { data, loading, fetchMore: fetchMoreProjectList } = useQuery(getProjectList, {
    fetchPolicy: 'no-cache',
    variables: {
      offset: 0,
      limit: UIConstants.projectFetchLimit,
      status: projectStatus === 'all' ? undefined : projectStatus,
      organizationId: advertiserId,
      withBrand: true
    }
  })

  useEffect(() => {
    if (!loading && data?.listProject?.length > 0) {
      handleCreateList(data.listProject)
    }
  }, [loading])

  useEffect(() => {
    handleCreateList([])
  }, [projectStatus])

  const handleScroll = cardViewRef => {
    const { scrollTop, scrollHeight, clientHeight } = cardViewRef.current
    if (loadingMoreProjectStatus === 'done' && cardViewRef.current) {
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        data?.listProject?.length >= UIConstants.projectFetchLimit && fetchMoreProjects()
      }
    }
  }

  const fetchMoreProjects = () => {
    try {
      setLoadingMoreProjectStatus('loading')
      fetchMoreProjectList({
        variables: {
          offset: list.length,
          limit: UIConstants.projectFetchLimit,
          status: projectStatus === 'all' ? undefined : projectStatus,
          organizationId: advertiserId,
          withBrand: true
        }
      }).then((data) => {
        setLoadingMoreProjectStatus(data.data?.listProject?.length < 4 ? 'limited' : 'done')
        handleUpdateList([...data.data?.listProject])
      }).catch(() => { setLoadingMoreProjectStatus('error') })
    } catch (error) {
      setLoadingMoreProjectStatus('error')
    }
  }

  return (
    <ProjectsWrapper
      data={list}
      loading={loading}
      handleScroll={handleScroll}
      loadingMoreProjectStatus={loadingMoreProjectStatus === 'loading'}
    />
  )
}

ProjectInfo.propTypes = {
  advertiserId: PropTypes.string,
  projectStatus: PropTypes.string
}
