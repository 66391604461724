import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'

import { Fonts } from '../../constants'
import NavBar from './Components/NavBar'

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`

const HeroSection = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
`

const NotFoundText = styled.p`
  font-family: ${Fonts.degular};
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.25px;
  text-align: left;
`

const Page404 = () => {
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')
  const message = searchParams.get('message')

  return (
    <Container>
      <NavBar />
      <HeroSection>
        <NotFoundText>
         {status && message ? `${status} ${message}` : '404 Page not found'}
        </NotFoundText>
      </HeroSection>
    </Container>
  )
}

export default Page404
