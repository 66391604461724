import { Fragment } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Collapse from '@mui/material/Collapse'

import { Images } from '../../../../assets'
import { Colors } from '../../../../constants'
import { getSidebarStatus } from '../../../../redux/actions/SidebarActions'
import { setActiveSidebarRoute } from '../../../../redux/actions/DashBoardActions'

import {
  NavItemImg,
  SubStyledNavLink,
  ParentMenuSpan,
  MenuArrow,
  SubMenuSpan,
  StyledNavLink
} from '../../Styles'

const Users = props => {
  // props
  const { isOpen, width, isSubMenuOpen, pathname } = props

  // hooks
  const dispatch = useDispatch()
  const { isSelectSubMenuOpen } = useSelector(state => state.SidebarReducer)
  const user = useSelector(state => state.UserReducer)

  // functions
  const resetActiveSidebarRoute = () => {
    dispatch(setActiveSidebarRoute(''))
  }

  const openSubMenu = () => {
    if (isOpen) {
      dispatch(
        getSidebarStatus({
          isOpen,
          width,
          isSubMenuOpen: !isSubMenuOpen,
          isSelectSubMenuOpen
        })
      )
    }
  }

  return (
    <Fragment>
      {user.isAdvertiser
        ? <StyledNavLink
          to={'/users/advertiser-user'}
          onClick={() => resetActiveSidebarRoute()}>
          <NavItemImg
            src={
              pathname === '/users/advertiser-user'
                ? Images.userActive
                : Images.userInActive
            }
          />
          <ParentMenuSpan
            color={pathname === '/users/advertiser-user'}>
            Users
          </ParentMenuSpan>
        </StyledNavLink>
        : <StyledNavLink
          to={isOpen ? '#' : '/users/talent'}
          onClick={() => {
            resetActiveSidebarRoute()
            openSubMenu()
          }}>
          <NavItemImg
            src={
              pathname === '/users/talent' ||
              pathname === '/users/advertiser-user' ||
              pathname.includes('/users/talent/')
                ? Images.userActive
                : Images.userInActive
            }
          />
          <ParentMenuSpan
            color={
              pathname === '/users/talent' ||
              pathname === '/users/advertiser-user' ||
              pathname.includes('/users/talent/')
                ? Colors.black
                : Colors.inactiveBlack
            }>
            Users
            <MenuArrow
              src={Images.arrowDropDown}
              isSubMenuOpen={isSubMenuOpen}
            />
          </ParentMenuSpan>
        </StyledNavLink>
      }
      {isOpen && (
        <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
          <SubStyledNavLink
            onClick={resetActiveSidebarRoute}
            to={'/users/talent'}
            state={location.pathname}>
            <div>
              <SubMenuSpan>Talents</SubMenuSpan>
            </div>
          </SubStyledNavLink>
          <SubStyledNavLink to={'/users/advertiser-user'}>
            <div>
              <SubMenuSpan>Advertisers</SubMenuSpan>
            </div>
          </SubStyledNavLink>
        </Collapse>
      )}
    </Fragment>
  )
}

Users.propTypes = {
  isOpen: PropTypes.bool,
  width: PropTypes.string,
  isSubMenuOpen: PropTypes.bool,
  pathname: PropTypes.string
}

export default Users
