import classNames from 'classnames'

import { Dialog } from '../Dialog'
import { Button } from '../buttonV2'
import style from './UGCConfirmationModal.module.scss'
import Close from '../../assets/images/UGCProject/Close.svg'

interface Props {
  open: boolean
  closeOfferedModal: () => void
  loading?: boolean
  title: string
  description: string
  handleBookingStatus: (status: string) => void
}

export const UGCConfirmationModal = (props: Props): JSX.Element => {
  const { open, closeOfferedModal, title, description, loading = false, handleBookingStatus } = props

  return (
    <div className={style.Dialog}>
      <Dialog open={open} handleClose={closeOfferedModal} size='xs'>
        <div className={style.Dialog__Wrapper}>
          <div className={style.Dialog__CloseIcon} onClick={closeOfferedModal}>
            <img src={Close} />
          </div>
          <h2 className={style.Dialog__Title}>{title}</h2>
          <p className={style.Dialog__Description}>{description}</p>
          <div className={style.Dialog__Footer}>
            <Button onClick={closeOfferedModal} backgroundColor={'white'}><span className={style.Dialog__BtnTxt}>Cancel</span></Button>
            <Button loading={loading} onClick={() => { handleBookingStatus('booking_requested') }}><span className={classNames(style.Dialog__BtnTxt, style['Dialog__BtnTxt--White'])}>Confirm</span></Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
