import { type ReactNode, useReducer, createContext, type Dispatch } from 'react'

export interface IProduct {
  id?: string
  url: string
  ownerId?: string
  description: string | null
  directions: string | null
  provisioningMethod: string | null
}

export interface IProductActions {
  id?: string
  url?: string
  ownerId?: string
  description?: string | null
  directions?: string | null
  provisioningMethod?: string | null
}

const productInitialData = {
  id: '',
  url: '',
  ownerId: '',
  description: null,
  directions: null,
  provisioningMethod: null
}
export const ProductContext = createContext<[IProduct, Dispatch<IProductActions>]>([productInitialData, () => {}])

interface ProductContextProps {
  children: ReactNode
}

export const ProductProvider = ({ children }: ProductContextProps): JSX.Element => {
  const [productData, updateProductData] = useReducer((prev: IProduct, next: IProductActions) => ({ ...prev, ...next }), { ...productInitialData })

  return (
    <ProductContext.Provider value={[productData, updateProductData]}>
      {children}
    </ProductContext.Provider>
  )
}
