import { useLazyQuery } from '@apollo/client'
import AdvertiserUserAPI from '../../../services/AdvertiserUser/AdvertiserUserAPI'

const useOrganizationListQuery = () => {
  const [fetchOrganizartionList, { data, loading }] = useLazyQuery(
    AdvertiserUserAPI.getOrganizationList(), {
      fetchPolicy: 'no-cache'
    }
  )
  return [fetchOrganizartionList, { data, loading }]
}

export default useOrganizationListQuery
