import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fragment, useRef } from 'react'

import { Form, Formik } from 'formik'
import { Box, Stack, Typography } from '@mui/material'

import { Button } from '../../../../components'
import { Colors, UIConstants } from '../../../../constants'
import useAddTalentMutation from '../../Hooks/useAddTalentMutation'
import CountryListData from '../../../../constants/CountryList.json'
import { FormikControl } from '../../../../components/formik/formikControl'
import UseSocialLinksFeature from '../../../../hooks/FeatureFlag/useSocialLinksFeature'
import { AddTalentValidationSchema } from '../../../../services/ValidationServices'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-inline: 40px;
  overflow-y: auto;
`
const PhoneContainer = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: row;
  align-items: flex-start;
`

const AddNewTlynt = ({ isVisibleModal, toggleModal }) => {
  // ref
  const formikRef = useRef(null)
  // api calls
  const [addTalent, { loading: addTalentLoading }] = useAddTalentMutation()

  // social link feature
  const socialLinksFeature = UseSocialLinksFeature()

  // constants
  const formattedCountryData = Object.keys(CountryListData).map(e => {
    const obj = {}
    obj.id = e
    obj.name = CountryListData[e]
    return obj
  }).filter((country) => country.id !== 'CA' && country.id !== 'US')

  // constants
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    countryCode: '0',
    phone: '',
    socialLinks: {
      instagram: null,
      facebook: null,
      tiktok: null,
      twitter: null,
      website: null
    },
    countryCodeLocation: JSON.stringify('US'),
    postalCode: ''
  }

  return (
    <Fragment>
      <Wrapper>
        <Box
          position="sticky"
          top={0}
          backgroundColor="white"
          zIndex={1}
          pt={3}>
          <Typography fontSize="34px" mb="15px" fontWeight="bold">
            {UIConstants.addTlynt}
          </Typography>
          <Typography fontSize="14px" color={Colors.headingBlack}>
            {UIConstants.addBasicDetail}
          </Typography>
        </Box>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={data => addTalent(data)}
          validationSchema={AddTalentValidationSchema}>
          {formik => (
            <Form>
              <FormikControl
                name="firstName"
                isStandard
                control="input"
                label="First Name"
                style={{ marginBottom: 20 }}
              />
              <FormikControl
                name="lastName"
                isStandard
                control="input"
                label="Last Name"
                style={{ marginBottom: 20 }}
              />
              <FormikControl
                name="email"
                isStandard
                control="input"
                label="Email"
                style={{ marginBottom: 20 }}
              />
              <Stack direction="row">
                <FormikControl
                  control="select"
                  label="Country"
                  name="countryCodeLocation"
                  isStandard
                  placeholder="countryCodeLocation"
                  containerStyle={{
                    marginRight: 10,
                    display: 'flex',
                    height: 50,
                    width: 100
                  }}
                  style={{ height: 100 }}
                  options={[{ id: 'US', name: 'Unites States' }, { id: 'CA', name: 'Canada' }].concat(formattedCountryData)}
                  selectProps={{
                    renderValue: (e) => JSON.parse(e)
                  }}
                  hidelabel
                />
                <FormikControl
                  name="postalCode"
                  isStandard
                  control="input"
                  label="Postal code"
                />
              </Stack>
              <PhoneContainer>
              <FormikControl
                  control="select"
                  label=""
                  name="countryCode"
                  isStandard
                  placeholder="CountryCode"
                  containerStyle={{ marginRight: 10, display: 'flex', height: 50, width: 100 }}
                  style={{ fontSize: 12, height: 100 }}
                  options={[{ id: 0, name: '+1 (US)' }]}
                  hidelabel
                />
                <FormikControl
                  name="phone"
                  isStandard
                  control="input"
                  label="Phone"
                />
              </PhoneContainer>
              {socialLinksFeature && (
                <>
                  <FormikControl
                    isStandard
                    control="input"
                    label="Instagram"
                    name="socialLinks.instagram"
                    placeholder="Instagram"
                    style={{ marginBottom: 20 }}
                  />
                  <FormikControl
                    isStandard
                    control="input"
                    label="Facebook"
                    name="socialLinks.facebook"
                    placeholder="Facebook"
                    style={{ marginBottom: 20 }}
                  />
                  <FormikControl
                    isStandard
                    control="input"
                    label="Twitter"
                    name="socialLinks.twitter"
                    placeholder="Twitter"
                    style={{ marginBottom: 20 }}
                  />
                  <FormikControl
                    isStandard
                    control="input"
                    label="Tiktok"
                    name="socialLinks.tiktok"
                    placeholder="Tiktok"
                    style={{ marginBottom: 20 }}
                  />
                  <FormikControl
                    isStandard
                    control="input"
                    label="Website"
                    name="socialLinks.website"
                    placeholder="Website"
                    style={{ marginBottom: 20 }}
                  />
                </>
              )}
            </Form>
          )}
        </Formik>
      </Wrapper>
      <Stack direction="row" justifyContent="end" padding="40px">
        <Button
          backgroundColor={'white'}
          onClick={() => toggleModal(!isVisibleModal)}
          minWidth={'100px'}>
          <Typography fontWeight="bold" letterSpacing="0.45px">
            CANCEL
          </Typography>
        </Button>
        <Button
          loading={addTalentLoading}
          onClick={() => formikRef?.current?.submitForm()}
          minWidth={'100px'}>
          <Typography fontWeight="bold" letterSpacing="0.45px" color="white">
            ADD TALENT
          </Typography>
        </Button>
      </Stack>
    </Fragment>
  )
}
export default AddNewTlynt

AddNewTlynt.propTypes = {
  mutation: PropTypes.object,
  isVisibleModal: PropTypes.bool,
  toggleModal: PropTypes.func
}
