import { Colors } from './Colors'

export const UIConstants = {
  TopMenu: [
    {
      name: 'Talent',
      routeName: 'talent',
      activeIcon: 'dashBoardIconActive',
      inActiveIcon: 'dashBoardIconInActive',
      isActiveMenu: true,
      pageInfo: 'View all the user registered with TLYNT here.',
      isSideBarItem: true,
      tabBarMenu: [
        {
          key: 1,
          name: 'Active',
          isFocused: true
        },
        {
          key: 2,
          name: 'Inactive',
          isFocused: false
        }
      ]
    },
    {
      name: 'Selects',
      routeName: 'selects',
      activeIcon: 'selectsIconActive',
      inActiveIcon: 'selectsIconInactive',
      isActiveMenu: false,
      pageInfo: 'View all the Selects with TLYNT here.',
      isSideBarItem: true,
      tabBarMenu: [
        {
          key: 1,
          name: 'Active',
          isFocused: true
        },
        {
          key: 2,
          name: 'Inactive',
          isFocused: false
        }
      ]
    },
    {
      name: 'Advertiser',
      routeName: 'advertiser',
      activeIcon: 'advertiserIconActive',
      inActiveIcon: 'advertiserIconInActive',
      isActiveMenu: false,
      pageInfo: 'View all the companies registered with TLYNT here.',
      isSideBarItem: false,
      tabBarMenu: [
        {
          key: 1,
          name: 'Active',
          isFocused: false
        },
        {
          key: 2,
          name: 'Inactive',
          isFocused: false
        },
        {
          key: 3,
          name: 'Requests',
          isFocused: false
        }
      ]
    },
    {
      name: 'Payments',
      routeName: 'payments',
      activeIcon: 'paymentsIconActive',
      inActiveIcon: 'paymentsIconInActive',
      isActiveMenu: false,
      pageInfo: 'TLYNT user and advertiser payment transition records',
      tabBarMenu: [],
      isSideBarItem: false
    },
    {
      name: 'Notifications',
      routeName: 'notifications',
      activeIcon: 'notificationsIconActive',
      inActiveIcon: 'notificationsIconInActive',
      isActiveMenu: false,
      pageInfo: 'TLYNT user and advertiser action updates',
      tabBarMenu: []
    }
  ],
  BottomMenu: [
    {
      name: 'Support',
      routeName: 'support',
      activeIcon: 'supportIconActive',
      inActiveIcon: 'supportIconInActive',
      isActiveMenu: false,
      pageInfo: 'TLYNT user and advertiser supports',
      isSideBarItem: false,
      tabBarMenu: []
    },
    {
      name: 'Settings',
      routeName: 'settings',
      activeIcon: 'settingIconActive',
      inActiveIcon: 'settingIconInActive',
      isActiveMenu: false,
      pageInfo: 'TLYNT company details',
      isSideBarItem: false,
      tabBarMenu: [
        {
          key: 1,
          name: 'Details',
          isFocused: false
        },
        {
          key: 2,
          name: 'Team',
          isFocused: false
        }
      ]
    },
    {
      name: 'Talent',
      routeName: 'talent',
      isSideBarItem: false,
      tabBarMenu: [
        {
          key: 1,
          name: 'Portfolio',
          isFocused: true
        },
        {
          key: 2,
          name: 'Personal Details',
          isFocused: false
        }
      ]
    }
  ],
  StackGridImages: [],
  UserInfoForm: [
    {
      key: 'firstname',
      labelName: 'First Name',
      type: 'text',
      placeHolder: 'Jane'
    },
    {
      key: 'lastname',
      labelName: 'Last Name',
      type: 'text',
      placeHolder: 'Doe'
    },
    {
      key: 'email',
      labelName: 'Email',
      type: 'email',
      placeHolder: 'jane@doe.com'
    },
    {
      key: 'phone',
      labelName: 'Phone',
      type: 'tel',
      placeHolder: '123 456 7890'
    },
    {
      key: 'location',
      labelName: 'Location',
      type: 'text',
      placeHolder: 'Los Angeles, CA'

    }
  ],
  CreateSelectsForm: [
    {
      key: 'name',
      labelName: 'Selects Name',
      type: 'text',
      placeHolder: 'Name'
    },
    {
      key: 'description',
      labelName: 'Description',
      type: 'textarea',
      placeHolder: 'Description'
    }
  ],
  Countries: [
    {
      key: 1,
      name: 'US',
      value: '+1 (US)',
      code: '+1'
    }
  ],
  SelectsDetails: [],
  ProjectMenu: [],
  searchBarPlaceHolder: 'Search for talents',
  TLYNT: 'TLYNT',
  defaultIndex: 0,
  Selects: 'selects',
  Advertiser: 'advertiser',
  Projects: 'projects',
  Payments: 'payments',
  Notifications: 'notifications',
  Support: 'support',
  Settings: 'settings',
  Talent: 'talent',
  Back: 'Back',
  addToCollection: 'Add to selects',
  deleteTlynt: 'Delete talent',
  portfolio: 'Portfolio',
  personalDetails: 'Personal Details',
  editDetails: 'Edit Details',
  saveDetails: 'Save details',
  submit: 'submit',
  cancel: 'Cancel',
  addTlynt: 'Add talent',
  addBasicDetail: 'Add basic details of model.',
  phoneNumber: 'phoneNumber',
  phone: 'phone',
  country: 'country',
  addTalentToSelects: 'Add Talent To Selects',
  addBasicDetailTlyntSelects: 'Add your selection of talent to a new or existing Select.',
  selectTlynt: 'Select talent',
  addToSelect: 'Add to selects',
  Cancel: 'Cancel',
  add: 'Add',
  createNewSelects: 'Create New Selects',
  duplicateSelects: 'Duplicates Selects',
  addBasicDetailCreateSelects: 'Add basic details of Selects',
  createSelects: 'Create Selects',
  addTalentsToSelectsSuccessMessage: 'You\'ve successfully added talent to selects.',
  addSelect: 'You\'ve successfully created select.',
  addRole: 'You\'ve successfully added new roles.',
  deleteSelect: 'You\'ve successfully deleted select.',
  clearVote: 'You\'ve successfully clear all votes for Talent.',
  addNewSelectsButtonText: 'Add New Selects',
  save: 'Save',
  editSelects: 'Edit Selects',
  editBasicDetailSelects: 'Edit basic details of Selects.',
  addTlyntButton: '+ Add talent',
  addNewRoleText: '+ Add New Role',
  addNewRoleHeading: 'Add New Role',
  addMoreRoleText: '+ Add more Role',
  addRoleText: 'Add Role',
  roleNameText: 'Role Name',
  listOfSelects: 'List Of Selects',
  addNewRolePlaceholder: 'Add new role here',
  addNewPhotos: 'Add New Photos',
  hidden: 'Hidden',
  deleteImage: 'Delete Image',
  share: 'Share',
  edit: 'Edit',
  shareSelectsHeading: 'Share Selects',
  shareSelectsDescription: 'You can copy the link and share it with anyone normally or with password protection.',
  copy: 'Copy',
  copyLink: 'Copy Link',
  delete: 'Delete',
  isDeleteSelect: 'Are you sure you want to delete this Select?',
  deleteSelectDescription: 'This Select will be deleted permanently',
  role: 'Role',
  deleteSuccessMessage: 'You\'ve successfully deleted Select.',
  retry: 'Retry',
  error: 'Error',
  talentFetchLimit: 10000,
  selectFetchLimit: 20,
  projectFetchLimit: 12
}

export const ProjectConstants = {
  Project: {
    name: 'Projects',
    routeName: 'projects',
    activeIcon: 'projectsIconActive',
    inActiveIcon: 'projectsIconInActive',
    isActiveMenu: true,
    pageInfo: 'View all the projects created by all registered advertisers',
    isSideBarItem: false,
    addProjectText: 'Add New Project',
    cancelButton: 'CANCEL',
    nextButton: 'NEXT',
    successStatus: 'Project status updated successfully.',
    deleteStatus: 'Project deleted successfully.',
    roleDeleteStatus: 'Project role deleted successfully.',
    addToRoleSuccess: 'Talent added to role successfully.',
    tabBarMenu: [
      {
        key: 1,
        name: 'all',
        isFocused: false
      },
      {
        key: 2,
        name: 'draft',
        isFocused: false
      },
      {
        key: 3,
        name: 'active',
        isFocused: true
      },
      {
        key: 4,
        name: 'completed',
        isFocused: false
      },
      {
        key: 5,
        name: 'cancelled',
        isFocused: false
      }
    ],
  },
  ProjectDetail: {
    tabBarMenu: [
      {
        id: 1,
        key: 'projectOverview',
        name: 'Project Overview',
        isFocused: true
      },
      {
        id: 2,
        key: 'roleDetails',
        name: 'Role Details',
        isFocused: false
      },
      {
        id: 3,
        key: 'projectTalent',
        name: 'Talent',
        isFocused: false
      }
    ]
  }
}

export const TalentConstants = {
  Talent: {
    tabTitleList: [
      {
        key: 0,
        name: 'With Pictures'
      },
      {
        key: 1,
        name: 'Without Pictures'
      },
      {
        key: 2,
        name: 'With Videos'
      }
    ]
  },
  UserTalent: {
    tabTitleList: [
      {
        key: 0,
        name: 'All Talents'
      },
      {
        key: 'flagged',
        name: 'Flagged'
      },
      {
        key: 'disabled',
        name: 'Disabled'
      }
    ]
  },
  UpdateTalentsuccess: 'Talent status Updated Successfully.',
  UpdateProfilePictureSuccess: 'Profile picture updated! It can take up to 24hrs for it to be updated everywhere.'
}

export const AdvertiserConstants = {
  randomUserColor: [
    Colors.nonPhotoBlue,
    Colors.lightBlue,
    Colors.celeste,
    Colors.vividTangerine,
    Colors.vodka,
    Colors.menthol,
    Colors.Dandelion,
    Colors.aeroBlue,
    Colors.shampoo
  ]
}
