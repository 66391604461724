import { DashboardTypes } from '../types/DashboardTypes'
import types from '../types/types'

// handling toast
export const showToast = data => ({
  type: types.SHOW_TOAST,
  payload: data
})

export const hideToast = () => ({
  type: types.HIDE_TOAST
})

// handling talent with and without pictures
export const setTalentsWithPictures = data => ({
  type: DashboardTypes.SET_TALENTS_WITH_PICTURES,
  payload: data
})

export const setTalentsWithVideos = data => ({
  type: DashboardTypes.SET_TALENTS_WITH_VIDEOS,
  payload: data
})

export const setTalentsWithoutPictures = data => {
  return ({
    type: DashboardTypes.SET_TALENTS_WITHOUT_PICTURES,
    payload: data
  })
}

// handling user talents
export const setFlaggedTalents = data => ({
  type: DashboardTypes.SET_FLAGGED_TALENTS,
  payload: data
})

export const setDisabledTalents = data => ({
  type: DashboardTypes.SET_DISABLED_TALNETS,
  payload: data
})

export const setAllTalents = data => ({
  type: DashboardTypes.SET_ALL_TALNETS,
  payload: data
})

// handling active tab index of talents
export const setActiveTalentTabIndex = (data) => ({
  type: DashboardTypes.SET_ACTIVE_TALENT_TAB_INDEX,
  payload: data
})

// handling active sidebar route, so that when user navigate from
// talents to user talents it starts from top
export const setActiveSidebarRoute = (data) => ({
  type: DashboardTypes.SET_ACTIVE_SIDEBAR_ROUTE,
  payload: data
})

// handling talent card size number
export const setTalentCardResizeData = (data) => ({
  type: DashboardTypes.SET_TALENT_CARD_RESIZE_DATA,
  payload: data
})
