import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import styled, { keyframes } from 'styled-components'

import TalentCard from '../TalentCard'
import { RenderIf } from '../../../../utils/Helpers'
import { AddToRole, TlyntSidebar } from '../../../../components'
import useAddToRoleMutation from '../../Hooks/useAddToRoleMutation'
import useCombineWithMedia from '../../Hooks/useCombineWithMedia'

export const TalentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${props => `calc(24px * ${props['data-size']}/ 220)`} ;
  grid-template-columns: ${props =>
    `repeat(auto-fill, minmax(${props['data-size']}px, 1fr))`};
`

const Wrapper = styled.div`
  height: 100%;
  padding: 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  justify-content: flex-start;
`
const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
const ShimmerBox = styled.div`
  height: 350px;
  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const ProjectTalent = props => {
  const { projectId, selectId } = props
  // Hooks
  const { dataWithMedia: talentData, loading: talentLoading } = useCombineWithMedia({ projectId, selectId })
  const { addTalent, addTalentLoading } = useAddToRoleMutation()

  // state
  const [talentId, setTalentId] = useState()
  const [sortedTalents, setSortedTalents] = useState([])
  const [addToRoleModalVisible, setAddToRoleModalVisible] = useState(false)
  const shimmerCount = useState(
    new Array(6).fill(0).map((_, index) => ({ id: index }))
  )[0]

  const handelToggleSidebar = id => {
    setTalentId(id)
    setAddToRoleModalVisible(!addToRoleModalVisible)
  }

  useEffect(() => {
    if (!talentLoading && talentData) {
      setSortedTalents(talentData)
    }
  }, [talentData, talentLoading])

  // change position of new selected talent while update
  const setSelectedTalent = () => {
    const newSortedData = sortedTalents
    const index = newSortedData.findIndex(e => e.id === talentId)
    const updatedTlent = newSortedData.splice(index, 1)[0]
    updatedTlent.isAddedtoRole = true

    newSortedData.splice(0, 0, updatedTlent)
    setSortedTalents(newSortedData)
  }

  return (
    <Wrapper>
      <RenderIf isTrue={!talentLoading && sortedTalents && sortedTalents?.length === 0}>
        <Typography fontSize='36px' fontWeight='bold' textAlign='center' width='100%' mt={3}>
          No talent have shown interest yet.</Typography>
      </RenderIf>
      <TalentContainer data-size={220}>
      {(!talentLoading && sortedTalents)
        ? sortedTalents?.length > 0 &&
          sortedTalents.map((talent, index) => (
            <TalentCard
              key={index}
              item={talent}
              handelToggleSidebar={handelToggleSidebar}
            />
          ))
        : shimmerCount.map(item => <ShimmerBox key={item.id} />)}
      </TalentContainer>
      <RenderIf isTrue={addToRoleModalVisible}>
        <TlyntSidebar direction="right">
          <AddToRole
            selectId={selectId}
            talentId={talentId}
            addTalent={addTalent}
            setSortedData={setSelectedTalent}
            addTalentLoading={addTalentLoading}
            handelToggleSidebar={handelToggleSidebar}
          />
        </TlyntSidebar>
      </RenderIf>
    </Wrapper>
  )
}

ProjectTalent.propTypes = {
  projectId: PropTypes.string,
  selectId: PropTypes.string
}

export default ProjectTalent
