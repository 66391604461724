import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import type { KeyboardEvent, ReactNode } from 'react'
import moment from 'moment'
import * as z from 'zod'

import { Drawer } from '../Drawer'
import ArrowBackward from '../../assets/images/Projects/ArrowBackward.svg'
import style from './UGCAssetDetailDrawer.module.scss'
import VideoJS from '../VideoJS'
import InfoIcon from '../../assets/images/UGCProject/Info.svg'
import { RenderIf } from '../../utils/Helpers'
import images from '../../assets/images'
import { InputBox } from '../InputBox'
import { SpinnerSmall } from '../Progress'
import { useGetMediaFull } from '../../pages/TalentDetailMain/Hooks/useGetMediaById'
import { API } from '../../constants'

const feedbackValidationSchema = z.object({
  feedback: z.string().trim().min(1, 'Feedback should be greater than 1 character').max(2048, 'Feedback should be less than 2048 characters')
})

type IValidationError = Record<string, string | undefined>

interface Deliverable {
  deliverableId: string
  version: number
  updatedAt: string
  createdAt: string
  feedback: string[] | null
  media: Media
  status: string
  mediaId: string
}

interface Media {
  uris: Uris
}

interface Uris {
  poster: string
  stream: string
  full: string
}

const DownloadButton = ({ assetId, children }: { assetId?: string, children: ReactNode }): JSX.Element => {
  const [asset, setAsset] = useState({ id: assetId, loaded: false, file: '' })
  const { getMedia } = useGetMediaFull()

  useEffect(() => {
    async function fetchMedia (): Promise<void> {
      const res = await getMedia({
        variables: {
          id: assetId
        }
      })

      const details = res?.data?.mediaGet

      if (details?.file !== null && details?.processing === 'complete') {
        setAsset({ ...res?.data?.mediaGet, loaded: true })
      }
    }

    if (asset?.id !== undefined && !asset?.loaded) {
      fetchMedia().catch(console.error)
    }
  }, [asset])

  return (
    <a href={`${API.cdn as string}/${asset.file}`} download className={classNames(style.Drawer__AssetDownload)}>{ children }</a>
  )
}

// Not using React component and BEM styling as video js expect the watermark to be in inline HTML, also not using modular global scss
// because that will be applied to all the video js instances whether they have watermark or not
const TLYNTWATERMARK = `<div style="height: inherit; justify-content: center; align-items: center; display: flex;"><img alt="watermark" src=${images.tlyntWatermark as string} /></div>`

const CloseIcon = (): JSX.Element => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1663 7.47837L20.5213 5.83337L13.9997 12.355L7.47801 5.83337L5.83301 7.47837L12.3547 14L5.83301 20.5217L7.47801 22.1667L13.9997 15.645L20.5213 22.1667L22.1663 20.5217L15.6447 14L22.1663 7.47837Z" fill="black" />
    </svg>
  )
}

const CrossIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
    </svg>
  )
}

const CheckIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" fill="white" />
    </svg>

  )
}
interface Props {
  open: boolean
  isAdmin: boolean
  handleCloseDrawer: () => void
  deliverableData: Deliverable[] | null
  isPhoto: boolean
  handleUpdateDeliverable: (payload: { deliverableVersion: number, status: string, deliverableId: string, feedback?: string[] }, cb?: () => void) => void
  updateProjectTalentSubmissionLoading: boolean
  isLandscape: boolean
  iteration: number
  assetNumber: number
}

interface AssetRenderProps {
  assetImageURL?: string
  isPhoto: boolean
  isLandscape: boolean
  videoPlayerRef: React.MutableRefObject<any>
  assetVideoURL?: string
}

/**
 * @param props - AssetRenderProps
 * @returns JSX.Element - AssetRender
 * @description This Component is used to render the asset in the detail drawer.
 */
const AssetRender = (props: AssetRenderProps): JSX.Element => {
  const { assetImageURL, isPhoto, isLandscape, videoPlayerRef, assetVideoURL } = props

  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const videoJsOptions = useMemo(() => ({
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: false,
    fill: true,
    preload: 'auto',
    controlBar: { pictureInPictureToggle: false },
    sources: [
      {
        src: assetVideoURL,
        type: 'application/x-mpegURL'
      }
    ],
    bigPlayButton: false
  }), [assetVideoURL])

  if (isPhoto) {
    return (
      <div className={style.Drawer__MainPhotoWrapper}>
        <>
          <img onLoad={() => { setIsLoaded(true) }} onError={() => { setIsError(true) }} className={classNames(style.Drawer__MainPhoto, isLandscape ? style['Drawer__MainPhoto--Landscape'] : '')} src={(isLoaded || !isError) ? assetImageURL : images.imagePlaceholder} />
          <RenderIf isTrue={isLoaded}>
            <div className={style.Drawer__Watermark}>
              <img alt="watermark" src={images.tlyntWatermark} />
            </div>
          </RenderIf>
        </>
      </div>
    )
  } else {
    return (
      <div className={classNames(style.Drawer__MainVideo, isLandscape ? style['Drawer__MainVideo--Landscape'] : '')}>
        <VideoJS ref={videoPlayerRef} overlayContent={TLYNTWATERMARK} showWatermark options={videoJsOptions} />
      </div>
    )
  }
}
export const UGCAssetDetailDrawer = (props: Props): JSX.Element => {
  // props
  const { isAdmin, open, handleCloseDrawer, deliverableData, isPhoto, handleUpdateDeliverable, updateProjectTalentSubmissionLoading, isLandscape, iteration, assetNumber } = props

  const user = useSelector((state: any) => state.UserReducer)

  // Refs
  const clickedCTAType = useRef<null | 'approve' | 'rejected'>(null)
  const videoPlayerRef = useRef<null | any>(null)

  // States
  const [activeDeliverableIndex, setActiveDeliverableIndex] = useState(0)
  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [feedbackError, setFeedbackError] = useState<IValidationError>({})

  // Constants
  const deliverableVersion = deliverableData?.[activeDeliverableIndex]?.version
  const isCurrentVersion = deliverableData?.length !== deliverableVersion
  const status = deliverableData?.[activeDeliverableIndex]?.status

  // shallow copying the array via slice() and then sorting it
  const sortedDeliverableByVersion = deliverableData?.slice()?.sort((a, b) => a?.version > b.version ? 1 : -1) ?? []
  const isLastDeliverableRejected = sortedDeliverableByVersion[sortedDeliverableByVersion.length - 1]?.status === 'rejected'

  const revisionLeftCount = (Number(iteration + 1) - (deliverableData?.length ?? 0) - (isLastDeliverableRejected ? 1 : 0)) > 0
    ? Number(iteration + 1) - (deliverableData?.length ?? 0) - (isLastDeliverableRejected ? 1 : 0)
    : 0

  const approvedDeliverableIndex = deliverableData?.findIndex((deliverable) => deliverable.status === 'approved')

  // Effect that is responsible for to set the active deliverable index when there is one approved deliverable
  useEffect(() => {
    if (approvedDeliverableIndex !== -1) {
      setActiveDeliverableIndex(approvedDeliverableIndex ?? 0)
    }
  }, [approvedDeliverableIndex])

  /**
   * @description This function is used to set the active deliverable video url
   */
  const assetVideoURL = useMemo(() => (
    deliverableData?.[activeDeliverableIndex]?.media?.uris?.stream
  ), [deliverableData, activeDeliverableIndex])

  /**
   * @description This function is used to toggle the video
   * @param type - next or prev
   */
  const handleToggleVideo = (type: 'next' | 'prev' | 'space'): void => {
    setIsRejectButtonClicked(false)
    if (type === 'next' && deliverableData?.[activeDeliverableIndex - 1] !== undefined) {
      setActiveDeliverableIndex(activeDeliverableIndex - 1)
    } else if (type === 'prev' && deliverableData?.[activeDeliverableIndex + 1] !== undefined) {
      setActiveDeliverableIndex(activeDeliverableIndex + 1)
    } else if (type === 'space') {
      if (videoPlayerRef?.current !== null) {
        videoPlayerRef.current.togglePlay()
      }
    }
  }

  /**
   * @description This function is used to handle the keyboard navigation
   * @param e - KeyboardEvent
   */
  const handleKeyboardKeyPressed = (e: KeyboardEvent<HTMLImageElement>): void => {
    // Check if the event target is an input element
    const isTextInput = e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement

    if (isTextInput) {
      return // Do not handle keyboard shortcuts if the target is a text input
    }

    if (e.code === 'ArrowRight') {
      handleToggleVideo('next')
    } else if (e.code === 'ArrowLeft') {
      handleToggleVideo('prev')
    } else if (e.code === 'Space') {
      if (videoPlayerRef?.current !== null) {
        videoPlayerRef.current.togglePlay()
      }
    } else if (e.code === 'Escape') {
      handleCloseDrawer()
    }
  }

  /**
   * @description This function is used to handle the submit feedback and perform the validation
   */
  const handleSubmitFeedback = (): void => {
    const { version, deliverableId } = deliverableData?.[activeDeliverableIndex] as Deliverable
    clickedCTAType.current = 'rejected'

    const result = feedbackValidationSchema.safeParse({ feedback })
    if (result.success) {
      setFeedbackError({})
      if (deliverableData !== null && feedback !== null) {
        const payload = {
          deliverableVersion: version,
          status: 'rejected',
          deliverableId,
          feedback: feedback.split('\n').filter(str => str !== '')
        }
        handleUpdateDeliverable(payload, () => {
          setIsRejectButtonClicked(false)
          setFeedback('')
        })
      }
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          errValues[item.path.join('.')] = item.message
        })

        setFeedbackError({ ...feedbackError, ...errValues })
      }
    }
  }

  const RenderTag = (props: { status?: string }): JSX.Element => {
    switch (props.status) {
      case 'rejected':
        return (
          <span className={classNames(style.Drawer__TitleTag, style['Drawer__TitleTag--Orange'])}>
            Revision Requested
          </span>
        )
      case 'submitted':
        return (
          <span className={classNames(deliverableData?.length !== deliverableVersion ? style['Drawer__TitleTag--Hidden'] : style.Drawer__TitleTag)}>
            Current
          </span>
        )
      case 'approved':
        return (
          <span className={classNames(style.Drawer__TitleTag, style['Drawer__TitleTag--Green'])}>
            Approved
          </span>
        )
      default:
        return <></>
    }
  }
  /**
   * @description This function is used to approve the deliverable
   */
  const handleApproveDeliverable = (): void => {
    const { version, deliverableId } = deliverableData?.[activeDeliverableIndex] as Deliverable
    clickedCTAType.current = 'approve'

    const payload = {
      deliverableVersion: version,
      status: 'approved',
      deliverableId
    }
    handleUpdateDeliverable(payload)
  }

  return (
    <Drawer open={open} handleNavigate={handleKeyboardKeyPressed} onClose={handleCloseDrawer}>
      <div className={style.Drawer}>
        <header className={style.Drawer__Header}>
          <h2>{isPhoto ? 'Photo' : 'Video'} {assetNumber}</h2>
          <div className={style.Drawer__HeaderClose} onClick={handleCloseDrawer} >
            <CloseIcon />
          </div>
        </header>
        <div className={style.Drawer__Main}>
          <article>
            <div className={style.Drawer__MainControls}>
              <img onClick={() => { handleToggleVideo('prev') }} className={style.Drawer__MainControlArrow} src={ArrowBackward} />
              <div className={style.Drawer__MainVideoInfo}>
                <p className={style.Drawer__MainVideoTitleText}>Version {deliverableVersion}
                  <RenderTag status={status} />
                </p>
                <p className={style.Drawer__MainVideoDateText}>{moment(deliverableData?.[activeDeliverableIndex]?.createdAt).format('MMM DD, YYYY')}</p>
              </div>
              <img onClick={() => { handleToggleVideo('next') }} className={classNames(style.Drawer__MainControlArrow, style['Drawer__MainControlArrow--Right'])} src={ArrowBackward} />
            </div>
            <AssetRender assetImageURL={deliverableData?.[activeDeliverableIndex]?.media?.uris?.full} isPhoto={isPhoto} isLandscape={isLandscape} videoPlayerRef={videoPlayerRef} assetVideoURL={assetVideoURL} />
            <RenderIf isTrue={isCurrentVersion || status === 'rejected'}>
              <section className={style.Drawer__Feedback}>
                <h4 className={style.Drawer__FeedbackTitle}>Feedback</h4>
                {deliverableData?.[activeDeliverableIndex]?.feedback?.map((feedback) => <p key={feedback} className={style.Drawer__FeedbackTitleText}>{feedback}</p>) ?? '-'}
                <p className={style.Drawer__FeedbackAgoText}>{moment(deliverableData?.[activeDeliverableIndex]?.updatedAt).fromNow()}</p>
              </section>
            </RenderIf>
            <RenderIf isTrue={isRejectButtonClicked}>
              <section className={style.Drawer__Feedback}>
                <h4 className={style.Drawer__FeedbackTitle}>Feedback</h4>
                <InputBox isMultiline isError={Object.keys(feedbackError).length > 0} errorText={feedbackError.feedback} acceptedLength={2048} value={feedback} onChange={(e) => { setFeedback(e?.target?.value) }} placeholder='Leave a feedback' />
              </section>
            </RenderIf>
          </article>
        </div>
        <div className={style.Drawer__Footer}>
          <RenderIf isTrue={user.isAdmin}>
            <div className={style.Drawer__FooterAdminActions}>
              <DownloadButton assetId={deliverableData?.[activeDeliverableIndex]?.mediaId}>
                DOWNLOAD ASSET
              </DownloadButton>
            </div>
          </RenderIf>
          <RenderIf isTrue={status !== 'approved'}>
            <div className={style.Drawer__FooterRevision}>
              <img src={InfoIcon} />
              <p className={style.Drawer__FooterRevisionText}>{revisionLeftCount} Revision Available</p>
            </div>
          </RenderIf>
          <RenderIf isTrue={isAdmin && status !== 'approved' && approvedDeliverableIndex === -1}>
            <div className={style.Drawer__FooterCTAContainer}>
              <button onClick={() => { setIsRejectButtonClicked(false) }} className={classNames('primary-text-button', isRejectButtonClicked ? '' : style.Hidden)}>
                <p>Cancel</p>
              </button>
              <button
                onClick={() => { !isRejectButtonClicked ? setIsRejectButtonClicked(true) : handleSubmitFeedback() }}
                className={classNames('error-button', isCurrentVersion || status === 'rejected' ? style.Hidden : '', feedback === '' && isRejectButtonClicked ? 'error-button--Disabled' : '')}>
                {updateProjectTalentSubmissionLoading && clickedCTAType.current === 'rejected' ? <SpinnerSmall /> : <CrossIcon />}
                <p>Reject</p>
              </button>
              <button onClick={handleApproveDeliverable} className={classNames('success-button', isRejectButtonClicked ? style.Hidden : '')}>
                {updateProjectTalentSubmissionLoading && clickedCTAType.current === 'approve' ? <SpinnerSmall /> : <CheckIcon />}
                <p>Accept</p></button>
            </div>
          </RenderIf>
        </div>
      </div>
    </Drawer>
  )
}
