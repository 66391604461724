import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { formatProjectTalentDateAndTime } from '../../../utils/ProjectHelper'
import { RenderIf, calculateTimeLeftFrom24Hour } from '../../../utils/Helpers'
import { VoteBtnContent } from '../../../pages/PublicSelect/Components/TalentCard'
import { VoteList } from '../../VoteList'
import images from '../../../assets/images'
import style from './UGCTalentCard.module.scss'
import TimerIcon from '../../../assets/images/UGCProject/TimerIcon.svg'
import { useSelector } from 'react-redux'
import { type UserState } from '../../../redux/reducers/user'

/**
  * Used to display the status tag on the talent card
  * @param {boolean} showTag - boolean to show the tag
  * @param {string} status - status of the talent
  * @returns {string} - string to display on the tag
*/
const displayStatus = (showTag: boolean, status: string): string | undefined => {
  if (showTag) {
    switch (status) {
      case 'booking_requested':
        return 'Booking Offered'
      case 'booking_confirmed':
        return 'Booked'
      case 'selected':
        return 'Selected'
      case 'wrapped':
        return 'Wrapped'
      default:
        return ''
    }
  }
}

/**
  * Used to render the timer on the talent card
  * @param {string} timeLeft - time left for the booking to expire
  * @returns {JSX.Element} - JSX Element to render the timer
*/

const RenderTimer = (props: { timeLeft: string }): JSX.Element => {
  const { timeLeft } = props
  return (
    <>
      <img src={TimerIcon} />
      <p className={style.Card__TagText}>Booking Offered <br /> <span className={style['Card__TagText--Bold']}>{timeLeft}</span> Left</p>
    </>
  )
}

/**
  * Used to render the status tag on the talent card
  * @param {boolean} showTag - boolean to show the tag
  * @param {string} status - status of the talent
  * @param {string} lastUpdated - time when the booking was offered
  * @returns {JSX.Element} - JSX Element to render the status tag
*/

const DisplayTag = (props: { status: string, lastUpdated?: string, showTag: boolean }): JSX.Element => {
  const { status, lastUpdated, showTag } = props
  switch (status) {
    case 'booking_requested':
      return (
        <div className={classNames(style.Card__Tag, style['Card__Tag--Orange'], calculateTimeLeftFrom24Hour(lastUpdated) !== 'expired' ? style['Card__Tag--Timer'] : '')}>
          {calculateTimeLeftFrom24Hour(lastUpdated) === 'expired' ? <span className={style.Card__TagText}>Expired</span> : <RenderTimer timeLeft={calculateTimeLeftFrom24Hour(lastUpdated)} />}
        </div>
      )
    case 'booking_confirmed':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Green'])}><span className={style.Card__TagText}>{displayStatus(showTag, status)}</span></div>
    case 'selected':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Blue'])}><span className={style.Card__TagText}>{displayStatus(showTag, status)}</span></div>
    case 'wrapped':
      return <div className={classNames(style.Card__Tag, style['Card__Tag--Pink'])}><span className={style.Card__TagText}>{displayStatus(showTag, status)}</span></div>
    default:
      return <div className={classNames(style.Card__Tag)}><span className={style.Card__TagText}>{displayStatus(showTag, status)}</span></div>
  }
}

/**
  * Used to render the talent card thumbnail
  * @param {string} videoThumbnail - thumbnail of the talent card
  * @param {boolean} hasVideos - boolean to show the play button
  * @returns {JSX.Element} - JSX Element to render the talent card thumbnail
*/

export const whatThumbnailToUse = (hasAudition: boolean, auditionURIs: Record<string, string> | undefined, videoProfileURIs: Record<string, string> | undefined, photoProfileURIs: Record<string, string> | undefined): string => {
  if (hasAudition && auditionURIs?.poster !== undefined) {
    return auditionURIs?.poster
  } else if (videoProfileURIs?.poster !== undefined) {
    return videoProfileURIs?.poster
  } else if (photoProfileURIs?.card !== undefined) {
    return photoProfileURIs?.card
  } else {
    return images.tlyntPlaceholder
  }
}

interface TalentCardThumbnailProps {
  videoThumbnail: string
  hasVideos?: boolean
}

const TalentCardThumbnail = (props: TalentCardThumbnailProps): JSX.Element => {
  const { videoThumbnail, hasVideos = true } = props
  const [imgSource, setImgSource] = useState(videoThumbnail)
  useEffect(() => {
    setImgSource(videoThumbnail)
  }, [videoThumbnail])

  return (
    <picture>
      <div className={style.Card__Overlay}>
        <RenderIf isTrue={hasVideos}>
          <div className={style.Card__Play}>
            <div className={style.Card__PlayTriangle} />
          </div>
        </RenderIf>
      </div>
      <source srcSet={imgSource} type='image/webp' />
      <img alt='' loading='lazy' className={style.Card__Photo} onError={() => { setImgSource(images.tlyntPlaceholder) }} />
    </picture>
  )
}

interface RenderLinkProps {
  id?: string
  hasRedirectLink: boolean
  children: JSX.Element
}

const RenderLink = (props: RenderLinkProps): JSX.Element => {
  const { id, hasRedirectLink, children } = props
  if (id !== undefined && hasRedirectLink) {
    return <Link to={`/talent/${id}`} target="_blank">{children}</Link>
  } else {
    return <>{children}</>
  }
}

interface UGCTalentCardProps {
  id?: string
  firstname?: string
  lastname?: string
  showTag: boolean
  lastUpdated?: string
  profilePhoto: string | null
  videoThumbnail: string
  status?: string | null
  isLandscape?: boolean
  onClick?: () => void
  hideInfo?: boolean
  isSelect?: boolean
  voteData?: any
  handleActionOnCard?: (arg1: { id?: string }, arg2: () => void) => void
  hasRedirectLink?: boolean
  isPublicSelect?: boolean
  selectId?: string
  childSelectId?: string
  isLiked?: boolean
  hasVideos?: boolean
  handleAddVote?: (arg1: () => void, arg2: string) => void
  handleRemoveVote?: (arg1: () => void, arg2: string) => void
  voteLoading?: boolean
  isTalentDeleted?: boolean
}

export const UGCTalentCard = (props: UGCTalentCardProps): JSX.Element => {
  // init
  const {
    id = '', firstname, lastname, showTag, lastUpdated, profilePhoto, videoThumbnail, status, isLandscape = false, onClick, hideInfo = false, isSelect = false, handleAddVote, handleRemoveVote, voteLoading,
    handleActionOnCard = () => null, voteData, hasRedirectLink = false, isPublicSelect = false, selectId, childSelectId, isLiked = false, hasVideos = true, isTalentDeleted = false
  } = props

  const user: UserState = useSelector((state: any) => state.UserReducer)

  // states
  const [loading, setLoading] = useState(false)
  const [likedState, setLikedState] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(true)

  useEffect(() => {
    isLiked !== undefined && setLikedState(isLiked)
  }, [isLiked])

  const handleSuccess = (): void => { setLikedState(!likedState) }

  const handleUserName = (firstname: string | undefined | null, lastname: string | undefined | null, isDeleted: boolean): string | JSX.Element | undefined => {
    if (isDeleted) {
      return <>[ Deleted User ]</>
    } else if (firstname !== undefined && lastname !== undefined && firstname !== null && lastname !== null && firstname?.length > 0 && lastname?.length > 0) {
      return `${firstname} ${lastname}`
    } else if (firstname !== undefined && firstname !== null && firstname?.length > 0) {
      return firstname
    } else if (lastname !== undefined && lastname !== null && lastname?.length > 0) {
      return lastname
    }
  }

  const handleInitials = (firstname: string | undefined | null, lastname: string | undefined | null, isDeleted: boolean): string | undefined => {
    if (isDeleted) {
      return '␡'
    } else if (firstname !== undefined && lastname !== undefined && firstname !== null && lastname !== null && firstname?.length > 0 && lastname?.length > 0) {
      return `${firstname.charAt(0)}${lastname.charAt(0)}`
    } else if (firstname !== undefined && firstname !== null && firstname?.length > 0) {
      return firstname.charAt(0)
    } else if (lastname !== undefined && lastname !== null && lastname?.length > 0) {
      return lastname.charAt(0)
    }
  }

  return (
    <article onClick={(typeof onClick === 'function' && !isTalentDeleted) ? onClick : undefined} className={classNames(style.Card, isTalentDeleted ? style.Cursor__Disabled : '', isLandscape ? style['Card--Landscape'] : '')}>
      <RenderIf isTrue={isSelect}>
        <VoteList voteData={voteData} />
        <div className={style.Card__CloseButton}
          onClick={e => {
            e.stopPropagation()
            handleActionOnCard({ id }, () => { setLoading(false) })
            setLoading(true)
          }}>
          {loading ? <CircularProgress size='15px' color='inherit' /> : <img src={images.crossWhite} className={style.Card__CrossIcon} />}
        </div>
      </RenderIf>
      <RenderLink id={id} hasRedirectLink={hasRedirectLink}>
        <TalentCardThumbnail videoThumbnail={videoThumbnail} hasVideos={hasVideos} />
      </RenderLink>
      {showTag && !hideInfo && status !== null && status !== undefined && <DisplayTag status={status} lastUpdated={lastUpdated} showTag />}
      <RenderIf isTrue={!hideInfo}>
        <div className={style.Card__Link}>
          <div className={classNames(style.Card__Footer)}>
            {profilePhoto !== null && isImageLoaded && <img src={profilePhoto} alt='Profile Picure' loading='lazy' className={style.Card__Avatar} onError={() => { setIsImageLoaded(false) }} />}
            {(profilePhoto === null || !isImageLoaded) && <div className={style.Card__AvatarInitial}><span className={style.Card__AvatarInitialText}>{handleInitials(firstname, lastname, isTalentDeleted)}</span></div>}
            <div className={style.Card__FooterTalentInfo}>
              <span className={style.Card__FooterTitleText}>{handleUserName(firstname, lastname, isTalentDeleted)}</span>
              <RenderIf isTrue={lastUpdated !== undefined}><span className={style.Card__FooterSubtitleText}>{formatProjectTalentDateAndTime(lastUpdated)}</span></RenderIf>
            </div>
          </div>
          <RenderIf isTrue={isPublicSelect}>
            <div className={style.Card__BtnWrapper}>
              <Link className={style.Card__DetailButton} to={(isPublicSelect && selectId !== undefined && childSelectId !== undefined && id !== undefined) ? `/public/selects/${selectId}/${childSelectId}/talent/${id}` : 'javascript: void(0)'} target={hasRedirectLink ? '_blank' : ''}>
                View Profile
              </Link>
              <button className={classNames(style.Card__LikeButton, style[`Card__LikeButton${user?.isAdmin ? '--Disabled' : likedState ? '--Liked' : ''}`])} disabled={user?.isAdmin} onClick={e => {
                e.stopPropagation()
                if (!user?.isAdmin) {
                  likedState ? handleRemoveVote?.(handleSuccess, id) : handleAddVote?.(handleSuccess, id)
                  setLoading(true)
                }
              }}>
                <VoteBtnContent VoteBtnContent isLoading={voteLoading} isLiked={likedState} />
              </button>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </article>
  )
}

interface UGCVideoCardProps {
  videoThumbnail: string
  isLandscape?: boolean
  onClick?: () => void
  hasVideos?: boolean
}

export const UGCVideoCard = (props: UGCVideoCardProps): JSX.Element => {
  const { videoThumbnail, isLandscape = false, onClick, hasVideos = true } = props

  return (
    <article onClick={(typeof onClick === 'function' && hasVideos) ? onClick : undefined} className={classNames(style.Card, isLandscape ? style['Card--Landscape'] : '')}>
      <TalentCardThumbnail videoThumbnail={videoThumbnail} hasVideos={hasVideos} />
    </article>
  )
}
