import { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import Collapse from '@mui/material/Collapse'

import { Images } from '../../../../assets'
import { Colors, Fonts } from '../../../../constants'
import { ShimmerContentBox, Button, TlyntSidebar } from '../../../../components'
import { RenderIf, formatPhoneNumber } from '../../../../utils/Helpers'
import AddAdvertiser from '../../../Advertiser/Components/AddAdvertiser'

import useCombineWithRole from '../../Hooks/useCombineWithRole'
import useDeleteUserMutation from '../../Hooks/useDeleteUserMutation'

const Wrapper = styled.div`
  padding: 0 0 40px 40px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  font-family: ${Fonts.degular};
  overflow-y: auto;
`
const CollapseHeader = styled.div`
  padding: 24px 0;
  border-bottom: 1px ${Colors.inputGray} ${props => !props.isLast ? 'solid' : ''};
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const CollapseBody = styled.div`
  border-bottom: 1px ${Colors.inputGray} ${props => (props.show && !props.isLast) ? 'solid' : ''};
  width: 100%;
`
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 160%;
  text-transform: capitalize;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 40px;
  cursor: pointer;
`
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const InsideFieldWrapper = styled.div``
const Field = styled.div`
  display: flex;
  padding: 12px 0;
`
const FieldTitle = styled.span`
  width: 160px;
  color: ${Colors.blackOpacity}
`
const FieldValue = styled.div`
  display: flex;
`
const AddButton = styled.div`
  margin-right: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
    font-weight: bold;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`
const Shimmer = styled(ShimmerContentBox)`
  height: 50px;
  width: calc(100% - 40px);
  margin-bottom: 20px;
`
const ButtonDesign = styled(Button)`
  width: 135px;
  min-width: auto;
`
const ButtonText = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
`
const ButtonWrapper = styled.div`
  margin: 40px;
  align-self: end;
`

const FieldData = ({ title, value }) => {
  return (
    <Field><FieldTitle>{title}</FieldTitle><FieldValue>{value ?? '-'}</FieldValue></Field>
  )
}

export const TeamInfo = ({ advertiserId, refetchAdvertiser, advertiserData }) => {
  // api calls
  const { data, loading, refetch } = useCombineWithRole({ advertiserId })
  const { deleteUser, loading: deleteLoading } = useDeleteUserMutation()

  // states
  const [isChecked, setIsChecked] = useState([])
  const [isDeleteIndex, setIsDeleteIndex] = useState(null)
  const [isEditAdvertiserModal, setIsEditAdvertiserModal] = useState(false)
  const [editKey, setEditKey] = useState(0)

  const handleEditAdvertiserSidebar = (key) => {
    setEditKey(key)

    setIsEditAdvertiserModal(!isEditAdvertiserModal)
  }

  useEffect(() => {
    const statusArray = []
    if (data) {
      data?.map(e => statusArray.push(false))
      setIsChecked(statusArray)
    }
  }, [data])

  const updateStatus = (index) => {
    setIsChecked(prevState => {
      const newState = prevState.map((obj, i) => {
        if (i === index) {
          return !obj
        }
        return obj
      })
      return newState
    })
  }

  const onUserDelete = () => {
    refetch()
  }
  const deleteUserFunc = (id, index) => {
    setIsDeleteIndex(index)
    deleteUser(id, onUserDelete)
  }

  const shimmerCount = new Array(3).fill(0).map((_, index) => (index))

  return (
    <Wrapper>
      {data && !loading
        ? <>
      <CollapseHeader>
        <HeaderTitle>{data.length} Members</HeaderTitle>
          <AddButton onClick={handleEditAdvertiserSidebar}><img src={Images.plusCollaps} /> Add New Member</AddButton>
      </CollapseHeader>
      {data?.map((e, index) => <Fragment key={index}>
        <CollapseHeader>
        <HeaderTitle>{e?.firstname + ' ' + e?.lastname ?? '-'}</HeaderTitle>
        <IconWrapper>
          <Button style={{ padding: 0, marginRight: 40, border: 'none' }} outline onClick={() => deleteUserFunc(e.id, index)} loading={deleteLoading && (isDeleteIndex === index)}>
            <RenderIf isTrue={!deleteLoading}><img src={Images.delete} height={24} width={24} /></RenderIf>
          </Button>
          <DeleteIcon src={isChecked[index] ? Images.minusCollaps : Images.plusCollaps} onClick={() => updateStatus(index)} />
        </IconWrapper>
      </CollapseHeader>
      <CollapseBody show={isChecked[index]}>
        <Collapse in={isChecked[index]}>
          <FieldWrapper>
            <InsideFieldWrapper>
              <FieldData title='First Name' value={e.firstname} />
              <FieldData title='Last Name' value={e.lastname} />
              <FieldData title='Email' value={e.email } />
              <FieldData title='Title' value={e.roles && e.roles?.length > 0 ? e.roles.join(', ') : null} />
              <FieldData title='Phone' value={e.phone ? e.countryCode + ' ' + formatPhoneNumber(e.phone) : '-'} />
            </InsideFieldWrapper>
            <ButtonWrapper>
              <ButtonDesign outline={true} height={'42px'} onClick={() => handleEditAdvertiserSidebar(index)}>
                <ButtonText>Edit Details</ButtonText>
              </ButtonDesign>
            </ButtonWrapper>
          </FieldWrapper>
        </Collapse>
      </CollapseBody>
      </Fragment>)}
      </>
        : <>{shimmerCount.map((e, i) => <Shimmer key={i} />)}</>}
      <RenderIf isTrue={isEditAdvertiserModal}>
        <TlyntSidebar>
          <AddAdvertiser
            handleToggle={handleEditAdvertiserSidebar}
            reFetchData={refetchAdvertiser}
            memberData={data}
            advertiserData={advertiserData}
            preActiveStep={true}
            editKey={editKey}
            refetchMember={refetch} />
        </TlyntSidebar>
      </RenderIf>
    </Wrapper>
  )
}

FieldData.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string
}

TeamInfo.propTypes = {
  advertiserId: PropTypes.string,
  advertiserData: PropTypes.object,
  refetchAdvertiser: PropTypes.func
}
