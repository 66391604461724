/* eslint-disable no-unused-vars */
import { Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Colors } from '../../../../constants'
import { Button, Typography } from '../../../../components'
import useDeleteUserMutation from '../../Hooks/useDeleteUserMutation'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import LocalStorage from '../../../../utils/LocalStorage'
import { useAuth } from '../../../../services/Auth'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  height: 0;
  flex: 1;
  flex-direction: column;
  padding: 40px 40px;
`

const BottomWrapper = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  padding-inline: 40px;
  align-items: flex-start;
`

const DeleteUser = props => {
  // props
  const { activeUserTab, handleDeleteData, toggleDeleteUserSidebarVisible } = props
  // api call
  const [deleteUserMutation, { loading }] = useDeleteUserMutation()
  // hooks
  const auth = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // constants
  const userInfo = LocalStorage.getItem('userInfo')

  // functions
  const handleDelete = async () => {
    try {
      await deleteUserMutation({
        variables: {
          id: activeUserTab?.id
        }
      })
      const response = await handleDeleteData(activeUserTab?.id)
      if (userInfo?.sub === activeUserTab?.id) {
        LocalStorage.resetLocalStorage()
        auth.signout(() => navigate('/login'))
      }
      dispatch(showToast({
        message: 'User deleted successfully.'
      }))
      toggleDeleteUserSidebarVisible()
    } catch (error) {
      dispatch(showToast({
        message: 'User deletion failed.',
        error: true
      }))
    }
  }
  return (
    <Fragment>
      <Wrapper>
        <Typography fontSize={34} fontWeight={700} letterSpacing={0.25}>
          Are you sure you want to delete this user?
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={400}
          letterSpacing={0.15}
          color={Colors.errorRed}
          style={{
            opacity: 0.8
          }}>
          By doing this action user will be deleted permanently.
        </Typography>
        <section style={{ marginTop: 24 }}>
          <Typography
            fontSize={20}
            fontWeight={'700'}
            letterSpacing={0.15}
            style={{
              marginBottom: 4
            }}>
            {activeUserTab?.firstname} {activeUserTab?.lastname}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={'400'}
            letterSpacing={0.15}
            style={{
              marginBottom: 4
            }}>
            {activeUserTab?.organizationData?.name}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={400}
            letterSpacing={0.15}
            style={{
              opacity: 0.4,
              marginBottom: 2
            }}>
            {activeUserTab?.email}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={400}
            letterSpacing={0.15}
            style={{
              opacity: 0.4
            }}>
            {activeUserTab.roleDetail?.name}
          </Typography>
        </section>
      </Wrapper>
      <BottomWrapper>
        <Button
        onClick={toggleDeleteUserSidebarVisible}
        outline backgroundColor="white">
          <Typography
            fontSize={15}
            fontWeight={700}
            letterSpacing={0.46}
            color="black">
            CANCEL
          </Typography>
        </Button>
        <Button
        onClick={handleDelete}
        loading={loading}>
          <Typography
            fontSize={15}
            fontWeight={700}
            letterSpacing={0.46}
            color="white">
            DELETE
          </Typography>
        </Button>
      </BottomWrapper>
    </Fragment>
  )
}

DeleteUser.propTypes = {
  activeUserTab: PropTypes.object,
  handleDeleteData: PropTypes.func,
  toggleDeleteUserSidebarVisible: PropTypes.func
}

export default DeleteUser
