import { initializeApp } from 'firebase/app'
import { getAnalytics, setUserId, setUserProperties, logEvent } from 'firebase/analytics'

const config = {
  apiKey: 'AIzaSyBU3FnUzmgA5q_4wefYz0053IU0uqeZkk8',
  authDomain: 'book-tlynt.firebaseapp.com',
  projectId: 'book-tlynt',
  storageBucket: 'book-tlynt.appspot.com',
  messagingSenderId: '952500127164',
  appId: '1:952500127164:web:ad72752407e1f73d1917b9',
  measurementId: 'G-0C2654CRRN'
}

const app = initializeApp(config)
const instance = getAnalytics(app)

console.info('Analytics initialized')

const defaults = {}

if (process.env.REACT_APP_ENV !== 'prod') {
  defaults.debug_mode = true
}

export default {
  setInternalTraffic: () => { defaults.traffic_type = 'internal' },
  setUserId: (userId) => setUserId(instance, userId),
  setUserProperties: (properties) => setUserProperties(instance, properties),
  logEvent: (name, params = {}) => logEvent(instance, name, { ...defaults, ...params })
}
