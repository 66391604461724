import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { UIConstants } from '../../../constants'
import TalentAPI from '../../../services/Talent/TalentAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useAddTalentMutation () {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addTalentMutation, { loading }] = useMutation(TalentAPI.createTalent(), { notifyOnNetworkStatusChange: true, errorPolicy: 'all' })

  // extract username from profile url
  const extractUsername = url => {
    const rx = /^https?:\/\/(?:www\.)?(?:facebook|tiktok|instagram|twitter).com\/(?:#!\/)?([^?/#]*)(?:[?/#].*)?$/gm
    const arr = rx.exec(url)
    return arr ? arr[1] : url
  }

  const extractFacebookUsername = url => {
    const rx = /(?:(?:http|https):\/\/)?(?:www.|m.)?facebook.com\/(?!home.php)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w.-]+)/gm
    const arr = rx.exec(url)
    return arr ? arr[1] : url
  }

  const onNavigate = queryString =>
    navigate(`/${UIConstants.Talent}/${queryString}`)

  const addTalent = async data => {
    const { firstName, lastName, email, phone, socialLinks, countryCodeLocation, postalCode } = data

    const payload = {
      firstname: firstName,
      lastname: lastName,
      email,
      phone: phone ?? '',
      countryCode: '+1',

      countryCodeLocation: JSON.parse(countryCodeLocation),
      postalCode,

      socialLinks: {
        instagram: socialLinks?.instagram?.length > 0 ? extractUsername(socialLinks?.instagram) : null,
        facebook: socialLinks?.facebook?.length > 0 ? extractFacebookUsername(socialLinks?.facebook) : null,
        tiktok: socialLinks?.tiktok?.length > 0 ? extractUsername(socialLinks?.tiktok).replace(/^(@)/, '') : null,
        twitter: socialLinks?.twitter?.length > 0 ? extractUsername(socialLinks?.twitter).replace(/^(@)/, '') : null,
        website: socialLinks.website || null
      }
    }

    try {
      const respose = await addTalentMutation({
        variables: Object.fromEntries(
          Object.entries(payload).filter(([_, v]) => v != null)
        )
      })
      const talentId = respose?.data?.talentCreate.id
      onNavigate(talentId)
      dispatch(
        showToast({
          message: 'Talent created successfully'
        })
      )
    } catch (err) {
      dispatch(
        showToast({
          message: err?.networkError?.result?.errors[0]?.message ?? err?.message,
          isError: true
        })
      )
    }
  }

  return [
    addTalent,
    {
      loading
    }
  ]
}

export default useAddTalentMutation
