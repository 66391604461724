import { useState, useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Formik, Form, FieldArray } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Select, MenuItem } from '@mui/material'

import { Button } from '../buttonV2'
import { Images } from '../../assets'
import { Colors, Fonts, UIConstants } from '../../constants'
import { RenderIf } from '../../utils/Helpers'
import SelectAPI from '../../services/Select/SelectAPI'
import { showToast } from '../../redux/actions/DashBoardActions'
import { CreateNewSelectValidationSchema } from '../../services/ValidationServices'
import useOrganizationListQuery from '../../pages/Projects/Hooks/useOrganizationListQuery'
import { DELETE_SELECT } from '../../services/API'

const FormWrapper = styled.div`
  min-height: 6vh;
  max-height:40vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 24px;

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: ${Colors.white};
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.lightSilver};
    border: 4px solid white;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px ${Colors.white};
  }
  ::-webkit-scrollbar-corner {
    background: ${Colors.white};
  }
`
const FormFieldView = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 40px;

`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px;
  padding-bottom: 20px;
`
const HeaderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const BackArrowImg = styled.img`
  height: 20px;
  width: auto;
  cursor: pointer;
`
const BackArrowImgWrapper = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
  padding-bottom: 10px;
  margin-right: 15px;
`
const FieldInput = styled.textarea`
  width: 100%;
  height: ${props => props.name === 'description' ? '64px' : '24px'};
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  outline: none;
  overflow:hidden;
  resize: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${Colors.labelBlack};
  border-bottom: 2px solid ${(props) => props.isValid ? Colors.errorRed : Colors.black}};
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  display:block;
  padding-bottom: 5px;
  margin-bottom: 5px auto;
`
const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  overflow-x : hidden;

`
const RowView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: end;
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  align-self: center;
`
const CloseButton = styled.img`
  height: 14px;
  width: 14px;
  display: flex;
  position: relative;
  margin-left: -23px;
  cursor: pointer;
`
const RoleNameWrapper = styled.div`
  height: 40px;
  display:flex;
  flex-direction: row;
`
const Bottom = styled.div`
 display: flex;
 flex: 1;
 justify-content: flex-end;
 align-items: flex-end;
 padding: 0 40px;
 margin-top: 30px
 `
const RoleWrapper = styled.div`
  padding-left : 40px
 `
const SelectBoxWrapper = styled.div`
  padding-left: 40px;
`

export const CreateNewSelects = ({
  handleActiveParentTabData,
  handelUpdateTabData,
  isVisibleModal,
  toggleModal,
  isEdit,
  roleData,
  parentId,
  isBackArrow,
  isDuplicate = false
}) => {
  const { id: paramId } = useParams()
  const dispatch = useDispatch()

  const user = useSelector(state => state.UserReducer)

  const { data: selectInfoData } = useQuery(SelectAPI.getSelectForCRUD(), {
    variables: {
      id: parentId ?? paramId
    },
    skip: !isEdit
  })
  const [UpdateSelect, { loading }] = useMutation(SelectAPI.updateSelect(), {
    notifyOnNetworkStatusChange: true,
    refetchQueries: () => {
      return [{
        query: SelectAPI.getSelectForCRUD(),
        variables: { id: parentId }
      }]
    }
  })

  const [CreateSelect, { loading: createSelectLoading }] = useMutation(
    SelectAPI.createSelect(),
    {
      notifyOnNetworkStatusChange: true
    }
  )
  const [DeleteChildSelect, { loading: deleteSubSelectLoading }] = useMutation(DELETE_SELECT, {
    notifyOnNetworkStatusChange: true
  }
  )

  const { data, loading: organizationListLoading } = useOrganizationListQuery()

  useEffect(() => {
    if (roleData.length > 0) {
      const tempData = roleData.map(role => {
        return {
          role_name: role.name,
          id: role.id,
          talents: role.talents
        }
      })
      setFormValues({
        ...formValues,
        roles: tempData
      })
    }
  }, [roleData])

  const addRoleContainerRef = useRef(null)
  const formikRef = useRef(null)

  const [formValues, setFormValues] = useState({
    name: isEdit ? isDuplicate ? `Duplicate - ${selectInfoData?.getSelect.name}` : selectInfoData?.getSelect.name : '',
    description: isEdit ? selectInfoData?.getSelect?.description : '',
    roles: [{ role_name: '', id: '' }],
    ownerId: selectInfoData?.getSelect?.ownerId
  })

  const scrollToBottom = () =>
    addRoleContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    })
  const [editedRoleIndex, setEditedRoleIndex] = useState([])
  const [newRoleIndexs, setNewRoleIndexs] = useState([])
  const [deletedRoles, setDeletedRoles] = useState([])
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const [editedReponseCount, setEditedResponseCount] = useState(null)
  const [addedRoleReponseCount, setAddedRoleResponseCount] = useState(null)
  const [deleteRoleResponseCount, setDeletedRoleResponseCount] = useState(null)
  const [isNameDescriptionUpdated, setIsNameDescriptionUpdated] = useState(false)

  const optionsArray = [{ id: 0, name: 'Select Advertiser' }].concat(data?.organizationList)

  const handelOnChangeRole = (e, index) => {
    e.preventDefault()
    formValues.roles[index].role_name = e.target.value
    setFormValues({ ...formValues, roles: [...formValues.roles] })
    const isIndexPresent = editedRoleIndex.findIndex(idx => idx === index)
    const isIndexPresentInNewRole = newRoleIndexs.findIndex(
      idx => idx === index
    )

    if (isIndexPresent === -1 && formValues.roles[index].id !== '') {
      editedRoleIndex.push(index)
      setEditedRoleIndex(editedRoleIndex)
    } else if (
      index === 0 &&
      formValues.roles[index].id === '' &&
      isIndexPresentInNewRole === -1
    ) {
      newRoleIndexs.push(index)
      setNewRoleIndexs(newRoleIndexs)
    }
  }
  const filterArray = (arr1, arr2) => {
    let newArr = []
    const filtered = arr1?.filter(el => {
      return arr2?.indexOf(el) === -1
    })
    newArr = filtered
    return newArr
  }

  useEffect(() => {
    if (isDuplicate) setNewRoleIndexs(new Array(roleData.length).fill(0).map((_, index) => index))
  }, [roleData])

  useEffect(() => {
    if (isEdit && !isDuplicate) { // @TOFIX THIS IS MAKING MY EYES BLEED
      if (
        isSubmitPressed &&
        filterArray(editedRoleIndex, newRoleIndexs).length === editedReponseCount && newRoleIndexs.length === addedRoleReponseCount && isNameDescriptionUpdated &&
        deletedRoles.length === deleteRoleResponseCount
      ) {
        toggleModal(!isVisibleModal)
      } else if (
        isSubmitPressed &&
        filterArray(editedRoleIndex, newRoleIndexs).length === editedReponseCount && newRoleIndexs.length === addedRoleReponseCount && isNameDescriptionUpdated
      ) {
        toggleModal(!isVisibleModal)
      } else if (
        isSubmitPressed &&
        filterArray(editedRoleIndex, newRoleIndexs).length === editedReponseCount && newRoleIndexs.length === addedRoleReponseCount
      ) {
        toggleModal(!isVisibleModal)
      } else if (newRoleIndexs.length === addedRoleReponseCount) {
        toggleModal(!isVisibleModal)
      } else if (
        filterArray(editedRoleIndex, newRoleIndexs).length === editedReponseCount
      ) {
        toggleModal(!isVisibleModal)
      } else if (isNameDescriptionUpdated) {
        toggleModal(!isVisibleModal)
      } else if (deletedRoles.length === deleteRoleResponseCount) {
        toggleModal(!isVisibleModal)
      }
    } else if (!isEdit || isDuplicate) {
      if (isSubmitPressed && addedRoleReponseCount === newRoleIndexs.length) {
        dispatch(
          showToast({
            message: 'Select created successfully.'
          })
        )
        toggleModal(!isVisibleModal)
      }
    }
  }, [
    isSubmitPressed,
    editedRoleIndex,
    newRoleIndexs,
    addedRoleReponseCount,
    editedReponseCount,
    isNameDescriptionUpdated,
    deletedRoles,
    deleteRoleResponseCount
  ])

  useEffect(() => {
    formikRef.current.setValues(formValues)
  }, [])

  const handelAddMoreRole = () => {
    setFormValues({
      ...formValues,
      roles: [...formValues.roles, { role_name: '' }]
    })
    const formLength = formValues.roles.length
    newRoleIndexs.push(formLength)
    setNewRoleIndexs(newRoleIndexs)
    setTimeout(() => {
      scrollToBottom()
    }, 300)
  }

  const handelSubmitSelect = async (values) => {
    setIsSubmitPressed(true)
    const { name, description, ownerId } = values

    // EDIT EXISTING SUB SELECT
    if (isEdit && !isDuplicate) {
      UpdateSelect({
        variables: {
          id: parentId,
          name,
          description,
          ownerId: user.isAdmin && ownerId !== '' ? ownerId : user.info.org
        }
      }).then((res) => {
        setIsNameDescriptionUpdated(true)
      })
    }

    // CREATE OR DUPLICATE ROOT SELECT
    if (!isEdit || isDuplicate) {
      const createSelectResponse = await CreateSelect({
        variables: {
          name,
          description,
          ownerId: user.isAdmin && formValues.ownerId !== '' ? formValues.ownerId : user.info.org,
          mediaType: 'photo'
        }
      })
      const selectId = createSelectResponse.data?.createSelect.id
      typeof handleActiveParentTabData === 'function' && handleActiveParentTabData({
        id: selectId
      })
      let resCount = 0
      for (const item of newRoleIndexs) {
        if (formValues?.roles[item]?.role_name) {
          // DUPLICATE SUB SELECT
          await CreateSelect({
            variables: {
              parentId: selectId,
              name: formValues.roles[item].role_name,
              ownerId: user.isAdmin && formValues.ownerId !== '' ? formValues.ownerId : user.info.org
            }
          }).then(({ data }) => {
            if (isDuplicate) {
              const id = data?.createSelect?.id
              // DUPLICATE TALENTS IN SUB SELECT
              UpdateSelect({ // @TOFIX WHY IS THIS NOT AWAITED?
                variables: {
                  id,
                  talents: formValues.roles[item].talents,
                  ownerId: user.isAdmin && formValues.ownerId !== '' ? formValues.ownerId : user.info.org
                }
              })
            }
            resCount = resCount + 1
          })
        }
      }
      setAddedRoleResponseCount(resCount)
    }

    // EDIT EXISTING SUB SELECTS
    if (editedRoleIndex.length > 0 && isEdit && !isDuplicate) {
      let editCount = 0
      for (const item of editedRoleIndex) {
        if (formValues?.roles[item]?.id) {
          UpdateSelect({
            variables: {
              id: formValues.roles[item].id,
              name: formValues.roles[item].role_name,
              description,
              ownerId: user.isAdmin && formValues.ownerId !== '' ? formValues.ownerId : user.info.org
            }
          }).then(() => {
            editCount = editCount + 1
            handelUpdateTabData()
            setEditedResponseCount(editCount)
          })
        }
      }
    }

    // ADD NEW SUB SELECTS
    if (newRoleIndexs.length > 0 && isEdit && !isDuplicate) {
      let addCount = 0
      for (const item of newRoleIndexs) {
        if (formValues?.roles[item]?.role_name) {
          CreateSelect({
            variables: {
              parentId,
              name: formValues.roles[item].role_name,
              ownerId: user.isAdmin && formValues.ownerId !== '' ? formValues.ownerId : user.info.org
            }
          }).then(() => {
            addCount = addCount + 1
            handelUpdateTabData()
            setAddedRoleResponseCount(addCount)
          })
        }
      }
    }

    // DELETE EXISTING SUB SELECTS
    if (deletedRoles.length > 0 && isEdit && !isDuplicate) {
      let deleteCount = 0
      for (const role of deletedRoles) {
        if (role?.id) {
          DeleteChildSelect({
            variables: {
              id: role?.id
            }
          }).then(() => {
            deleteCount = deleteCount + 1
            handelUpdateTabData()
            setDeletedRoleResponseCount(deleteCount)
          })
        }
      }
    }
  }

  const handelRemoveRole = index => {
    const roleData = formValues.roles[index]
    const isRoleAlreadyPresent = deletedRoles.findIndex(
      role => role.role_name === roleData.role_name
    )
    if (isRoleAlreadyPresent === -1) {
      deletedRoles.push(roleData)
      setDeletedRoles(deletedRoles)
    }
    const newRoles = formValues.roles.filter((_, idx) => idx !== index)
    setFormValues({ ...formValues, roles: newRoles })
  }

  const BottomActionButtons = () => {
    return (
      <Bottom>
      <RowView isEdit={isEdit}>
        <Button onClick={() => toggleModal(!isVisibleModal)}
          backgroundColor={Colors.white}>
          <Typography
            fontWeight='bold'
            textTransform='uppercase'>
            {UIConstants.cancel}
          </Typography>
        </Button>
        <Button
        onClick={() => formikRef?.current?.submitForm()}
          type="submit" loading={(loading ||
                    deleteSubSelectLoading || createSelectLoading)}>
            <Typography color={Colors.white}
              fontWeight='bold'
              textTransform='uppercase'
              >
              {isEdit
                ? UIConstants.save
                : UIConstants.createSelects}
            </Typography>
        </Button>
      </RowView>
    </Bottom>
    )
  }

  return (
    isVisibleModal && (
      <Wrapper>
        <InnerWrapper>
          <HeaderWrapper>
            <BackArrowImgWrapper show={isBackArrow}>
              <BackArrowImg src={Images.backArrowBlack} onClick={() => toggleModal(!isVisibleModal)}/>
            </BackArrowImgWrapper>

            <HeaderInfoWrapper>
              <RenderIf isTrue={isEdit && !isDuplicate}>
                <Typography fontWeight='bold' fontSize={24} letterSpacing='0.25px'>{UIConstants.editSelects}</Typography>
              </RenderIf>
              <RenderIf isTrue={!isEdit && !isDuplicate}>
                <Typography fontWeight='bold' fontSize={24} letterSpacing='0.25px'>{UIConstants.createNewSelects}</Typography>
              </RenderIf>
              <RenderIf isTrue={isDuplicate}>
                <Typography fontWeight='bold' fontSize={24} letterSpacing='0.25px'>{UIConstants.duplicateSelects}</Typography>
              </RenderIf>
              <Typography fontSize={14} color={Colors.headingBlack}>
                {isEdit
                  ? UIConstants.editBasicDetailSelects
                  : UIConstants.addBasicDetailCreateSelects}
              </Typography>
            </HeaderInfoWrapper>
          </HeaderWrapper>
          <Formik
            innerRef={formikRef}
            initialValues={formValues}
            validationSchema={CreateNewSelectValidationSchema}
            validateOnMount={!!isEdit}
            enableReinitialize={!!isEdit}
            onSubmit={values => handelSubmitSelect(values)}>
            {({ errors, touched, handleSubmit, handleChange, values }) => {
              return (
                <Fragment>
                  <Form
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                    onSubmit={handleSubmit}>
                    {UIConstants.CreateSelectsForm.map(userDetails => {
                      return (
                        <FormFieldView key={userDetails.key}>
                          <Typography
                            fontSize={13}
                            color={(touched[userDetails.key] && errors[userDetails.key]) ? Colors.errorRed : Colors.black}>
                            {userDetails.labelName}
                            {touched[userDetails.key] &&
                              errors[userDetails.key] && '*'}
                          </Typography>
                          <FieldInput
                            isValid={
                              touched[userDetails.key] &&
                              errors[userDetails.key]
                            }
                            type={userDetails.type}
                            name={userDetails.key}
                            placeholder={userDetails.placeHolder}
                            value={values[userDetails.key]}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                name: userDetails.key === 'name' && isEdit ? e.target.value : undefined,
                                description: userDetails.key === 'description' && isEdit ? e.target.value : undefined
                              })
                              handleChange(e)
                            }}
                            valid={
                              touched[userDetails.key] &&
                              !errors[userDetails.key]
                            }
                            error={
                              touched[userDetails.key] &&
                              errors[userDetails.key]
                            }
                          />
                          {errors[userDetails.key] &&
                            touched[userDetails.key] && (
                              <Typography
                                fontSize={12}
                                color={Colors.errorRed}
                                style={{ marginTop: 3 }}>
                                {errors[userDetails.key]}
                              </Typography>
                          )}
                        </FormFieldView>
                      )
                    })}
                    <Typography style={{ padding: '30px 40px 0px' }} fontWeight={700} fontSize={20}>
                        {UIConstants.addRoleText}
                    </Typography>
                    <FormWrapper>
                      <FieldArray name="roles">
                        {arrayHelpers => {
                          return (
                            <Fragment>
                              {formValues.roles.map((roleDetails, index) => {
                                return (
                                  <RoleWrapper key={index}>
                                    <Typography
                                      fontSize={13}
                                      color={errors?.roles?.length > 0 &&
                                          errors?.roles[index]?.role_name &&
                                          touched?.roles?.length > 0 &&
                                          touched?.roles[index]?.role_name
                                        ? Colors.errorRed
                                        : Colors.black}>
                                      {UIConstants.roleNameText}
                                    </Typography>
                                    <RoleNameWrapper>
                                      <FieldInput
                                        type={'roles'}
                                        name={`roles[${index}].role_name`}
                                        placeholder={'Role Name'}
                                        value={roleDetails.role_name}
                                        isValid={ errors?.roles?.length > 0 &&
                                          errors?.roles[index]?.role_name &&
                                          touched?.roles?.length > 0 &&
                                          touched?.roles[index]?.role_name }
                                        onChange={e => {
                                          handelOnChangeRole(e, index)
                                          handleChange(e)
                                        }}/>
                                      <RenderIf isTrue={formValues.roles.length > 1}>
                                      <CloseButton
                                        onClick={() => {
                                          handelRemoveRole(index)
                                          arrayHelpers.remove(index)
                                        }}
                                        src={Images.closeVectorBlack} />
                                      </RenderIf>
                                    </RoleNameWrapper>
                                    {errors?.roles?.length > 0 &&
                                      errors?.roles[index]?.role_name &&
                                      touched?.roles?.length > 0 &&
                                      touched?.roles[index]?.role_name && (
                                        <Typography
                                          fontSize={12}
                                          color={Colors.errorRed}
                                          style={{ marginTop: 3 }}>
                                          {errors?.roles[index]?.role_name}
                                        </Typography>
                                    )}
                                  </RoleWrapper>
                                )
                              })}
                              <Typography
                                fontSize={12}
                                style={{ cursor: 'pointer', textDecoration: 'underline', paddingLeft: '40px', marginTop: '10px' }}
                                onClick={() => {
                                  handelAddMoreRole()
                                  arrayHelpers.push({ role_name: '', id: '' })
                                }}>
                                {UIConstants.addMoreRoleText}
                              </Typography>
                              { // @TOFIX INDENTATION IS MESSED UP HERE
                                user.isAdmin && !isEdit && (
                                  <>
                                   <Typography style={{ padding: '30px 40px 0px' }} fontWeight={700} fontSize={20}>
                                Advertiser
                              </Typography>
                              <Typography style={{ padding: '16px 40px' }} fontSize={13} fontWeight={400} lineHeight={'13px'}>
                                Choose Advertiser
                              </Typography>
                              <SelectBoxWrapper>
                                <Select
                                  value={formValues.ownerId ?? ''}
                                  onChange={(e) => {
                                    setFormValues({
                                      ...formValues,
                                      ownerId: e.target.value
                                    })
                                  }}
                                  sx={{
                                    '.MuiInputBase-input': {
                                      borderBottom: '2px solid black'
                                    },
                                    '&.MuiInputBase-root': {
                                      '::after': {
                                        borderBottom: 'black'
                                      }
                                    }
                                  }}
                                  variant='standard'
                                  fullWidth >
                                  {
                                    !organizationListLoading && optionsArray.map((org) => {
                                      return (
                                        <MenuItem key={org.id} value={org.id === 0 ? '' : org.id}>{org.name}</MenuItem>
                                      )
                                    })
                                  }
                                </Select>
                              </SelectBoxWrapper>
                                  </>
                                )
                              }
                            </Fragment>
                          )
                        }}
                      </FieldArray>
                      <div ref={addRoleContainerRef} />
                    </FormWrapper>
                  </Form>
                    <BottomActionButtons />
                </Fragment>
              )
            }}
          </Formik>
        </InnerWrapper>
      </Wrapper>
    )
  )
}

CreateNewSelects.defaultProps = {
  activeMenuDetails: { name: '', description: '', role_list: [{ role_name: '' }] },
  isEdit: false,
  roleData: [],
  parentId: '',
  isBackArrow: false,
  isDuplicate: false
}
CreateNewSelects.propTypes = {
  handleActiveParentTabData: PropTypes.func,
  toggleNewSelectCreated: PropTypes.func,
  handelUpdateTabData: PropTypes.func,
  isVisibleModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  activeMenuDetails: PropTypes.object,
  isEdit: PropTypes.bool,
  roleData: PropTypes.array,
  parentId: PropTypes.string,
  handelRefetchSelects: PropTypes.func,
  isBackArrow: PropTypes.bool,
  isDuplicate: PropTypes.bool
}
