import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import NavBar from './Components/NavBar'

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`

const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const LoaderPage = () => {
  return (
    <Container>
      <NavBar />
      <HeroSection>
        <div style={{ margin: 15 }}>
          <Skeleton variant="rounded" width={350} height={300} />
          <br />
          <Skeleton variant="rounded" height={30} /><br />
          <Skeleton variant="rounded" height={100} />
        </div>
       <div style={{ margin: 15 }}>
          <Skeleton variant="rounded" width={350} height={300} />
          <br />
          <Skeleton variant="rounded" height={30} /><br />
          <Skeleton variant="rounded" height={100} />
        </div>
      </HeroSection>
    </Container>
  )
}

export default LoaderPage
