import { Close } from '@mui/icons-material'
import { FormHelperText } from '@mui/material'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import type { Dispatch, ChangeEvent, MouseEvent, KeyboardEvent } from 'react'

import { Accordion } from '../Accordion'
import { DeliverablesContext } from '../../contexts/deliverables'
import { InputBox } from '../InputBox'
import { ReferenceLinksList } from '../ReferenceLinksList'
import { RenderIf, isValidURL } from '../../utils/Helpers'
import { SectionHeader } from '../UGCSectionHeader'
import { UGCCheckboxInputRow } from '../UGCCheckboxInputRow'
import { UGCTooltip } from '../Tooltip'
import Counter from '../Counter/Counter'
import Duration15Sec from '../../assets/images/UGCProject/Duration15Sec.svg'
import Duration15SecWhite from '../../assets/images/UGCProject/Duration15SecWhite.svg'
import Duration30Sec from '../../assets/images/UGCProject/Duration30Sec.svg'
import Duration30SecWhite from '../../assets/images/UGCProject/Duration30SecWhite.svg'
import Duration60Sec from '../../assets/images/UGCProject/Duration60Sec.svg'
import Duration60SecWhite from '../../assets/images/UGCProject/Duration60SecWhite.svg'
import Info from '../../assets/images/UGCProject/Info.svg'
import Landscape from '../../assets/images/UGCProject/Landscape.svg'
import LandscapeWhite from '../../assets/images/UGCProject/LandscapeWhite.svg'
import Portrait from '../../assets/images/UGCProject/Portrait.svg'
import PortraitWhite from '../../assets/images/UGCProject/PortraitWhite.svg'
import Radio from '../ImageRadio/ImageRadio'
import style from './UGCDeliverablesVideos.module.scss'
import type { IDeliverablesActions, IDeliverables } from '../../contexts/deliverables'

type IDeliverablesError = Record<string, string | undefined>
interface UGCDeliverablesVideosProps {
  errorsData: [IDeliverablesError, Dispatch<IDeliverablesError>]
}

const MAX_THRESHOLD_REFERENCE_LINKS_VALUE = 5

export function UGCDeliverablesVideos (props: UGCDeliverablesVideosProps): JSX.Element {
  const { errorsData } = props

  const user = useSelector((state: any) => state.UserReducer)
  const [referenceLinkInput, setReferenceLinkInput] = useState('')
  const [errors, setErrorsData] = errorsData
  const [{ main, audition, referenceLinks }, updateDeliverableData] = useContext<[IDeliverables, Dispatch<IDeliverablesActions>]>(DeliverablesContext)

  const { videoCount, projectDeliverable, deliverableSpec } = main

  // functions
  const videoCountIncrementHandler = (): void => {
    const updatedVideoCount = Number(videoCount)
    const script = [...projectDeliverable.script, { isChecked: true, value: [''], id: null }]
    updateDeliverableData({ main: { ...main, videoCount: updatedVideoCount + 1, projectDeliverable: { ...projectDeliverable, script } } })
  }
  const videoCountDecrementHandler = (): void => {
    const updatedVideoCount = Number(videoCount)
    const script = [...projectDeliverable.script]
    script.pop()
    updateDeliverableData({ main: { ...main, videoCount: updatedVideoCount - 1, projectDeliverable: { ...projectDeliverable, script } } })
  }

  const handleVideoRemove = (index: number): void => {
    const updatedVideoCount = Number(videoCount)
    const script = projectDeliverable.script.filter((item, i) => i !== index)

    updateDeliverableData({ main: { ...main, videoCount: updatedVideoCount - 1, projectDeliverable: { ...projectDeliverable, script } } })
  }

  const handleOrientationClick = (type: string): void => {
    updateDeliverableData({ main: { ...main, deliverableSpec: { ...deliverableSpec, orientation: type } } })
  }
  const handleVideoDurationClick = (duration: number): void => {
    setErrorsData({ ...errors, duration: undefined })
    updateDeliverableData({ main: { ...main, deliverableSpec: { ...deliverableSpec, duration } } })
  }

  const handleScriptChange = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    projectDeliverable.script[index].value = [e.target.value]
    updateDeliverableData({ main: { ...main, projectDeliverable: { ...projectDeliverable } } })
  }

  const handleDirectionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    updateDeliverableData({ main: { ...main, deliverableSpec: { ...deliverableSpec, specs: [e.target.value] } } })
  }

  const handleAuditionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    audition.specs[0].value = [e.target.value]
    updateDeliverableData({ audition })
  }

  const handleScriptCheckboxClick = (index: number, data: boolean): void => {
    projectDeliverable.script[index].isChecked = data
    updateDeliverableData({ main: { ...main, projectDeliverable: { ...projectDeliverable } } })
  }

  const handleAuditionCheckboxClick = (data: boolean): void => {
    audition.specs[0].isChecked = data
    updateDeliverableData({ audition })
  }

  const handleInputBlur = (errorValue: string): void => {
    setErrorsData({ ...errors, [errorValue]: undefined })
  }

  const handleReferenceLinkInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length === 0) {
      setErrorsData({ ...errors, referenceLink: undefined })
    }
    setReferenceLinkInput(e.target.value)
  }

  const handleReferenceLinkKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      const validURL: boolean = isValidURL(referenceLinkInput.trim())
      const isDuplicateLink = referenceLinks.some((link: string) => link === referenceLinkInput.trim())

      if (!validURL) {
        setErrorsData({ ...errors, referenceLink: 'Please enter a valid URL.' })
      } else if (referenceLinks.length >= MAX_THRESHOLD_REFERENCE_LINKS_VALUE) {
        setErrorsData({ ...errors, referenceLink: 'Only up to five links can be added.' })
      } else if (isDuplicateLink) {
        setErrorsData({ ...errors, referenceLink: 'This link has already been added.' })
      } else if (referenceLinkInput.trim().length > 0) {
        setErrorsData({ ...errors, referenceLink: undefined })
        updateDeliverableData({ referenceLinks: [...referenceLinks, referenceLinkInput.trim()] })
        setReferenceLinkInput('')
      }
    }
  }

  const handleOnReferenceLinkInputClose = (e: MouseEvent<HTMLButtonElement>): void => {
    setErrorsData({ ...errors, referenceLink: undefined })
    setReferenceLinkInput('')
  }

  const handleDeleteReferenceLink = (index: number): void => {
    const updatedReferenceLinks = [...referenceLinks]
    updatedReferenceLinks.splice(index, 1)
    updateDeliverableData({ referenceLinks: updatedReferenceLinks })
  }

  return (
    <div className={style.Videos}>

      {/* video */}
      <div className={style.Videos__Video}>
        <p className={style.Videos__VideoTitle}>Primary Videos</p>
        <SectionHeader headerText='Number of Primary Videos' descriptionText='This is your primary video. Each talent will produce the total number of videos you request.' />
        <div className={style.Videos__VideoCounter}>
          <Counter min={1} max={user.isAdmin === true ? 10 : 3} value={videoCount} handleIncrement={videoCountIncrementHandler} handleDecrement={videoCountDecrementHandler} />
        </div>
      </div>

      {/* video specification */}
      <div className={style.Videos__Specification}>
        <div className={style.Videos__SpecificationOrientation}>
          <SectionHeader headerText='Video Orientation' descriptionText='Applies to all videos.' tooltip={<UGCTooltip dataTestId='project-ugc-form-tooltip-video-orientation' popper={<img src={Info} />}>
            <span>Choose the orientation (portrait or landscape) that suits the platforms you&apos;re targeting for your campaign. Creators will produce all the videos in this orientation.</span>
          </UGCTooltip>} />
          <div className={style.Videos__SpecificationOrientationRow}>
            <Radio onClick={() => { handleOrientationClick('portrait') }} isSelected={deliverableSpec.orientation === 'portrait'} img={Portrait} activeImg={PortraitWhite} title='Portrait' />
            <Radio onClick={() => { handleOrientationClick('landscape') }} isSelected={deliverableSpec.orientation === 'landscape'} img={Landscape} activeImg={LandscapeWhite} title='Landscape' />
          </div>
        </div>
        <div className={style.Videos__Specification__Orientation}>
          <SectionHeader headerText='Video Duration' descriptionText='Applies to all videos.' tooltip={<UGCTooltip dataTestId='project-ugc-form-tooltip-video-duration' popper={<img src={Info} />}>
            <span>Choose how long you want the videos to be.  Creators will produce each video to this duration.</span>
          </UGCTooltip>} />
          <div className={style.Videos__SpecificationOrientationRow}>
            <Radio onClick={() => { handleVideoDurationClick(15) }} isSelected={deliverableSpec.duration === 15} img={Duration15Sec} activeImg={Duration15SecWhite} />
            <Radio onClick={() => { handleVideoDurationClick(30) }} isSelected={deliverableSpec.duration === 30} img={Duration30Sec} activeImg={Duration30SecWhite} />
            <Radio onClick={() => { handleVideoDurationClick(60) }} isSelected={deliverableSpec.duration === 60} img={Duration60Sec} activeImg={Duration60SecWhite} />
          </div>
          {errors?.duration !== undefined && <FormHelperText error={true}>This field is required</FormHelperText>}
        </div>
      </div>

      {/* video directions */}
      <div className={style.Videos__Direction}>
        <SectionHeader headerText='Direction for All Videos' descriptionText='Here you can provide one or more lines of direction that you want talents to follow for all videos.' />
        <div className={style.Videos__DirectionInputContainer}>
          <InputBox isMultiline value={deliverableSpec.specs[0]} onChange={(e) => { handleDirectionChange(e) }} label='Add one or more lines/bullets' acceptedLength={2048} withLengthSuffix />
        </div>
      </div>

      {/* video script */}
      <div className={style.Videos__ScriptVideo}>
        {projectDeliverable?.script?.length > 0 && projectDeliverable?.script?.map(
          (item: { isChecked: boolean, value: string[] }, index: number) => {
            return <Accordion key={`Video${index}`} label={`Video ${index + 1}`} isRemovable withRemoveText={false} onRemove={() => { handleVideoRemove(index) }}>
              <UGCCheckboxInputRow
                onBlur={() => { handleInputBlur(`main.${index}.value`) }}
                errorText={errors[`main.${index}.value`]} withLengthSuffix isChecked={item.isChecked}
                onCheckboxClick={(data) => { handleScriptCheckboxClick(index, data) }}
                value={projectDeliverable.script[index].value[0]}
                onChange={(e: ChangeEvent<HTMLInputElement>) => { handleScriptChange(e, index) }}
                title='Does this video have a script for talents to follow?'
                warnOnNonSelection warningText='Revisions are limited. Providing a script is highly recommended to ensure video quality' />
            </Accordion>
          })}
      </div>

      {/* audition */}
      <RenderIf isTrue={user.isAdmin}>
        <div className={style.Videos__Audition}>
          <SectionHeader headerText='Audition Video' descriptionText='Talents will be prompted to upload a short audition video.  Here you can provide direction for what you&apos;d like to see.' />
          <div className={style.Videos__GrayBox}><UGCCheckboxInputRow InputLabel='Directions' onBlur={() => { handleInputBlur('audition.0.value') }} errorText={errors['audition.0.value']} labels={['Yes', 'No']} onCheckboxClick={(data) => { handleAuditionCheckboxClick(data) }} isChecked={audition.specs[0].isChecked} value={audition.specs[0]?.value[0]} onChange={handleAuditionChange} title='Do you want an Audition video?' /></div>
        </div>
      </RenderIf>

      {/* Reference links */}
      <div className={style.Videos__ReferenceLink}>
        <SectionHeader headerText='Reference Links' descriptionText='References are links to content examples that give a talent a clear picture of your campaign goals. You can provide up to five links here.  We recommend links from Instagram, TikTok or YouTube.' />
        <div className={style.Videos__ReferenceLinkInputContainer}>
          <InputBox
            value={referenceLinkInput}
            isError={errors?.referenceLink !== undefined}
            errorText={errors?.referenceLink}
            onChange={handleReferenceLinkInputChange}
            onKeyPress={handleReferenceLinkKeyPress}
            endAdornment={<span onClick={handleOnReferenceLinkInputClose} className={style.Videos__ReferenceLinkCloseIcon}><Close /></span>}
            label='Type a URL and press Enter to add.'/>
        </div>

        {/* Reference Links List */}
        <ReferenceLinksList referenceLinks={referenceLinks} hasCloseButton onCloseClick={handleDeleteReferenceLink}/>
      </div>
    </div>
  )
}
