export const Colors = {
  primaryGray: 'rgba(240, 240, 240, 1)',
  primaryGrayOpacity: 'rgba(240, 240, 240, 0.4)',
  borderGray: 'rgba(238, 238, 238, 1)',
  buttonGrayOpacity: 'rgba(255, 255, 255, 0.24)',
  backgroundGrayOpacity: 'rgba(255, 255, 255, 0.08)',
  black: 'rgba(0, 0, 0, 1)',
  inactiveBlack: 'rgba(0, 0, 0, 0.5)',
  white: 'rgb(255, 255, 255, 1)',
  pink: 'rgba(225, 48, 108, 1)',
  labelBlack: 'rgba(0, 0, 0, 0.87)',
  grayLabelBlack: 'rgba(0, 0, 0, 0.38)',
  errorRed: '#F44336',
  inputGray: 'rgba(224, 224, 224, 1)',
  backgroundGray: 'rgba(250, 250, 250, 1)',
  headingBlack: 'rgba(0, 0, 0, 0.38)',
  symbolGray: 'rgba(0, 0, 0, 0.08)',
  lightBlack: 'rgba(33, 33, 33, 1)',
  dashedBorderGray: 'rgb(189, 189, 189, 1)',
  grayOpacity: 'rgba(245, 245, 245, 1)',
  blackOpacity: 'rgba(0, 0, 0, 0.60)',
  successGreen: 'rgba(76,175,80,1)',
  lightSilver: 'rgba(0,0,0,0.2)',
  secondaryBlue: 'rgba(40,110,180,1)',
  blackgroundBlackOpacity: 'rgba(60, 60, 60, 0.95)',
  PreviewSideBarblackgroundBlackOpacity: 'rgba(30, 30, 30, 0.95)',
  brightGray: '#EEEEEE',
  spanishGray: '#9E9E9E',
  sonicSilver: '#757575',
  nickel: '#727272',
  cerise: '#E1306C',
  mediumSeaGreen: '#3CAF6E',
  antiFlashWhite: '#F0F0F0',
  greenBlue: 'rgba(40, 110, 180, 1)',
  spanishOrange: '#ED6C02',
  platinum: '#E7E7E7',
  // random color
  lightBlue: 'rgba(160, 230, 255, 1)',
  nonPhotoBlue: 'rgba(153, 236, 226, 1)',
  shampoo: 'rgba(255, 200, 255, 1)',
  calamansi: 'rgba(230, 255, 160, 1)',
  peachPuff: 'rgba(255, 217, 183, 1)',
  lightRed: 'rgba(255, 200, 200, 1)',
  pastelYellow: 'rgba(253, 255, 134, 1)',
  vividTangerine: 'rgba(255, 152, 130, 1)',
  vodka: 'rgba(195, 185, 255, 1)',
  celeste: 'rgba(178, 250, 255, 1)',
  menthol: 'rgba(184, 255, 135, 1)',
  Dandelion: 'rgba(255, 217, 115, 1)',
  aeroBlue: 'rgba(197, 255, 220, 1)'
}
