import { Skeleton } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import classNames from 'classnames'
import type { ApolloCache, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client'
import type { Dispatch } from 'react'

import { Accordion } from '../../../Accordion'
import { InputBox } from '../../../InputBox'
import { RadioInput } from '../../../RadioInput'
import { RenderIf } from '../../../../utils/Helpers'
import { SectionHeader } from '../../../UGCSectionHeader'
import { SendNotification } from '../../../../apis/ugc'
import { setEthnicity } from '../../../../redux/actions/DemographicAction'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { SpinnerSmall } from '../../../Progress'
import { TagCheckbox } from '../../../TagCheckbox'
import { talentAgeOptions, talentGenderOptions, talentOptions, talentValidationSchema } from '../../../../constants/UGCConstants'
import { TalentContext } from '../../../../contexts/talent'
import { UGCSidebarContext } from '../../../../contexts/ugcSidebar'
import { URL } from '../../../../constants/URL'
import Counter from '../../../Counter/Counter'
import style from './Talent.module.scss'
import type { ITalentActions, ITalent } from '../../../../contexts/talent'
import type { UserState } from '../../../../redux/reducers/user'

interface IprofileData {
  name: string
  id: number | string
  projectId?: string
  quantity: number
  ageRanges?: string[] | null
  gender?: string[] | null
  ethnicity?: string[] | null
  description?: string
  isRemoved?: boolean
  isEdited?: boolean
  isExpanded?: boolean
}
interface IValidationProfileError {
  name?: string
  quantity?: string
  ageRanges?: string
  gender?: string
  ethnicity?: string
  description?: string
}
interface IValidationError {
  numberOfCreators?: string
  compensation?: string
  hasTalentProfile?: string
  profile?: IValidationProfileError[]
}
interface ProfileComponentProps {
  id: number | string
  label: string
  isRemovable: boolean
  data: IprofileData
  maxCount: number
  errors?: IValidationProfileError
  handleRemoveProfile: (id: number | string, selectId?: string, isEdited?: boolean) => void
  handleProfileChange: (id: number | string, value: string | number | object) => void
  index: number
}

const ProfileComponent = (props: ProfileComponentProps): JSX.Element => {
  const demographicData = useSelector((state: any) => state.DemographicReducer)
  const ethnicityAPIData = demographicData?.ethnicity?.map((item: { id: string, value: string }) => ({ id: item.id, name: item.value, label: item.value }))
  const ethnicityOptions = [{ id: 0, name: 'any', label: 'Any' }, ...ethnicityAPIData] ?? []

  const { id, label, handleRemoveProfile, isRemovable, data, handleProfileChange, errors, maxCount, index } = props
  const [isExpanded, setIsExpanded] = useState(data?.isExpanded ?? index === 0)

  const handleQuantityIncrement = (): void => {
    handleProfileChange(id, { ...data, quantity: data.quantity + 1 })
  }
  const handleQuantityDecrement = (): void => {
    handleProfileChange(id, { ...data, quantity: data.quantity - 1 })
  }

  const handleChange = (name: string, value: string[] | string): void => {
    if (name === 'ageRanges' && value.includes('any')) {
      value = ['any']
    }
    const updatedData = { ...data, [name]: value }
    handleProfileChange(id, updatedData)
  }

  return (
    <Accordion expanded={isExpanded} onClick={() => { setIsExpanded(!isExpanded) }} label={data?.name ?? label} handleChangeLabel={handleChange} isRemovable={isRemovable} isError={errors?.name !== undefined} errorText={errors?.name} isEditableLabel onRemove={() => { handleRemoveProfile(id) }}>
      <div className={style.Talent__AccordionForm}>
        <SectionHeader headerText='Number fitting this profile' descriptionText='How many talent do you want to match to this profile?' variant='small' />
        <Counter value={data.quantity} handleIncrement={handleQuantityIncrement} min={1} max={maxCount} handleDecrement={handleQuantityDecrement} />
        <SectionHeader headerText='Age ranges' descriptionText='Select the age ranges describing this profile' variant='small' />
        <TagCheckbox name='ageRanges' options={talentAgeOptions} value={data.ageRanges === null ? ['any'] : data.ageRanges} isError={errors?.ageRanges !== undefined} errorText={errors?.ageRanges} onChange={(name, value) => { handleChange(name, value) }} />
        <SectionHeader headerText='One or more genders' descriptionText='Select the gender(s) describing this profile' variant='small' />
        <TagCheckbox name='gender' options={talentGenderOptions} value={data.gender === null ? ['any'] : data.gender} isError={errors?.gender !== undefined} errorText={errors?.gender} onChange={(name, value) => { handleChange(name, value) }} />
        <SectionHeader headerText='One or more ethnicities' descriptionText='Select the ethnicities describing this profile' variant='small' />
        <TagCheckbox name='ethnicity' options={ethnicityOptions} value={data.ethnicity === null ? ['any'] : data.ethnicity} isError={errors?.ethnicity !== undefined} errorText={errors?.ethnicity} onChange={(name, value) => { handleChange(name, value) }} />
        <SectionHeader headerText='Additional Profile Criteria' descriptionText='Specify any additional profile criteria here' variant='small' />
        <InputBox name='description' label='Add one or more lines' value={data.description} isMultiline
          isError={errors?.description !== undefined} errorText={errors?.description} onChange={(event) => { handleChange(event.target.name, event.target.value) }} />
      </div>
    </Accordion>
  )
}

interface IProjectTalentProps {
  projectTalentData: {
    id: string
    name: string
    ownerId: string
    selectId: string
    compensation: number
    hasTalentProfile: string
    numberOfCreators: number
    loading: boolean
    profile: Array<{
      id: number | string
      name: string
      projectId?: string
      quantity: number
      ageRanges?: string[] | null
      gender?: string[] | null
      ethnicity?: string[] | null
      description?: string
      isRemoved?: boolean
      isEdited?: boolean
    }>
  }
  talentQueryMutation: {
    useUpdateUGCProjectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useCreateProjectRoleMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useUpdateProjectRoleMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useDeleteProjectRoleMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useDeleteRoleSelectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useGetDemographicDataQuery: () => ({ data: { getTalentDemographicSelectionData: any } | undefined, loading: boolean })
  }
}

export const Talent = (props: IProjectTalentProps): JSX.Element => {
  const defaultUGCCompensation = useFeatureValue('portal:ugc:default-compensation', 300)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: projectId } = useParams()

  const [sidebarData, handleSidebarData] = useContext(UGCSidebarContext)
  const [talentData, updateHandler] = useContext<[ITalent, Dispatch<ITalentActions>]>(TalentContext)

  const { projectTalentData, talentQueryMutation } = props
  const { useUpdateUGCProjectMutation, useCreateProjectRoleMutation, useUpdateProjectRoleMutation, useDeleteProjectRoleMutation, useDeleteRoleSelectMutation, useGetDemographicDataQuery } = talentQueryMutation

  const [errors, setErrors] = useState<IValidationError>({})
  const [CTAType, setCTAType] = useState('')
  const [removedProfiles, setRemovedProfiles] = useState<string[]>([])
  const [removedSelects, setRemovedSelects] = useState<string[]>([])

  const user: UserState = useSelector((state: any) => state.UserReducer)

  const firstName = String(user?.info?.name?.first)
  const lastName = String(user?.info?.name?.last)
  const userInfo = (firstName?.length > 0) || (lastName?.length > 0) ? `${firstName} ${lastName}` : user?.info?.email

  const [updateUGCProjectMutation, { loading: updateUGCProjectLoading }] = useUpdateUGCProjectMutation()
  const [createProjectRoleMutation, { loading: createProjectRoletLoading }] = useCreateProjectRoleMutation()
  const [updateProjectRoleMutation, { loading: updateProjectRoleLoading }] = useUpdateProjectRoleMutation()
  const [deleteProjectRoleMutation, { loading: deleteProjectRoleLoading }] = useDeleteProjectRoleMutation()
  const [deleteRoleSelectMutation, { loading: deleteRoleSelectLoading }] = useDeleteRoleSelectMutation()
  const { data: demographicContent, loading: demographicLoading } = useGetDemographicDataQuery()
  const [sendNotificationMutation] = useMutation(SendNotification, { notifyOnNetworkStatusChange: true })

  const isLoading = updateUGCProjectLoading || createProjectRoletLoading || updateProjectRoleLoading || deleteProjectRoleLoading || deleteRoleSelectLoading

  const initialProfileData = {
    id: Date.now(),
    name: `Profile ${talentData.profile.length + 1}`,
    projectId,
    quantity: 1,
    ageRanges: ['any'],
    gender: ['any'],
    ethnicity: ['any'],
    description: '',
    isExpanded: true
  }

  useEffect(() => {
    if (!demographicLoading && demographicContent?.getTalentDemographicSelectionData !== undefined) {
      dispatch(setEthnicity(demographicContent.getTalentDemographicSelectionData?.ethnicity))
    }
  }, [demographicLoading])

  useEffect(() => {
    if (projectTalentData?.id !== '') {
      updateHandler({ ...projectTalentData })
    } else {
      updateHandler({
        numberOfCreators: 1,
        compensation: defaultUGCCompensation,
        hasTalentProfile: 'true',
        profile: [{
          id: Date.now(),
          name: 'Profile 1',
          projectId: '',
          quantity: 1,
          ageRanges: ['any'],
          gender: ['any'],
          ethnicity: ['any'],
          description: '',
          isRemoved: false,
          isEdited: false
        }]
      })
    }
  }, [projectTalentData])

  const handelChange = (name: string, value: string | number | boolean | IprofileData[]): void => {
    const updatedTalentData = { ...talentData }
    updatedTalentData[name] = value
    updateHandler({ ...updatedTalentData })
    handleValidate(updatedTalentData)
  }

  const handleValidate = (formValues: ITalent): void => {
    const validation = talentValidationSchema.safeParse(formValues)
    if (validation.success) {
      setErrors({})
    } else {
      const err = validation.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        const myObject: Record<string, any> = {}
        err.issues.forEach((item) => {
          const path = item.path
          const currentObject = myObject
          if (path.length === 3) {
            const [key, index, subKey] = path
            if ((currentObject[key]) === undefined) {
              currentObject[key] = []
            }
            if ((currentObject[key][index]) === undefined) {
              currentObject[key][index] = {}
            }
            currentObject[key][index][subKey] = item.message
          } else if (path.length === 2) {
            const [key, subKey] = path
            if (currentObject[key] === undefined) {
              currentObject[key] = {}
            }
            currentObject[key][subKey] = item.message
          } else {
            const [key] = path
            currentObject[key] = item.message
          }
        })
        setErrors({ ...errValues, ...myObject })
      }
    }
  }

  const handleSubmit = async (isClose: boolean): Promise<void> => {
    const result = talentValidationSchema.safeParse(talentData)
    if (result.success) {
      const profileData = talentData.profile
      const payload = {
        ...talentData,
        hasTalentProfile: talentData.hasTalentProfile === 'true',
        updateUgcProjectId: projectId
      }

      try {
        if (projectId != null) {
          // updating project
          payload.updateUgcProjectId = projectId
          const updatedUGC = await updateUGCProjectMutation({ variables: payload })
          // updating project roles
          if (talentData?.hasTalentProfile === 'true') {
            const newProfile = []
            const updatedProfile = []

            for (let i = 0; i < profileData.length; i++) {
              const profile = profileData[i]
              const { name, description, quantity, ageRanges, gender, ethnicity, selectId } = profile
              const payloadObj = {
                name,
                projectId,
                description,
                quantity: quantity ?? 1,
                selectId,
                criteria: {
                  ageRanges: (ageRanges?.length === 1 && (Boolean((ageRanges?.includes('any'))))) ? undefined : ageRanges?.filter((item) => item !== 'any').map((ageRange) => ({ min: Number(ageRange.split('-')[0]), max: Number(ageRange.split('-')[1]) })),
                  gender: (gender?.length === 1 && gender?.includes('any')) ? undefined : gender,
                  ethnicity: (ethnicity?.length === 1 && ethnicity?.includes('any')) ? undefined : ethnicity
                }
              }
              if (typeof profile.id === 'string' && profile.isEdited === true) {
                updatedProfile.push({ ...payloadObj, id: profile.id })
              } else if (typeof profile.id === 'number') {
                newProfile.push({ ...payloadObj })
              }
            }
            if (updatedProfile.length > 0) {
              const updatedRoleResponse = await updateProjectRoleMutation({ variables: { updateRoles: updatedProfile, parentId: talentData.selectId } })
              updateHandler({
                ...talentData,
                profile: profileData.map((profile) => {
                  const updatedProfile = updatedRoleResponse?.data.updateRolesWithSelect.find((p: { id: string }) => p.id === profile.id)
                  if (updatedProfile !== undefined) {
                    return {
                      ...profile,
                      name: updatedProfile.name,
                      quantity: updatedProfile.quantity,
                      description: updatedProfile.description,
                      ageRanges: updatedProfile.criteria?.ageRanges === null ? ['any'] : updatedProfile.criteria?.ageRanges?.map((age: { min: number, max: number }) => `${age.min}-${age.max}`),
                      gender: updatedProfile.criteria?.gender,
                      ethnicity: updatedProfile.criteria?.ethnicity,
                      isRemoved: false,
                      isEdited: false
                    }
                  }
                  return profile
                })
              })
            }
            if (newProfile.length > 0) {
              const createRoleResponse = await createProjectRoleMutation({ variables: { createRoles: newProfile, ownerId: talentData.ownerId, parentId: talentData.selectId } })

              const createdProfile = createRoleResponse?.data.addRolesWithSelect
              if (createdProfile !== undefined) {
                updateHandler({
                  ...talentData,
                  profile: profileData.map((profile) => {
                    return {
                      ...profile,
                      id: createdProfile.id,
                      name: createdProfile.name,
                      quantity: createdProfile.quantity,
                      description: createdProfile.description,
                      ageRanges: createdProfile.criteria?.ageRanges === null ? ['any'] : createdProfile.criteria?.ageRanges?.map((age: { min: number, max: number }) => `${age.min}-${age.max}`),
                      gender: createdProfile.criteria?.gender,
                      ethnicity: createdProfile.criteria?.ethnicity,
                      isRemoved: false,
                      isEdited: false
                    }
                  })
                })
              }
              updateHandler({
                ...talentData,
                profile: profileData.filter((profile) => typeof profile.id === 'string')
              })
            }
            // deleting project roles
            if (removedProfiles.length > 0) {
              await Promise.all(removedProfiles.map(async (id) => {
                await deleteProjectRoleMutation({ variables: { id } })
                updateHandler({
                  ...talentData,
                  profile: profileData.filter((profile) => profile.id !== id)
                })
              }))
              if (removedSelects.length > 0) {
                await Promise.all(removedSelects.map(async id => {
                  return await deleteRoleSelectMutation({ variables: { id } })
                }))
              }
            }
          } else {
            if (profileData.length > 0) {
              await Promise.all(profileData.map(async (profile) => {
                if (typeof profile.id === 'string') {
                  await deleteProjectRoleMutation({ variables: { id: profile.id } })
                  profile.selectId !== undefined && await deleteRoleSelectMutation({ variables: { id: profile.selectId } })
                }
              }))
            }
            const payloadObj = {
              name: 'Profile 1',
              projectId,
              description: '',
              quantity: talentData.numberOfCreators
            }
            await createProjectRoleMutation({ variables: { createRoles: [{ ...payloadObj }], ownerId: talentData.ownerId, parentId: talentData.selectId } })
          }
          if (isClose) {
            if (user?.isAdvertiser) {
              await sendNotificationMutation({
                variables: {
                  orgName: updatedUGC?.data.updateUgcProject.owner.name ?? '',
                  logoUrl: updatedUGC?.data.updateUgcProject.owner.logo?.uris?.logo ?? '',
                  projectId: updatedUGC?.data.updateUgcProject.id,
                  projectUrl: `${URL}/projects/ugc/view/${projectId}/overview`,
                  projectName: updatedUGC?.data.updateUgcProject.name,
                  userInfo,
                  action: 'update'
                }
              })
            }
          }
          updateHandler({
            ...talentData,
            compensation: updatedUGC?.data.updateUgcProject.compensation,
            hasTalentProfile: updatedUGC?.data.updateUgcProject.hasTalentProfile
          })
        }
        if (isClose) {
          navigate('/projects')
        } else if (projectId != null) {
          navigate(`/projects/ugc/${projectId}/product`)
        }
        // handling sidebar status
        sidebarData[3].isError = false
        sidebarData[3].isCompleted = true
      } catch (error: any) {
        dispatch(showToast({ message: error?.message, isError: true }))
        sidebarData[3].isError = false
        sidebarData[3].isCompleted = true
      }
    } else {
      sidebarData[3].isError = true
      sidebarData[3].isCompleted = false
    }
    handleSidebarData([...sidebarData])
  }

  const creatorsIncrementHandler = (): void => { handelChange('numberOfCreators', talentData.numberOfCreators + 1) }

  const creatorsDecrementHandler = (): void => {
    handelChange('numberOfCreators', talentData.numberOfCreators - 1)
  }

  const handleAddProfile = (): void => {
    const newProfile = [...talentData.profile, { ...initialProfileData }]
    handelChange('profile', newProfile)
  }

  const handleRemoveProfile = (id: number | string, selectId?: string, isEdited?: boolean): void => {
    const updatedProfiles = (talentData.profile.filter((profile) => profile.id !== id))
    const removedProfileId = removedProfiles
    const removedSelectsId = removedSelects
    if ((isEdited ?? false) && typeof id === 'string') {
      removedProfileId.push(id)
      selectId !== undefined && removedSelectsId.push(selectId)
      setRemovedProfiles(removedProfileId)
      setRemovedSelects(removedSelectsId)
    }
    handelChange('profile', updatedProfiles)
  }

  const handleProfileChange = (id: number | string, value: string | number | object): void => {
    const updatedProfiles: IprofileData[] = talentData.profile.map((profile) => {
      if (profile.id === id) {
        return value as IprofileData
      }
      return profile as IprofileData
    })
    handelChange('profile', updatedProfiles)
  }

  return (
    <>
      <section className={style.Talent}>
        <form className={style.Talent__Form}>
          <div className={style.Talent__Creator}>
            <div>
              <SectionHeader headerText='Number Of Talent' descriptionText='How many talent do you want to work on your project?' />
              <RenderIf isTrue={projectTalentData?.loading}>
                <div className={style.Talent__Shimmer}>
                  <Skeleton />
                </div>
              </RenderIf>
              <RenderIf isTrue={!projectTalentData?.loading}>
                <Counter value={talentData.numberOfCreators} handleIncrement={creatorsIncrementHandler} handleDecrement={creatorsDecrementHandler} min={1} max={99} isError={errors?.numberOfCreators !== undefined} errorText={errors?.numberOfCreators} />
              </RenderIf>
            </div>
          </div>
          <RenderIf isTrue={user.isAdmin}>
            <div>
              <SectionHeader headerText='Compensation' descriptionText='Specify what each talent will be paid for the project. Note: advertisers won&apos;t see this amount.' />
              <div className={style.Talent__Compensation}>
                <RenderIf isTrue={projectTalentData?.loading}>
                  <div className={style.Talent__Shimmer}>
                    <Skeleton />
                  </div>
                </RenderIf>
                <RenderIf isTrue={!projectTalentData?.loading}>
                  <InputBox variant='large' name='compensation' value={talentData.compensation.toString()} onChange={(e) => { handelChange(e.target.name, parseInt(e.target.value)) }} withPrefix prefixText='$' acceptedLength={4} isError={errors?.compensation !== undefined} errorText={errors?.compensation} />
                </RenderIf>
              </div>
            </div>
          </RenderIf>
          <div>
            <SectionHeader headerText='Specify Profiles' descriptionText='Here you can optionally choose to provide criteria for matching with talent.' />
            <RadioInput label='Do you want to specify the types of profiles you&apos;re looking for?' name='hasTalentProfile' errorText={errors?.hasTalentProfile} isError={errors?.hasTalentProfile !== undefined} onChange={(e) => { handelChange(e.target.name, e.target.value) }}
              options={talentOptions} value={talentData?.hasTalentProfile} />
          </div>
          <RenderIf isTrue={projectTalentData?.loading}>
            <div className={style.Talent__ProfileShimmer}>
              <Skeleton />
              <Skeleton />
            </div>
          </RenderIf>
          <RenderIf isTrue={(!projectTalentData?.loading && talentData.hasTalentProfile === 'true') || demographicLoading}>
            <div className={style.Talent__ProfileContainer}>
              {talentData.profile.map((profile, i) => (
                <ProfileComponent
                  index={i}
                  key={profile.id}
                  data={profile}
                  errors={errors?.profile?.[i]}
                  handleProfileChange={(id, value) => { handleProfileChange(id, value) }}
                  handleRemoveProfile={(id) => { handleRemoveProfile(id, profile?.selectId, profile.isEdited) }}
                  id={profile.id}
                  isRemovable={talentData?.profile.length > 1}
                  label={profile.name}
                  maxCount={talentData?.numberOfCreators}
                />
              ))}
            </div>
          </RenderIf>
        </form>
        <RenderIf isTrue={(!projectTalentData?.loading && talentData.hasTalentProfile === 'true') || demographicLoading}>
          <button className={classNames('primary-button', style.Footer__Button)} onClick={() => { handleAddProfile() }}>
            <span className={style.Talent__CTAText}>Add New Profile</span>
          </button>
        </RenderIf>
      </section>
      <div className={style.Footer}>
        <button onClick={() => { (projectId != null) && navigate(`/projects/ugc/${projectId}/deliverables`) }} className={classNames('primary-text-button', style.Footer__Button)}>Back</button>
        <div className={style.Footer__ButtonRow}>
          <button onClick={() => {
            setCTAType('close')
            void handleSubmit(true)
          }} className={classNames('outline-primary-button', style.Footer__Button)}><p>{(projectId != null) ? 'Update & Close' : 'Save & Close'}</p> {isLoading && CTAType === 'close' && <SpinnerSmall />} </button>
          {
            sidebarData[3].isVisible && <button onClick={() => {
              setCTAType('next')
              void handleSubmit(false)
            }} className={classNames('primary-button', style.Footer__Button)}>Next: Product {isLoading && CTAType === 'next' && <SpinnerSmall />}  </button>
          }
        </div>
      </div>
    </>
  )
}
