import { API } from '../constants/'

export const uploadMedia = async (
  formData,
  successHandler,
  errorHandler,
  progressHandler,
  fileName,
  file
) => {
  try {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = false
    xhr.upload.onerror = () => {}

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          xhr?.responseText && uploadToS3(JSON.parse(xhr.responseText), successHandler,
            errorHandler, progressHandler, fileName, file)
        } else {
          errorHandler('Error uploading file', fileName)
        }
      }
    }
    xhr.open('POST', API.mediaUpload)
    xhr.send(formData)
  } catch (error) {
    errorHandler(error, fileName)
  }
}

export const logout = async () => {
  await fetch(`${API.logout}`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('access_token')
    }
  })
}

export const uploadToS3 = async (
  uploadedImageResponse,
  successHandler,
  errorHandler,
  progressHandler,
  fileName,
  file
) => {
  try {
    const s3Url = uploadedImageResponse?.item?.url || ''
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = false

    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100
        progressHandler(percentComplete, fileName)
      }
    }
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          await successHandler(uploadedImageResponse, fileName)
        } else {
          errorHandler('Error uploading file', fileName)
        }
      }
    }
    xhr.open('PUT', s3Url)
    xhr.send(file.file)
  } catch (error) {
    console.log('medialUploadApi', error)
  }
}

export const uploadMultipartToS3 = async (
  url,
  file
) => {
  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: 'PUT',
      body: file
    }).then(() => resolve())
      .catch(err => reject(err))
  })
}

export const uploadBulkMedia = async (
  formData,
  successHandler,
  errorHandler,
  progressHandler,
  fileName,
  file
) => {
  try {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = false
    xhr.upload.onerror = () => {}
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          xhr?.responseText && uploadToS3(JSON.parse(xhr.responseText), successHandler,
            errorHandler, progressHandler, fileName, file)
        } else {
          errorHandler((xhr?.responseText && JSON.parse(xhr.responseText)?.error) ?? 'Error occur while uploading file', fileName)
        }
      }
    }
    xhr.open('POST', API.bulkMediaUpload)
    xhr.setRequestHeader('authorization', localStorage.getItem('access_token'))
    xhr.send(formData)
  } catch (error) {
    errorHandler(error, fileName)
  }
}

export const getPublicToken = async (userId, selectId, scope, password) => {
  const response = await fetch(`${API.publicToken}/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      scope: scope ?? `select:${selectId}`,
      password
    })
  })
  return response.json()
}
