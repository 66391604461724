import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { setAllTalents } from '../../../redux/actions/DashBoardActions'
import TalentAPI from '../../../services/Talent/TalentAPI'
import useTalentListQuery from './useTalentListQuery'

const THRESHOLD_MEDIA_API_RESPONSE = 4
const TALENT_THRESHOLD_LIMIT = 120

const useAllTalentsWithMedia = () => {
  // hooks
  const dispatch = useDispatch()
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)

  // constants
  const rAllTalents = dashboardReduxData.allTalents

  // states
  const [loading, setLoading] = useState(true)
  const [allTalentsData, setAllTalentsData] = useState([])
  const [totalAllTalents, setTotalAllTalents] = useState(0)

  const [hasNextPageCalled, setHasNextPageCalled] = useState(false)
  const [mediaAPICallFinished, setMediaAPICallFinished] = useState(false)

  // api calls
  const { data: talentListData, loading: talentListLoading, fetchMore: fetchMoreTalentList } = useTalentListQuery({
    offset: 0,
    limit: TALENT_THRESHOLD_LIMIT,
    skip: rAllTalents.length > 0
  })

  const [fetchMoreTalentMedia] = useLazyQuery(TalentAPI.getMediaListData())

  useEffect(() => {
    if (talentListData?.listTalent?.talents.length > 0) {
      setAllTalentsData(talentListData?.listTalent.talents.map(item => ({ ...item, isSelected: false })))
      setTotalAllTalents(talentListData?.listTalent?.metadata?.total)
      partiallyMediaAPICalls(talentListData)
      setLoading(false)
    }
  }, [talentListLoading])

  useEffect(() => {
    setTimeout(() => {
      if (hasNextPageCalled && mediaAPICallFinished) {
        fetchNextPageData()
      }
    }, 2000)
  }, [mediaAPICallFinished])

  const fetchNextPageData = async () => {
    const { data: searchFetchMoreData } = await fetchMoreTalentList({
      variables: {
        offset: rAllTalents.length,
        limit: TALENT_THRESHOLD_LIMIT
      }
    })
    partiallyMediaAPICalls(searchFetchMoreData)
  }

  const partiallyMediaAPICalls = async (talentData) => {
    if (talentData?.listTalent?.talents.length > 0) {
      const mediAPICall = async (item, index) => {
        const { data: newMediaData } = await fetchMoreTalentMedia({
          variables: {
            talentIds: talentData?.listTalent?.talents.slice(item, (index + 1) * 30).map((item) => item?.id),
            disabled: false,
            limit: 5,
            offset: 0
          }
        })
        const mediaList = newMediaData?.mediaList
        const newTalentList = talentData?.listTalent?.talents.slice(item, (index + 1) * 30).map(talent => {
          const medias = mediaList?.filter(media => talent.id === media.talentId)
          return { ...talent, isSelected: false, media: medias }
        })
        return newTalentList
      }

      const arrayForLoop = new Array(THRESHOLD_MEDIA_API_RESPONSE).fill(0).map((item, index) => index * 30)

      const [firstData, secondData, thridData, fourthData] = await Promise.all(arrayForLoop.map(async (item, index) => mediAPICall(item, index)))

      setAllTalentsData([...rAllTalents, ...firstData, ...secondData, ...thridData, ...fourthData])
      dispatch(setAllTalents([...rAllTalents, ...firstData, ...secondData, ...thridData, ...fourthData]))
      setMediaAPICallFinished(true)
    }
  }

  return {
    allTalentsData: rAllTalents.length > 0 ? rAllTalents : allTalentsData,
    allTalentLoading: rAllTalents.length > 0 ? false : loading,
    totalAllTalents,
    allTalentsToggleNextPageCall: () => {
      setHasNextPageCalled(true)
      if (!talentListLoading && rAllTalents.length > 0) {
        setHasNextPageCalled(false)
        fetchNextPageData()
      }
    }
  }
}

export default useAllTalentsWithMedia
