import { useEffect, useState } from 'react'
import useOrganizationListQuery from './useOrganizationListQuery'

import useUserSearchListQuery from './useUserSearchListQuery'

const useCombineWithListAndRole = (props) => {
  // props
  const { order, searchQuery } = props
  // states
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  // api calls
  const [fetchOrganizationList] = useOrganizationListQuery()
  const { data: userListData, loading: userListDataLoading, fetchMore: fetchMoreUserListData } = useUserSearchListQuery({ order, searchQuery })

  useEffect(() => {
    setLoading(true)
  }, [order, searchQuery])

  useEffect(() => {
    if (!userListDataLoading && loading) {
      clubData()
    } else if (!userListDataLoading && userListData?.searchUsers?.length === 0) {
      setLoading(false)
    }
  }, [userListDataLoading])

  const clubData = async () => {
    const listData = userListData?.searchUsers
    if (listData && listData.length > 0) {
      const clubData = listData?.map(data => ({
        ...data,
        roleDetail: data?.roles ?? []
      }))
      const ownerIds = clubData?.map(cdata => cdata.ownerId)
      const response = await fetchOrganizationList({
        variables: {
          ids: ownerIds
        }
      })
      const organizationListData = response?.data?.organizationList
      const clubDataWithOrganization = clubData?.map(data => ({
        ...data,
        organizationData: organizationListData.find(orgData => orgData.id === data.ownerId)
      }))
      setData(clubDataWithOrganization)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const fetchMore = async (cb, cb2) => {
    const fetchMoreUserListResponse = await fetchMoreUserListData({
      variables: {
        offset: data.length,
        limit: 15
      }
    })
    if (fetchMoreUserListResponse.data && fetchMoreUserListResponse.data.searchUsers.length > 0) {
      const listData = fetchMoreUserListResponse.data.searchUsers

      const clubData = listData.map(data => ({
        ...data,
        roleDetail: data?.roles ?? []
      }))
      const ownerIds = clubData.map(cdata => cdata.ownerId)
      const response = await fetchOrganizationList({
        variables: { ids: ownerIds }
      })
      const organizationListData = response.data.organizationList
      const clubDataWithOrganization = clubData.map(data => ({
        ...data,
        organizationData: organizationListData.find(orgData => orgData.id === data.ownerId)
      }))
      setData([...data, ...clubDataWithOrganization])
      cb2()
    } else {
      cb()
    }
  }

  const handleDeleteData = id => {
    if (id) {
      const updatedData = data.filter(oldData => oldData?.id !== id)
      setData([...updatedData])
    }
  }

  const handleMasterData = (modifiedData) => setData([...modifiedData])

  return {
    data,
    loading,
    fetchMore,
    handleDeleteData,
    handleMasterData
  }
}

export default useCombineWithListAndRole
