import styledComponents from 'styled-components'

import { Colors } from '../../../../constants'

const Style = {

  // For Upload component
  UploadContainerChild: styledComponents.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 100px;
    text-align: center;
    position: ${props => props.isUploaded ? 'absolute' : 'relative'};
    background: ${props => props.isUploaded ? 'rgba(0, 0, 0, 0.6)' : 'white'};
    z-index: 9;
  `,

  ImgView: styledComponents.img`
    width: auto;
    height: 50px;
    margin: 0px auto 10px auto;
    display: flex;
  `,

  BrowseWrapper: styledComponents.div`
    display: flex;
    justify-content: center;
  `,

  CloseButtonWrapper: styledComponents.div`
  position: absolute;
  display: ${props => props.isUploaded ? 'flex' : 'none'};
  right: 30px;
  top: 30px;
  width: 40px;
  height: 40px;
  background: ${Colors.white};
  opacity: 0.8;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`
}

export default Style
