import { useEffect, useState } from 'react'

import useGetMediaQuery from './useGetMediaQuery'
import useCombineWithTalentAndLikes from './useCombineWithTalentAndLikes'

function useCombineWithMediaAndLikes (props) {
  // props
  // talentsId - in strings
  const { talentIds, activeChildSelectTab, parentActiveTabData } = props

  // states
  const [masterData, setMasterData] = useState([])
  const [masterDataWithMedia, setMasterDataWithMedia] = useState([])

  // api calls for merging talent and vote count
  const { talentWithLikeCountData, loading: talentWithLikeCountDataLoading } =
    useCombineWithTalentAndLikes({
      talentIds,
      activeChildSelectTab
    })

  // api call - for fetching all the medias of above talents
  const { mediaData, loading: mediaDataLoading } = useGetMediaQuery({
    talentIds
  })

  useEffect(() => {
    if (talentIds || parentActiveTabData) {
      setMasterData([])
      setMasterDataWithMedia([])
    }
  }, [talentIds, parentActiveTabData])

  // mapping the talent list data and vote data in master data
  useEffect(() => {
    if (!talentWithLikeCountDataLoading) {
      setMasterData([...talentWithLikeCountData])
    }
  }, [talentWithLikeCountDataLoading, talentWithLikeCountData, mediaDataLoading])

  // Mapping medias with talents
  useEffect(() => {
    if (masterData.length > 0) {
      const dataWithMedia = masterData?.map(talent => {
        const medias = mediaData?.filter(media => talent.id === media.talentId)
        return {
          ...talent,
          media: medias
        }
      })
      setMasterDataWithMedia(dataWithMedia)
    }
  }, [masterData, talentWithLikeCountDataLoading])

  const handleMasterData = data => {
    setMasterData(data)
    setMasterDataWithMedia(data)
  }

  return {
    masterData: masterDataWithMedia?.length > 0 ? masterDataWithMedia : masterData,
    handleMasterData,
    loading: talentWithLikeCountDataLoading
  }
}

export default useCombineWithMediaAndLikes
