import { useEffect, useState } from 'react'

import classNames from 'classnames'
import style from './ImageRadio.module.scss'

interface ImageRadioProps {
  img: string
  activeImg?: string
  title?: string
  isSelected?: boolean
  onClick?: () => void
}

function ImageRadio (props: ImageRadioProps): JSX.Element {
  const { img, activeImg, title, onClick, isSelected = false } = props
  const [isSelectedValue, setIsSelectedValue] = useState(isSelected)

  const handleRadioClick = (): void => {
    if (typeof onClick === 'function') {
      setIsSelectedValue(!isSelectedValue)
      onClick()
    }
  }

  useEffect(() => {
    setIsSelectedValue(isSelected)
  }, [isSelected])

  return (
    <div onClick={ isSelected ? undefined : handleRadioClick} className={classNames(style.ImageRadio, isSelectedValue ? style['ImageRadio--Active'] : '')}>
      <div className={classNames(style.ImageRadio__Container)}>
        <img src={isSelectedValue ? activeImg : img} />
        {title !== '' && title !== undefined && <p className={classNames(style.ImageRadio__ContainerText)}>{title}</p> }
      </div>
    </div>
  )
}

export default ImageRadio
