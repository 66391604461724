import styled from 'styled-components'
import { Colors, Fonts } from '../../../constants'

export const FullWidth = styled.div`
 width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const LabelText = styled.label`
  font-family: ${Fonts.degular};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
  font-family: ${Fonts.degular};
`

export const FloatRight = styled.div`
  position: absolute;
  right: 20px;
  height: 100%;
  display: grid;
  place-items: center;
  display: ${props => props?.visible ? 'grid' : 'none'};
  z-index: 20;
  font-family: ${Fonts.degular};
`

export const ModifiedInput = styled.input`
  padding: 0;
  height: 48px;
  width: 100%;
  border: none;
  background: ${Colors.antiFlashWhite};
  box-sizing: border-box;
  padding-left: 10px;
  outline: none;
  border: 1px solid ${props => (props.error ? Colors.errorRed : 'transparent')} };
  font-family: ${Fonts.degular};
  font-size: 16px;
  `
