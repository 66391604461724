import { Skeleton } from '@mui/material'
import PropTypes from 'prop-types'

import Card from '.'
import Style from '../../Styles'

const CardWrapper = ({ data, uploadLoader }) => {
  const Loader = () => {
    return new Array(4).fill(0).map((_, index) => <Skeleton key={`shimmer-${index}`} variant='rounded' height={70} />)
  }
  return (
    <Style.CardLayout>
      {uploadLoader && <>
        <Loader />
      </>}
      {data.length > 0 && data.map((item, index) => (
        <Card key={item.title}
          data={item}
          index={index} />
      ))}
    </Style.CardLayout>
  )
}

CardWrapper.propTypes = {
  data: PropTypes.array,
  uploadLoader: PropTypes.bool
}

export default CardWrapper
