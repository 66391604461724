export const DashboardTypes = {
  SET_TALENTS_WITH_PICTURES: 'SET_TALENTS_WITH_PICTURES',
  SET_TALENTS_WITH_VIDEOS: 'SET_TALENTS_WITH_VIDEOS',
  SET_TALENTS_WITHOUT_PICTURES: 'SET_TALENTS_WITHOUT_PICTURES',
  SET_FLAGGED_TALENTS: 'SET_FLAGGED_TALENTS',
  SET_DISABLED_TALNETS: 'SET_DISABLED_TALNETS',
  SET_ALL_TALNETS: 'SET_ALL_TALNETS',
  SET_ACTIVE_TALENT_TAB_INDEX: 'SET_ACTIVE_TALENT_TAB_INDEX',
  SET_ACTIVE_SIDEBAR_ROUTE: 'SET_ACTIVE_SIDEBAR_ROUTE',
  SET_TALENT_CARD_RESIZE_DATA: 'SET_TALENT_CARD_RESIZE_DATA'
}
