import classNames from 'classnames'

import style from './ProjectCard.module.scss'
import { RenderIf } from '../../utils/Helpers'

import { handleProjectCardDate } from '../../utils/ProjectHelper'
import MoneyIcon from '../../assets/images/Projects/Money.svg'
import PersonIcon from '../../assets/images/Projects/Person.svg'
import CalendarIcon from '../../assets/images/Projects/Calendar.svg'
import LocationIcon from '../../assets/images/Projects/Location.svg'
import PlayCircle from '../../assets/images/Projects/PlayCircle.svg'
import ArrowForwardIcon from '../../assets/images/Projects/ArrowForward.svg'

interface ProjectCardProps {
  id: string
  name: string
  status: string
  type: string
  bgColor?: string
  owner: IAdvertiser
  endDate: string
  isAdmin?: boolean
  brandId: string

  // ugc
  compensation?: number
  numberOfCreators?: number
  numberOfVideos?: number

  // casting
  roles?: IRole[]
  startDate?: string
  postalCode?: string
  countryCodeLocation?: string
}

interface IRole {
  rate: number
}

interface IAdvertiser {
  name: string
  logo: ILogo
  brands: IBrand[]
}

interface IBrand {
  id: string
  name: string
  logo: ILogo
}

interface ILogo {
  uris: IUris
}

interface IUris {
  logo: string
}

interface AdvertiserLogoProps {
  name: string
  logo: string
}

interface ProjectCardHeaderProps {
  owner: IAdvertiser
  status: string
  name: string
  isUGC: boolean
  brandId: string
}

interface CastingCardBodyProps {
  date: string
  roles?: IRole[]
  postalCode?: string
  countryCode?: string
}

interface UGCCardBodyProps {
  date: string
  compensation: number
  numberOfCreators: number
  numberOfVideos: number
  isAdmin: boolean
}

const AdvertiserLogo = ({ name, logo }: AdvertiserLogoProps): JSX.Element => {
  if (logo !== undefined && logo !== '') {
    return (
      <picture>
        <source srcSet={logo} type='image/webp' />
        <img src={logo} alt='advLogo' loading='lazy' />
      </picture>
    )
  } else {
    return <span>{name?.charAt(0)?.toUpperCase()}</span>
  }
}

const ProjectCardHeader = ({ owner, status, name, isUGC, brandId }: ProjectCardHeaderProps): JSX.Element => {
  const brandDetails = owner?.brands?.find(brand => brand?.id === brandId)
  let logo, orgName
  if (brandId !== undefined && brandId !== null) {
    logo = <AdvertiserLogo name={brandDetails?.name ?? ''} logo={brandDetails?.logo?.uris?.logo ?? '' } />
    orgName = brandDetails?.name
  } else {
    logo = <AdvertiserLogo name={owner?.name ?? ''} logo={owner?.logo?.uris?.logo ?? '' } />
    orgName = owner?.name
  }
  return (
    <>
      <div className={classNames(style.ProjectCard__Header)}>
        <div className={classNames(style.ProjectCard__LogoWrapper)}>
          <RenderIf isTrue={owner !== undefined}>
            {logo}
          </RenderIf>
        </div>
        <div>
          <RenderIf isTrue={isUGC}>
            <span className={classNames(style.ProjectCard__Status, style['ProjectCard__Status--UGC'])}>UGC</span>
          </RenderIf>
          <span className={classNames(style.ProjectCard__Status)}>
            {status?.replaceAll('_', ' ').charAt(0).toUpperCase() + status?.replaceAll('_', ' ').slice(1)}
          </span>
        </div>
      </div>
      <div className={classNames(style.ProjectCard__OwnerName)}>
        <span>{orgName}</span>
      </div>
      <div className={classNames(style.ProjectCard__Title)}>
        <span>{name}</span>
      </div>
    </>
  )
}

const handlePriceRange = (item: number[]): string => {
  if (item !== undefined && item.length > 0) {
    if (item.length === 1) {
      return '$' + String(item[0].toFixed(2))
    }
    const minPrice = Math.min(...item).toFixed(2)
    const maxPrice = Math.max(...item).toFixed(2)
    if (minPrice === maxPrice) {
      return '$' + minPrice
    } else {
      return '$' + minPrice + '- $' + maxPrice
    }
  }
  return '-'
}

const CastingCardBody = ({ date, roles, countryCode, postalCode }: CastingCardBodyProps): JSX.Element => {
  return (
    <div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={MoneyIcon} />
        <span>Price : {roles !== undefined && handlePriceRange(roles.map(e => e.rate))}</span>
      </div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={PersonIcon} />
        <span>Number of Roles : {roles?.length ?? 0}</span>
      </div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={CalendarIcon} />
        <span>{date}</span>
      </div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={LocationIcon} />
        <span>{(countryCode !== undefined && postalCode !== undefined) ? `${countryCode}, ${postalCode}` : '-' }</span>
      </div>
    </div>
  )
}

const UGCCardBody = ({ date, compensation, numberOfCreators, numberOfVideos, isAdmin }: UGCCardBodyProps): JSX.Element => {
  return (
    <div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={PersonIcon} />
        <span># of Talents : {numberOfCreators}</span>
      </div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={PlayCircle} />
        <span># of Videos : {numberOfVideos}</span>
      </div>
      <div className={classNames(style.ProjectCard__DetailRow)}>
        <img src={CalendarIcon} />
        <span>Due Date : {date}</span>
      </div>
      <RenderIf isTrue={isAdmin}>
        <div className={classNames(style.ProjectCard__DetailRow)}>
          <img src={MoneyIcon} />
          <span>Price : {compensation !== null ? `$${compensation}` : '-'}</span>
        </div>
      </RenderIf>
    </div>
  )
}

export const ProjectCard = ({ id, name, roles = [], status, endDate, startDate, postalCode, countryCodeLocation, owner, type, compensation = 0, numberOfCreators = 0, numberOfVideos = 0, bgColor = '99ECE2', isAdmin = true, brandId }: ProjectCardProps): JSX.Element => {
  return (
    <article className={style.ProjectCard} style={{ backgroundColor: `#${bgColor}` }}>
      <div className={classNames(style.ProjectCard__Wrapper)}>
        <ProjectCardHeader owner={owner} status={status} name={name} brandId={brandId} isUGC={type === 'ugc'} />
        <RenderIf isTrue={type === 'casting'}>
          <CastingCardBody
            date={handleProjectCardDate(startDate ?? Date(), endDate)}
            roles={roles}
            countryCode={countryCodeLocation}
            postalCode={postalCode}
          />
        </RenderIf>
        <RenderIf isTrue={type === 'ugc'}>
          <UGCCardBody
            date={new Date(endDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
            compensation={compensation}
            numberOfCreators={numberOfCreators}
            numberOfVideos={numberOfVideos}
            isAdmin={isAdmin}
          />
        </RenderIf>
      </div>
      {/* card footer */}
      <a href={ type === 'ugc' ? `/projects/ugc/view/${id}/overview` : `/projects/${id}`} className={classNames(style.ProjectCard__Footer)}>
        <span>SEE DETAILS</span>
        <img src={ArrowForwardIcon} />
      </a>
    </article>
  )
}
