import { useEffect, useState, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import { useMutation } from '@apollo/client'
import { ArrowBack, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

import style from './PortfolioVideoPlayer.module.scss'
import VideoJS from '../../../../components/VideoJS'

import { Button } from '../../../../components/buttonV2'
import MediaAPI from '../../../../services/Media/MediaAPI'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { setTalentVideoList } from '../../../../redux/actions/TalentVideoAction'

const SidebarVideoToggler = togglerProps => {
  const { left, onClick } = togglerProps
  return (
    <div className={classNames(style.VideoContainer__Main__SidebarToggler, left ? style['VideoContainer__Main__SidebarToggler--Left'] : '')}>
      <div className={classNames(style.VideoContainer__Main__SidebarToggler__Logo)} onClick={onClick}>
        {left ? <ArrowBackIos sx={{ color: 'white', fontSize: 20 }} /> : <ArrowForwardIos sx={{ color: 'white', fontSize: 20 }} />}
      </div>
    </div>
  )
}

function PortfolioVideoPlayer (props) {
  // props
  const { previewClickedVideoDataHandler, refetchVideo, isPublic, setProfileVideoHandler } = props
  const [activeVideoIndex, setActiveVideoIndex] = previewClickedVideoDataHandler

  // constants
  const user = useSelector(state => state.UserReducer)

  // hooks
  const dispatch = useDispatch()
  const videoList = useSelector(state => state.TalentVideoReducer)

  // api calls
  const [deleteMediaHandler, { loading: deleteVideoLoading }] = useMutation(MediaAPI.deleteMedia(), { errorPolicy: 'all' })

  // states
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [toggleDeleteSidebar, setToggleDeleteSidebar] = useState(false)

  // lifecycle hook
  useEffect(() => {
    document.addEventListener('keyup', keyboardNavHandler)

    return () => {
      document.removeEventListener('keyup', keyboardNavHandler)
    }
  }, [activeVideoIndex])

  // functions
  const handleDeleteVideo = async () => {
    const videoId = videoList[activeVideoIndex].id || videoList[activeVideoIndex]?.uris?.id

    const { errors: deleteMediaError } = await deleteMediaHandler({ variables: { mediaDeleteId: videoId } })
    if (deleteMediaError?.length) {
      dispatch(showToast({
        isError: true,
        message: deleteMediaError?.map(e => e.message).join(', ')
      }))
    } else {
      const filteredList = videoList.filter((video) => (video.id || video?.uris?.id) !== videoId)
      setToggleDeleteSidebar(!toggleDeleteSidebar)
      dispatch(setTalentVideoList([...filteredList]))
      dispatch(showToast({ message: 'Video deleted successfully' }))
      refetchVideo()
      setActiveVideoIndex(null)
    }
  }

  const keyboardNavHandler = e => {
    if (activeVideoIndex < 0) return

    switch (e?.key) {
      case 'ArrowLeft':
        handlePreviousVideo()
        break
      case 'ArrowRight':
        handleNextVideo()
        break
      case 'Escape':
        setActiveVideoIndex(null)
        break
    }
  }

  const handleNextVideo = () => {
    if (activeVideoIndex + 1 < videoList.length) {
      setActiveVideoIndex(activeVideoIndex + 1)
    }
  }

  const handlePreviousVideo = () => {
    if (activeVideoIndex - 1 !== -1) {
      setActiveVideoIndex(activeVideoIndex - 1)
    }
  }

  const videoJsOptions = useMemo(() => {
    return {
      fluid: false,
      controls: true,
      autoplay: false,
      responsive: true,
      fill: true,
      controlBar: {
        pictureInPictureToggle: false
      },
      sources: [{
        src: videoList[activeVideoIndex]?.uris?.stream,
        type: 'application/x-mpegURL'
      }]
    }
  }, [activeVideoIndex])

  return (
    <>
    <div className={classNames(style.VideoContainer)}>
      <header className={classNames(style.VideoContainer__Header)}>
        <div className={classNames(style.VideoContainer__Header__Back)} onClick={() => setActiveVideoIndex(null)}>
          <ArrowBack sx={{ color: 'white' }} size={'14'} />
          <span className={classNames(style.VideoContainer__Header__Back__Text)}>Back</span>
        </div>

        {user.isAdmin && !isPublic && (
          <div className={classNames(style.AdminActions)}>
            <button className={classNames(style.AdminActions__Button, style.AdminActions__SetAsProfile)} onClick={() => setProfileVideoHandler(videoList[activeVideoIndex].id || videoList[activeVideoIndex]?.uris?.id)}>
              SET AS PROFILE VIDEO
            </button>

            <button className={classNames(style.AdminActions__Button, style.AdminActions__Delete)} onClick={() => setToggleDeleteSidebar(!toggleDeleteSidebar)} disabled={isVideoPlaying}>
              DELETE VIDEO
            </button>
          </div>
        )}
      </header>
      <main className={classNames(style.VideoContainer__Main)}>
        <SidebarVideoToggler left onClick={handlePreviousVideo} />
        <div className={classNames(style.VideoContainer__Main__Video)}>
         <VideoJS options={videoJsOptions} handleVideoPlaying={setIsVideoPlaying} />
        </div>
        <SidebarVideoToggler onClick={handleNextVideo} />
      </main>
    </div>
      <Drawer anchor='right' open={toggleDeleteSidebar}>
        <div className={classNames(style.Drawer)}>
          <div className={classNames(style.Drawer__Header)}>
            <h3 className={classNames(style.Drawer__Header__Text)}>
              Are you sure you want to delete this video?
            </h3>
          </div>
          <div className={classNames(style.Drawer__Footer)}>
            <button className={classNames(style.Drawer__Footer__Button)} onClick={() => setToggleDeleteSidebar(!toggleDeleteSidebar)}>
              <span className={classNames(style.Drawer__Footer__Button__Text)}>Cancel</span>
            </button>
            <Button loading={deleteVideoLoading} onClick={handleDeleteVideo}>
             <span className={classNames(style.Drawer__Footer__Button__Text, style['Drawer__Footer__Button__Text--White'])}>Delete</span>
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

PortfolioVideoPlayer.propTypes = {
  isPublic: PropTypes.bool,
  refetchVideo: PropTypes.func,
  previewClickedVideoDataHandler: PropTypes.array,
  setProfileVideoHandler: PropTypes.func
}

export default PortfolioVideoPlayer
