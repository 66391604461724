const images = {
  logo: require('./Logo.png'),
  dashBoardIconActive: require('./Sidebar/DashBoardIconActive.png'),
  dashBoardIconInActive: require('./Sidebar/DashBoardIconInActive.png'),
  advertiserIconActive: require('./Sidebar/AdvertiserIconActive.png'),
  projectsIconActive: require('./Sidebar/ProjectsIconActive.png'),
  paymentsIconActive: require('./PaymentsIconActive.png'),
  notificationsIconActive: require('./NotificationsIconActive.png'),
  advertiserIconInActive: require('./Sidebar/AdvertiserIconInActive.png'),
  projectsIconInActive: require('./Sidebar/ProjectsIconInActive.png'),
  projectsIconWhite: require('./Sidebar/ProjectsIconInWhite.png'),
  paymentsIconInActive: require('./PaymentsIconInActive.png'),
  notificationsIconInActive: require('./NotificationsIconInActive.png'),
  supportIconActive: require('./Sidebar/SupportIconActive.png'),
  supportIconInActive: require('./Sidebar/SupportIconInActive.png'),
  settingIconActive: require('./Sidebar/SettingIconActive.png'),
  settingIconInActive: require('./Sidebar/SettingIconInActive.png'),
  logout: require('./Sidebar/Logout.png'),
  searchIcon: require('./SearchIcon.png'),
  demo: require('./demo_Pic.png'),
  arrowLeft: require('./arrowLeft.png'),
  arrowForInfo: require('./ArrowForInfo.png'),
  plusIcon: require('./PlusIcon.png'),
  avtarDemo: require('./AvtarDemo.png'),
  demo1: require('./Demo1.png'),
  demo2: require('./Demo2.png'),
  demo3: require('./Demo3.png'),
  demo4: require('./Demo4.png'),
  demo5: require('./Demo5.png'),
  demo6: require('./Demo6.png'),
  demo7: require('./Demo7.png'),
  demo8: require('./Demo8.png'),
  greetings: require('./Greetings.png'),
  dashedVector: require('./DashedVector.png'),
  closeTransparent: require('./CloseTransparent.png'),
  selectsIconInactive: require('./Sidebar/SelectsIconInactive.png'),
  selectsIconActive: require('./Sidebar/SelectsIconActive.png'),
  uploadIcon: require('./upload-icon.png'),
  closeVector: require('./Clear.png'),
  humburgerMenu: require('./humburgerMenuIcon.png'),
  plusDropDown: require('./PlusDropDown.png'),
  minusDropDown: require('./MinusDropDown.png'),
  check: require('./check.png'),
  closeVectorBlack: require('./CloseArrowBlack.png'),
  backArrowBlack: require('./BackArrowBlack.png'),
  checkBlack: require('./CheckBlack.png'),
  successCheck: require('./SuccessCheck.png'),
  closeSuccessIcon: require('./CloseSuccessIcon.png'),
  errorIcon: require('./ErrorIcon.png'),
  tlyntPlaceholder: require('./TlyntPlaceholder.png'),
  tlyntPlaceholderSquare: require('./TalentDetails/TlyntPlaceholderSqare.png'),
  loginPiece1: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_1.png'),
  loginPiece2: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_2.png'),
  loginPiece3: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_3.png'),
  loginPiece4: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_4.png'),
  loginPiece5: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_5.png'),
  loginPiece6: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_6.png'),
  loginPiece7: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_7.png'),
  loginPiece8: require('./Login-Images/PHA_210917_TLYNT_Luis Espinosa_8.png'),
  googleIcon: require('./Login-Images/Google.png'),
  errorRoundedIcon: require('./ErrorRoundedIcon.png'),
  like: require('./PublicSelect/Like.png'),
  liked: require('./PublicSelect/Liked.png'),
  chevronUp: require('./ChevronUp.png'),
  chevronDown: require('./ChevronDown.png'),
  cross: require('./TalentSearch/cross.png'),
  money: require('./Projects/Money.png'),
  person: require('./Projects/Person.png'),
  calendar: require('./Projects/Calendar.png'),
  location: require('./Projects/Location.png'),
  arrowForward: require('./Projects/ArrowForward.png'),
  deleteOutline: require('./Projects/DeleteOutline.png'),
  delete: require('./Projects/Delete.png'),
  copy: require('./Projects/ContentCopy.png'),
  plusCollaps: require('./Projects/Plus.png'),
  minusCollaps: require('./Projects/Minus.png'),
  noProjectFound: require('./Projects/NoProjects.png'),
  calender: require('./TalentDetails/Calender.png'),
  grayCross: require('./TalentFilter/Cross.png'),
  filter: require('./TalentFilter/Filter.png'),
  doubleArrowRight: require('./Sidebar/DoubleArrowRight.png'),
  doubleArrowLeft: require('./Sidebar/DoubleArrowLeft.png'),
  arrowDropDown: require('./Sidebar/ArrowDropDown.png'),
  shortLogo: require('./Sidebar/ShortLogo.png'),
  userActive: require('./Sidebar/UsersActive.png'),
  userInActive: require('./Sidebar/UsersInActive.png'),
  imagePlaceholder: require('./TalentDetails/ImagePlaceholder.png'),
  ImagePlaceholderSquare: require('./TalentDetails/ImagePlaceholderSquare.png'),
  AdvertiserImagePlaceholder: require('./Advertiser/ImagePlaceholder.png'),
  crossWhite: require('./Select/CrossWhite.png'),
  resizeMinimal: require('./Home/Minimal.png'),
  resizeMaximum: require('./Home/Maximum.png'),
  resizeCompact: require('./Home/Compact.png'),
  resizeArrowdown: require('./Home/ResizeArrowdown.png'),
  UploadPlaceholder: require('./BulkUpload/Placeholder.png'),
  FileUploadActive: require('./Sidebar/FileUploadActive.png'),
  FileUploadInActive: require('./Sidebar/FileUploadInActive.png'),
  // tiktok assets
  play: require('./TikTok/play.png'),
  squareChecked: require('./TikTok/Checked.png'),
  squareUnchecked: require('./TikTok/Unchecked.png'),
  paid: require('./TikTok/Paid.png'),
  // UGC assets
  ugcGreetings: require('./UGCProject/UGCGreetings.png'),
  tlyntWatermark: require('./UGCProject/TLYNTWatermark.png')
}

export default images
