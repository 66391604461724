import { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import styled, { keyframes } from 'styled-components'

import { Colors } from '../../../../constants'
import TalentCard from '../../Components/TalentCard'
import { RenderIf } from '../../../../utils/Helpers'

import LegacyListProjectInterests from '../LegacyListProjectInterests'
import useCombineWithMedia from '../../Hooks/useCombineWithMediaAssociatedWithRoles'
import useAddSingleTalentToRoleMutation from '../../Hooks/useAddSingleTalentToRoleMutation'
import useLegacyListProjectInterestsQuery from '../../Hooks/useLegacyListProjectInterestsQuery'

const TalentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${props => `calc(24px * ${props['data-size']}/ 220)`};
  grid-template-columns: ${props => `repeat(auto-fill, minmax(${props['data-size']}px, 1fr))`};
`

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
`
const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
const ShimmerBox = styled.div`
  height: 350px;
  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const TabShimmer = styled.div`
  height: 45px;
  width: 100px;
  background: #777;
  border-radius: 3px;
  margin-right: 10px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const TabItem = styled.div`
  cursor: pointer;
  padding: 10px;
  flex-grow: 0;
  margin-bottom: 10px;
  flex-shrink: 0;
  transition: all 0.15s ease-in;
  border-bottom: 2px solid ${props => (props?.isActive ? Colors.black : 'white')};
`

const TabShimmers = () => {
  return (
    <Box display='flex' flexDirection='row'>
      {new Array(5).fill(0).map((_, index) => <TabShimmer key={index} />)}
    </Box>
  )
}

const ProjectTalent = props => {
  const { projectId, selectId, roles } = props
  // state
  const setTalentId = useState()[1]
  const [isLoaded, setIsLoaded] = useState(false)
  const [sortedTalents, setSortedTalents] = useState([])
  const [activeRoleTab, setActiveRoleTab] = useState('')
  const shimmerCount = new Array(6).fill(0).map((_, index) => ({ id: index }))

  // api calls
  const { addSingleTalent } = useAddSingleTalentToRoleMutation({ selectId: activeRoleTab?.selectId })
  const { dataWithMedia: talentData, loading: talentLoading } = useCombineWithMedia({ projectId: activeRoleTab?.id, selectId: activeRoleTab?.selectId, roleId: activeRoleTab.id })
  const { data: legacyListProjectInterestsQuery, loading: legacyListProjectInterestsLoading } = useLegacyListProjectInterestsQuery(projectId)

  useEffect(() => {
    if (!talentLoading && talentData) {
      setSortedTalents(talentData.map(item => ({ ...item, isLoading: false })))
    }
  }, [talentData, talentLoading])

  useEffect(() => {
    if (roles.length > 0) {
      setActiveRoleTab(roles[0])
    }
  }, [roles])

  useEffect(() => {
    if (!legacyListProjectInterestsLoading && roles.length === 0) {
      setActiveRoleTab('legacy')
    }
  }, [legacyListProjectInterestsLoading, roles])

  // pre load all image and cache them for fast loading
  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()

        img.src = src
        img.onload = resolve
      })
    })
    await Promise.all(promises)
  }
  useEffect(() => {
    if (sortedTalents.length && !isLoaded) {
      cacheImages(sortedTalents.map((e) => e?.uris?.card))
        .then(() => setIsLoaded(true))
        .catch(e => console.error(e))
      setIsLoaded(true)
    }
  }, [sortedTalents])

  // change position of new selected talent while update
  const setSelectedTalent = (talentID) => {
    const newSortedData = sortedTalents
    const index = newSortedData.findIndex(e => e.id === talentID)
    const updatedTlent = newSortedData.splice(index, 1)[0]

    newSortedData.splice(0, 0, updatedTlent)
    setSortedTalents([...newSortedData])
  }

  const toggleTalentCardLoading = (index, isAddedToRole) => {
    sortedTalents[index].isLoading = !sortedTalents[index].isLoading
    if (isAddedToRole) {
      sortedTalents[index].isAddedtoRole = true
    }

    setSortedTalents([...sortedTalents])
  }

  const handleAddSingleTalent = async (talentId, index) => {
    setTalentId(talentId)
    toggleTalentCardLoading(index, false)
    const payload = {
      talentIndex: index,
      selectId: activeRoleTab.selectId,
      talentId,
      handleUpdateSelection: () => setSelectedTalent(talentId),
      toggleTalentCardLoading: (index, status) => toggleTalentCardLoading(index, status)
    }
    await addSingleTalent(payload)
  }

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="row"
        paddingX={'40px'}
        paddingTop={2}
        sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
          {legacyListProjectInterestsLoading
            ? <TabShimmers />
            : roles.length > 0 &&
          roles.map(item => (
            <TabItem key={item.id} isActive={activeRoleTab.id === item?.id} onClick={() => setActiveRoleTab(item)}>
              <Typography fontSize={16} textTransform="capitalize" color={activeRoleTab.id === item?.id ? Colors.black : Colors.blackOpacity}>
                {item?.name}
              </Typography>
            </TabItem>
          ))}
       <RenderIf isTrue={legacyListProjectInterestsQuery?.listProjectInterests?.length > 0}>
        <TabItem isActive={activeRoleTab === 'legacy'} onClick={() => setActiveRoleTab('legacy')}>
          <Typography fontSize={16} color={activeRoleTab === 'legacy' ? Colors.black : Colors.blackOpacity} textTransform="capitalize">
            Global (Legacy)
          </Typography>
        </TabItem>
      </RenderIf>
      </Box>
      {activeRoleTab === 'legacy'
        ? <LegacyListProjectInterests projectId={projectId} selectId={selectId} />
        : <Wrapper>
           <RenderIf isTrue={!talentLoading && sortedTalents && sortedTalents?.length === 0}>
           <Typography fontSize='36px' fontWeight='bold' textAlign='center' width='100%' mt={3}>
            No talent have shown interest yet.</Typography>
           </RenderIf>
          <TalentContainer data-size={220}>
            {!talentLoading && sortedTalents
              ? sortedTalents?.length > 0 && sortedTalents.map((talent, index) => (
                  <TalentCard key={index} item={talent} loading={talent?.isLoading} handelToggleSidebar={() => handleAddSingleTalent(talent.id, index)} />
              ))
              : shimmerCount.map(item => <ShimmerBox key={item.id} />)}
          </TalentContainer>
        </Wrapper>
      }
    </Fragment>
  )
}

ProjectTalent.propTypes = {
  projectId: PropTypes.string,
  selectId: PropTypes.string,
  roles: PropTypes.array
}

export default ProjectTalent
