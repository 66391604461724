import { useQuery } from '@apollo/client'
import SelectAPI from '../../../services/Select/SelectAPI'

function useGetSelectIdQuery ({ data: projectGetData, loading: projectGetLoading }) {
  const { data, loading, error } = useQuery(SelectAPI.getSelectById(), {
    variables: {
      id: projectGetData?.projectGet?.selectId
    },
    skip: (projectGetLoading || !projectGetData?.projectGet?.selectId)
  })
  return { data, loading, error }
}

export default useGetSelectIdQuery
