import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import useTalentListQuery from '../../pages/TalentMain/Hooks/useTalentListQuery'
import { setTalentsWithVideos } from '../../redux/actions/DashBoardActions'
import { useLazyQuery } from '@apollo/client'
import TalentAPI from '../../services/Talent/TalentAPI'

const THRESHOLD_MEDIA_API_RESPONSE = 4
const TALENT_THRESHOLD_LIMIT = 120

const useAllTalentsWithVideo = (props) => {
  const { activeTabIndex } = props
  // hooks
  const dispatch = useDispatch()
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)

  // constants
  const rTalentsDataWithVideos = dashboardReduxData.talentsWithVideos

  // states
  const [loading, setLoading] = useState(true)
  const [allTalentsData, setAllTalentsData] = useState([])
  const [totalAllTalentsWithVideo, setTotalAllTalentsWithVideo] = useState(0)

  const [hasNextPageCalled, setHasNextPageCalled] = useState(false)
  const [mediaAPICallFinished, setMediaAPICallFinished] = useState(false)

  // api calls
  const { data: talentListData, loading: talentListLoading, fetchMore: fetchMoreTalentList } = useTalentListQuery({
    offset: 0,
    limit: TALENT_THRESHOLD_LIMIT,
    skip: rTalentsDataWithVideos.length > 0 || activeTabIndex === 0 || activeTabIndex === 1,
    hasVideo: true,
    flagged: false,
    disabled: false
  })

  const [fetchMoreTalentMedia] = useLazyQuery(TalentAPI.getMediaListData())

  useEffect(() => {
    if (rTalentsDataWithVideos.length) {
      setAllTalentsData(rTalentsDataWithVideos)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (hasNextPageCalled && mediaAPICallFinished) {
        fetchNextPageData()
      }
    }, 2000)
  }, [mediaAPICallFinished])

  useEffect(() => {
    if (talentListData?.listTalent?.talents.length > 0) {
      setAllTalentsData(
        talentListData?.listTalent.talents.map(item => ({
          ...item,
          isSelected: false
        }))
      )
      setTotalAllTalentsWithVideo(talentListData?.listTalent?.metadata?.total)
      partiallyMediaAPICalls(talentListData)
      setLoading(false)
    }
  }, [talentListLoading])

  const fetchNextPageData = async () => {
    const { data: searchFetchMoreData } = await fetchMoreTalentList({
      variables: {
        offset: rTalentsDataWithVideos.length,
        limit: TALENT_THRESHOLD_LIMIT,
        hasVideo: true,
        flagged: false,
        disabled: false
      }
    })
    partiallyMediaAPICalls(searchFetchMoreData)
  }

  const partiallyMediaAPICalls = async (talentData) => {
    if (talentData?.listTalent?.talents.length > 0) {
      const mediAPICall = async (item, index) => {
        const { data: newMediaData } = await fetchMoreTalentMedia({
          variables: {
            talentIds: talentData?.listTalent?.talents.slice(item, (index + 1) * 30).map((item) => item?.id),
            disabled: false,
            limit: 5,
            offset: 0
          }
        })
        const mediaList = newMediaData?.mediaList
        const newTalentList = talentData?.listTalent?.talents.slice(item, (index + 1) * 30).map(talent => {
          const medias = mediaList?.filter(media => talent.id === media.talentId)
          return { ...talent, isSelected: false, media: medias }
        })
        return newTalentList
      }

      const arrayForLoop = new Array(THRESHOLD_MEDIA_API_RESPONSE).fill(0).map((item, index) => (index) * 30)

      const [firstData, secondData, thridData, fourthData] = await Promise.all(arrayForLoop.map(async (item, index) => mediAPICall(item, index)))

      setAllTalentsData([...rTalentsDataWithVideos, ...firstData, ...secondData, ...thridData, ...fourthData])
      dispatch(setTalentsWithVideos([...rTalentsDataWithVideos, ...firstData, ...secondData, ...thridData, ...fourthData]))
      setMediaAPICallFinished(true)
    }
  }

  return {
    allTalentsWithVideoData: rTalentsDataWithVideos.length > 0 ? rTalentsDataWithVideos : allTalentsData,
    allTalentWithVideoLoading: rTalentsDataWithVideos.length > 0 ? false : loading,
    totalAllTalentsWithVideo,
    setAllTalentsWithVideoData: setAllTalentsData,
    allTalentsWithVideos: allTalentsData,
    allTalentsWithVideoToggleNextPageCall: () => {
      setHasNextPageCalled(true)
      if (!talentListLoading && rTalentsDataWithVideos.length > 0) {
        setHasNextPageCalled(false)
        fetchNextPageData()
      }
    }
  }
}

export default useAllTalentsWithVideo
