/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { ErrorMessage, Field } from 'formik'
import TextField from '@mui/material/TextField'
import AutocompleteMUI from '@mui/material/Autocomplete'

import { TextError } from '../textError'
import { Fonts } from '../../../constants'

function Autocomplete (props) {
  const { name, label, onChange, options, defaultValue, loading, ...rest } = props
  return (
    <>
      <Field name={name}>
        {fieldProps => {
          const { form, meta } = fieldProps
          const { setFieldValue } = form
          const { error, touched } = meta
          const errorText = error && touched ? error : ''
          return (
            <AutocompleteMUI
              freeSolo
              loading={loading}
              value={defaultValue}
              id={name}
              options={options}
              onChange={(_event, value) => {
                setFieldValue(name, value)
              }}
              getOptionLabel={option => option?.label ?? ''}
              renderInput={params => {
                return (
                <TextField
                  fullWidth
                  error={error && touched}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: {
                      fontSize: 16,
                      fontWeight: 400
                    }
                  }}
                  variant='standard'
                  inputProps={{ ...params.inputProps }}
                  InputProps={{ ...params.InputProps }}
                  onChange={e => {
                    setFieldValue(name, null)
                    onChange(e.target.value)
                  }}
                  label={label}
                />
                )
              }}
            />
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export default Autocomplete

Autocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.object,
  loading: PropTypes.bool
}
