import { Fragment } from 'react'
import { MenuSpan, NavItemImg, StyledNavLink } from '../../Styles'

import PropTypes from 'prop-types'
import { Images } from '../../../../assets'

const Advertisers = props => {
  // props
  const { pathname } = props
  return (
    <Fragment>
        <StyledNavLink to={'/advertiser'}>
          <NavItemImg
            src={
              pathname === '/advertiser' || pathname.includes('/advertiser/')
                ? Images.advertiserIconActive
                : Images.advertiserIconInActive
            }
          />
          <MenuSpan>Advertiser</MenuSpan>
        </StyledNavLink>
    </Fragment>
  )
}

Advertisers.propTypes = {
  pathname: PropTypes.string
}

export default Advertisers
