import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fragment, useState } from 'react'

import SelectChip from '../SelectChip'
import { ShimmerContentBox } from '../../../../components'
import { useParams } from 'react-router-dom'

const Shimmer = styled(ShimmerContentBox)`
  margin-bottom: 20px;
`

const SelectList = props => {
  // props
  const { id } = useParams()
  const { handleTabData, selectListData, selectListLoading } = props
  const shimmerCount = useState(new Array(10).fill(0).map((_, index) => ({ id: index })))[0]

  return (
    <Fragment>
      {selectListLoading && selectListData?.length === 0 && shimmerCount.map((_, index) => <Shimmer key={index} />)}
      {selectListData?.map((item, index) => (
        <SelectChip
          key={index.toString()}
          data={item}
          isActive={id === item.id}
          onClick={() => handleTabData(item)}
        />
      ))}
      {selectListLoading && <Shimmer />}
    </Fragment>
  )
}

SelectList.propTypes = {
  selectListLoading: PropTypes.bool,
  selectListData: PropTypes.array,

  activeTabData: PropTypes.object,
  handleTabData: PropTypes.func
}

export default SelectList
