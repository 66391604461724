import PropTypes from 'prop-types'

import classNames from 'classnames'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import style from './PortfolioVideoPreview.module.scss'
import MediaAPI from '../../../../services/Media/MediaAPI'
import { DefaultErrorTooltip } from '../../../../components/Tooltip'
import Video from '../../../../assets/images/TalentDetails/Video.svg'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import ErrorIcon from '../../../../assets/images/TalentDetails/Error.svg'
import { setFinalVideoList } from '../../../../redux/actions/TalentVideoAction'
import { SpinnerXtraSmall, LinearProgress } from '../../../../components/Progress'

function PortfolioVideoFailed (props) {
  // props
  const { media } = props
  // hooks
  const { id } = useParams()
  const dispatch = useDispatch()

  // constants
  const videoList = useSelector(state => state.TalentVideoReducer?.filter(video => video.isUploading ? video.talentId === id : true))
  // api calls
  const [deleteMediaHandler, { loading: deleteVideoLoading }] = useMutation(MediaAPI.deleteMedia(), { errorPolicy: 'all' })

  const handleDeleteVideo = async (id, fileName) => {
    const { errors: deleteMediaError } = await deleteMediaHandler({ variables: { mediaDeleteId: id } })
    if (deleteMediaError?.length) {
      dispatch(showToast({
        isError: true,
        message: deleteMediaError?.map(e => e.message).join(', ')
      }))
    } else {
      const mediaListClone = [...videoList]
      const mediaIndex = mediaListClone.findIndex(item => item?.name === fileName)
      mediaListClone.splice(mediaIndex, 1)
      dispatch(setFinalVideoList([...mediaListClone]))
      dispatch(showToast({
        message: 'Video deleted successfully'
      }))
    }
  }
  // functions
  const removeVideo = fileName => {
    if (media?.processing === 'errored') {
      handleDeleteVideo(media?.id, fileName)
    } else {
      const mediaListClone = [...videoList]
      const mediaIndex = mediaListClone.findIndex(item => item?.name === fileName)
      mediaListClone.splice(mediaIndex, 1)
      dispatch(setFinalVideoList([...mediaListClone]))
    }
  }

  const tooltipTitle = (isSizeExceed, isValidExtension, error) => {
    if (isSizeExceed) {
      return 'File size is more than 100MB'
    } else if (isValidExtension) {
      return 'This file format is not allowed'
    } else {
      return error
    }
  }

  const ErrorFooter = () => {
    return (
      <div className={classNames(style.FooterContainer)}>
        <DefaultErrorTooltip placement='top' title={tooltipTitle(media.isExceedSizeLimit, !media?.isValidExtension, media.errorMessage)}>
          <div className={classNames(style.FooterContainer__Error)}>
            <img src={ErrorIcon} className={classNames(style.FooterContainer__ErrorImg)} />
            <span className={classNames(style.FooterContainer__ErrorText)}>Error</span>
          </div>
        </DefaultErrorTooltip>
        <div className={classNames(style.FooterContainer__Remove)}>
         {deleteVideoLoading
           ? <SpinnerXtraSmall />
           : <button className={classNames(style.FooterContainer__RemoveButton)} onClick={() => removeVideo(media?.name)}>
              <span className={classNames(style.FooterContainer__RemoveButtonText)}>Remove</span>
            </button>
         }
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(style.VideoPreview)}>
      <div className={classNames(style.VideoPreview__Background)}>
        <div className={classNames(style.VideoPreview__BackgroundVideoPlaceholder)}>
          <img className={classNames(style.VideoPreview__BackgroundVideoPlaceholder__Img)} src={Video} />
        </div>
      </div>
      <div className={classNames(style.VideoPreview__Overlay)} />
      {media.isExceedSizeLimit || !media.isValid
        ? <ErrorFooter />
        : <div className={classNames(style.VideoPreview__Progress)}>
           <LinearProgress value={media.progress}/>
        </div>
      }
    </div>
  )
}

PortfolioVideoFailed.propTypes = {
  media: PropTypes.object
}

export default PortfolioVideoFailed
