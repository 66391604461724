import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'
import 'videojs-overlay/dist/videojs-overlay.css'
import PropTypes from 'prop-types'
import 'videojs-overlay'

import classNames from 'classnames'
import style from './VideoJS.module.scss'
import './VideoJS.css'

const VideoJS = (props, ref) => {
  // props
  const { options, handleVideoPlaying, overlayContent } = props
  // refs
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    togglePlay
  }), [playerRef?.current])

  const togglePlay = () => {
    if (playerRef?.current) {
      if (playerRef.current.paused()) {
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }

  useEffect(() => {
    if (playerRef?.current && ref) {
      ref = playerRef
    }
  }, [playerRef.current])

  useEffect(() => {
    if (!playerRef?.current) {
      const videoElement = document.createElement('video-js')

      videoElement.classList.add('vjs-big-play-centered')
      videoRef.current.appendChild(videoElement)

      playerRef.current = videojs(videoElement, options, () => {
        playerRef?.current?.on('playing', () => {
          handleVideoPlaying && handleVideoPlaying(true)
        })

        playerRef?.current?.on('pause', () => {
          handleVideoPlaying && handleVideoPlaying(false)
        })
      })
      if (overlayContent) {
        playerRef?.current?.overlay({
          content: overlayContent ?? ''
        })
      }
    } else {
      const player = playerRef.current

      player.autoplay(options.autoplay)
      player.src(options.sources)
    }
  }, [videoRef, options])

  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div className={classNames(style.Video, 'vjs-fill')} ref={videoRef} />
  )
}

VideoJS.propTypes = {
  options: PropTypes.object,
  handleVideoPlaying: PropTypes.func,
  showWatermark: PropTypes.bool,
  overlayContent: PropTypes.string
}
export default forwardRef(VideoJS)
