import { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { API, Colors, Fonts } from '../../../../constants'
import { ShimmerContentBox, Typography, Button } from '../../../../components'

const Wrapper = styled.div`
  padding: 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  font-family: ${Fonts.degular};
`
const LogoWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 12px;
  border: 0.5px solid ${Colors.primaryGray};
  display: flex;
  align-items: center;
  img {
    max-height: 96px;
    width: 96px;
  }
  div {
    background: ${Colors.borderGray};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 64px;
    font-weight: bold;
  }
`
const InfoWrapper = styled.div`
  margin: 40px 0;
  div {
    display: flex;
  }
`
const InfoTitle = styled.div`
  color: ${Colors.blackOpacity};
  width: 160px;
  margin-right: 32px;
  padding-bottom: 28px;
  font-size: 16px;
`
const ButtonWrapper = styled.div`
  align-self: end;
`
const UserInfoShimmer = styled(ShimmerContentBox)`
  height: ${props => props.height};
  width: ${props => props.width};
  margin-top: ${props => props.marginTop};
  border-radius: ${props => props.borderRadius ?? '0'};
  margin-bottom: ${props => props.marginBottom};
`

export const Detail = ({ data, setIsEditAdvertiserModal, isEditAdvertiserModal }) => {
  const handleEditAdvertiserSidebar = () => setIsEditAdvertiserModal(!isEditAdvertiserModal)

  const [isImageFailed, setIsImageFailed] = useState(false)

  return (
    <Wrapper>
      {data
        ? <>
          <LogoWrapper>
            {data?.logo?.uris?.logo
              ? <img
                  onError={() => setIsImageFailed(true)}
                  src={!isImageFailed ? data.logo?.uris?.logo : `${API.baseUrl}/${data?.logo?.file}`}
                  height={96} style={{ borderRadius: 12, objectFit: 'contain' }} />
              : <div>{data?.name.charAt(0).toUpperCase()}</div>
            }
          </LogoWrapper>
          <InfoWrapper>
            <div>
              <InfoTitle>Company Name</InfoTitle>
              <Typography fontSize={16}>{data?.name ?? '-'}</Typography>
            </div>
            <div>
              <InfoTitle>Type</InfoTitle>
              <Typography fontSize={16}>{`${data?.type[0].toUpperCase()}${data?.type.slice(1).toLowerCase()}`}</Typography>
            </div>
            <div>
              <InfoTitle>Email Address</InfoTitle>
              <Typography fontSize={16}>{data.contact.length ? data?.contact : '-'}</Typography>
            </div>
          </InfoWrapper>
          <ButtonWrapper>
            <Button width="100%" onClick={handleEditAdvertiserSidebar} outline>
              <Typography fontSize={15} fontWeight="bold">
                Edit Details
              </Typography>
            </Button>
          </ButtonWrapper>
        </>
        : <div>
            <UserInfoShimmer
              height={'96px'}
              width={'96px'}
              borderRadius={'12px'}
            />
            <UserInfoShimmer
              marginTop={'10px'}
              height={'25px'}
              borderRadius={'4px'}
            />
            <>
              <UserInfoShimmer
                marginTop={'10px'}
                height={'25px'}
                borderRadius={'4px'}
              />
              <UserInfoShimmer
                marginTop={'10px'}
                height={'25px'}
                borderRadius={'4px'}
                marginBottom={'10px'}
              />
            </>
        </div>}
    </Wrapper>
  )
}

Detail.propTypes = {
  data: PropTypes.object,
  refetchAdvertiser: PropTypes.func,
  setIsEditAdvertiserModal: PropTypes.func,
  isEditAdvertiserModal: PropTypes.bool
}
