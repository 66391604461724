import PropTypes from 'prop-types'
import TalentDetailMain from '../TalentDetailMain'

const Detail = props => {
  const { isPublic, selectData } = props
  return <TalentDetailMain isPublic={isPublic} isUserTalent={false} selectDetail={selectData?.getSelect} />
}

Detail.propTypes = {
  isPublic: PropTypes.bool,
  selectData: PropTypes.object
}

export default Detail
