import { useRef, Fragment } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'

import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { Colors } from '../../../../../constants'
import { RenderIf } from '../../../../../utils/Helpers'
import CountryListData from '../../../../../constants/CountryList.json'
import { FormikControl } from '../../../../../components/formik/formikControl'
import { Button, Typography, ShimmerContentBox } from '../../../../../components'
import { ProjectOverviewValidationSchema } from '../../../../../services/ValidationServices'
import useOrganizationListQuery from '../../../Hooks/useOrganizationListQuery'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
  margin-right: 20px;
`

const ColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => !props.isAdvertiser && 'space-between'};
`
const FieldContainer = styled.div`
  height: 75px;
  width: ${props => (props.width ? props.width : '0px')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`
const DateContainer = styled.div`
  width: 149px;
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
`
const DatesWrapper = styled.div`
  display: flex;
`
const CheckboxContainer = styled.div`
  margin-top: 32px;
`
const ButtonRow = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  align-items: center;
`

const ButtonTray = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-inline: 40px;
  overflow-y: scroll;
`

const ShimmerWrapper = styled.div`
  display: flex;
  flexDirection: row;
  width: 100%;
`

const mediaTypeItems = [
  { label: 'Photos', value: 'photo' },
  { label: 'Videos', value: 'video' }
]
const usageItems = [
  { label: 'Print', value: 'print' },
  { label: 'Broadcast', value: 'broadcast' },
  { label: 'Digital', value: 'digital' },
  { label: 'Out of Home', value: 'out of home' },
  { label: 'Other', value: 'other' }
]

const ProjectOverview = (props) => {
  // props
  const { overviewData, handleToggle, handleRoleDates, setOverviewData, handleActiveStep, projectDetaildata, projectDetailLoading } = props
  // ref
  const formikRef = useRef(null)

  // constants
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const formattedCountryData = Object.keys(CountryListData).map(e => {
    const obj = {}
    obj.id = e
    obj.name = CountryListData[e]
    return obj
  }).filter((country) => country.id !== 'CA' && country.id !== 'US')

  // hooks
  const { id } = useParams()

  // api calls
  const { data: organizationData, loading: organizationLoading } = useOrganizationListQuery(id)

  // initial values
  let initialValues
  initialValues = {
    projectName: projectDetaildata?.name ?? '',
    startDate: projectDetaildata?.startDate ?? null,
    endDate: projectDetaildata?.endDate ?? null,
    advertiser: userInfo?.type === 'advertiser' ? userInfo?.ownerId : JSON.stringify(projectDetaildata?.ownerId) ?? '',
    mediaType: projectDetaildata?.mediaType ?? [],
    usage: projectDetaildata?.usage ?? [],
    description: projectDetaildata?.description ?? '',
    notes: projectDetaildata?.notes ?? '',
    postalCode: projectDetaildata?.postalCode ?? '',
    countryCodeLocation: JSON.stringify(projectDetaildata?.countryCodeLocation ?? 'US')
  }

  if (overviewData) {
    initialValues = {
      projectName: overviewData?.name ?? '',
      startDate: overviewData?.startDate ?? null,
      endDate: overviewData?.endDate ?? null,
      advertiser: userInfo?.type === 'advertiser' ? userInfo?.ownerId : JSON.stringify(overviewData?.ownerId) ?? '',
      mediaType: overviewData?.mediaType ?? [],
      usage: overviewData?.usage ?? [],
      description: overviewData?.description ?? '',
      notes: overviewData?.notes ?? '',
      postalCode: overviewData?.postalCode ?? '',
      countryCodeLocation: JSON.stringify(overviewData?.countryCodeLocation) ?? ''
    }
  }

  const handleNext = (roleDates) => {
    handleActiveStep(1)
    if (roleDates) {
      handleRoleDates(roleDates)
    }
  }

  const handleFormSubmit = data => {
    const { advertiser, endDate, startDate, mediaType, usage, projectName, description, notes, postalCode, countryCodeLocation } = data

    const modifiedData = {
      name: projectName.trim(),
      startDate,
      endDate,
      mediaType,
      usage,
      ownerId: userInfo?.type === 'advertiser' ? userInfo?.ownerId : JSON.parse(advertiser),
      description: description.trim(),
      notes: notes.trim(),
      postalCode,
      countryCodeLocation: JSON.parse(countryCodeLocation)
    }
    const dates = {
      startDate,
      endDate
    }
    handleRoleDates(dates)

    if (id) {
      setOverviewData({
        ...modifiedData,
        id: projectDetaildata?.id,
        selectId: projectDetaildata?.selectId
      })
      handleNext()
    } else {
      setOverviewData({
        ...modifiedData
      })
      handleNext()
    }
  }

  const Loader = () => {
    return (
      <Fragment>
        <ShimmerWrapper style={{ marginBottom: 30 }} >
          <ShimmerContentBox style={{ height: 50 }} />
          <div style={{ width: 50 }} />
          <ShimmerContentBox style={{ height: 50 }} />
        </ShimmerWrapper>
        <ShimmerWrapper>
          <ShimmerContentBox style={{ height: 50 }} />
          <div style={{ width: 50 }} />
          <ShimmerContentBox style={{ height: 50 }} />
        </ShimmerWrapper>
      </Fragment>
    )
  }
  return (
    <>
      <Container>
        <RenderIf isTrue={projectDetailLoading || organizationLoading}>
          <Loader />
        </RenderIf>
        <RenderIf isTrue={!projectDetailLoading && !organizationLoading}>
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ProjectOverviewValidationSchema}
            onSubmit={handleFormSubmit}>
            {formik => (
              <Form style={{ height: '100%' }}>
                <Wrapper>
                  <ColumnContainer>
                    <ColumnOne>
                      <FieldContainer marginTop={'37.5px'} width={'310px'}>
                        <FormikControl
                          name="projectName"
                          isStandard
                          control="input"
                          label="Project Name"
                          required
                        />
                      </FieldContainer>
                      <FieldContainer
                        width={'310px'}
                        marginTop={'37.5px'}
                        style={{ height: '100%' }}>
                        <FormikControl
                          name="description"
                          isStandard
                          control="input"
                          label="Description"
                          required
                          multiline
                          rows={5}
                        />
                      </FieldContainer>
                    </ColumnOne>
                    <ColumnTwo isAdvertiser={userInfo?.type === 'advertiser'}>
                      <FieldContainer
                        marginRight={'25px'}
                        width={'310px'}
                        marginTop={'37.5px'}>
                          <Stack direction="row">
                            <FormikControl
                              control="select"
                              label="Country"
                              name="countryCodeLocation"
                              isStandard
                              placeholder="countryCodeLocation"
                              containerStyle={{
                                marginRight: 10,
                                display: 'flex',
                                height: 50,
                                width: 100
                              }}
                              selectProps={{
                                renderValue: (e) => JSON.parse(e)
                              }}
                              style={{ height: 100 }}
                              options={[{ id: 'US', name: 'United States' }, { id: 'CA', name: 'Canada' }].concat(formattedCountryData)}
                              hidelabel
                            />
                            <FormikControl
                              name="postalCode"
                              isStandard
                              control="input"
                              label="Postal code"
                            />
                          </Stack>
                      </FieldContainer>
                      <DatesWrapper>
                        <DateContainer marginRight={'12px'}>
                          <FormikControl
                            name="startDate"
                            isStandard
                            control="date"
                            label='Start Date'
                            disablePast={false}
                            loading={projectDetailLoading}
                          />
                        </DateContainer>
                        <DateContainer>
                          <FormikControl
                            name="endDate"
                            isStandard
                            control="date"
                            disablePast={false}
                            minDate={moment(formik.values.startDate) ?? undefined}
                            label='End Date'
                            loading={projectDetailLoading}
                          />
                        </DateContainer>
                      </DatesWrapper>
                      <RenderIf isTrue={userInfo?.type !== 'advertiser'}>
                       <FieldContainer
                        marginRight={'25px'}
                        width={'310px'}
                        marginTop={'37.5px'}>
                          {
                            id
                              ? null
                              : <FormikControl
                                  name="advertiser"
                                  isStandard
                                  control="select"
                                  label="Select Advertiser"
                                  options={organizationLoading ? [] : organizationData?.organizationList}
                                />
                          }
                      </FieldContainer>
                    </RenderIf>
                    </ColumnTwo>
                  </ColumnContainer>
                  <CheckboxContainer>
                    <FormikControl
                      name="mediaType"
                      control="checkbox"
                      label="Media Type"
                      items={mediaTypeItems}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <FormikControl
                      name="usage"
                      control="checkbox"
                      label="Usage"
                      items={usageItems}
                    />
                  </CheckboxContainer>
                  <FieldContainer
                    marginRight={'25px'}
                    width={'322px'}
                    marginTop={'37.5px'}
                    style={{ height: '100%' }}>
                    <FormikControl
                      name="notes"
                      isStandard
                      control="input"
                      label="Notes"
                      required
                      multiline
                      rows={5}
                    />
                  </FieldContainer>
                </Wrapper>
              </Form>
            )}
          </Formik>
        </RenderIf>
      </Container>
      <ButtonRow>
        <ButtonTray>
          <Button
            backgroundColor={Colors.white}
            onClick={handleToggle}>
            <Typography fontWeight='bold' fontSize={15} letterSpacing='0.46px'>CANCEL</Typography>
          </Button>
          <Button
            onClick={() => formikRef?.current.submitForm()}>
            <Typography fontWeight='bold' fontSize={15} letterSpacing='0.46px' color={Colors.white}>NEXT</Typography>
          </Button>
        </ButtonTray>
      </ButtonRow>
    </>
  )
}

export default ProjectOverview

ProjectOverview.propTypes = {
  handleToggle: PropTypes.func,
  handleActiveStep: PropTypes.func,
  resetProjectList: PropTypes.func,
  handleRoleDates: PropTypes.func,
  overviewData: PropTypes.object,
  setOverviewData: PropTypes.func,
  projectDetaildata: PropTypes.object,
  projectDetailLoading: PropTypes.bool
}
