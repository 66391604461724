import { useQuery } from '@apollo/client'
import DemographicAPI from '../../../services/DemographicAPI'

function useGetTalentDemographic (id) {
  const { data, loading, refetch } = useQuery(DemographicAPI.getTalentDemographicData(id), {
    variables: {
      talentId: id
    },
    skip: !id
    // fetchPolicy: 'no-cache',
    // notifyOnNetworkStatusChange: true
  })

  return {
    data,
    loading,
    refetch
  }
}

export default useGetTalentDemographic
