import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import SelectAPI from '../../../services/Select/SelectAPI'

function useChildSelectListQuery (props) {
  const { parentSelectId, archived } = props
  // api calls
  const [getChildSelectListQuery, { data, loading, refetch }] = useLazyQuery(SelectAPI.getChildSelectList(), { fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (parentSelectId) {
      getChildSelectList()
    }
  }, [parentSelectId])

  const getChildSelectList = () => {
    getChildSelectListQuery({
      variables: {
        offset: 0,
        limit: 100,
        archived,
        parentId: parentSelectId,
        sort: JSON.stringify({
          created_at: 'asc',
          id: 'asc'
        })
      }
    })
  }
  return {
    data,
    loading,
    refetch
  }
}

export default useChildSelectListQuery
