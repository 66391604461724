import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import { showToast } from '../../redux/actions/DashBoardActions'
import { useCreateBrand } from '../../hooks/organization/brand'

import style from './brand.module.scss'

/**
 * Handler to blindly update React State when a text input is changed
 * @param e Original HTML Input Change Event
 * @param setter React SetStateAction to save the updated value
 */
const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, setter: (data: any) => void): void => {
  setter(e.target.value)
}

interface BrandHandlers {
  showError: () => void
  showSuccess: () => void
  createBrand: (data: any, onSuccess: any) => void
}

/**
 * Handler that create a FormData object and calls the uploadMedia service on form submit
 * @param e Original HTML Form Submit Event
 * @param media ReactState representing the selected file
 * @param type ReactState representing the coerced file type
 * @param category ReactState representing the selected category
 * @param entityID ReactState representing the input entity ID
 * @param handlers Object containing functions to show error and success toasts, set the media ID and create the media object
 */
const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, orgID: string, name: string, logo: string, handlers: BrandHandlers): Promise<void> => {
  e.preventDefault()
  e.stopPropagation()

  if (orgID === '') {
    throw Error('No Advertiser ID provided')
  }

  const opts: {
    orgId: string
    name: string
    logo?: string
  } = {
    orgId: orgID,
    name,
    logo
  }

  if (logo === '') {
    delete opts.logo
  }

  handlers.createBrand(opts, (data: any) => {
    console.log('create brand', data)
    handlers.showSuccess()
  })
}

export const ToolsBrand = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.UserReducer)

  if (user.isAdmin === false) {
    navigate('/404/')
    return <></>
  }

  const [createBrand, { loading: brandCreating }] = useCreateBrand() as unknown as [any, { loading: boolean }]

  const [orgID, setOrgID] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [logo, setLogo] = useState<string>('')

  const showError = (): void => { dispatch(showToast({ isError: true, message: 'Something went wrong.' })) }
  const showSuccess = (): void => { dispatch(showToast({ message: 'Brand Created Successfully.' })) }

  return (
    <Layout>
      <form onSubmit={(e) => { void handleSubmit(e, orgID, name, logo, { showError, showSuccess, createBrand }) }}>
        <fieldset>
          <input type='text' name='orgID' placeholder='Advertiser ID' onChange={(e) => { handleTextChange(e, setOrgID) }} value={orgID} required />
        </fieldset>

        <fieldset>
          <input type='text' name='name' placeholder='Brand Name' onChange={(e) => { handleTextChange(e, setName) }} value={name} required />
        </fieldset>

        <fieldset>
          <input type='text' name='logo' placeholder='Logo Media ID' onChange={(e) => { handleTextChange(e, setLogo) }} value={logo} />
        </fieldset>

        <fieldset>
          <input type='submit' value='Create' />
        </fieldset>
      </form>

      { brandCreating && <div className={style.Loading}>Creating...</div> }
    </Layout>
  )
}
