import { CircularProgress, Typography } from '@mui/material'
import styled from 'styled-components'

const CircularProgressContainer = styled.div`
  height: 50px;
  width: 100%;
  display: grid;
  place-items: center;
`

const FooterLoader = () => {
  return (
    <>
      <CircularProgressContainer>
        <CircularProgress color="inherit" />
        <Typography fontSize={14} style={{ marginTop: 20 }}>
          Loading more users.
        </Typography>
      </CircularProgressContainer>
    </>
  )
}

export default FooterLoader
