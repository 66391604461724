import { useQuery } from '@apollo/client'
import { VOTE_BY_USER } from '../../../services/API'

function useVoteByUserQuery (id, skip) {
  const { data, loading } = useQuery(VOTE_BY_USER, {
    variables: {
      selectId: id
    },
    fetchPolicy: 'no-cache',
    skip
  })

  return { data, loading }
}

export default useVoteByUserQuery
