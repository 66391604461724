/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'

import { Button } from '../../../../components'
import { Colors, Fonts } from '../../../../constants'
import useUpdateTalentMutation from '../../Hooks/useUpdateTalentMutation'

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding: 0 35px;
`

const Heading = styled.p`
  font-family: ${Fonts.degular};
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`

const NotesContainer = styled.div`
  height: 168px;
  border: 1px solid ${props => props.enabled ? Colors.black : '#EBEBEB'};
  border-radius: 6px;
  padding: 14px;
`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
`

const SaveText = styled.p`
    font-family: ${Fonts.degular};
    font-size: 13px;
    font-weight: 700;
    color: ${props => !props.enabled ? Colors.black : Colors.white};
`

const Notes = props => {
  // props
  const { data } = props
  // states
  const [notes, setNotes] = useState('')
  const [enabled, setEnabled] = useState(false)

  // api calls
  const { updateTalent, loading } = useUpdateTalentMutation()

  // loading notes into states
  useEffect(() => {
    if (data?.notes) {
      setNotes(data.notes)
    }
  }, [data])

  const submitNotesUpdate = () => {
    setEnabled(!enabled)
    if (enabled) {
      updateTalent(
        data.id,
        {
          notes
        },
        () => null
      )
    }
  }

  return (
    <Container>
      <Heading>Notes</Heading>
      <NotesContainer enabled={false} >
        <TextField
          fullWidth
          id="outlined-multiline-flexible"
          label=""
          placeholder="+ Add the Notes"
          multiline
          variant='standard'
          disabled={!enabled}
          InputProps={{
            disableUnderline: true,
            style: {
              color: Colors.black,
              fontFamily: Fonts.degular,
              opacity: enabled ? 1 : 0.5
            }
          }}
          maxRows={7}
          value={notes}
          onChange={e => setNotes(e.target.value)}
        />
      </NotesContainer>
      <ActionWrapper>
        <Button outline={!enabled} loading={loading} onClick={submitNotesUpdate} minWidth="100px">
            <SaveText enabled={enabled}> {!enabled ? 'EDIT' : 'SAVE'}</SaveText>
        </Button>
      </ActionWrapper>
    </Container>
  )
}

Notes.propTypes = {
  data: PropTypes.object
}

export default Notes
