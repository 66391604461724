import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import TabBar from '../TabBar'
import SearchBox from '../SearchBox'

import { Images } from '../../../../assets'
import { Button, Typography } from '../../../../components'
import { RenderIf } from '../../../../utils/Helpers'

import { useOutsideHandler } from '../../../../hooks'
import { Colors, TalentConstants } from '../../../../constants'

const MainWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  background: white;
  z-index: 800;
  p {
    margin: 0;
  };
`

const TabBarContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 42px;
`

const SelectModeButton = styled.button`
  border: ${props => !props.selectionMode ? '1px solid' : '0px'};
  display: flex;
  height: 42px;
  width: ${props => (!props.selectionMode ? '160px' : '78px')};
  white-space: nowrap;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
`

const FilterContainer = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const FilterImage = styled.img`
  height: 16px;
  margin-right: 12px;
`

const ResizeContainer = styled.div`
  border: 1px solid black;
  padding: 0px 20px 0px 10px;
  width: 80px;
  margin-left: 20px;
  height: 42px;
  box-sizing: border-box;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  margin-right: ${props => props.isUserTalents ? '0px' : '15px'};
`

const MenuPopupBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  background: white;
  z-index: 1000;
  width: 178px;
  height: 126px;
  right: ${props => (props.isUserTalents ? '65px' : '350px')};
  top: ${props => (props.isUserTalents ? '150px' : '30px')};
  padding-left: 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
`

const ResizeListItem = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
`

const RESIZE_DATA = [
  {
    id: 0,
    key: 'SM',
    img: Images.resizeMinimal,
    title: 'Minimal'
  },
  {
    id: 1,
    key: 'MD',
    img: Images.resizeCompact,
    title: 'Compact'
  },
  {
    id: 2,
    key: 'LG',
    img: Images.resizeMaximum,
    title: 'Maximum'
  }
]

const HeaderBottom = props => {
  // props
  const {
    activeTabIndex,
    handleActiveTabIndex,
    setSelectionMode,
    selectionMode,
    toggleModal,
    isVisibleModal,
    toggleFilterModal,
    showHeaderButtons,
    isSticky,
    handleResetSelection,
    resizeType,
    handleTalentCard,
    isUserTalents = false,
    selectedTalentlength = 0
  } = props

  // states
  const [showResizeModal, setShowResizeModal] = useState(false)
  const user = useSelector(state => state.UserReducer)

  // constants
  const popupRef = useRef(null)

  // hooks
  useOutsideHandler(popupRef, () => setShowResizeModal(false))

  // functions
  const getResizeIcon = () => {
    switch (resizeType) {
      case 'SM':
        return Images.resizeMinimal
      case 'MD':
        return Images.resizeCompact
      case 'LG':
        return Images.resizeMaximum
    }
  }

  const toggleResizeModal = () => setShowResizeModal(!showResizeModal)

  // components
  const ShortModal = () => {
    return (
      <ResizeContainer isUserTalents={isUserTalents} onClick={toggleResizeModal}>
        <img src={getResizeIcon()} height={14} />
        <img
          src={Images.resizeArrowdown}
          height={5}
          style={{
            alignSelf: 'center',
            cursor: 'pointer',
            rotate: showResizeModal ? '180deg' : '0deg'
          }}
        />
      </ResizeContainer>
    )
  }

  return (
    <MainWrapper>
      <TabBarContainer>
        <RenderIf isTrue={user.isAdmin}>
          {!isSticky
            ? <TabBar
              data={isUserTalents ? TalentConstants.UserTalent.tabTitleList : TalentConstants.Talent.tabTitleList}
              activeTabIndex={activeTabIndex}
              handleActiveTabIndex={handleActiveTabIndex}
            />
            : <SearchBox isLarge={true} />
          }
        </RenderIf>
      </TabBarContainer>
      {!showHeaderButtons && <ShortModal />}

      <RenderIf isTrue={showResizeModal && isUserTalents}>
        <MenuPopupBox ref={popupRef} isUserTalents={isUserTalents}>
          {RESIZE_DATA.map(item => (
            <ResizeListItem key={item.id} onClick={() => handleTalentCard(item.key)}>
              <img src={item.img} height={16} style={{ marginRight: 10 }} />
              <Typography fontSize={16}>{item.title}</Typography>
            </ResizeListItem>
          ))}
        </MenuPopupBox>
      </RenderIf>

      <RenderIf isTrue={showHeaderButtons}>
       <ButtonView>
          <FilterContainer onClick={toggleFilterModal}>
            <FilterImage src={Images.filter} />
            <Typography fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>
              FILTERS
            </Typography>
          </FilterContainer>
          <ShortModal />
          <RenderIf isTrue={showResizeModal}>
            <MenuPopupBox ref={popupRef}>
              {RESIZE_DATA.map(item => (
                <ResizeListItem key={item.id} onClick={() => handleTalentCard(item.key)}>
                  <img src={item.img} height={16} style={{ marginRight: 10 }} />
                  <Typography fontSize={16}>{item.title}</Typography>
                </ResizeListItem>
              ))}
            </MenuPopupBox>
          </RenderIf>
          <SelectModeButton
            onClick={() => {
              handleResetSelection()
              setSelectionMode(!selectionMode)
            }}
            selectionMode={selectionMode}>
            <Typography fontSize={15} fontWeight={'bold'} letterSpacing={0.46}>
              {selectionMode ? 'CANCEL' : 'SELECT TALENT'}
            </Typography>
          </SelectModeButton>
          <RenderIf isTrue={user.isAdmin && !selectionMode}>
            <Button onClick={() => toggleModal(!isVisibleModal)} disabled={selectionMode ? selectedTalentlength < 1 : false}>
              <Typography
                color={Colors.white}
                fontWeight={'bold'}
                fontSize={15}
                letterSpacing={0.46}>
                {selectionMode ? 'ADD TO SELECT' : 'ADD TALENT'}
              </Typography>
            </Button>
          </RenderIf>

          <RenderIf isTrue={selectionMode}>
            <Button onClick={() => toggleModal(!isVisibleModal)} disabled={selectedTalentlength < 1}>
               <Typography
                 color={Colors.white}
                 fontWeight={'bold'}
                 fontSize={15}
                 letterSpacing={0.46}>
                 ADD TO SELECT
               </Typography>
             </Button>
          </RenderIf>

        </ButtonView>
      </RenderIf>

    </MainWrapper>
  )
}

HeaderBottom.propTypes = {
  activeTabIndex: PropTypes.number,
  handleActiveTabIndex: PropTypes.func,
  setSelectionMode: PropTypes.func,
  selectionMode: PropTypes.bool,
  toggleModal: PropTypes.func,
  isVisibleModal: PropTypes.bool,
  toggleFilterModal: PropTypes.func,
  showHeaderButtons: PropTypes.bool,
  isSticky: PropTypes.bool,
  handleResetSelection: PropTypes.func,
  resizeType: PropTypes.string,
  handleTalentCard: PropTypes.func,
  isUserTalents: PropTypes.bool,
  selectedTalentlength: PropTypes.number
}

export default HeaderBottom
