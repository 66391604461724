import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Images } from '../../../assets'
import { Colors } from '../../../constants'

const SearchBarView = styled.div`
  border: 1px solid ${Colors.borderGray};
  width: ${props => props.isLarge ? '100%' : '400px'};
  box-sizing: border-box;
  background: ${Colors.white};
  padding: 10px 0px;
  display: flex;
  align-items: center;
  margin-right: ${props => props.isLarge ? '35px' : '0'};
`

const SearchIcon = styled.img`
  padding-left: 8px;
  padding-right: 8px;
`

const SearchBar = styled.input`
  flex-direction: row;
  border: 0px;
  width: 100%;
  height: 24px;
  outline: none;
`
const SearchCrossWrapper = styled.div`
 height: 100%;
 width: 20px;
 margin-right: 5px;
 cursor: pointer;
 display: ${props => !props.show && 'none'}
`

const MainSearch = ({
  isLarge = false,
  handleSearchInput,
  search,
  handleClearSearch
}) => (
  <SearchBarView isLarge={isLarge}>
    <SearchIcon src={Images.searchIcon} alt={Images.searchIcon} />
    <SearchBar
      onChange={handleSearchInput}
      value={search}
      placeholder="Search for talents"
    />
    <SearchCrossWrapper show={search} onClick={handleClearSearch}>
      <img src={Images.cross} style={{ height: 20 }} />
    </SearchCrossWrapper>
  </SearchBarView>
)
export default MainSearch

MainSearch.propTypes = {
  isLarge: PropTypes.bool,
  handleSearchInput: PropTypes.func,
  search: PropTypes.string,
  handleClearSearch: PropTypes.func
}
