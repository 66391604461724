import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import MediaAPI from '../../services/Media/MediaAPI'
import { showToast } from '../../redux/actions/DashBoardActions'

const useMediaUploadMutation = () => {
  const dispatch = useDispatch()

  const [mediaUploadMutation, { loading }] = useMutation(MediaAPI.mediaUploadMutation(), {
    notifyOnNetworkStatusChange: true
  })

  const mediaUpload = async (variables) => {
    const { talentId, type, extension, category } = variables
    try {
      const response = await mediaUploadMutation({
        variables: {
          talentId,
          type,
          extension,
          category
        }
      })
      return response
    } catch (err) {
      dispatch(showToast({
        message: 'Internal server Error.',
        isError: true
      }))
    }
  }
  return [mediaUpload, { loading }]
}

export default useMediaUploadMutation
