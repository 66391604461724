import { useDispatch } from 'react-redux'
import { generateProjectBranchLink } from '../../utils/ProjectHelper'
import { showToast } from '../../redux/actions/DashBoardActions'

interface ShareProject {
  handleProjectShare: (id?: string) => Promise<void>
}

export const useShareProject = (): ShareProject => {
  const dispatch = useDispatch()

  const handleProjectShare = async (id?: string): Promise<void> => {
    try {
      if (id === undefined) {
        throw new Error('Project id is missing')
      }

      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

      if (isSafari) {
        // NOTE: Safari locks down the clipboard API to only work when triggered
        //   by a direct user interaction. You can't use it async in a promise.
        //   But! You can wrap the promise in a ClipboardItem, and give that to
        //   the clipboard API.
        //   Found this on https://developer.apple.com/forums/thread/691873

        const projectShareableLink: any = new ClipboardItem({
          'text/plain': generateProjectBranchLink(id)
        })

        await navigator.clipboard.write([projectShareableLink])
      } else {
        // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
        //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
        //   Good news is that other than Safari, Firefox does not care about
        //   Clipboard API being used async in a Promise.

        const shareProjectLink = await generateProjectBranchLink(id)
        await navigator.clipboard.writeText(shareProjectLink)
      }
      dispatch(showToast({ message: 'Link copied to clipboard' }))
    } catch (error: any) {
      dispatch(showToast({ message: error?.message ?? 'Something went wrong', isError: true }))
    }
  }

  return { handleProjectShare }
}
