import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import TalentAPI from '../../../services/Talent/TalentAPI'

function useGetTalentListQuery (props) {
  // props
  const { talentIds } = props
  // states
  const [talentListData, setTalentListData] = useState([])
  const [loading, setLoading] = useState(false)
  // api calls
  const [getTalentListQuery] = useLazyQuery(TalentAPI.getTalentList(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  // invoking lazy query based on talent ids
  useEffect(() => {
    setLoading(true)
    if (talentIds === '' || talentIds === null) {
      setTalentListData([])
      setLoading(false)
    } else {
      getTalentList(talentIds)
    }
  }, [talentIds])

  const getTalentList = async talentIds => {
    try {
      const response = await getTalentListQuery({
        variables: {
          offset: 0,
          limit: 7000,
          ids: talentIds
        }
      })
      if (response.data.listTalent.talents?.length > 0) {
        setTalentListData(response.data.listTalent.talents)
      } else {
        setTalentListData([])
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return {
    talentListData,
    loading
  }
}

export default useGetTalentListQuery
