// TODO: Move styles to scss and convert code to TS
import { useEffect, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors, Fonts, UIConstants } from '../../constants'
import { Images } from '../../assets'
import { showToast } from '../../redux/actions/DashBoardActions'
import SelectAPI from '../../services/Select/SelectAPI'
import style from './AddTalentToSelectsSubMenu.module.scss'
import { SpinnerSmall } from '../Progress'

const TopMenuText = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 143%;
  margin-top: 10px;
  letter-spacing: 0.15px;
  color: ${Colors.black};
`

const SubMenuText = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 143%;
  margin-left: 23px;
  margin-top: 10px;
  color: ${Colors.black};
`

const AddNewRoleText = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 143%;
  text-decoration: underline;
  margin-left: 80px;
  margin-top: 13px;
  letter-spacing: 0.15px;
  color: ${Colors.black};
`

const DropDownIcon = styled.img`
  width: 21px;
  height: 21px;
  display: flex;
  position: relative;
  margin-left: 70px;
  margin-top: 12px;
`

const InnerTextDiv = styled.div`
  width: 100%;
  margin-top: 3px;
  overflow: hidden;
`

const TextBox = styled.input`
  width: 100%;
  border: none;
  font-size: 17px;
  margin-left: 20px;
  height: 30px;
  margin-top: 5px;
  letter-spacing: 0.15px;
  color: ${Colors.black};
`

const TopMenuDiv = styled.div`
  border-top: 1px solid ${Colors.symbolGray};
  min-height: 45px;
  flex-direction: row;
  display: flex;
  cursor: pointer;
`

const CheckboxWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
`

const RenderEmptyProfile = (props) => {
  const { visible } = props

  if (!visible) {
    return <></>
  }

  return (
    <div className={style.EmptyProfile}> <p className={style.EmptyProfile__Text}> This Select doesn&apos;t have any profile.</p> </div>
  )
}

RenderEmptyProfile.propTypes = {
  visible: PropTypes.bool
}

const Roles = ({ textElement, handleOnSelect, role }) => {
  return (
    <TopMenuDiv>
      <CheckboxWrapper>
        <Checkbox
          onClick={() => handleOnSelect(role)}
          checked={role?.isSelected}
          sx={{ '&.Mui-checked': { color: Colors.black } }}
        />
      </CheckboxWrapper>
      {textElement}
    </TopMenuDiv>
  )
}

Roles.propTypes = {
  textElement: PropTypes.element,
  handleOnSelect: PropTypes.func,
  role: PropTypes.object
}

export const AddTalentSubMenu = subMenuProps => {
  // init
  const { select, selectList, setSelectList, parentIndex, handelToggelSubList, addNewSubselect } = subMenuProps
  const dispatch = useDispatch()


  const isProjectInDraft = selectList[parentIndex]?.project?.status === 'draft'

  // states
  const [subSelectName, setSubSelectName] = useState('')

  // api calls
  const [fetchChildSelect, { loading }] = useLazyQuery(SelectAPI.getChildSelectList(), { skip: !select.isSelected, fetchPolicy: 'no-cache' })
  const [AddSelect] = useMutation(SelectAPI.createSelect(), { notifyOnNetworkStatusChange: true })

  useEffect(() => {
    if (select.isSelected) {
      handleFetchChildSelects()
    }
  }, [select.isSelected])

  const handleFetchChildSelects = async () => {
    try {
      const subSelectResponse = await fetchChildSelect({ variables: { offset: 0, limit: 25, parentId: selectList[parentIndex].id } })

      if (subSelectResponse.data.listSelects?.length > 0) {

        selectList[parentIndex].subSelect = subSelectResponse.data.listSelects.map(
          subSelect => {
            return {
              ...subSelect,
              isSelected: !!selectList[parentIndex]?.subSelect?.find((subSel) => subSel.id === subSelect?.id)?.isSelected
            }
          }
        )
        setSelectList([...selectList])
      }

    } catch (error) {
      dispatch(showToast({ message: error?.message ?? 'Something went wrong', isError: true }))
    }
  }

  const handelChangeRole = (e, index) => {
    e.preventDefault()
    setSubSelectName(e.target.value)
    selectList[parentIndex].subSelect[index].name = e.target.value
    setSelectList(selectList)
  }

  const handelSubmitSubSelect = async (index) => {
    if (subSelectName) {
      try {
        const addSelectResponse = await AddSelect({
          variables: {
            parentId: selectList[parentIndex].id,
            name: subSelectName,
            ownerId: selectList[parentIndex]?.ownerId ?? null
          }
        })

        if (addSelectResponse?.data?.createSelect?.id) {
          selectList[parentIndex].subSelect[index].id = addSelectResponse.data.createSelect.id
          selectList[parentIndex].subSelect[index].isSelected = false
          setSubSelectName('')
          setSelectList([...selectList])
        }
      } catch (error) {
        dispatch(showToast({ message: error?.message ?? 'Something went wrong', isError: true }))
      }
    }
  }

  const handleOnSelect = (subSelectIndex) => {
    selectList[parentIndex].subSelect[subSelectIndex].isSelected = !selectList[parentIndex].subSelect[subSelectIndex].isSelected
    setSelectList([...selectList])
  }

  return (
    <>
      <TopMenuDiv onClick={handelToggelSubList}>
        <InnerTextDiv>
          <TopMenuText>{select.name}</TopMenuText>
        </InnerTextDiv>
        {select.isSelected ? <DropDownIcon src={Images.minusDropDown} /> : <DropDownIcon src={Images.plusDropDown} />}
      </TopMenuDiv>

      {loading && select.subSelect?.length === 0 && (
        <div className={style.Main__SubSelectSpinnerWrapper}>
          <SpinnerSmall />
        </div>
      )}

      {select.isSelected && select.subSelect?.length > 0 && select.subSelect.map(
        (role, index) =>
          role.id && (
            <Roles
              handleOnSelect={() => handleOnSelect(index)}
              key={index}
              textElement={<InnerTextDiv onClick={() => handleOnSelect(index)}><SubMenuText>{role?.name}</SubMenuText></InnerTextDiv>}
              role={role}
            />
          )
      )}

      <RenderEmptyProfile visible={select.isSelected && !loading && isProjectInDraft && select.subSelect?.length === 0} />

      {select.isSelected && !select.project && select.subSelect?.length > 0 && select.subSelect.map(
        (subSelect, index) =>
          !subSelect?.id && (
            <Roles
              handleOnSelect={() => handleOnSelect(index)}
              key={index}
              textElement={<TextBox onChange={e => handelChangeRole(e, index)} onBlur={() => handelSubmitSubSelect(index)} placeholder={'Add new role here'} />}
            />
          )
      )}
      {!select.project && select.isSelected && (
        <TopMenuDiv onClick={addNewSubselect}>
          <AddNewRoleText>{UIConstants.addNewRoleText}</AddNewRoleText>
        </TopMenuDiv>
      )}
    </>
  )
}

AddTalentSubMenu.propTypes = {
  select: PropTypes.object,
  handelToggelSubList: PropTypes.func
}
