import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { showToast } from '../../redux/actions/DashBoardActions'

import MediaAPI from '../../services/Media/MediaAPI'

export function useCreateMediaMutation () {
  // Hook
  const dispatch = useDispatch()
  // Mutation
  const [createMediaMutation, { loading }] = useMutation(MediaAPI.createMediaMutation(), {
    notifyOnNetworkStatusChange: true
  })

  const createMedia = (variables, onSuccess) => {
    createMediaMutation({
      variables: {
        ...variables
      }
    })
      .then(res => {
        onSuccess(res.data.mediaCreate?.id)
      })
      .catch(e => dispatch(showToast({
        message: 'Internal server Error.',
        isError: true
      })))
  }
  return [createMedia, { loading }]
}

export default useCreateMediaMutation
