import types from '../types/types'

const INITIAL_STATE = {
  mediaList: [],
  isMediaUploadInProgress: false
}

const TalentDetailReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_MEDIA_LIST:
      return {
        ...state,
        mediaList: payload
      }
    case types.SET_MEDIA_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        isMediaUploadInProgress: payload
      }
    case types.CLEAR_STATE: {
      return state
    }
    default:
      return state
  }
}

export default TalentDetailReducer
