import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useFeature } from '@growthbook/growthbook-react'

import style from './Portfolio.module.scss'
import { Images } from '../../../../assets'
import { RenderIf } from '../../../../utils/Helpers'
import { uploadMedia } from '../../../../services/RestAPI'

import Skeleton from '@mui/material/Skeleton'
import PortfolioTab from '../PortfolioTab/PortfolioTab'
import { SpinnerSmall } from '../../../../components/Progress'
import PortfolioVideo from '../PortfolioVideo/PortfolioVideo'
import PersonalPortfolioPreview from './PersonalPortfolioPreview'
import DeleteTalentImage from '../TalentDeleteImage/DeleteTalentImage'
import NoImagePlaceholder from '../../../../assets/images/TalentDetails/NoImagePlaceholder.svg'

import UploadVideo from '../UploadVideo/UploadVideo'
import PortfolioVideoPlayer from '../PortfolioVideoPlayer'
import { PortfolioShimmer } from '../../../../components/ShimmerV2'
import useTalentMediaQuery from '../../../../hooks/Talent/useTalentMediaQuery'
import { useUpdateTalentProfileVideo } from '../../Hooks/useUpdateTalentMutation'
import { StackGridView, UploadMedia, TlyntSidebar } from '../../../../components'
import { setTalentMediaList } from '../../../../redux/actions/TalentDetailActions'
import { MEDIA_DELETE, MEDIA_CREATE, MEDIA_UPDATE } from '../../../../services/API'
import { setTalentsWithoutPictures, setTalentsWithPictures, showToast } from '../../../../redux/actions/DashBoardActions'
import images from '../../../../assets/images'
import { PortfolioProfileVideo } from '../../../../components/PortfolioProfileVideo'
import NoVideoPlaceholder from '../../../../assets/images/TalentDetails/NoVideoPlaceholder.svg'
import { getTalentSubmission } from '../../../../apis/project'

const loadingStatus = {
  done: 1,
  loading: 2,
  limited: 3,
  error: 4
}

export const Portfolio = (props) => {
  // props
  const {
    id,
    error,
    loading,
    mediaList,
    modalVisible,
    fetchMoreMedia,
    profileImageId,
    setModalVisible,
    isPublic = false,
    loadingMoreStatus,
    refetchTalentDetail,
    videoProfile,
    auditionId
  } = props

  // hooks
  const store = useStore()
  const dispatch = useDispatch()

  const mediaListRef = useRef(null)
  const mediaContainerRef = useRef(null)
  const portfolioVideoUpload = useFeature('admin:portfolio-video-upload')

  // states
  const [mediaLength, setMediaLength] = useState()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [imageLoadedCount, setImageLoadedCount] = useState(0)
  const [showUploadVideo, setShowUploadVideo] = useState(false)
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(-1)
  const [toggleUploadVideos, setToggleUploadVideos] = useState(false)
  const [isDeleteTalentImageModalVisible, setIsDeleteTalentImageModalVisible] = useState(false)

  // video states, set null when no video is selected or user hit back, so that hide the video playing modal
  const [activeVideoIndex, setActiveVideoIndex] = useState(null)
  const [auditionURIs, setAuditionURIs] = useState()
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)

  // api calls
  const [updateMediaHandler] = useMutation(MEDIA_UPDATE)
  const [onCreateMediaHandler] = useMutation(MEDIA_CREATE)
  const [deleteMediaHandler, { loading: deleteMediaLoading }] = useMutation(MEDIA_DELETE)
  const { loading: videoLoading, refetch: refetchVideo } = useTalentMediaQuery({ talentId: id, type: 'video' })
  const [getTalentSubmissionData] = useLazyQuery(getTalentSubmission, { skip: !auditionId, fetchPolicy: 'no-cache' })
  const [setProfileVideo] = useUpdateTalentProfileVideo()

  // constants
  const user = useSelector(state => state.UserReducer)
  const rTalentsDataWithPictures = dashboardReduxData.talentsWithPictures
  const shimmerCount = new Array(6).fill(0).map((_, index) => ({ id: index }))

  const videoList = useSelector((state) => {
    return state.TalentVideoReducer?.filter(video => video.isUploading ? video.talentId === id : true)
  })

  // lifecycle hooks
  useEffect(() => {
    const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
    if (mediaStoreList?.length === 0) {
      setModalVisible(true)
    }
  }, [mediaList])

  useEffect(() => {
    if (!loading && imageLoadedCount === 15) {
      setTimeout(() => {
        const parentContainerHeight = mediaListRef.current?.offsetHeight
        const childContainerHeight = mediaContainerRef.current?.offsetHeight
        if (parentContainerHeight > childContainerHeight) {
          fetchMoreMedia()
        }
      }, 1000)
    }
  }, [loading, imageLoadedCount])

  useEffect(() => {
    // set uploaded media length
    const mediaCount = activeTabIndex === 1 ? mediaList?.length : (activeTabIndex === 2 ? videoList?.length : 0)
    setMediaLength(mediaCount)
  }, [mediaList?.length, videoList?.length, activeTabIndex])

  useEffect(() => {
    if (auditionId !== undefined) {
      void handleSetAuditionVideo()
    }
  }, [auditionId])

  const handleSetAuditionVideo = async () => {
    try {
      const talentSubmissionData = await getTalentSubmissionData({ variables: { deliverableId: auditionId, talentId: id } })
      const auditionVideoData = talentSubmissionData?.data?.getTalentSubmission
      if (auditionVideoData !== undefined && auditionVideoData.length > 0) {
        setAuditionURIs(auditionVideoData[0]?.media?.uris)
      }
      setActiveTabIndex(3)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (mediaListRef.current) {
      if (activeTabIndex === 2) {
        if (videoList.length === 0) {
          mediaListRef.current.scrollTo(0, 0)
        }
        mediaListRef.current.style.overflowY = (showUploadVideo || videoList.length === 0) ? 'clip' : 'scroll'
      } else if (activeTabIndex === 1) {
        if (mediaList.length === 0) {
          mediaListRef.current.scrollTo(0, 0)
        }
        mediaListRef.current.style.overflowY = modalVisible ? 'clip' : 'scroll'
      }
    }
  }, [showUploadVideo, modalVisible, activeTabIndex, videoList])

  // functions
  const handleModalVisible = () => {
    if (activeTabIndex === 1) {
      setModalVisible(!modalVisible)
    } else if (activeTabIndex === 2) {
      handleShowUploadVideo(true)
    }
  }
  const handleActiveTabIndex = (index) => { setActiveTabIndex(index) }
  const handleShowUploadVideo = (value) => { setShowUploadVideo(value) }
  const onPreviewClicked = mediaId => { setSelectedMediaIndex(mediaList.findIndex(media => media.id === mediaId)) }
  const handleToggleDeleteImageSidebar = () => { setIsDeleteTalentImageModalVisible(!isDeleteTalentImageModalVisible) }

  /**
   * @description upload media success progress handler
   * @param {Array} mediaData
   * @param {string} fileName
   */
  const successHandler = async (mediaData, fileName) => {
    if (mediaData && mediaData.item) {
      const {
        filename: mediaFilename,
        id: mediaId
      } = mediaData.item
      const { error: createMediaError, data: createMediaData } =
        await onCreateMediaHandler({
          variables: {
            filename: mediaFilename,
            mediaCreateId: mediaId,
            type: 'photo',
            talentId: id
          }
        })
      if (!createMediaError && createMediaData) {
        const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
        const { mediaCreate } = createMediaData
        const mediaListClone = [...mediaStoreList]
        const mediaIndex = mediaListClone.findIndex((media) => media.title === fileName)
        mediaListClone[mediaIndex] = {
          ...mediaCreate,
          ...mediaListClone[mediaIndex]
        }
        mediaListClone[mediaIndex].isUploaded = true
        dispatch(setTalentMediaList([...mediaListClone]))
      }
    }
  }
  /**
   * @description upload media error progress handler
   * @param {Array} errorResponse
   * @param {string} fileName
   */
  const errorHandler = (errorResponse, fileName) => {
    const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
    const mediaListClone = [...mediaStoreList]
    const mediaIndex = mediaListClone.findIndex(media => media?.title === fileName)
    if (mediaIndex > -1) {
      mediaListClone[mediaIndex].isError = true
      mediaListClone[mediaIndex].isUploading = false
      mediaListClone[mediaIndex].isUploaded = true
    }
    dispatch(setTalentMediaList([...mediaListClone]))
  }
  /**
   * @description upload media progress handler
   * @param {Array} progress
   * @param {string} fileName
   */
  const progressHandler = async (progress, fileName) => {
    const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
    const mediaListClone = [...mediaStoreList]
    const mediaIndex = mediaListClone.findIndex((item) => item?.title === fileName)
    if (mediaIndex > -1) {
      mediaListClone[mediaIndex].uploadProgress = progress
    }
    if (progress === 100) {
      mediaListClone[mediaIndex].isUploading = false
    }
    dispatch(setTalentMediaList([...mediaListClone]))
  }

  /**
   * @description upload media data
   * @param {Array} fileList
   * @returns {Promise}
   */
  const uploadMediaData = async (fileList) => {
    try {
      for (let i = 0; i < fileList.length; i++) {
        if (!fileList[i].isExceedSizeLimit) {
          const formdata = new FormData()
          formdata.append('talentId', id)
          formdata.append('type', 'photo')
          formdata.append('extension', `.${fileList[i]?.file?.path.split('.').pop()}`)
          formdata.append('filetype', fileList[i]?.file?.type)
          await uploadMedia(
            formdata,
            successHandler,
            errorHandler,
            progressHandler,
            fileList[i].title,
            fileList[i]
          )
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onBackHandler = () => {
    setSelectedMediaIndex(-1)
  }

  /**
   * @description delete media handler
   * @returns {Promise}
   */
  const onDeleteHandler = async () => {
    const mediaCount = mediaList.length
    if (selectedMediaIndex >= 0) {
      const mediaId = mediaList[selectedMediaIndex]?.id
      const { error: deleteMediaError, data: deleteMediaData } = await deleteMediaHandler({ variables: { mediaDeleteId: mediaId } })
      if (deleteMediaError) {
        dispatch(showToast({
          message: 'Something went wrong, Try again later',
          isError: true
        }))
      }
      if (mediaCount === 1 && videoList?.length === 0) {
        dispatch(setTalentsWithPictures([...rTalentsDataWithPictures.filter((tlnt) => tlnt.id !== id)]))
        dispatch(setTalentsWithoutPictures([]))
      }
      if (!deleteMediaError && deleteMediaData) {
        const { mediaDelete } = deleteMediaData
        if (mediaDelete.status === 'success') {
          dispatch(setTalentMediaList(mediaList.filter((media, index) => index !== selectedMediaIndex)))
          setSelectedMediaIndex(-1)
        }
      }
    }
    handleToggleDeleteImageSidebar()
  }

  /**
   * @description update media status
   * @param {string} id
   * @param {string} id
   */
  const onUpdateHandler = async (id, status) => {
    if (selectedMediaIndex >= 0) {
      const { error: updateMediaError, data: updateMediaData } = await updateMediaHandler({ variables: { mediaId: id, disabled: !status } })
      if (!updateMediaError && updateMediaData) {
        const updatedData = mediaList
        updatedData[selectedMediaIndex].disabled = !status
        dispatch(setTalentMediaList(updatedData))
        dispatch(showToast({
          message: 'Action successful',
          isError: false
        }))
      } else {
        dispatch(showToast({
          message: 'Error occured! Please try again',
          isError: true
        }))
      }
    }
  }

  /**
   * @description on previous button click handler
   * @returns {void}
   */
  const onPreviousHandler = () => {
    if (selectedMediaIndex > 0) {
      setSelectedMediaIndex(selectedMediaIndex => selectedMediaIndex - 1)
    }
  }

  const onNextHandler = () => {
    if (selectedMediaIndex < mediaList.length - 1) {
      setSelectedMediaIndex(selectedMediaIndex => selectedMediaIndex + 1)
    }
  }

  const onScroll = () => {
    if (mediaListRef.current && loadingMoreStatus === loadingStatus.done && activeTabIndex === 1) {
      const { scrollTop, scrollHeight, clientHeight } = mediaListRef.current
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        fetchMoreMedia()
      }
    }
  }

  const keyboardNavHandler = e => {
    if (selectedMediaIndex < 0) return

    switch (e?.key) {
      case 'ArrowLeft':
        onPreviousHandler()
        break
      case 'ArrowRight':
        onNextHandler()
        break
      case 'Escape':
        onBackHandler()
        break
    }
  }

  const handleToggleUploadVideos = () => { setToggleUploadVideos(!toggleUploadVideos) }

  useEffect(() => {
    document.addEventListener('keyup', keyboardNavHandler)

    return () => {
      document.removeEventListener('keyup', keyboardNavHandler)
    }
  }, [selectedMediaIndex])

  if (error) return <p>Error :(</p>

  return (
    <>
      <RenderIf isTrue={activeVideoIndex !== null}>
        <PortfolioVideoPlayer
          isPublic={isPublic}
          refetchVideo={refetchVideo}
          previewClickedVideoDataHandler={[activeVideoIndex, setActiveVideoIndex]}
          setProfileVideoHandler={videoId => setProfileVideo(id, videoId)}
        />
      </RenderIf>
      <section className={classNames(style.Portfolio)} ref={mediaListRef} onScroll={onScroll}>
        <RenderIf isTrue={showUploadVideo}>
          <UploadVideo handleShowUploadVideo={handleShowUploadVideo} handleToggleUploadVideos={handleToggleUploadVideos} />
        </RenderIf>
        <div className={classNames(style.Portfolio__Tab, isPublic ? style['Portfolio__Tab--Public'] : '')}>
          <RenderIf isTrue={portfolioVideoUpload.on}>
            <PortfolioTab index={activeTabIndex} handleTabIndex={handleActiveTabIndex} isPublic={isPublic} isAuditionAvailable={auditionURIs !== undefined} />
          </RenderIf>
          <RenderIf isTrue={!isPublic && user.isAdmin && mediaLength > 0 && activeTabIndex !== 0}>
            <button className={classNames(style.Portfolio__Tab__AddNewButton)} onClick={handleModalVisible}>
              <img className={classNames(style.Portfolio__Tab__AddNewButton__Icon)} src={Images.plusIcon} />
              <span className={classNames(style.Portfolio__Tab__AddNewButton__Text)}>Add New {activeTabIndex !== 1 ? 'Videos' : 'Photos'}</span>
            </button>
          </RenderIf>
        </div>
        <div className={classNames(style.Portfolio__Preview)}>
          {/*  profile video  */}
          <RenderIf isTrue={activeTabIndex === 0}>
            <div className={classNames(style.Portfolio__Preview__VideoContainer, isPublic ? style['Portfolio__Preview__VideoContainer--Public'] : '')}>
              <div className={style.Portfolio__ProfileVideo}>
                <RenderIf isTrue={loading}>
                  <div className={style.Portfolio__ProfileVideoContainer}><Skeleton style={{ aspectRatio: '9/16' }} width='100%' height='100%' variant='rectangular' /></div>
                </RenderIf>
                <RenderIf isTrue={!loading}>
                  {videoProfile
                    ? <div className={style.Portfolio__ProfileVideoContainer}><PortfolioProfileVideo poster={videoProfile?.poster ?? images.tlyntPlaceholder} stream={videoProfile?.stream} /></div>
                    : <div className={style.Portfolio__ProfileEmpty}>
                      <img src={NoVideoPlaceholder} className={style.Portfolio__ProfileEmptyImg} />
                      <p className={style.Portfolio__ProfileEmptyText}>No Profile Video Found</p>
                    </div>}
                </RenderIf>
              </div>
            </div>
          </RenderIf>
          <RenderIf isTrue={activeTabIndex === 3}>
            <div className={classNames(style.Portfolio__Preview__VideoContainer, isPublic ? style['Portfolio__Preview__VideoContainer--Public'] : '')}>
              <div className={style.Portfolio__ProfileVideo}>
                <RenderIf isTrue={loading}>
                  <div className={style.Portfolio__ProfileVideoContainer}><Skeleton style={{ aspectRatio: '9/16' }} width='100%' height='100%' variant='rectangular' /></div>
                </RenderIf>
                <RenderIf isTrue={!loading}>
                  {auditionURIs
                    ? <div className={style.Portfolio__ProfileVideoContainer}><PortfolioProfileVideo poster={auditionURIs?.poster ?? images.tlyntPlaceholder} stream={auditionURIs?.stream} /></div>
                    : <div className={style.Portfolio__ProfileEmpty}>
                      <img src={NoVideoPlaceholder} className={style.Portfolio__ProfileEmptyImg} />
                      <p className={style.Portfolio__ProfileEmptyText}>No Audition Video Found</p>
                    </div>}
                </RenderIf>
              </div>
            </div>
          </RenderIf>
          {/* portfolio video */}
          <RenderIf isTrue={activeTabIndex === 2}>
            <div className={classNames(style.Portfolio__Preview__VideoContainer, isPublic ? style['Portfolio__Preview__VideoContainer--Public'] : '')}>
              <PortfolioVideo
                isPublic={isPublic}
                loading={videoLoading}
                isAdmin={user.isAdmin}
                refetchVideos={refetchVideo}
                showUploadVideoModel={showUploadVideo}
                toggleUploadVideos={toggleUploadVideos}
                handleShowUploadVideo={handleShowUploadVideo}
                handleToggleUploadVideos={handleToggleUploadVideos}
                previewClickedVideoDataHandler={[activeVideoIndex, setActiveVideoIndex]}
              />
            </div>
          </RenderIf>
          {/* portfolio images */}
          <RenderIf isTrue={activeTabIndex === 1}>
            <div className={classNames(style.Portfolio__Preview__ImagesContainer, !loading && mediaLength === 0 ? style['Portfolio__Preview__ImagesContainer--Empty'] : '')} ref={mediaContainerRef}>
              <div className={classNames(style.Portfolio__Preview__ImagesContainer__ShimmerContainer, isPublic ? style['Portfolio__Preview__ImagesContainer__ShimmerContainer--Public'] : '')}>
                {loading && mediaList?.length === 0 && shimmerCount.map(item => <PortfolioShimmer key={item.id} />)}
              </div>
              <RenderIf isTrue={mediaList?.length}>
                <StackGridView
                  isPublic={isPublic}
                  imageData={mediaList}
                  handleRetryMedia={uploadMediaData}
                  onPreviewClicked={onPreviewClicked}
                  incImageLoadedCount={() => { setImageLoadedCount((prevData) => prevData + 1) }}
                />
              </RenderIf>
              <RenderIf isTrue={activeTabIndex === 1}>
                {(!user.isAdmin || isPublic) && mediaLength === 0
                  ? !loading && (
                    <div className={classNames(style.Portfolio__Preview__ImagesContainer__NoImages)}>
                      <img src={NoImagePlaceholder} className={classNames(style.Portfolio__Preview__ImagesContainer__NoImages__Image)} />
                      <p className={classNames(style.Portfolio__Preview__ImagesContainer__NoImages__Text)}>No Images Found</p>
                    </div>
                    )
                  : <UploadMedia
                    isEmpty={true}
                    gridImages={mediaList}
                    uploadMediaData={uploadMediaData}
                    setModalVisible={handleModalVisible}
                    modalVisible={modalVisible && !isPublic && !loading}
                  />}
              </RenderIf>
            </div>
          </RenderIf>
          <RenderIf isTrue={loadingMoreStatus === 2}>
            <div className={classNames(style.Portfolio__Preview__SpinnerContainer)}>
              <SpinnerSmall />
              <span className={classNames(style.Portfolio__Preview__SpinnerContainer__Text)}>Loading more portfolio</span>
            </div>
          </RenderIf>
        </div>
        <RenderIf isTrue={selectedMediaIndex >= 0 && mediaList.length > 0}>
          <PersonalPortfolioPreview
            talentId={id}
            isPublic={isPublic}
            mediaList={mediaList}
            onBackHandler={onBackHandler}
            profileImageId={profileImageId}
            onNextHandler={onNextHandler}
            onUpdateHandler={onUpdateHandler}
            onPreviousHandler={onPreviousHandler}
            selectedMediaIndex={selectedMediaIndex}
            refetchTalentDetail={refetchTalentDetail}
            handleToggleDeleteImageSidebar={handleToggleDeleteImageSidebar}
          />
        </RenderIf>
        <RenderIf isTrue={isDeleteTalentImageModalVisible}>
          <TlyntSidebar direction="right" opacityLevel={1}>
            <DeleteTalentImage
              loading={deleteMediaLoading}
              onDeleteHandler={onDeleteHandler}
              handleToggleDeleteImageSidebar={handleToggleDeleteImageSidebar}
            />
          </TlyntSidebar>
        </RenderIf>
      </section>
    </>
  )
}

Portfolio.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  isPublic: PropTypes.bool,
  loading: PropTypes.bool,
  fetchMore: PropTypes.func,
  mediaList: PropTypes.array,
  modalVisible: PropTypes.bool,
  fetchMoreMedia: PropTypes.func,
  setModalVisible: PropTypes.func,
  profileImageId: PropTypes.object,
  refetchTalentDetail: PropTypes.func,
  loadingMoreStatus: PropTypes.number,
  videoProfile: PropTypes.object,
  auditionId: PropTypes.string
}
