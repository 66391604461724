const base = {
  local: 'https://api.dev.tlynt.com',
  dev: 'https://api.dev.tlynt.com',
  qa: 'https://api.qa.tlynt.com',
  prod: 'https://api.tlynt.com'
}

const mediaCDN = {
  local: 'https://media.dev.tlynt.com',
  dev: 'https://media.dev.tlynt.com',
  qa: 'https://media.qa.tlynt.com',
  prod: 'https://media.tlynt.com'
}

const adminCDN = {
  local: 'https://book.dev.tlynt.com',
  dev: 'https://book.dev.tlynt.com',
  qa: 'https://book.qa.tlynt.com',
  prod: 'https://book.tlynt.com'
}

const growthBookAPIKey = {
  local: 'key_dev_f7a1375299f58648',
  dev: 'key_dev_f7a1375299f58648',
  qa: 'key_qa_390a9a91ce51c8c0',
  prod: 'key_prod_be95c16b7eeadbfd'
}

const branchKey = {
  local: 'key_test_ap28QOnHKMZhFPJ7EzVEcaclEwlR4ZFU',
  dev: 'key_test_ap28QOnHKMZhFPJ7EzVEcaclEwlR4ZFU',
  qa: 'key_test_ap28QOnHKMZhFPJ7EzVEcaclEwlR4ZFU',
  prod: 'key_live_la81OMePGG7mDGJ3DwtLHbaoqxoJ63wA'
}

export const API = {
  graphql: `${base[process.env.REACT_APP_ENV]}/graphql`,
  auth: `${base[process.env.REACT_APP_ENV]}/auth`,
  mediaUpload: `${base[process.env.REACT_APP_ENV]}/v1/media/upload`,
  bulkMediaUpload: `${base[process.env.REACT_APP_ENV]}/v1/media/bulkupload`,
  publicToken: `${base[process.env.REACT_APP_ENV]}/auth/public/token`,
  magicLink: `${base[process.env.REACT_APP_ENV]}/auth/login/magic`,
  base: base[process.env.REACT_APP_ENV],
  cdn: mediaCDN[process.env.REACT_APP_ENV],
  logout: `${base[process.env.REACT_APP_ENV]}/auth/logout`,
  growthBook: `https://cdn.growthbook.io/api/features/${growthBookAPIKey[process.env.REACT_APP_ENV]}`,
  baseUrl: `${mediaCDN[process.env.REACT_APP_ENV]}`,
  adminCDNBaseUrl: `${adminCDN[process.env.REACT_APP_ENV]}`,
  branchKey: `${branchKey[process.env.REACT_APP_ENV]}`
}
