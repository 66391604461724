import { useQuery } from '@apollo/client'
import TalentAPI from '../../../services/Talent/TalentAPI'

function useSearchTalentQuery (props) {
  const { offset, limit, hasPhoto, flagged, disabled, skip, hasVideo } = props

  const { data, loading, refetch, fetchMore } = useQuery(TalentAPI.getTalentList(), {
    variables: {
      offset,
      limit,
      hasPhoto,
      flagged,
      disabled,
      hasVideo
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
    skip
  })

  return { data, loading, refetch, fetchMore }
}

export default useSearchTalentQuery
