import { useEffect } from 'react'
import platform from 'platform'
import PropTypes from 'prop-types'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

import { useAuth } from '../Auth'
import { API } from '../../constants'

const GrowthbookWrapper = ({ children }) => {
  const auth = useAuth()

  const growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {
      console.log({
        experimentId: experiment.key,
        variationId: result.variationId
      })
    }
  })
  useEffect(() => {
    // Load feature definitions from GrowthBook API
    fetch(API.growthBook)
      .then(res => res.json())
      .then(parsed => {
        growthbook.setFeatures(parsed.features)
      })
      .catch(() => {
        console.log('Failed to fetch feature definitions from GrowthBook')
      })

    growthbook.setAttributes({
      id: auth?.user?.email?.value ?? '-',
      browser: platform.name,
      url: window.location.href
    })
  }, [auth?.user])

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}

export default GrowthbookWrapper

GrowthbookWrapper.propTypes = {
  children: PropTypes.node
}
