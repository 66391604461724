import { Fragment, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { API, Colors } from '../../../../constants'
import { Typography } from '../../../../components'
import { ShimmerContentBox } from '../../../../components/shimmer/Shimmer'

const InfoShimmer = styled(ShimmerContentBox)`
  height: ${props => props.height};
  width: ${props => props.width};
  margin-top: ${props => props.marginTop};
  border-radius: ${props => props.borderRadius ?? '0'};
  margin-bottom: ${props => props.marginBottom};
`
const LogoWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 12px;
  border: 0.5px solid ${Colors.primaryGray};
  display: flex;
  align-items: center;
  img {
    max-height: 96px;
    width: 96px;
  }
  div {
    background: ${Colors.borderGray};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 64px;
    font-weight: bold;
  }
`
const GeneralInfo = ({ data, loading }) => {
  const [isImageFailed, setIsImageFailed] = useState(false)
  return (
    <div>
      {loading
        ? <div>
          <InfoShimmer height={'96px'} width={'96px'} borderRadius={'12px'} />
          <InfoShimmer
            marginTop={'10px'}
            height={'25px'}
            borderRadius={'4px'}
          />
          <Fragment>
            <InfoShimmer
              marginTop={'10px'}
              height={'25px'}
              borderRadius={'4px'}
            />
            <InfoShimmer
              marginTop={'10px'}
              height={'25px'}
              borderRadius={'4px'}
              marginBottom={'10px'}
            />
          </Fragment>
        </div>
        : <Fragment>
          <LogoWrapper>
            {data?.logo?.uris?.logo
              ? <img
                  onError={() => setIsImageFailed(true)}
                  src={!isImageFailed ? data.logo?.uris?.logo : `${API.baseUrl}/${data?.logo?.file}`}
                  height={96}
                  style={{ borderRadius: 12, objectFit: 'contain' }} />
              : <div>{data?.name.charAt(0).toUpperCase()}</div>}
          </LogoWrapper>
          <Typography
            fontSize="20px"
            lineHeight="24px"
            fontWeight="bold"
            style={{ marginTop: '10px' }}>
            {data?.name}
          </Typography>
          <Typography
            fontSize="16px"
            lineHeight="24px"
            style={{ opacity: '0.4' }}>
            {data?.contact}
          </Typography>
          <Typography
            fontSize="16px"
            lineHeight="24px"
            style={{ opacity: '0.4' }}>
            {data?.id}
          </Typography>
        </Fragment>}
    </div>
  )
}

GeneralInfo.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}

export default GeneralInfo
