import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
const Wrapper = styled.div`
  display: block;
  width: 100%;
`
const ShimmerWrapper = styled.div`
  display: flex;
  width: 100%;
`

const ShimmerBox = styled.div`
  height: ${props => props.height + 'px' ?? '50px'};
  background: #777;
  width: ${props => props.isSquare ? props.height + 'px' : '100%'};
  border-radius: 5px;
  margin-right: ${props => props.isSquare ? '15px' : 0};
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

export const ShimmerGeneral = ({ isCheckbox = false, height = 50, style = {} }) => {
  return (
    <Wrapper style={{ ...style }} >
      <ShimmerWrapper>
        {isCheckbox && <ShimmerBox isSquare={true} height={height} />}
        <ShimmerBox isSquare={false} height={height} />
      </ShimmerWrapper>
    </Wrapper>
  )
}

ShimmerGeneral.propTypes = {
  count: PropTypes.number,
  isCheckbox: PropTypes.bool,
  height: PropTypes.number,
  style: PropTypes.object
}
