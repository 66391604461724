import { useQuery } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useProjectRoleListQuery (projectId) {
  const { data, loading, refetch } = useQuery(ProjectAPI.getProjectRoleList(), {
    variables: {
      id: projectId
    },
    fetchPolicy: 'network-only',
    skip: !projectId
  })
  return { data, loading, refetch }
}

export default useProjectRoleListQuery
