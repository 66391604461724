import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import style from './PortfolioVideo.module.scss'
import { RenderIf } from '../../../../utils/Helpers'
import UploadVideo from '../UploadVideo/UploadVideo'

import VideoThumbnail from '../VideoThumbnail'
import PortfolioVideoPreview from '../PortfolioVideoPreview'
import MasonryResponsive from '../../../../components/Masonry'
import { POSTER_PROCESSING_STATUS } from '../../../../constants/PortfolioConstants'
import NoVideoPlaceholder from '../../../../assets/images/TalentDetails/NoVideoPlaceholder.svg'

const PortfolioVideo = props => {
  // props
  const {
    loading,
    isAdmin,
    isPublic,
    showUploadVideoModel,
    handleShowUploadVideo,
    handleToggleUploadVideos,
    previewClickedVideoDataHandler
  } = props

  // constants
  const setActiveVideoIndex = previewClickedVideoDataHandler[1]
  const videoList = useSelector(state => state.TalentVideoReducer)

  return (
      <section className={classNames(style.Videos)}>
        <RenderIf isTrue={!loading && videoList.length === 0 && !showUploadVideoModel}>
          <RenderIf isTrue={isAdmin && !isPublic}>
            <UploadVideo
              isUploaded={false}
              handleShowUploadVideo={handleShowUploadVideo}
              handleToggleUploadVideos={handleToggleUploadVideos}
            />
          </RenderIf>
          <RenderIf isTrue={!loading}>
           <div className={classNames(style.Videos__Empty)}>
            <img src={NoVideoPlaceholder} className={classNames(style.Videos__Empty__Image)} />
            <p className={style.Videos__Empty__Text}>No Videos Found</p>
           </div>
          </RenderIf>
        </RenderIf>
        <RenderIf isTrue={videoList.length}>
          <div className={classNames(style.Videos__Masonary)}>
            <MasonryResponsive gutter='10px' columnsCount={4}>
              {videoList.map((media, index) =>
                !media.isUploading && media?.processing !== POSTER_PROCESSING_STATUS.ERROR
                  ? <VideoThumbnail
                      key={index}
                      id={media?.id}
                      source={media?.preview}
                      poster={media?.uris?.poster}
                      stream={media?.uris?.stream}
                      processing={media?.processing}
                      onClick={() => setActiveVideoIndex(index)}
                    />
                  : <PortfolioVideoPreview media={media} key={index} />
              )}
            </MasonryResponsive>
          </div>
        </RenderIf>
      </section>
  )
}

export default PortfolioVideo

PortfolioVideo.propTypes = {
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  isPublic: PropTypes.bool,
  showUploadVideoModel: PropTypes.bool,
  handleShowUploadVideo: PropTypes.func,
  handleToggleUploadVideos: PropTypes.func,
  previewClickedVideoDataHandler: PropTypes.array
}
