import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Assets } from '../../components/pages/UGC/Assets'
import { DetailOverview } from '../../components/pages/UGC/DetailOverview'
import { getAssetsListProjectTalentStatus } from '../../apis/project'
import { GetProductByProject, GetSelect, GetUGCProject } from '../../apis/ugc'
import { InterestedTalent } from '../../components/pages/UGC/InterestedTalent'
import { Layout } from '../../components'
import { RenderIf } from '../../utils/Helpers'
import { SET_ATLEAST_ONE_TALENT_BOOKED, SET_OVERVIEW, UGCDetailContext } from '../../contexts/project/ugcDetail'
import { setUGCAssetsDownloadJobs } from '../../redux/slices/ugcDownloadAssetSlice'
import { showToast } from '../../redux/actions/DashBoardActions'
import { SpinnerSmall } from '../../components/Progress'
import { UGCViewProjectHeader } from '../../components/UGCViewProjectHeader'
import { useDownloadAssets } from '../../hooks/UGC/useDownloadAssets'
import { useShareProject } from '../../hooks'
import Cross from '../../assets/images/UGCProject/Close.svg'
import ErrorIcon from '../../assets/images/UGCProject/ErrorIcon.svg'
import style from './UGCViewProjectWizard.module.scss'
import type { AssetState, DownloadAssetsState } from '../../redux/slices/ugcDownloadAssetSlice'

/**
  * @description Component that responsible for rendering the active tab
  * @returns JSX.Element
*/
const ActiveTab = (): JSX.Element => {
  const { activeTab } = useParams()
  switch (activeTab) {
    case 'overview':
      return <DetailOverview />
    case 'talent':
      return <InterestedTalent />
    case 'assets':
      return <Assets />
    default:
      return <></>
  }
}

const UGCProjectWizard = (): JSX.Element => {
  // init
  useDownloadAssets()
  const [{ overview }, dispatch] = useContext(UGCDetailContext)
  const { id } = useParams()
  const reduxDispatch = useDispatch()
  const { handleProjectShare } = useShareProject()

  const { hasProduct } = overview

  const { downloadAssetsJobs, showBottomBanner, isAnyJobFailed }: DownloadAssetsState = useSelector((state: any) => state.UGCDownloadAssetsReducer)

  const { data, loading } = useQuery(GetUGCProject, { variables: { getProjectId: id, withBrand: true }, fetchPolicy: 'network-only' })
  const [getSelectInfo] = useLazyQuery(GetSelect, { variables: { getSelectId: overview?.selectId } })
  const [getProductByProject] = useLazyQuery(GetProductByProject, { variables: { projectId: id }, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })
  const [getAssetsListProjectTalentStatusQuery] = useLazyQuery(getAssetsListProjectTalentStatus, { variables: { projectId: id, statuses: ['booking_confirmed', 'wrapped'] }, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })

  // constants
  const processingOrPendingDownloadJobs = downloadAssetsJobs?.filter((job: AssetState) => job?.status === 'processing' || job?.status === 'pending') ?? []
  const isAnyFailedDownloadJobs = downloadAssetsJobs?.filter((job: AssetState) => job?.status === 'errored') ?? []
  const downloadAssetJobVisibility = (showBottomBanner && (processingOrPendingDownloadJobs?.length > 0 || isAnyFailedDownloadJobs?.length > 0)) || isAnyJobFailed
  const brandDetails = overview?.owner?.brands?.find(brand => brand?.id === overview?.brandId)

  let logo, orgName
  if (overview?.brandId !== undefined && overview?.brandId !== null) {
    logo = brandDetails?.logo?.uris?.logo
    orgName = brandDetails !== undefined ? brandDetails.name : ''
  } else {
    logo = overview?.owner?.logo?.uris?.logo
    orgName = overview.owner.name
  }

  // LOAD OVERVIEW DATA WHEN PROJECT HAS DATA
  useEffect(() => {
    if (!loading && Object.keys(data?.getProject ?? {}).length > 0) {
      void loadOverviewData()
    }
  }, [loading, data])

  /**
    * @description Handler that responsible for loading the overview data
    * @returns void
  */
  const loadOverviewData = async (): Promise<void> => {
    try {
      await getSelectInfo({
        variables: { getSelectId: data.getProject.selectId },
        onCompleted: (selectData) => {
          dispatch({
            type: SET_OVERVIEW,
            payload: { ...overview, ...data.getProject, loading: false, archived: selectData?.getSelect?.archived, selectName: selectData?.getSelect?.name }
          })
        }
      })

      await getAssetsListProjectTalentStatusQuery({
        variables: { projectId: id, statuses: ['booking_confirmed', 'wrapped'] },
        onCompleted: (data) => {
          if (data?.listProjectTalentStatus?.length > 0) {
            dispatch({ type: SET_ATLEAST_ONE_TALENT_BOOKED, payload: true })
          }
        }
      })
    } catch (error: any) {
      reduxDispatch(showToast({ isError: true, message: error?.message ?? 'Something went wrong, Try again later' }))
    }
  }

  // LOAD PRODUCT DATA WHEN PROJECT HAS PRODUCT
  useEffect(() => {
    if (hasProduct) {
      void handleLoadProductData()
    }
  }, [hasProduct])

  /**
    * @description Handler that responsible for loading the product data
    * @returns void
  */
  const handleLoadProductData = async (): Promise<void> => {
    const productProject = await getProductByProject({ variables: { projectId: id } })

    if (productProject?.data?.getProductByProject?.id !== undefined && productProject?.data?.getProductByProject?.id !== null) {
      const { url, provisioningMethod, description, directions } = productProject?.data?.getProductByProject
      dispatch({
        type: SET_OVERVIEW,
        payload: {
          ...overview,
          productURL: url,
          productDescription: description ?? '-',
          productDirections: directions ?? '-',
          provisioningMethod
        }
      })
    }
  }

  const handleCloseDownloadBanner = (): void => { reduxDispatch(setUGCAssetsDownloadJobs([])) }

  return (
    <Layout>
      <main className={style.Main}>
        <UGCViewProjectHeader
          selectName={overview?.selectName}
          isArchived={overview.archived}
          selectId={overview.selectId}
          advertiserName={orgName}
          logo={logo}
          loading={overview.loading}
          status={overview?.status}
          projectName={overview?.name}
          atleastOneTalentBooked={overview.atleastOneTalentBooked ?? false}
          headerColor={overview?.color}
          handleProjectShare={handleProjectShare}
        />
        <ActiveTab />

        <RenderIf isTrue={downloadAssetJobVisibility}>
          <div className={style.Main__DownloadAssetsProgressContainer}>
            <RenderIf isTrue={processingOrPendingDownloadJobs?.length > 0}>
              <div className={style.Main__DownloadAssetsProgressTextConatiner}>
                <SpinnerSmall />
                <p className={style.Main__DownloadAssetProgressText}>You have {processingOrPendingDownloadJobs?.length} download{processingOrPendingDownloadJobs?.length > 1 ? 's' : ''} processing.  Please don&apos;t leave the page.</p>
              </div>
            </RenderIf>
            <RenderIf isTrue={isAnyJobFailed}>
              <div className={style.Main__DownloadAssetsProgressTextConatiner}>
                <img src={ErrorIcon} />
                <p className={classNames(style.Main__DownloadAssetProgressText, style['Main__DownloadAssetProgressText--Error'])}>Download failed, Something went wrong.</p>
              </div>
              <img onClick={handleCloseDownloadBanner} src={Cross} className={style.Main__DownloadAssetsErrorImg} />
            </RenderIf>
          </div>
        </RenderIf>
      </main>
    </Layout>
  )
}

export default UGCProjectWizard
