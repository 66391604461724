import classNames from 'classnames'
import style from './SectionHeader.module.scss'

interface HeaderProps {
  headerText: string
  descriptionText?: string | JSX.Element
  variant?: 'normal' | 'small'
  tooltip?: JSX.Element
  extHeaderTextClassNames?: string[]
  extDescriptionTextClassNames?: string[]
  isBullets?: boolean
}

export const SectionHeader = (props: HeaderProps): JSX.Element => {
  const { headerText, descriptionText, tooltip, variant = 'normal', extHeaderTextClassNames = [], extDescriptionTextClassNames = [], isBullets = false } = props
  return (
    <div className={style.Header}>
      <div className={style.Header__HeaderContainer}>
        <h3 className={classNames(style.Header__HeaderContainerText, style[`Header__HeaderContainerText--${variant}`], ...extHeaderTextClassNames)}>{headerText}</h3>
        { tooltip ?? null }
      </div>
      {isBullets ? <div className={classNames(style.Header__Description, ...extDescriptionTextClassNames)}>{descriptionText}</div> : <p className={classNames(style.Header__Description, ...extDescriptionTextClassNames)}>{descriptionText}</p>}
    </div>
  )
}
