import PropTypes from 'prop-types'
import { useDispatch, useStore } from 'react-redux'
import { useContext, useEffect, useState } from 'react'

import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { CardMedia, LinearProgress, Tooltip } from '@mui/material'

import Style from '../../Styles'
import BulkDataContext from '../../Context'
import { Colors } from '../../../../constants'
import { Typography } from '../../../../components'
import { uploadBulkMedia } from '../../../../services/RestAPI'
import { setBulkUploadList } from '../../../../redux/actions/BulkUploadAction'

const useStyles = makeStyles(() => ({
  inProgress: {
    backgroundColor: '#D9D9D9',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#E1306C'
    }
  },
  completeProgress: {
    backgroundColor: '#4CAF50',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#4CAF50'
    }
  }
}))

const Index = ({ data }) => {
  // Hooks
  const classes = useStyles()
  const {
    tags,
    removeImage,
    incrementFailCounter,
    decrementFailCounter,
    incrementSuccessCounter,
    uploadCounter
  } = useContext(BulkDataContext)

  const dispatch = useDispatch()
  const store = useStore()
  const uploadStoreList = store.getState()?.BulkUploadReducer?.uploadList ?? []
  // state
  const [progress, setProgress] = useState(data.uploadProgress ?? 0)
  const [isUploaded, setIsUploaded] = useState('pending')
  const [isError, setIsError] = useState(false)
  const [ErrorMsg, setErrorMsg] = useState('')
  const [updatedData, setUpdatedData] = useState(data)

  const successHandler = async () => {
    setIsUploaded('done')
    incrementSuccessCounter()
    const final = {
      ...updatedData,
      uploadProgress: 100,
      isUploaded: true
    }
    storeRedux(final)
  }
  const errorHandler = async (err) => {
    incrementFailCounter()
    setIsUploaded('fail')
    setIsError(true)
    const final = {
      ...updatedData,
      isValid: false
    }
    storeRedux(final, true)
    setErrorMsg(err)
  }

  const storeRedux = (updatedData, isFail = false) => {
    dispatch(setBulkUploadList([...uploadStoreList, updatedData]))
  }

  const progressHandler = async (progress) => {
    setUpdatedData({
      ...updatedData,
      uploadProgress: progress
    })
    setProgress(progress)
  }

  const uploadMediaData = async () => {
    if (!data.isExceedSizeLimit) {
      setIsUploaded('start')
      try {
        if (!data.isExceedSizeLimit) {
          const formdata = new FormData()
          formdata.append('filename', data?.file?.name)
          formdata.append('type', 'photo')
          formdata.append('profile', false)
          formdata.append('disabled', false)
          formdata.append('tags', tags ?? '')
          formdata.append('extension', `.${data?.file?.path.split('.').pop()}`)
          formdata.append('filetype', data?.file?.type)
          await uploadBulkMedia(
            formdata,
            successHandler,
            errorHandler,
            progressHandler,
            data.file.name,
            data
          )
        }
      } catch (error) {
        setIsError(true)
        setIsUploaded('fail')
        setErrorMsg('error')
      }
    } else {
      setIsUploaded('fail')
      incrementFailCounter()
    }
  }

  const retryUpload = () => {
    decrementFailCounter()
    setIsError(false)
    setErrorMsg()
    uploadMediaData()
  }
  useEffect(() => {
    if (uploadCounter > 0 && isUploaded !== 'done') {
      uploadMediaData()
    }
  }, [uploadCounter])

  useEffect(() => {
    if (data.isExceedSizeLimit) {
      setIsUploaded('fail')
      setErrorMsg('File size is higher then 5MB.')
    }
  }, [data])

  return (
    <Style.ModifiedCard>
      <Style.CardWrapper>
        <CardMedia
          component="img"
          sx={{ width: 54, height: 54, borderRadius: '6px' }}
          image={updatedData.src}
          alt="Uploaded Image"
        />
        <Style.CardInfoWrapper>
          <Style.CardInfo>
            <Typography fontSize={16} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {updatedData.title}
            </Typography>
            {isUploaded !== 'fail' && <Typography fontSize={12} color={Colors.sonicSilver}>
              {isUploaded === 'pending' ? 'Ready to upload' : progress === 100 ? 'Upload done' : 'In progress...'}
            </Typography>}
            {isUploaded === 'fail' &&
            <Style.ErrorBlock>
              <Tooltip title={ErrorMsg} arrow>
                <div><Typography fontSize={12} color={Colors.errorRed}>
                  Upload error
                </Typography></div>
              </Tooltip>
              {!data.isExceedSizeLimit &&
              <div onClick={retryUpload}>
                <Typography color='blue'
                  fontSize={16}
                  style={{ marginLeft: '5px', textDecoration: 'underline', cursor: 'pointer' }}>
                    Retry
                </Typography>
              </div>}
            </Style.ErrorBlock>}
          </Style.CardInfo>
          {(isUploaded === 'fail' || isUploaded === 'pending') &&
          <Style.IconButtonModified onClick={() => {
            removeImage(data.title, isError)
            isError && decrementFailCounter()
          }}>
            <CloseIcon sx={{ color: Colors.sonicSilver, alignSelf: 'center', marginLeft: 'auto' }} />
          </Style.IconButtonModified>}
        </Style.CardInfoWrapper>
      </Style.CardWrapper>
      {progress > 0 && <LinearProgress
        variant="determinate"
        value={progress}
        className={progress === 100 ? classes.completeProgress : classes.inProgress} />}
    </Style.ModifiedCard>
  )
}

Index.propTypes = {
  data: PropTypes.object
}

export default Index
