import { Stack } from '@mui/material'
import { useState } from 'react'

import {
  HeaderV2,
  Layout,
  Typography,
  Button,
  TlyntSidebar
} from '../../components'
import { Colors } from '../../constants'
import AddAdvertiser from './Components/AddAdvertiser'
import AdvertiserWrapper from './Components/AdvertiserWrapper'
import useCombineWithUsers from './Hooks/useCombineWithUsers'

const Advertiser = () => {
  // State
  const [order, setOrder] = useState('createdAt')
  const [isRefetch, setIsRefetch] = useState(false)

  // API call
  const { dataWithUsers, loading, reFetchData, fetchMore } = useCombineWithUsers({ order })

  const HeaderDescription = () => (
    <Stack direction='row'>
      <Typography fontSize={14} color={Colors.headingBlack} letterSpacing={0.15} lineHeight='20px'>View all the Advertiser created by all registered advertisers</Typography>
    </Stack>
  )
  const [isVisibleModal, setIsvisibleModal] = useState(false)
  const handleToggle = () => setIsvisibleModal(!isVisibleModal)
  return (
    <Layout>
      <HeaderV2
        title={
          <Typography
            fontSize={34}
            fontWeight="bold"
            lineHeight="42px"
            letterSpacing={0.25}>
            Advertiser
          </Typography>
        }
        titleRight={
          <Button width={'200px'} height={'42px'} onClick={handleToggle}>
            <Typography
              fontSize={15}
              color={Colors.white}
              letterSpacing={0.15}
              fontWeight="bold"
              lineHeight="20px"
              textTransform="uppercase">
              Add new Advertiser
            </Typography>
          </Button>
        }
        description={<HeaderDescription />}
      />
      {isVisibleModal && (
        <TlyntSidebar>
          <AddAdvertiser
            handleToggle={handleToggle}
            reFetchData={reFetchData}
            setIsRefetch={setIsRefetch}
          />
        </TlyntSidebar>
      )}
      <AdvertiserWrapper
        data={dataWithUsers}
        loading={loading}
        reFetchData={reFetchData}
        fetchMore={fetchMore}
        setOrder={setOrder}
        order={order}
        isRefetch={isRefetch}
        setIsRefetch={setIsRefetch}
      />
    </Layout>
  )
}

export default Advertiser
