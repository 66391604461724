import { useEffect, useState } from 'react'

import classNames from 'classnames'
import SelectMui from '@mui/material/Select'
import { FormControl, FormHelperText, InputLabel, MenuItem, type SelectChangeEvent } from '@mui/material'

import style from './Select.module.scss'
import { RenderIf } from '../../utils/Helpers'

interface SelectProps {
  label: string
  name?: string
  errorText?: string
  isError?: boolean
  value: string
  disabled?: boolean
  onChange?: (e: SelectChangeEvent<string>) => void
  options: Array<{
    id: string
    value: string
    logo: string
  }>
}
export const Select = ({
  label,
  isError = false,
  errorText = '',
  value,
  onChange,
  name,
  options,
  disabled = false
}: SelectProps): JSX.Element => {
  const [localValue, setLocalValue] = useState<string>('')

  useEffect(() => {
    if (value !== '') {
      setLocalValue(value)
    }
  }, [value])

  const handleChange = (event: SelectChangeEvent<string>): (void) => {
    setLocalValue(event.target.value)
    if (typeof onChange === 'function') {
      onChange(event)
    }
  }
  return (
    <FormControl variant="standard" fullWidth error={isError} className={classNames(style.Select)}>
      <InputLabel>{label}</InputLabel>
      <SelectMui name={name} value={typeof onChange === 'function' ? value : localValue} onChange={handleChange} disabled={disabled}>
        {options?.map((option) => <MenuItem key={option.id} value={option.id}>
          <div className={classNames(style.Select__Option)}>
            {!(option.logo?.length > 0) ? <div className={classNames(style.Select__InitialContainer)}><p className={classNames(style.Select__InitialText)} >{option.value.charAt(0).toUpperCase()}</p></div> : <img src={option.logo} className={classNames(style.Select__OptionIcon)} />}
            <span className={style.Select__OptionValue}>{option.value}</span>
          </div>
          </MenuItem>
        )}
      </SelectMui>
      <RenderIf isTrue={isError}>
        <FormHelperText>{errorText}</FormHelperText>
      </RenderIf>
    </FormControl>
  )
}
