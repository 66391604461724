import { useContext, useEffect, useState } from 'react'

import classNames from 'classnames'
import { Link, useLocation, useParams } from 'react-router-dom'

import styles from './UGCProjectSidebar.module.scss'
import Error from '../../assets/images/Projects/Error.svg'
import CheckedCircle from '../../assets/images/UGCProject/CheckedCircle.svg'
import { UGCSidebarContext } from '../../contexts/ugcSidebar'

interface SidebarChipProps {
  isError: boolean
  isCollapsable: boolean
  title: string
  isActive: boolean
  isCompleted: boolean
}

const SidebarChip = (props: SidebarChipProps): JSX.Element => {
  const { title, isCompleted, isActive, isError } = props
  return (
    <div className={classNames(isActive || isCompleted ? styles['SidebarChip--Active'] : '')} >
      <div className={classNames(styles.SidebarChip)}>
        <p className={classNames(styles.SidebarChip__Text, isCompleted || isActive ? styles['SidebarChip__Text--Completed'] : '')}>{title}</p>
        {isError && <img src={Error} />}
        {isCompleted && <img src={CheckedCircle} />}
      </div>
    </div>
  )
}

interface CollapsableProps {
  data: Array<{ title: string, isActive: boolean, route: string }>
}

const Collapsable = (props: CollapsableProps): JSX.Element => {
  const { data } = props
  const { id } = useParams()
  const location = useLocation()
  const [pathname, setPathname] = useState('')

  useEffect(() => {
    setPathname(location.hash)
  }, [location])

  return (
    <aside className={classNames(styles.Collapsable)}>
      {data.map((item, index) => (
        <div key={index}>
          <Link className={classNames(styles.Collapsable__Text, ((pathname !== '') && (Boolean(item.route.includes(pathname)))) || (index === 0 && pathname === '') ? styles['Collapsable__Text--Active'] : '')} to={ (id != null) ? `/projects/ugc/${id}/${item.route}` : `/projects/ugc/${item.route}`} >
            {item.title}
          </Link>
        </div>
      ))}
    </aside>
  )
}

interface UGCProjectSidebarProps {
  type: string
  activeTab: string
}

export function UGCProjectSidebar (props: UGCProjectSidebarProps): JSX.Element {
  const { type, activeTab } = props
  const [data] = useContext(UGCSidebarContext)
  const { id } = useParams()

  return (
    <div className={classNames(styles.Sidebar)}>
      { data?.length > 0 && data?.map((item, index: number) => (
        item.isVisible && <div key={index}>
          <Link className={styles.Sidebar__Link} to={ data[index].isCompleted || (data[index - 1]?.isCompleted) ? (id != null) ? `/projects/${type}/${id}/${item.route}` : `/projects/${type}/${item.route}` : '#'} key={index}>
            <SidebarChip isCollapsable={((item?.collapsableData) != null) && item?.collapsableData?.length > 0} title={item.title} isActive={activeTab === item.route} isError={item.isError} isCompleted={item.isCompleted} />
          </Link>
          {activeTab === item.route && ((item?.collapsableData) != null) && item?.collapsableData?.length > 0 && <Collapsable data={item?.collapsableData} /> }
        </div>
      ))}
    </div>
  )
}
