import { useMutation } from '@apollo/client'

import ProjectAPI from '../../../services/Project/ProjectAPI'

function useUpdateRolesWithSelectMutation () {
  // mutation
  const [UpdateRolestWithSelect, { data, loading }] = useMutation(
    ProjectAPI.updateRolesWithSelect()
  )

  const SubmitUpdateRole = async (props, toggleModal) => {
    const { roles, projectId, selectId } = props
    const payload = []
    roles.forEach((role, index) => {
      const {
        name,
        id,
        selectId,
        rate,
        startDate,
        endDate,
        age,
        height,
        weight,
        bodyType,
        hairColor,
        eyeColor,
        race,
        gender,
        genderExpression,
        sexualOrientation,
        isAgeAllowed,
        isWeightAllowed,
        isHeightAllowed,
        ethnicity,
        description,
        notes,
        quantity
      } = role
      const payloadObj = {
        id,
        selectId,
        name,
        rate: rate ? parseInt(rate) : null,
        startDate: startDate || null,
        endDate: endDate || null,
        projectId,
        description: description.trim(),
        notes: notes.trim(),
        quantity: parseInt(quantity),
        criteria: {
          age: isAgeAllowed
            ? {
                min: age[0],
                max: age[1]
              }
            : null,
          height: isHeightAllowed
            ? {
                min: height[0],
                max: height[1]
              }
            : null,
          weight: isWeightAllowed
            ? {
                min: weight[0],
                max: weight[1]
              }
            : null,
          bodyType: bodyType?.length > 0 ? bodyType : null,
          hairColor:
            hairColor?.length > 0 ? hairColor : null,
          eyeColor:
            eyeColor?.length > 0 ? eyeColor : null,
          race: race?.length > 0 ? race : null,
          gender: gender?.length > 0 ? gender : null,
          genderExpression:
            genderExpression?.length > 0
              ? genderExpression
              : null,
          sexualOrientation:
            sexualOrientation?.length > 0
              ? sexualOrientation
              : null,
          ethnicity: ethnicity?.length > 0
            ? ethnicity
            : null
        }
      }
      const nullFilteredMainObject = Object.fromEntries(
        Object.entries(payloadObj).filter(([_, v]) => v != null)
      )
      const nullFIlteredCriteriaObject = Object.fromEntries(
        Object.entries(payloadObj.criteria).filter(([_, v]) => v != null)
      )
      nullFilteredMainObject.criteria = nullFIlteredCriteriaObject
      payload.push(nullFilteredMainObject)
    })
    await UpdateRolestWithSelect({
      variables: {
        updateRoles: payload,
        parentId: selectId
      }
    }).then((res) => toggleModal())
  }

  return [SubmitUpdateRole, { data, loading }]
}

export default useUpdateRolesWithSelectMutation
