import { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material'

import { AdvertiserConstants, API, Colors } from '../../../../constants'

const StyledCellBody = styled(TableCell)`
  border-bottom: 1px solid ${Colors.antiFlashWhite} !important;
  font-size: 14px !important;
  color: ${Colors.grayLabelBlack} !important;
`
const CompanyWrapper = styled.div`
  font-weight: 600 !important;
  display: flex;
  align-items: center;
`
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: ${Colors.black};
  background: ${Colors.borderGray};
`
const LogoWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  background: white;
  border-radius: 5px;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`
const UserIcon = styled.div`
  border: 2px solid #FFFFFF;
  border-radius: 200px;
  font-size: 12px !important;
  width: 24px;
  height: 24px;
  text-align: center;
  background: ${props => props.bg};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -7px;
  cursor: pointer;
  color: ${Colors.black};
`
const StyledLink = styled.a`
  color: ${Colors.greenBlue};
  text-decoration: none;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  &:hover{
    color: ${Colors.black};
  }
`
const AdvertiserTableRow = ({ advertiser, editAdvertiserModel, deleteAdvertiserModel }) => {
  // state
  const [isImageFailed, setIsImageFailed] = useState(false)

  const usersIcon = (users) => {
    if (users?.length) {
      const firstFiveUsers = users.slice(0, 5)
      const otherUsers = users.slice(5)
      const randomColor = AdvertiserConstants.randomUserColor.sort(() => Math.random() - 0.5)
      return <Stack direction='row'>
        {firstFiveUsers.map((user, index) => <Tooltip key={index} placement='top' arrow
          title={<Typography fontWeight='bold' textTransform='capitalize' fontSize={10}>{user.firstname + ' ' + user.lastname}</Typography>}>
          <UserIcon bg={randomColor[index]}>
            <Typography fontWeight='bold' fontSize='12px'>{user.firstname?.charAt(0).toUpperCase()}</Typography>
          </UserIcon>
        </Tooltip>)}
        {otherUsers.length
          ? <Tooltip
            title={<Typography fontWeight='bold' textTransform='capitalize'>
              {otherUsers.map(e => e.firstname + ' ' + e.lastname).join(', ')}
            </Typography>}>
            <UserIcon bg={Colors.inputGray}>
              <span>{'+' + otherUsers.length}</span>
            </UserIcon>
          </Tooltip>
          : ''}
      </Stack>
    }
    return '-'
  }
  return (
    <TableRow>
      <StyledCellBody>
        <CompanyWrapper>
          {advertiser.logo?.uris?.logo
            ? <LogoWrapper>
              <img
                onError={() => setIsImageFailed(true)}
                src={!isImageFailed ? advertiser.logo?.uris?.logo : `${API.baseUrl}/${advertiser?.logo?.file}`}
              />
            </LogoWrapper>
            : <IconWrapper>
                <Typography
                  fontSize={'28px'}
                  fontWeight='bold'
                  lineHeight='initial'
                  style={{ textAlign: 'center' }}>
                  {advertiser?.name.charAt(0).toUpperCase()}
                </Typography>
            </IconWrapper>}
          <Link to={`/advertiser/${advertiser?.id}`} style={{ marginLeft: 25, textDecoration: 'none' }}>
            <Typography fontWeight={600} fontSize='14px' color={Colors.black}>
              {advertiser.name}
            </Typography>
          </Link>
        </CompanyWrapper>
      </StyledCellBody>
      <StyledCellBody>{advertiser.type[0].toUpperCase() + advertiser.type.slice(1)}</StyledCellBody>
      <StyledCellBody>{advertiser.contact || '-'}</StyledCellBody>
      <StyledCellBody>{advertiser.projectCount ?? 0}</StyledCellBody>
      <StyledCellBody>{usersIcon(advertiser.users)}</StyledCellBody>
      <StyledCellBody>
        <StyledLink onClick={() => editAdvertiserModel(advertiser)}>Edit</StyledLink>
        <StyledLink onClick={() => deleteAdvertiserModel(advertiser)}>Delete</StyledLink>
      </StyledCellBody>
    </TableRow>
  )
}

AdvertiserTableRow.propTypes = {
  advertiser: PropTypes.object,
  editAdvertiserModel: PropTypes.func,
  deleteAdvertiserModel: PropTypes.func
}

export default AdvertiserTableRow
