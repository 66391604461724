import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { FormikControl } from '../../../../../components/formik/formikControl'
import useProjectRoleListQuery from '../../../../ProjectDetail/Hooks/useProjectRoleListQuery'
import { Box } from '@mui/material'

const SpacedRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const marks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 20,
    label: ''
  },
  {
    value: 40,
    label: ''
  },
  {
    value: 60,
    label: ''
  },
  {
    value: 80,
    label: ''
  },
  {
    value: 100,
    label: '100+'
  }
]

const heightMarks = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: ''
  },
  {
    value: 4,
    label: ''
  },
  {
    value: 6,
    label: ''
  },
  {
    value: 8,
    label: '8+'
  }
]

const ProjectOverviewLeftForm = props => {
  const { index, values, demographicData } = props
  // Hooks

  const { id } = useParams()
  const { loading } = useProjectRoleListQuery(id)

  const bodyTypeData = demographicData?.bodyType

  return (
    <Box width='47%'>
      <FormikControl
        name={`roles.${index}.description`}
        isStandard
        control='input'
        label='Description'
        required
        multiline
        rows={5}
        style={{ marginBottom: 20 }}
      />
      <FormikControl
        name={`roles.${index}.quantity`}
        isStandard
        control='input'
        type='number'
        min='1'
        label='Number of positions'
        required
      />
      <SpacedRow style={{ marginTop: 30 }}>
        <FormikControl
          name={`roles.${index}.rate`}
          isStandard
          control='input'
          type='number'
          label='Daily Rate'
          required
        />
      </SpacedRow>
      <SpacedRow style={{ marginTop: 20 }}>
        <div style={{ width: '47%' }}>
          <FormikControl
            name={`roles.${index}.startDate`}
            isStandard
            disablePast={false}
            control='date'
            label='Start Date'
          />
        </div>
        <div style={{ width: '47%' }}>
          <FormikControl
            name={`roles.${index}.endDate`}
            isStandard
            disablePast={false}
            control='date'
            minDate={moment(values[index]?.startDate) ?? undefined}
            label='End Date'
          />
        </div>
      </SpacedRow>
      <FormikControl
        loading={loading}
        index={index}
        formalName={'age'}
        name={`roles.${index}.age`}
        control='slider'
        label='Age (Years)'
        min={0}
        max={100}
        marks={marks}
        values={values}
      />
      <FormikControl
        loading={loading}
        index={index}
        formalName={'height'}
        name={`roles.${index}.height`}
        control='slider'
        label='Height (Feet)'
        min={1}
        max={8}
        marks={heightMarks}
        values={values}
      />
      <FormikControl
        name={`roles.${index}.bodyType`}
        isStandard
        control='multiselectother'
        label='Body Type'
        required
        data={['Any'].concat(bodyTypeData?.map((item) => item?.value)) ?? []}
        showPlaceholder={false}
        freeSolo={false}
      />
    </Box>
  )
}

ProjectOverviewLeftForm.propTypes = {
  index: PropTypes.number,
  values: PropTypes.array,
  demographicData: PropTypes.object
}

export default ProjectOverviewLeftForm
