import { useMutation } from '@apollo/client'
import SelectAPI from '../../../services/Select/SelectAPI'

const useDeleteTalentMutation = () => {
  const [UpdateSelect, { loading }] = useMutation(SelectAPI.updateSelect(), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })
  return [UpdateSelect, { loading }]
}

export default useDeleteTalentMutation
