import styled, { keyframes } from 'styled-components'
import { Fonts } from '../../../constants'

export const TalentContainer = styled.div`
  padding: 0 40px 40px;
  display: grid;
  grid-gap: ${props => `calc(24px * ${props['data-size']}/ 220)`} ;
  grid-template-columns: ${props =>
    `repeat(auto-fill, minmax(${props['data-size']}px, 1fr))`};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`

export const TitleText = styled.span`
  font-family: ${Fonts.degular};
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.25px;
  text-align: left;
`
export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background: white;
  padding: 4px 0 20px;
`

export const DescriptionText = styled.span`
  padding: 0;
  font-family: ${Fonts.degular};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.38);
  letter-spacing: 0.15px;
  text-align: left;
`
export const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
export const ShimmerBox = styled.div`
  height: 250px;
  cursor: pointer;
  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`
export const NonSticky = styled.div``
export const StickyHeader = styled.div`
  position: sticky !important;
  z-index: 9;
  top: 0;
  padding: 24px 40px 20px 40px;
  background-color: white;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
`
export const NoRecordText = styled.div`
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
  width: 100%;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  height: 100%;
  overflow: hidden;
  margin: 0;
  ms-overflow-style: none;
  overflow-y: auto;
  transition: all .5s ease;
`

export const SidebarWrapper = styled.div`
  position: sticky;
  z-index: 50;
  top: 0;
  height: 100vh;
`
export const BodyWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.showFilterModal && '100vh'};
  overflow: ${props => props.showFilterModal && 'hidden'};
`
