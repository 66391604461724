const ENV = process.env.REACT_APP_ENV as Envs ?? 'prod'

type Envs = 'local' | 'dev' | 'qa' | 'prod'

const urlsMap: Record<Envs, string> = {
  local: 'http://localhost:3000',
  dev: 'https://book.dev.tlynt.com',
  qa: 'https://book.qa.tlynt.com',
  prod: 'https://book.tlynt.com'
}

const shareableUrlsMap: Record<Envs, string> = {
  local: 'https://dev.tlynt.app',
  dev: 'https://dev.tlynt.app',
  qa: 'https://qa.tlynt.app',
  prod: 'https://tlynt.app'
}

const branchUrlsMap: Record<Envs, string> = {
  local: 'https://tlynt.test-app.link',
  dev: 'https://tlynt.test-app.link',
  qa: 'https://tlynt.test-app.link',
  prod: 'https://tlynt.app.link'
}

export const BRANCH_URL = branchUrlsMap[ENV]

export const PUBLIC_SHAREABLE_URL = shareableUrlsMap[ENV]

export const URL = urlsMap[ENV]
