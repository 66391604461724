import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { showToast } from '../../../redux/actions/DashBoardActions'
import SelectAPI from '../../../services/Select/SelectAPI'

const useSelectUpdateMutation = () => {
  // hook
  const dispatch = useDispatch()
  const [UpdateSelectMutation, { loading }] = useMutation(SelectAPI.updateSelect(), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const updateSelect = async (data, cb) => {
    await UpdateSelectMutation({
      variables: data
    })
      .then(res => {
        dispatch(showToast({
          message: "You've successfully updated select.",
          isError: false
        }))
        // callback for hiding the SHORT MODAL
        cb()
      })
      .catch(e => dispatch(showToast({
        message: e?.message,
        isError: true
      })))
  }
  return {
    updateSelect,
    loading
  }
}

export default useSelectUpdateMutation
