import { useContext, type Dispatch, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { type QueryResult, useMutation, useQuery, type ApolloCache, type DefaultContext, type OperationVariables, type MutationTuple } from '@apollo/client'
import { useFeatureValue } from '@growthbook/growthbook-react'

import { CreateProject } from '../../components/pages/UGC/CreateProject/CreateProject'
import { DeliverableProvider } from '../../contexts/deliverables'
import { GetUGCProject, ListBrands, createUGCProject, updateUGCProject, CreateProjectProduct, UpdateProduct, GetProductByProject, UpdateProjectProduct, DeleteProjectProduct, ListProjectDeliverables, SendNotification } from '../../apis/ugc'
import { Layout } from '../../components'
import { ProductProvider } from '../../contexts/product'
import { ProjectDetail, Deliverables, Product, Talent, Directions } from '../../components/pages'
import { type IProjectDetail, type IProjectDetailActions, UGCProjectDetailContext, UGCProjectDetailProvider } from '../../contexts/projectDetail'
import { UGCHeader } from '../../components/UGCHeader/UGCHeader'
import { UGCProjectSidebar } from '../../components/UGCProjectSidebar/UGCProjectSidebar'
import { UGCSidebarProvider } from '../../contexts/ugcSidebar'
import { TalentProvider } from '../../contexts/talent'
import { useAuth } from '../../services/Auth'
import { useSubmitDeliverables } from '../../hooks/UGC/useSubmitDeliverables'
import { useUpdateDeliverable } from '../../hooks/UGC/useUpdateDeliverable'
import ProjectAPI from '../../services/Project/ProjectAPI'
import style from './UGCProjectWizard.module.scss'
import { useInitilizeSidebarStatus } from '../../hooks/UGC/useInitilizeSidebarStatus'
import DemographicAPI from '../../services/DemographicAPI'
import { DELETE_SELECT } from '../../services/API'

interface Logo {
  id: string
  file: string
  uris: Uris
  __typename: string
}

interface Uris {
  card: string
  thumbnail: string
  logo: string
  __typename: string
}

interface organizationList {
  id: string
  name: string
  contact: string
  logo: Logo
  type: string
  __typename: string
}
interface IListBrand {
  id: string
  name: string
  logo: {
    id: string
    type: string
    file: string
    uris: {
      thumbnail: string
      card: string
      grid: string
      full: string
      logo: string
      original: string
      profilePic: string
    }
    createdAt: string
  }
}

export interface IListProjectDeliverables {
  id: string
  mediaType: string
  projectId: string
  script: string
  type: string
  specs: {
    duration: string
    orientation: string
    deliverableType: string
    specs: string
  }
}

export interface IActiveTabProps {
  projectTalentData: {
    id: string
    name: string
    ownerId: string
    selectId: string
    compensation: number
    hasTalentProfile: string
    numberOfCreators: number
    profile: Array<{
      id: number | string
      name: string
      projectId?: string
      quantity: number
      ageRanges?: string[] | null
      gender?: string[] | null
      ethnicity?: string[] | null
      description?: string
    }>
    loading: boolean
  }
}

export interface IDemographicData {
  id: string
  ownerId: string
  projectId: string
  name: string
  url: string
  description: string[]
  provisioningMethod: string
  directions: string[]
}

export interface IProjectProduct {
  id: string
  ownerId: string
  projectId: string
  name: string
  url: string
  description: string[]
  provisioningMethod: string
  directions: string[]
}

interface ProjectProductQueryMutationObj {
  useUpdateProjectProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useUpdateProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useCreateProjectProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useGetProductByProjectQuery: () => QueryResult<{ getProductByProject: IProjectProduct | null }, { projectId: string | undefined }>
}
interface ProjectDetailQueryMutationObj {
  useUpdateProjectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useCreateUGCProjectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useListBrandQuery: () => QueryResult<{ listBrands: IListBrand[] }, { orgId: string }>
  useGetProductByProjectQuery: () => QueryResult<{ getProductByProject: IProjectProduct | null }, { projectId: string | undefined }>
  useDeleteProjectProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useSendNotificationMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
}

interface DeliverablelQueryMutationObj {
  useSubmitDeliverables: (close: boolean) => { handleSubmitProject: (close: boolean) => void, isLoading: boolean }
  useUpdateDeliverable: (close: boolean) => { handleUpdateDeliverable: (close: boolean) => void, isLoading: boolean }
  useListProjectDeliverables: () => QueryResult<{ listProjectDeliverables: IListProjectDeliverables[] | null }, { projectId: string | undefined }>
}

interface ProjectTalentQueryMutationObj {
  useUpdateUGCProjectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useCreateProjectRoleMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useUpdateProjectRoleMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useDeleteProjectRoleMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useDeleteRoleSelectMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>
  useGetDemographicDataQuery: () => QueryResult<{ getTalentDemographicSelectionData:
  { id: string, ownerId: string, projectId: string, name: string, url: string, description: string[], provisioningMethod: string, directions: string[] } | null }>
}

const ActiveTab = (props: IActiveTabProps): JSX.Element | null => {
  const { activeTab } = useParams()
  const { id } = useParams()
  const { projectTalentData } = props
  const projectDetailData = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)[0]

  const { data: organisationListData, loading: organisationListLoading } = useQuery(ProjectAPI.getOrganizationList(), {
    variables: { offset: 0, limit: 1000, sort: JSON.stringify({ name: 'asc' }) },
    fetchPolicy: 'no-cache'
  })

  const projectProductQueryMutationObj: ProjectProductQueryMutationObj = {
    useUpdateProjectProductMutation: () => useMutation(UpdateProjectProduct, { notifyOnNetworkStatusChange: true }),
    useUpdateProductMutation: () => useMutation(UpdateProduct, { notifyOnNetworkStatusChange: true }),
    useCreateProjectProductMutation: () => useMutation(CreateProjectProduct, { notifyOnNetworkStatusChange: true }),
    useGetProductByProjectQuery: () => useQuery<{ getProductByProject: IProjectProduct | null }, { projectId: string | undefined }>(GetProductByProject, { variables: { projectId: id }, skip: id === undefined })
  }

  const projectDetailQueryMutationObj: ProjectDetailQueryMutationObj = {
    useUpdateProjectMutation: () => useMutation(updateUGCProject, { notifyOnNetworkStatusChange: true }),
    useCreateUGCProjectMutation: () => useMutation(createUGCProject, { notifyOnNetworkStatusChange: true }),
    useListBrandQuery: () => useQuery<{ listBrands: IListBrand[] }, { orgId: string }>(ListBrands, { variables: { orgId: projectDetailData.ownerId }, skip: projectDetailData.ownerId === '' }),
    useGetProductByProjectQuery: () => useQuery<{ getProductByProject: IProjectProduct | null }, { projectId: string | undefined }>(GetProductByProject, { variables: { projectId: id }, skip: projectDetailData?.hasProduct === 'false', fetchPolicy: 'no-cache' }),
    useDeleteProjectProductMutation: () => useMutation(DeleteProjectProduct, { notifyOnNetworkStatusChange: true }),
    useSendNotificationMutation: () => useMutation(SendNotification, { notifyOnNetworkStatusChange: true })
  }

  const deliverableQueryMutation: DeliverablelQueryMutationObj = {
    useSubmitDeliverables: (close: boolean) => useSubmitDeliverables(close),
    useUpdateDeliverable: (close: boolean) => useUpdateDeliverable(close),
    useListProjectDeliverables: () => useQuery(ListProjectDeliverables, { variables: { projectId: id }, skip: id == null, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })
  }

  const demographicData = useSelector((state: any) => state.DemographicReducer)

  const talentQueryMutation: ProjectTalentQueryMutationObj = {
    useUpdateUGCProjectMutation: () => useMutation(updateUGCProject, { notifyOnNetworkStatusChange: true }),
    useCreateProjectRoleMutation: () => useMutation(ProjectAPI.addRolesWithSelect(), { notifyOnNetworkStatusChange: true }),
    useUpdateProjectRoleMutation: () => useMutation(ProjectAPI.updateRolesWithSelect(), { notifyOnNetworkStatusChange: true }),
    useDeleteProjectRoleMutation: () => useMutation(ProjectAPI.deleteRoleProject(), { notifyOnNetworkStatusChange: true }),
    useDeleteRoleSelectMutation: () => useMutation(DELETE_SELECT, { notifyOnNetworkStatusChange: true }),
    useGetDemographicDataQuery: () => useQuery(DemographicAPI.talentDemographicSelectionData(), { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true, skip: demographicData.ethnicity.length > 0 })
  }

  switch (activeTab) {
    case 'details':
      return <ProjectDetail projectDetailQueryMutationObj={projectDetailQueryMutationObj} organisationListLoading={organisationListLoading} organizationList={organisationListData?.organizationList.map((item: organizationList) => ({ id: item.id, value: item.name, logo: item.logo?.uris?.logo, type: item?.type })) ?? []} />
    case 'directions':
      return <DeliverableProvider><Directions /></DeliverableProvider>
    case 'deliverables':
      return <DeliverableProvider><Deliverables deliverableQueryMutation={deliverableQueryMutation} /></DeliverableProvider>
    case 'talent':
      return <TalentProvider><Talent projectTalentData={projectTalentData} talentQueryMutation={talentQueryMutation} /></TalentProvider>
    case 'product':
      return <ProductProvider><Product projectProductQueryMutationObj={projectProductQueryMutationObj} /></ProductProvider>
    default:
      return <></>
  }
}

const InitilizeSidebarStatus = (): JSX.Element => {
  useInitilizeSidebarStatus()
  return <></>
}

function UGCProjectWizard (): JSX.Element {
  const defaultUGCCompensation = useFeatureValue('portal:ugc:default-compensation', 300)
  const auth = useAuth() // DO NOT USE CONTEXT - USE REDUX USER REDUCER
  const { activeTab, id } = useParams()

  const updateUGCProjectMutation = useMutation(updateUGCProject)
  const { data: projectGetData, loading: projectGetLoading, refetch } = useQuery(GetUGCProject, { variables: { getProjectId: id, withBrand: true }, skip: !!(id == null), notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })
  const [dataLoading, setDataLoading] = useState<boolean>(true)

  useEffect(() => {
    if (activeTab === 'talent') {
      void handleRefetchUGCProject()
    }
  }, [activeTab])

  const handleRefetchUGCProject = async (): Promise<void> => {
    await refetch()
  }

  useEffect(() => {
    setDataLoading(projectGetLoading)
  }, [projectGetLoading])

  const projectTalentData = useMemo(() => {
    return {
      id: projectGetData?.getProject?.id,
      name: projectGetData?.getProject?.name,
      ownerId: projectGetData?.getProject?.ownerId,
      selectId: projectGetData?.getProject?.selectId,
      compensation: projectGetData?.getProject?.compensation ?? defaultUGCCompensation,
      hasTalentProfile: (projectGetData?.getProject?.hasTalentProfile)?.toString(),
      numberOfCreators: projectGetData?.getProject?.numberOfCreators > 0 ? projectGetData?.getProject?.numberOfCreators : 1,
      profile: projectGetData?.getProject?.roles?.length > 0
        ? projectGetData?.getProject?.roles?.sort((a: { createdAt: string }, b: { createdAt: string }) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((role: { id: string
          projectId: string
          name: string
          quantity: number
          description: string
          selectId: string
          criteria: {
            gender: string[] | null
            ethnicity: string[] | undefined
            ageRanges: Array<{ min: number, max: number }>
          } }) => (
          {
            id: role.id,
            name: role.name,
            projectId: role.projectId,
            quantity: role.quantity,
            selectId: role.selectId,
            description: role.description ?? undefined,
            ageRanges: role.criteria?.ageRanges === null ? ['any'] : role.criteria?.ageRanges?.map((age) => `${age.min}-${age.max}`),
            gender: role.criteria?.gender === null ? ['any'] : role.criteria?.gender,
            ethnicity: role.criteria?.ethnicity === null ? ['any'] : role.criteria?.ethnicity,
            isRemoved: false,
            isEdited: true
          }
        ))
        : [{
            id: Date.now(),
            name: 'Profile 1',
            projectId: id,
            quantity: 1,
            ageRanges: ['any'],
            gender: ['any'],
            ethnicity: ['any'],
            description: ''
          }],
      loading: dataLoading
    }
  }, [projectGetLoading, dataLoading])

  return (
   <Layout>
    <UGCSidebarProvider>
      <UGCProjectDetailProvider>
        <InitilizeSidebarStatus />
        { activeTab === undefined && <CreateProject userName={auth?.user?.name?.first} /> }
        { activeTab !== undefined && (
          <div className={style.UGCLayout}>
            <div className={style.UGCLayout__Main}>
              <UGCHeader updateUGCProjectMutation={() => updateUGCProjectMutation} />
              <main className={style.UGCLayout__MainHeroSection}>
                <div>
                  <UGCProjectSidebar type='ugc' activeTab={activeTab ?? ''} />
                </div>
                <div className={style.UGCLayout__MainHeroActiveTab}>
                  <ActiveTab projectTalentData={projectTalentData} />
                </div>
              </main>
            </div>
          </div>
        )}
      </UGCProjectDetailProvider>
    </UGCSidebarProvider>
   </Layout>
  )
}

export default UGCProjectWizard
