import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { decodeJwt } from 'jose'

import { Images } from '../../../../assets'
import { RenderIf } from '../../../../utils/Helpers'
import { Colors, Fonts } from '../../../../constants'
import useCreateVoteMutation from '../../Hooks/useCreateVoteMutation'
import useRemoveVoteMutation from '../../Hooks/useRemoveVoteMutation'
import { useSelector } from 'react-redux'

const TalentContainer = styled.div`
  flex: 0 1 70%;
  height: 300px;

  @media (min-width: 700px) {
    flex: 0 1 calc(45% - 1em);
  }
  @media (min-width: 900px) {
    flex: 0 1 calc(25% - 0.5em);
  }
`
const Overlay = styled.div`
  display: flex;
  flex: 1;
  background: transparent;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.64) 92%
  );
`
const OverlayBottom = styled.div`
  height: 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const ActionsWrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LikeIndicatorWrapper = styled.div`
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
`

const LikeIndicatorBox = styled.div`
  font-size: 10px;
  height: 22px;
  width: 34px;
  color: ${Colors.white};
  background: ${Colors.black};
  border-radius: 4px;
  display: grid;
  place-items: center;
`
const LikeIndicatorTriangle = styled.div`
  height: 0;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid ${Colors.black};
`

const LikeIndicatorText = styled.p`
  margin: 0;
  font-family: ${Fonts.degular};
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: ${Colors.white};
`

const LikeButton = styled.button`
  height: 100%;
  width: 20%;
  background: ${props => (props.isLiked ? Colors.cerise : Colors.brightGray)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  &:hover ${LikeIndicatorWrapper} {
    display: flex;
  }
`
const DetailButton = styled(Link)`
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(42px);
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
`
const LikeImage = styled.img`
  width: 20px;
`
const TalentDetailWrapper = styled.div`
  height: 56px;
  box-sizing: border-box;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
`
const TalentDetail = styled.div`
  height: 100%;
  width: 77%;
  box-sizing: border-box;
  padding-left: 16px;
`

const TalentLocation = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: ${Colors.white};
`

const TalentImage = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(${props => props.imgSrc});
`

const MediaCountWrapper = styled.div`
  width: 23%;
  height: 100%;
  display: grid;
  place-items: center;
`

const MediaToggleBox = styled.div`
  box-sizing: border-box;
  height: 52px;
  width: 28px;
  background: ${Colors.backgroundGrayOpacity};
  backdrop-filter: blur(44px);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 0;
`

const ToggleArrow = styled.img`
  opacity: ${props => !props.isAllowed ? 0.2 : 1};
  height: 6px;
  cursor: ${props => !props.isAllowed ? 'not-allowed' : 'pointer'}
`

export const VoteBtnContent = (likeTalentProps) => {
  const { isLoading, isLiked, isAdmin } = likeTalentProps
  if (isLoading) {
    return <CircularProgress size={15} color='inherit' />
  } else {
    return (
      <>
        {!isAdmin && (
          <LikeIndicatorWrapper>
            <LikeIndicatorBox>
              <LikeIndicatorText>{isLiked ? 'Liked' : 'Like'}</LikeIndicatorText>
            </LikeIndicatorBox>
            <LikeIndicatorTriangle />
          </LikeIndicatorWrapper>
        )}
        <LikeImage src={isLiked ? Images.liked : Images.like} />
      </>
    )
  }
}

export const TalentCard = props => {
  // init
  const { item, selectId, childSelectId } = props
  const user = useSelector((state) => state.UserReducer)
  // hooks
  const { createVote, loading: createVoteLoading } = useCreateVoteMutation(childSelectId)
  const { removeVote, loading: removeVoteLoading } = useRemoveVoteMutation(childSelectId)
  // states
  const [isLiked, setIsLiked] = useState(false)
  const [thumbnailImageIndex, setThumbnailImageIndex] = useState(0)

  useEffect(() => {
    setIsLiked(item?.isLiked)
  }, [item?.isLiked])

  const handleSuccess = () => setIsLiked(!isLiked)

  const handleLike = () => {
    if (user?.isAdmin) return
    !isLiked ? createVote(handleSuccess, item.id) : removeVote(handleSuccess, item.id)
  }

  const handelIncrement = () => setThumbnailImageIndex(thumbnailImageIndex + 1)
  const handelDecrement = () => setThumbnailImageIndex(thumbnailImageIndex - 1)

  return (
    <TalentContainer>
      <TalentImage imgSrc={item?.media?.length > 0 ? item?.media[thumbnailImageIndex]?.uris?.card : Images.tlyntPlaceholder}>
        <Overlay>
          <OverlayBottom>
            <TalentDetailWrapper>
              <TalentDetail>
                <RenderIf isTrue={item?.firstname || item?.lastname}>
                  <Stack spacing={1}>
                    <Typography fontSize={24} lineHeight={'32px'} fontWeight='bold' color={Colors.white}>{(item?.firstname ?? '') + ' ' + (item?.lastname ?? '')}</Typography>
                  </Stack>
                </RenderIf>
                {!!item?.location && <TalentLocation>{item?.location}</TalentLocation>}
              </TalentDetail>
              {item?.media?.length > 0 && (
                <MediaCountWrapper>
                  <MediaToggleBox>
                    <ToggleArrow isAllowed={thumbnailImageIndex > 0} onClick={thumbnailImageIndex > 0 ? handelDecrement : undefined} src={Images.chevronUp} />
                    <ToggleArrow isAllowed={thumbnailImageIndex < item?.media?.length - 1} onClick={thumbnailImageIndex < item?.media?.length - 1 ? handelIncrement : undefined} src={Images.chevronDown} />
                  </MediaToggleBox>
                </MediaCountWrapper>
              )}
            </TalentDetailWrapper>
            <ActionsWrapper>
              <DetailButton to={`/public/selects/${selectId}/${childSelectId}/talent/${item?.id}`}>
                <Typography fontWeight='bold' fontSize={15} color='white' textAlign='center'>SEE DETAILS</Typography>
              </DetailButton>
              <LikeButton isLiked={isLiked} onClick={handleLike} disabled={user.isAdmin}>
                <VoteBtnContent isLoading={createVoteLoading || removeVoteLoading} isLiked={isLiked} isAdmin={user?.isAdmin} />
              </LikeButton>
            </ActionsWrapper>
          </OverlayBottom>
        </Overlay>
      </TalentImage>
    </TalentContainer>
  )
}

TalentCard.propTypes = {
  item: PropTypes.object,
  selectId: PropTypes.string,
  childSelectId: PropTypes.string,
}

export default TalentCard
