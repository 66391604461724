import { useReducer, createContext, type ReactNode, type Dispatch } from 'react'

interface IOverview {
  loading: boolean
  deliverables: Deliverable[]
  roles: Role[]
  brandId: string | null
  numberOfCreators: number
  compensation: number
  createdAt: string
  description: string
  endDate: string
  framingSpecs: string[]
  hasProduct: boolean
  hasTalentProfile: boolean
  id: string
  iteration: number
  lightingSpecs: string[]
  locationSpecs: string[]
  referenceLinks: string[]
  name: string
  notes?: any
  owner: Owner
  ownerId: string
  selectId: string
  status: string
  color: string
  type: string
  updatedAt: string
  wardrobeSpecs: string[]
  productURL: string
  productDescription: string | string[]
  productDirections: string | string[]
  provisioningMethod: string
  archived: boolean
  selectName: string
  atleastOneTalentBooked?: boolean
}

interface IAssetsBookedTalents {
  id: string
  name: string
  photo: string
  assetApprovedCount: number
  inReviewCount: number
  allApproved: boolean
  initials: string
  status: string
}

export interface IProjectTalentStatus {
  talent: {
    firstname: string
    lastname: string
    mediaProfile?: {
      uris: {
        card: string
        profilePic: string
      }
    }
    videoProfile: {
      uris: {
        poster: string
        stream: string
      }
    }
    auditionSubmissions: {
      uris: {
        poster: string
        stream: string
      }
    }
  } | null
  talentId: string
  createdAt: string
  updatedAt: string
  status: string
}

interface IInitialState {
  overview: IOverview
  interestedTalents: IProjectTalentStatus[]
  assetsBookedTalents: IAssetsBookedTalents[]
}

interface Owner {
  id: string
  name: string
  logo: {
    uris: {
      logo: string
    }
  }
  brands: Brand[]
}

interface Brand {
  id: string
  name: string
  logo: {
    uris: {
      logo: string
    }
  }
}

interface Role {
  id: string
  name: string
  projectId: string
  selectId?: any
  quantity: number
  description: string
  startDate?: any
  endDate?: any
  rate?: any
  criteria: Criteria
  createdAt: string
}

interface Criteria {
  ageRanges: AgeRange[]
  ethnicity: string[]
  gender: string[]
}

interface AgeRange {
  min: number
  max: number
}

interface Deliverable {
  id: string
  mediaType: string
  projectId: string
  specs?: Spec
  type: string
  updatedAt: string
  script?: string[]
  updatedBy: string
  createdAt: string
  stillSpecs?: {
    directions: string[]
  }
}

interface Spec {
  createdAt: string
  deliverableType: string
  duration?: number
  orientation: string
  projectId: string
  specs: string[]
  updatedAt?: any
}

const INITIAL_STATE: IInitialState = {
  overview: {
    loading: true,
    deliverables: [],
    roles: [],
    brandId: null,
    numberOfCreators: 0,
    compensation: 0,
    createdAt: '',
    description: '',
    endDate: '',
    framingSpecs: [],
    hasProduct: false,
    hasTalentProfile: false,
    id: '',
    iteration: 1,
    lightingSpecs: [],
    locationSpecs: [],
    referenceLinks: [],
    name: '',
    notes: '',
    owner: {
      id: '',
      name: '',
      logo: {
        uris: {
          logo: ''
        }
      },
      brands: []
    },
    ownerId: '',
    selectId: '',
    status: '',
    color: '',
    type: '',
    updatedAt: '',
    wardrobeSpecs: [''],
    productURL: '-',
    productDescription: '-',
    productDirections: '-',
    provisioningMethod: '-',
    archived: false,
    selectName: '',
    atleastOneTalentBooked: false
  },
  interestedTalents: [],
  assetsBookedTalents: []
}

interface IAction {
  type: string
  payload: any
}
export const SET_OVERVIEW = 'SET_OVERVIEW'
export const SET_INTERESTED_TALENTS = 'SET_INTERESTED_TALENTS'
export const SET_ASSETS_BOOKED_TALENTS = 'SET_ASSETS_BOOKED_TALENTS'
export const SET_ATLEAST_ONE_TALENT_BOOKED = 'SET_ATLEAST_ONE_TALENT_BOOKED'

const UGCDetailReducer = (state: any, action: IAction): any => {
  switch (action.type) {
    case SET_OVERVIEW:
      return {
        ...state,
        overview: action.payload
      }
    case SET_INTERESTED_TALENTS:
      return {
        ...state,
        interestedTalents: action.payload
      }
    case SET_ASSETS_BOOKED_TALENTS:
      return {
        ...state,
        assetsBookedTalents: action.payload
      }
    case SET_ATLEAST_ONE_TALENT_BOOKED:
      state.overview.atleastOneTalentBooked = action.payload
      return state
    default:
      return state
  }
}

export const UGCDetailContext = createContext<[IInitialState, Dispatch<IAction>]>([INITIAL_STATE, () => { }])

export const UGCDetailViewProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(UGCDetailReducer, INITIAL_STATE)

  return <UGCDetailContext.Provider value={[state, dispatch]}>{children}</UGCDetailContext.Provider>
}
