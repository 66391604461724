/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Stack } from '@mui/system'
import Skeleton from '@mui/material/Skeleton'
import { Typography } from '../../../../components'
import useGetSelectionData from '../../Hooks/useGetSelectionData'
import { convertHeightToFeetAndInch } from '../../../../utils/Helpers'
import useGetTalentDemographic from '../../Hooks/useGetTalentDemographic'

const PublicTalentInfo = props => {
  // props
  const { profileData } = props
  const { data: selectionData, loading: selectionDataLoading } =
    useGetSelectionData()
  const { data: talentDemographicData, loading: talentDemographicLoading } =
    useGetTalentDemographic(profileData?.id)

  // components
  const Row = rowProps => {
    const { title, description } = rowProps
    return (
      <Grid item>
        <Typography fontWeight={400} fontSize={14} color="#757575">
          {title}
        </Typography>
        <Box sx={{ height: 10 }} />
        <Typography fontWeight={400} fontSize={16} style={{ width: '80%' }}>
          {description}
        </Typography>
      </Grid>
    )
  }

  const SanitizeData = (masterData, selectedData) => {
    if (!selectedData || selectedData[0] === null || selectedData?.length === 0 || selectedData[0] === undefined) {
      return '-'
    } else {
      const filteredData = masterData
        .filter(item => selectedData.includes(item.id))
        .map(item => item.value)
        .join(', ')
      return filteredData
    }
  }

  const SanitizeHeightData = height => {
    if (!height) {
      return '-'
    } else {
      return `${convertHeightToFeetAndInch(height).feet}'
      ${
        convertHeightToFeetAndInch(height).inch === 0
          ? ''
          : convertHeightToFeetAndInch(height).inch
      }${convertHeightToFeetAndInch(height).inch !== 0 ? "''" : ''}`
    }
  }

  const getAge = dateString => {
    if (!dateString) {
      return '-'
    } else {
      const today = new Date()
      const birthDate = new Date(dateString)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    }
  }

  if (selectionDataLoading || talentDemographicLoading) {
    return (
      <Stack paddingLeft={4} paddingRight={4}>
        <Skeleton variant="text" height={60} animation="wave" />
        <Skeleton variant="text" height={60} animation="wave" />
        <Skeleton variant="text" height={60} animation="wave" />
      </Stack>
    )
  }
  return (
    <Box
      sx={{ flexGrow: 1, paddingInline: '35px' }}
      justifyContent="space-between">
        <Grid container flexDirection="row">
          <Grid item xs={6}>
            <Row
              title="Age"
              description={getAge(
                talentDemographicData?.talentDemographicGet?.birthDate
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Body Type"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData.bodyType,
                [talentDemographicData?.talentDemographicGet.bodyType]
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Height"
              description={SanitizeHeightData(
                talentDemographicData?.talentDemographicGet?.height
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Hair Color"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData.hairColor,
                [talentDemographicData?.talentDemographicGet.hairColor]
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Eye Color"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData.eyeColor,
                [talentDemographicData?.talentDemographicGet.eyeColor]
              )}
            />
            <Box sx={{ height: 20 }} />

            <Row
              title="Race"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData.race,
                talentDemographicData?.talentDemographicGet.race
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Row
              title="Ethnicity"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData
                  .countryOfHeritage,
                talentDemographicData?.talentDemographicGet.countryOfHeritage
              )}
            />
            <Box sx={{ height: 20 }} />

            <Row
              title="Country of Heritage"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData.country,
                [talentDemographicData?.talentDemographicGet.country]
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Gender"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData.gender,
                [talentDemographicData?.talentDemographicGet.gender]
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Gender Expression"
              description={SanitizeData(
                selectionData?.getTalentDemographicSelectionData
                  .genderExpression,
                [talentDemographicData?.talentDemographicGet.genderExpression]
              )}
            />
            <Box sx={{ height: 20 }} />
            <Row
              title="Sexual Orientation"
              description={ talentDemographicData?.talentDemographicGet?.sexualOrientationOther
                ? talentDemographicData?.talentDemographicGet.sexualOrientationOther
                : SanitizeData(
                  selectionData?.getTalentDemographicSelectionData
                    .sexualOrientation,
                  [talentDemographicData?.talentDemographicGet.sexualOrientation]
                )}
            />
            <Box sx={{ height: 100 }} />
          </Grid>
        </Grid>
    </Box>
  )
}

PublicTalentInfo.propTypes = {
  profileData: PropTypes.object
}

export default PublicTalentInfo
