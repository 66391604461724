import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useSearchTalentQuery from './useSearchTalentQuery'
import useTalentMediaQuery from '../../../hooks/Talent/useTalentMediaQuery'

function useCombineWithMedia (props) {
  // props
  const { search, resetToDone, setIsDataFetched, isUserTalent } = props
  const searchQuery = new URLSearchParams(useLocation().search)
  const height = searchQuery.get('height')?.split(',')
  const weight = searchQuery.get('weight')?.split(',')
  const bodyType = searchQuery.get('bodyType')?.split(',')
  const hairColor = searchQuery.get('hairColor')
  const eyeColor = searchQuery.get('eyeColor')
  const gender = searchQuery.get('gender')
  const genderExpression = searchQuery.get('genderExpression')
  const race = searchQuery.get('race')
  const sexualOrientation = searchQuery.get('sexualOrientation')
  const countryOfHeritage = searchQuery.get('countryOfHeritage')
  const ethinicity = searchQuery.get('ethinicity')
  const talentTags = searchQuery.get('talentTags')
  const birthdate = searchQuery.get('birthdate')?.split(',')

  const searchObject = {
    height: height || null,
    weight: weight || null,
    hair_color: hairColor ? [hairColor] : null,
    body_type: bodyType ? [bodyType] : null,
    eye_color: eyeColor ? [eyeColor] : null,
    gender: gender ? [gender] : null,
    gender_expression: genderExpression ? [genderExpression] : null,
    race_id: race ? [race] : null,
    sexual_orientation: sexualOrientation ? [sexualOrientation] : null,
    country: countryOfHeritage ? [countryOfHeritage] : null,
    ethnicity: ethinicity ? [ethinicity] : null,
    birth_date: birthdate || null,
    talent_tags: [talentTags] || null
  }

  // states
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [isRouteEmpty, setIsRouteEmpty] = useState(true)
  const [total, setTotal] = useState(0)
  // apis
  const parameter = {
    flagged: false,
    disabled: false
  }

  if (isUserTalent) {
    parameter.flagged = null
    parameter.disabled = null
  }
  const {
    data: talentData,
    loading: talentLoading,
    fetchMore: fetchMoreSearchTalent
  } = useSearchTalentQuery({
    offset: 0,
    limit: 100,
    ...parameter,
    search: search ?? '',
    filters: JSON.stringify(
      Object.fromEntries(
        Object.entries(searchObject).filter(([_, v]) => v !== null)
      )
    )
  })
  const {
    data: talentMediaData,
    loading: talentMediaLoading,
    fetchMore: fetchMoreTalentMedia
  } = useTalentMediaQuery({
    talentIds: talentData?.searchTalents.talents.map(talent => talent?.id),
    disabled: false
  })
  // loading talents into states
  useEffect(() => {
    setIsDataFetched(false)
    if (talentData?.searchTalents?.talents.length > 0 && !talentLoading) {
      setData(
        talentData?.searchTalents?.talents.map(item => ({
          ...item,
          isSelected: false
        }))
      )
      setTotal(talentData?.searchTalents?.metadata.total)
      setLoading(false)
    } else if (talentData?.searchTalents?.talents.length === 0 && !talentLoading) {
      setData([])
      setLoading(false)
      setIsDataFetched(true)
      setTotal(talentData?.searchTalents?.metadata.total)
    }
  }, [talentLoading])

  useEffect(() => {
    if (search) setLoading(true)
    else if (search === '') resetToDone()
  }, [search])
  // mapping media into talents
  useEffect(() => {
    if (talentMediaData?.mediaList?.length > 0) {
      const dataWithMedia = data?.map(talent => {
        const medias = talentMediaData?.mediaList?.filter(
          media => talent.id === media.talentId
        )
        return {
          ...talent,
          media: medias
        }
      })
      setLoading(false)
      setIsDataFetched(true)
      setData([...dataWithMedia])
    }
  }, [talentMediaLoading])
  // checking is route is empty
  useEffect(() => {
    if (
      (height?.length === 1 || height === undefined) &&
      (weight?.length === 1 || weight === undefined) &&
      !hairColor &&
      !eyeColor &&
      !gender &&
      !genderExpression &&
      !race &&
      !sexualOrientation &&
      !countryOfHeritage &&
      !ethinicity &&
      !talentTags &&
      !bodyType &&
      (birthdate?.length === 1 || birthdate === undefined)
    ) {
      setIsRouteEmpty(true)
    } else {
      setIsRouteEmpty(false)
    }
  }, [searchQuery])

  const fetchMore = async (cb, cb2) => {
    const { data: searchFetchMoreData } = await fetchMoreSearchTalent({
      variables: {
        offset: data.length,
        limit: 100
      }
    })
    if (searchFetchMoreData && searchFetchMoreData.searchTalents.talents.length > 0) {
      const { data: newMediaData } = await fetchMoreTalentMedia({
        variables: {
          talentIds: searchFetchMoreData.searchTalents.talents.map(item => item?.id),
          disabled: false
        }
      })
      const mediaList = newMediaData?.mediaList
      const newTalentList = searchFetchMoreData.searchTalents.talents.map(talent => {
        const medias = mediaList?.filter(media => talent.id === media.talentId)
        return {
          ...talent,
          isSelected: false,
          media: medias
        }
      })
      cb2()
      setData([...data, ...newTalentList])
      setTotal(searchFetchMoreData.searchTalents.metadata.total)
    } else cb()
  }

  return {
    data,
    setData,
    loading,
    fetchMore,
    total,
    isRouteEmpty
  }
}

export default useCombineWithMedia
