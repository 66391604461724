import { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

import { Colors } from '../../../../constants'
import { ShimmerContentBox } from '../../../../components'

const Wrapper = styled.div`
  min-height: 156px;
  width: 100%;
  margin-bottom: 24px;
`
const Title = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: ${Colors.black};
  font-weight: 700;
  margin-top: 24px;
`
const Description = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  color: ${Colors.black};
  font-weight: 300;
`
const TabWrapper = styled.div`
  margin-top: 12px;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: ${props => (props.isLoading ? '0px' : '1px')} solid #eeeeee;
`
const TabItem = styled.div`
  height: 48px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  word-break: break-all;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;

  border-bottom: ${props =>
    props.active ? '2px solid black' : '0px solid black'};
  cursor: pointer;
`
const TabTitle = styled.p`
  margin: 0;
  margin-inline: 8px;
  display: flex;
  flex: 1;
  place-items: center;
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: 300;
  color: ${props => (props?.isActive ? Colors.black : Colors.spanishGray)};
`

const SelectHeaderShimmer = styled(ShimmerContentBox)`
height: ${props => props.height};
width: ${props => props.width};
margin-top: ${props => props.marginTop};
margin-right: ${props => props.marginRight}
`

const SelectDetail = props => {
  const {
    data,
    childSelectList,
    loading,
    childSelectsLoading,
    handelChildSelectData,
    handelActiveTabIndex
  } = props
  // states
  const tabShimmerCount = useState(
    new Array(9).fill(0).map((_, index) => ({ id: index }))
  )

  const updateActiveSelect = (id, tabIndex) => {
    handelActiveTabIndex(tabIndex)
    const selectIndex = childSelectList.findIndex(item => item?.id === id)
    childSelectList.forEach((element, idx) => {
      if (idx === selectIndex) childSelectList[selectIndex].isSelected = true
      else childSelectList[idx].isSelected = false
    })
    handelChildSelectData([...childSelectList])
  }

  const SelectTab = () => {
    return (
      <TabWrapper isLoading={childSelectsLoading}>
        {(childSelectsLoading || loading) &&
          tabShimmerCount?.map((item, index) => (
            <SelectHeaderShimmer
              key={index}
              height={'100%'}
              width={'120px'}
              marginRight={'10px'}
            />
          ))}
        {childSelectList &&
          childSelectList?.length > 0 &&
          childSelectList.map((item, index) => (
            <Tooltip title={item?.name} key={index} placement='bottom' arrow>
              <TabItem onClick={() => updateActiveSelect(item?.id, index)} active={item?.isSelected}>
                <TabTitle isActive={item?.isSelected}>
                {item?.name?.substring(0, 12)}{item?.name?.length > 12 ? '...' : ''}
                </TabTitle>
              </TabItem>
            </Tooltip>
          ))}
      </TabWrapper>
    )
  }

  return (
    <Wrapper>
      {loading
        ? <SelectHeaderShimmer height="30px" width="20%" marginTop={'25px'} />
        : <Title>{data?.getSelect?.name}</Title> }
      {loading
        ? <SelectHeaderShimmer height="35px" width="80%" marginTop={'4px'} />
        : <Description>{data?.getSelect?.description}</Description>}
      <SelectTab />
    </Wrapper>
  )
}

SelectDetail.propTypes = {
  data: PropTypes.object,
  childSelectList: PropTypes.array,
  loading: PropTypes.bool,
  childSelectsLoading: PropTypes.bool,
  handelChildSelectData: PropTypes.func,
  handelActiveTabIndex: PropTypes.func
}

export default SelectDetail
