/* eslint-disable no-useless-escape */
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { TALENT_UPDATE } from '../../../services/API'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useTalentUpdateMutation () {
  // hooks
  const dispatch = useDispatch()

  // mutation
  const [TalentProfileInfoUpdate, { data, loading }] = useMutation(TALENT_UPDATE, { errorPolicy: 'all' })

  // extract username from profile url
  const extractUsername = url => {
    const rx = /^https?:\/\/(?:www\.)?(?:facebook|tiktok|instagram|twitter).com\/(?:#!\/)?([^?/#]*)(?:[?/#].*)?$/gm
    const arr = rx.exec(url)
    return arr ? arr[1] : url
  }

  const extractFacebookUsername = (url) => {
    const rx = /(?:(?:http|https):\/\/)?(?:www.|m.)?facebook.com\/(?!home.php)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\.-]+)/gm
    const arr = rx.exec(url)
    return arr ? arr[1] : url
  }
  const HandleTalentUpdate = async data => {
    const { id, email, firstname, lastname, phone, countryCode, socialLinks, countryCodeLocation, postalCode } = data

    const payload = {
      talentUpdateId: id,
      email,
      firstname,
      lastname,
      countryCodeLocation: JSON.parse(countryCodeLocation),
      postalCode,
      phone: phone ? `${countryCode.substring(1)}${phone}` : '',
      socialLinks: {
        instagram: socialLinks?.instagram?.length > 0 ? extractUsername(socialLinks?.instagram) : null,
        facebook: socialLinks?.facebook?.length > 0 ? extractFacebookUsername(socialLinks?.facebook) : null,
        tiktok: socialLinks?.tiktok?.length > 0 ? extractUsername(socialLinks?.tiktok)?.replace(/^(@)/, '') : null,
        twitter: socialLinks?.twitter?.length > 0 ? extractUsername(socialLinks?.twitter)?.replace(/^(@)/, '') : null,
        website: socialLinks.website || null
      }
    }
    try {
      await TalentProfileInfoUpdate({ variables: Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null)) })
      dispatch(
        showToast({ message: 'Profile updated successfully' })
      )
    } catch (error) {
      dispatch(
        showToast({
          message: error?.networkError?.result?.errors[0]?.message ?? error?.message,
          isError: true
        })
      )
    }
  }

  return [HandleTalentUpdate, { data, loading }]
}

export default useTalentUpdateMutation
