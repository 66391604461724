import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${props => props.sidebarWidth});
  height: 100%;
  overflow: hidden;
  margin: 0;
  ms-overflow-style: none;
  overflow-y: auto;
  transition: all .5s ease;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  margin: 0;
  height: 100vh;
`
