import Moment from 'moment'
import PropTypes from 'prop-types'

export const seperatePhoneAndCountryCode = phoneNumber => {
  if (phoneNumber) {
    const countryCodeLength = 1
    const newPhoneNumber = phoneNumber.replace(/\D/g, '')
    return {
      countryCode: `+${newPhoneNumber.substr(0, countryCodeLength)}`,
      phone: formatPhoneNumber(
        newPhoneNumber.substr(countryCodeLength, newPhoneNumber.length)
      )
    }
  }
}

export const formatPhoneNumber = value => {
  if (!value) {
    return value
  }
  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) {
    return phoneNumber
  }
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`
  }
  return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`
}

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null
}

RenderIf.propTypes = {
  children: PropTypes.node,
  isTrue: PropTypes.bool
}

RenderIf.defaultProps = {
  children: {},
  isTrue: false
}

export const convertFeetAndInchToHeight = value => {
  const feet = convertValue(value?.feet, 'INTEGER')
  const inch = convertValue(value?.inch, 'INTEGER')
  return convertValue(feet * 12 + inch, 'INTEGER')
}

export const convertValue = (value, to) => {
  switch (to) {
    case 'INTEGER':
      return value ? parseInt(value, 10) : 0
    case 'FLOAT':
      return value ? parseFloat(value) : 0
    default:
      return ''
  }
}

export const convertHeightToFeetAndInch = height => {
  if (!height) return null
  const feet = Math.floor(height / 12)
  const inch = height % 12
  return { feet, inch }
}

export const formatDate = (date, format) => {
  return date ? Moment(date).format(format) : '-'
}
export const formatPSTDateWithtime = (date) => {
  const convertedDate = date
    ? new Date(date).toLocaleTimeString(['en-US'], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/Los_Angeles'
    })
    : '-'
  return convertedDate
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const calculateTimeLeftFrom24Hour = (date) => {
  const targetTime = new Date(date).getTime()
  const currentTime = new Date().getTime()
  const milliseconds = currentTime - targetTime
  const seconds = 86400 - Math.floor(milliseconds / 1000)

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  if (hours < 0) {
    return 'expired'
  }

  return hours < 1 ? `${minutes}m` : `${hours}h`
}

export const isMoreThan24HoursApart = (dateString) => {
  // Parse the input date string to create a Date object
  const targetDate = new Date(dateString)

  // Get the current date and time
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const timeDifference = currentDate - targetDate

  // Convert the time difference to hours
  const hoursDifference = timeDifference / (1000 * 60 * 60)
  // Check if the difference is greater than 24 hours
  return hoursDifference > 24
}

export const isValidURL = (url) => {
  const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(url);
}

/**
 * @description This function is used to get the direction specs for still image
 * @param value - value of the direction
 * @param additionalNote - additional note for the direction
 */
export const stillImageDirection = (value, additionalNote) => {
  const directionArr = additionalNote?.trim()?.split('\n')

  if (value === '' || value === "Talent's choice" ) {
    return ["Talent's choice", '']
  } else if (value === 'Other') {
    return directionArr
  } else {
    return [value].concat(directionArr)
  }
}
