import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import reducers from './reducers'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export default () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const persistedReducer = persistReducer({
    key: 'root',
    storage,
    blacklist: [
      'DashBoardReducer',
      'TalentDetailReducer',
      'BulkUploadReducer'
    ]
  }, reducers)
  const store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)
  ))
  const persistor = persistStore(store)
  return { store, persistor }
}
