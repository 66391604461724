import { Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Tooltip } from '@mui/material'

import { Typography } from '../../../../components'

const TabContainer = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
`
const Tab = styled.div`
  height: 48px;
  display: flex;
  flex-shrink: 0;
  word-break: break-all;
  align-items: center;
  justify-content: center;
  padding-inline: 10px;

  border-bottom: ${props =>
    props.active ? '2px solid black' : '0px solid black'};
  cursor: pointer;
`

const ChildSelectListTab = props => {
  const {
    tabData,
    isActiveSelect,
    activeChildSelectTab,
    isAssociateWithProject,
    handleActiveChildSelectTab,
    togglAddNewChildeSelectSidebarModalVisible
  } = props

  return (
    <Box sx={{ borderBottom: '1px solid #eeeeee', width: '100%' }}>
      <TabContainer>
        {tabData.map((item, index) => (
          <Fragment key={index}>
            <Tooltip title={item?.name} placement='bottom' arrow>
              <Tab
                active={activeChildSelectTab?.id === item?.id}
                onClick={() => handleActiveChildSelectTab(item)}
                key={index}>
                <Typography
                  style={{
                    opacity: activeChildSelectTab?.id === item?.id ? 1 : 0.6
                  }}
                  fontSize={16}
                  fontWeight={400}>
                  {item?.name.substring(0, 12)}{item?.name.length > 12 ? '...' : ''}
                </Typography>
              </Tab>
            </Tooltip>
          </Fragment>
        ))}
        {(!isAssociateWithProject && isActiveSelect) && <Tab
        onClick={togglAddNewChildeSelectSidebarModalVisible}>
          <Typography
            fontSize={15}
            fontWeight={400}
            style={{ opacity: 0.3, textDecoration: 'underline' }}>
            + Add New Role
          </Typography>
        </Tab>}
      </TabContainer>
    </Box>
  )
}

ChildSelectListTab.propTypes = {
  tabData: PropTypes.array,
  activeChildSelectTab: PropTypes.object,
  handleActiveChildSelectTab: PropTypes.func,
  togglAddNewChildeSelectSidebarModalVisible: PropTypes.func,
  isAssociateWithProject: PropTypes.bool,
  isActiveSelect: PropTypes.bool
}

export default ChildSelectListTab
