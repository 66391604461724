import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames'
import Skeleton from '@mui/material/Skeleton'

import { UGCDetailContext } from '../../contexts/project/ugcDetail'
import { useContext, useMemo } from 'react'
import { viewProjectTabData } from '../../constants/UGCConstants'
import ArrowLeft from '../../assets/images/UGCProject/ArrowLeft.svg'
import ShareIcon from '../../assets/images/Projects/ShareIcon.svg'
import style from './UGCViewProject.module.scss'

interface UGCViewProjectHeaderProps {
  loading: boolean
  status: string
  advertiserName: string
  projectName: string
  logo?: string
  isArchived: boolean
  selectId: string
  selectName: string
  atleastOneTalentBooked: boolean
  headerColor: string
  handleProjectShare: (id?: string) => Promise<void>
}

interface HeaderLogoProps {
  logo: string | undefined
  loading?: boolean
  initial: string
  variant?: 'Large' | 'Medium'
}

interface IProjectInfo {
  loading: boolean
  brandName: string
  isArchived: boolean
  selectId: string
  selectName: string
  projectName: string
}

const SelectIcon = (): JSX.Element => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18L7 15L14 18V2C14 0.9 13.1 0 12 0ZM12 15L7 12.82L2 15V2H12V15Z" fill="black" />
    </svg>
  )
}

export const AdvertiserLogo = (props: HeaderLogoProps): JSX.Element => {
  const { logo, loading, initial, variant = 'Large' } = props

  if (loading === true) {
    return (
      <div className={style.Header__LogoSkeleton}><Skeleton variant="rectangular" /></div>
    )
  } else if (logo != null && logo !== undefined) {
    return (
      <div className={classNames(style.Header__LogoWrapper, style[`Header__LogoWrapper--${variant}`])}><img className={style.Header__Logo} src={logo} alt='logo' /></div>
    )
  } else {
    return (
      <div className={classNames(style.Header__LogoInitial, style[`Header__LogoInitial--${variant}`])}>
        <p className={classNames(style.Header__LogoInitialText, style[`Header__LogoInitialText--${variant}`])}>{initial}</p>
      </div>
    )
  }
}

const ProjectInfo = (props: IProjectInfo): JSX.Element => {
  const { loading, brandName, isArchived, selectId, selectName, projectName } = props

  if (loading) {
    return (
      <>
        <div className={classNames(style.Header__AdvertiserTextSkeleton, style['Header__AdvertiserTextSkeleton--MT10'])}><Skeleton variant="rectangular" /></div>
        <div className={style.Header__AdvertiserTextSkeleton}><Skeleton variant="rectangular" /> </div>
      </>
    )
  }
  return (
    <>
      <p className={style.Header__DetailAdvertiserText}>{brandName}</p>
      <div className={style.Header__DetailProjectTextWrapper}>
        <p className={style.Header__DetailProjectText}>{projectName}</p>
        <Link to={`/selects/${isArchived ? 'archive' : 'active'}/${selectId}/sub-select`} className={style.Header__SelectText}><SelectIcon /> {selectName}</Link>
      </div>
    </>
  )
}

export const UGCViewProjectHeader = (props: UGCViewProjectHeaderProps): JSX.Element => {
  // init
  const { loading, status, advertiserName, projectName, logo, isArchived, selectId, selectName, atleastOneTalentBooked, headerColor = '99ECE2', handleProjectShare } = props
  const { id, activeTab, innerTab } = useParams()

  const [{ overview }] = useContext(UGCDetailContext)
  const { brandId, owner } = overview

  const brandDetails = useMemo(() => {
    return owner?.brands?.find(brand => brand?.id === brandId)
  }, [owner?.brands, brandId])

  const brandLogo = brandId !== null && brandDetails?.logo?.uris?.logo !== undefined ? brandDetails?.logo?.uris?.logo : logo
  const brandName = brandId !== null && brandDetails?.name !== undefined ? brandDetails?.name : advertiserName
  const brandInitials = brandName?.charAt(0).toUpperCase() ?? advertiserName.charAt(0).toUpperCase()

  return (
    <div className={style.Header} style={{ backgroundColor: `#${headerColor}` }}>
      <Link to={'/projects'} className={style.Header__BackContainer}><img alt='back' src={ArrowLeft} /><p className={style.Header__BackText}>Back</p></Link>
      <div className={style.Header__DetailContainer}>

        <div className={style.Header__AdvertiserDetails}>
          {/* Logo */}
          <AdvertiserLogo logo={brandLogo} loading={loading} initial={brandInitials} />

          {/* Project name and description */}
          <div className={style.Header__ProjectInfoContainer}>
            <ProjectInfo loading={loading} brandName={brandName} isArchived={isArchived} selectId={selectId} selectName={selectName} projectName={projectName} />
          </div>
        </div>

        {!loading && <p className={style.Header__DetailStatusText}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</p>}
      </div>

      {/* Removing the assets tab when there is no talent booked */}
      <div className={style.Header__Tab}>
        {viewProjectTabData.filter((item) => atleastOneTalentBooked ? -1 : item.title !== 'Assets').map((item) => (
          <Link to={item?.tabRoute === 'assets' && innerTab !== undefined ? '#' : `/projects/ugc/view/${id ?? ''}/${item.tabRoute}`} key={item.id} className={classNames(style.Header__TabItem, activeTab === item.tabRoute ? style['Header__TabItem--Active'] : '')}>
            <p className={classNames(style.Header__TabItemText, activeTab === item.tabRoute ? style['Header__TabItemText--Active'] : '')}>{item.title}</p>
          </Link>
        ))}
        <button className={classNames(loading ? style['Header__ShareButton--Hidden'] : style.Header__ShareButton)} onClick={() => { void handleProjectShare(id) }}><img src={ShareIcon} />Share</button>
      </div>
    </div>
  )
}
