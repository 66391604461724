import { gql } from '@apollo/client'

class MediaAPI {
  createMediaMutation () {
    return gql`
      mutation createOrganizationMedia($filename: String!, $mediaCreateId: ID, $type: Type, $orgId: ID, $category: MediaCategory) {
        mediaCreate(filename: $filename, id: $mediaCreateId, type: $type, orgId: $orgId, category: $category) {
          id
          type
          file
          createdAt
        }
      }
    `
  }

  mediaUploadMutation () {
    return gql`
      mutation mediaUpload($talentId: ID, $orgId: ID, $projectId: ID, $category: MediaCategory!, $type: Type!, $extension: String!) {
        mediaUpload(talentId: $talentId, orgId: $orgId, projectId: $projectId, category: $category, type: $type, extension: $extension) {
          id
          filename
          url
        }
      }
    `
  }

  deleteMedia () {
    return gql`
    mutation MediaDelete($mediaDeleteId: ID!) {
      mediaDelete(id: $mediaDeleteId) {
        status
        message
      }
    }
  `
  }

  createMultipartUpload () {
    return gql`
    mutation CreateMultipartUpload($category: MediaCategory!, $type: Type!, $extension: String!, $size: Int!, $talentId: ID, $orgId: ID, $projectId: ID) {
      createMultipartUpload(category: $category, type: $type, extension: $extension, size: $size, talentId: $talentId, orgId: $orgId, projectId: $projectId) {
        id
        filename
        uploadId
        key
        chunkSize
        urls {
          url
          partNumber
        }
      }
    }`
  }

  CompleteMultipartUpload () {
    return gql`
    mutation CompleteMultipartUpload($uploadId: ID!, $key: String!) {
      completeMultipartUpload(uploadId: $uploadId, key: $key) {
        status
        message
      }
    }`
  }

  AbortMultipartUpload () {
    return gql`
    mutation AbortMultipartUpload($uploadId: ID!, $key: String!) {
      abortMultipartUpload(uploadId: $uploadId, key: $key) {
        status
        message
      }
    }`
  }
}

export default new MediaAPI()
