import types from '../types/types'

const INITIAL_STATE = {
  menu: [],
  isOpen: true,
  width: '250px',
  isSubMenuOpen: false,
  isSelectSubMenuOpen: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET__MENU_DETAILS:
      return { ...state, menu: payload }
    case types.SET_SIDEBAR_STATUS:
      return {
        isOpen: payload.isOpen,
        width: payload.width,
        isSubMenuOpen: payload.isSubMenuOpen,
        isSelectSubMenuOpen: payload.isSelectSubMenuOpen
      }
    case types.SET_SELECT_SUB_MENU_TOGGLE:
      return {
        ...state,
        isSelectSubMenuOpen: payload
      }
    default:
      return state
  }
}
