
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`
export const ShimmerContentBox = styled.div`
  height: 65px;
  background: #777;
  width: 100%;
  border-radius:2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`
