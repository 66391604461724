import { Fragment, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress, Grid, Stack, Tooltip, Typography } from '@mui/material'

import Sidebars from '../Sidebars'
import { Images } from '../../../../assets'
import { Colors } from '../../../../constants'
import ChildSelectListTab from '../ChildSelectListTab/ChildSelectListTab'

import { Button, HeaderV2, ShimmerContentBox, TalentCard } from '../../../../components'
import SelectGreeting from '../SelectGreeting'
import NoTalentFoundPlaceholder from '../NoTalnetFoundPlaceholder'

import { useOutsideHandler } from '../../../../hooks'
import LocalStorage from '../../../../utils/LocalStorage'
import useGetSelectById from '../../Hooks/useGetSelectById'

import useDeleteTalentMutation from '../../Hooks/useDeleteTalentMutation'
import useChildSelectListQuery from '../../Hooks/useChildSelectListQuery'
import useSelectUpdateMutation from '../../Hooks/useSelectUpdateMutation'

import useCombineWithMediaAndLikes from '../../Hooks/useCombineWithMediaAndLikes'
import useClearVoteSelectionMutation from '../../Hooks/useClearVoteSelectionMutation'
import { RenderIf } from '../../../../utils/Helpers'

import UGCIconBlack from '../../../../assets/images/UGCProject/UGCProjectIconBlack.svg'
import CastingIconBlack from '../../../../assets/images/Sidebar/ProjectBlackIcon.svg'
import { useLazyQuery } from '@apollo/client'
import { listTalentSubmissions } from '../../../../apis/project'
import { UGCTalentCard as UGCInterestCard } from '../../../../components/TalentCardV2'
import images from '../../../../assets/images'

const HeaderShimmer = styled(ShimmerContentBox)`
  height: 130px;
  margin-right: 200px;
  margin-bottom: 20px;
`

const SelectNameEllipsesWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-width: calc(60vw - 300px);
  -webkit-box-orient: vertical;
`

const TalentListContainer = styled.div`
  padding-right: 30px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  list-style: none;
  @media (min-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
`

const UGCTalentListContainer = styled.div`
  padding-right: 30px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  list-style: none;
  @media (min-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
`

const rotate = keyframes`
 0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`

const ShimmerBox = styled.div`
  flex: 0 1 70%;
  height: 320px;
  cursor: pointer;

  @media (min-width: 900px) {
    flex: 0 1 calc(33.33% - 10px);
  }

  @media (min-width: 1024px) {
    flex: 0 1 calc(25% - 10px);
  }

  @media (min-width: 1400px) {
    flex: 0 1 calc(20% - 10px);
  }

  @media (min-width: 1800px) {
    flex: 0 1 calc(16.66% - 10px);
  }

  background: #777;
  border-radius: 2px;
  animation: ${rotate} 2s linear infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`

const HumBurgerButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 24px;
  height: 24px;
  margin-top: 8px;
  margin-left: 18px;
  cursor: pointer;
  &:hover {
    background: ${Colors.grayOpacity};
  }
`

const MenuPopupBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  background: white;
  z-index: 1000;
  width: 178px;
  right: 60px;
  top: 170px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
`

const PopupListBox = styled.div`
  cursor: pointer;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  &:hover {
    background: ${Colors.symbolGray};
  }
`
const IconWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 64px;
  width: 64px;
  background: white;
  display: grid;
  place-items: center;
  margin-right: 16px;
  overflow: hidden;
`
const Icon = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`

const UGCTalentCard = ({ talentSubmission, data, isLandscape, deleteTalentFromSelect }) => {
  const [masterData, setMasterData] = useState([])

  useEffect(() => {
    if(data?.length > 0){
      const newData = data?.map((item) => {
        const talent = talentSubmission?.find((e) => e?.talentId === item?.id)
        return {
          ...item,
          talentAudition: talent?.media?.uris?.poster
        }
      })
      setMasterData(newData)
    }
  }, [data])

  return (
    <>
      {masterData.map((item) =>
        <UGCInterestCard key={item.id} id={item.id} firstname={item.firstname} lastname={item.lastname} showTag={false} status={item?.status ?? ''} isLandscape={isLandscape}
          profilePhoto={item?.mediaProfile?.uris?.profilePic ?? null} handleActionOnCard={deleteTalentFromSelect} isSelect={true} voteData={item?.voteData} hasRedirectLink={true}
          videoThumbnail={item?.talentAudition ?? (item?.videoProfile?.uris?.poster ?? item?.mediaProfile?.uris?.profilePic ?? images.tlyntPlaceholder)}
          hasVideos={item?.talentAudition !== undefined || item?.videoProfile?.uris?.stream !== undefined} />
      )}
    </>)
}

UGCTalentCard.propTypes = {
  talentSubmission: PropTypes.array,
  data: PropTypes.array,
  isLandscape: PropTypes.bool,
  deleteTalentFromSelect: PropTypes.func
}

const SelectInfo = props => {
  // props
  const { handleRefetchSelectList, parentActiveTabData, resetActiveParentSelectData, isActiveSelect } = props
  // hooks
  const popupRef = useRef(null)

  const userInfo = LocalStorage.getItem('userInfo')

  const navigate = useNavigate()
  const { id, roleId } = useParams()
  useOutsideHandler(popupRef, () => setShowPopup(false))

  // api calls
  const { updateSelect, loading: updateSelectLoading } = useSelectUpdateMutation()

  // constants
  const popupData = [
    {
      id: 1,
      title: isActiveSelect ? 'Archive' : 'Unarchived',
      functionCall: async () => {
        await updateSelect({
          id,
          archived: isActiveSelect
        }, () => setShowPopup(false))
        handleRefetchSelectList()
        resetActiveParentSelectData()
      }
    },
    {
      id: 2,
      title: 'Duplicate',
      functionCall: () => {
        setShowPopup(false)
        setIsDuplicateSelectSidebarModalVisible(!isDuplicateSelectSidebarModalVisible)
      }
    },
    {
      id: 3,
      title: 'Clear Votes',
      functionCall: async () => {
        try {
          await ClearSelectionVote({
            variables: {
              selectId: activeChildSelectTab?.id
            }
          })
        } catch (error) {

        }
      }
    }
  ]
  // states
  const [isLoaded, setIsLoaded] = useState(false)
  const [activeChildSelectTab, setActiveChildSelectTab] = useState({})
  const shimmerCount = useState(
    new Array(20).fill(0).map((_, index) => ({ id: index }))
  )[0]
  const [showPopup, setShowPopup] = useState(false)

  // ----------------------  Handling sidebar visibility starts  ------------------------------

  const [isEditSelectSidebarModalVisible, setIsEditSelectSidebarModalVisible] = useState(false)
  const [
    isShareSelectSidebarModalVisible,
    setIsShareSelectSidebarModalVisible
  ] = useState(false)
  const [
    isDeleteSelectSidebarModalVisible,
    setIsDeleteSelectSidebarModalVisible
  ] = useState(false)

  const [
    isAddNewChildSelectSidebarModalVisible,
    setIsAddNewChildSelectSidebarModalVisible
  ] = useState(false)

  const [
    isDuplicateSelectSidebarModalVisible,
    setIsDuplicateSelectSidebarModalVisible
  ] = useState(false)

  // ----------------------  Handling sidebar visibility ends  ------------------------------

  // api call - for fetching parent select information i.e name and description
  const { data: parentSelectData, loading: parentSelectDataLoading, refetch: refetchParentSelect} = useGetSelectById({ id: parentActiveTabData?.id })

  // api call - for getting the child selects associated with parent select
  const { data: childSelectsData, loading: childSelectsListLoading, refetch } = useChildSelectListQuery({ parentSelectId: parentSelectData?.getSelect?.id, archived: !isActiveSelect })

  const selectData = parentSelectData?.getSelect ?? null
  // api call - for update child select which containing talents
  const [UpdateSelect] = useDeleteTalentMutation()

  // api call - for clear votes
  const [ClearSelectionVote, { loading: clearVoteLoading }] = useClearVoteSelectionMutation({ activeSelectId: activeChildSelectTab?.id })

  // api call - for getting list of talents inside child select
  // passing null because hook invoke at the time of component render
  const { masterData, loading, handleMasterData } = useCombineWithMediaAndLikes(
    {
      talentIds: activeChildSelectTab?.talents?.join() ?? null,
      activeChildSelectTab,
      parentActiveTabData
    }
  )

  useEffect(() => {
    if (!id) {
      setActiveChildSelectTab({})
    }
  }, [id])

  // when child select list load, loading first active select
  useEffect(() => {
    if (!childSelectsListLoading && childSelectsData?.listSelects?.length > 0 && parentActiveTabData?.id) {
      const firstTabData = childSelectsData?.listSelects[0]
      if (activeChildSelectTab.parentId !== firstTabData.parentId) {
        setActiveChildSelectTab(firstTabData)
      }

      window.history.replaceState(`/selects/${isActiveSelect ? 'active' : 'archived'}/${id}`, '', `/selects/${isActiveSelect ? 'active' : 'archived'}/${id}/${firstTabData.id}`)
    } else if (childSelectsData?.listSelects?.length === 0) {
      setActiveChildSelectTab({})
    }
  }, [childSelectsListLoading, parentActiveTabData])

  const deleteTalentFromSelect = async (data, cb) => {
    await UpdateSelect({
      variables: { id: activeChildSelectTab?.id, talents: masterData?.filter(tlnt => tlnt.id !== data.id).map(tlnt => tlnt.id)
      }
    })
    handleMasterData(masterData.filter(talent => talent.id !== data.id))
    cb()
    refetch()
  }

  if (!selectData?.project) {
    popupData.push({
      id: 0,
      title: 'Delete',
      functionCall: () => {
        setShowPopup(false)
        setIsDeleteSelectSidebarModalVisible(!isDeleteSelectSidebarModalVisible)
      }
    })
  }
  useEffect(() => {
    if (roleId) {
      const activeTabData = childSelectsData?.listSelects.find(e => e.id === roleId)
      activeTabData && setActiveChildSelectTab(activeTabData)
    }
  }, [childSelectsListLoading, roleId])

  useEffect(() => {
    if (parentSelectData?.getSelect?.parentId) {
      navigate(`/selects/archived/${parentSelectData?.getSelect?.parentId}/${parentSelectData?.getSelect?.id}`)
    }
  }, [parentSelectData, parentSelectDataLoading])

  // pre load all image and cache them for fast loading
  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const mediaData = masterData.map((e) => e?.media)
    if (mediaData) {
      const allMedia = [].concat(...mediaData)
      const chunkSize = 200
      if (!isLoaded && allMedia.length) {
        for (let i = 0; i < allMedia.length; i += chunkSize) {
          const chunk = allMedia.slice(i, i + chunkSize)
          cacheImages(chunk?.map((e) => e?.uris?.card))
            .catch(e => console.error(e))
          if (i >= (allMedia.length - 1 - chunkSize)) {
            setIsLoaded(true)
          }
        }
      }
    }
  }, [masterData])

  const isUGCProject = selectData?.project?.type === 'ugc'
  const projectIcon = isUGCProject ? UGCIconBlack : CastingIconBlack

  const brandId = selectData?.project?.brandId
  const brandLogo = brandId ? selectData?.project?.owner?.brands?.find(brand => brand.id === brandId)?.logo?.uris?.logo : selectData?.owner?.logo?.uris?.logo ?? null
  const brandName = brandId ? selectData?.project?.owner?.brands?.find(brand => brand.id === brandId)?.name : selectData?.owner?.name

  const [getTalentSubmission] = useLazyQuery(listTalentSubmissions, { fetchPolicy: 'no-cache' })
  const [talentSubmission, setTalentSubmission] = useState([])
  const [isLandscape, setIsLandscape] = useState(false)

  const getTalentSubmissionData = (offset, auditionId) => {
    getTalentSubmission({ variables: { deliverableId: auditionId, offset, limit: 40 } }).then(({ data }) => {
      if (data?.listTalentSubmission !== undefined && data?.listTalentSubmission.length > 0) {
        setTalentSubmission([...data?.listTalentSubmission])
        data?.listTalentSubmission > 40 && getTalentSubmissionData(talentSubmission.length, auditionId)
      }
    })
  }

  useEffect(() => {
    if (isUGCProject && selectData?.project) {
      const hasAudition = selectData?.project.deliverables.find((item) => item.type === 'audition') !== undefined
      const auditionId = selectData?.project.deliverables.find((item) => item.type === 'audition')?.id
      
      const isLandscapeStatus = hasAudition ? selectData?.project.deliverables.find((item) => item.type === 'main')?.specs?.orientation === 'landscape' : false
      setIsLandscape(isLandscapeStatus)
      setTalentSubmission([])
      auditionId !== undefined && getTalentSubmissionData(talentSubmission.length, auditionId)
    }
  }, [selectData?.project])

  return (
    <Fragment>
      {showPopup && (
        <MenuPopupBox ref={popupRef}>
          {/* sorting menu based on their id */}
          {popupData
            .filter((item) => parentSelectData?.getSelect?.project ? item.id !== 1 : -1)
            .filter((item) => parentSelectData?.getSelect?.project ? item.id !== 2 : -1)
            .sort((a, b) => a.id - b.id).map((item, index) => (
            <PopupListBox key={item.id} onClick={item.functionCall}>
              <Typography
                fontSize={14}
                fontWeight={400}
                pl={2}>
                {item.title}
              </Typography>
              {index === 2 && clearVoteLoading && <CircularProgress size={15} />}
              {item?.id === 1 && updateSelectLoading && <CircularProgress size={15} />}
            </PopupListBox>
            ))}
        </MenuPopupBox>
      )}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {parentSelectDataLoading || childSelectsListLoading
          ? <div style={{ marginRight: 40 }}>
              <HeaderShimmer />
            </div>
          : parentActiveTabData?.id && selectData
            ? <HeaderV2
            style={{
              position: 'sticky',
              paddingTop: 0,
              paddingLeft: 0
            }}
            title={
              <Grid container direction='row' mb={1.5}>
                <Grid item md='auto'>
                  <IconWrapper>
                    {brandLogo
                      ? <Icon src={brandLogo} />
                      : <Typography
                          fontSize={46} lineHeight={1}
                          fontWeight={'bold'}>
                          {brandName?.charAt(0)?.toUpperCase() ??
                          selectData?.name?.charAt(0).toUpperCase() ?? '-'}
                        </Typography>}
                  </IconWrapper>
                </Grid>
                <Grid item md='auto' alignItems='center' sx={{ width: '-webkit-fill-available' }}>
                  <Grid item pt={1}>
                    {selectData?.project?.name
                      ? <Stack direction='row' gap={1} alignItems='center' style={{ cursor: 'pointer' }} onClick={() => navigate(isUGCProject ? `/projects/ugc/view/${selectData?.project.id}/overview` : `/projects/${selectData?.project.id}`)}>
                          <img height={16} src={projectIcon} />
                          <SelectNameEllipsesWrapper>
                            <Typography fontSize={10} whiteSpace='nowrap' width='auto' textOverflow='ellipsis' overflow='hidden'>
                              {selectData.project?.name}
                            </Typography>
                          </SelectNameEllipsesWrapper>
                        </Stack>
                      : <Typography fontSize={10} whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>{brandName}</Typography>}
                  </Grid>
                  <Grid item>
                    <Tooltip title={selectData?.name ?? ''} placement='bottom' arrow>
                      <SelectNameEllipsesWrapper>
                        <Typography fontSize={20} fontWeight={700} whiteSpace='nowrap'
                          textOverflow='ellipsis' overflow='hidden' width='auto'>
                          {selectData?.name}
                        </Typography>
                      </SelectNameEllipsesWrapper>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            }
            titleRight={
              <Box display='flex'>
                {/* ------------- SHARE ----------- */}
               <RenderIf isTrue={isActiveSelect}>
                <Button
                  onClick={() =>
                    setIsShareSelectSidebarModalVisible(
                      !isShareSelectSidebarModalVisible
                    )}
                    style={{ width: 100 }}
                    outline>
                  <Typography fontSize={15} fontWeight={700}>
                    SHARE
                  </Typography>
                </Button>
               </RenderIf>
                {/* ------------- EDIT ------------ */}
                <RenderIf isTrue={isActiveSelect}>
                 {!parentSelectData?.getSelect?.project && <Button
                  onClick={() =>
                    setIsEditSelectSidebarModalVisible(!isEditSelectSidebarModalVisible)
                   }
                    style={{ width: 100, marginLeft: 15 }}
                    outline>
                  <Typography fontSize={15} fontWeight={700}>
                    EDIT
                  </Typography>
                 </Button>}
                </RenderIf>
                {/* ------------- MORE --------------- */}
                <RenderIf isTrue={isActiveSelect}>
                 <HumBurgerButtonWrapper onClick={() => setShowPopup(!showPopup) } >
                   <img
                     style={{
                       height: 16
                     }}
                     src={Images.humburgerMenu}/>
                 </HumBurgerButtonWrapper>
                </RenderIf>
                 {/* -------- DELETE ADND RESTORE ------- */}
                <RenderIf isTrue={!isActiveSelect && !parentSelectData?.getSelect?.project}>
                  <RenderIf isTrue={userInfo?.type === 'advertiser' && !isActiveSelect && !parentSelectData?.getSelect?.project}>
                   <Button
                      outline
                      backgroundColor='white'
                      loading={updateSelectLoading}
                      onClick={() => setIsDeleteSelectSidebarModalVisible(!isDeleteSelectSidebarModalVisible)}
                      style={{ minWidth: 100 }}>
                      <Typography fontSize={15} fontWeight={700}>
                        DELETE
                      </Typography>
                   </Button>
                  </RenderIf>
                 <RenderIf isTrue={userInfo?.type !== 'advertiser'}>
                  <Button
                     outline
                     backgroundColor='white'
                     loading={updateSelectLoading}
                     onClick={() => setIsDeleteSelectSidebarModalVisible(!isDeleteSelectSidebarModalVisible)}
                     style={{ minWidth: 100 }}>
                     <Typography fontSize={15} fontWeight={700}>
                       DELETE
                     </Typography>
                  </Button>
                <Button
                  loading={updateSelectLoading}
                  onClick={ async () => {
                    await updateSelect({
                      id,
                      archived: false
                    }, () => null)
                    handleRefetchSelectList()
                    resetActiveParentSelectData()
                  }}
                  style={{ minWidth: 100 }}>
                  <Typography fontSize={15} color='white' fontWeight={700}>
                    RESTORE
                  </Typography>
                </Button>
                </RenderIf>
               </RenderIf>
              </Box>
            }
            description={
              <Typography fontSize={14} letterSpacing={0.15} mt={1} textAlign='justify'>
                {selectData?.description}
              </Typography>
            }
            bottom={
              <ChildSelectListTab
                isActiveSelect={isActiveSelect}
                // prop for rendering list of child selects
                tabData={childSelectsData?.listSelects ?? []}
                // loading data inside state of Select info when user change any of the sub select
                activeChildSelectTab={activeChildSelectTab}
                handleActiveChildSelectTab={data => {
                  navigate(`/selects/${isActiveSelect ? 'active' : 'archived'}/${id}/${data.id}`)
                  setActiveChildSelectTab(data)
                }}
                togglAddNewChildeSelectSidebarModalVisible={() =>
                  setIsAddNewChildSelectSidebarModalVisible(
                    !isAddNewChildSelectSidebarModalVisible
                  )
                }
                isAssociateWithProject={!!selectData?.project}
              />
            }
          />
            : null}
        <div>
          <RenderIf isTrue={isUGCProject}>
            <UGCTalentListContainer>
              <RenderIf isTrue={loading || childSelectsListLoading || parentSelectDataLoading}>
                {shimmerCount.map((_, index) => <ShimmerBox key={index} />)}
              </RenderIf>
              <RenderIf isTrue={!loading && !parentSelectDataLoading && !childSelectsListLoading && masterData?.length > 0}>
                <UGCTalentCard talentSubmission={talentSubmission} data={masterData} isLandscape={isLandscape} deleteTalentFromSelect={deleteTalentFromSelect} />
              </RenderIf>
            </UGCTalentListContainer>
          </RenderIf>
          <RenderIf isTrue={!isUGCProject}>
            <TalentListContainer>
              {loading || childSelectsListLoading || parentSelectDataLoading ? shimmerCount.map((_, index) => <ShimmerBox key={index} />) : null }
              <RenderIf isTrue={!loading && !parentSelectDataLoading && !childSelectsListLoading && masterData?.length > 0}>
                {masterData?.map((item) => (
                  <TalentCard key={item.id} data={item} isSelect={true} handleActionOnCard={deleteTalentFromSelect} />
                ))}
              </RenderIf>
            </TalentListContainer>
          </RenderIf>
        </div>
        { parentActiveTabData?.id && !loading && !parentSelectDataLoading && !childSelectsListLoading && !(id && !selectData) && (activeChildSelectTab?.talents?.length === 0 || masterData.length === 0)
          ? <Box display='grid' placeitems='center'><NoTalentFoundPlaceholder /></Box>
          : !parentActiveTabData?.id && <SelectGreeting />
          }
      </Box>
      {(id !== 'select' && !selectData) && <Stack height='95%' alignItems='center' justifyContent='center'>
          <Typography fontSize={34} fontWeight='bold' letterSpacing='0.25px'>Add your first select.</Typography>
        </Stack>}
      <Sidebars
        // parent select active tab data
        activeTabData={parentActiveTabData}
        // child select list data
        childSelectList={childSelectsData?.listSelects ?? []}
        // refetching the parent select list when user perform edit, delete add operation.
        handleRefetchSelectList={async () => {
          await refetch()
          refetchParentSelect()
          handleRefetchSelectList()
        }}
        resetActiveParentSelectData={resetActiveParentSelectData}
        // for edit select props

        handleRefetchChildSelectList={ async () => {
          await refetch()
          refetchParentSelect()
          handleRefetchSelectList()
        }}
        isEditSelectSidebarModalVisible={isEditSelectSidebarModalVisible}
        toggleEditSelectSidebarModalVisible={async () => {
          setIsEditSelectSidebarModalVisible(!isEditSelectSidebarModalVisible)
        }}
        // for share share select
        isShareSelectSidebarModalVisible={isShareSelectSidebarModalVisible}
        toggleShareSelectSidebarModalVisible={() => {
          setIsShareSelectSidebarModalVisible(!isShareSelectSidebarModalVisible)
        }}
        // for delete select sidebar
        isDeleteSelectSidebarModalVisible={isDeleteSelectSidebarModalVisible}
        toggleDeleteSelectSidebarModalVisible={() =>
          setIsDeleteSelectSidebarModalVisible(
            !isDeleteSelectSidebarModalVisible
          )
        }
        // add new child select sidebar
        isAddNewChildSelectSidebarModalVisible={isAddNewChildSelectSidebarModalVisible}
        toggleAddNewChildSelectSidebarModalVisible={() => {
          setIsAddNewChildSelectSidebarModalVisible(
            !isAddNewChildSelectSidebarModalVisible
          )
        }}
        // duplicate child select
        isDuplicateSelectSidebarModalVisible={isDuplicateSelectSidebarModalVisible}
        toggleDuplicateSelectSidebarModalVisible={() => {
          setIsDuplicateSelectSidebarModalVisible(
            !isDuplicateSelectSidebarModalVisible
          )
        }}
        refetchChildSelectList={refetch}
      />
    </Fragment>
  )
}

SelectInfo.propTypes = {
  handleRefetchSelectList: PropTypes.func,
  parentActiveTabData: PropTypes.object,
  resetActiveParentSelectData: PropTypes.func,
  isActiveSelect: PropTypes.bool
}

export default SelectInfo
