import { useEffect, useRef, useState } from 'react'

import style from './CompanyDetail.module.scss'

import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { LinearProgress } from '@mui/material'

import { Images } from '../../../../../assets'
import { RenderIf } from '../../../../../utils/Helpers'
import { Button, Typography } from '../../../../../components'
import { uploadMedia } from '../../../../../services/RestAPI'
import { FormikControl } from '../../../../../components/formik/formikControl'
import { CompanyDetailValidationSchema } from '../../../../../services/ValidationServices'

import useCreateMediaMutation from '../../../../../hooks/Media/useCreateMediaMutation'
import { ADVERTISER_LOGO_ALLOWED_IMAGE_EXTENSION, ADVERTISER_LOGO_MAX_IMAGE_SIZE, API, Colors } from '../../../../../constants'
import AdvertiserAPI from '../../../../../services/Advertiser/AdvertiserAPI'
import { showToast } from '../../../../../redux/actions/DashBoardActions'
import { SpinnerXtraSmall } from '../../../../../components/Progress'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const FieldContainer = styled.div`
  height: 75px;
  width: 100%;
`
const ButtonRow = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  align-items: center;
`
const LogoWrapper = styled.div`
  display: flex;
`
const LogoImg = styled.div`
  min-width: 96px;
  height: 96px;
  background: ${Colors.brightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 20px 0;
  border-radius: 12px;
  border: 1px solid ${props => props.isError ? 'red' : 'transperent'};

`
const ImageWithProgressWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 12px;
  background: ${Colors.brightGray};
  position: relative;
  margin: 0 20px 20px 0;
  background-image: ${props => 'url(' + props.src + ')'};
  background-size: 96px;
  background-position: center;
  background-repeat: no-repeat;
`
const Loader = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 12px;
  z-index: 100;
`
const ButtonTray = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-inline: 40px;
  overflow-y: scroll;
`

const BrowseTextStyledDiv = styled.div`
  position: static;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const BrowseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: static;
  justify-content: center;
`

const CompanyDetail = ({ handleToggle, handleActiveStep, setAdvertiserId, reFetchData, advertiserData }) => {
  // ref
  const formikRef = useRef(null)
  const ref = useRef(null)
  const dispatch = useDispatch()

  // api calls
  const [createMedia, { loading: uploadMediaLoading }] = useCreateMediaMutation()
  const [AddAdvertiserMutation, { loading: addAdvertiserLoading }] = useMutation(AdvertiserAPI.addAdvertiser(), { notifyOnNetworkStatusChange: true })
  const [UpdateAdvertiserMutation, { loading: updateAdvertiserLoading }] = useMutation(AdvertiserAPI.updateAdvertiser(), { notifyOnNetworkStatusChange: true })

  // state
  const [progressValue, setProgressValue] = useState(0)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [isImageFailed, setIsImageFailed] = useState(false)
  const [imageUploadErrors, setImageUploadErrors] = useState([])
  const [uploadingStatus, setUploadingStatus] = useState('pending')

  const [logoData, setLogoData] = useState({})
  const [advId, setAdvId] = useState(null)
  const [logoMediaData, setLogoMediaData] = useState(null)

  // initial values
  const initialValues = {
    companyName: advertiserData?.name ?? '',
    email: advertiserData?.contact ?? '',
    logo: advertiserData?.logo?.id ?? ''
  }

  const handleNext = () => {
    handleActiveStep(1)
    reFetchData()
  }

  const handleFormSubmit = async data => {
    try {
      const { companyName, email, logo } = data

      const modifiedData = {
        name: companyName.trim(),
        contact: email
      }
      if (logo) {
        modifiedData.logo = logo
      }
      if (advertiserData?.id) {
        modifiedData.id = advertiserData.id
      }
      if (advertiserData) {
        const updateAdvertiserData = await UpdateAdvertiserMutation({ variables: modifiedData })
        if (Object.values(logoData).length > 0) {
          setUploadingStatus('uploading')
          setProgressValue(0)
          setUploadLoading(true)
          setAdvId(updateAdvertiserData?.data?.organizationUpdate?.id)
          uploadMediaData(logoData, updateAdvertiserData?.data?.organizationUpdate?.id)
        } else {
          handleNext()
          dispatch(showToast({ message: 'Advertiser updated successfully' }))
        }
      } else {
        const addAdvertiserData = await AddAdvertiserMutation({ variables: modifiedData })
        await setAdvertiserId(addAdvertiserData?.data?.organizationCreate?.id)
        if (Object.values(logoData).length > 0) {
          setUploadingStatus('uploading')
          setProgressValue(0)
          setUploadLoading(true)
          setAdvId(addAdvertiserData?.data?.organizationCreate?.id)
          uploadMediaData(logoData, addAdvertiserData?.data?.organizationCreate?.id)
        } else {
          handleNext()
          dispatch(showToast({ message: 'Advertiser created successfully' }))
        }
      }
    } catch (error) {
      dispatch(showToast({ message: error?.message ?? 'Try again later', isError: true }))
    }
  }

  useEffect(() => {
    if (advertiserData?.id) {
      setAdvertiserId(advertiserData.id)
    }
  }, [advertiserData])

  useEffect(() => {
    if (uploadingStatus === 'success' && uploadLoading === false) {
      handleCreateLogo()
    }
  }, [uploadingStatus, uploadLoading])

  const handleCreateLogo = async () => {
    await createMedia({
      filename: logoMediaData?.mediaFilename,
      mediaCreateId: logoMediaData?.mediaId,
      type: 'photo',
      orgId: advId,
      category: 'organization'
    }, successMediaCreate)
    dispatch(showToast({ message: advertiserData ? 'Advertiser updated successfully' : 'Advertiser created successfully' }))
  }

  const successMediaCreate = async (id) => {
    formikRef.current.setFieldValue('logo', id)
    await UpdateAdvertiserMutation({
      variables: { id: advId, logo: id },
      onCompleted: () => {
        handleNext()
      }
    })
  }
  const successHandler = async (mediaData) => {
    if (mediaData && mediaData.item) {
      const { filename: mediaFilename, id: mediaId } = mediaData.item
      setLogoMediaData({ mediaFilename, mediaId })
      setUploadLoading(false)
      setUploadingStatus('success')
    }
  }
  const errorHandler = () => {
    setUploadingStatus('fail')
    dispatch(showToast({ isError: true, message: 'Something went wrong. Please try again later' }))
  }

  const progressHandler = (progress) => {
    setProgressValue(progress)
    setUploadLoading(true)
  }

  const uploadMediaData = async (file, orgId) => {
    try {
      if (Object.values(file).length > 0 && !file.isExceedSizeLimit) {
        const formdata = new FormData()
        formdata.append('type', 'photo')
        formdata.append('filename', file.file, file.file.name)
        formdata.append('extension', `.${file?.file?.type.split('/')?.[1]}`)
        formdata.append('filetype', file?.file?.type)
        formdata.append('category', 'organization')
        formdata.append('orgId', orgId)

        await uploadMedia(
          formdata,
          successHandler,
          errorHandler,
          progressHandler,
          file.file.name,
          file
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]

      if (file.size > ADVERTISER_LOGO_MAX_IMAGE_SIZE) {
        setUploadingStatus('fail')
        setImageUploadErrors((prevErrors) => [...prevErrors, { message: 'File is larger than 1 MB' }])
      } else {
        setImageUploadErrors([])
        setIsImageFailed(false)
        setUploadingStatus('pending')
        setLogoData({
          file,
          preview: URL.createObjectURL(event.target.files[0]),
          title: file.name,
          isUploading: !(file.size > ADVERTISER_LOGO_MAX_IMAGE_SIZE),
          uploadProgress: 0,
          isUploaded: (file.size > ADVERTISER_LOGO_MAX_IMAGE_SIZE),
          isExceedSizeLimit: file.size > ADVERTISER_LOGO_MAX_IMAGE_SIZE
        })
      }
    }
  }

  return (
    <>
      <Container>
        <LogoWrapper>
          <RenderIf isTrue={uploadingStatus === 'pending' || uploadingStatus === 'fail'}>
            <LogoImg isError={uploadingStatus === 'fail'}>
              {(advertiserData?.logo?.uris?.logo || Object.values(logoData).length > 0) && uploadingStatus !== 'fail'
                ? <img
                    onError={() => setIsImageFailed(true)}
                    src={!isImageFailed || logoData?.preview ? logoData?.preview || advertiserData.logo?.uris?.logo : `${API.baseUrl}/${advertiserData?.logo?.file}`}
                    height="96px" width="96px"
                    style={{ objectFit: 'contain', borderRadius: 12 }}
                  />
                : <img src={Images.AdvertiserImagePlaceholder} height="48px" width="48px" />}
            </LogoImg>
          </RenderIf>
          <RenderIf isTrue={uploadingStatus === 'uploading' || uploadingStatus === 'success'}>
            <ImageWithProgressWrapper src={logoData?.preview}>
              <RenderIf isTrue={progressValue < 100}>
                <Loader>
                  <LinearProgress variant='determinate' value={progressValue} style={{ height: '5px', width: '90%', marginBottom: '10px' }} />
                </Loader>
              </RenderIf>
            </ImageWithProgressWrapper>
          </RenderIf>
          <div>
            <BrowseWrapper>
              <BrowseTextStyledDiv>
                <input style={{ display: 'none' }} onChange={onImageChange} onClick={(e) => e.stopPropagation()} type='file' ref={ref} accept={ADVERTISER_LOGO_ALLOWED_IMAGE_EXTENSION} />
                <div className={style.AdvertiserDetail__UploadLogo} >
                  <p className={style.AdvertiserDetail__UploadLogoText} onClick={() => ref.current.click()}>Upload logo</p>
                  {uploadingStatus === 'uploading' && <SpinnerXtraSmall /> }
                </div>
              </BrowseTextStyledDiv>
            </BrowseWrapper>
            <Typography fontSize='13px' color={Colors.grayLabelBlack} style={{ margin: '10px 0' }}>Max Size 1MB</Typography>
            <RenderIf isTrue={uploadingStatus === 'fail'}>
              <Typography fontSize='13px' color={Colors.errorRed} style={{ wordWrap: 'break-word' }}>{imageUploadErrors[0]?.message}</Typography>
            </RenderIf>
          </div>
        </LogoWrapper>

        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={CompanyDetailValidationSchema}
          onSubmit={handleFormSubmit}>
          {formik => (
            <Form style={{ height: '100%' }}>
              <Wrapper>
                <FormikControl
                  name="logo"
                  isStandard
                  control="input"
                  label="Logo"
                  required
                  style={{ display: 'none' }}
                />
                <FieldContainer>
                  <FormikControl
                    name="companyName"
                    isStandard
                    control="input"
                    label="Company Name"
                    required
                  />
                </FieldContainer>
                <FieldContainer>
                  <FormikControl
                    name="email"
                    isStandard
                    control="input"
                    label="Email Address"
                    required
                  />
                </FieldContainer>
              </Wrapper>
            </Form>
          )}
        </Formik>
      </Container>
      <ButtonRow>
        <ButtonTray>
          <Button
            minWidth="80px"
            backgroundColor={Colors.white}
            onClick={handleToggle}>
            <Typography fontSize='15px' fontWeight='bold' lineHeight='26px' letterSpacing='0.46px'>CANCEL</Typography>
          </Button>
          <Button
            loading={addAdvertiserLoading || updateAdvertiserLoading || uploadMediaLoading || uploadLoading}
            disabled={addAdvertiserLoading || updateAdvertiserLoading || uploadLoading}
            onClick={() => formikRef?.current.submitForm()}>
            <Typography fontSize='15px' fontWeight='bold' lineHeight='26px' letterSpacing='0.46px' color={Colors.white}>NEXT</Typography>
          </Button>
        </ButtonTray>
      </ButtonRow>
    </>
  )
}

export default CompanyDetail

CompanyDetail.propTypes = {
  handleToggle: PropTypes.func,
  handleActiveStep: PropTypes.func,
  resetProjectList: PropTypes.func,
  setAdvertiserId: PropTypes.func,
  reFetchData: PropTypes.func,
  advertiserData: PropTypes.object
}
