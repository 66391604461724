import classNames from 'classnames'

import { Drawer } from '../Drawer'
import PlayIcon from '../../assets/images/UGCProject/Play.svg'
import PhotoIcon from '../../assets/images/UGCProject/PhotoIcon.svg'
import style from './UGCCancelProjectDrawer.module.scss'
import { SpinnerSmall } from '../Progress'

interface Props {
  open: boolean
  primaryVideoCount: number
  introVideoCount: number
  stillPhotoCount: number
  bRoll: number
  audition: boolean
  loading: boolean
  advertiser: string
  projectName: string
  handleCancelProject: () => void
  handleCloseDrawer: () => void
}

interface ProjectSpecificationRowProps {
  title: string
  value: string | number
  imgSource: string
}

const ProjectSpecificationRow = (props: ProjectSpecificationRowProps): JSX.Element => {
  const { title, value, imgSource } = props
  return (
    <div className={style.Drawer__SpecificationRow}>
      <img src={imgSource} />
      <p className={style.Drawer__SpecificationRowText}>{title}: {value}</p>
    </div>
  )
}

export const UGCCancelProjectDrawer = (props: Props): JSX.Element => {
  const { open = false, advertiser, projectName, handleCancelProject, handleCloseDrawer, primaryVideoCount, introVideoCount, stillPhotoCount, bRoll, audition, loading } = props

  return (
    <Drawer open={open}>
      <aside className={style.Drawer}>
        <header className={style.Drawer__Header}>
          <h1 className={style.Drawer__HeadingText}>Cancel Project?</h1>
          <p className={style.Drawer__DescriptionText}>Cancelled projects can&apos;t be edited or published, and this action can&apos;t be undone. Are you sure you want to continue?</p>
        </header>
        <section className={style.Drawer__ProjectInfo}>
          <p className={style.Drawer__AdvertiserText}>{advertiser}</p>
          <p className={style.Drawer__ProjectNameText}>{projectName}</p>
        </section>
        <section className={style.Drawer__ProjectSpecifications}>
          <ProjectSpecificationRow imgSource={PlayIcon} title="Primary Video" value={primaryVideoCount} />
          <ProjectSpecificationRow imgSource={PlayIcon} title="Intro Videos" value={introVideoCount} />
          <ProjectSpecificationRow imgSource={PhotoIcon} title="Still Photos" value={stillPhotoCount} />
          <ProjectSpecificationRow imgSource={PlayIcon} title="B-Roll" value={bRoll} />
          <ProjectSpecificationRow imgSource={PlayIcon} title="Audition" value={audition ? 'Yes' : 'No'} />
        </section>
        <footer className={style.Drawer__Footer}>
          <button onClick={handleCloseDrawer} className='primary-text-button'><p className={classNames(style.Drawer__FooterButtonText, style['Drawer__FooterButtonText--Black'])}>Nevermind</p></button>
          <button className='primary-button' onClick={handleCancelProject}>{loading && <SpinnerSmall /> }<p className={classNames(style.Drawer__FooterButtonText, loading ? style['Drawer__FooterButtonText--Small'] : '')}>Yes, cancel the project</p></button>
        </footer>
      </aside>
    </Drawer>
  )
}
