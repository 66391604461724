import { Fragment } from 'react'

import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import { useDispatch, useSelector } from 'react-redux'

import { Images } from '../../../../assets'
import { MenuArrow, NavItemImg, ParentMenuSpan, StyledNavLink, SubMenuSpan, SubStyledNavLink } from '../../Styles'
import { Colors } from '../../../../constants'
import { toggleSelectSubMenu } from '../../../../redux/actions/SidebarActions'

const Selects = props => {
  // props
  const { pathname, isOpen } = props
  // hooks
  const dispatch = useDispatch()
  const { isSelectSubMenuOpen } = useSelector(state => state.SidebarReducer)
  // functions
  const openSubMenu = () => {
    if (isOpen) {
      dispatch(toggleSelectSubMenu(!isSelectSubMenuOpen))
    }
  }

  return (
    <Fragment>
      <StyledNavLink
        to={'/selects/active/select/sub-select'}
        onClick={openSubMenu}>
        <NavItemImg src={pathname === '/selects' || pathname.includes('/selects/') ? Images.selectsIconActive : Images.selectsIconInactive} />
        <ParentMenuSpan
          color={pathname === '/selects' || pathname.includes('/selects/') ? Colors.black : Colors.inactiveBlack}>
          Selects
          <MenuArrow
            src={Images.arrowDropDown}
            isSubMenuOpen={isSelectSubMenuOpen}
          />
        </ParentMenuSpan>
      </StyledNavLink>
      {isOpen && (
        <Collapse in={isSelectSubMenuOpen} timeout="auto" unmountOnExit>
          <SubStyledNavLink to={'/selects/active/select/sub-select'}>
            <div><SubMenuSpan>Active</SubMenuSpan></div>
          </SubStyledNavLink>
          <SubStyledNavLink to={'/selects/archived/select/sub-select'}>
            <div><SubMenuSpan>Archived</SubMenuSpan></div>
          </SubStyledNavLink>
        </Collapse>
      )}
    </Fragment>
  )
}

Selects.propTypes = {
  pathname: PropTypes.string,
  isOpen: PropTypes.bool
}

export default Selects
