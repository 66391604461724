import { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Images } from '../../../assets'
import { Colors, UIConstants } from '../../../constants'

const SearchBar = styled.input`
  flex-direction: row;
  border: 0px;
  width: 100%;
  height: 96%;
  outline: none;
`

const SearchBarView = styled.div`
  border: 1px solid ${Colors.borderGray};
  width: ${props => props.isLarge ? '100%' : '400px'};
  box-sizing: border-box;
  background: ${Colors.white};
  display: flex;
  align-items: center;
  height: 40px !important;
  align-items: center;
  margin-right: ${props => (props.isLarge && !props.isUserTalent) ? '35px' : '0'};
`

const SearchIcon = styled.img`
  padding-left: 8px;
  padding-right: 8px;
`

const SearchBox = ({ isLarge = false, isUserTalent = false }) => {
  const [search, setSearch] = useState()
  const navigate = useNavigate()
  const user = useSelector(state => state.UserReducer)

  if (user.isAdvertiser) {
    return (<></>)
  }

  return (
    <SearchBarView isLarge={isLarge} isUserTalent={isUserTalent}>
      <SearchIcon src={Images.searchIcon} alt={Images.searchIcon} />
      <SearchBar
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder={UIConstants.searchBarPlaceHolder}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            !isUserTalent
              ? navigate(`/talent/search?q=${search}`)
              : navigate(`/users/talent/search?q=${search}`)
          }
        }}
      />
    </SearchBarView>
  )
}

SearchBox.propTypes = {
  isUserTalent: PropTypes.bool,
  isLarge: PropTypes.bool
}

export default SearchBox
