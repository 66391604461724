import { useEffect, useState } from 'react'

import useTalentMediaQuery from './useTalentMediaQuery'
import useProjectInterestsQuery from './useProjectInterestsQuery'
import useFetchChildTalentQuery from './useFetchChildTalentQuery'

function useCombineWithMedia ({ projectId, selectId }) {
  // states
  const [loading, setLoading] = useState(true)
  const [dataWithMedia, setDataWithMedia] = useState([])
  const [addedTalents, setAddedTalents] = useState([])

  // apis
  const {
    data: interestTalents,
    loading: interestTalentsLoading
  } = useProjectInterestsQuery({ id: projectId, skip: projectId === undefined })

  const { fetchMore: fetchMoreTalentMedia } = useTalentMediaQuery({
    talentIds: []
  })

  const { data: roles } =
    useFetchChildTalentQuery(selectId)

  // pre load added to role status for disabled add to role button
  useEffect(() => {
    const talents = roles?.listSelects?.map(e => e.talents.join(', '))
    const finalTalents = Array.from(new Set(talents?.join(', ').split(', ')))
    setAddedTalents(finalTalents)
  }, [roles?.listSelects])

  useEffect(() => {
    if (interestTalents?.listProjectInterests && !interestTalentsLoading) {
      if (interestTalents?.listProjectInterests?.length === 0) {
        setLoading(false)
      } else {
        const talents = interestTalents?.listProjectInterests.map(item => ({
          ...item.talent,
          isAddedtoRole: addedTalents.includes(item?.talent.id) ?? false
        }))
        talents.sort((a, b) => Number(b.isAddedtoRole) - Number(a.isAddedtoRole))
        setDataWithMedia(talents)
        partiallyMediaAPICalls(interestTalents?.listProjectInterests)
        setLoading(false)
      }
    }
  }, [interestTalents, interestTalentsLoading, addedTalents])

  const partiallyMediaAPICalls = async (talentData) => {
    if (talentData?.length > 0) {
      const mediAPICall = async (item, index) => {
        const talentIds = talentData?.slice(item, (index + 1) * 50).map((item) => item?.talent?.id)
        if (talentIds.length > 0) {
          const { data: newMediaData } = await fetchMoreTalentMedia({
            variables: {
              talentIds,
              disabled: false,
              limit: 5,
              offset: 0,
              skip: !talentIds.length
            }
          })
          const mediaList = newMediaData?.mediaList
          const newTalentList = talentData?.slice(item, (index + 1) * 50).map(e => {
            const mediaData = mediaList?.filter(media => e.talentId === media.talentId)
            return {
              ...e.talent,
              media: mediaData,
              isAddedtoRole: addedTalents.includes(e?.talent.id) ?? false

            }
          })
          return newTalentList
        } else return []
      }
      const arrayForLoop = new Array(40).fill(0).map((item, index) => (index) * 50)

      const [firstData, secondData, thridData, fourthData] = await Promise.all(arrayForLoop.map(async (item, index) => mediAPICall(item, index)))
      const allData = [...firstData, ...secondData, ...thridData, ...fourthData]

      allData.sort((a, b) => Number(b.isAddedtoRole) - Number(a.isAddedtoRole))
      setDataWithMedia(allData)

      setLoading(false)
    }
  }

  return {
    loading,
    dataWithMedia
  }
}

export default useCombineWithMedia
