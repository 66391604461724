import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import ProjectAPI from '../../../services/Project/ProjectAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'
import useAddRolesWithSelectMutation from './useAddRolesWithSelectMutation'
import useUpdateRolesWithSelectMutation from './useUpdateRolesWithSelectMutation'

function useUpdateProjectMutation () {
  // hooks
  const dispatch = useDispatch()
  // state
  const [loading, setLoading] = useState(false)

  // mutation
  const [UpdateProjectWithoutSelectMutation, { loading: projectUpdateLoading }] = useMutation(ProjectAPI.updateProject(), { errorPolicy: 'all' })
  const [UpdateProjectWithSelectMutation, { data, loading: projectDataLoading }] = useMutation(ProjectAPI.updateProjectWithSelect(), { errorPolicy: 'all' })

  const [SubmitRole, { loading: submitRoleLoading }] = useAddRolesWithSelectMutation()
  const [SubmitUpdateRole, { loading: submitUpdateRoleLoading }] = useUpdateRolesWithSelectMutation()

  useEffect(() => {
    setLoading(projectDataLoading || submitRoleLoading || submitUpdateRoleLoading || projectUpdateLoading)
  }, [projectDataLoading, submitRoleLoading, submitUpdateRoleLoading, projectUpdateLoading])

  const UpdateProjectWithSelect = async (formData, cb) => {
    const { updatedRoles, newRoles, updatedProject, projectStatus, project } = formData

    const submitRoleFunc = () => {
      const projectId = project.id
      const ownerId = project.ownerId
      const selectId = project.selectId
      const finalNewRoles = { roles: newRoles.roles, projectId, selectId, ownerId }
      const finalUpdatedRoles = { roles: updatedRoles.roles, projectId, selectId, ownerId }
      let toggleModal = () => null
      if (newRoles.roles.length > 0) {
        if (updatedRoles.roles.length === 0) {
          toggleModal = cb
        }
        SubmitRole(finalNewRoles, toggleModal)
      }
      if (updatedRoles.roles.length > 0) {
        SubmitUpdateRole(finalUpdatedRoles, cb)
      }
    }
    if (updatedProject) {
      await UpdateProjectWithSelectMutation({
        variables: updatedProject
      }).then(
        () => {
          submitRoleFunc()
        }
      ).catch((err) => {
        dispatch(
          showToast({
            message: err?.networkError?.result?.errors[0]?.extensions?.response?.body?.error ?? err?.message,
            isError: true
          })
        )
      })
    } else {
      UpdateProjectWithoutSelectMutation({
        variables: {
          id: project.id,
          status: projectStatus
        }
      }).then(
        () => {
          submitRoleFunc()
        }
      ).catch((error) => {
        dispatch(
          showToast({
            message: error?.message,
            isError: true
          })
        )
      })
    }
  }

  return [UpdateProjectWithSelect, { data, loading }]
}

export default useUpdateProjectMutation
