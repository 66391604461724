import { Fragment, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Button, Typography } from '../../../../components'
import useUserRoleList from '../../Hooks/useUserRoleListQuery'
import userUpdateMutation from '../../Hooks/userUpdateMutation'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { FormikControl } from '../../../../components/formik/formikControl'
import { AdvertiserUserValidationSchema } from '../../../../services/ValidationServices'

const Wrapper = styled.div`
  display: flex;
  height: 0;
  flex: 1;
  flex-direction: column;
  padding: 40px 40px;
`

const BottomWrapper = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  padding-inline: 40px;
  align-items: flex-start;
`

const EditUser = props => {
  // ref
  const formikRef = useRef(null)
  // hooks
  const dispatch = useDispatch()

  // props
  const {
    activeUserTab,
    masterData,
    handleMasterData,
    toggleEditUserSidebarVisible
  } = props

  // initial value for formik
  const initialValues = {
    firstName: activeUserTab?.firstname,
    lastName: activeUserTab?.lastname,
    email: activeUserTab.email,
    title: activeUserTab?.roles
  }

  // api calls
  const [fetchUserRoleList, { data }] = useUserRoleList()
  const [updateUserMutation, { loading: updateUserLoading }] = userUpdateMutation()

  // initiating API call to get user role list
  useEffect(() => {
    getAllUserRoleList()
  }, [])

  // functions
  const getAllUserRoleList = async () => await fetchUserRoleList()

  const handleUserUpdate = async formikData => {
    try {
      await updateUserMutation({
        variables: {
          userUpdateId: activeUserTab?.id,
          firstname: formikData?.firstName,
          lastname: formikData?.lastName,
          email: formikData?.email,
          roles: formikData?.title
        }
      })
      const userIndex = masterData.findIndex((data) => data?.id === activeUserTab?.id)
      masterData[userIndex] = {
        ...masterData[userIndex],
        firstname: formikData?.firstName,
        lastname: formikData?.lastName,
        email: formikData?.email,
        roleDetail: formikData?.title
      }
      handleMasterData(masterData)
      toggleEditUserSidebarVisible()
      dispatch(showToast({
        message: 'User updated successfully'
      }))
    } catch (error) {
      dispatch(showToast({
        message: 'User updation failed',
        isError: true
      }))
    }
  }

  return (
    <Fragment>
      <Wrapper>
        <Typography fontSize={34} fontWeight={700} letterSpacing={0.25}>
          Edit User
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={400}
          style={{
            opacity: 0.4,
            marginBottom: 25
          }}>
          Edit details of advertiser&apos;s user.
        </Typography>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          validateOnMount={true}
          initialValues={initialValues}
          validationSchema={AdvertiserUserValidationSchema}
          onSubmit={handleUserUpdate}>
          {formik => {
            return (
              <Form>
                <FormikControl
                  name="firstName"
                  control="input"
                  isStandard
                  label="First Name"
                  style={{ marginBottom: 15 }}
                />
                <FormikControl
                  name="lastName"
                  control="input"
                  isStandard
                  label="Last Name"
                  style={{ marginBottom: 15 }}
                />
                <FormikControl
                  name="email"
                  control="input"
                  isStandard
                  label="Email"
                  style={{ marginBottom: 15 }}
                />
                {data?.userRoleList?.length > 0 && (
                    <FormikControl
                      name="title"
                      control="multiselectfortag"
                      isStandard
                      label="Title"
                      style={{ marginBottom: 15 }}
                      options={data?.userRoleList.map(role => ({ id: role.id, label: role.name })) ?? []}
                      data={data?.userRoleList.map(role => role.name)}
                    />
                )}
              </Form>
            )
          }
        }
        </Formik>
      </Wrapper>
      <BottomWrapper>
        <Button
          onClick={toggleEditUserSidebarVisible}
          outline
          backgroundColor="white">
          <Typography
            fontSize={15}
            fontWeight={700}
            letterSpacing={0.46}
            color="black">
            CANCEL
          </Typography>
        </Button>
        <Button
          loading={updateUserLoading}
          onClick={() => formikRef?.current.submitForm()}>
          <Typography
            fontSize={15}
            fontWeight={700}
            letterSpacing={0.46}
            color="white">
            SUBMIT
          </Typography>
        </Button>
      </BottomWrapper>
    </Fragment>
  )
}

EditUser.propTypes = {
  isEditUserSidebarVisible: PropTypes.bool,
  toggleEditUserSidebarVisible: PropTypes.func,
  activeUserTab: PropTypes.object,
  handleMasterData: PropTypes.func,
  masterData: PropTypes.array
}

export default EditUser
