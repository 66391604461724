import { type Dispatch, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as z from 'zod'
import classNames from 'classnames'
import { Skeleton } from '@mui/material'

import style from './Directions.module.scss'

import { UGCDeliverablesDirectionsV2 } from '../../../UGCDeliverablesDirectionsV2'
import { UGCSidebarContext } from '../../../../contexts/ugcSidebar'
import { type IProjectDetail, type IProjectDetailActions, UGCProjectDetailContext } from '../../../../contexts/projectDetail'
import { SpinnerSmall } from '../../../Progress'
import { useSubmitDirections } from '../../../../hooks/UGC/useSubmitDirections'
import { useInitializeDeliverables } from '../../../../hooks/UGC/useInitializeDeliverables'
import { DeliverablesContext, type IDeliverables, type IDeliverablesActions } from '../../../../contexts/deliverables'
import { directionsValidationSchema } from '../../../../constants/UGCConstants'

type IValidationError = Record<string, string | undefined>

const HeroShimmer = (): JSX.Element => {
  return (
    <>
     <div className={style.Skeleton__Heading}>
        <Skeleton variant='rectangular' />
      </div>
      <div className={style.Skeleton__Description}>
        <Skeleton variant='rectangular' />
      </div>
      <div className={style.Skeleton__Input}>
        <Skeleton variant='rectangular' />
      </div>
    </>
  )
}

export default function Directions (): JSX.Element {
  const { id } = useParams()
  const navigate = useNavigate()

  const [errors, setErrors] = useState({})
  const [CTAType, setCTAType] = useState('')

  const { loading } = useInitializeDeliverables()

  const [sidebarData, handleSidebarData] = useContext(UGCSidebarContext)
  const [projectDetailData] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)
  const [{ directions }, updateDeliverableData] = useContext<[IDeliverables, Dispatch<IDeliverablesActions>]>(DeliverablesContext)

  const { handleSubmitDirections, isLoading: submitLoading } = useSubmitDirections(CTAType === 'close')

  const handleBack = (): void => {
    if (id != null) {
      navigate(`/projects/ugc/${id}/details`)
    }
  }

  const submitDirectionsHandler = (type: string, close: boolean): void => {
    setCTAType(type)

    // If no direction value selected, then set to 'No Preference'
    const updatedDirections = { ...directions }
    if (updatedDirections.wardrobeSpecs.value.length <= 0) {
      updatedDirections.wardrobeSpecs.value = 'No Preference'
    }
    if (updatedDirections.lightingSpecs.value.length <= 0) {
      updatedDirections.lightingSpecs.value = 'No Preference'
    }
    if (updatedDirections.locationSpecs.value.length <= 0) {
      updatedDirections.locationSpecs.value = 'No Preference'
    }
    if (updatedDirections.framingSpecs.value.length <= 0) {
      updatedDirections.framingSpecs.value = 'No Preference'
    }
    updateDeliverableData({ directions: updatedDirections })

    // Validate data
    const validateData = {
      wardrobeSpecs: { value: directions.wardrobeSpecs.value, note: directions.wardrobeSpecs.additionalNote.trim() },
      lightingSpecs: { value: directions.lightingSpecs.value, note: directions.lightingSpecs.additionalNote.trim() },
      locationSpecs: { value: directions.locationSpecs.value, note: directions.locationSpecs.additionalNote.trim() },
      framingSpecs: { value: directions.framingSpecs.value, note: directions.framingSpecs.additionalNote.trim() }
    }

    const result = directionsValidationSchema.safeParse(validateData)

    if (result.success) {
      setErrors({})
      handleSubmitDirections(close)
      sidebarData[1].isCompleted = true
      sidebarData[1].isError = false
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          errValues[item.path.join('.')] = item.message
        })
        sidebarData[1].isError = true
        sidebarData[1].isCompleted = false
        setErrors({ ...errors, ...errValues })
      }
    }
    handleSidebarData([...sidebarData])
  }

  if (loading) {
    return (
      <>
        <section className={style.Skeleton}>
         <HeroShimmer />
         <HeroShimmer />
        </section>
      </>
    )
  }

  return (
    <div className={style.Direction}>
      <section className={style.Direction__Section}>
        <UGCDeliverablesDirectionsV2 errorsData={[errors, setErrors]}/>
      </section>
      <footer className={style.Footer}>
        <button className={classNames('primary-text-button', style.Footer__Button)} onClick={handleBack}><p>Back</p></button>
        <div className={style.Footer__ButtonRow}>
          <button disabled={false} className={classNames('outline-primary-button', style.Footer__Button, projectDetailData.name === '' ? style['Footer__Button--Disabled'] : '')} onClick={projectDetailData.name === '' ? undefined : () => { submitDirectionsHandler('close', true) }}>{(id != null) ? 'Update & Close' : 'Save & Close'}{(submitLoading && CTAType === 'close') && <SpinnerSmall />}</button>
          <button disabled={false} className={classNames('primary-button', style.Footer__Button, projectDetailData.name === '' ? style['Footer__Button--Disabled'] : '')} onClick={projectDetailData.name === '' ? undefined : () => { submitDirectionsHandler('next', false) }}>Next: Deliverables  {(submitLoading && CTAType === 'next') && <SpinnerSmall />}</button>
        </div>
      </footer>
    </div>
  )
}
