// TODO: Need to create a storybook for this component
import { Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '../../../../components/buttonV2'
import { RenderIf } from '../../../../utils/Helpers'
import { Colors } from '../../../../constants'

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  justify-content: space-between;
  border-left: 1px solid ${Colors.primaryGray};
`
const UserInfoContainer = styled.div`
  flex: 1;
  flex-direction: column;
`
const AvatarView = styled.img`
  position: static;
  width: 96px;
  height: 96px;
  border-radius: 12px;
  background: url(${props => props.imageUrl});
  background-size: cover;
`
const UserName = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${Colors.black};
  text-transform: capitalize;
`
const UserDetail = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.black};
  opacity: 0.4;
  margin: 10px 0px;
`
const AvatarPlaceholder = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primaryGray};
`
const AvatarText = styled.p`
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
`

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41.99px;
  display: flex;
  align-items: center;
  color: ${Colors.black};
  margin: 0;
`
const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: ${Colors.spanishGray};
`

const ButtonText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${props => props.color};
`

const BottomActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const RenderAvatar = (props) => {
  const { img, firstname, lastname } = props
  if (img) {
    return <AvatarView imageUrl={img} />
  }
  return (
    <RenderIf isTrue={firstname || lastname} >
      <AvatarPlaceholder>
        <AvatarText> {firstname?.charAt(0) + lastname?.charAt(0)}</AvatarText>
      </AvatarPlaceholder>
    </RenderIf>
  )
}

RenderAvatar.propTypes = {
  loading: PropTypes.bool,
  img: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string
}

export const DisableTalent = (props) => {
  // props
  const { profileData, handelToggleDisableSidebar, updateTalentFunc, updateTalentLoading } = props
  const { firstname, lastname, email, phone, location, id, disabled } = profileData

  // hooks

  const handelDisableTalent = () => {
    updateTalentFunc(id, !disabled, false)
    handelToggleDisableSidebar()
  }

  return (
    <Fragment>
      <Wrapper>
        <Title>Are you sure you want to disable this Talent?</Title>
        <Description>
          By doing this action Talent will be disabled and won&apos;t do anything in app, Later you can enable them.
        </Description>
        <UserInfoContainer>
          <RenderAvatar firstname={firstname} lastname={lastname} img={profileData?.mediaProfile?.uris?.profilePic} />
          <RenderIf isTrue={firstname || lastname}>
            <UserName>{(firstname || '') + ' ' + (lastname?.charAt(0) ?? '') + '.'}</UserName>
          </RenderIf>
          <UserDetail>{email}</UserDetail>
          <UserDetail>{phone && '+1 ' + phone}</UserDetail>
          <UserDetail>{location}</UserDetail>
        </UserInfoContainer>
        <BottomActionWrapper>
          <Button onClick={handelToggleDisableSidebar} height="40px" minWidth="96px" backgroundColor={Colors.white}>
            <ButtonText>CANCEL</ButtonText>
          </Button>
          <Button onClick={handelDisableTalent} loading={updateTalentLoading} height="40px" minWidth="96px">
            <ButtonText color={Colors.white}>Disable talent</ButtonText>
          </Button>
        </BottomActionWrapper>
      </Wrapper>
    </Fragment>
  )
}

DisableTalent.propTypes = {
  profileData: PropTypes.object,
  handelToggleDisableSidebar: PropTypes.func,
  updateTalentFunc: PropTypes.func,
  updateTalentLoading: PropTypes.bool
}
