import { useState, useRef, useEffect, Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { Formik, Form, FieldArray } from 'formik'

import { Images } from '../../../../assets'
import { Colors, Fonts } from '../../../../constants'
import { Button, Typography } from '../../../../components'
import { AddNewRoleValidationSchema } from '../../../../services/ValidationServices'
import SelectAPI from '../../../../services/Select/SelectAPI'

const FormWrapper = styled.div`
  min-height: 6vh;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 24px;

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: ${Colors.white};
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.lightSilver};
    border: 4px solid white;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px ${Colors.white};
  }
  ::-webkit-scrollbar-corner {
    background: ${Colors.white};
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  margin-top: 40px;
  padding: 0 40px;
`

const HeaderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 100px;
`

const BackArrowImg = styled.img`
  display: flex;
  height: 20%;
  margin-top: 60%;
  margin-bottom: 40%;
  margin-left: 10%;
  width: 52%;
  cursor: pointer;
`

const BackArrowImgWrapper = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  height: 100%;
  width: 11%;
`

const AddNewRoleText = styled.p`
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  margin-top: 15px;
  color: ${Colors.labelBlack};
  cursor: pointer;
  padding: 0 40px;
`

const FieldInput = styled.textarea`
  width: 100%;
  height: ${props => (props.name === 'description' ? '64px' : '24px')};
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  outline: none;
  overflow:hidden;
  resize: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${Colors.labelBlack};
  border-bottom: 2px solid ${props =>
    props.isValid ? Colors.errorRed : Colors.black}};
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  display:block;
  padding-bottom: 5px;
  margin-bottom: 5px auto;
`

const ErrorMessage = styled.p`
  color: ${Colors.errorRed};
  margin-top: 3px;
  font-size: 12px;
`
const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  overflow-x: hidden;
`

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: end;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  align-self: center;
`

const CloseButton = styled.img`
  height: 14px;
  width: 14px;
  display: flex;
  position: relative;
  margin-left: -23px;
  cursor: pointer;
`

const RoleNameWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
`

const Bottom = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 40px;
  margin-top: 30px;
`

const RoleWrapper = styled.div`
  padding-left: 40px;
`

const AddNewRole = ({
  handelUpdateTabData,
  isVisibleModal,
  toggleModal,
  roleData,
  parentId,
  isBackArrow,
  parentActiveTabData
}) => {
  const [AddSelect, { loading: addSubSelectLoading }] = useMutation(
    SelectAPI.createSelect(),
    {
      notifyOnNetworkStatusChange: true
    }
  )

  useEffect(() => {
    if (roleData.length > 0) {
      const tempData = roleData.map(role => {
        return {
          role_name: role.name,
          id: role.id
        }
      })
      setFormValues({
        ...formValues,
        roles: tempData
      })
    }
  }, [roleData])

  const addRoleContainerRef = useRef(null)
  const formikRef = useRef(null)

  const [formValues, setFormValues] = useState({
    roles: [{ role_name: '', id: '' }]
  })
  const scrollToBottom = () =>
    addRoleContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    })
  const [deletedRoles, setDeletedRoles] = useState([])
  const [newRoleIndexs, setNewRoleIndexs] = useState([])
  const [editedRoleIndex, setEditedRoleIndex] = useState([])
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const [addedRoleReponseCount, setAddedRoleResponseCount] = useState(null)

  const handelOnChangeRole = (e, index) => {
    e.preventDefault()
    formValues.roles[index].role_name = e.target.value
    setFormValues({ ...formValues, roles: [...formValues.roles] })
    const isIndexPresent = editedRoleIndex.findIndex(idx => idx === index)
    const isIndexPresentInNewRole = newRoleIndexs.findIndex(
      idx => idx === index
    )

    if (isIndexPresent === -1 && formValues.roles[index].id !== '') {
      editedRoleIndex.push(index)
      setEditedRoleIndex(editedRoleIndex)
    } else if (
      index === 0 &&
      formValues.roles[index].id === '' &&
      isIndexPresentInNewRole === -1
    ) {
      newRoleIndexs.push(index)
      setNewRoleIndexs(newRoleIndexs)
    }
  }

  useEffect(() => {
    if (isSubmitPressed && newRoleIndexs.length === addedRoleReponseCount) {
      toggleModal(!isVisibleModal)
    }
  }, [
    isSubmitPressed,
    editedRoleIndex,
    newRoleIndexs,
    addedRoleReponseCount,
    deletedRoles
  ])

  useEffect(() => {
    formikRef.current.setValues(formValues)
  }, [])

  const handelAddMoreRole = () => {
    setFormValues({
      ...formValues,
      roles: [...formValues.roles, { role_name: '' }]
    })
    const formLength = formValues.roles.length
    newRoleIndexs.push(formLength)
    setNewRoleIndexs(newRoleIndexs)
    setTimeout(() => {
      scrollToBottom()
    }, 300)
  }

  const handelSubmitSelect = async values => {
    setIsSubmitPressed(true)
    let resCount = 0
    if (newRoleIndexs.length > 0) {
      for (const item of newRoleIndexs) {
        if (values?.roles[item]?.role_name) {
          await AddSelect({
            variables: {
              parentId,
              name: values.roles[item].role_name,
              ownerId: parentActiveTabData?.ownerId
            }
          }).then(() => {
            resCount = resCount + 1
          })
        }
      }
      handelUpdateTabData()
      setAddedRoleResponseCount(resCount)
    }
  }

  const handelRemoveRole = index => {
    const roleData = formValues.roles[index]
    const isRoleAlreadyPresent = deletedRoles.findIndex(
      role => role.role_name === roleData.role_name
    )
    if (isRoleAlreadyPresent === -1) {
      deletedRoles.push(roleData)
      setDeletedRoles(deletedRoles)
    }
    const newRoles = formValues.roles.filter((_, idx) => idx !== index)
    setFormValues({ ...formValues, roles: newRoles })
  }

  const BottomActionButtons = () => {
    return (
      <Bottom>
        <RowView>
            <Button backgroundColor='white' onClick={toggleModal}>
              <Typography
                fontWeight="bold"
                fontSize={15}
                onClick={toggleModal}
                letterSpacing={0.46}>
                CANCEL
              </Typography>
            </Button>
            <Button loading={addSubSelectLoading} >
              <Typography
               color='white'
               fontWeight='bold'
               fontSize={15}
               letterSpacing={0.46}>
                ADD ROLE
              </Typography>
            </Button>
        </RowView>
      </Bottom>
    )
  }

  return (
    isVisibleModal && (
      <Wrapper>
        <InnerWrapper>
          <HeaderWrapper>
            <BackArrowImgWrapper show={isBackArrow}>
              <BackArrowImg
                src={Images.backArrowBlack}
                onClick={() => toggleModal(!isVisibleModal)}
              />
            </BackArrowImgWrapper>
            <HeaderInfoWrapper>
              <Typography
                fontSize={24}
                fontWeight="bold"
                style={{ marginBottom: 10 }}>
                Add New Role
              </Typography>
              <Typography fontSize={14} style={{ opacity: 0.5 }}>
                Add new role here
              </Typography>
            </HeaderInfoWrapper>
          </HeaderWrapper>
          <Formik
            innerRef={formikRef}
            initialValues={formValues}
            validationSchema={AddNewRoleValidationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={values => handelSubmitSelect(values)}>
            {({ errors, touched, handleSubmit, handleChange, values }) => {
              return (
                <Fragment>
                  <Form
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                    onSubmit={handleSubmit}>
                    <FormWrapper>
                      <FieldArray name="roles">
                        {arrayHelpers => {
                          return (
                            <Fragment>
                              {formValues.roles.map((roleDetails, index) => {
                                return (
                                  <RoleWrapper key={index}>
                                    <Typography
                                      fontSize={13}
                                      letterSpacing={0.15}
                                      style={{ marginBottom: 10 }}
                                    >
                                      Role Name
                                    </Typography>
                                    <RoleNameWrapper>
                                      <FieldInput
                                        type={'roles'}
                                        name={`roles[${index}].role_name`}
                                        placeholder={'Role Name'}
                                        value={roleDetails.role_name}
                                        onChange={e => {
                                          handelOnChangeRole(e, index)
                                          handleChange(e)
                                        }}
                                      />
                                      <CloseButton
                                        onClick={() => {
                                          handelRemoveRole(index)
                                          arrayHelpers.remove(index)
                                        }}
                                        src={Images.closeVectorBlack}
                                      />
                                    </RoleNameWrapper>
                                    {errors?.roles?.length > 0 &&
                                      errors?.roles[index]?.role_name &&
                                      touched?.roles?.length > 0 &&
                                      touched?.roles[index]?.role_name && (
                                        <ErrorMessage
                                          isValid={
                                            errors?.roles[index]?.role_name
                                          }>
                                          {errors?.roles[index]?.role_name}
                                        </ErrorMessage>
                                    )}
                                  </RoleWrapper>
                                )
                              })}
                              <AddNewRoleText
                                onClick={() => {
                                  handelAddMoreRole()
                                  arrayHelpers.push({ role_name: '', id: '' })
                                }}>
                                Add more role
                              </AddNewRoleText>
                            </Fragment>
                          )
                        }}
                      </FieldArray>
                      <div ref={addRoleContainerRef} />
                    </FormWrapper>
                    <BottomActionButtons />
                  </Form>
                </Fragment>
              )
            }}
          </Formik>
        </InnerWrapper>
      </Wrapper>
    )
  )
}
export default AddNewRole
AddNewRole.defaultProps = {
  activeMenuDetails: { role_list: [{ role_name: '' }] },
  roleData: [],
  parentId: '',
  isBackArrow: false
}
AddNewRole.propTypes = {
  handelUpdateTabData: PropTypes.func,
  isVisibleModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  activeMenuDetails: PropTypes.object,
  roleData: PropTypes.array,
  parentId: PropTypes.string,
  isBackArrow: PropTypes.bool,
  parentActiveTabData: PropTypes.object
}
