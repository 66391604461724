import styled from 'styled-components'

import { useState } from 'react'

import { Colors } from '../../constants'
import SearchBox from './Components/SearchBox'
import { HeaderV2, Layout, Typography } from '../../components'
import AdvertiserUserMain from './Components/AdvertiserUserMain'
import useDebounce from '../../hooks/Common/useDebounce'

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Advertiser = () => {
  const [search, setSearch] = useState('')

  // hooks
  const debouncedSearchTerm = useDebounce(search, 500)

  const HeaderDescription = () => (
    <DescriptionWrapper>
      <Typography
        fontSize={14}
        color={Colors.headingBlack}
        letterSpacing={0.15}
        lineHeight="20px">
        View your users
      </Typography>
    </DescriptionWrapper>
  )

  return (
    <Layout>
      <HeaderV2
        title={
          <Typography
            fontSize={34}
            fontWeight="bold"
            lineHeight="42px"
            letterSpacing={0.25}>
            Users
          </Typography>
        }
        titleRight={<SearchBox search={search} handleSearch={text => setSearch(text)} />}
        description={<HeaderDescription />}
      />
      <AdvertiserUserMain searchText={debouncedSearchTerm}/>
    </Layout>
  )
}

export default Advertiser
