import { DashboardTypes } from '../types/DashboardTypes'
import types from '../types/types'

const INITIAL_STATE = {

  // toast
  isToastVisible: false,
  toastMessage: null,
  isError: false,

  // talents
  talentsWithPictures: [],
  talentsWithoutPictures: [],
  talentsWithVideos: [],

  // user talents
  allTalents: [],
  flaggedTalents: [],
  disabledTalents: [],

  // active index of user talents/ users
  activeTalentTabIndex: {
    userTalentsActiveTabIndex: 0,
    talentsActiveTabIndex: 0
  },
  activeSidebarRoute: '',

  // talent card resolution
  talentCardResizeData: {
    resolutionName: 'SM',
    resolutionSize: 150
  }
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SHOW_TOAST:
      return {
        ...state,
        isToastVisible: true,
        toastMessage: payload.message,
        isError: payload.isError
      }

    case types.HIDE_TOAST:
      return { ...state, isToastVisible: false, toastMessage: null }

    case DashboardTypes.SET_TALENTS_WITH_PICTURES:
      return {
        ...state,
        talentsWithPictures: payload
      }

    case DashboardTypes.SET_TALENTS_WITH_VIDEOS:
      return {
        ...state,
        talentsWithVideos: payload
      }
    case DashboardTypes.SET_TALENTS_WITHOUT_PICTURES:
      return {
        ...state,
        talentsWithoutPictures: payload
      }
    case DashboardTypes.SET_FLAGGED_TALENTS:
      return {
        ...state,
        flaggedTalents: payload
      }

    case DashboardTypes.SET_DISABLED_TALNETS:
      return {
        ...state,
        disabledTalents: payload
      }
    case DashboardTypes.SET_ALL_TALNETS:
      return {
        ...state,
        allTalents: payload
      }
    case DashboardTypes.SET_ACTIVE_TALENT_TAB_INDEX:
      return {
        ...state,
        activeTalentTabIndex: payload
      }
    case DashboardTypes.SET_ACTIVE_SIDEBAR_ROUTE:
      return {
        ...state,
        activeSidebarRoute: payload
      }
    case DashboardTypes.SET_TALENT_CARD_RESIZE_DATA:
      return {
        ...state,
        talentCardResizeData: payload
      }
    default:
      return state
  }
}
