import { useQuery } from '@apollo/client'
import TalentAPI from '../../services/Talent/TalentAPI'

function useTalentMediaQuery (props) {
  const { talentIds, talentId, disabled, type } = props
  const { data, loading, fetchMore, refetch } = useQuery(
    TalentAPI.getMediaListData(),
    {
      variables: {
        talentIds,
        talentId,
        disabled,
        type
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: false,
      skip: talentIds?.length === 0 || !talentId
    }
  )

  return { data, loading, fetchMore, refetch }
}

export default useTalentMediaQuery
