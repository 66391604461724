import { gql } from '@apollo/client'

export const getProjectList = gql`
  query ListProject($projectIds: [ID!], $offset: Int, $limit: Int, $sort: String, $status: [String!], $organizationId: ID, $showPast: Boolean, $types: [ProjectType!], $withBrand: Boolean) {
    listProject(projectIds: $projectIds, offset: $offset, limit: $limit, sort: $sort, status: $status, organizationId: $organizationId, showPast: $showPast, types: $types) {
      ... on CastingProject {
        countryCodeLocation
        endDate
        owner(withBrand: $withBrand) {
          name
          logo {
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
          brands {
            id
            name
            logo {
              uris {
                ... on PhotoURLS {
                  logo
                }
              }
            }
          }
        }
        name
        id
        roles {
          rate
        }
        startDate
        color
        type
        status
        postalCode
        brandId
      }
      ... on UGCProject {
        numberOfCreators
        numberOfVideos
        name
        id
        compensation
        endDate
        owner(withBrand: $withBrand) {
          name
          logo {
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
          brands {
            id
            name
            logo {
              uris {
                ... on PhotoURLS {
                  logo
                }
              }
            }
          }
        }
        status
        color
        type
        brandId
      }
    }
  }
`

export const getListProjectTalentStatus = gql`
  query ListProjectTalentStatus($projectId: ID!, $offset: Int, $limit: Int) {
    listProjectTalentStatus(projectId: $projectId, offset: $offset, limit: $limit) {
      talentId
      talent {
        firstname
        lastname
        mediaProfile {
          uris {
            ... on PhotoURLS {
              card
              profilePic
            }
          }
        }
        videoProfile {
          uris {
            ... on VideoURLS {
              poster
              stream
            }
          }
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`

export const getAssetsListProjectTalentStatus = gql`
  query ListProjectTalentStatus($projectId: ID!, $offset: Int, $limit: Int, $statuses: [ProjectTalentStatus!]) {
    listProjectTalentStatus(projectId: $projectId, offset: $offset, limit: $limit, statuses: $statuses) {
      talentId
      talent {
        firstname
        lastname
        mediaProfile {
          uris {
            ... on PhotoURLS {
              card
              profilePic
            }
          }
        }
        videoProfile {
          uris {
            ... on VideoURLS {
              poster
              stream
            }
          }
        }
      }
      metadata {
        approvedSubmissions
        rejectedSubmissions
        submittedSubmissions
      }
      status
      createdAt
      updatedAt
    }
  }
`

export const listProjectTalentStatus = gql`
  query ListProjectTalentStatus($projectId: ID!, $offset: Int, $limit: Int, $status: ProjectTalentStatus) {
    listProjectTalentStatus(projectId: $projectId, offset: $offset, limit: $limit, status: $status) {
      status
      talentId
      createdAt
      updatedAt
    }
  }
`

export const listTalentSubmissions = gql`
  query ListTalentSubmission($deliverableId: ID!, $offset: Int, $limit: Int) {
    listTalentSubmission(deliverableId: $deliverableId, offset: $offset, limit: $limit) {
      talentId
      media {
        uris {
          ... on VideoURLS {
            poster
            stream
          }
        }
      }
    }
  }
`

export const getTalentSubmission = gql`
  query GetTalentSubmission($deliverableId: ID!, $talentId: ID!) {
    getTalentSubmission(deliverableId: $deliverableId, talentId: $talentId) {
      talentId
      media {
        uris {
          ... on VideoURLS {
            poster
            stream
          }
        }
      }
    }
  }
`

export const updateUGCProjectTalentStatus = gql`
  mutation UpdateUGCProjectTalentStatus ($projectId: ID!, $talentId: ID!, $status: ProjectTalentStatus!) {
    updateUGCProjectTalentStatus (projectId: $projectId, talentId: $talentId, status: $status) {
      talentId
      status
      updatedAt
    }
  }
`

export const getListSelects = gql`
  query ListSelects($parentId: ID, $offset: Int, $limit: Int) {
    listSelects(parentId: $parentId, offset: $offset, limit: $limit) {
      talents
      id
    }
  }
`

export const selectUpdate = gql`
  mutation SelectUpdate($selectUpdateId: ID!, $talents: [ID!]) {
    updateSelect(id: $selectUpdateId, talents: $talents) {
      talents
    }
  }
`
