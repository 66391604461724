import { useQuery } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useProjectInterestsQuery ({ id, skip }) {
  const { data, loading, error, fetchMore } = useQuery(ProjectAPI.getListProjectInterests(), {
    variables: {
      projectId: id,
      offset: 0,
      limit: 2000
    },
    skip,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  return { data, loading, error, fetchMore }
}

export default useProjectInterestsQuery
