import { type ChangeEvent, useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, type RadioProps } from '@mui/material'

import { InputBox } from '../InputBox'
import { RenderIf } from '../../utils/Helpers'
import style from './RadioInput.module.scss'

interface InputBoxProps {
  name: string
  label?: string
  errorText?: string
  placeholder?: string
  isError?: boolean
  variant?: string
  withPrefix?: boolean
  prefixText?: string
  withLabel?: boolean
  isMultiline?: boolean
  withLengthSuffix?: boolean
  acceptedLength?: number
}
interface RadioInputProps {
  label?: string
  name?: string
  errorText?: string
  isError?: boolean
  value?: boolean | string | null
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  noteValue?: string
  options: Array<{
    id: string | number
    value: boolean | string
    label: string
    hasNote?: boolean
  }>
  inputBoxProps?: InputBoxProps
}

export const RadioInput = (props: RadioInputProps): JSX.Element => {
  const { label, isError = false, errorText = '', value, noteValue, onChange, name, options, inputBoxProps } = props
  const [hasNote, setHasNote] = useState<boolean>(false)

  const StyledRadio = (props: RadioProps): JSX.Element => {
    return (
      <Radio
        color='default'
        checkedIcon={<CheckBoxIcon className={style.RadioInput__Icon} />}
        icon={<CheckBoxOutlineBlankIcon className={style.RadioInput__Icon} />}
        {...props}
      />
    )
  }
  return (
    <>
      <FormControl error={isError} className={style.RadioInput}>
        <FormLabel className={style.RadioInput__Label}>{label}</FormLabel>
        {/* value ?? null because making this component as controlled component */}
        <RadioGroup row name={name} onChange={onChange} value={value ?? null}>
          {options.map((e, index) => <FormControlLabel key={index} value={e.value} onChange={() => { setHasNote(e?.hasNote ?? false) }} control={<StyledRadio />} label={e.label} />)}
        </RadioGroup>
      </FormControl>
      <RenderIf isTrue={isError}>
        <FormHelperText error>{errorText}</FormHelperText>
      </RenderIf>
      <RenderIf isTrue={hasNote}>
        <InputBox onChange={onChange} value={noteValue} {...inputBoxProps} />
      </RenderIf>
    </>
  )
}
