import { type ChangeEvent, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import * as z from 'zod'
import { TextField } from '@mui/material'
import { useParams } from 'react-router-dom'

import style from './UGCHeader.module.scss'
import { showToast } from '../../redux/actions/DashBoardActions'
import { UGCProjectDetailContext } from '../../contexts/projectDetail'
import { type MutationTuple, type OperationVariables, type DefaultContext, type ApolloCache } from '@apollo/client'

const validationSchema = z.object({
  name: z.string().nonempty('This field is required')
})

type IValidationError = Record<string, string | undefined>
interface UGCHeaderProps {
  updateUGCProjectMutation?: MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | any
}

export function UGCHeader (props: UGCHeaderProps): JSX.Element {
  const { updateUGCProjectMutation } = props
  const [updateUGCProject] = updateUGCProjectMutation()
  const { id } = useParams()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState<IValidationError>({})

  const [{ name }, handleChangeName] = useContext(UGCProjectDetailContext)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    handleChangeName({ [name]: value })
    handleValidate(e)
  }

  const handleValidate = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target

    const result = validationSchema.safeParse({ [name]: value })
    if (result.success) {
      setErrors({ ...errors, [name]: undefined })
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          if (item.path[0] === name) {
            errValues[String(item.path[0])] = item.message
          }
        })
        setErrors({ ...errors, ...errValues })
      }
    }
  }

  const handleUpdateProjectName = async (): Promise<void> => {
    const result = validationSchema.safeParse({ name })
    if (!result.success || id == null) {
      return
    }
    try {
      await updateUGCProject({ variables: { updateUgcProjectId: id, name: name.trim() } })
      dispatch(showToast({
        message: 'Project name updated successfully'
      }))
    } catch (error: any) {
      dispatch(showToast({
        isError: true,
        message: error?.message
      }))
    }
  }

  return (
    <header className={style.Header}>
      <TextField
        name={'name'}
        value={name}
        onChange={handleChange}
        placeholder={'Project Name'}
        type='text'
        onBlur={() => { void handleUpdateProjectName() }}
        error={errors.name !== undefined}
        helperText={errors.name}
        variant='standard'
        inputProps={{ style: { width: `${(name.length + 11) * 11}px`, marginRight: 10, minWidth: 240, maxWidth: 500 }, maxLength: 255 }}
      />
    </header>
  )
}
