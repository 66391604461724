import { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, TableSortLabel } from '@mui/material'

import AddAdvertiser from './AddAdvertiser'
import { Colors } from '../../../constants'
import { RenderIf } from '../../../utils/Helpers'
import DeleteAdvertiser from './DeleteAdvertiser/DeleteAdvertiser'

import AdvertiserTableRow from './AdvertiserTableRow'
import useDeleteAdvertiserMutation from '../Hooks/useDeleteAdvertiserMutation'
import { ShimmerContentBox, TlyntSidebar, Typography, FooterLoader } from '../../../components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  justify-content: flex-start;
  padding-bottom: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-inline: 40px;
  padding-top: 0;
  padding-bottom: 50px;
`
const StyledTableHead = styled(TableHead)`
  background: ${Colors.primaryGrayOpacity};
`
const StyledTableRow = styled(TableRow)`
  height: 70px !important;
`
const StyledCellHeader = styled(TableCell)`
  border-bottom: none !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: ${Colors.blackOpacity} !important;
`
const StyledCellBody = styled(TableCell)`
  border-bottom: 1px solid ${Colors.antiFlashWhite} !important;
  font-size: 14px !important;
  color: ${Colors.grayLabelBlack} !important;
`
const Shimmer = styled(ShimmerContentBox)`
  height: 50px;
  margin-bottom: 20px;
`

function AdvertiserWrapper ({ data, loading, reFetchData, fetchMore, order, setOrder }) {
  // hooks
  const scrollViewRef = useRef(null)
  // state
  const [orderKey, setOrderKey] = useState(0)
  const [loadMoreAdvertiserStatus, setLoadMoreAdvertiserStatus] = useState('done')
  const [selectedAdvertiser, setSelectedAdvertiser] = useState({})
  const [isDeleteAdvertiserModal, setIsDeleteAdvertiserModal] = useState(false)
  const [isEditAdvertiserModal, setIsEditAdvertiserModal] = useState(false)
  const [activeAdvertiserIndex, setActiveAdvertiserIndex] = useState(null)
  // api call
  const { deleteAdvertiser, loading: deleteLoading } = useDeleteAdvertiserMutation()

  const shimmerCount = new Array(3).fill(0).map((_, index) => (index))

  const handleRequestSort = () => {
    switch (orderKey) {
      case 0:
        setOrder('asc')
        setOrderKey(1)
        break
      case 1:
        setOrder('desc')
        setOrderKey(2)
        break
      case 2:
        setOrder('createdAt')
        setOrderKey(0)
        break
      default:
        break
    }
    setLoadMoreAdvertiserStatus('done')
  }

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current
    if (loadMoreAdvertiserStatus === 'done' && scrollViewRef.current) {
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        if (data?.length >= 20) {
          fetchMoreAdvertiser()
        }
      }
    }
  }

  const fetchMoreAdvertiser = async () => {
    try {
      setLoadMoreAdvertiserStatus('loading')
      await fetchMore(
        () => setLoadMoreAdvertiserStatus('limited'),
        () => setLoadMoreAdvertiserStatus('done')
      )
    } catch (error) {
      setLoadMoreAdvertiserStatus('error')
    }
  }

  const handleDeleteAdvertiserSidebar = () => setIsDeleteAdvertiserModal(!isDeleteAdvertiserModal)
  const handleEditAdvertiserSidebar = () => setIsEditAdvertiserModal(!isEditAdvertiserModal)

  const deleteAdvertiserModel = (data) => {
    setSelectedAdvertiser(data)
    handleDeleteAdvertiserSidebar()
  }
  const editAdvertiserModel = (activeAdertiserIndex) => {
    setActiveAdvertiserIndex(activeAdertiserIndex)
    handleEditAdvertiserSidebar()
  }
  const advertiserDeleteSuccess = (res) => {
    handleDeleteAdvertiserSidebar()
  }

  const onDeleteHandler = async () => {
    if (selectedAdvertiser?.id && !deleteLoading) {
      await deleteAdvertiser(selectedAdvertiser.id, selectedAdvertiser.name, () => null, () => null)
      advertiserDeleteSuccess()
      reFetchData()
    }
  }

  const NoUserFound = () => {
    return (
      <StyledTableRow>
        <StyledCellBody colSpan={5} align="center">
          <Typography
            fontSize={45}
            fontWeight="bold"
            color={Colors.black}
            style={{ textAlign: 'center' }}>
            Add the first advertiser
          </Typography>
        </StyledCellBody>
      </StyledTableRow>
    )
  }

  return (
    <Wrapper ref={scrollViewRef} onScroll={handleScroll}>
      <Table stickyHeader={true}>
        <StyledTableHead>
          <TableRow>
            <StyledCellHeader>
              <Tooltip
                title={order === 'asc' ? 'Sort by descending' : order === 'createdAt' ? 'Sort by ascending' : 'Sort by createdAt'}
                placement={'bottom-end'}>
                <TableSortLabel
                  active={true}
                  direction={order === 'createdAt' ? 'desc' : order === 'asc' ? 'asc' : 'desc'}
                  onClick={handleRequestSort}>
                  Company Name
                </TableSortLabel>
              </Tooltip>
            </StyledCellHeader>
            <StyledCellHeader>Type</StyledCellHeader>
            <StyledCellHeader>Email</StyledCellHeader>
            <StyledCellHeader>No. of Projects</StyledCellHeader>
            <StyledCellHeader>Team Members</StyledCellHeader>
            <StyledCellHeader>Action</StyledCellHeader>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {!loading
            ? data?.length > 0
              ? data.map((advertiser, index) => (
                <AdvertiserTableRow
                  key={advertiser.id}
                  selectedAdvertiser={selectedAdvertiser}
                  advertiser={advertiser}
                  editAdvertiserModel={() => editAdvertiserModel(index)}
                  deleteAdvertiserModel={deleteAdvertiserModel}
                />
              ))
              : data?.length === 0 && <NoUserFound />
            : <StyledTableRow>
              <StyledCellBody colSpan={6}>
                {shimmerCount.map(id => <Shimmer key={id} />)}
              </StyledCellBody>
            </StyledTableRow>}
        </TableBody>
      </Table>
      {loadMoreAdvertiserStatus === 'loading' && <FooterLoader loadMessage='Loading more advertiser..!' />}
      <RenderIf isTrue={isDeleteAdvertiserModal}>
        <TlyntSidebar direction='right' opacityLevel={1} >
          <DeleteAdvertiser
            handleDeleteAdvertiserSidebar={handleDeleteAdvertiserSidebar}
            advertiserData={selectedAdvertiser}
            onDeleteHandler={onDeleteHandler}
            deleteLoading={deleteLoading}
          />
        </TlyntSidebar>
      </RenderIf>
      <RenderIf isTrue={isEditAdvertiserModal}>
        <TlyntSidebar>
          <AddAdvertiser handleToggle={handleEditAdvertiserSidebar} reFetchData={reFetchData} advertiserData={data?.[activeAdvertiserIndex] ?? {}} />
        </TlyntSidebar>
      </RenderIf>
    </Wrapper>
  )
}

AdvertiserWrapper.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  reFetchData: PropTypes.func,
  fetchMore: PropTypes.func,
  order: PropTypes.string,
  setOrder: PropTypes.func
}

export default AdvertiserWrapper
