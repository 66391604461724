import { useState } from 'react'

import md5 from 'md5'
import classNames from 'classnames'
import { styled } from '@mui/material/styles'
import { Tooltip, tooltipClasses } from '@mui/material'
import type { TooltipProps } from '@mui/material/Tooltip'

import { Images } from '../../assets'
import style from './VoteList.module.scss'
import { RenderIf } from '../../utils/Helpers'

interface VoteListProps {
  voteData?: {
    user: MainUser[]
    votes: number
  }
}

interface MainUser {
  id: string
  firstname: string
  lastname: string
  email: string
}

interface userIconProps {
  users?: MainUser[]
  totalVotes?: number
}

interface UserProps {
  email: string
  name: string
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)'
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '5px !important'
  }
}))

const MainUsers = (props: UserProps): JSX.Element => {
  const { name, email } = props
  const [isGravatarLoaded, setIsGravatarLoaded] = useState(true)
  return (
    <CustomTooltip arrow title={<span className={style.VoteList__Username}>{name}</span>}>
     <div className={style.VoteList__UserIcon}>
       {!isGravatarLoaded && <span className={classNames(style.VoteList__IconText, style['VoteList__IconText--Large'])}>{name?.charAt(0).toUpperCase()}</span>}
       <img className={classNames(style.VoteList__UserIconImg, style[`VoteList__UserIconImg${!isGravatarLoaded ? '--Hide' : ''}`])}
        onError={() => { setIsGravatarLoaded(false) }} src={`https://www.gravatar.com/avatar/${md5(email)}?d=404`} />
     </div>
   </CustomTooltip>
  )
}

const OtherUsers = (props: UserProps): JSX.Element => {
  const { name, email } = props
  const [isGravatarLoaded, setIsGravatarLoaded] = useState(true)
  return (
    <div className={style.VoteList__OtherUserWrapper}>
      <div className={classNames(style.VoteList__UserIcon, style['VoteList__UserIcon--InList'])}>
        {!isGravatarLoaded && <span className={style.VoteList__IconText}>{name?.charAt(0).toUpperCase()}</span>}
        <img className={classNames(style.VoteList__UserIconImg, style[`VoteList__UserIconImg${!isGravatarLoaded ? '--Hide' : ''}`])}
          onError={() => { setIsGravatarLoaded(false) }} src={`https://www.gravatar.com/avatar/${md5(email)}?d=404`} />
      </div>
      <span className={style.VoteList__OtherUsername}>{name}</span>
    </div>
  )
}

const UsersIcon = (props: userIconProps): JSX.Element => {
  const { users = [], totalVotes = 0 } = props
  const guestVote = totalVotes - users.length
  if (users?.length > 0) {
    const displayUsers = users.slice(0, 3)
    const otherUsers = users.slice(3)
    const moreCount = totalVotes - displayUsers.length

    return (
      <div className={style.VoteList__Wrapper}>
        {displayUsers.map(user => <MainUsers key={user.id} name={user.firstname + ' ' + user.lastname} email={user.email} />)}
        <RenderIf isTrue={(otherUsers.length > 0) || guestVote > 0}>
          <CustomTooltip arrow
            title={
              <div className={style.VoteList__MainUserHeader}>
                <span className={style.VoteList__HeaderText}>{totalVotes} Likes</span>
                <RenderIf isTrue={guestVote}>
                  <span className={classNames(style.VoteList__HeaderText, style['VoteList__HeaderText--Grey'])}>&nbsp;({guestVote} Guest)</span>
                </RenderIf>
                {otherUsers.map(user => <OtherUsers key={user.id} name={user.firstname + ' ' + user.lastname} email={user.email} />)}
              </div>
            }>
            <div className={classNames(style.VoteList__UserIcon, style['VoteList__UserIcon--IsCount'])}>
              <span className={style.VoteList__Text}>{'+' + String(moreCount)}</span>
            </div>
          </CustomTooltip>
        </RenderIf>
      </div>
    )
  } else if (totalVotes > 0) {
    return (
    <div className={style.VoteList__Wrapper}>
      <CustomTooltip arrow title={<span className={style.VoteList__Text}>Guest</span>}>
          <div className={style.VoteList__UserIcon}>
            <img className={style.VoteList__UserPlaceholder} src={Images.tlyntPlaceholderSquare} />
          </div>
        </CustomTooltip>
      <RenderIf isTrue={guestVote > 1}>
       <CustomTooltip arrow title={<div className={style.VoteList__MainUserHeader}>
            <span className={style.VoteList__HeaderText}>{guestVote} Likes</span>
          </div>}>
        <div className={classNames(style.VoteList__UserIcon, style['VoteList__UserIcon--IsCount'])}>
          <span className={style.VoteList__Text}>{'+' + String(guestVote - 1)}</span>
        </div>
       </CustomTooltip>
      </RenderIf>
    </div>
    )
  }
  return <></>
}

export function VoteList ({ voteData }: VoteListProps): JSX.Element {
  return (
    <div className={style.VoteList}>
      <UsersIcon users={voteData?.user} totalVotes={voteData?.votes} />
    </div>
  )
}
