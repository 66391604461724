import { useContext } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import type { ChangeEvent, Dispatch } from 'react'

import { Accordion } from '../Accordion'
import { DeliverablesContext } from '../../contexts/deliverables'
import { InputBox } from '../InputBox'
import { SectionHeader } from '../UGCSectionHeader'
import { stillImageOptions } from '../../constants/UGCConstants'
import { TagCheckbox } from '../TagCheckbox'
import { UGCCheckboxInputRow } from '../UGCCheckboxInputRow'
import { UGCTooltip } from '../Tooltip'
import Counter from '../Counter/Counter'
import Info from '../../assets/images/UGCProject/Info.svg'
import style from './UGCDeliverablesAdditional.module.scss'
import type { IDeliverables, IDeliverablesActions } from '../../contexts/deliverables'

type IDeliverablesError = Record<string, string | undefined>
interface UGCDeliverablesAdditionalProps {
  errorsData: [IDeliverablesError, Dispatch<IDeliverablesError>]
}

export const UGCDeliverablesAdditional = (props: UGCDeliverablesAdditionalProps): JSX.Element => {
  // init
  const user = useSelector((state: any) => state.UserReducer)

  const [{ introHooks, bRoll, photo }, updateDeliverableData] = useContext<[IDeliverables, Dispatch<IDeliverablesActions>]>(DeliverablesContext)
  const [errors, setErrorsData] = props.errorsData

  const { introHooksCount, projectDeliverable: introHooksDeliverable } = introHooks
  const { photoCount } = photo

  const videoCountIncrementHandler = (): void => {
    const updatedVideoCount = Number(introHooksCount)
    const script = [...introHooksDeliverable.script, { isChecked: true, value: [''], id: null }]
    updateDeliverableData({ introHooks: { ...introHooks, introHooksCount: updatedVideoCount + 1, projectDeliverable: { ...introHooksDeliverable, script } } })
  }

  const videoCountDecrementHandler = (): void => {
    const updatedVideoCount = Number(introHooksCount)
    const script = [...introHooksDeliverable.script]
    script.pop()
    updateDeliverableData({ introHooks: { ...introHooks, introHooksCount: updatedVideoCount - 1, projectDeliverable: { ...introHooksDeliverable, script } } })
  }

  const handleIntroRemove = (index: number): void => {
    const updatedVideoCount = Number(introHooksCount)
    const script = introHooksDeliverable.script.filter((item, i) => i !== index)

    updateDeliverableData({ introHooks: { ...introHooks, introHooksCount: updatedVideoCount - 1, projectDeliverable: { ...introHooksDeliverable, script } } })
  }

  const photoCountIncrementHandler = (): void => {
    updateDeliverableData({ photo: { ...photo, photoCount: photoCount + 1 } })
    photo.projectDeliverable?.specs.push({ value: '', additionalNote: '', id: null })
  }

  const photoCountDecrementHandler = (): void => {
    updateDeliverableData({ photo: { ...photo, photoCount: photoCount - 1 } })
    photo.projectDeliverable?.specs.pop()
  }

  const handleIntroHooksScriptChange = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    introHooksDeliverable.script[index].value = [e.target.value]
    updateDeliverableData({ introHooks: { ...introHooks, projectDeliverable: { ...introHooksDeliverable } } })
  }

  const handleBRollChange = (e: ChangeEvent<HTMLInputElement>): void => {
    bRoll.projectDeliverable.specs.value = [e.target.value]
    updateDeliverableData({ bRoll: { ...bRoll, projectDeliverable: { ...bRoll.projectDeliverable } } })
  }

  const handleIntroHookScriptCheckboxClick = (index: number): void => {
    introHooksDeliverable.script[index].isChecked = !introHooksDeliverable.script[index].isChecked
    updateDeliverableData({ introHooks: { ...introHooks, projectDeliverable: { ...introHooksDeliverable } } })
  }

  const handleBRollCheckboxClick = (): void => {
    bRoll.projectDeliverable.specs.isChecked = !bRoll.projectDeliverable.specs.isChecked
    updateDeliverableData({ bRoll: { ...bRoll, projectDeliverable: { ...bRoll.projectDeliverable } } })
  }

  const handlePhotoAdditionals = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    photo.projectDeliverable.specs[index].additionalNote = e.target.value
    updateDeliverableData({ photo: { ...photo, projectDeliverable: { ...photo.projectDeliverable } } })
  }

  const handleInputBlur = (errorValue: string): void => { setErrorsData({ ...errors, [errorValue]: undefined }) }

  const handleChangePhotoOptionSelection = (value: string[], index: number, errorValue: string): void => {
    photo.projectDeliverable.specs[index].value = value[0]
    updateDeliverableData({ photo: { ...photo } })
    setErrorsData({ ...errors, [errorValue]: undefined })
  }

  const handleRemovePhoto = (index: number): void => {
    photo.projectDeliverable.specs.splice(index, 1)
    updateDeliverableData({ photo: { ...photo, photoCount: photoCount - 1, projectDeliverable: { ...photo.projectDeliverable } } })
  }

  return (
    <div className={style.Additional}>
      <p className={style.Additional__Title}>Additional</p>
      <SectionHeader headerText='Intro Videos' tooltip={<UGCTooltip dataTestId='project-ugc-form-tooltip-intro-videos' popper={<img src={Info} />}>
        <span>Intro videos give you more creative options in the editing process. For each intro, you can add a script or select “creator&apos;s choice” if you&apos;d like the content creator to come up with intros that can be paired with the hero video(s). Each intro video script should take less than 5 seconds to read at normal reading speed.</span>
      </UGCTooltip>} descriptionText='Intro videos are the first 3-5 seconds of a video that can be combined into any video to create additional variations.' />

      <Counter min={0} max={user.isAdmin === true ? 10 : 3} handleDecrement={videoCountDecrementHandler} handleIncrement={videoCountIncrementHandler} value={introHooksCount} />
      <div className={style.Additional__IntroHooks}>
        {introHooksDeliverable.script.map((item: { isChecked: boolean, value: string[] }, index: number) => {
          return <Accordion key={`script${index}`} label={`Intro ${index + 1}`} isRemovable withRemoveText={false} onRemove={() => { handleIntroRemove(index) }}>
            <UGCCheckboxInputRow onBlur={() => { handleInputBlur(`introHooks.${index}.value`) }}
                errorText={errors[`introHooks.${index}.value`]} isChecked={item.isChecked}
                onCheckboxClick={() => { handleIntroHookScriptCheckboxClick(index) }} value={item.value[0]}
                onChange={(e: ChangeEvent<HTMLInputElement>) => { handleIntroHooksScriptChange(e, index) }}
                title='Does this intro videos have a script for talents to follow?'
                warnOnNonSelection warningText='Revisions are limited. Providing a script is highly recommended to ensure video quality' />
          </Accordion>
        })}
      </div>

      <SectionHeader headerText='Still Images' tooltip={<UGCTooltip dataTestId='project-ugc-form-tooltip-still-images' popper={<img src={Info} />}>
        <span>Still images are useful for thumbnails, promotional stills, banner ads, and more. You can specify notes to ensure creators capture specific angles or compositions.</span>
      </UGCTooltip>} descriptionText='Provide additional guidance about the image style you would like' />

      <Counter min={0} max={5} handleDecrement={photoCountDecrementHandler} handleIncrement={photoCountIncrementHandler} value={photoCount} />
      <div className={classNames(style.Additional__StillImages, photoCount === 0 ? style['Additional__StillImages--Empty'] : '')}>
        {photo.projectDeliverable?.specs.map((item: any, index: number) => {
          return (
            <Accordion isRemovable withRemoveText={false} onRemove={() => { handleRemovePhoto(index) }} label={`Image ${index + 1}`} key={`image${index}`}>
              <p className={style.Additional__ImageStyleText}>Image Style</p>
              <p className={style.Additional__ImageStyleDescriptionText}>What type of shot should talent produce for this image?</p>
              <TagCheckbox name={`photo${index}`} isMultiSelection={false} value={[item.value]} onChange={(name, value) => { handleChangePhotoOptionSelection(value, index, `photo.${index}.value`) }} options={stillImageOptions} />
              <div className={style.Additional__StillImagesInput}>
                <InputBox isMultiline onBlur={() => { handleInputBlur(`photo.${index}.value`) }} disabled={item.value === "Talent's choice"} withLengthSuffix placeholder='Directions' isError={errors[`photo.${index}.value`] !== undefined} errorText={errors[`photo.${index}.value`]} value={photo.projectDeliverable.specs[index].additionalNote} onChange={(e) => { handlePhotoAdditionals(e, index) }} acceptedLength={2048} />
              </div>
            </Accordion>
          )
        })}
      </div>
      <SectionHeader headerText='B-Roll' tooltip={<UGCTooltip dataTestId='project-ugc-form-tooltip-b-roll' popper={<img src={Info} />}>
        <span>B-Roll is recorded video footage that can be used during the editing process. With b-roll, we can cut away from you speaking at points in the ad, and show the B Roll footage while you speak.</span>
      </UGCTooltip>} descriptionText='B-roll is supplemental footage used when editing ads. (Provide direction for up to 60 seconds of b-roll video. Include details about specific scenes, angles or actions you would like to capture.)' />
      <div className={style.Additional__GrayBox}>
        <UGCCheckboxInputRow InputLabel='Directions' labels={['Yes', 'No']} onBlur={() => { handleInputBlur('bRoll.value') }} errorText={errors['bRoll.value']} isChecked={bRoll.projectDeliverable.specs.isChecked} onCheckboxClick={handleBRollCheckboxClick} value={bRoll.projectDeliverable.specs.value?.[0]} onChange={(e: ChangeEvent<HTMLInputElement>) => { handleBRollChange(e) }} title='Do you want talents to produce B-roll video?'/>
      </div>

    </div>
  )
}
