import { useEffect, useState } from 'react'

import useAdvertiserUserListQuery from '../../Advertiser/Hooks/useAdvertiserUserListQuery'
import useGetUserRoleListQuery from '../Hooks/useGetUserRoleListQuery'

function useCombineWithRole ({ advertiserId }) {
  // states
  const [data, setData] = useState()
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(true)

  // apis
  const { data: roleData, loading: roleLoading } = useGetUserRoleListQuery()
  const { data: userData, loading: userLoading, refetch } = useAdvertiserUserListQuery({ organizationIds: [advertiserId] })

  // store role data
  useEffect(() => {
    setRoles(roleData?.userRoleList)
  }, [roleLoading])

  // maping user with role name
  useEffect(() => {
    if (userData?.userList?.length > 0 && !userLoading) {
      const finalData = userData?.userList.filter(user => advertiserId === user.ownerId)
      if (roles?.length > 0) {
        const updated = finalData.map(user => {
          const role = roles.find(e => e.id === user.roleId)?.name
          return { ...user, role }
        })
        setData(updated)
      } else setData(finalData)
      setLoading(false)
    } else if (userData?.userList?.length === 0 && !userLoading) {
      setData([])
      setLoading(false)
    }
  }, [userLoading, roles])

  return {
    data,
    loading,
    refetch
  }
}

export default useCombineWithRole
