import { useQuery } from '@apollo/client'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

function useAdvertiserUserListQuery (props) {
  const { organizationIds } = props

  const { data, loading, refetch, fetchMore } = useQuery(AdvertiserAPI.getAdvertiserUserList(), {
    variables: {
      organizationIds,
      limit: 1000
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !(organizationIds?.length > 0)
  })

  return { data, loading, refetch, fetchMore }
}

export default useAdvertiserUserListQuery
