import { gql } from '@apollo/client'

export const CHILD_SELECT_LIST = gql`
  query ChildSelectList($offset: Int, $limit: Int, $parentId: ID, $ids: [ID!], $sort: String ){
    selectList(offset: $offset, limit: $limit,parentId: $parentId, ids: $ids, sort: $sort){
     id
     name
     parentId
     talents
    }
  }
`

export const DELETE_SELECT = gql`
  mutation DeleteChildSelect ( $id: ID! ){
    selectDelete(id: $id){
    status
  }
}
`

export const GET_MEDIA_LIST = gql`
  query GetMediaList ( $talentIds: [ID!], $disabled: Boolean, $offset: Int, $limit: Int ){
    mediaList(talentIds: $talentIds, disabled: $disabled, offset: $offset, limit: $limit) {
     id
     talentId
     file
     disabled
     uris {
        ... on PhotoURLS {
         card
        }
      }
    }
  }
`
export const TALENT_UPDATE = gql`
  mutation UpdateTalent($talentUpdateId: String!, $firstname: String, $lastname: String, $email: String, $phone: String, $location: String, $mediaProfile: ID, $socialLinks: SocialLinksInput, $countryCodeLocation: String, $postalCode: String) {
    talentUpdate(id: $talentUpdateId, firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, location: $location, mediaProfile: $mediaProfile, socialLinks: $socialLinks, countryCodeLocation: $countryCodeLocation, postalCode: $postalCode) {
      id
      firstname
      lastname
      email
      phone
      location
      countryCodeLocation
      postalCode
      createdAt
      updatedAt
      socialLinks {
        instagram
        facebook
        tiktok
        twitter
        website
      }
      mediaProfile {
        type
        talentId
        disabled
        file
        id
        createdAt
      }
    }
  }
`
export const MEDIA_LIST = gql`
  query MediaList($talentIds: [ID!], $type: Type, $offset: Int, $limit: Int, $disabled: Boolean) {
    mediaList(talentIds: $talentIds, type: $type, offset: $offset, limit: $limit, disabled: $disabled) {
      id
      type
      talentId
      file
      disabled
      uris {
        ... on PhotoURLS {
          thumbnail
          profilePic
        }
      }
      createdAt
    }
  }
`

export const MEDIA_CREATE = gql`
  mutation MediaCreate($filename: String!, $mediaCreateId: ID, $type: Type, $talentId: ID) {
    mediaCreate(filename: $filename, id: $mediaCreateId, type: $type, talentId: $talentId) {
      id
      type
      talentId
      file
      processing
      uris {
        ... on PhotoURLS {
          card
          full
        }
        ... on VideoURLS {
          stream
          poster
        }
      }
      createdAt
    }
  }
`
export const MEDIA_DELETE = gql`
  mutation MediaDelete($mediaDeleteId: ID!) {
    mediaDelete(id: $mediaDeleteId) {
      status
      message
    }
  }
`

export const MEDIA_UPDATE = gql`
  mutation MediaUpdate($mediaId: ID!, $disabled: Boolean) {
    mediaUpdate(id: $mediaId, disabled: $disabled) {
      id
      disabled
    }
  }
`

export const TALENT_DELETE = gql`
  mutation TalentDelete($talentId: String!) {
    talentDelete(id: $talentId) {
      status
    }
  }
  `
export const CREATE_VOTE = gql`
 mutation CreateVote($selectId: ID!, $talentId: ID!, $userId: ID, $external: Boolean) {
  selectionVoteCreate(selectId:$selectId, talentId: $talentId, userId: $userId, external: $external) {
    selectId
    talentId
    votes
  }
 }
`

export const DELETE_VOTE = gql`
 mutation DeleteVote($selectId: ID!, $talentId: ID!) {
  selectionVoteDeleteByUser(selectId:$selectId, talentId: $talentId) {
    status
    message
  }
 }
`

export const VOTE_BY_USER = gql`
 query VoteByUser($selectId: ID!){
   selectionVoteByUser(selectId: $selectId){
     talentId
     votes
   }
 }
 `

export const SELECTION_VOTE_LIST = gql`
  query SelectionVoteList($selectId: ID!) {
      selectionVoteList(selectId: $selectId) {
      talentId
      votes
      user {
        firstname
        lastname
        email
      }
    }
  }
`

export const CLEAR_SELECTION_VOTE = gql`
  mutation SelectionVoteDelete ( $selectId: ID! ){
    selectionVoteDelete(selectId: $selectId){
    status
  }
}
`
