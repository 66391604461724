
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { SideBar } from '..'
import { MainContainer, Wrapper } from './Styles'

export const Layout = props => {
  const { children } = props
  const { width: sidebarWidth } = useSelector(state => state.SidebarReducer)

  return (
    <Wrapper>
      <SideBar />
      <MainContainer sidebarWidth={sidebarWidth}>{children}</MainContainer>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  ref: PropTypes.object
}

export default Layout
