import classNames from 'classnames'
import style from './UGCAssetsSidebar.module.scss'
import { Link, useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { RenderIf } from '../../utils/Helpers'

const GreenTick = (): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#4CAF50" />
    </svg>
  )
}

/**
  * Component that responsible for rendering the shimmer
  * @returns JSX.Element
*/

const Shimmer = (): JSX.Element => {
  return (
    <div className={style.Sidebar__Skeleton}>
      <Skeleton variant='rectangular' />
    </div>
  )
}

/**
  * Component that responsible for rendering the sidebar skeleton
  * @returns JSX.Element
*/
const SidebarSkeleton = (props: { count: number }): JSX.Element => {
  const { count } = props
  const shimmers = []

  for (let i = 0; i < count; i++) {
    shimmers.push(<Shimmer key={`shimmer-${i}`} />)
  }

  return (
    <> {shimmers} </>
  )
}

interface SidebarChipProps {
  isActive: boolean
  allApproved: boolean
  name: string
  assetApprovedCount: number
  inReviewCount: number
  photo: string | null
  id: string
  initials: string
  isTalentDeleted?: boolean
}

/**
  * Component that responsible for rendering the sidebar chip
  * @returns JSX.Element
  * @param {boolean} isActive - is the chip active
  * {boolean} allApproved - is all the assets approved
  * {string} name - the name of the talent
  * {number} assetApprovedCount - the number of approved assets
  * {number} inReviewCount - the number of assets in review
  * {string} photo - the photo of the talent
  * {string} id - the id of the talent
  * {string} initials - the initials of the talent
  * {boolean} isTalentDeleted - is talent deleted
*/

const SidebarChip = (props: SidebarChipProps): JSX.Element => {
  const { isActive = false, allApproved = false, name, assetApprovedCount, inReviewCount, photo, id: talentId, initials, isTalentDeleted = false } = props
  const { id, activeTab, innerTab } = useParams()
  return (
    <Link replace to={id !== undefined && activeTab !== undefined && innerTab !== undefined ? `/projects/ugc/view/${id}/${activeTab}/${talentId}/${innerTab}` : '#'} className={classNames(style.SidebarChip, isActive ? style['SidebarChip--Active'] : '')}>
      {photo !== null ? <img src={photo} className={style.SidebarChip__Avatar} /> : <div className={style.SidebarChip__Initials}><p className={style.SidebarChip__InitialsText} >{!isTalentDeleted ? initials : 'N/A'}</p></div>}
      <div className={style.SidebarChip__Main}>
        <p className={classNames(style.SidebarChip__MainTalentText, isActive ? style['SidebarChip__MainTalentText--Active'] : '')}>{!isTalentDeleted ? name : <i>[ Deleted User ]</i>}</p>
        <div className={style.SidebarChip__Info}>
          <p className={classNames(style.SidebarChip__MainApprovedText, isActive ? style['SidebarChip__MainApprovedText--Active'] : '')}>{`${assetApprovedCount} Approved`}</p>
          <RenderIf isTrue={!allApproved}>
            <div className={style.SidebarChip__GreenIndicator} />
            <p className={classNames(style.SidebarChip__MainReviewText, isActive ? style['SidebarChip__MainReviewText--Active'] : '')}>{`${inReviewCount} Review`}</p>
          </RenderIf>
        </div>
      </div>
      {allApproved && <GreenTick />}
    </Link>
  )
}

interface Props {
  data: Array<{
    id: string
    name: string
    photo: string
    assetApprovedCount: number
    inReviewCount: number
    initials: string
    allApproved: boolean
  }>
  activeTalentId: string | undefined
  loading: boolean
}

export const UGCAssetsSidebar = (props: Props): JSX.Element => {
  const { data, activeTalentId, loading } = props

  if (loading) {
    return (
      <aside className={style.Sidebar}>
        <SidebarSkeleton count={20} />
      </aside>
    )
  }
  return (
    <aside className={style.Sidebar}>
      {data.map((item) => <SidebarChip key={item.id} {...item} isActive={activeTalentId === item.id} />)}
    </aside>
  )
}
