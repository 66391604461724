import { useEffect, useState } from 'react'

import moment from 'moment'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextField } from '@mui/material'
import { DesktopDatePicker as KeyboardDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import { TextError } from '../textError'
import { Colors } from '../../../constants'

import { LabelText, Wrapper } from './styles'
import 'react-datepicker/dist/react-datepicker.css'

const dateStyles = {
  height: '48px',
  background: `${Colors.antiFlashWhite}`,
  boxSizing: 'border-box',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingInline: '10px'
}

// custome style for picker input font

const CssTextField = styled(TextField)({
  '& input': {
    fontWeight: 400,
    fontSize: 16
  },
  '& .MuiInput-root:before': {
    border: 'none'
  },
  '& .MuiInput-root:after': {
    border: 'none'
  },
  '& .MuiInput-root:hover:not(.Mui-disabled):before': {
    borderBottom: 'none'
  }
})
function DatePicker (props) {
  const {
    label,
    name,
    isStandard = false,
    minDate,
    loading = false,
    disablePast = false
  } = props
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(!isLoaded)
  }, [loading])

  if (isStandard) {
    return (
      <Field name={name}>
        {({ form, field, meta }) => {
          const { setFieldValue } = form
          const { value } = field
          const { error, touched } = meta
          return (
            <>
              <KeyboardDatePicker
                inputFormat="MM/DD/YYYY"
                value={moment(value).utc(true)}
                key={isLoaded}
                InputAdornmentProps={{ style: { marginRight: 10 } }}
                minDate={minDate}
                onChange={date => setFieldValue(name, date)}
                disablePast={disablePast}
                slots={{ textField: TextField }}
                slotProps={{ textField: { fullWidth: true, label, variant: 'standard', error: error && touched } }}
              />
              <TextError>{error && touched ? error : ''}</TextError>
            </>
          )
        }}
      </Field>
    )
  } else {
    return (
      <Wrapper>
        <LabelText htmlFor={name}>{label}</LabelText>
        <Field name={name}>
          {({ form, field, meta }) => {
            const { setFieldValue } = form
            const { value } = field
            const { error } = meta
            return (
              <div style={{ width: '100%' }}>
                <KeyboardDatePicker
                  format='MM/DD/YYYY'
                  value={moment(value).utc(true)}
                  disableFuture
                  key={isLoaded}
                  onChange={date => setFieldValue(name, date)}
                  slots={{ textField: CssTextField }}
                  slotProps={{
                    textField: {
                      fullWidth: true, variant: 'standard', sx: { border: `1px solid ${error ? Colors.errorRed : 'transparent'}`, ...dateStyles }
                    }
                  }}
                />
                <TextError>{error}</TextError>
              </div>
            )
          }}
        </Field>
      </Wrapper>
    )
  }
}

export default DatePicker

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  right: PropTypes.any,
  isStandard: PropTypes.bool,
  minDate: PropTypes.string,
  disablePast: PropTypes.bool,
  loading: PropTypes.bool
}
