import { useEffect, useState } from 'react'

import Footer from './Components/Footer'
import UploadMedia from './Components/UploadMedia'
import { HeaderV2, Layout, Typography } from '../../components'

import BulkDataContext from './Context'

const BulkUpload = () => {
  const [data, setData] = useState([])
  const [tags, setTags] = useState([])
  const [failCount, setFailCount] = useState(0)
  const [successCount, setSuccessCount] = useState(0)
  const [uploadCounter, setUploadCounter] = useState(0)
  const [isUploading, setIsUploading] = useState('pending')

  const removeImage = (title, isFail) => {
    const final = data.filter((item) => item.title !== title)
    isFail && setFailCount(preState => preState - 1)
    setData([...final])
  }

  const incrementSuccessCounter = () => {
    setSuccessCount(preState => preState + 1)
  }

  const incrementFailCounter = () => {
    setFailCount(preState => preState + 1)
  }
  const decrementFailCounter = () => {
    if (failCount > 0) {
      setFailCount(preState => preState > 0 ? preState - 1 : 0)
    }
  }

  const finishUpload = () => {
    setData([])
    setSuccessCount(0)
    setFailCount(0)
    setUploadCounter(0)
  }
  useEffect(() => {
    if (data.length === 0) {
      setUploadCounter(0)
      setIsUploading('pending')
    }
  }, [data])

  return (
    <BulkDataContext.Provider
      value={{
        incrementSuccessCounter,
        incrementFailCounter,
        decrementFailCounter,
        removeImage,
        uploadCounter,
        tags
      }}>
      <Layout>
        <HeaderV2
          title={<Typography fontSize={34} fontWeight='bold' lineHeight='42px'>
            {(data.length === 0 || isUploading === 'pending') ? 'Upload Images' : 'Uploading Images'}</Typography>}
          description={
            <Typography>
              {data.length === 0
                ? 'Drag & drop your files or Browse from your computer'
                : isUploading === 'pending'
                  ? 'Images are Ready to be upload'
                  : 'Image uploading is in progress, Please wait untill uploading is done'}
            </Typography>}
        />
        <UploadMedia setData={setData} data={data} isUploading={isUploading} />
        {data.length > 0 && <Footer
          totalCount={data.length}
          successCount={successCount}
          failCount={failCount}
          setFailCount={setFailCount}
          finishUpload={finishUpload}
          setTags={setTags}
          setUploadCounter={setUploadCounter}
          isUploading={isUploading}
          setIsUploading={setIsUploading} />}
      </Layout>
    </BulkDataContext.Provider>
  )
}

export default BulkUpload
