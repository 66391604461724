import { useEffect, useState } from 'react'

import useGetTalentListQuery from './useGetTalentListQuery'
import useGetTalentLikesQuery from './useGetTalentLikesQuery'

function useCombineWithTalentAndLikes (props) {
  // props
  const { talentIds, activeChildSelectTab } = props
  // api calls
  const { talentListData, loading: talentListLoading } = useGetTalentListQuery({
    talentIds
  })

  const { voteData, loading: voteLoading } = useGetTalentLikesQuery({
    activeSelectId: activeChildSelectTab?.id
  })
  // states
  const [loading, setLoading] = useState(true)
  const [talentWithLikeCountData, setTalentWithLikeCountData] = useState([])

  useEffect(() => {
    if (talentIds) {
      setLoading(true)
    }
  }, [talentIds])

  useEffect(() => {
    if (!voteLoading && !talentListLoading) {
      const talentWithLike = talentListData
        .map(talent => {
          return {
            ...talent,
            voteData: voteData.find(
              voteTalent => voteTalent.talentId === talent.id
            )
          }
        })
      setTalentWithLikeCountData([...talentWithLike.sort((a, b) => (parseInt(b.voteData?.votes) - parseInt(a.voteData?.votes) || a?.firstname?.localeCompare(b?.firstname)))])
      setLoading(false)
    }
  }, [talentListLoading, voteLoading])

  return {
    loading,
    talentWithLikeCountData
  }
}

export default useCombineWithTalentAndLikes
