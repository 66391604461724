import { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Button } from '../buttonV2'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Box, Typography, Checkbox } from '@mui/material'

import { RenderIf } from '../../utils/Helpers'
import { ShimmerGeneral } from '../shimmer/ShimmerGeneral'
import { Colors, ProjectConstants } from '../../constants'
import { showToast } from '../../redux/actions/DashBoardActions'
import useFetchChildTalentQuery from '../../pages/ProjectDetail/Hooks/useFetchChildTalentQuery'

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  padding-left: 0;
  border-left: 1px solid ${Colors.primaryGray};
`
const RoleContainer = styled.div`
  overflow: auto;
  margin-bottom: auto;
`
const RoleList = styled.div`
  height: calc(70vh - 150px);
  overflow: scroll;
  padding-left: 30px;
`

const BottomActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: end;
`
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AddToRole = ({
  handelToggleSidebar,
  addTalent,
  talentId,
  selectId,
  setSortedData
}) => {
  // State
  const [rolesData, setRolesData] = useState()
  const [loading, setLoading] = useState(false)
  const [roleLoader, setRoleLoader] = useState(true)
  // hooks
  const dispatch = useDispatch()

  // api calls
  const { data: roles, loading: roleLoading } =
    useFetchChildTalentQuery(selectId)

  const shimmerCount = new Array(2).fill(0).map((_, index) => index)

  const handleOnSelect = (roleId) => {
    const roleArray = rolesData.slice()
    const index = rolesData.findIndex(role => role.id === roleId)
    let updatedTalents = rolesData[index].talents.slice()
    roleArray[index].isSelected = !roleArray[index].isSelected
    if (roleArray[index].isSelected) {
      updatedTalents.push(talentId)
    } else {
      updatedTalents.splice(updatedTalents.indexOf(talentId), 1)
    }
    updatedTalents = [...new Set(updatedTalents)]
    roleArray[index].talents = updatedTalents
    setRolesData(roleArray)
  }

  useEffect(() => {
    setRoleLoader(true)
    setRolesData()
    const newState = roles?.listSelects?.map(obj => {
      if (obj.talents.includes(talentId)) {
        return { ...obj, isSelected: true }
      }
      return { ...obj, isSelected: false }
    })
    setRolesData(newState)
    setRoleLoader(false)
    setRoleLoader(roleLoading)
  }, [roles?.listSelects, roleLoading])

  const addTalentToRole = () => {
    if (!loading) {
      addTalent(
        rolesData,
        setLoading,
        successAddTalentToRole,
        failAddTalentToRole
      )
    }
  }
  const successAddTalentToRole = () => {
    setSortedData()
    setRolesData()
    handelToggleSidebar()
    dispatch(
      showToast({
        message: ProjectConstants.Project.addToRoleSuccess,
        isError: false
      })
    )
    setLoading(false)
  }
  const failAddTalentToRole = () => {
    handelToggleSidebar()
    dispatch(
      showToast({
        message: 'Internal server Error.',
        isError: true
      })
    )
    setLoading(false)
  }
  const closeSidebar = () => {
    setRolesData()
    handelToggleSidebar()
  }

  return (
    <Fragment>
      <Wrapper>
        <Box pl='40px'>
          <Typography fontWeight='bold' fontSize={34} lineHeight='42px'>Add to Role</Typography>
          <Typography fontSize={14} color={Colors.spanishGray}>Add Talent to a Role.</Typography>
          <Typography fontWeight='bold' fontSize={'20px'} mt={2}>List of Roles</Typography>
        </Box>
        <RoleContainer>
          {selectId
            ? <RoleList>
              {!roleLoader && roles?.listSelects
                ? rolesData?.length
                  ? rolesData?.map((role) => (
                      <CheckboxWrapper key={role.id} onClick={() => handleOnSelect(role.id)}>
                        <Checkbox
                          checked={role.isSelected}
                          sx={{
                            '&.Mui-checked': {
                              color: Colors.black
                            }
                          }}
                        />
                        <Typography>{role.name}</Typography>
                      </CheckboxWrapper>
                  ))
                  : <Typography pl='10px'>No Roles Found.</Typography>
                : shimmerCount.map((e, i) => (
                    <CheckboxWrapper key={i} style={{ margin: '10px 0' }}>
                      <ShimmerGeneral isCheckbox={true} height={22} style={{ paddingLeft: '10px' }} />
                    </CheckboxWrapper>
                ))}
            </RoleList>
            : <Typography pl='40px'>No Roles Found.</Typography>
          }
        </RoleContainer>
        <BottomActionWrapper>
          <Button
            onClick={() => closeSidebar()}
            height="40px"
            minWidth="96px"
            backgroundColor={Colors.white}>
            <Typography fontWeight='bold'>CANCEL</Typography>
          </Button>
          <RenderIf isTrue={!roleLoading && roles?.listSelects}>
            <Button
              onClick={addTalentToRole}
              height="40px"
              minWidth="96px"
              loading={loading}>
              <Typography color={Colors.white} fontWeight='bold'>ADD</Typography>
            </Button>
          </RenderIf>
        </BottomActionWrapper>
      </Wrapper>
    </Fragment>
  )
}

AddToRole.propTypes = {
  handelToggleSidebar: PropTypes.func,
  addTalent: PropTypes.func,
  selectId: PropTypes.string,
  talentId: PropTypes.string,
  setSortedData: PropTypes.func
}
