import { useQuery } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useLegacyListProjectInterestsQuery (projectId) {
  const { data, loading, error, refetch } = useQuery(
    ProjectAPI.getLegacyListProjectInterests(),
    {
      variables: {
        projectId,
        offset: 0,
        limit: 100,
        hideRoleInterest: true
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip: !projectId
    }
  )

  return { data, loading, error, refetch }
}

export default useLegacyListProjectInterestsQuery
