import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { Stack } from '@mui/material'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Images } from '../../../../assets'
import { RenderIf } from '../../../../utils/Helpers'
import { Colors } from '../../../../constants/index'
import { Button, ShimmerContentBox, Typography } from '../../../../components'

import { FormikControl } from '../../../../components/formik/formikControl'
import { PersonalInfoValidationSchema } from '../../../../services/ValidationServices'

import { showToast } from '../../../../redux/actions/DashBoardActions'
import useUpdateDemographicMutation from '../../Hooks/useUpdateDemographicMutation'
import useCreateDemographicMutation from '../../Hooks/useCreateDemographicMutation'

const LeftShimmer = styled(ShimmerContentBox)`
  height: 48px;
  width: 45%;
  margin: 0;
  margin-right: 38px;
`

const RightShimmer = styled(ShimmerContentBox)`
  height: 48px;
  margin: 0;
`

const Container = styled.div`
 padding-left: 50px;
 overflow-y: auto;
 padding-right: 100px;
 padding-top: 32px;
`

const BottomWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`

export const PersonalInfo = ({ demographicData, demographicLoading, talentDemographicData, initialValues, refetch, refetchTalentDetail }) => {
  // hooks
  const { id } = useParams()
  const dispatch = useDispatch()

  // states
  const [personalInfoData, setPersonalInfoData] = useState({
    birthdate: '',
    bodytype: '',
    country: '',
    customsexualorientation: '',
    ethnicity: [],
    eyecolor: '',
    gender: '',
    genderexpression: '',
    haircolor: '',
    hfeet: '',
    hinch: '',
    race: [],
    sexualorientation: ''
  })

  useEffect(() => {
    if (initialValues) {
      const {
        birthdate,
        bodytype,
        country,
        customsexualorientation,
        ethnicity,
        eyecolor,
        gender,
        genderexpression,
        haircolor,
        hfeet,
        hinch,
        race,
        sexualorientation
      } = initialValues
      setPersonalInfoData({
        birthdate: birthdate ?? '',
        bodytype: bodytype ?? '',
        country: country ?? '',
        customsexualorientation: customsexualorientation ?? '',
        ethnicity: ethnicity?.length > 0 ? ethnicity : '',
        eyecolor: eyecolor ?? '',
        gender: gender ?? '',
        genderexpression: genderexpression ?? '',
        haircolor: haircolor ?? '',
        hfeet: hfeet ?? '',
        hinch: hinch ?? '',
        race: race?.length > 0 ? race : '',
        sexualorientation: sexualorientation ?? ''

      })
    }
  }, [initialValues])
  const shimmerCount = useState(new Array(8).fill(0).map((_, index) => ({ id: index })))[0]
  // api calls
  const [CreateDemographicMutation, { loading: createDemographicLoading }] = useCreateDemographicMutation()
  const [UpdateDemographicMutation, { loading: updateDemographicLoading }] = useUpdateDemographicMutation()

  // talentDemographicData?.talentDemographicGet ==> null when no demographic data is there

  const gender = [{
    id: null,
    value: 'Select gender'
  }].concat(demographicData?.getTalentDemographicSelectionData?.gender)
  const bodytype = [{
    id: null,
    value: 'Select Body Type'
  }].concat(demographicData?.getTalentDemographicSelectionData?.bodyType)
  const eyecolor = [{
    id: null,
    value: 'Select Eye Color'
  }].concat(demographicData?.getTalentDemographicSelectionData?.eyeColor)
  const haircolor = [{
    id: null,
    value: 'Select Hair Color'
  }].concat(demographicData?.getTalentDemographicSelectionData?.hairColor)
  const sexualorientation = [{
    id: null,
    value: 'Select Sexual Orientation'
  }].concat(demographicData?.getTalentDemographicSelectionData?.sexualOrientation).concat([{
    id: demographicData?.getTalentDemographicSelectionData?.sexualOrientation?.length + 1,
    value: 'Others'
  }
  ])
  const countryOfHeritage = [{
    id: null,
    value: 'Select Country of heritage'
  }].concat(demographicData?.getTalentDemographicSelectionData?.country)

  const race = demographicData?.getTalentDemographicSelectionData?.race
  const genderexpression = [{
    id: null,
    value: 'Select Gender Expression'
  }].concat(demographicData?.getTalentDemographicSelectionData?.genderExpression)
  const ethnicity = demographicData?.getTalentDemographicSelectionData?.ethnicity

  const copyRace = race
  const copyEthnicity = ethnicity

  const onSubmit = async (values) => {
    try {
      const data = {
        id,
        values,
        copyEthnicity,
        copyRace
      }
      if (!talentDemographicData?.talentDemographicGet) {
        CreateDemographicMutation(data, () => {
          refetch()
          refetchTalentDetail()
        })
      } else {
        UpdateDemographicMutation(data, () => {
          refetch()
          refetchTalentDetail()
        })
      }
    } catch (error) {
      dispatch(showToast({ message: error }))
    }
  }
  return (
    <Container>
      <RenderIf isTrue={demographicLoading}>
        {shimmerCount.map(item => (
          <Stack mb={1.5} direction='row' key={item.id}>
            <LeftShimmer />
            <RightShimmer />
          </Stack>
        ))}
      </RenderIf>
      <RenderIf isTrue={!demographicLoading}>
        <Formik
          initialValues={personalInfoData}
          validationSchema={PersonalInfoValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize>
          {formik => (
            <Form style={{ marginBottom: 100 }}>
              <FormikControl
                control="date"
                label="Birthdate"
                name="birthdate"
                placeholder="Birthdate"
                showYearDropdown={true}
                showPopperArrow={false}
                right={<img src={Images.calender} style={{ height: 20 }} />}
              />
              <FormikControl
                control="select"
                label="Body Type"
                name="bodytype"
                options={bodytype}
              />
              <FormikControl
                control="input"
                label="Height"
                name={[
                  { name: 'hfeet', placeholder: 'Feet' },
                  { name: 'hinch', placeholder: 'Inch(es)' }
                ]}
                right={[
                  <Typography fontSize={16} key='feet'>Feet</Typography>,
                  <Typography fontSize={16} key='inch'>Inch(es)</Typography>
                ]}
                placeholder="Height"
              />
              <FormikControl
                control="select"
                label="Hair Color"
                name="haircolor"
                options={haircolor}
              />
              <FormikControl
                control="select"
                label="Eye Color"
                name="eyecolor"
                options={eyecolor}
              />
              <FormikControl
                control="multiselect"
                label="Race (Optional)"
                name="race"
                options={race}
                placeholder="Race"
              />
              <FormikControl
                control="multiselect"
                label="Ethnicity (Optional)"
                name="ethnicity"
                options={ethnicity}
                placeholder="Ethnicity"
              />
              <FormikControl
                control="select"
                label="Country Of Heritage (Optional)"
                name="country"
                options={countryOfHeritage}
              />
              <FormikControl
                control="select"
                label="Gender (Optional)"
                name="gender"
                options={gender}
              />
              <FormikControl
                control="select"
                label="Gender Expression (Optional)"
                name="genderexpression"
                options={genderexpression}
              />
              <FormikControl
                control="select"
                label="Sexual Orientation (Optional)"
                name="sexualorientation"
                options={sexualorientation}
              />
              <RenderIf
                isTrue={ String(formik.values.sexualorientation) === String(sexualorientation?.length - 1) }>
                <FormikControl
                  control="input"
                  label="Other Sexual Orientation"
                  name="customsexualorientation"
                  options={sexualorientation}
                  placeholder="Other Sexual Orientation"
                />
              </RenderIf>
              <BottomWrapper>
                <Button loading={createDemographicLoading || updateDemographicLoading} type="submit">
                  <Typography color={Colors.white} fontWeight='bold' fontSize={15} letterSpacing={'0.46px'} style={{ marginInline: '10px' }}>Save</Typography>
                </Button>
              </BottomWrapper>
            </Form>
          )}
        </Formik>
      </RenderIf>
    </Container>
  )
}

PersonalInfo.propTypes = {
  demographicData: PropTypes.object,
  demographicLoading: PropTypes.bool,
  talentDemographicData: PropTypes.object,
  initialValues: PropTypes.object,
  refetch: PropTypes.func,
  refetchTalentDetail: PropTypes.func
}
