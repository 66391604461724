import { gql } from '@apollo/client'

class AdvertiserAPI {
  getAdvertiserList () {
    return gql`
      query AdvertiserList($offset: Int, $limit: Int, $sort: String) {
        organizationList(offset: $offset, limit: $limit, sort: $sort) {
          id
          type
          name
          contact
          logo {
            id
            type
            file
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
          projectCount 
        }
      }`
  }

  getAdvertiserData () {
    return gql`
      query AdvertiserData($id: ID!) {
        organizationGet(id: $id) {
          id
          type
          name
          type
          contact
          logo {
            id
            type
            file
            uris {
              ... on PhotoURLS {
                logo
              }
            }
          }
          projectCount 
        }
      }`
  }

  getAdvertiserUserList () {
    return gql`
      query AdvertiserUserList($organizationIds: [ID!], $offset: Int, $limit: Int) {
        userList(organizationIds: $organizationIds, offset: $offset, limit: $limit) {
          id
          firstname
          lastname
          email
          countryCode
          phone
          ownerId
          roles
        }
      }`
  }

  getUserRoleList () {
    return gql`
      query UserRoleList {
        userRoleList {
          id
          name
        }
      }`
  }

  deleteAdvertiser () {
    return gql`
      mutation AdvertiserDelete($id: ID!) {
        organizationDelete(id: $id) {
          status
          message
        }
      }`
  }

  addAdvertiser () {
    return gql`
      mutation AddAdvertiser (
        $name: String
        $contact: String
        $logo: ID
      ) {
        organizationCreate (
          name: $name
          contact: $contact
          logo: $logo
        ) {
          id
          name
          contact
          logo {
            id
            type
            file
          }
        }
      }
    `
  }

  updateAdvertiser () {
    return gql`
      mutation UpdateAdvertiser (
        $id: ID!
        $name: String
        $contact: String
        $logo: ID
      ) {
        organizationUpdate (
          id: $id
          name: $name
          contact: $contact
          logo: $logo
        ) {
          id
          name
          contact
          logo {
            id
            type
            file
          }
        }
      }
    `
  }

  addUserToAdvertiser () {
    return gql`
      mutation AddAdvertiser (
        $firstname: String
        $lastname: String
        $email: String!
        $ownerId: ID!
        $roleId: ID!
        $countryCode: String,
        $phone: String
      ) {
        userCreate (
          firstname: $firstname
          lastname: $lastname
          email: $email
          ownerId: $ownerId
          roleId: $roleId
          countryCode: $countryCode
          phone: $phone
        ) {
          id
          firstname
          lastname
          email
          ownerId
          roleId
        }
      }
    `
  }

  updateUserToAdvertiser () {
    return gql`
      mutation UpdateAdvertiser (
        $id: ID!
        $firstname: String
        $lastname: String
        $email: String!
        $roleId: ID!
        $countryCode: String,
        $phone: String
      ) {
        userUpdate (
          id: $id
          firstname: $firstname
          lastname: $lastname
          email: $email
          roleId: $roleId
          countryCode: $countryCode
          phone: $phone
        ) {
          id
          firstname
          lastname
          email
          ownerId
          roleId
        }
      }
    `
  }

  deleteAdvertiserUser () {
    return gql`
      mutation UserDelete (
        $id: ID!
      ) {
        userDelete (
          id: $id
        ) {
          status
          message
        }
      }
    `
  }

  bulkUserCreate () {
    return gql` mutation BulkUserCreate($users: [UserCreate!]!) {
      bulkUserCreate(users: $users) {
        id
        firstname
        lastname
        email
        ownerId
        countryCode
        phone
        roles
        createdAt
        updatedAt
      }
    }`
  }

  bulkUserUpdate () {
    return gql` mutation BulkUserUpdate($users: [UserUpdate!]!) {
      bulkUserUpdate(users: $users) {
        id
        firstname
        lastname
        email
        ownerId
        roles
        countryCode
        phone
        createdAt
        updatedAt
      }
    }`
  }
}

export default new AdvertiserAPI()
