import { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'

import Header from './Components/Header'
import { Layout } from '../../components'

import Sidebars from './Components/Sidebars'
import SelectList from './Components/SelectList'
import SelectInfo from './Components/SelectInfo'
import useSelectListQuery from './Hooks/useSelectListQuery'

const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
`
const SelectListContainer = styled.aside`
  overflow-y: auto;
  padding-left: 40px;
  padding-right: 10px;
  flex-grow: 0;
  width: 300px;
  flex-shrink: 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
`
const SelectDetailContainer = styled.main`
  padding-left: 10px;
  width: 100%;
  overflow-y: auto;
`

const Selects = (props) => {
  // props
  const { active } = props

  // ref
  const selectListChildRef = useRef(null)
  const selectListScrollRef = useRef(null)

  // hooks
  const { id } = useParams()
  const navigate = useNavigate()
  // states
  const [activeTabData, setActiveTabData] = useState({})

  const [loadingMoreSelectStatus, setLoadingMoreSelectStatus] = useState('done')

  // --------------------------- Create new select modal -----------------------------
  const [
    isCreateNewSelectSidebarModalVisible,
    setIsCreateNewSelectSidebarModalVisible
  ] = useState(false)

  // api call for getting list of selects
  const {
    fetchMore: fetchMoreSelects,
    data: selectListData,
    loading: selectListLoading,
    handleRefetchSelectList
  } = useSelectListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    isArchived: !active
  })

  const getActiveSelectData = () => {
    const activeSelect = selectListData?.find((select) => select.id === id)
    return activeSelect ?? {}
  }

  // manipulating states
  useEffect(() => {
    if (!id) {
      setActiveTabData({})
    } else {
      const activeSelectData = getActiveSelectData()
      setActiveTabData({ ...activeSelectData })
    }
  }, [id])

  // fetch more selects when scroll height is lesser then view port
  useEffect(() => {
    const parentComponentHeight = selectListScrollRef.current?.clientHeight
    const childComponentHeight = selectListChildRef.current?.clientHeight
    if (parentComponentHeight && childComponentHeight && parentComponentHeight > childComponentHeight) {
      fetchMoreInSelectsList()
    }
    const activeSelectData = getActiveSelectData()
    setActiveTabData({ ...activeSelectData })
  }, [selectListData])
  // ---------------- handling select starts --------------
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      selectListScrollRef.current
    if (loadingMoreSelectStatus === 'done' && selectListScrollRef.current) {
      if (parseInt(scrollTop + clientHeight) === scrollHeight) {
        fetchMoreInSelectsList()
      }
    }
  }
  const fetchMoreInSelectsList = async () => {
    try {
      setLoadingMoreSelectStatus('loading')
      await fetchMoreSelects(
        () => setLoadingMoreSelectStatus('limited'),
        () => setLoadingMoreSelectStatus('done')
      )
    } catch (error) {
      setLoadingMoreSelectStatus('error')
      console.error(error)
    }
  }
  // ---------------- handling select ends --------------

  const toggleCreateNewSelectModal = () =>
    setIsCreateNewSelectSidebarModalVisible(
      !isCreateNewSelectSidebarModalVisible
    )

  const resetActiveParentSelectData = () => setActiveTabData({})

  return (
    <Layout>
      <Header
       isArchived={!active}
       toggleCreateNewSelectModal={toggleCreateNewSelectModal}
      />
      <Container>
        <SelectListContainer ref={selectListScrollRef} onScroll={handleScroll}>
          <div ref={selectListChildRef}>
            <SelectList
              // active select data for setting the active select in state and passing active select
              // data to show

              // handling selects for rendering list inside select list component
              selectListData={selectListData}
              selectListLoading={selectListLoading}
              // handling tab data (select)
              activeTabData={activeTabData}
              handleTabData={select => {
                navigate(`/selects/${active ? 'active' : 'archived'}/${select?.id}/sub-select`)
                setActiveTabData(select)
              }}
            />
          </div>
        </SelectListContainer>
        <SelectDetailContainer>
          <SelectInfo
            isActiveSelect={active}
            parentActiveTabData={activeTabData}
            handleRefetchSelectList={handleRefetchSelectList}
            resetActiveParentSelectData={resetActiveParentSelectData}
          />
        </SelectDetailContainer>
      </Container>
      <Sidebars
        // parent active tab data
        activeTabData={activeTabData}
        handleRefetchSelectList={handleRefetchSelectList}
        // for createNewSelect props
        isCreateNewSelectSidebarModalVisible={isCreateNewSelectSidebarModalVisible}
        toggleCreateNewSelectSidebarModalVisible={() => setIsCreateNewSelectSidebarModalVisible(!isCreateNewSelectSidebarModalVisible)}
      />
    </Layout>
  )
}

Selects.propTypes = {
  active: PropTypes.bool
}
export default Selects
