import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { showToast } from '../../../redux/actions/DashBoardActions'
import SelectAPI from '../../../services/Select/SelectAPI'

const useSecureSelectMutation = () => {
  // Hook
  const dispatch = useDispatch()
  const [SecureSelectMutation, { loading }] = useMutation(SelectAPI.secureSelect(), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const secureSelect = async (data, setIsSuccess) => {
    await SecureSelectMutation({
      variables: data
    })
      .then(res => {
        setIsSuccess(true)
        dispatch(showToast({
          message: "You've successfully generated secure link.",
          isError: false
        }))
      })
      .catch(e => dispatch(showToast({
        message: e?.message,
        isError: true
      })))
  }
  return {
    secureSelect,
    loading
  }
}

export default useSecureSelectMutation
