import PropTypes from 'prop-types'

import { Images } from '../../../../assets'
import { MenuSpan, NavItemImg, StyledNavLink } from '../../Styles'

const Talent = props => {
  const { resetActiveSidebarRoute, pathname } = props
  return (
    <StyledNavLink
      to={'/talent'}
      onClick={resetActiveSidebarRoute}
      state={location.pathname}
    >
      <NavItemImg
        src={
          pathname === '/talent' ||
          (pathname.includes('/talent/') &&
            !pathname.includes('/users/talent/'))
            ? Images.dashBoardIconActive
            : Images.dashBoardIconInActive
        }
      />
      <MenuSpan>Talent</MenuSpan>
    </StyledNavLink>
  )
}

Talent.propTypes = {
  resetActiveSidebarRoute: PropTypes.func,
  pathname: PropTypes.string
}

export default Talent
