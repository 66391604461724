import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useStore } from 'react-redux'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './VideoThumbnail.module.scss'
import VideoJS from '../../../../components/VideoJS'
import { RenderIf } from '../../../../utils/Helpers'
import { SpinnerSmall } from '../../../../components/Progress'

import useGetMediaById from '../../Hooks/useGetMediaById'
import Play from '../../../../assets/images/TalentDetails/Play.svg'
import { setTalentVideoList } from '../../../../redux/actions/TalentVideoAction'
import { POSTER_PROCESSING_STATUS } from '../../../../constants/PortfolioConstants'

const ProcessingPlaceholder = () => {
  return (
    <div className={classNames(style.Processing)}>
      <SpinnerSmall />
      <p className={classNames(style.Processing__Text)}>Preparing your file,</p>
      <p className={classNames(style.Processing__Text)}>Please wait...</p>
    </div>
  )
}

const Thumbnail = (props) => {
  // props
  const { videoPlayData, videoJsOptions, onClick, poster, posterLoadedData } = props

  // states
  const [isVideoPlaying, setIsVideoPlaying] = videoPlayData
  const [isPosterLoaded, setIsPosterLoaded] = posterLoadedData
  return (
    <>
      <RenderIf isTrue={isVideoPlaying}>
       <div className={classNames(style.Thumbnail__VideoJSContainer)}>
          <VideoJS options={videoJsOptions} />
       </div>
      </RenderIf>
      <RenderIf isTrue={!isVideoPlaying}>
        <img className={classNames(style.Thumbnail__PosterImage)} onClick={onClick} onLoad={() => setIsPosterLoaded(true)} src={poster} />
      </RenderIf>
      <RenderIf isTrue={isPosterLoaded && !isVideoPlaying}>
        <div className={classNames(style.Thumbnail__Play)} onClick={() => setIsVideoPlaying(true)}>
          <img src={Play} className={classNames(style.Thumbnail__Play__Image)} />
        </div>
      </RenderIf>
    </>
  )
}

Thumbnail.propTypes = {
  onClick: PropTypes.func.isRequired,
  poster: PropTypes.string.isRequired,
  videoJsOptions: PropTypes.object.isRequired,

  videoPlayData: PropTypes.array.isRequired,
  posterLoadedData: PropTypes.array.isRequired
}

const VideoThumbnail = (props) => {
  // props
  const { onClick, poster, processing, id, stream } = props
  // api calls
  const { getMedia } = useGetMediaById()

  const [isPosterLoaded, setIsPosterLoaded] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  // hooks
  const store = useStore()
  const dispatch = useDispatch()
  const intervalRef = useRef(null)

  // lifecycle hook
  useEffect(() => {
    if (poster && processing !== POSTER_PROCESSING_STATUS.COMPLETE && processing !== POSTER_PROCESSING_STATUS.ERROR) {
      // check processing status of video at every 8 sec.
      const intervalId = setInterval(async () => {
        try {
          const response = await getMedia({
            variables: {
              id
            }
          })
          const videoId = response?.data?.mediaGet?.id
          if (response?.data?.mediaGet?.processing === POSTER_PROCESSING_STATUS.COMPLETE) {
            const videoList = store.getState().TalentVideoReducer

            const videoIndex = videoList.findIndex(video => video.id === videoId)
            videoList[videoIndex] = {
              ...videoList[videoIndex],
              processing: POSTER_PROCESSING_STATUS.COMPLETE
            }
            dispatch(setTalentVideoList([...videoList]))
            if (intervalRef?.current) {
              clearInterval(intervalRef?.current)
            }
          } else if (response?.data?.mediaGet?.processing === POSTER_PROCESSING_STATUS.ERROR) {
            const videoList = store.getState().TalentVideoReducer

            const videoIndex = videoList.findIndex(video => video.id === videoId)
            videoList[videoIndex].processing = POSTER_PROCESSING_STATUS.ERROR

            dispatch(setTalentVideoList(videoList))
            if (intervalRef?.current) {
              clearInterval(intervalRef?.current)
            }
          }
        } catch (error) {
          if (intervalRef?.current) {
            clearInterval(intervalRef?.current)
          }
        }
      }, 8000)
      intervalRef.current = intervalId
    }
  }, [poster])

  // constants
  const videoJsOptions = useMemo(() => {
    return {
      autoplay: isVideoPlaying,
      controls: true,
      responsive: true,
      fluid: true,
      fill: false,
      preload: 'auto',
      controlBar: {
        pictureInPictureToggle: false
      },
      sources: [
        {
          src: stream,
          type: 'application/x-mpegURL'
        }
      ],
      bigPlayButton: false
    }
  }, [stream, isVideoPlaying])

  if (poster !== undefined) {
    return (
      <div className={classNames(style.Thumbnail)}>
        {processing === POSTER_PROCESSING_STATUS.PENDING || processing === POSTER_PROCESSING_STATUS.PROCESSING
          ? <ProcessingPlaceholder />
          : <Thumbnail
              poster={poster}
              onClick={onClick}
              videoJsOptions={videoJsOptions}
              isPosterLoaded={isPosterLoaded}
              setIsPosterLoaded={setIsPosterLoaded}
              posterLoadedData={[isPosterLoaded, setIsPosterLoaded]}
              videoPlayData={[isVideoPlaying, setIsVideoPlaying]}
          />
        }
      </div>
    )
  } else {
    return (
      <div className={classNames(style.Thumbnail)}>
        <ProcessingPlaceholder />
      </div>
    )
  }
}

export default VideoThumbnail

VideoThumbnail.propTypes = {
  onClick: PropTypes.func,
  poster: PropTypes.string,
  processing: PropTypes.string,
  id: PropTypes.string,
  stream: PropTypes.string
}
