import { useEffect, useState } from 'react'

import useAdvertiserListQuery from './useAdvertiserListQuery'
import useAdvertiserUserListQuery from './useAdvertiserUserListQuery'

function useCombineWithUsers ({ order }) {
  // states
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dataWithUsers, setDataWithUsers] = useState()
  // apis
  const { data: advertisers, loading: advertiserLoading, refetch: refetchAdvertiser, fetchMore: fetchMoreAdvertiser } = useAdvertiserListQuery({
    offset: 0,
    limit: 20,
    sort: order
  })

  const { data: userData, loading: userLoading, refetch: refetchUsers, fetchMore: fetchMoreUsers } = useAdvertiserUserListQuery({ organizationIds: advertisers?.organizationList.map(advertiser => advertiser?.id) ?? [] })

  useEffect(() => {
    if (order) {
      setLoading(true)
    }
  }, [order])

  // loading advertisers into states
  useEffect(() => {
    if (advertisers?.organizationList?.length > 0 && !advertiserLoading) {
      setData(advertisers?.organizationList)
    } else if (advertisers?.organizationList?.length === 0 && !advertiserLoading) {
      setLoading(false)
    }
  }, [advertisers, advertiserLoading])

  // mapping users into advertisers
  useEffect(() => {
    if (userData?.userList?.length > 0) {
      const dataWithUsers = data?.map(advertiser => {
        const users = userData?.userList?.filter(user => advertiser.id === user.ownerId)
        return {
          ...advertiser,
          users
        }
      })
      setLoading(false)
      setData([...dataWithUsers])
      if (dataWithUsers.length > 0) {
        setDataWithUsers([...dataWithUsers])
      }
    } else {
      if (userData?.userList?.length === 0) {
        setLoading(false)
      }
      setDataWithUsers(advertisers?.organizationList)
    }
  }, [userLoading])

  const fetchMore = async (cb, cb2) => {
    const { data: fetchMoreAdvertiserData } = await fetchMoreAdvertiser({
      variables: {
        offset: data.length,
        limit: 20
      }
    })
    if (fetchMoreAdvertiserData && fetchMoreAdvertiserData.organizationList.length > 0) {
      const { data: newUserData } = await fetchMoreUsers({
        variables: {
          organizationIds: fetchMoreAdvertiserData.organizationList.map((item) => item?.id)
        }
      })
      const userList = newUserData?.userList
      const newUserList = fetchMoreAdvertiserData?.organizationList.map(advertiser => {
        const users = userList?.filter(user => advertiser.id === user.ownerId)
        return {
          ...advertiser,
          users
        }
      })
      cb2()
      setData([...data, ...newUserList])
      setDataWithUsers([...data, ...newUserList])
    } else cb()
  }

  const reFetchData = async (status) => {
    const count = status?.isCreate ? 1 : 0
    const payload = {
      offset: 0,
      limit: dataWithUsers.length + count,
      sort: order === 'createdAt' ? null : JSON.stringify({ name: order })
    }
    const advertiserListResponse = await refetchAdvertiser(Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null)))
    setData(advertiserListResponse?.data?.organizationList)

    await refetchUsers({
      variables: {
        organizationIds: advertiserListResponse.data.organizationList.map(advertiser => advertiser?.id)
      }
    })
  }

  return {
    loading,
    fetchMore,
    reFetchData,
    dataWithUsers
  }
}

export default useCombineWithUsers
