import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { Formik, Form } from 'formik'
import styled from 'styled-components'

import { Colors } from '../../../../constants'
import { FormikControl } from '../../../../components/formik/formikControl'
import { ShimmerContentBox, Button, Typography } from '../../../../components'
import { UpdateTalentValidationSchema } from '../../../../services/ValidationServices'
import CountryListData from '../../../../constants/CountryList.json'
import UseSocialLinksFeature from '../../../../hooks/FeatureFlag/useSocialLinksFeature'
import useTalentUpdateMutation from '../../Hooks/useTalentUpdateMutation'

const Wrapper = styled.div`
  width: 100%;
  margin: 32px 0;
  overflow-y: auto;
  padding-left: 50px;
  padding-right: 15%;
  box-sizing: border-box;
`
const LeftShimmer = styled(ShimmerContentBox)`
  height: 48px;
  width: 40%;
  margin: 0;
  margin-right: 38px;
`
const RightShimmer = styled(ShimmerContentBox)`
  height: 48px;
  margin: 0;
`
export const PersonalDetails = ({ profileData, initialValues, initialLoading }) => {
  // api calls
  const [HandleTalentUpdate, { loading }] = useTalentUpdateMutation()

  // growthbook hooks
  const socialLinksFeature = UseSocialLinksFeature()

  // constants
  const formattedCountryData = Object.keys(CountryListData).map(e => {
    const obj = {}
    obj.id = e
    obj.name = CountryListData[e]
    return obj
  }).filter((country) => country.id !== 'CA' && country.id !== 'US')

  const shimmerCount = new Array(5).fill(0).map((_, index) => ({ id: index }))

  return (
    <Wrapper>
      {initialLoading
        ? shimmerCount.map(item => (
          <Stack mb={1.5} direction='row' key={item.id}>
            <LeftShimmer />
            <RightShimmer />
          </Stack>))
        : <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={UpdateTalentValidationSchema}
            onSubmit={data => {
              const cleanedData = { ...data }
              cleanedData.phone = cleanedData.phone?.replace(/ /g, '')

              if (data.location?.label === profileData?.location) {
                cleanedData.location = cleanedData.location?.label
              }
              HandleTalentUpdate(cleanedData)
            }}>
            {formik => (
              <Form>
                <FormikControl
                  control="input"
                  label="First Name"
                  name="firstname"
                  placeholder="First Name"
                />
                <FormikControl
                  control="input"
                  label="Last Name"
                  name="lastname"
                  placeholder="Last Name"
                />
                <FormikControl
                  control="input"
                  label="Email"
                  name="email"
                  placeholder="Email"
                />
                <FormikControl
                  control="input"
                  label="Location"
                  name="postalCode"
                  placeholder="Postal code">
                  <FormikControl
                    control="select"
                    label=""
                    name="countryCodeLocation"
                    placeholder=""
                    containerStyle={{
                      backgroundColor: Colors.antiFlashWhite,
                      width: 80,
                      marginRight: 10,
                      height: 50
                    }}
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: '100%'
                    }}
                    options={[{ id: 'US', name: 'United States' }, { id: 'CA', name: 'Canada' }].concat(formattedCountryData)}
                    hidelabel
                    isStandard
                    selectProps={{
                      renderValue: (e) => JSON.parse(e),
                      disableUnderline: true
                    }}
                  />
                </FormikControl>
                <FormikControl
                  control="input"
                  label="Phone"
                  name="phone"
                  placeholder="Phone">
                  <FormikControl
                    control="select"
                    label="Country Code"
                    name="countryCode"
                    placeholder="CountryCode"
                    style={{ marginRight: 10, width: 80 }}
                    options={[{ id: 0, value: '+1 (US)' }]}
                    hidelabel
                  />
                </FormikControl>

                {socialLinksFeature &&
                <>
                  <FormikControl
                    control="input"
                    label="Instagram"
                    name="socialLinks.instagram"
                    placeholder="Instagram"
                  />
                  <FormikControl
                    control="input"
                    label="Facebook"
                    name="socialLinks.facebook"
                    placeholder="Facebook"
                  />
                  <FormikControl
                    control="input"
                    label="Twitter"
                    name="socialLinks.twitter"
                    placeholder="Twitter"
                  />
                  <FormikControl
                    control="input"
                    label="Tiktok"
                    name="socialLinks.tiktok"
                    placeholder="Tiktok"
                  />
                  <FormikControl
                    control="input"
                    label="Website"
                    name="socialLinks.website"
                    placeholder="Website"
                  />
                </>}
                <Button type="submit" onClick={() => formik.submitForm()} loading={loading} outline style={{ float: 'right', marginTop: '10px' }}>
                  <Typography fontSize={15} fontWeight='bold' letterSpacing='0.47px'>
                    UPDATE DETAILS
                  </Typography>
                </Button>
              </Form>
            )}
          </Formik>}
      </Wrapper>
  )
}

PersonalDetails.propTypes = {
  profileData: PropTypes.object,
  initialLoading: PropTypes.bool,
  initialValues: PropTypes.object
}
