import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'

import * as Yup from 'yup'
import { decodeJwt } from 'jose'
import { Form, Formik } from 'formik'
import Grid from '@mui/material/Grid'

import styled from 'styled-components'

import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled as muiStyled } from '@mui/material/styles'
import { useFeature } from '@growthbook/growthbook-react'

import LocalStorage from '../../utils/LocalStorage'
import { Images } from '../../assets'
import Logo from '../../components/Logo'
import { Growthbook } from '../../components'
import { useAuth } from '../../services/Auth'
import { API, Fonts } from '../../constants'
import { URL } from '../../constants/URL'
import { showToast } from '../../redux/actions/DashBoardActions'
import { setUserInfo, setUserType, setUserRole } from '../../redux/actions/user'
import { FormikControl } from '../../components/formik/formikControl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  position: relative;
  margin: 10px;
  min-width: calc(100% - 20px);
`

const LeftPanel = styled.div`
  flex: 1 0 auto;
  height: 100%;
  > svg {
    margin: 22px;
  }
`

const RightPanel = styled.div`
  flex: 0 0 auto;
  width: calc((100vh - 20px) * 0.75);
  height: 100%;
  max-width: 60%;
`

const LogoImg = styled(Logo)`
  position: static;
  width: auto;
  height: 32px;
  top: 32px;
  left: 24px;
`

const MidContainer = styled.div`
  width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${Fonts.degular};
  max-width: 360px;
  min-width: 300px;
  margin: auto;
  justify-content: ${props => props.magicLinkFeatureStatus ? 'flex-start' : 'center'}
`

const LoginText = styled.h4`
  width: 100%;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.25px;
  margin: 10px 0px 5px;
`

const CopyrightWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: 32px;
`

const CopyrightText = styled.h4`
  font-family: ${Fonts.degular};
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  margin: 0;
`

const GoogleButton = styled.button`
  width: 100%;
  height: 42px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: solid 1px #9E9E9E;
  transition: all 0.4s ease;
  &:hover {
    background: #f5f5f5;
  }
`

const GoogleIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`

const GoogleText = styled.label`
  font-family: ${Fonts.degular};
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  cursor: pointer;
`

const MainGrid = styled(Grid)`
  height: 100%;
`

const FirstGrid = styled(Grid)`
  height: 66.6%;
`

const SecondGrid = styled(Grid)`
  height: 33.3%;
`

const MaxHeightGridContainer = styled(Grid)`
  height: 100%;
`

const MaxHeightGrid = styled(Grid)`
  height: calc(100% - 10px);
  margin: 5px !important;
`

const HalfHeightGrid = styled(Grid)`
  height: calc(50% - 10px);
  margin: 5px !important;
`

const MaxSizeImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const LightBlueBgGrid = styled(HalfHeightGrid)`
  background-color: rgba(160, 230, 255, 1)
`

const MixBlendModeMaxSizeImg = styled(MaxSizeImg)`
  mix-blend-mode: multiply;
`

const LightPinkBgGrid = styled(HalfHeightGrid)`
  background-color: rgba(255, 200, 255, 1)
`

const LightGoldBgGrid = styled(MaxHeightGrid)`
  background: rgba(255, 217, 115, 1);
`

const LightOrangeBgGrid = styled(MaxHeightGrid)`
  background: rgba(255, 180, 130, 1);
`

const CssButton = muiStyled(LoadingButton)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.5,
  backgroundColor: 'black',
  borderColor: 'black',

  '&:hover': {
    backgroundColor: 'black',
    borderColor: 'black',
    boxShadow: 'none'
  }
})

const AdminLogin = () => {
  const initAuth = () => {
    window.location.replace(`${API.auth}/login/google?redirect_uri=${URL}/login/admin&type=admin&source=google`)
  }

  return (
    <>
      <LoginText>Sign in</LoginText>
      <GoogleButton onClick={initAuth}>
        <GoogleIcon src={Images.googleIcon} alt={Images.googleIcon} />
        <GoogleText>LOGIN WITH GOOGLE</GoogleText>
      </GoogleButton>
    </>
  )
}

const AdvertiserLogin = ({ magicLinkFeatureStatus }) => {
  const dispatch = useDispatch()
  // state
  const [isLoading, setIsLoading] = useState(false)

  const handleMagicLinkSubmit = async (data, resetForm) => {
    setIsLoading(true)
    const { email } = data
    const response = await fetch(`${API.magicLink}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        destination: email,
        redirect_uri: `${URL}/login`,
        source: 'email',
        type: 'advertiser'
      })
    })
    if (response.status === 204) {
      setIsLoading(false)
      dispatch(showToast({
        isError: false,
        message: `Email sent at ${email}`
      }))
      resetForm()
    } else {
      setIsLoading(false)
      if (response.status === 401) {
        dispatch(showToast({
          isError: true,
          message: 'Invalid account'
        }))
      } else {
        dispatch(showToast({
          isError: true,
          message: response?.message
        }))
      }
    }
  }

  const initialValues = {
    email: ''
  }

  return (
    <>
      <Growthbook status={magicLinkFeatureStatus.on}>
        <LoginText>Sign In</LoginText>
        <Formik
        validateOnMount
        onSubmit={(values, { resetForm }) => handleMagicLinkSubmit(values, resetForm)}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Invalid email')
            .max(255)
            .required('Email is required')
        })}>
          {(formik) => (
            <Form style={{ width: '100%', marginTop: 10 }}>
              <FormikControl
                name="email"
                isStandard
                control="input"
                label="Email"
                required
              />
            <CssButton onClick={formik.submitForm} loading={isLoading} variant='contained' disabled={!formik?.isValid} fullWidth sx={{ marginTop: 2 }}>
              SUBMIT
            </CssButton>
            </Form>
          )}
        </Formik>
      </Growthbook>
    </>
  )
}

AdvertiserLogin.propTypes = {
  magicLinkFeatureStatus: PropTypes.object
}

const platformVersion = (location, query, admin) => {
  const domain = location?.hostname
  if (admin || (/localhost/.test(domain) && query.get('admin') !== null)) {
    return 'admin'
  }
  return 'advertiser'
}

const Login = ({ admin = false }) => {
  // hooks
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const magicLinkFeatureStatus = useFeature('magic-link')

  const from = location.state?.from?.pathname || sessionStorage.getItem('from') || '/talent'
  sessionStorage.setItem('from', from)

  const [searchParams] = useSearchParams()
  const idToken = searchParams.get('id_token')
  const accessToken = searchParams.get('access_token')
  const refreshToken = searchParams.get('refresh_token')

  const platform = platformVersion(window.location, searchParams, admin)

  useEffect(() => {
    const mountEffect = async () => {
      if (accessToken && refreshToken && idToken) {
        const userInfo = await decodeJwt(accessToken)
        const userdetails = await decodeJwt(idToken)
        LocalStorage.setItem('userInfo', userInfo)
        const user = {
          info: {
            id: userdetails.sub,
            org: userInfo.ownerId,
            name: userdetails.name,
            email: userdetails.email
          },
          type: userInfo.type,
          role: userInfo.role
        }
        LocalStorage.setItem('user', user)
        dispatch(setUserInfo(user.info))
        dispatch(setUserType(user.type))
        dispatch(setUserRole(user.role))
        await auth.signin({ access: accessToken, refresh: refreshToken, id: idToken }, () => navigate(from, { replace: true }))
      } else {
        await auth.reload(() => navigate(from, { replace: true }))
      }
    }
    mountEffect()
  }, [])

  return (
    <Wrapper>
      <LeftPanel>
        <LogoImg color="#286EB4" />
        <MidContainer magicLinkFeatureStatus={magicLinkFeatureStatus?.on} >
          { platform === 'admin' && <AdminLogin /> }
          { platform === 'advertiser' && <AdvertiserLogin magicLinkFeatureStatus={magicLinkFeatureStatus} /> }
        </MidContainer>
        <CopyrightWrapper>
          <CopyrightText>Copyright TLYNT { new Date().getFullYear() }, All rights reserved.</CopyrightText>
        </CopyrightWrapper>
      </LeftPanel>
      <RightPanel>
        <MainGrid container>
          <FirstGrid item xs={12}>
            <MaxHeightGridContainer container>
              <MaxHeightGridContainer item xs={4}>
                <MaxHeightGridContainer container>
                  <LightBlueBgGrid item xs={12}>
                    <MixBlendModeMaxSizeImg src={Images.loginPiece1} loading="lazy" alt={Images.loginPiece1} />
                  </LightBlueBgGrid>
                  <LightBlueBgGrid item xs={12}>
                    <MixBlendModeMaxSizeImg src={Images.loginPiece2} loading="lazy" alt={Images.loginPiece2} />
                  </LightBlueBgGrid>
                </MaxHeightGridContainer>
              </MaxHeightGridContainer>
              <MaxHeightGridContainer item xs={4}>
                <MaxHeightGrid item xs={12}>
                  <MaxSizeImg src={Images.loginPiece3} loading="lazy" alt={Images.loginPiece3} />
                </MaxHeightGrid>
              </MaxHeightGridContainer>
              <MaxHeightGridContainer item xs={4}>
                <MaxHeightGridContainer container>
                  <LightPinkBgGrid item xs={12}>
                    <MixBlendModeMaxSizeImg src={Images.loginPiece4} loading="lazy" alt={Images.loginPiece4} />
                  </LightPinkBgGrid>
                  <HalfHeightGrid item xs={12}>
                    <MaxSizeImg src={Images.loginPiece5} loading="lazy" alt={Images.loginPiece5} />
                  </HalfHeightGrid>
                </MaxHeightGridContainer>
              </MaxHeightGridContainer>
            </MaxHeightGridContainer>
          </FirstGrid>
          <SecondGrid item xs={12}>
            <MaxHeightGridContainer container>
              <MaxHeightGridContainer item xs={4}>
                <MaxHeightGridContainer container>
                  <MaxHeightGrid item xs={12}>
                    <MaxSizeImg src={Images.loginPiece6} loading="lazy" alt={Images.loginPiece6} />
                  </MaxHeightGrid>
                </MaxHeightGridContainer>
              </MaxHeightGridContainer>
              <MaxHeightGridContainer item xs={4}>
                <MaxHeightGridContainer container>
                  <LightGoldBgGrid item xs={12}>
                    <MixBlendModeMaxSizeImg src={Images.loginPiece7} loading="lazy" alt={Images.loginPiece7} />
                  </LightGoldBgGrid>
                </MaxHeightGridContainer>
              </MaxHeightGridContainer>
              <MaxHeightGridContainer item xs={4}>
                <MaxHeightGridContainer container>
                  <LightOrangeBgGrid item xs={12}>
                    <MixBlendModeMaxSizeImg src={Images.loginPiece8} loading="lazy" alt={Images.loginPiece8} />
                  </LightOrangeBgGrid>
                </MaxHeightGridContainer>
              </MaxHeightGridContainer>
            </MaxHeightGridContainer>
          </SecondGrid>
        </MainGrid>
      </RightPanel>
    </Wrapper>
  )
}

Login.propTypes = {
  admin: PropTypes.bool
}

export default Login
