import { useMutation } from '@apollo/client'
import { CLEAR_SELECTION_VOTE, SELECTION_VOTE_LIST } from '../../../services/API'

const useClearVoteSelectionMutation = (props) => {
  const {
    activeSelectId
  } = props
  const [ClearSelectionVote, { loading }] = useMutation(CLEAR_SELECTION_VOTE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    refetchQueries: () => {
      return [{
        query: SELECTION_VOTE_LIST,
        variables: {
          selectId: activeSelectId
        },
        fetchPolicy: 'no-cache'
      }]
    }
  })
  return [ClearSelectionVote, { loading }]
}

export default useClearVoteSelectionMutation
