import { useState } from 'react'

import { Field } from 'formik'

import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { Autocomplete, Chip, createFilterOptions } from '@mui/material'

import { Images } from '../../../assets'
import { Colors } from '../../../constants'

const MultiSelectForTags = props => {
  const filter = createFilterOptions()
  const { label, name, options, data, freeSolo = true, ...rest } = props

  const [open, setOpen] = useState(false)
  return (
    <div>
      <Field id={name} name={name} {...rest}>
        {fieldProps => {
          const { field, meta, form } = fieldProps
          const { value } = field
          const { setFieldValue } = form
          const { error, touched } = meta
          const errorText = error && touched ? error : ''
          return (
            <Autocomplete
              multiple
              id="tags-filled"
              value={value ?? []}
              onChange={(x, emails) => setFieldValue(name, emails)}
              options={data}
              freeSolo={freeSolo}
              open={open}
              onOpen={() => setOpen(!!(data.length > 0))}
              onClose={() => setOpen(false)}
              style={{ marginBottom: 20 }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.label}
                    clickable
                    style={{
                      background: Colors.black,
                      color: Colors.white,
                      marginRight: 10,
                      marginBottom: 5
                    }}
                    deleteIcon={
                      <img
                        onMouseDown={e => e.stopPropagation()}
                        src={Images.grayCross}
                        style={{ height: 20, width: 20 }}
                      />
                    }
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  error={error && touched}
                  helperText={errorText}
                  onChange={(e) => setOpen(!!e.target.value.length > 0)}
                />
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options?.some(option => inputValue === option)
                if (inputValue !== '' && !isExisting && freeSolo) {
                  filtered.push(`${inputValue}`)
                }
                return filtered
              }}
            />
          )
        }}
      </Field>
    </div>
  )
}

export default MultiSelectForTags

MultiSelectForTags.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  data: PropTypes.array,
  freeSolo: PropTypes.bool
}
