import { useMutation } from '@apollo/client'
import AdvertiserUserAPI from '../../../services/AdvertiserUser/AdvertiserUserAPI'

export default function useDeleteUserMutation () {
  const [deleteUserMutation, { loading }] = useMutation(
    AdvertiserUserAPI.deleteUser(),
    {
      notifyOnNetworkStatusChange: true
    }
  )
  return [deleteUserMutation, { loading }]
}
