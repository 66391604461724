import { Alert, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import classNames from 'classnames'
import type { ChangeEvent } from 'react'
import type { RadioProps } from '@mui/material'

import { InputBox } from '../InputBox'
import { RenderIf } from '../../utils/Helpers'
import Play from '../../assets/images/UGCProject/Play.svg'
import style from './UGCCheckboxInputRow.module.scss'

interface UGCCheckboxInputRowProps {
  title?: string
  rowTitle?: string
  extClassNames?: string[]
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
  name?: string
  isChecked: boolean
  onCheckboxClick: (data: boolean) => void
  withLengthSuffix?: boolean
  labels?: string[]
  errorText?: string | undefined
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  warnOnNonSelection?: boolean
  warningText?: string
  InputLabel?: string
}

export const UGCCheckboxInputRow = (props: UGCCheckboxInputRowProps): JSX.Element => {
  const { title, rowTitle = '', extClassNames = [], onChange, value, name, isChecked = true, onCheckboxClick, withLengthSuffix = true, labels = ['Yes', 'No, Talent Choice'], errorText, onBlur, warnOnNonSelection = false, warningText = '', InputLabel = 'Script' } = props

  const StyledRadio = (props: RadioProps): JSX.Element => {
    return (
      <Radio
        color='default'
        checkedIcon={<CheckBoxIcon className={classNames(style.RadioInput__Icon)} />}
        icon={<CheckBoxOutlineBlankIcon className={classNames(style.RadioInput__Icon)} />}
        {...props}
      />
    )
  }

  return (
    <div className={classNames(style.UGCCheckboxInputRow, rowTitle !== '' ? style['UGCCheckboxInputRow--RowTitleHidden'] : '', ...extClassNames)}>
      <RenderIf isTrue={rowTitle !== '' && rowTitle !== undefined}>
        <div className={style.UGCCheckboxInputRow__TitleContainer}>
          <img src={Play} />
          <p className={style.UGCCheckboxInputRow__TitleContainerText}>{rowTitle}</p>
        </div>
        <div className={style.UGCCheckboxInputRow__Separator} />
      </RenderIf>
      <div className={classNames(style.UGCCheckboxInputRow__DescriptionContainer, (rowTitle === '') ? style['UGCCheckboxInputRow__DescriptionContainer--CountHidden'] : '')}>
        <p className={style.UGCCheckboxInputRow__DescriptionTitle}>{title}</p>
        <div className={style.UGCCheckboxInputRow__DescriptionRow}>
          <div className={style.UGCCheckboxInputRow__DescriptionCheckboxContainer}>
            <RadioGroup value={isChecked} row onMouseDown={(e) => { onCheckboxClick((e?.target as HTMLInputElement).value === 'true') } } >
              <FormControlLabel control={<StyledRadio />} value={true} label={labels[0]} />
              <FormControlLabel control={<StyledRadio />} value={false} label={labels[1]} />
            </RadioGroup>
          </div>
        </div>
        <RenderIf isTrue={isChecked}>
          <div className={style.InputContainer}>
            <InputBox onBlur={onBlur} isError={errorText !== undefined} errorText={errorText} isMultiline name={name} value={value} onChange={onChange} label={InputLabel} withLengthSuffix={withLengthSuffix} acceptedLength={2048} />
          </div>
        </RenderIf>
        <RenderIf isTrue={warnOnNonSelection && !isChecked}>
          <Alert severity='warning' className={style.UGCCheckboxInputRow__Alert} >{warningText}</Alert>
        </RenderIf>
      </div>
    </div>
  )
}
