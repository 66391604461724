import { type ReactNode, useReducer, createContext, type Dispatch } from 'react'

export interface IDeliverables {
  initialState: boolean
  main: Main
  audition: Audition
  referenceLinks: string[]
  introHooks: IntroHooks
  bRoll: BRoll
  photo: Photo
  directions: Directions
}

export interface IDeliverablesActions {
  initialState?: boolean
  main?: Main
  audition?: Audition
  referenceLinks?: string[]
  introHooks?: IntroHooks
  bRoll?: BRoll
  photo?: Photo
  directions?: Directions
}

type Directions = Record<string, DirectionsSpecs>

interface Photo {
  photoCount: number
  projectDeliverable: {
    type: string
    mediaType: string
    specs: Array<{ value: string, additionalNote: string, id: null }>
  }
}

interface BRoll {
  projectDeliverable: ProjectDeliverableSpecs
}

interface IntroHooks {
  introHooksCount: number
  projectDeliverable: ProjectDeliverable
}

interface Audition {
  id: null | string
  specs: Script[]
}

interface Main {
  videoCount: number
  projectDeliverable: ProjectDeliverable
  deliverableSpec: DeliverableSpec
}

interface DeliverableSpec {
  deliverableType: string
  duration: number | null
  orientation: string
  specs: string[]
}

interface ProjectDeliverable {
  type: string
  mediaType: string
  script: Script[]
}

interface ProjectDeliverableSpecs {
  type: string
  mediaType: string
  specs: Script
  ids?: string[] | null | string
  id?: null | string
}

interface Script {
  isChecked: boolean
  value: string[]
  id?: null | string
}

interface DirectionsSpecs {
  value: string
  additionalNote: string
  id?: null | string
}

const deliverableInitialData = {
  initialState: true,
  main: {
    videoCount: 1,
    projectDeliverable: {
      type: 'main',
      mediaType: 'video',
      script: [{ isChecked: true, value: [''], id: null }]
    },
    deliverableSpec: {
      deliverableType: 'main',
      duration: null,
      orientation: 'portrait',
      specs: ['']
    }
  },
  referenceLinks: [],
  audition: {
    id: null,
    specs: [{ isChecked: false, value: [''], id: null }]
  },
  introHooks: {
    introHooksCount: 3,
    projectDeliverable: {
      type: 'intro_hooks',
      mediaType: 'video',
      script: [{ isChecked: true, value: [''], id: null }, { isChecked: true, value: [''], id: null }, { isChecked: true, value: [''], id: null }]
    }
  },
  bRoll: {
    projectDeliverable: {
      id: null,
      type: 'b_roll',
      mediaType: 'video',
      specs: { isChecked: true, value: [''], id: null }
    }
  },
  photo: {
    photoCount: 3,
    projectDeliverable: {
      type: 'photo',
      mediaType: 'photo',
      specs: [{ value: '', additionalNote: '', id: null }, { value: '', additionalNote: '', id: null }, { value: '', additionalNote: '', id: null }]
    }
  },
  directions: {
    wardrobeSpecs: { value: '', additionalNote: '', id: null },
    lightingSpecs: { value: '', additionalNote: '', id: null },
    locationSpecs: { value: '', additionalNote: '', id: null },
    framingSpecs: { value: '', additionalNote: '', id: null }
  }
}
export const DeliverablesContext = createContext<[IDeliverables, Dispatch<IDeliverablesActions>]>([deliverableInitialData, () => { }])

interface DeliverableContextDecoratorProps {
  children: ReactNode
}

export const DeliverableProvider = ({ children }: DeliverableContextDecoratorProps): JSX.Element => {
  const [deliverableData, updateDeliverableData] = useReducer((prev: IDeliverables, next: IDeliverablesActions) => ({ ...prev, ...next }), { ...deliverableInitialData })

  return (
    <DeliverablesContext.Provider value={[deliverableData, updateDeliverableData]}>
      {children}
    </DeliverablesContext.Provider>)
}
