import * as z from 'zod'

export const directionsValidationSchema = z.object({
  wardrobeSpecs: z.object({
    value: z.string(),
    note: z.string()
  }).refine(data => { return data.value !== 'Other' || data.note.length >= 1 }, { message: 'ADDITIONAL_NOTE_REQUIRED' }),
  lightingSpecs: z.object({
    value: z.string(),
    note: z.string()
  }).refine(data => { return data.value !== 'Other' || data.note.length >= 1 }, { message: 'ADDITIONAL_NOTE_REQUIRED' }),
  locationSpecs: z.object({
    value: z.string(),
    note: z.string()
  }).refine(data => { return data.value !== 'Other' || data.note.length >= 1 }, { message: 'ADDITIONAL_NOTE_REQUIRED' }),
  framingSpecs: z.object({
    value: z.string(),
    note: z.string()
  }).refine(data => { return data.value !== 'Other' || data.note.length >= 1 }, { message: 'ADDITIONAL_NOTE_REQUIRED' })
})

export const deliverableValidationSchema = z.object({
  duration: z.number({ required_error: 'Duration is required' }),
  main: z.array(z.object({
    isChecked: z.boolean(),
    value: z.array(z.string())
  }).refine((data) => data.isChecked ? data.value[0] !== '' : true, { message: 'This field is required', path: ['value'] })),
  audition: z.array(z.object({
    isChecked: z.boolean(),
    value: z.array(z.string())
  }).refine((data) => data.isChecked ? data.value[0] !== '' : true, { message: 'This field is required', path: ['value'] })),
  introHooks: z.array(z.object({
    isChecked: z.boolean(),
    value: z.array(z.string())
  }).refine((data) => data.isChecked ? data.value[0] !== '' : true, { message: 'This field is required', path: ['value'] })),
  bRoll: z.object({
    isChecked: z.boolean(),
    value: z.array(z.string())
  }).refine((data) => data.isChecked ? data.value[0] !== '' : true, { message: 'This field is required', path: ['value'] }),
  photo: z.array(z.object({
    value: z.string(),
    additionalNote: z.string()
  }).refine((data) => data.value === 'Other' ? data.additionalNote !== '' : true, { message: 'This field is required', path: ['value'] }))
})

export const talentValidationSchema = z.object({
  numberOfCreators: z.number().min(1),
  compensation: z.number().optional(),
  hasTalentProfile: z.string().nonempty('This field is required.'),
  profile: z.array(
    z.object({
      name: z.string().nonempty('This field is required.'),
      quantity: z.number().min(1),
      ageRanges: z.array(z.string()).nullable().optional(),
      gender: z.array(z.string()).nullable().optional(),
      ethnicity: z.array(z.string()).nullable().optional(),
      description: z.string().nullable().optional()
    })
  ).optional()
}).nonstrict().refine((data) => {
  if (data.hasTalentProfile === 'false') return true
  const numberOfCreators = data.numberOfCreators
  const totalQuantity = data.profile?.reduce((acc, profile) => acc + profile.quantity, 0) ?? 0

  return totalQuantity === numberOfCreators
}, {
  message: 'Number of talent should be equal to the total number of profiles.',
  path: ['numberOfCreators']
})

export const ugcOverviewSidebarData = [
  {
    title: 'Project Details',
    route: 'project-detail'
  },
  {
    title: 'Directions',
    route: 'directions'
  },
  {
    title: 'Deliverables',
    route: 'deliverables'
  },
  {
    title: 'Talent',
    route: 'talent'
  },
  {
    title: 'Product',
    route: 'product'
  }
]

export const directionOptions = {
  wardrobe: [[
    { id: 'casual', value: 'Casual', label: 'Casual' },
    { id: 'comfy', value: 'Comfy', label: 'Comfy' },
    { id: 'formal', value: 'Formal', label: 'Formal' }
  ], [
    { id: 'business', value: 'Business', label: 'Business' },
    { id: 'lounge', value: 'Lounge', label: 'Lounge' },
    { id: 'athletic', value: 'Athletic', label: 'Athletic' }
  ], [
    { id: 'no_preference', value: 'No Preference', label: 'No Preference' },
    { id: 'other', value: 'Other', label: 'Other' }
  ]],
  location: [[
    { id: 'indoors', value: 'Indoors', label: 'Indoors' },
    { id: 'outdoors', value: 'Outdoors', label: 'Outdoors' },
    { id: 'kitchen', value: 'Kitchen', label: 'Kitchen' }
  ], [
    { id: 'living', value: 'Living Room', label: 'Living Room' },
    { id: 'bathroom', value: 'Bathroom', label: 'Bathroom' },
    { id: 'car', value: 'Car', label: 'Car' }
  ], [
    { id: 'yard', value: 'Yard', label: 'Yard' },
    { id: 'street', value: 'Street', label: 'Street' },
    { id: 'no_preference', value: 'No Preference', label: 'No Preference' }
  ], [
    { id: 'other', value: 'Other', label: 'Other' }
  ]],
  lighting: [[
    { id: 'daylight', value: 'Daylight', label: 'Daylight' },
    { id: 'evening_night', value: 'Evening/Night (artificial lighting)', label: 'Evening/Night (artificial lighting)' },
    { id: 'morning', value: 'Morning', label: 'Morning' }
  ], [
    { id: 'mid-day', value: 'Mid-day', label: 'Mid-day' },
    { id: 'afternoon', value: 'Afternoon', label: 'Afternoon' },
    { id: 'golden_hour', value: 'Golden Hour', label: 'Golden Hour' }
  ], [
    { id: 'no_preference', value: 'No Preference', label: 'No Preference' },
    { id: 'other', value: 'Other', label: 'Other' }
  ]],
  framing: [[
    { id: 'face', value: 'Face', label: 'Face' },
    { id: 'portrait', value: 'Portrait', label: 'Portrait' },
    { id: 'full_body', value: 'Full Body', label: 'Full Body' }
  ], [
    { id: 'no_preference', value: 'No Preference', label: 'No Preference' },
    { id: 'other', value: 'Other', label: 'Other' }
  ]]
}

export const talentOptions = [
  {
    id: 1,
    value: 'true',
    label: "Yes, I'd like to specify"
  },
  {
    id: 2,
    value: 'false',
    label: 'No preference'
  }
]

export const talentAgeOptions = [
  {
    id: 1,
    name: 'any',
    label: 'any'
  },
  {
    id: 2,
    name: '18-24',
    label: '18-24'
  },
  {
    id: 3,
    name: '25-29',
    label: '25-29'
  },
  {
    id: 4,
    name: '30-39',
    label: '30-39'
  },
  {
    id: 5,
    name: '40-49',
    label: '40-49'
  },
  {
    id: 6,
    name: '50-59',
    label: '50-59'
  },
  {
    id: 7,
    name: '60-100',
    label: '60+'
  }
]

export const talentGenderOptions = [
  { id: 1, name: 'any', label: 'Any' },
  { id: 2, name: 'Man', label: 'Man' },
  { id: 3, name: 'Woman', label: 'Woman' },
  { id: 4, name: 'Non-Binary Person', label: 'Non-Binary Person' },
  { id: 5, name: 'Trans Man', label: 'Trans Man' },
  { id: 6, name: 'Trans Woman', label: 'Trans Woman' }
]

export const stillImageOptions = [
  { label: "Talent's choice", id: 'talent_choice', name: "Talent's choice" },
  { label: 'Product shot', id: 'product_shot', name: 'Product shot' },
  { label: 'Selfie with product', id: 'selfie_with_product', name: 'Selfie with product' },
  { label: 'Before shot', id: 'before_shot', name: 'Before shot' },
  { label: 'After shot', id: 'after_shot', name: 'After shot' },
  { label: 'Close up headshot', id: 'close_up_headshot', name: 'Close up headshot' },
  { label: 'Full body shot', id: 'full_body_shot', name: 'Full body shot' },
  { label: 'UI/Screen', id: 'ui_screen', name: 'UI/Screen' },
  { label: 'other', id: 'other', name: 'Other' }
]

export const viewProjectTabData = [
  { id: 0, title: 'Overview', tabRoute: 'overview' },
  { id: 1, title: 'Talent', tabRoute: 'talent' },
  { id: 2, title: 'Assets', tabRoute: 'assets' }
]
