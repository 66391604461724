import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import { uploadMedia } from '../../services/RestAPI'
import { showToast } from '../../redux/actions/DashBoardActions'
import { useCreateMediaMutation } from '../../hooks/Media/useCreateMediaMutation'

import style from './media.module.scss'

interface MediaFile {
  preview: string
  type: 'photo' | 'video'
  file: File
}

/**
 * Handler to update React State when a file is selected
 * @param e Original HTML Change Event
 * @param setFile React SetStateAction to save the file, a preview blob url and a coerced type based on content type
 * @param setType React SetStateAction to save the coerced type of the file based on content type
 */
const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: (data: MediaFile) => void, setType: (data: 'photo' | 'video') => void): void => {
  const files = e.target.files
  const file = files?.[0]

  if (file !== undefined) {
    const contentType = file.type.split('/')[0]
    if (contentType !== 'image' && contentType !== 'video') {
      throw Error('Invalid file type')
    }

    setFile({
      preview: URL.createObjectURL(file),
      type: contentType === 'image' ? 'photo' : 'video',
      file
    })
    setType(contentType === 'image' ? 'photo' : 'video')
  }
}

/**
 * Handler to blindly update React State when a select is changed
 * @param e Original HTML Select Change Event
 * @param setter React SetStateAction to save the selected option
 */
const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, setter: (data: any) => void): void => {
  setter(e.target.value)
}

/**
 * Handler to blindly update React State when a text input is changed
 * @param e Original HTML Input Change Event
 * @param setter React SetStateAction to save the updated value
 */
const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, setter: (data: any) => void): void => {
  setter(e.target.value)
}

interface UploadHandlers {
  showError: () => void
  showSuccess: () => void
  createMedia: (data: any, onSuccess: any) => void
  setMediaId: (data: string) => void
}

/**
 * Handler to create the media object on the database after the file is uploaded
 * @param upload Data returned by the uploadMedia service on success
 * @param metadata Metadata object containing the type, category and entity ID
 * @param handlers Object containing functions to show error and success toasts, set the media ID and create the media object
 */
const handleUploadSuccess = async (upload: any, metadata: any, handlers: UploadHandlers): Promise<void> => {
  console.log('upload media', upload)

  const opts = {
    filename: upload.item?.filename,
    mediaCreateId: upload.item?.id,
    type: metadata.type,
    category: metadata.category,
    orgId: undefined,
    talentId: undefined
  }

  if (metadata.category === 'organization') {
    opts.orgId = metadata.entityID
  }
  if (metadata.category === 'talent') {
    opts.talentId = metadata.entityID
  }

  handlers.createMedia(opts, (data: any) => { console.log('create media', data); handlers.setMediaId(data); handlers.showSuccess() })
}

/**
 * Handler that create a FormData object and calls the uploadMedia service on form submit
 * @param e Original HTML Form Submit Event
 * @param media ReactState representing the selected file
 * @param type ReactState representing the coerced file type
 * @param category ReactState representing the selected category
 * @param entityID ReactState representing the input entity ID
 * @param handlers Object containing functions to show error and success toasts, set the media ID and create the media object
 */
const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, media: MediaFile | null, type: 'photo' | 'video', category: 'organization' | 'talent', entityID: string, handlers: UploadHandlers): Promise<void> => {
  e.preventDefault()
  e.stopPropagation()

  if (media === null) {
    throw Error('No media file selected')
  }

  const opts = {
    filetype: media?.file?.type,
    type,
    category,
    entityID
  }

  const formdata = new FormData()
  formdata.append('type', type)
  formdata.append('filename', media.file, media.file.name)
  formdata.append('extension', `.${media?.file?.type.split('/')?.[1]}`)
  formdata.append('filetype', media?.file?.type)
  formdata.append('category', category)
  if (category === 'organization') {
    formdata.append('orgId', entityID)
  }
  if (category === 'talent') {
    formdata.append('talentId', entityID)
  }

  await uploadMedia(formdata, (data: any) => { void handleUploadSuccess(data, opts, handlers) }, (error: any) => { console.error(error); handlers.showError() }, (progress: any) => { console.info(progress) }, media.file.name, media)
}

const PreviewMedia = ({ file }: { file: MediaFile | null }): JSX.Element => {
  if (file?.type === 'photo') {
    return <div className={style.Preview}><img src={file.preview} alt='' /></div>
  }

  if (file?.type === 'video') {
    return <div className={style.Preview}><video src={file.preview} controls /></div>
  }

  return <></>
}

export const ToolsMedia = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.UserReducer)

  if (user.isAdmin === false) {
    navigate('/404/')
    return <></>
  }

  const [createMedia, { loading: uploadMediaLoading }] = useCreateMediaMutation() as unknown as [any, { loading: boolean }]

  const [media, setMedia] = useState<MediaFile | null>(null)
  const [type, setType] = useState<'photo' | 'video'>('photo')
  const [category, setCategory] = useState<'organization' | 'talent'>('organization')
  const [entityID, setEntityID] = useState<string>('')

  const [mediaId, setMediaId] = useState<string>('')

  const showError = (): void => { dispatch(showToast({ isError: true, message: 'Something went wrong.' })) }
  const showSuccess = (): void => { dispatch(showToast({ message: 'Media uploaded successfully.' })) }

  return (
    <Layout>
      <form onSubmit={(e) => { void handleSubmit(e, media, type, category, entityID, { showError, showSuccess, createMedia, setMediaId }) }}>
        <fieldset>
          <select name='type' onChange={(e) => { handleSelectChange(e, setType) }} value={type}>
            <option value='photo'>Photo</option>
            <option value='video'>Video</option>
          </select>

          <select name='category' onChange={(e) => { handleSelectChange(e, setCategory) }} value={category}>
            <option value='organization'>Organization</option>
            <option value='talent'>Talent</option>
          </select>
        </fieldset>

        <fieldset>
          <input type='text' name='entityID' placeholder={`${category[0].toUpperCase()}${category.slice(1).toLowerCase()} ID`} onChange={(e) => { handleTextChange(e, setEntityID) }} value={entityID} required />
        </fieldset>

        <fieldset>
          <input onChange={(e) => { handleFileChange(e, setMedia, setType) }} name='file' type='file' required />
        </fieldset>

        <fieldset>
          <input type='submit' value='Upload' />
        </fieldset>
      </form>

      <PreviewMedia file={media} />

      { uploadMediaLoading && <div className={style.Loading}>Uploading...</div> }

      { mediaId !== '' && <div className={style.MediaId}>Media ID: {mediaId}</div> }
    </Layout>
  )
}
