import { useState, useEffect } from 'react'

import { Box } from '@mui/system'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { CircularProgress, Typography } from '@mui/material'

import { ProgressBar } from '..'
import { Images } from '../../assets'
import { API, Colors } from '../../constants'
import { RenderIf } from '../../utils/Helpers'

const ImageContainerWrapper = styled.div`
  cursor: pointer;
`

const BottomRow = styled.div`
  display: ${props => (props.isError ? 'flex' : 'none')};
  flex-direction: row;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 35px;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 15px;
  zindex: 100;

  flex: 1;
  background: transparent;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.64) 92%
  );
`

const ProgressBarView = styled.div`
  display: ${props => (props.isUploading ? 'flex' : 'none')};
  flex-direction: row;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 8px;

  progress[value] {
    height: 10px;
    appearance: none;
    ::-webkit-progress-bar {
      height: 3.5px;
      border-radius: 20px;
      align-self: center;
      background-color: ${Colors.borderGray};
    }
    ::-webkit-progress-value {
      height: 3.5px;
      border-radius: 20px;
      background-color: ${Colors.secondaryBlue};
    }
  }
`
const IndicatorWrapper = styled.div`
  height: 50px;
  width: 100px;
  position: absolute;
  bottom: 45px;
  bottom: 10px;
  left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
`

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  &:hover ${IndicatorWrapper} {
    display: flex;
  }
`

const ErrorImg = styled.img`
  display: flex;
  height: 12.73px;
  color: red;
  z-index; 10;
  margin-left: 13px;
  margin-right: 9px;
`

const GridImage = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.isUploading ? '0.24' : 'none')};
`

const GridImageView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  zindex: 1000;
  display: grid;
  width: 100%;
  place-items: center;
`

const IndicatorBox = styled.div`
  font-size: 10px;
  height: 22px;
  width: 100px;
  color: ${Colors.white};
  background: ${Colors.errorRed};
  border-radius: 4px;
  display: grid;
  place-items: center;
`
const LikeIndicatorTriangle = styled.div`
  height: 0;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid ${Colors.errorRed};
`

export const ImageContainer = (props) => {
  const [originalImage, setOriginalImage] = useState('')
  const [isHEICLoaded, setIsHEICLoaded] = useState(false)
  const {
    isError,
    isUploading,
    images,
    onClick,
    data,
    progressValue = 0,
    handleRetryMedia,
    isUploaded,
    isExceedSizeLimit,
    handleRemoveImage,
    incImageLoadedCount
  } = props

  useEffect(() => {
    setOriginalImage('')
  }, [data])
  return (
    <ImageContainerWrapper>
      <GridImageView>
        <GridImage
          onLoad={() => {
            incImageLoadedCount()
            images && setIsHEICLoaded(true)
          }}
          onClick={onClick}
          onError={() => {
            if (typeof data?.file === 'object') {
              // handling local image
              if (data?.file?.name?.includes('.heic')) {
                setOriginalImage(data?.uris?.full)
              } else setOriginalImage(data?.file?.preview)
            } else {
              // handling external image
              if (data?.file?.includes('.heic')) {
                setOriginalImage(`${data?.uris?.full}}`)
                setIsHEICLoaded(false)
              } else {
                setOriginalImage(`${API.baseUrl}/${data?.file}`)
              }
            }
          }}

          src={!images ? Images.imagePlaceholder : originalImage || images}
          isUploading={isUploading || !isUploaded || isExceedSizeLimit}
        />
        { ((typeof data?.file === 'object' ? data?.file?.name?.includes('.heic') : data?.file?.includes('.heic')) && !isHEICLoaded && !isUploading && isUploaded) &&
        <Box position='absolute'>
          <GridImage src={Images.imagePlaceholder} />
          <SpinnerWrapper>
            <CircularProgress sx={{ color: 'black' }} size={25} />
          </SpinnerWrapper>
        </Box>
        }
        <RenderIf isTrue={isUploading}>
          <ProgressBarView isUploading={isUploading}>
            <ProgressBar value={progressValue} />
          </ProgressBarView>
        </RenderIf>
        <RenderIf isTrue={(!isUploaded && !isUploading)}>
          <SpinnerWrapper>
          <CircularProgress sx={{ color: 'black' }} size={25} />
          </SpinnerWrapper>
        </RenderIf>
        <BottomRow isError={isError || isExceedSizeLimit}>
          <ErrorWrapper>
            <IndicatorWrapper>
              <IndicatorBox>
                <Typography fontSize={8} fontWeight={800} >
                {isExceedSizeLimit
                  ? 'File size is more than 5MB'
                  : 'Try again later'}
                </Typography>
              </IndicatorBox>
              <LikeIndicatorTriangle />
            </IndicatorWrapper>
            <ErrorImg src={Images.errorIcon} />
            <Typography color={Colors.errorRed} fontSize={12}>Error</Typography>
          </ErrorWrapper>
          <Typography
           color={Colors.secondaryBlue}
           mr={2}
           fontSize={12}
           onClick={isExceedSizeLimit ? handleRemoveImage : handleRetryMedia}>
             {isExceedSizeLimit ? 'Remove' : 'Retry'}
          </Typography>
        </BottomRow>
      </GridImageView>
    </ImageContainerWrapper>
  )
}

ImageContainer.propTypes = {
  images: PropTypes.string,
  onClick: PropTypes.func,
  progressValue: PropTypes.number,
  isUploading: PropTypes.bool,
  isError: PropTypes.bool,
  handleRetryMedia: PropTypes.func,
  isUploaded: PropTypes.bool,
  isExceedSizeLimit: PropTypes.bool,
  handleRemoveImage: PropTypes.func,
  incImageLoadedCount: PropTypes.func,
  data: PropTypes.object
}
