import { Form, Formik, type FormikProps } from 'formik'
import { Switch, TextField } from '@mui/material'
import { useRef, useState } from 'react'
import classNames from 'classnames'

import { RenderIf } from '../../utils/Helpers'
import { SecureSelectValidationSchema } from '../../services/ValidationServices'
import { SpinnerSmall } from '../Progress'
import style from './ShareSelect.module.scss'
import useSecureSelectMutation from '../../pages/Selects/Hooks/useSecureSelectMutation'

interface Props {
  selectId: string
  publicSelectURL: string
  isCopied: boolean
  setIsCopied: (value: boolean) => void
  isSuccess: boolean
  setIsSuccess: (value: boolean) => void
}

const Formcomponent = ({ selectId, publicSelectURL, isCopied, setIsCopied, isSuccess, setIsSuccess }: Props): JSX.Element => {
  // hooks
  const formikRef = useRef<FormikProps<{ password: string }>>(null)
  const { secureSelect, loading } = useSecureSelectMutation()

  // state
  const [isProtected, setIsProtected] = useState(false)

  // functions
  const handleClipboardHandler = async (): Promise<void> => {
    await navigator.clipboard.writeText(publicSelectURL)

    setIsCopied(true)
    setTimeout(() => { setIsCopied(false) }, 2000)
  }

  const handleFormSubmit = async (data: { password: string }): Promise<void> => {
    const modifiedData = {
      secureSelectId: selectId,
      password: isProtected ? data.password : '',
      protect: isProtected
    }
    setIsCopied(false)
    await secureSelect(modifiedData, setIsSuccess)
  }

  const handleToggleSwitch = async (): Promise<void> => {
    setIsSuccess(false)
    setIsCopied(false)
    await formikRef?.current?.setFieldValue('password', '')
    setIsProtected(!isProtected)
  }

  return (
    <>
      <div className={style.Drawer__SwitchContainer}>
        Password Protection
        <Switch value={isProtected}
          color="success"
          onChange={() => { void handleToggleSwitch() }}
        />
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={{ password: '' }}
        validationSchema={isProtected ? SecureSelectValidationSchema : ''}
        validateOnMount
        validateOnChange
        enableReinitialize={true}
        onSubmit={handleFormSubmit}>
        {({ errors, touched, handleSubmit, values, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <TextField
                label='Add Password'
                type='text'
                variant='standard'
                name='password'
                value={values.password}
                onChange={(e) => {
                  handleChange(e)
                  setIsSuccess(false)
                }}
                fullWidth
                disabled={!isProtected}
                error={(touched.password ?? false) && (errors.password != null) && isProtected}
              />

              <RenderIf isTrue={(errors.password != null) && (touched.password ?? false) && isProtected}>
                <p className={style.Drawer__PasswordErrorText}>{errors.password}</p>
              </RenderIf>

              <RenderIf isTrue={!isSuccess}>
                <button type="submit" className={classNames(style.Drawer__GenerateLinkCTA, 'outline-primary-button')}>{loading && <SpinnerSmall />}Generate Link</button>
              </RenderIf>
            </Form>
          )
        }}
      </Formik>

      <RenderIf isTrue={isSuccess}>
        <div className={style.Drawer__CopyLinkCTAWrapper} >
          <button onClick={() => { void handleClipboardHandler() }} className='primary-button'>Copy Link</button>
          {isCopied && <span className={style.Drawer__CopyLinkAlertText}>Link Copied to clipboard</span>}
        </div>
      </RenderIf>
    </>
  )
}

export default Formcomponent
