import { type ReactNode, createContext, useReducer } from 'react'

interface IProjectUGC {
  compensation: number
  numberOfCreators: number
  numberOfVideos: number
  owner: IAdvertiser
  endDate: string
  id: string
  name: string
  status: string
  type: string
}

interface IProjectCasting {
  owner: IAdvertiser
  name: string
  id: string
  roles: IRole[]
  startDate: string
  endDate: string
  type: string
  status: string
  postalCode: string
  countryCodeLocation: string
}

interface IRole {
  rate: number
}

interface IAdvertiser {
  name: string
  logo: ILogo
}

interface ILogo {
  uris: IUris
}

interface IUris {
  logo: string
}

type IProject = IProjectUGC | IProjectCasting

const projectListInitialData = {
  list: []
}

interface IAction {
  type: string
  payload: any
}

export const CREATE_LIST = 'CREATE_LIST'
export const UPDATE_LIST = 'UPDATE_LIST'

const ProjectListReducer = (state: any, action: IAction): any => {
  switch (action.type) {
    case CREATE_LIST:
      return {
        list: action.payload
      }
    case UPDATE_LIST:
      return {
        ...state,
        list: [...state.list, ...action.payload]
      }
    default:
      return state
  }
}

export const ProjectListContext = createContext([projectListInitialData, () => []])

export const ProjectListProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(ProjectListReducer, projectListInitialData)
  const value = {
    ...state,
    handleCreateList: (data: IProject[]) => {
      dispatch({ type: CREATE_LIST, payload: data })
    },
    handleUpdateList: (data: IProject[]) => {
      dispatch({ type: UPDATE_LIST, payload: data })
    }
  }
  return <ProjectListContext.Provider value={value}>{children}</ProjectListContext.Provider>
}
