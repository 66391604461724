class LocalStorage {
  getItem (key) {
    return JSON.parse(localStorage.getItem(key))
  }

  setItem (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  removeItem (key) {
    localStorage.removeItem(key)
  }

  resetLocalStorage () {
    localStorage.clear()
  }
}

export default new LocalStorage()
