import { useLazyQuery } from '@apollo/client'
import AdvertiserUserAPI from '../../../services/AdvertiserUser/AdvertiserUserAPI'

const useUserRoleList = () => {
  const [fetchUserRoleList, { data, loading }] = useLazyQuery(
    AdvertiserUserAPI.getUserRoleList(),
    {
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'no-cache'
    }
  )
  return [fetchUserRoleList, { data, loading }]
}

export default useUserRoleList
