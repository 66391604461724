import { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Images } from '../../../../assets'
import { Typography } from '../../../../components'
import { RenderIf } from '../../../../utils/Helpers'
import { useOutsideHandler } from '../../../../hooks'

const ResizeContainer = styled.div`
  border: 1px solid black;
  padding: 0px 20px 0px 10px;
  width: 80px;
  height: 42px;
  box-sizing: border-box;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  margin-left: 40px;
`

const MenuPopupBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  background: white;
  z-index: 1000;
  width: 178px;
  height: 126px;
  right: 25px;
  top:  30px;
  padding-left: 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
`

const ResizeListItem = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
`

const RESIZE_DATA = [
  {
    id: 0,
    key: 'SM',
    img: Images.resizeMinimal,
    title: 'Minimal'
  },
  {
    id: 1,
    key: 'MD',
    img: Images.resizeCompact,
    title: 'Compact'
  },
  {
    id: 2,
    key: 'LG',
    img: Images.resizeMaximum,
    title: 'Maximum'
  }
]

const ResizeModal = props => {
  // props
  const { resizeType, handleTalentCard } = props
  // states
  const [showResizeModal, setShowResizeModal] = useState(false)
  // hooks
  const popupRef = useRef(null)
  useOutsideHandler(popupRef, (e) => {
    setShowResizeModal(false)
  })

  const toggleResizeModal = (e) => {
    setShowResizeModal(!showResizeModal)
  }
  // functions
  const getResizeIcon = () => {
    switch (resizeType) {
      case 'SM':
        return Images.resizeMinimal
      case 'MD':
        return Images.resizeCompact
      case 'LG':
        return Images.resizeMaximum
    }
  }

  return (
    <div style={{ position: 'relative' }} ref={popupRef} >
      <ResizeContainer onClick={toggleResizeModal}>
        <img src={getResizeIcon()} height={14} />
        <img
          src={Images.resizeArrowdown}
          height={5}
          style={{
            alignSelf: 'center',
            cursor: 'pointer',
            rotate: showResizeModal ? '180deg' : '0deg'
          }}
        />
      </ResizeContainer>
      <RenderIf isTrue={showResizeModal}>
        <MenuPopupBox>
          {RESIZE_DATA.map(item => (
            <ResizeListItem
              key={item.id}
              onClick={() => handleTalentCard(item.key)}>
              <img src={item.img} height={16} style={{ marginRight: 10 }} />
              <Typography fontSize={16}>{item.title}</Typography>
            </ResizeListItem>
          ))}
        </MenuPopupBox>
      </RenderIf>
    </div>
  )
}

ResizeModal.propTypes = {
  resizeType: PropTypes.string,
  handleTalentCard: PropTypes.func
}

export default ResizeModal
