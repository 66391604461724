import { Tooltip as TooltipMUI } from '@mui/material'

interface DefaultErrorTooltipProps {
  children: React.ReactElement
  title: string
}

export const DefaultErrorTooltip = (props: DefaultErrorTooltipProps): JSX.Element => {
  const { children, title } = props
  return (
    <TooltipMUI PopperProps={{
      sx: {
        '& .MuiTooltip-tooltip': {
          color: 'white',
          backgroundColor: '#F44336'
        },
        '& .MuiTooltip-arrow': {
          color: '#F44336'
        }
      }
    }} title={title} arrow placement='top'>{children}</TooltipMUI>
  )
}
