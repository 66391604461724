import styled from 'styled-components'

import { Typography } from '@mui/material'

const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;

`

const NoTalentFoundPlaceholder = props => {
  return (
    <PlaceholderWrapper>
      <Typography fontSize={34} fontWeight='bold' letterSpacing='0.25px'>No talent found.</Typography>
    </PlaceholderWrapper>
  )
}

NoTalentFoundPlaceholder.propTypes = {}

export default NoTalentFoundPlaceholder
