import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseOutlined'

import Style from './Style'
import { Images } from '../../../../assets'
import { Colors } from '../../../../constants'
import { useDispatch, useStore } from 'react-redux'
import { MEDIA_CREATE } from '../../../../services/API'
import MediaAPI from '../../../../services/Media/MediaAPI'
import { uploadToS3, uploadMultipartToS3 } from '../../../../services/RestAPI'
import useMediaUploadMutation from '../../../../hooks/Media/useMediaUploadMutation'
import { MULTIPART_MIN_CHUNK_SIZE, PORTFOLIO_ALLOWED_VIDEO_EXTENSION } from '../../../../constants/PortfolioConstants'
import { setTalentVideoList, setUploadedVideoStatus, setVideoError } from '../../../../redux/actions/TalentVideoAction'

const UploadVideo = ({ handleShowUploadVideo, handleToggleUploadVideos, isUploaded = true }) => {
  // hooks
  const store = useStore()
  const dispatch = useDispatch()
  const { id: talentId } = useParams()

  const [mediaUpload] = useMediaUploadMutation()
  const [mediaCreate] = useMutation(MEDIA_CREATE)
  const [mediaUploadMutation] = useMutation(MediaAPI.createMultipartUpload(), { notifyOnNetworkStatusChange: true })
  // complete mulipart
  const [completeMultipartUpload] = useMutation(MediaAPI.CompleteMultipartUpload(), { notifyOnNetworkStatusChange: true })
  // abort mulipart
  const [abortMultipartUpload] = useMutation(MediaAPI.AbortMultipartUpload())

  // constants
  const allowdedExtension = PORTFOLIO_ALLOWED_VIDEO_EXTENSION
  const mediaStoreList = store.getState().TalentVideoReducer

  // parent upload wrapper
  const {
    getRootProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'video/*': allowdedExtension
    },
    noClick: true,
    noDrag: false,
    onDrop: (acceptedFiles) => onUploadVideo(acceptedFiles)
  })

  // browse upload wrapper
  const { getRootProps: getRootPropsChild, getInputProps: getChildInputProps, open } = useDropzone({
    noClick: true,
    accept: {
      'video/*': allowdedExtension
    },
    noDrag: false,
    noKeyboard: true,
    onDrop: (acceptedFiles) => onUploadVideo(acceptedFiles)
  })
  // handle uploaded videos
  const onUploadVideo = async (acceptedFiles) => {
    const videoList = []
    if (acceptedFiles.length > 0) {
      for (let i = 0; i < acceptedFiles.length; i++) {
        const src = URL.createObjectURL(acceptedFiles[i])
        const { name, size } = acceptedFiles[i]
        const fileExtension = '.' + (name.split('.')).pop()
        videoList.push({
          isUploading: true,
          isMultipart: size > MULTIPART_MIN_CHUNK_SIZE,
          progress: 0,
          preview: src,
          name: `${name}${Math.ceil((Math.random() * 10000000))}`,
          extension: fileExtension,
          file: acceptedFiles[i],
          isValid: !(size > 200000000) && allowdedExtension.includes(fileExtension.toLowerCase()),
          isExceedSizeLimit: size > 200000000,
          isValidExtension: allowdedExtension.includes(fileExtension.toLowerCase()),
          talentId
        })
      }
      handleShowUploadVideo(false)

      dispatch(setTalentVideoList([...videoList, ...mediaStoreList]))
      const mulipartVideos = videoList.filter(video => video.isMultipart)
      const smallVideos = videoList.filter(video => !video.isMultipart)
      if (smallVideos.length) {
        uploadVideos(smallVideos)
      }
      if (mulipartVideos.length) {
        uploadMultiParts(mulipartVideos)
      }
      handleToggleUploadVideos()
    }
  }
  // upload videos into S3
  const uploadVideos = async (videoData) => {
    for (let i = 0; i < videoData.length; i++) {
      const video = videoData[i]
      if (!video?.isExceedSizeLimit && allowdedExtension.includes(video.extension.toLowerCase())) {
        const uploadVideoResponse = await mediaUpload({
          talentId,
          type: 'video',
          extension: video.extension,
          category: 'talent'
        })
        await uploadToS3(
          { item: uploadVideoResponse?.data?.mediaUpload },
          successHandler,
          errorHandler,
          progressHandler,
          video.name,
          { file: video.file }
        )
      }
    }
  }
  // setting progress of uploaded video
  const progressHandler = (progress, filename) => {
    const mediaStoreList = store.getState().TalentVideoReducer
    const mediaListClone = [...mediaStoreList]
    const mediaIndex = mediaListClone.findIndex((item) => item?.name === filename)
    if (mediaIndex > -1) {
      mediaListClone[mediaIndex].progress = progress
    }
    if (progress === 100) {
      mediaListClone[mediaIndex].isUploading = false
    }
    dispatch(setTalentVideoList([...mediaListClone]))
  }
  // successfully upload video to server
  const successHandler = async (videoData, filename) => {
    await mediaCreate({
      variables: {
        filename: videoData?.item?.filename,
        mediaCreateId: videoData?.item?.id,
        type: 'video',
        talentId
      }
    }).then((response) => dispatch(
      setUploadedVideoStatus({
        filename,
        id: response.data.mediaCreate.id,
        uris: response?.data?.mediaCreate?.uris,
        processing: response?.data?.mediaCreate?.processing
      }))
    )
  }

  // START -- code block for multipart upload

  // error handler for upload videos
  const errorHandler = (errorMessage, filename) => {
    dispatch(setVideoError({ filename, errorMessage }))
  }
  // creating multiparts and uploads it to S3
  const uploadMultiParts = async (videos) => {
    // multipart response array
    for (let i = 0; i < videos.length; i++) {
      if (!videos[i]?.isExceedSizeLimit) {
        mediaUploadMutation({
          variables: {
            talentId,
            category: 'talent',
            type: 'video',
            size: videos[i]?.file.size,
            extension: '.' + videos[i]?.file.path.split('.').pop()
          }
        }).then(response => {
          convertToChunks(
            response?.data?.createMultipartUpload,
            videos[i].file,
            videos[i].name
          )
        }).catch(() => {
          errorHandler('Error in uploading file', videos[i].name)
        })
      }
    }
  }
  // convert large file to chunks and upload to S3
  const convertToChunks = async (response, file, name) => {
    const chunkSize = parseInt(response.chunkSize) + 1

    const chunks = []
    for (let i = 0; i < file.size; i += chunkSize) {
      const chunk = file.slice(i, i + chunkSize)
      chunks.push(chunk)
    }
    let completedPromise = 0
    await Promise.all(response.urls.map((urlObject) => {
      return uploadMultipartToS3(
        urlObject.url,
        chunks[parseInt(urlObject.partNumber) - 1],
        response.urls.length)
        .then(() => {
          completedPromise++
          const progress = (completedPromise / response.urls.length) * 100
          progressHandler(progress, name)
        })
    })).then(() => completeMultipartProcress(response, name))
      .catch(() => {
        abortMultipartUpload({
          variables: {
            key: response.key,
            uploadId: response.uploadId
          }
        })
        errorHandler('Error in uploading file', name)
      })
  }
  // complete multipart upload
  const completeMultipartProcress = async (response, name) => {
    await completeMultipartUpload({
      variables: {
        key: response.key,
        uploadId: response.uploadId
      }
    }).then(() => successMultipartHandler(response, name))
      .catch((err) => errorHandler(err, name))
  }
  // upload success multiparts
  const successMultipartHandler = async (videoData, filename) => {
    const mediaCreateResponse = await mediaCreate({
      variables: {
        filename: videoData?.filename,
        mediaCreateId: videoData?.id,
        type: 'video',
        talentId
      }
    })

    dispatch(
      setUploadedVideoStatus({
        filename,
        id: mediaCreateResponse.data.mediaCreate.id,
        uris: mediaCreateResponse?.data?.mediaCreate?.uris,
        processing: mediaCreateResponse?.data?.mediaCreate?.processing
      })
    )
  }
  // END -- code block for multipart upload

  return (
    <Style.UploadContainerChild isUploaded={isUploaded}
      {...getRootProps({
        className: 'dropzone',
        isFocused,
        isDragAccept,
        isDragReject,
        onClick: evt => evt.preventDefault()
      })}>
      <Style.CloseButtonWrapper onClick={() => handleShowUploadVideo(false)} isUploaded={isUploaded}>
        <CloseIcon fontSize='medium' />
      </Style.CloseButtonWrapper>
      <Box>
        <Typography fontSize={34} fontWeight={400} letterSpacing='0.25px' color={isUploaded ? 'white' : 'black'}>
          Upload your Videos
        </Typography>
        <Typography fontSize={16} letterSpacing='0.25px' fontWeight='bold' color={isUploaded ? 'white' : Colors.inactiveBlack} style={{ margin: '5px 0 10px 0' }}>
          Max 100 MB for each Video
        </Typography>
      </Box>
      <Box sx={{ background: Colors.backgroundGray, padding: '35px 170px', border: `1px dashed ${Colors.dashedBorderGray}`, mt: 2 }}>
        <div>
          <Style.ImgView src={Images.uploadIcon} />
          <Typography fontSize={14} lineHeight={'20px'}>
            Drag &amp; drop your files or
          </Typography>
          <Style.BrowseWrapper>
            <div {...getRootPropsChild({ className: 'dropzone' })}>
              <input {...getChildInputProps()} />
              <div type="button" onClick={open}>
                <Typography
                  fontWeight='bold'
                  fontSize={14}
                  style={{
                    marginRight: 5,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}>
                  Browse
                </Typography>
              </div>
            </div>
            <Typography fontSize={14}>from your computer</Typography>
          </Style.BrowseWrapper>
        </div>
      </Box>
    </Style.UploadContainerChild>
  )
}

export default UploadVideo

UploadVideo.propTypes = {
  isUploaded: PropTypes.bool,
  handleShowUploadVideo: PropTypes.func,
  handleToggleUploadVideos: PropTypes.func
}
