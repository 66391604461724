import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { showToast } from '../../../redux/actions/DashBoardActions'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

function useDeleteAdvertiserMutation () {
  // Hook
  const dispatch = useDispatch()
  // Mutation
  const [deleteAdvertiserMutation, { loading }] = useMutation(AdvertiserAPI.deleteAdvertiser(), {
    notifyOnNetworkStatusChange: true
  })

  const deleteAdvertiser = async (id, name, onSuccess, onError) => {
    await deleteAdvertiserMutation({
      variables: {
        id
      }
    })
      .then(async res => {
        dispatch(showToast({
          message: `You've successfully deleted ${name}.`,
          isError: false
        }))
        await onSuccess(res)
      })
      .catch(e => dispatch(showToast({
        message: 'Internal server Error.',
        isError: true
      })))
  }
  return {
    deleteAdvertiser,
    loading
  }
}

export default useDeleteAdvertiserMutation
