import { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Stack } from '@mui/system'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'

import { RenderIf, seperatePhoneAndCountryCode } from '../../utils/Helpers'

import HeroSection from './Component/HeroSection'
import { Portfolio, PersonalDetails, PersonalInfo, DisableTalent } from './Component'
import { SideBar, UserInfo, TlyntSidebar, CreateNewSelects, AddTlyntToSelects, DeleteTalent } from '../../components'
import { formatPhoneNumber } from '../../services/ValidationServices'
import { setTalentMediaList } from '../../redux/actions/TalentDetailActions'

import useTalentMediaQuery from './Hooks/useTalentMediaQuery'
import useTalentMediaTypeQuery from '../../hooks/Talent/useTalentMediaQuery'

import useGetSelectionData from './Hooks/useGetSelectionData'
import useTalentDetailQuery from './Hooks/useTalentDetailQuery'
import useGetTalentDemographic from './Hooks/useGetTalentDemographic'
import useUpdateTalentMutation from './Hooks/useUpdateTalentMutation'
import useSelectListQuery from '../TalentMain/Hooks/useSelectLIstQuery'
import { setTalentVideoList } from '../../redux/actions/TalentVideoAction'
import useGetInitialPersonalInfoValue from './Hooks/useGetInitialPersonalInfoValue'

const TalentDetail = props => {
  const { isPublic, isUserTalent, selectDetail } = props

  // hooks
  const store = useStore()
  const dispatch = useDispatch()
  const { id, childSelectId, talentId } = useParams()
  const user = useSelector(state => state.UserReducer)
  const mediaList = useSelector(state => state.TalentDetailReducer?.mediaList)
  const { initialValues: personalInfoInitialValues, refetch: personalInfoRefetch } = useGetInitialPersonalInfoValue()

  // api calls
  const { data: talentDemographicData } = useGetTalentDemographic(id)
  const { loading, error, data, refetch } = useTalentDetailQuery(id ?? talentId)
  const { updateTalent, loading: updateTalentLoading } = useUpdateTalentMutation()
  const { data: demographicData, loading: demographicLoading } = useGetSelectionData()
  const selectListQuery = useSelectListQuery({ notifyOnNetworkStatusChange: false, fetchPolicy: 'no-cache' })
  // personal portfolio
  const { talentMediaLoading, talentMediaError, talentMediaData, talentMediaFetchMore } = useTalentMediaQuery({ talentId: id ?? talentId, disabled: user.isAdvertiser ? false : !isPublic })

  // portdfolio video
  const { data: videoData, loading: videoLoading } = useTalentMediaTypeQuery({ talentId: id ?? talentId, type: 'video' })

  // states
  const [profileData, setProfileData] = useState(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDisableModalVisible, setIsDisableModalVisible] = useState(false)
  const [isAddSelectModalVisible, setIsAddSelectModalVisible] = useState(false)
  const [isNewSelectModalVisible, setIsNewSelectModalVisible] = useState(false)

  // initial value state for personal details component
  const [initialValues, setInitialValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    location: '',
    countryCode: '+1',
    pincode: '',
    countryCodeLocation: JSON.stringify('US'),
    socialLinks: {
      instagram: '',
      facebook: '',
      twitter: '',
      tiktok: '',
      website: ''
    }
  })

  // storing profile data in local state
  useEffect(() => {
    if (!loading && !error && data) {
      const { talentGet } = data
      setProfileData(talentGet)
    }
  }, [loading, error, data])

  // manipulating states based on previous data
  useEffect(() => {
    if (profileData) {
      setInitialLoading(true)
      loadInitialValues()
    }
  }, [profileData])

  /**
   * @description load initial values for personal details component
   */
  const loadInitialValues = async () => {
    if (profileData) {
      let localphone = ''
      let localcountrycode = ''
      const { phone: phonenumber, countryCode, location, countryCodeLocation, postalCode } = profileData
      if (!countryCode) {
        if (phonenumber) {
          const { countryCode, phone } =
            seperatePhoneAndCountryCode(phonenumber)
          localcountrycode = countryCode
          localphone = phone
        } else localcountrycode = '+1'
      } else localphone = formatPhoneNumber(profileData?.phone)

      setInitialValues({
        ...profileData,
        phone: localphone,
        countryCode: localcountrycode,
        countryCodeLocation: countryCodeLocation ? JSON.stringify(countryCodeLocation) : JSON.stringify('US'),
        pincode: postalCode,
        location: location ? { label: location, value: {} } : '',
        socialLinks: {
          instagram: profileData?.socialLinks?.instagram ?? '',
          facebook: profileData?.socialLinks?.facebook ?? '',
          twitter: profileData?.socialLinks?.twitter ?? '',
          tiktok: profileData?.socialLinks?.tiktok ?? '',
          website: profileData?.socialLinks?.website ?? ''
        }
      })

      setInitialLoading(false)
    }
  }
  // handeling sidebars
  const handelToggleDeleteSidebar = () => setIsDeleteModalVisible(!isDeleteModalVisible)

  const handelToggleDisableSidebar = () => setIsDisableModalVisible(!isDisableModalVisible)

  const handelToggleSelectSidebar = () => setIsAddSelectModalVisible(!isAddSelectModalVisible)

  // handeling tab and rendering tab components
  const updateActiveTab = (id, index) => setActiveTabIndex(index)

  const refetchTalentDetail = () => refetch({ variables: { id: id ?? talentId } })

  const successUpdateTalent = () => {
    setIsDisableModalVisible()
    refetchTalentDetail()
  }
  const loadingStatus = {
    done: 1,
    loading: 2,
    limited: 3,
    error: 4
  }
  const [loadingMoreStatus, setLoadingMoreStatus] = useState(loadingStatus.done)

  // for load portfolio images
  useEffect(() => {
    if (!talentMediaLoading && !talentMediaError && talentMediaData) {
      const cloneData = talentMediaData.mediaList.map((item) => ({ ...item, isUploaded: true }))
      dispatch(setTalentMediaList([...cloneData]))
      if (data?.mediaList?.length === 0) {
        setModalVisible(true)
      } else {
        setModalVisible(false)
      }
    } else {
      dispatch(setTalentMediaList([]))
    }
  }, [talentMediaData, talentMediaLoading, talentMediaError])

  useEffect(() => {
    if (videoData && !videoLoading && videoData?.mediaList && !loading) {
      const filteredWithoutProfileVideo = videoData.mediaList.filter((item) => item.id !== data?.talentGet?.videoProfile?.id)
      dispatch(setTalentVideoList(filteredWithoutProfileVideo))
    }
  }, [videoData, videoLoading, loading])

  /**
   * @description fetch more media for portfolio tab
   * @returns {Promise}
   */
  const fetchMoreMedia = async () => {
    const mediaStoreList = store.getState().TalentDetailReducer?.mediaList
    try {
      setLoadingMoreStatus(loadingStatus.loading)
      const { data: newData } = await talentMediaFetchMore({
        variables: {
          offset: mediaStoreList.length
        }
      })
      if (newData && newData.mediaList.length > 0) {
        const cloneData = newData.mediaList.map((item) => ({ ...item, isUploaded: true }))
        setLoadingMoreStatus(loadingStatus.done)
        dispatch(setTalentMediaList([...mediaStoreList, ...cloneData]))
      } else {
        setLoadingMoreStatus(loadingStatus.limited)
      }
    } catch (error) {
      setLoadingMoreStatus(loadingStatus.error)
    }
  }
  /**
   * @description update talent function
   * @param {string} id
   * @param {string} value
   * @param {boolean} isFlagged
   * @returns {JSX.Element} JSX.Element
   */
  const updateTalentFunc = (id, value, isFlagged = false) => {
    if (!updateTalentLoading) {
      const updatedValue = {}
      if (isFlagged) {
        updatedValue.flagged = value
      } else {
        updatedValue.disabled = value
      }
      updateTalent(id, updatedValue, successUpdateTalent)
    }
  }
  /**
   * @returns {JSX.Element} for render sidebar component
   */
  const Sidebars = () => {
    return (
      <Fragment>
        <RenderIf isTrue={isNewSelectModalVisible}>
          <TlyntSidebar direction={'right'} opacityLevel={0} zIndex={11}>
            <CreateNewSelects
              isBackArrow={true}
              isVisibleModal={isNewSelectModalVisible}
              toggleModal={() => {
                setIsNewSelectModalVisible()
                selectListQuery.refetch()
              }}
            />
          </TlyntSidebar>
        </RenderIf>
        <RenderIf isTrue={isDeleteModalVisible && profileData}>
          <TlyntSidebar direction="right">
            <DeleteTalent
              profileData={profileData}
              handelToggleDeleteSidebar={handelToggleDeleteSidebar}
            />
          </TlyntSidebar>
        </RenderIf>
        <RenderIf isTrue={isDisableModalVisible && profileData}>
          <TlyntSidebar direction="right">
            <DisableTalent
              profileData={profileData}
              handelToggleDisableSidebar={handelToggleDisableSidebar}
              updateTalentFunc={updateTalentFunc}
              updateTalentLoading={updateTalentLoading}
            />
          </TlyntSidebar>
        </RenderIf>
        <RenderIf isTrue={isAddSelectModalVisible}>
          <TlyntSidebar direction={'right'} opacityLevel={1}>
            <AddTlyntToSelects
              gridList={[profileData]}
              selectListQuery={selectListQuery}
              isVisibleModal={isAddSelectModalVisible}
              toggleModal={setIsAddSelectModalVisible}
              toggleCreateNewSelects={() =>
                setIsNewSelectModalVisible(!isNewSelectModalVisible)
              }
            />
          </TlyntSidebar>
        </RenderIf>
      </Fragment>
    )
  }

  return (
    <Stack direction='row' height='100%'>
      {!isPublic && <SideBar />}
      <Stack height='100vh' flexGrow={1}>
        <HeroSection
          isPublic={isPublic}
          updateActiveTab={updateActiveTab}
          activeTabIndex={activeTabIndex}
        />
        <RenderIf isTrue={activeTabIndex === 0}>
          <Portfolio
            isPublic={isPublic}
            id={id ?? talentId}
            profileImageId={data?.talentGet?.mediaProfile}
            loading={talentMediaLoading || initialLoading}
            error={talentMediaError}
            data={talentMediaData}
            videoProfile={data?.talentGet?.videoProfile?.uris}
            fetchMore={talentMediaFetchMore}
            fetchMoreMedia={fetchMoreMedia}
            loadingMoreStatus={loadingMoreStatus}
            mediaList={mediaList}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            refetchTalentDetail={refetch}
            auditionId={selectDetail?.project?.deliverables?.find((item) => item.type === 'audition')?.id}
          />
        </RenderIf>
        <RenderIf isTrue={activeTabIndex === 1}>
          <PersonalDetails
            profileData={profileData}
            initialValues={initialValues}
            initialLoading={initialLoading}
          />
        </RenderIf>
        <RenderIf isTrue={activeTabIndex === 2}>
          <PersonalInfo
            demographicData={demographicData}
            demographicLoading={demographicLoading}
            talentDemographicData={talentDemographicData}
            initialValues={personalInfoInitialValues}
            refetch={personalInfoRefetch}
            refetchTalentDetail={refetchTalentDetail}
          />
        </RenderIf>
      </Stack>
      <UserInfo
        key={loading}
        error={error}
        loading={loading}
        isPublic={isPublic}
        profileData={profileData}
        isUserTalent={isUserTalent}
        parentId={childSelectId ?? null}
        updateTalentFunc={updateTalentFunc}
        updateTalentLoading={updateTalentLoading}
        handelToggleSelectSidebar={handelToggleSelectSidebar}
        handelToggleDeleteSidebar={handelToggleDeleteSidebar}
        handelToggleDisableSidebar={handelToggleDisableSidebar}
      />
      <Sidebars />
    </Stack>
  )
}

TalentDetail.propTypes = {
  isPublic: PropTypes.bool,
  isUserTalent: PropTypes.bool,
  selectDetail: PropTypes.object
}

export default TalentDetail
