import { type ChangeEvent, useState, useContext, type Dispatch, useEffect } from 'react'

import * as z from 'zod'
import classNames from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'

import { InputBox } from '../../../../components/InputBox'
import { RadioInput } from '../../../RadioInput'
import { SectionHeader } from '../../../UGCSectionHeader/SectionHeader'
import { SpinnerSmall } from '../../../Progress'
import { type IProduct, type IProductActions, ProductContext } from '../../../../contexts/product'
import style from './Product.module.scss'
import { type ApolloCache, ApolloError, type DefaultContext, type MutationTuple, type OperationVariables, useMutation } from '@apollo/client'
import { type IProjectDetail, type IProjectDetailActions, UGCProjectDetailContext } from '../../../../contexts/projectDetail'
import { useDispatch, useSelector } from 'react-redux'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { UGCSidebarContext } from '../../../../contexts/ugcSidebar'
import { SendNotification } from '../../../../apis/ugc'
import type { UserState } from '../../../../redux/reducers/user'

import { URL } from '../../../../constants/URL'

const validationSchema = z.object({
  url: z.string().nonempty('This field is required'),
  description: z.string().max(2028, 'Product description must be less than 2028 characters').optional().nullable(),
  directions: z.string().max(2028, 'Specific direction must be less than 2028 characters').optional().nullable(),
  provisioningMethod: z.enum(['not_required', 'shipment', 'reimbursement'], { invalid_type_error: 'This field is required' })
})

interface IProjectProduct {
  id: string
  ownerId: string
  projectId: string
  name: string
  url: string
  description: string[]
  provisioningMethod: string
  directions: string[]
}
interface ProjectProductProps {
  projectProductQueryMutationObj: {
    useUpdateProjectProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useUpdateProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useCreateProjectProductMutation: () => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> | [() => void, { loading: boolean }]
    useGetProductByProjectQuery: () =>({ data: { getProductByProject: IProjectProduct | null } | undefined })
  }
}

type IValidationError = Record<string, string | undefined>

const radioOptions = [
  { id: 1, value: 'not_required', label: 'Not required' },
  { id: 2, value: 'shipment', label: 'Shipment' },
  { id: 3, value: 'reimbursement', label: 'Reimbursement' }
]

export function Product (props: ProjectProductProps): JSX.Element {
  const { projectProductQueryMutationObj } = props
  const { useUpdateProjectProductMutation, useCreateProjectProductMutation, useGetProductByProjectQuery, useUpdateProductMutation } = projectProductQueryMutationObj

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState<IValidationError>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: projectProductAPIData } = useGetProductByProjectQuery()
  const [sidebarData, handleSidebarData] = useContext(UGCSidebarContext)
  const [productData, updateProductData] = useContext<[IProduct, Dispatch<IProductActions>]>(ProductContext)
  const [projectDetailData] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)
  const [sendNotificationMutation] = useMutation(SendNotification, { notifyOnNetworkStatusChange: true })
  const [createProjectProductMutation] = useCreateProjectProductMutation()
  const [updateProjectProductMutation] = useUpdateProjectProductMutation()
  const [updateProductMutation] = useUpdateProductMutation()

  const user: UserState = useSelector((state: any) => state.UserReducer)

  const firstName = String(user?.info?.name?.first) // BAD PRACTICE
  const lastName = String(user?.info?.name?.last) // BAD PRACTICE
  const userInfo = (firstName?.length > 0) || (lastName?.length > 0) ? `${firstName} ${lastName}` : user?.info?.email

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    const formValues = { [name]: value }
    updateProductData(formValues)
    handleValidate(event)
  }

  const handleBack = (): void => {
    if (id != null) {
      navigate(`/projects/ugc/${id}/talent`)
    }
  }

  useEffect(() => {
    if (projectProductAPIData?.getProductByProject != null) {
      const { getProductByProject } = projectProductAPIData
      updateProductData({
        id: getProductByProject.id,
        url: getProductByProject.url,
        ownerId: getProductByProject.ownerId,
        description: getProductByProject.description?.join('\n'),
        directions: getProductByProject.directions?.join('\n'),
        provisioningMethod: getProductByProject.provisioningMethod
      })
    }
  }, [projectProductAPIData])

  const handleValidate = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target

    const result = validationSchema.safeParse({ [name]: value })
    if (result.success || result.error.issues.length === 0) {
      setErrors({ ...errors, [name]: undefined })
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => {
          if (item.path[0] === name) {
            errValues[String(item.path[0])] = item.message
          }
        })
        setErrors({ ...errors, ...errValues })
      }
    }
  }
  //
  const handleAddUriSchemes = (url: string): string => {
    if (!/^https?:\/\//i.test(url) && !/^www\./i.test(url)) {
      url = 'https://' + url
    } else if (/^www\./i.test(url)) {
      url = url.replace(/^www\./i, 'https://')
    }
    return url
  }

  const handleSubmit = async (e: IProduct): Promise<void> => {
    const result = validationSchema.safeParse(productData)
    const notificationPayload = {
      orgName: projectDetailData?.owner?.name ?? '',
      logoUrl: projectDetailData?.owner?.logo?.uris?.logo ?? '',
      projectId: projectDetailData?.id,
      projectUrl: (id != null) ? `${URL}/projects/ugc/view/${id}/overview` : '',
      projectName: projectDetailData?.name,
      userInfo,
      action: ''
    }
    try {
      if (result.success || result.error.issues.length === 0) {
        setIsSubmitting(true)
        setErrors({})
        if ((projectProductAPIData?.getProductByProject?.id) != null) {
          const { url: oldUrl, description: oldDescription, id: productId } = projectProductAPIData?.getProductByProject
          const { url: newUrl, description: newDescription, ownerId } = productData

          const oldDescriptionString = oldDescription?.join('\n') ?? null
          const newDescriptionString = (newDescription !== undefined && newDescription !== null && newDescription?.trim().length > 0) ? newDescription : null
          // update top product and description
          if (oldUrl !== newUrl || oldDescriptionString !== newDescription) {
            await updateProductMutation({
              variables: {
                updateProductId: productId,
                ownerId,
                url: oldUrl === newUrl ? undefined : handleAddUriSchemes(newUrl),
                description: newDescriptionString !== null ? (newDescriptionString === oldDescriptionString ? undefined : newDescriptionString?.split('\n').filter(str => str !== '')) : null
              }
            })
          }
          // update provisioning method and direction
          if (productData.provisioningMethod !== projectProductAPIData?.getProductByProject?.provisioningMethod || productData.directions !== projectProductAPIData?.getProductByProject?.directions?.join('\n')) {
            await updateProjectProductMutation({
              variables: {
                productId: projectProductAPIData?.getProductByProject?.id,
                projectId: id,
                directions: productData.directions !== null ? productData.directions.split('\n').filter(str => str !== '') : null,
                provisioningMethod: productData.provisioningMethod
              }
            })
          }
          notificationPayload.action = 'update'
          if (user?.isAdvertiser) await sendNotificationMutation({ variables: notificationPayload })
        } else {
          await createProjectProductMutation({
            variables: {
              projectId: id,
              ownerId: projectDetailData?.ownerId,
              name: 'Product',
              url: handleAddUriSchemes(productData.url),
              description: productData.description !== null ? productData.description.split('\n') : null,
              directions: productData.directions !== null ? productData.directions.split('\n') : null,
              provisioningMethod: productData.provisioningMethod
            }
          })
          notificationPayload.action = 'create'
          if (user?.isAdvertiser) await sendNotificationMutation({ variables: notificationPayload })
        }
        navigate('/projects')
      } else {
        const err = result.error
        if (err instanceof z.ZodError) {
          const errValues: IValidationError = {}
          err.issues.forEach((item) => {
            errValues[String(item.path[0])] = item.message
          })
          setErrors({ ...errValues })
          sidebarData[4].isError = true
          sidebarData[4].isCompleted = false
        }
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        dispatch(showToast({
          message: error?.message,
          isError: true
        }))
        sidebarData[4].isError = false
        sidebarData[4].isCompleted = true
      }
    }
    setIsSubmitting(false)
    handleSidebarData([...sidebarData])
  }

  return (
    <>
      <form className={style.Product}>
      <div>
        <SectionHeader headerText='Product URL' descriptionText='URL that describes the product or category.' />
        <InputBox onBlur={handleValidate} name='url' onChange={handleChange} label='Add url link' isError={errors?.url !== undefined} value={productData.url} errorText={errors?.url} />
      </div>
      <div>
        <SectionHeader headerText='Product Description' descriptionText='Describe and explain the benefits of your product.' />
        <InputBox isMultiline name='description' onChange={handleChange} label='Add one or more lines/bullets' withLengthSuffix acceptedLength={2028} value={productData.description} isError={errors.description !== undefined} errorText={errors.description} />
      </div>
      <div>
        <SectionHeader headerText='How Will Talent Receive the Product?' descriptionText='Please choose from below options.' />
        <RadioInput name='provisioningMethod' onChange={handleChange} options={radioOptions} value={productData.provisioningMethod} isError={errors.provisioningMethod !== undefined} errorText={errors.provisioningMethod} />
      </div>
      <div>
        <SectionHeader headerText='Specific Direction' descriptionText='Talent to describe the product' />
        <InputBox isError={errors.directions !== undefined} errorText={errors.directions} isMultiline name='directions' value={productData.directions} onChange={handleChange} label='Add one or more lines/bullets' withLengthSuffix acceptedLength={2028} />
      </div>
      </form>
      <div className={style.Footer}>
        <div className={style.Footer__ButtonRow} onClick={handleBack}><button className={classNames(style.Footer__Button, style['Footer__Button--TransparentBorder'])}><p>Back</p></button></div>
        <div className={style.Footer__ButtonRow}><button className={classNames(style.Footer__Button)} onClick={() => { void handleSubmit(productData) }} disabled={isSubmitting}><p>{'Save & Close'}</p>{isSubmitting && <SpinnerSmall />}</button></div>
      </div>
    </>
  )
}
