import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { showToast } from '../../../redux/actions/DashBoardActions'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

const useBulkUserUpdateMutation = () => {
  // hooks
  const dispatch = useDispatch()
  const [bulkUserUpdate, { loading }] = useMutation(
    AdvertiserAPI.bulkUserUpdate(),
    {
      notifyOnNetworkStatusChange: true
    }
  )

  const submitRole = async props => {
    const { data, toggleModal } = props
    const { members } = data

    const payload = members.map(member => ({
      id: member.id,
      firstname: member.firstname,
      lastname: member.lastname,
      email: member.email,
      roles: member.title,
      countryCode: '+1',
      phone: member.phone?.length !== 0 ? member.phone?.replace(/ /g, '') : undefined
    }))

    try {
      await bulkUserUpdate({
        variables: {
          users: payload
        }
      })
      toggleModal()
      dispatch(
        showToast({
          message: 'Advertiser member updated successfully',
          isError: false
        })
      )
    } catch (error) {
      dispatch(
        showToast({
          message: error?.message,
          isError: true
        })
      )
    }
  }

  return [submitRole, { loading }]
}

export default useBulkUserUpdateMutation
