import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import RoleDetail from '../roleDetail'
import ProjectOverview from '../projectOverview'
import ProjectTalent from '../InterestedTalentsMain'

import useUpdateProjectMutation from '../../Hooks/useUpdateProjectMutation'
import useDeleteProjectMutation from '../../Hooks/useDeleteProjectMutation'

import Footer from '../Footer'
import { RenderIf } from '../../../../utils/Helpers'
import { TlyntSidebar } from '../../../../components'
import { ProjectConstants } from '../../../../constants'
import DeleteProject from '../DeleteProject/DeleteProject'
import AddNewProject from '../../../Projects/Components/AddNewProject'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import useProjectRoleListQuery from '../../Hooks/useProjectRoleListQuery'
import useAddProjectMutation from '../../../Projects/Hooks/useAddProjectMutation'

const Wrapper = styled.div`
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
`

const ActiveTabComponent = (activeTabComponentProps) => {
  const { activeTab, detail, projectId, setIsFooter, roleList, roleLoading, refetchRole } = activeTabComponentProps
  switch (activeTab) {
    case 'roleDetails':
      return (
        <RoleDetail
          roleList={roleList}
          roleLoading={roleLoading}
          refetch={refetchRole}
          projectId={projectId}
          setIsFooter={setIsFooter}
        />
      )

    case 'projectTalent':
      return (
        <ProjectTalent
          projectId={projectId}
          selectId={detail?.selectId}
          roles={detail?.roles}
        />
      )

    case 'projectOverview':
      return (
        <ProjectOverview
          detail={detail}
          setIsFooter={setIsFooter}
        />
      )
    default:
      return null
  }
}

const DetailWrapper = props => {
  const { activeTab, detail, projectId, refetchProjectOverviewData } = props
  // state
  const [isFooter, setIsFooter] = useState(true)
  const [isCancel, setIsCancel] = useState(false)
  const [toggleEditModal, setToggleEditModal] = useState(false)
  const [isDeleteTalentProjectModalVisible, setIsDeleteTalentProjectModalVisible] = useState(false)

  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // api's
  const { updateProject, loading } = useUpdateProjectMutation()
  const { deleteProject, loading: deleteProjectLoading } = useDeleteProjectMutation()

  const user = useSelector((state) => state.UserReducer)

  const [AddProjectWithSelect, { loading: addProjectLoading }] = useAddProjectMutation()
  const { data: projectRoleList, loading: roleLoading, refetch } = useProjectRoleListQuery(projectId)

  const onSuccessUpdateProject = () => {
    dispatch(
      showToast({
        message: ProjectConstants.Project.successStatus,
        isError: false
      })
    )
  }
  const onErrorUpdateProject = (e) => {
    dispatch(showToast({ message: e?.message, isError: true }))
  }
  useEffect(() => {
    activeTab === 'projectTalent' && setIsFooter(false)
  }, [activeTab])

  const projectDeleteSuccess = () => {
    dispatch(showToast({ message: ProjectConstants.Project.deleteStatus, isError: false }))
    navigate('/projects')
  }

  const updateProjectFunc = (status, CancelStatus, updateProjectCB = () => null) => {
    setIsCancel(CancelStatus)
    updateProject(
      projectId,
      status,
      () => {
        onSuccessUpdateProject()
        updateProjectCB()
      },
      onErrorUpdateProject,
      'ugc'
    )
  }

  const handleToggleDeleteProjectSidebar = () =>
    setIsDeleteTalentProjectModalVisible(!isDeleteTalentProjectModalVisible)

  const onDeleteHandler = async () => {
    if (detail?.selectId) {
      await deleteProject(
        detail.selectId,
        projectDeleteSuccess,
        onErrorUpdateProject
      )
    }
    handleToggleDeleteProjectSidebar()
  }

  const duplicateProject = (handleCloseMenu) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const projectDetaildata = detail
    const projectData = {
      name: `${projectDetaildata?.name} - Copy`,
      startDate: projectDetaildata?.startDate ?? null,
      endDate: projectDetaildata?.endDate ?? null,
      ownerId: userInfo?.type === 'advertiser' ? userInfo?.ownerId : projectDetaildata?.ownerId,
      mediaType: projectDetaildata?.mediaType ?? [],
      usage: projectDetaildata?.usage ?? [],
      description: projectDetaildata?.description ?? '',
      notes: projectDetaildata?.notes ?? '',
      status: 'draft',

      countryCodeLocation: projectDetaildata?.countryCodeLocation,
      postalCode: projectDetaildata?.postalCode
    }

    const roleData = projectRoleList?.projectRoleList?.map((item) => ({
      name: item?.name,
      id: item.id,
      selectId: item?.selectId,
      rate: item.rate,
      startDate: item.startDate,
      endDate: item.endDate,
      age: [item.criteria?.age?.min ?? 0, item.criteria?.age?.max ?? 100],
      weight: [item.criteria.weight?.min ?? 0, item.criteria.weight?.max ?? 450],
      bodyType: item.criteria.bodyType,
      height: [item.criteria.height?.min.toString() ?? '1.0', item.criteria.height?.max.toString() ?? '8.0'],
      hairColor: item.criteria.hairColor,
      eyeColor: item.criteria.eyeColor,
      race: item.criteria.race,
      gender: item.criteria.gender,
      genderExpression: item.criteria.genderExpression,
      sexualOrientation: item.criteria.sexualOrientation,
      ethnicity: item.criteria.ethnicity,
      isAgeAllowed: item.criteria?.age !== null,
      isWeightAllowed: item.criteria?.weight !== null,
      isHeightAllowed: item.criteria?.height !== null,
      description: item.description,
      notes: item.notes,
      quantity: item?.quantity
    }))

    AddProjectWithSelect({ project: projectData, role: roleData }, (projectId) => {
      handleCloseMenu()
      dispatch(showToast({
        message: 'Project created successfully.'
      }))
      navigate(`/projects/${projectId}`)
    })
  }

  return (
  <Wrapper>
    <ActiveTabComponent
      detail ={detail}
      refetchRole={refetch}
      activeTab={activeTab}
      projectId ={projectId}
      setIsFooter={setIsFooter}
      roleLoading={roleLoading}
      roleList={projectRoleList?.projectRoleList}
    />
    <RenderIf isTrue={detail && isFooter && user.isAdmin}>
      <Footer
        detail={detail}
        loading={loading}
        isCancel={isCancel}
        toggleEditModal={toggleEditModal}
        duplicateProject={duplicateProject}
        addProjectLoading={addProjectLoading}
        updateProjectFunc={updateProjectFunc}
        setToggleEditModal={setToggleEditModal}
        handleToggleDeleteProjectSidebar={handleToggleDeleteProjectSidebar}
      />
    </RenderIf>
      {/* ---------- sidebars ----------- */}
    <RenderIf isTrue={toggleEditModal}>
      <TlyntSidebar width={'764px'}>
        <AddNewProject
          detail={detail}
          refetchProjectOverviewData={refetchProjectOverviewData}
          handleToggle={() => setToggleEditModal(!toggleEditModal)}/>
      </TlyntSidebar>
    </RenderIf>
    <RenderIf isTrue={isDeleteTalentProjectModalVisible}>
      <TlyntSidebar direction="right" opacityLevel={1}>
        <DeleteProject
          handleToggleDeleteProjectSidebar={handleToggleDeleteProjectSidebar}
          onDeleteHandler={onDeleteHandler}
          loading={deleteProjectLoading}
        />
      </TlyntSidebar>
    </RenderIf>
  </Wrapper>
  )
}

DetailWrapper.propTypes = {
  activeTab: PropTypes.string,
  detail: PropTypes.object,
  projectId: PropTypes.string,
  isTikTokProject: PropTypes.bool,
  refetchProjectOverviewData: PropTypes.func
}

export default DetailWrapper
