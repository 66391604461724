import { Fragment } from 'react'

import PropTypes from 'prop-types'
import { HeaderV2, Typography, Button } from '../../../../components'
import { RenderIf } from '../../../../utils/Helpers'

const Header = props => {
  const {
    isArchived,
    toggleCreateNewSelectModal
  } = props
  return (
    <Fragment>
      <HeaderV2
        title={
          <Typography fontSize={34} fontWeight={700}>
            Selects
          </Typography>
        }
        titleRight={
          <RenderIf isTrue={!isArchived}>
           <Button onClick={toggleCreateNewSelectModal}>
            <Typography
              fontSize={15}
              letterSpacing={0.46}
              fontWeight={700}
              color={'white'}>
              CREATE NEW SELECTS
            </Typography>
          </Button>
         </RenderIf>
        }
        description={
          <Typography
            fontSize={14}
            fontWeight={400}
            style={{
              opacity: 0.3
            }}>
            View all the Selects with TLYNT here.
          </Typography>
        }
      />
    </Fragment>
  )
}

Header.propTypes = {
  toggleCreateNewSelectModal: PropTypes.func,
  isArchived: PropTypes.bool
}

export default Header
