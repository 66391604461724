import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { DELETE_VOTE } from '../../../services/API'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useRemoveVoteMutation (selectId) {
  // mutation
  const [RemoveVoteMutation, { loading }] = useMutation(DELETE_VOTE, {
    notifyOnNetworkStatusChange: true
  })
  const dispatch = useDispatch()

  const removeVote = (onSuccess, talentId) => {
    RemoveVoteMutation({
      variables: {
        selectId,
        talentId
      }
    })
      .then(res => onSuccess())
      .catch(e => {
        dispatch(showToast({
          message: e.message ?? 'Try again later',
          isError: true
        }))
      })
  }
  return { removeVote, loading }
}

export default useRemoveVoteMutation
