import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'

import { HeaderV2, TalentCard, Typography, SideBar, FooterLoader } from '../../components'
import { RenderIf } from '../../utils/Helpers'
import { setActiveSidebarRoute, setActiveTalentTabIndex, setTalentCardResizeData } from '../../redux/actions/DashBoardActions'
import { TalentContainer, Wrapper, TitleText, DescriptionWrapper, DescriptionText, ShimmerBox, NonSticky, StickyHeader, NoRecordText, SidebarWrapper, StickyHeaderSearchContainer } from './Styles'
import HeaderBottom from '../TalentMain/Components/HeaderBottom'
import ResizeModal from './Components/ResizeModal'
import SearchBox from '../TalentMain/Components/SearchBox'
import useAllTalentsWithMedia from './Hooks/useAllTalentsWithMedia'
import useDisabledTalentsWithMedia from '../../hooks/Talent/useDisabledTalentsWithMedia'
import useFlaggedTalentsWithMedia from '../../hooks/Talent/useFlaggedTalentsWithMedia'
import useWindowDimensions from '../../hooks/Common/useWindowDimension'

const UserTalent = props => {
  // destructuring props
  const { title = 'Talents', description = 'View all the talents along with flagged and diabled talents.', isUserTalent = true, showHeaderButtons = false } = props

  // constants
  const dashboardReduxData = useSelector(state => state.DashBoardReducer)
  const { activeSidebarRoute, talentCardResizeData: { resolutionName, resolutionSize } } = dashboardReduxData

  // hooks
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  // states
  const selectCount = useState(0)[0]
  const shimmerCount = useState(new Array(9).fill(0).map((_, index) => ({ id: index })))[0]

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [isVisibleModal, toggleModal] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [selectionMode, setSelectionMode] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)

  // lifecycle hooks
  useEffect(() => {
    dispatch(
      setActiveTalentTabIndex({
        userTalentsActiveTabIndex: activeTabIndex,
        talentsActiveTabIndex
      })
    )
  }, [activeTabIndex])

  const { userTalentsActiveTabIndex, talentsActiveTabIndex } = dashboardReduxData.activeTalentTabIndex

  useEffect(() => {
    if (activeSidebarRoute === '') {
      window.scrollTo(0, 0)
      dispatch(setActiveSidebarRoute('userTalent'))
    }
  }, [location])

  useEffect(() => {
    setActiveTabIndex(userTalentsActiveTabIndex)
  }, [])

  // api calls
  const {
    allTalentsData,
    allTalentLoading,
    totalAllTalents,
    allTalentsToggleNextPageCall
  } = useAllTalentsWithMedia()

  const {
    flaggedTalentsData,
    flaggedTalentsLoading,
    flaggedTotalTalents,
    flaggedToggleNextPageCall
  } = useFlaggedTalentsWithMedia()

  const {
    disabledTalentsData,
    disabledTalentsLoading,
    disabledTotalTalents,
    disabledTalentsToggleNextPageCall
  } = useDisabledTalentsWithMedia()

  useEffect(() => {
    const headerElement = document.querySelector('.nonStickyHeader')
    if (headerElement?.classList) {
      if (window.scrollY >= 200) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', setScroll)
    return () => {
      window.removeEventListener('scroll', setScroll)
    }
  }, [])

  useEffect(() => {
    const parameter = {}
    if (isUserTalent) {
      parameter.hasMedia = activeTabIndex === 0
    }
  }, [])

  // functions
  const handleActiveTabIndex = index => setActiveTabIndex(index)

  const setScroll = () => {
    const headerElement = document.querySelector('.nonStickyHeader')
    if (headerElement?.classList) {
      if (window.scrollY >= 200) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }

  const getTabTalentsData = () => {
    switch (activeTabIndex) {
      case 0:
        return allTalentsData
      case 'flagged':
        return flaggedTalentsData
      case 'disabled':
        return disabledTalentsData
      default:
        return []
    }
  }

  const getTabTalentsLoading = () => {
    switch (activeTabIndex) {
      case 0:
        return allTalentLoading
      case 'flagged':
        return flaggedTalentsLoading
      case 'disabled':
        return disabledTalentsLoading
      default:
        return false
    }
  }

  const getTabTotalTalents = () => {
    switch (activeTabIndex) {
      case 0:
        return totalAllTalents
      case 'flagged':
        return flaggedTotalTalents
      case 'disabled':
        return disabledTotalTalents
      default:
        return 0
    }
  }

  const NumberOfTalents = () => {
    if (!getTabTalentsLoading()) {
      return (
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={'166%'}
          letterSpacing={'0.4px'}
          style={{ paddingTop: 15 }}>
          Showing {getTabTalentsData().length} out of{' '}
          {getTabTotalTalents()}
        </Typography>
      )
    } else return null
  }

  const handleTalentCard = type => {
    const handleTalentCardResizeNumber = data => {
      dispatch(setTalentCardResizeData(data))
    }

    if (width > 1920) {
      switch (type) {
        case 'SM':
          return handleTalentCardResizeNumber({
            resolutionName: 'SM',
            resolutionSize: 150
          })
        case 'MD':
          return handleTalentCardResizeNumber({
            resolutionName: 'MD',
            resolutionSize: 256
          })
        case 'LG':
          return handleTalentCardResizeNumber({
            resolutionName: 'LG',
            resolutionSize: 312
          })
      }
    } else {
      switch (type) {
        case 'SM':
          return handleTalentCardResizeNumber({
            resolutionName: 'SM',
            resolutionSize: 150
          })
        case 'MD':
          return handleTalentCardResizeNumber({
            resolutionName: 'MD',
            resolutionSize: 180
          })
        case 'LG':
          return handleTalentCardResizeNumber({
            resolutionName: 'LG',
            resolutionSize: 220
          })
      }
    }
  }

  // pre load all image and cache them for fast loading
  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const mediaData = getTabTalentsData().map((e) => e?.media)
    if (mediaData) {
      const allMedia = [].concat(...mediaData)
      const chunkSize = 200
      if (!isLoaded && allMedia.length) {
        for (let i = 0; i < allMedia.length; i += chunkSize) {
          const chunk = allMedia.slice(i, i + chunkSize)
          cacheImages(chunk?.map((e) => e?.uris?.card))
            .catch(e => console.error(e))
          if (i >= (allMedia.length - 1 - chunkSize)) {
            setIsLoaded(true)
          }
        }
      }
    }
  }, [getTabTalentsData()])

  return (
    <Box display="flex">
      <SidebarWrapper>
        <SideBar />
      </SidebarWrapper>
      <Box minHeight="100vh" width="100%">
        <Wrapper>
          <HeaderV2
            title={<TitleText>{title}</TitleText>}
            titleRight={<SearchBox isUserTalent={isUserTalent} />}
            description={
              <DescriptionWrapper>
                <DescriptionText>{description}</DescriptionText>
              </DescriptionWrapper>
            }
            bottom={
              <NonSticky className="nonStickyHeader">
                <RenderIf isTrue={!isSticky}>
                  <Fragment>
                    <HeaderBottom
                      activeTabIndex={activeTabIndex}
                      handleActiveTabIndex={handleActiveTabIndex}
                      setSelectionMode={setSelectionMode}
                      selectionMode={selectionMode}
                      toggleModal={toggleModal}
                      selectCount={selectCount}
                      isVisibleModal={isVisibleModal}
                      toggleFilterModal={() =>
                        setShowFilterModal(!showFilterModal)
                      }
                      handleResetSelection={() => null}
                      showHeaderButtons={showHeaderButtons}
                      handleTalentCard={handleTalentCard}
                      resizeType={resolutionName}
                      isUserTalents={true}
                    />
                    <NumberOfTalents />
                  </Fragment>
                </RenderIf>
              </NonSticky>
            }
            stickyBottom={true}
          />
          <RenderIf isTrue={isSticky}>
            <StickyHeader>
              <StickyHeaderSearchContainer>
                <SearchBox isLarge={true} isUserTalent={isUserTalent} />
                <ResizeModal
                  handleTalentCard={handleTalentCard}
                  resizeType={resolutionName}
                />
              </StickyHeaderSearchContainer>
              <NumberOfTalents />
            </StickyHeader>
          </RenderIf>
          <InfiniteScroll
            dataLength={getTabTalentsData()?.length}
            next={() =>
              activeTabIndex === 0
                ? allTalentsToggleNextPageCall()
                : activeTabIndex === 1
                  ? flaggedToggleNextPageCall()
                  : disabledTalentsToggleNextPageCall()
            }
            hasMore={true}
            scrollThreshold={0.4}>
            <TalentContainer data-size={resolutionSize}>
              <RenderIf isTrue={getTabTalentsLoading()}>
                {shimmerCount.map(item => (
                  <ShimmerBox key={item.id} />
                ))}
              </RenderIf>
              <RenderIf
                isTrue={getTabTalentsData() && getTabTalentsData()?.length > 0}>
                {getTabTalentsData()?.map(talent => (
                  <TalentCard
                    key={talent?.id}
                    isSelectionMode={selectionMode}
                    data={talent}
                    handelTalentSelection={() => null}
                    isUserTalent={isUserTalent}
                  />
                ))}
              </RenderIf>
            </TalentContainer>
          </InfiniteScroll>
          <RenderIf isTrue={getTabTalentsData().length < getTabTotalTalents()}>
            <FooterLoader loadMessage="Loading more talents" />
          </RenderIf>
          <RenderIf
            isTrue={
              !getTabTalentsLoading() && getTabTalentsData()?.length === 0
            }>
            <NoRecordText>No Talents Found..!</NoRecordText>
          </RenderIf>
        </Wrapper>
      </Box>
    </Box>
  )
}

UserTalent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tabTitleList: PropTypes.array,
  isUserTalent: PropTypes.bool,
  showHeaderButtons: PropTypes.bool
}

export default UserTalent
