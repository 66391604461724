import { Skeleton } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import classNames from 'classnames'
import type { Dispatch } from 'react'

import { DeliverablesContext } from '../../../../contexts/deliverables'
import { deliverableValidationSchema } from '../../../../constants/UGCConstants'
import { SpinnerSmall } from '../../../Progress'
import { UGCDeliverablesAdditional } from '../../../UGCDeliverablesAdditional'
import { UGCDeliverablesVideos } from '../../../UGCDeliverablesVideos'
import { UGCProjectDetailContext } from '../../../../contexts/projectDetail'
import { UGCSidebarContext } from '../../../../contexts/ugcSidebar'
import { useInitializeDeliverables } from '../../../../hooks/UGC/useInitializeDeliverables'
import style from './Deliverables.module.scss'
import type { IDeliverables, IDeliverablesActions } from '../../../../contexts/deliverables'
import type { IProjectDetail, IProjectDetailActions } from '../../../../contexts/projectDetail'

type IValidationError = Record<string, string | undefined>

interface DeliverablesProps {
  deliverableQueryMutation: {
    useSubmitDeliverables: (close: boolean) => { handleSubmitProject: (close: boolean) => void, isLoading: boolean }
    useUpdateDeliverable: (close: boolean) => { handleUpdateDeliverable: (close: boolean) => void, isLoading: boolean }
    useListProjectDeliverables: () => { data: any, loading: boolean }
  }
}

const ScrollToHashElement = (): null => {
  const location = useLocation()

  const hashElement = useMemo(() => {
    const hash = location.hash
    const removeHashCharacter = (str: string): string => {
      const result = str.slice(1)
      return result
    }

    if (hash !== '') {
      const element = document.getElementById(removeHashCharacter(hash))
      return element
    } else {
      return null
    }
  }, [location])

  useEffect(() => {
    if (hashElement != null) {
      hashElement.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
    }
  }, [hashElement])

  return null
}

export default function Deliverables (props: DeliverablesProps): JSX.Element {
  // init
  const { deliverableQueryMutation } = props
  const { useSubmitDeliverables, useUpdateDeliverable } = deliverableQueryMutation
  const { loading } = useInitializeDeliverables()
  const { id } = useParams()
  const navigate = useNavigate()

  const [sidebarData, handleSidebarData] = useContext(UGCSidebarContext)
  const [deliverableData] = useContext<[IDeliverables, Dispatch<IDeliverablesActions>]>(DeliverablesContext)
  const [projectDetailData] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)

  // states
  const [errors, setErrors] = useState({})
  const [CTAType, setCTAType] = useState('')

  // api calls
  const { handleSubmitProject, isLoading: submitLoading } = useSubmitDeliverables(CTAType === 'close')
  const { handleUpdateDeliverable, isLoading: updateLoading } = useUpdateDeliverable(CTAType === 'close')

  /**
   * @description handle back button
   */
  const handleBack = (): void => {
    if (id != null) {
      navigate(`/projects/ugc/${id}/directions`)
    }
  }

  /**
   *  @description submit deliverable
   *  @param {boolean} close
   *  @returns {void}
   */
  const submitDeliverable = ({ close }: { close: boolean }): void => {
    const validateData = {
      main: deliverableData.main.projectDeliverable.script,
      audition: deliverableData.audition.specs,
      introHooks: deliverableData.introHooks.projectDeliverable.script,
      bRoll: deliverableData.bRoll.projectDeliverable.specs,
      duration: deliverableData?.main.deliverableSpec.duration,
      photo: deliverableData.photo.projectDeliverable.specs
    }

    const result = deliverableValidationSchema.safeParse(validateData)

    if (result.success) {
      setErrors({})
      handleSubmitProject(close)
      sidebarData[2].isError = false
      sidebarData[2].isCompleted = true
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => { errValues[item.path.join('.')] = item.message })

        sidebarData[2].isError = true
        sidebarData[2].isCompleted = false
        setErrors({ ...errors, ...errValues })
      }
    }
    handleSidebarData([...sidebarData])
  }

  const updateDeliverable = ({ close }: { close: boolean }): void => {
    const validateData = {
      main: deliverableData.main.projectDeliverable.script,
      audition: deliverableData.audition.specs,
      introHooks: deliverableData.introHooks.projectDeliverable.script,
      bRoll: deliverableData.bRoll.projectDeliverable.specs,
      duration: deliverableData?.main.deliverableSpec.duration,
      photo: deliverableData.photo.projectDeliverable.specs
    }

    const result = deliverableValidationSchema.safeParse(validateData)

    if (result.success) {
      setErrors({})
      handleUpdateDeliverable(close)
      sidebarData[2].isError = false
      sidebarData[2].isCompleted = true
    } else {
      const err = result.error
      if (err instanceof z.ZodError) {
        const errValues: IValidationError = {}
        err.issues.forEach((item) => { errValues[item.path.join('.')] = item.message })

        sidebarData[2].isError = true
        sidebarData[2].isCompleted = false
        setErrors({ ...errors, ...errValues })
      }
    }
    handleSidebarData([...sidebarData])
  }

  const submitDeliverableHandler = (type: string, shouldClose: boolean): void => {
    setCTAType(type)
    if (!deliverableData.initialState) {
      updateDeliverable({ close: shouldClose })
    } else {
      submitDeliverable({ close: shouldClose })
    }
  }

  if (loading) {
    return (
      <main className={style.Skeleton}>
        <div className={style.Skeleton__Title}>
          <Skeleton variant='rectangular' />
        </div>
        <div className={style.Skeleton__Description}>
          <Skeleton variant='rectangular' />
        </div>
        <div className={style.Skeleton__BoxContainer}>
          <div className={style.Skeleton__Box}>
            <Skeleton variant='rectangular' />
          </div>
          <div className={style.Skeleton__Box}>
            <Skeleton variant='rectangular' />
          </div>
        </div>

        <div className={style.Skeleton__Title}>
          <Skeleton variant='rectangular' />
        </div>
        <div className={style.Skeleton__Description}>
          <Skeleton variant='rectangular' />
        </div>
      </main>
    )
  }

  return (
    <>
      <ScrollToHashElement />
      <section id='videos'>
        <UGCDeliverablesVideos errorsData={[errors, setErrors]} />
      </section>
      <section id='additionals'>
        <UGCDeliverablesAdditional errorsData={[errors, setErrors]} />
      </section>
      <div className={style.Footer}>
        <button className={classNames('primary-text-button', style.Footer__Button)} onClick={handleBack}><p>Back</p></button>
        <div className={style.Footer__ButtonRow}>
          <button className={classNames('outline-primary-button', style.Footer__Button, projectDetailData.name === '' ? style['Footer__Button--Disabled'] : '')} onClick={projectDetailData.name === '' ? undefined : () => { submitDeliverableHandler('close', true) }}>{(id != null) ? 'Update & Close' : 'Save & Close'}{((submitLoading || updateLoading) && CTAType === 'close') && <SpinnerSmall />}</button>
          <button className={classNames('primary-button', style.Footer__Button, projectDetailData.name === '' ? style['Footer__Button--Disabled'] : '')} onClick={projectDetailData.name === '' ? undefined : () => { submitDeliverableHandler('next', false) }}>Next: Talent {((submitLoading || updateLoading) && CTAType === 'next') && <SpinnerSmall />}</button>
        </div>
      </div>
    </>
  )
}
