import style from './DatePicker.module.scss'

import moment, { type Moment } from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

interface CustomRadioProps {
  label?: string
  name: string
  errorText?: string
  isError?: boolean
  value?: string | Date
  disablePast?: boolean
  onChange?: (name: string, e: Moment | null) => void
  minDate?: Moment
}

export const DatePicker = (props: CustomRadioProps): JSX.Element => {
  const { label, name, disablePast = false, value, onChange, isError = false, errorText = '', minDate } = props

  const handleChange = (name: string, e: Moment | null): void => {
    if (typeof onChange === 'function') {
      onChange(name, e)
    }
  }

  return (
    <div className={style.DatePicker}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          label={label}
          disableHighlightToday
          minDate={minDate}
          slotProps={{ textField: { name, variant: 'standard', fullWidth: true, error: isError, helperText: isError ? errorText : '' } }}
          value={ value === undefined ? undefined : moment(value).utc(true)} onChange={e => { handleChange(name, e) }} disablePast={disablePast}
        />
       </LocalizationProvider>
    </div>
  )
}
