import { useQuery } from '@apollo/client'
import TalentAPI from '../../../services/Talent/TalentAPI'

function useTalentMediaQuery ({ talentIds }) {
  const { data, loading, error, fetchMore } = useQuery(TalentAPI.getMediaListData(), {
    variables: {
      talentIds,
      disabled: false
    },
    skip: !talentIds?.length,
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  })

  return { data, loading, error, fetchMore }
}

export default useTalentMediaQuery
