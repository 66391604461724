import PropTypes from 'prop-types'

export const Growthbook = props => {
  const { children, status } = props
  if (status) {
    return <>{children}</>
  }
  return null
}

Growthbook.propTypes = {
  children: PropTypes.node,
  status: PropTypes.bool
}
