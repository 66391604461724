import { Widget } from '@typeform/embed-react'
import { useSelector } from 'react-redux'

import {
  Layout
} from '../../components'

const ProjectsForm = () => {
  const user = useSelector(state => state.UserReducer)

  const prefill = {
    user_id: user.info.id,
    firstname: user.info.name.first,
    lastname: user.info.name.last,
    email: user.info.email,
    org_id: user.info.org
  }

  return (
    <Layout>
      <Widget id='ToAhk2vL' style={{ width: '100%', height: '100%' }} hideFooter hideHeaders hidden={prefill} />
    </Layout>
  )
}

export default ProjectsForm
