import { any } from 'prop-types'
import styled from 'styled-components'
import { Colors, Fonts } from '../../constants'

const ErrorText = styled.span`
  color: ${Colors.errorRed};
  font-family: ${Fonts.degular};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left;
  display: block;
`

export const TextError = props => {
  return <ErrorText>{props.children}</ErrorText>
}

TextError.propTypes = {
  children: any
}
