import classNames from 'classnames'
import type { KeyboardEvent } from 'react'
import { Accordion } from '../Accordion'
import { Drawer } from '../Drawer'
import style from './UGCAddTalentToSelect.module.scss'
import { Button } from '../buttonV2'

interface ChipProps {
  title: string
}

const Chip = (props: ChipProps): JSX.Element => {
  const { title } = props
  return (
    <div className={style.Chip}>
      <span className={style.Chip__Text}>{title}</span>
    </div>
  )
}

interface UGCAddTalentToSelectProps {
  open: boolean
  isLoading: boolean
  accordianData: Array<{ title: string, isOpen: boolean, isSelected: boolean, subData: Array<{ title: string, chips: string[] }> }>
  handleToggleProfile: (index: number) => void
  handleCheckboxChange: (index: number) => void
  handleToggleAddToSelectSidebar: () => void
  handleAddToSelect: () => void
  handleToggleSidebar: (isOpen: boolean) => void
  isAddToRoleDisabled?: boolean
}

export const UGCAddTalentToSelect = (props: UGCAddTalentToSelectProps): JSX.Element => {
  const { open, accordianData, handleToggleProfile = () => { }, handleCheckboxChange = () => { }, handleToggleAddToSelectSidebar, handleAddToSelect, handleToggleSidebar, isLoading, isAddToRoleDisabled } = props

  const closeSidebar = (): void => {
    handleToggleAddToSelectSidebar()
    handleToggleSidebar(false)
  }

  /**
   * @description This function is used to handle the keyboard navigation
   * @param e - KeyboardEvent
   */
  const handleKeyboardKeyPressed = (e: KeyboardEvent<HTMLImageElement>): void => {
    if (e.code === 'Escape') {
      closeSidebar()
    }
  }

  return (
    <Drawer open={open} handleNavigate={handleKeyboardKeyPressed} onClose={closeSidebar}>
      <div className={style.Drawer}>
        <div className={style.Drawer__Main}>
          <h1 className={style.Drawer__Heading}>Choose a Profile</h1>
          <p className={style.Drawer__Description}>Choose which profile to add this talent to.</p>
          {accordianData?.length > 0 && accordianData.map((item, index) => (
            <Accordion isChecked={item.isSelected} onCheckboxClick={() => { handleCheckboxChange(index) }} onClick={() => { handleToggleProfile(index) }} expanded={item.isOpen} key={item.title} showCheckbox label={item.title} externalMainClasses={[style.Drawer__Accordion]}>
              <div className={style.Drawer__AccordionContent}>
                {item.subData?.length > 0 && item.subData.map((subItem) => (
                  <div key={subItem.title}>
                    <p className={style.Drawer__AccordionChipsTitle}>{subItem.title}</p>
                    <div className={style.Drawer__AccordionChips}>
                      {subItem.chips.map((chip) => <Chip key={chip} title={chip} />)}
                    </div>
                  </div>
                ))}
              </div>
            </Accordion>
          ))}
        </div>
        <div className={style.Drawer__Footer}>
          <Button backgroundColor={'transparent'} onClick={() => { handleToggleAddToSelectSidebar() } }><p className={style.Drawer__FooterText}>Cancel</p></Button>
          <Button onClick={handleAddToSelect} disabled={isLoading || isAddToRoleDisabled} loading={isLoading}><p className={classNames(style.Drawer__FooterText, style['Drawer__FooterText--White'])}>Add</p></Button>
        </div>
      </div>
    </Drawer>
  )
}
