import { useQuery } from '@apollo/client'
import TalentAPI from '../../../services/Talent/TalentAPI'

function useSearchTalentQuery (props) {
  const propObject = Object.fromEntries(Object.entries(props).filter(([_, v]) => v != null))
  const { data, loading, refetch, fetchMore } = useQuery(TalentAPI.getSearchTalentsData(), {
    variables: {
      ...propObject,
      sort: JSON.stringify({ firstname: 'asc', lastname: 'asc' })
    },
    fetchPolicy: 'no-cache'
  })

  return { data, loading, refetch, fetchMore }
}

export default useSearchTalentQuery
