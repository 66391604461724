import TalentMain from '../TalentMain'
import { TalentConstants } from '../../constants'

const Talent = () => {
  return (
    <TalentMain
      title={'Talent'}
      description={'View all our talents'}
      tabTitleList={TalentConstants.Talent.tabTitleList}
      showHeaderButtons={true}
      isUserTalent={false}
    />
  )
}

export default Talent
