import { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import { useParams } from 'react-router-dom'
import { Stepper, Step, StepLabel } from '@mui/material'

import RolesDetail from './Components/RolesDetails'
import { Colors, Fonts } from '../../../../constants'
import ProjectOverview from './Components/ProjectOverview'
import { HeaderV2, Typography } from '../../../../components'

import useAddProjectMutation from '../../Hooks/useAddProjectMutation'
import useUpdateProjectMutation from '../../Hooks/useUpdateProjectMutation'

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-active': { color: Colors.black },
    '& .Mui-completed': { color: Colors.black },
    '& .Mui-disabled .MuiStepIcon-root': { color: '#9E9E9E' },
    '& .MuiStepLabel-labelContainer  span ': {
      fontFamily: Fonts.degular,
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0.1
    },
    '& .MuiStepLabel-active': {
      color: '#000'
    },
    marginTop: '10px',
    padding: '24px'
  }
}))

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const steps = ['Project Overview', 'Roles Details']

const AddNewProject = ({ resetProjectList, handleToggle, detail, refetchProjectOverviewData = () => null }) => {
  // mui classes
  const classes = useStyles()
  // hooks
  const { id } = useParams()
  const [AddProjectWithSelect, { loading: addProjectLoading }] = useAddProjectMutation()
  const [UpdateProjectWithSelect, { loading: updateProjectLoading }] = useUpdateProjectMutation()

  // states
  const [activeStep, setActiveStep] = useState(0)
  const [roleDates, setRoleDates] = useState({
    startDate: null,
    endDate: null
  })
  const [overviewData, setOverviewData] = useState()
  const [projectStatus, setProjectStatus] = useState('draft')

  // submit data for add project
  const finalSubmit = (data) => {
    overviewData.status = projectStatus
    AddProjectWithSelect({ project: overviewData, role: data.roles }, data.toggleModal)
  }
  // submit data for updated project
  const finalUpdatedSubmit = (data) => {
    if (overviewData) {
      overviewData.status = projectStatus
    }
    const projectGet = detail
    const projectData = {
      id: projectGet.id,
      ownerId: projectGet.ownerId,
      selectId: projectGet.selectId
    }
    UpdateProjectWithSelect({
      project: projectData,
      updatedProject: overviewData,
      projectStatus,
      newRoles: data.newRoles,
      updatedRoles: data.updatedRoles
    }, () => {
      refetchProjectOverviewData()
      data.toggleModal()
    })
  }

  return (
    <Wrapper>
      <HeaderV2
        stickey={false}
        title={
        <Typography
          fontSize={34}
          fontWeight='bold'
          lineHeight='125%'
          letterSpacing='0.25px'>
          {id ? 'Edit Project' : 'Add New Project'}
        </Typography>}
        description={
          <Typography fontSize={14} style={{ opacity: 0.5 }}>
            {id ? 'Edit details of Project' : 'Add details of New Project.'}
          </Typography>
        }
        bottom={
          <Stepper
            className={classes.root}
            activeStep={activeStep}
            alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step onClick={() => {
                  if (id || overviewData) {
                    setActiveStep(index)
                  }
                }} key={index}>
                  <StepLabel
                    active={(activeStep === index.toString())}>
                    {label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        }
      />
      {activeStep === 0 && <ProjectOverview
        overviewData={overviewData}
        handleToggle={handleToggle}
        handleActiveStep={setActiveStep}
        setOverviewData={setOverviewData}
        resetProjectList={resetProjectList}
        projectDetaildata={detail}
        projectDetailLoading={false}
        handleRoleDates={(dates) => setRoleDates(dates)}
       />}
      {activeStep === 1 && <RolesDetail
        roleDates={roleDates}
        finalSubmit={finalSubmit}
        handleToggle={handleToggle}
        projectStatus={projectStatus}
        resetProjectList={resetProjectList}
        setProjectStatus={setProjectStatus}
        finalUpdatedSubmit={finalUpdatedSubmit}
        dataLoading={(addProjectLoading || updateProjectLoading)}
      /> }
    </Wrapper>
  )
}

export default AddNewProject

AddNewProject.propTypes = {
  handleToggle: PropTypes.func,
  resetProjectList: PropTypes.func,
  detail: PropTypes.object,
  refetchProjectOverviewData: PropTypes.func
}
