import { useQuery } from '@apollo/client'
import ProjectAPI from '../../../services/Project/ProjectAPI'

function useListRoleInterestsQuery (props) {
  const { id, skip } = props
  const { data, loading, error, refetch } = useQuery(
    ProjectAPI.getListRoleInterests(),
    {
      variables: {
        roleId: id,
        offset: 0,
        limit: 100
      },
      skip,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  return { data, loading, error, refetch }
}

export default useListRoleInterestsQuery
