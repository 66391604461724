import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Colors, Fonts } from '../../../constants'

export const Wrapper = styled.section`
  background: ${Colors.primaryGray};
  fill: Solid ${Colors.primaryGray};
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  width: ${props => props.theme.menuWidth} !important;
  transition: all .5s ease;
  -moz-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
`
export const LogoImgDiv = styled.div`
  display: flex;
  padding: 0px 20px 0px 24px;
  position: static;
  width: ${props => props.theme.logoWidth};
  height: auto;
  top: 32px;
  margin: 32px 0px;
`
export const NavItemImg = styled.img`
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: calc(50% - 208px / 2);
  top: 0%;
  bottom: 81.25%;
  margin-right: ${props => props.theme.iconMarginRight};
  margin-left: ${props => props.theme.iconMarginLeft};
  transition: all 0.5s ease;
`

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 4px;
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.inactiveBlack};
  transition: all .3s ease;
  &:hover, &.active {
    color: ${Colors.black};
  }
`

export const SubStyledNavLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 4px;
  font-family: ${Fonts.degular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.inactiveBlack};
  transition: all .5s ease;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-left: calc(25px + ${props => props.theme.iconMarginLeft});
    padding: 0px 15px;
  }
  &.active {
    color: ${Colors.black};
    div {
      background: ${Colors.platinum};
    }
  }
`
export const MenuSpan = styled.span`
  transition: all 0.5s ease;
  text-align: ${props => props.theme.textAlign};
  opacity: ${props => props.theme.menuTextVisiblity};
  width: ${props => props.theme.menuTextVisiblity ? '100%' : 0};
  display: flex;
  justify-content: space-between;
`
export const ParentMenuSpan = styled.span`
  color: ${props => props.color};
  transition: all 0.5s ease;
  text-align: ${props => props.theme.textAlign};
  opacity: ${props => props.theme.menuTextVisiblity};
  width: ${props => props.theme.menuTextVisiblity ? '100%' : 0};
  display: flex;
  justify-content: space-between;
`

export const MenuArrow = styled.img`
  height: 24px;
  width: 24px;
  transition: all 0.2s ease-in;
  transform: rotate(${props => props.isSubMenuOpen ? '0deg' : '-90deg'});
`

export const SubMenuSpan = styled.span`
  transition: all 0.5s ease;
  margin-right: ${props => props.theme.iconMarginRight};
  text-align: ${props => props.theme.textAlign};
  opacity: ${props => props.theme.menuTextVisiblity};
  width: ${props => props.theme.menuTextVisiblity ? '100%' : 0};
`
