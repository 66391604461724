import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import Branch from 'branch-sdk'
import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.scss'
import { API } from './constants'
import App from './App'
import reportWebVitals from './reportWebVitals'

const { REACT_APP_NO_SENTRY, REACT_APP_ENV = '', REACT_APP_VERSION = '' } = process.env

if (REACT_APP_NO_SENTRY === undefined) {
  Sentry.init({
    release: 'tlynt-portal@' + REACT_APP_VERSION,
    environment: REACT_APP_ENV,
    dsn: 'https://07bec47a444142dc862665a3caee5c07@o4504005920555008.ingest.sentry.io/4504005922324480',
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.1 : 1.0
  })
} else {
  console.info('Sentry disabled')
}

Branch.init(API.branchKey, {}, (err) => {
  if (err !== null) {
    Sentry.captureException(err)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

reportWebVitals()
