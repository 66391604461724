import { useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

// import { ProjectConstants } from '../../../constants'
import SelectAPI from '../../../services/Select/SelectAPI'
import { showToast } from '../../../redux/actions/DashBoardActions'

function useAddSingleTalentToRoleMutation ({ selectId }) {
  // hooks
  const dispatch = useDispatch()
  // mutation
  const [AddToRoleMutation, { loading }] = useMutation(
    SelectAPI.updateSelect(),
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [fetchAllTalentAssociatedWithSelect] = useLazyQuery(SelectAPI.getSelectById(), {
    variables: {
      id: selectId
    },
    fetchPolicy: 'no-cache'
  })

  const addSingleTalent = async agrs => {
    const { selectId, talentId, handleUpdateSelection, toggleTalentCardLoading, talentIndex } = agrs
    try {
      const response = await fetchAllTalentAssociatedWithSelect()
      const listTalentsAssociatedWithSelect = response.data.getSelect.talents
      if (listTalentsAssociatedWithSelect.includes(talentId)) {
        // already present
      } else {
        // pushing talent id with previously added talent ids
        const modifiedListTalentsAssociatedWithSelect = listTalentsAssociatedWithSelect.concat(talentId)
        await AddToRoleMutation({
          variables: {
            id: selectId,
            talents: modifiedListTalentsAssociatedWithSelect
          }
        })
        toggleTalentCardLoading(talentIndex, true)
        setTimeout(() => {
          handleUpdateSelection()
        }, 500)
        dispatch(
          showToast({
            message: 'Updated successfully',
            isError: false
          })
        )
      }
    } catch (error) {
      dispatch(
        showToast({
          message: error?.message,
          isError: true
        })
      )
      toggleTalentCardLoading(talentIndex, false)
    }
  }

  return {
    addSingleTalent,
    loading
  }
}

export default useAddSingleTalentToRoleMutation
