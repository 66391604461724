import { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import GeneralInfo from './GeneralInfo'
import { Colors } from '../../../../constants'
import { RenderIf } from '../../../../utils/Helpers'
import { Button, Typography, TlyntSidebar } from '../../../../components'
import DeleteAdvertiser from '../../../Advertiser/Components/DeleteAdvertiser/DeleteAdvertiser'
import useDeleteAdvertiserMutation from '../../../Advertiser/Hooks/useDeleteAdvertiserMutation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  padding: 40px;
  justify-content: space-between;
  border-left: 1px solid ${Colors.primaryGray};
`

export const AdvertiserInfo = ({ data, loading }) => {
  // Hooks
  const navigate = useNavigate()
  // Local State
  const [isDeleteAdvertiserModal, setIsDeleteAdvertiserModal] = useState(false)
  // API call
  const { deleteAdvertiser, loading: deleteLoading } = useDeleteAdvertiserMutation()

  const handleDeleteAdvertiserSidebar = () => setIsDeleteAdvertiserModal(!isDeleteAdvertiserModal)

  const projectDeleteSuccess = () => {
    navigate('/advertiser')
    handleDeleteAdvertiserSidebar()
  }

  const onDeleteHandler = () => {
    if (data) {
      deleteAdvertiser(data.id, data.name, projectDeleteSuccess)
    }
  }

  return (
    <>
      <Wrapper>
        <GeneralInfo data={data} loading={loading} />
        <RenderIf isTrue={data}>
          <Button backgroundColor={Colors.pink} width='100%' onClick={() => handleDeleteAdvertiserSidebar(true)} outline>
            <Typography color={Colors.pink} fontSize={15} fontWeight='bold'>
              DELETE ADVERTISER
            </Typography>
          </Button>
          <RenderIf isTrue={isDeleteAdvertiserModal}>
            <TlyntSidebar direction='right' opacityLevel={1}>
              <DeleteAdvertiser
                handleDeleteAdvertiserSidebar={handleDeleteAdvertiserSidebar}
                advertiserData={data}
                onDeleteHandler={onDeleteHandler}
                deleteLoading={deleteLoading}
              />
            </TlyntSidebar>
          </RenderIf>
        </RenderIf>
      </Wrapper>
    </>
  )
}

AdvertiserInfo.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}
