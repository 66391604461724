import { useQuery } from '@apollo/client'
import TalentAPI from '../../../services/Talent/TalentAPI'

function useSearchTalentQuery (props) {
  const { offset, limit, hasPhoto, flagged, disabled, skip = false } = props

  const { data, loading, refetch, fetchMore } = useQuery(
    TalentAPI.getTalentList(),
    {
      variables: {
        offset,
        limit,
        hasPhoto,
        flagged,
        disabled
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
      skip
    }
  )

  return { data, loading, refetch, fetchMore }
}

export default useSearchTalentQuery
