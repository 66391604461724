import { Fragment, useEffect, useState } from 'react'

import { Field } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slider from '@mui/material/Slider'
import { makeStyles } from '@mui/styles'

import { Fonts } from '../../../constants'
import { capitalizeFirstLetter } from '../../../utils/Helpers'

const useStyles = makeStyles({
  root: {
    '&>.MuiSlider-rail': {
      background: '#9E9E9E'
    },
    '&>.MuiSlider-track': {
      background: '#9E9E9E'
    },
    '&>.MuiSlider-thumb': {
      background: '#000',
      height: 12,
      width: 12,
      '&:hover, &.Mui-focusVisible': {
        boxShadow: '0px 0px 0px 8px #D6D6D6'
      }
    },
    '&>.MuiSlider-mark': {
      background: '#000'
    },
    '&>.MuiSlider-markLabel': {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: Fonts.degular
    }
  }
})

const LabelText = styled.p`
  padding: 0;
  font-family: ${Fonts.degular};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`
const RightLabel = styled.p`
 padding: 0;
 font-family: ${Fonts.degular};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #E1306C;
  text-decoration: underline;
  cursor: pointer;
  display: ${props => props?.show ? '' : 'none'}
`

const SliderCtrl = props => {
  // mui classes
  const classes = useStyles()
  // props
  const { label, name, formalName, max, marks, index, values, loading } = props
  // states
  const [defaultValue, setDefaultValue] = useState([])
  const [reinitializeSlider, setReinitializeSlider] = useState(false)

  useEffect(() => {
    if (formalName === 'height') {
      setDefaultValue([convertValue(values[index][formalName][0]), convertValue(values[index][formalName][1])])
    } else {
      setDefaultValue(values[index][formalName])
    }
  }, [loading])

  // functions
  const getNewHeight = data => {
    const realFeet = data
    const feet = Math.floor(realFeet)
    const inches = Math.round((realFeet - feet) * 11)
    return `${feet}.${inches}`
  }

  const convertValue = (data) => {
    if (data) {
      const realValue = data.split('.')[0]
      const decimalValue = data.split('.')[1]

      if (decimalValue === '5') {
        return `${realValue}.48`
      } else if (decimalValue === '6') {
        return `${realValue}.58`
      } else if (decimalValue === '7') {
        return `${realValue}.68`
      } else if (decimalValue === '8') {
        return `${realValue}.76`
      } else if (decimalValue === '9') {
        return `${realValue}.88`
      } else if (decimalValue === '10') {
        return `${realValue}.93`
      } else if (decimalValue === '11') return `${realValue}.96`
      else return data
    }
  }

  const handleChange = (e, value, setFieldValue) => {
    if (formalName === 'height') {
      const leftValue = value[0]
      const rightValue = value[1]
      setFieldValue(name, [getNewHeight(leftValue), getNewHeight(rightValue)])
    } else {
      setFieldValue(name, value)
    }
  }

  const getModifiedLabel = (title, value) => {
    switch (title) {
      case 'age':
        return value === 100 ? '100+' : value
      case 'weight':
        return value === 450 ? '450+' : value
      case 'height':
        return value === '8.0' ? '8+' : value
      default:
        return value
    }
  }

  const getDefaultValue = () => {
    switch (formalName) {
      case 'age':
        return [0, 100]
      case 'weight':
        return [0, 450]
      case 'height':
        return [0, 8]
      default:
        return 'null'
    }
  }

  return (
    <div>
      <Field key={name} name={name}>
        {fieldProps => {
          const { form } = fieldProps
          const { setFieldValue } = form
          return (
            <Fragment key={reinitializeSlider} >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <LabelText>{label}</LabelText>
                <RightLabel
                  show={values[index]['is' + capitalizeFirstLetter(formalName) + 'Allowed']}
                  onClick={() => {
                    setDefaultValue(getDefaultValue())
                    setFieldValue(`roles.${index}.is${capitalizeFirstLetter(formalName)}Allowed`, false)
                    setFieldValue(`roles.${index}.${formalName}`, getDefaultValue())
                    setReinitializeSlider(!reinitializeSlider)
                  }}>Any</RightLabel>
              </div>
              <Slider
               size='small'
               defaultValue={defaultValue}
               key={defaultValue}
               step={formalName === 'height' ? 0.01 : 1}
               valueLabelDisplay="auto"
               min={formalName === 'height' ? 1 : 0}
               max={max}
               onChangeCommitted={() => {
                 setFieldValue(`roles.${index}.is${capitalizeFirstLetter(formalName)}Allowed`, true)
               }}
               className={classes.root}
               onChange={(e, value) => handleChange(e, value, setFieldValue)}
               marks={marks}
               scale={x => {
                 if (formalName === 'height') {
                   const realFeet = x
                   const feet = Math.floor(realFeet)
                   const inches = Math.round((realFeet - feet) * 11)
                   return `${feet}.${inches}`
                 } else return x
               }}
               valueLabelFormat={value => <div>{getModifiedLabel(formalName, value)}</div>}
              />
            </Fragment>
          )
        }}
      </Field>
    </div>
  )
}

SliderCtrl.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  formalName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.array,
  index: PropTypes.number,
  values: PropTypes.array,
  loading: PropTypes.bool
}

export default SliderCtrl
