import classNames from 'classnames'

import style from './UGCTooltip.module.scss'

interface TooltipProps {
  children?: JSX.Element
  popper: JSX.Element
  dataTestId?: string
}

export function UGCTooltip (props: TooltipProps): JSX.Element {
  const { children, popper, dataTestId = '' } = props

  return (
    <div className={classNames(style.Tooltip)} data-test-id={dataTestId}>
      <div className={classNames(style.Tooltip__Popper)}>
       {popper}
      </div>
      <div className={classNames(style.Tooltip__Container)}>
        {children}
      </div>
    </div>
  )
}
