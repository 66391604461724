import { Card, IconButton } from '@mui/material'
import styledComponents from 'styled-components'

import { Colors } from '../../../constants'
import { ShimmerContentBox } from '../../../components'

const Style = {
  // For card
  CardLayout: styledComponents.div`
    padding: 0 10px;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    padding-bottom: 110px;
  `,
  ModifiedCard: styledComponents(Card)`
    background: #FAFAFA !important;
    box-shadow: 7px 9px 10px -5px rgba(0, 0, 0, 0.08) !important;
    border-radius: 2px !important;
  `,
  CardWrapper: styledComponents.div`
    display: flex;
    padding: 10px;
  `,
  CardInfoWrapper: styledComponents.div`
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: auto;
  `,
  CardInfo: styledComponents.div`
    overflow: hidden;
    width: 80%;
    display: flex;
    flex-direction: column;
  `,
  IconButtonModified: styledComponents(IconButton)`
    height: fit-content;
    align-self: center;
  `,
  ErrorBlock: styledComponents.div`
    display: flex;
    width: 100% !important;
    align-items: center;
  `,

  // For footer
  FooterWrapper: styledComponents.div`
    margin-top: auto;
    border-top: 1px solid ${Colors.symbolGray};
    box-shadow: 10px 5px 54px ${Colors.symbolGray};
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    background: white;
  `,
  FlexWrapper: styledComponents.div`
    display: flex;
  `,
  ProgressBar: styledComponents.div`
    height: 20px;
    width: 20px;
    margin-right: 10px;
  `,

  // For Upload component
  UploadContainerChild: styledComponents.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  `,
  UploadBox: styledComponents.section`
    width: calc(100% - 80px);
    height: 100%;
    margin-inline: 40px;
    display: flex;
    padding-bottom: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: ${Colors.backgroundGray};
    // border: 1px dashed ${Colors.dashedBorderGray};
    box-sizing: border-box;
  `,
  ImgView: styledComponents.img`
    width: auto;
    height: 50px;
    margin: 35px auto 10px auto;
    display: flex;
  `,
  BrowseTextStyledDiv: styledComponents.div`
    display: flex;
    cursor: pointer;
  `,
  BrowseWrapper: styledComponents.div`
    display: flex;
    justify-content: center;
  `,
  // For Add tags
  AddTagWrapper: styledComponents.div`
    display: flex;
    flex-direction: column;
    font-style: normal;
    height: 100%;
  `,
  AddtagBottom: styledComponents.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 20px 40px;
    margin-top: 30px
  `,
  AddtagShimmer: styledComponents(ShimmerContentBox)`
    height: 30px;
    margin: 10px 40px;
    width: auto;
  `
}

export default Style
