import { useQuery } from '@apollo/client'
import SelectAPI from '../../../services/Select/SelectAPI'

function useFetchChildTalentQuery (id) {
  const { data, loading, error, refetch } = useQuery(SelectAPI.getSelectById(), {
    variables: {
      id
    },
    skip: !id,
    fetchPolicy: 'no-cache'
  })

  return { data, loading, error, refetch }
}

export default useFetchChildTalentQuery
