import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { API } from '../../constants/API'
import Layout from '../../components/Layout'
import { showToast } from '../../redux/actions/DashBoardActions'
import { useCreateShortLink } from '../../hooks/shortlink/'

import style from './payment.module.scss'

/**
 * Handler to blindly update React State when a text input is changed
 * @param e Original HTML Input Change Event
 * @param setter React SetStateAction to save the updated value
 */
const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, setter: (data: any) => void): void => {
  setter(e.target.value)
}

interface ShortURLHandlers {
  showError: () => void
  showSuccess: () => void
  createShortURL: (data: any, onSuccess: any) => void
  setLink: (data: string) => void
}

/**
 * Handler that create a FormData object and calls the uploadMedia service on form submit
 * @param e Original HTML Form Submit Event
 * @param url ReactState representing the url to shorten
 * @param slug ReactState representing the desired slug in the short url
 * @param overwrite ReactState representing whether to overwrite an existing short url
 * @param handlers Object containing functions to show error and success toasts, create the short url
 */
const handleSubmit = (e: React.FormEvent<HTMLFormElement>, talentID: string, handlers: ShortURLHandlers): void => {
  e.preventDefault()
  e.stopPropagation()

  const opts = {
    href: `${API.base as string}/v1/talent/${talentID}/payment/connect`
  }

  if (talentID === '') {
    throw Error('No Talent ID provided')
  }

  handlers.createShortURL(opts, (data: any) => {
    console.log('create short url', data)
    handlers.setLink(data)
    handlers.showSuccess()
  })
}

/**
 * Handler to copy the short url to clipboard
 */
const copyText = async (text: string, success: () => void): Promise<void> => {
  await navigator.clipboard.writeText(text).then(success)
}

export const ToolsPayment = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.UserReducer)

  if (user.isAdmin === false) {
    navigate('/404/')
    return <></>
  }

  const [createShortURL, { loading: shortLinkCreating }] = useCreateShortLink() as unknown as [any, { loading: boolean }]

  const [talentID, setTalentID] = useState<string>('')
  const [link, setLink] = useState<string>('')

  const showError = (): void => { dispatch(showToast({ isError: true, message: 'Something went wrong.' })) }
  const showSuccess = (): void => { dispatch(showToast({ message: 'Talent Payment Onboarding Link Created Successfully.' })) }
  const copySuccess = (): void => { dispatch(showToast({ message: 'URL Copied.' })) }

  return (
    <Layout>
      <form onSubmit={(e) => { handleSubmit(e, talentID, { showError, showSuccess, createShortURL, setLink }) }}>
        <fieldset>
          <input type='text' name='talentID' placeholder='Talent ID to onboard' onChange={(e) => { handleTextChange(e, setTalentID) }} value={talentID} required />
        </fieldset>

        <fieldset>
          <input type='submit' value='Create Link' />
        </fieldset>
      </form>

      { shortLinkCreating && <div className={style.Loading}>Uploading...</div> }

      { link !== '' && <div className={style.Link}>Onboarding Link: <span className={style.Copiable} onClick={() => { void copyText(link, copySuccess) }}>{link}</span></div> }
    </Layout>
  )
}
