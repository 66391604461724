import { useContext, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import type { Dispatch } from 'react'

import { DeliverablesContext } from '../../contexts/deliverables'
import { SendNotification, updateUGCProject } from '../../apis/ugc'
import { showToast } from '../../redux/actions/DashBoardActions'
import { UGCProjectDetailContext } from '../../contexts/projectDetail'
import { UGCSidebarContext } from '../../contexts/ugcSidebar'
import { URL } from '../../constants/URL'
import type { IProjectDetail, IProjectDetailActions } from '../../contexts/projectDetail'
import type { UserState } from '../../redux/reducers/user'

interface useSubmitDirectionsType {
  handleSubmitDirections: (close: boolean) => void
  isLoading: boolean
}

export const useSubmitDirections = (close: boolean): useSubmitDirectionsType => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const user: UserState = useSelector((state: any) => state.UserReducer)

  const firstName = String(user?.info?.name?.first)
  const lastName = String(user?.info?.name?.last)
  const userInfo = (firstName?.length > 0) || (lastName?.length > 0) ? `${firstName} ${lastName}` : user?.info?.email

  const dispatch = useDispatch()
  const [{ directions }] = useContext(DeliverablesContext)
  const [projectDetailData] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)
  const [sidebarData, updateSidebarData] = useContext(UGCSidebarContext)

  const { brandId, owner } = projectDetailData

  const brandDetails = useMemo(() => {
    return owner?.brands?.find((brand: { id: string }) => brand?.id === brandId)
  }, [owner?.brands, brandId])

  // constants
  const orgName = brandId?.length > 0 ? brandDetails?.name ?? '' : projectDetailData.owner?.name ?? ''
  const orgLogoUrl = brandId?.length > 0 ? brandDetails?.logo?.uris?.logo ?? '' : projectDetailData.owner?.logo?.uris?.logo ?? ''
  const sendNotificationPayload = {
    orgName,
    logoUrl: orgLogoUrl,
    projectId: projectDetailData.id,
    projectName: projectDetailData.name,
    userInfo,
    action: 'update'
  }

  // api calls
  const [updateUGCProjectMutation] = useMutation(updateUGCProject, { notifyOnNetworkStatusChange: true })
  const [sendNotificationMutation] = useMutation(SendNotification, { notifyOnNetworkStatusChange: true })

  const handleSubmitDirections = async (close: boolean): Promise<void> => {
    setIsLoading(true)
    try {
      const directionsPayload: any = {}
      for (const specs in directions) {
        directionsPayload[specs] = []
        const element = directions[specs]

        if (element.value !== 'Other') {
          directionsPayload[specs].push(element.value)
        }

        const additionalDirections = element.additionalNote.trim().split('\n')
        if (element.value !== 'No Preference' && additionalDirections.length > 0 && additionalDirections[0] !== '') {
          directionsPayload[specs].push(...additionalDirections)
        }
      }
      await updateUGCProjectMutation({ variables: { updateUgcProjectId: id, ...directionsPayload } })
      if (id !== undefined && close) {
        if (user?.isAdvertiser) {
          await sendNotificationMutation({
            variables: {
              ...sendNotificationPayload,
              projectUrl: `${URL}/projects/ugc/view/${id}/overview`
            }
          })
        }
      }
      setIsLoading(false)
      sidebarData[1].isCompleted = true
      if (close) {
        navigate('/projects')
      } else if (id != null) {
        navigate(`/projects/ugc/${id}/deliverables`)
      }
      updateSidebarData([...sidebarData])
    } catch (error) {
      setIsLoading(false)
      dispatch(showToast({
        isError: true,
        message: 'Something went wrong. Please try again.'
      }))
    }
  }

  return { handleSubmitDirections: (close: boolean) => { void handleSubmitDirections(close) }, isLoading }
}
