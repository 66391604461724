import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { showToast } from '../../../redux/actions/DashBoardActions'
import AdvertiserAPI from '../../../services/Advertiser/AdvertiserAPI'

const useBulkUserCreateMutation = () => {
  // hooks
  const dispatch = useDispatch()
  const [bulkUserCreate, { loading }] = useMutation(
    AdvertiserAPI.bulkUserCreate(),
    {
      notifyOnNetworkStatusChange: true
    }
  )

  const submitRole = async props => {
    const { data, toggleModal } = props
    const { members } = data

    try {
      await bulkUserCreate({
        variables: {
          users: members.map(member => ({
            firstname: member.firstname,
            lastname: member.lastname,
            email: member.email,
            ownerId: member.ownerId,
            roles: member.title,
            countryCode: '+1',
            phone: member.phone?.length !== 0 ? member.phone?.replace(/ /g, '') : undefined
          }))
        }
      })
      toggleModal()
      dispatch(
        showToast({
          message: 'Advertiser member added successfully',
          isError: false
        })
      )
    } catch (error) {
      dispatch(
        showToast({
          message: error?.message,
          isError: true
        })
      )
    }
  }

  return [submitRole, { loading }]
}

export default useBulkUserCreateMutation
