
import PropTypes from 'prop-types'
import { useFeature } from '@growthbook/growthbook-react'

import { Images } from '../../../../assets'
import { MenuSpan, NavItemImg, StyledNavLink } from '../../Styles'

const BulkUpload = props => {
  // props
  const { pathname } = props

  // hook
  const BulkUploadFeature = useFeature('bulk-upload')

  if (BulkUploadFeature.on) {
    return (
      <StyledNavLink to={'/bulk-upload'}>
        <NavItemImg
          src={
            pathname === '/bulk-upload'
              ? Images.FileUploadActive
              : Images.FileUploadInActive
          }
        />
        <MenuSpan>Bulk Upload</MenuSpan>
      </StyledNavLink>
    )
  }
  return null
}

BulkUpload.propTypes = {
  pathname: PropTypes.string
}

export default BulkUpload
