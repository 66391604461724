import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import moment from 'moment'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Menu, MenuItem, Skeleton } from '@mui/material'

import { GetUGCProject, updateUGCProject } from '../../../../apis/ugc'
import { RenderIf, capitalizeFirstLetter } from '../../../../utils/Helpers'
import { SectionHeader } from '../../../UGCSectionHeader'
import { UGCDetailContext } from '../../../../contexts/project/ugcDetail'
import { UGCPublishProject } from '../../../UGCPublishProject'
import style from './DetailOverview.module.scss'
import { UGCCancelProjectDrawer } from '../../../UGCCancelProjectDrawer'
import { UGCWrapProjectDrawer } from '../../../UGCWrapProjectDrawer/UGCWrapProjectDrawer'
import { getAssetsListProjectTalentStatus } from '../../../../apis/project'
import { showToast } from '../../../../redux/actions/DashBoardActions'
import { UGCOverviewDeliverables } from '../../../UGCOverviewDeliverables/UGCOverviewDeliverables'
import { ugcOverviewSidebarData } from '../../../../constants/UGCConstants'
import { ReferenceLinksList } from '../../../ReferenceLinksList'
import { UGCProjectStatus } from '../../../../utils/ProjectHelper'
import { AdvertiserLogo } from '../../../UGCViewProjectHeader'

interface IBrandInfo {
  logo?: string
  name: string
}

const ScrollToHashElement = ({ activeSidebarChip }: { activeSidebarChip: string }): null => {
  const hashElement = useMemo(() => {
    const hash = activeSidebarChip

    if (hash !== '') {
      const element = document.getElementById(hash)
      return element
    } else {
      return null
    }
  }, [activeSidebarChip])

  useEffect(() => {
    if (hashElement != null) {
      hashElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest'
      })
    }
  }, [hashElement])

  return null
}

interface TalentProfileProps {
  quantity: number
  ethnicities: string
  gender: string
  ageRanges: string
  name?: string
}

const TalentProfile = (props: TalentProfileProps): JSX.Element => {
  const { quantity, ethnicities, gender, ageRanges, name } = props
  return (
    <div>
      <p className={style.Overview__TalentProfileTitleText}>{name}</p>
      <SectionHeader headerText='Number fitting this profile' />
      <p className={style.Overview__DeliverableVideoCount}>{quantity}</p>

      {/* TODO: please remove __OldSectionHeaderDescriptionText from here and scss file when update talent section as per new UI , and don't use margin bottom for spacing between components, please use gap property on parent element */}
      <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__OldSectionHeaderDescriptionText]} headerText='Age ranges' descriptionText={ageRanges} />

      <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__OldSectionHeaderDescriptionText]} headerText='Gender' descriptionText={gender} />

      <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__OldSectionHeaderDescriptionText]} headerText='Ethnicities' descriptionText={ethnicities} />
    </div>
  )
}

const HeroShimmer = (): JSX.Element => {
  return (
    <>
      <div className={style.Overview__HeroTitleShimmer}>
        <Skeleton variant='rectangular' />
      </div>
      <div className={style.Overview__HeroHeadingShimmer}>
        <Skeleton variant='rectangular' />
      </div>
      <div className={style.Overview__HeroDescriptionShimmer}>
        <Skeleton variant='rectangular' />
      </div>
    </>
  )
}

export const renderBullets = (data: string[]): JSX.Element | string => {
  if (data?.length > 0) {
    return <ul>{data.map((item, index) => item !== '' ? <li key={index}>{item}</li> : null)}</ul>
  } else {
    return '-'
  }
}

/**
 * @description BrandInfo component
 * @param {IBrandInfo} props
 * @returns {JSX.Element}
 */
const BrandInfo = (props: IBrandInfo): JSX.Element => {
  const { logo, name } = props
  const initial = name?.charAt(0)

  // brand override
  if (logo !== null && logo !== undefined) {
    // if brand is not there then show advertiser logo
    return (
      <div className={style.Overview__BrandSection}>
        <AdvertiserLogo logo={logo} variant='Medium' initial={initial} /> <span className={style.Overview__BrandSectionBrandName}>{name}</span>
      </div>
    )
  } else {
    return (
      <div className={style.Overview__BrandSection}>
        <span className={style.Overview__BrandInitialsContainer}>{initial}</span>{name}
      </div>
    )
  }
}

export const DetailOverview = (): JSX.Element => {
  // init
  const { id } = useParams()
  const dispatch = useDispatch()
  const [{ overview }] = useContext(UGCDetailContext)
  const moreButtonRef = useRef<HTMLButtonElement>(null)
  const user = useSelector((state: any) => state.UserReducer)
  const { name, description, deliverables, brandId, endDate, hasProduct, loading, numberOfCreators, compensation, roles, productURL, hasTalentProfile, productDescription, productDirections, provisioningMethod, owner, status, wardrobeSpecs, lightingSpecs, framingSpecs, locationSpecs, referenceLinks } = overview

  // api calls
  const [updateUGCProjectMutation, { loading: updateUGCProjectLoading }] = useMutation(updateUGCProject, {
    notifyOnNetworkStatusChange: true, refetchQueries: [{ query: GetUGCProject, variables: { getProjectId: id, withBrand: true }, fetchPolicy: 'no-cache' }], awaitRefetchQueries: true
  })

  const { data: listProjectTalentStatusData } = useQuery(getAssetsListProjectTalentStatus,
    {
      variables: { projectId: id, statuses: ['wrapped', 'booking_requested', 'booking_confirmed'], limit: 100, offset: 0 },
      skip: id === undefined || id === null
    })

  const isAllTalentWrapped = useMemo(() => {
    if (listProjectTalentStatusData?.listProjectTalentStatus?.length > 0) {
      // removing deleted talent from list
      const talentStatusData = listProjectTalentStatusData?.listProjectTalentStatus?.filter((item: any) => item.talent !== null)

      const currentTime: Date = new Date()
      const twentyFourHoursAgo: Date = new Date(currentTime.getTime() - (24 * 60 * 60 * 1000))

      for (const talent of talentStatusData) {
        const isBookingRequested24HourElapsed = talent.status === 'booking_requested' && talent.updatedAt !== undefined && new Date(talent.updatedAt) >= twentyFourHoursAgo

        if (isBookingRequested24HourElapsed) {
          return false
        }
      }
      return talentStatusData.filter((item: any) => item?.status !== 'booking_requested').every((item: any) => item?.status === 'wrapped')
    }
  }, [listProjectTalentStatusData?.listProjectTalentStatus])

  const brandDetails = useMemo(() => {
    return owner?.brands?.find(brand => brand?.id === brandId)
  }, [owner?.brands, brandId])

  // audition
  const auditionData = deliverables?.filter((item) => item.type === 'audition')[0]?.specs?.specs
  const audition = auditionData != null && auditionData?.length > 0 ? auditionData.join('\n') : '-'

  // states
  const [activeSidebarChip, setActiveSidebarChip] = useState('project-detail')
  const [openCancelSidebar, setOpenCancelSidebar] = useState(false)
  const [isMoreOpen, setIsMoreOpen] = useState(false)

  const handleUpdateProjectStatus = async (cb: () => void, status: string): Promise<void> => {
    if (id != null) {
      await updateUGCProjectMutation({
        variables: { updateUgcProjectId: id, status },
        onCompleted: () => {
          cb()
          if (status === UGCProjectStatus.completed) {
            dispatch(showToast({ message: "You've successfully wrapped the project" }))
          }
        }
      })
    }
  }

  const displayProvisioningMethod = (provisioningMethod: string): string => {
    switch (provisioningMethod) {
      case 'not_required':
        return 'Not Required'
      case 'shipment':
        return 'Shipment'
      case 'reimbursement':
        return 'Reimbursement'

      default:
        return '-'
    }
  }

  if (loading) {
    return (
      <div className={style.Overview}>
        <div className={style.Overview__Sidebar}>
          <div className={style.Overview__SidebarShimmer}>{new Array(4).fill(0).map((item, index) => <Skeleton key={index} variant='rectangular' />)}</div>
        </div>
        <div className={style.Overview__Hero}>
          {new Array(3).fill(0).map((item, index) => <HeroShimmer key={index} />)}
        </div>
      </div>
    )
  }

  // update project config for publish, wrap drawer
  const updateProjectConfig = {
    loading: updateUGCProjectLoading,
    advertiser: owner?.name,
    projectName: name,
    primaryVideoCount: overview.deliverables?.filter((item) => item.type === 'main').length ?? 0,
    introVideoCount: overview.deliverables?.filter((item) => item.type === 'intro_hook').length ?? 0,
    stillPhotoCount: overview.deliverables?.filter((item) => item.type === 'photo').length ?? 0,
    bRoll: overview.deliverables?.filter((item) => item.type === 'b_roll').length ?? 0,
    audition: overview.deliverables?.filter((item) => item.type === 'audition').length > 0
  }

  return (
    <>
      <div className={style.Overview}>
        <ScrollToHashElement activeSidebarChip={activeSidebarChip} />
        <aside>
          <div className={style.Overview__Sidebar}>
            {ugcOverviewSidebarData.slice(0, hasProduct ? 5 : 4).map((item, index) => <div onClick={() => { setActiveSidebarChip(item.route) }} className={classNames(style.Overview__SidebarChip, activeSidebarChip === item.route ? style['Overview__SidebarChip--Active'] : '')} key={index} >{item.title}</div>)}
          </div>
        </aside>
        <div className={style.Overview__Hero}>

          {/* Project details */}
          <section id='project-detail' className={style.Overview__ProjectDetail}>
            <header className={style.Overview__Title}>Project Details</header>
            <div className={style.Overview__GrayBox}>

              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Project description' descriptionText={description ?? '-'} />

              <div>
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Brand' />
                <BrandInfo logo={brandDetails?.logo?.uris?.logo ?? owner?.logo?.uris?.logo} name={brandDetails?.name ?? owner?.name} />
              </div>

              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Campaign Due Date' descriptionText={moment(endDate).format('MMM DD, YYYY')} />
              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Physical Product' descriptionText={hasProduct ? 'Yes' : 'No'} />

              {/* Audition */}
              <RenderIf isTrue={user.isAdmin}>
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Audition' descriptionText={audition} />
              </RenderIf>

              {/* Reference Links */}
              <section className={style.Overview__ReferenceLinks} >
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Reference Links' descriptionText='References are links to content examples that give a talent a clear picture of your campaign goals.' />
                <div className={style.Overview__ReferenceLinkList}>
                  {referenceLinks !== null && referenceLinks.length > 0
                    ? <ReferenceLinksList referenceLinks={referenceLinks} />
                    : '-'}
                </div>
              </section>
            </div>
          </section>

          {/* Directions */}
          <section id='directions' className={style.Overview__Directions}>
            <header className={style.Overview__Title}>Directions</header>
            <div className={style.Overview__GrayBox}>
              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Wardrobe' descriptionText={wardrobeSpecs !== null && wardrobeSpecs?.length > 0 ? wardrobeSpecs.join('\n') : 'NA'} />
              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Location' descriptionText={locationSpecs !== null && locationSpecs?.length > 0 ? locationSpecs.join('\n') : 'NA'} />
              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Lighting' descriptionText={lightingSpecs !== null && lightingSpecs?.length > 0 ? lightingSpecs.join('\n') : 'NA'} />
              <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Specific Framing' descriptionText={framingSpecs !== null && framingSpecs?.length > 0 ? framingSpecs.join('\n') : 'NA'} />
            </div>
          </section>

          {/* Deliverables */}
          <UGCOverviewDeliverables />

          {/* Talent */}
          <section id='talent' className={style.Overview__Talent}>
            <p className={style.Overview__Title}>Talent</p>
            <SectionHeader headerText='Number of Talent' />
            <p className={style.Overview__DeliverableVideoCount}>{numberOfCreators}</p>
            <RenderIf isTrue={user.isAdmin}>
              <SectionHeader headerText='Compensation' />
              <p className={style.Overview__DeliverableVideoCount}> {compensation !== null ? `$${compensation}` : '-'}</p>
            </RenderIf>
            {hasTalentProfile && roles?.length > 0 && roles?.slice().sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((item, index) => <TalentProfile name={item?.name} key={index} quantity={item?.quantity} ageRanges={item?.criteria?.ageRanges?.length > 0 ? item?.criteria?.ageRanges?.slice().sort((a, b) => { return (a?.min + a?.max) - (b?.min + b?.max) })?.map((item) => `${item.min}-${item.max}`).join(', ') : 'Any'} gender={item?.criteria?.gender?.length > 0 ? item?.criteria?.gender.map((item) => capitalizeFirstLetter(item)).join(', ') : 'Any'} ethnicities={item?.criteria?.ethnicity?.length > 0 ? item?.criteria?.ethnicity?.join(', ') : 'Any'} />)}
          </section>

          {/* Product */}
          <RenderIf isTrue={hasProduct}>
            <section id='product' className={style.Overview__Product}>
              <p className={style.Overview__Title}>Product</p>
              <div className={style.Overview__GrayBox}>
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Product URL' descriptionText={productURL ?? '-'} />
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Product Description' isBullets descriptionText={Array.isArray(productDescription) ? renderBullets(productDescription) : '-'} />
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='How Will Talent Receive the Product?' descriptionText={provisioningMethod?.length > 0 && provisioningMethod !== null ? displayProvisioningMethod(provisioningMethod) : '-'} />
                <SectionHeader extHeaderTextClassNames={[style.Overview__SectionHeaderText]} extDescriptionTextClassNames={[style.Overview__SectionHeaderDescriptionText]} headerText='Specific Direction' isBullets descriptionText={Array.isArray(productDirections) ? renderBullets(productDirections) : '-'} />
              </div>
            </section>
          </RenderIf>
        </div>
      </div>

      <UGCCancelProjectDrawer
        open={openCancelSidebar}
        advertiser={overview.owner.name ?? ''}
        projectName={overview.name ?? ''}
        loading={updateUGCProjectLoading}
        handleCancelProject={() => { void handleUpdateProjectStatus(() => { setOpenCancelSidebar(false) }, UGCProjectStatus.cancelled) }}
        handleCloseDrawer={() => { setOpenCancelSidebar(false) }}
        primaryVideoCount={overview.deliverables?.filter((item) => item.type === 'main').length ?? 0}
        introVideoCount={overview.deliverables?.filter((item) => item.type === 'intro_hook').length ?? 0}
        stillPhotoCount={overview.deliverables?.filter((item) => item.type === 'photo').length ?? 0}
        bRoll={overview.deliverables?.filter((item) => item.type === 'b_roll').length ?? 0}
        audition={overview.deliverables?.filter((item) => item.type === 'audition').length > 0}
      />

      <footer className={classNames(style.Footer, status === UGCProjectStatus.completed ? style['Footer--Hidden'] : '')}>
        <button ref={moreButtonRef} className={classNames('outline-primary-button', user.isAdmin === true ? '' : style['Footer__MoreButton--Hidden'])} onClick={() => { setIsMoreOpen(!isMoreOpen) }} >
          <MoreHorizIcon />
          <p className={style.Footer__MoreButtonText}>More</p>
          <Menu id="basic-menu" anchorEl={moreButtonRef.current} open={isMoreOpen}>
            <MenuItem disabled={status !== UGCProjectStatus.draft} onClick={() => { setOpenCancelSidebar(true) }}>Cancel Project</MenuItem>
          </Menu>
        </button>
        <RenderIf isTrue={status !== UGCProjectStatus.cancelled && status !== UGCProjectStatus.completed}>
          <div className={style.Footer__Row}>
            <RenderIf isTrue={!(status === UGCProjectStatus.active && (Boolean(user.isAdvertiser)))}>
              <Link to={(id != null) ? `/projects/ugc/${id}/details` : '#'} className={'outline-primary-button'}>Edit</Link>
            </RenderIf>
            <RenderIf isTrue={user.isAdmin === true && status !== UGCProjectStatus.completed && status !== UGCProjectStatus.active}>
              <UGCPublishProject published={status === UGCProjectStatus.active} handlePublish={(cb: any) => { void handleUpdateProjectStatus(cb, UGCProjectStatus.active) }} {...updateProjectConfig} />
            </RenderIf>
            <RenderIf isTrue={status === UGCProjectStatus.active && isAllTalentWrapped}>
              <UGCWrapProjectDrawer published={status === UGCProjectStatus.completed} handlePublish={(cb: any) => { void handleUpdateProjectStatus(cb, UGCProjectStatus.completed) }} {...updateProjectConfig} />
            </RenderIf>
          </div>
        </RenderIf>
      </footer>
    </>
  )
}
