import { gql } from '@apollo/client'

class TagAPI {
  getTagList () {
    return gql`
      query TagList($offset: Int, $limit: Int, $search: String) {
        getTalentTag(offset: $offset, limit: $limit, search: $search) {
        id
        tag
        }
      }`
  }
}

export default new TagAPI()
