
import { CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Images } from '../../../../assets'
import { Colors, Fonts } from '../../../../constants'
import useDebounce from '../../../../hooks/Common/useDebounce'
import useUserSearchListQuery from '../../Hooks/useUserSearchListQuery'

const SearchBar = styled.input`
  flex-direction: row;
  border: 0px;
  width: 100%;
  height: 24px;
  outline: none;
  font-family: ${Fonts.degular};
  letter-spacing: 0.15px;
  font-size: 16px;
`

const SearchBarView = styled.div`
  border: 1px solid ${Colors.borderGray};
  width: ${props => (props.isLarge ? '100%' : '400px')};
  box-sizing: border-box;
  background: ${Colors.white};
  padding: 10px 0px;
  display: flex;
  align-items: center;
  height: 44px !important;
  align-items: center;
  padding-right: 5px;
  margin-right: ${props =>
    props.isLarge && !props.isUserTalent ? '35px' : '0'};
`

const SearchIcon = styled.img`
  padding-left: 8px;
  padding-right: 8px;
`

const SearchBox = ({ search, handleSearch }) => {
  // hooks
  const debouncedSearchTerm = useDebounce(search, 500)

  const { loading } = useUserSearchListQuery({
    order: 'asc',
    searchQuery: debouncedSearchTerm
  })

  return (
    <SearchBarView isLarge={false} isUserTalent={false}>
      <SearchIcon src={Images.searchIcon} alt={Images.searchIcon} />
      <SearchBar
        value={search}
        onChange={e => handleSearch(e.target.value)}
        placeholder={'Search for user'}
      />
      {
       loading && search
         ? <CircularProgress size={15} style={{ marginRight: 5 }} />
         : search && <img onClick={() => handleSearch('') } src={Images.cross} height={25} style={{
           cursor: 'pointer'
         }} />
      }
    </SearchBarView>
  )
}

SearchBox.propTypes = {
  search: PropTypes.string,
  handleSearch: PropTypes.func
}

export default SearchBox
