// TODO: Move styles to scss and convert to TS
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Skeleton, Typography, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import { Colors } from '../../constants'
import { Images } from '../../assets'
import { RenderIf } from '../../utils/Helpers'
import { showToast } from '../../redux/actions/DashBoardActions'
import { SideBar } from '../../components'
import { useShareProject } from '../../hooks'
import DetailWrapper from './Components/DetailWrapper'
import ShareIcon from '../../assets/images/Projects/ShareIcon.svg'
import style from './ProjectDetail.module.scss'
import useGetSelectIdQuery from './Hooks/useGetSelectIdQuery'
import useProjectDetailQuery from './Hooks/useProjectDetailQuery'

const TabItem = styled.div`
  cursor: pointer;
  padding: 10px 8px;
  transition: all 0.15s ease-in;
  border-bottom: 2px solid
    ${props => (props?.isActive ? Colors.black : 'transprent')};
`

const ProjectDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`
const ProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  @media (max-width: 900px) {
    flex-direction: column;
    justigy-content: start;
    align-items: flex-start;
  }
`
const ProjectText = styled.span`
  font-weight: bold;
  font-size: 32px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const SelectIcon = styled.img`
  height: 24px;
  margin-right: 5px;
  margin-left: 16px;
  @media (max-width: 900px) {
    margin-top: 10px;
    margin-left: 0px;
  }
`

const SelectText = styled.span`
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;

  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    margin-top: 10px;
    align-items: start;
  }
`
const LogoDiv = styled.div`
  margin-right: 20px;
  height: 64px;
  width: 64px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
const LogoImg = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const MainTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: calc(65vw - ${props => (props?.sidebarWidth)});
  
`

const SidebarWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  color: ${Colors.black};
  `
const projectMenu = [
  {
    id: 1,
    key: 'projectOverview',
    name: 'Project Overview',
    isFocused: true
  },
  {
    id: 2,
    key: 'roleDetails',
    name: 'Role Details',
    isFocused: false
  },
  {
    id: 3,
    key: 'projectTalent',
    name: 'Talent',
    isFocused: false
  }
]

const tikTokMenu = [
  {
    id: 1,
    key: 'projectOverview',
    name: 'Project Overview',
    isFocused: true
  },
  {
    id: 2,
    key: 'assets',
    name: 'Assets',
    isFocused: false
  },
  {
    id: 3,
    key: 'submittedVideos',
    name: 'Submitted Videos',
    isFocused: false
  },
  {
    id: 4,
    key: 'selectedVideos',
    name: 'Selected Videos',
    isFocused: false
  }
]

const OwnerLogo = (name, logo) => {
  if (logo) {
    return <LogoImg src={logo} />
  } else {
    return <Typography fontSize={64} fontWeight='bold' textTransform='upperCase'>{name.charAt(0)}</Typography>
  }
}

const ProjectDetail = () => {
  // hooks
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width: sidebarWidth } = useSelector(state => state.SidebarReducer)
  const { handleProjectShare } = useShareProject()

  // api calls
  const { data, loading, refetch } = useProjectDetailQuery(id)
  const { data: selectData, error: fetchSelectError } = useGetSelectIdQuery({ data, loading })

  // states
  const [isTikTokProject, setIsTikTokProject] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState('projectOverview')

  const MenuList = isTikTokProject ? tikTokMenu : projectMenu

  const updateActiveSelect = (key, tabIndex) => {
    setActiveTabKey(key)
    if (isTikTokProject) {
      const selectIndex = tikTokMenu.findIndex(item => item?.key === key)

      tikTokMenu.forEach((element, idx) => {
        if (idx === selectIndex) MenuList[selectIndex].isFocused = true
        else tikTokMenu[idx].isFocused = false
      })
    } else {
      const selectIndex = MenuList.findIndex(item => item?.key === key)

      MenuList.forEach((element, idx) => {
        if (idx === selectIndex) MenuList[selectIndex].isFocused = true
        else MenuList[idx].isFocused = false
      })
    }
  }

  useEffect(() => {
    MenuList.forEach((element, idx) => {
      if (idx === 0) MenuList[0].isFocused = true
      else MenuList[idx].isFocused = false
    })
  }, [MenuList])

  useEffect(() => {
    data?.projectGet?.type && setIsTikTokProject(data?.projectGet?.type === 'ugc')
  }, [data])

  if (fetchSelectError) {
    dispatch(showToast({
      message: 'Internal server Error.',
      isError: true
    }))
  }

  return (
    <div className={style.ProjectDetail}>
      <SidebarWrapper>
        <SideBar />
      </SidebarWrapper>
      <ProjectDetailContainer>
        <div className={style.ProjectDetail__Header} style={{ backgroundColor: `#${data?.projectGet.color}` }}>
          <BackWrapper onClick={() => navigate(-1)}>
            <img height='16px' width='16px' src={Images.backArrowBlack} />
            <Typography ml='10px'>Back</Typography>
          </BackWrapper>
          <Stack direction='row' mt='28px' mb='40px'>
            {data?.projectGet
              ? <>
                <LogoDiv>
                  {data?.projectGet.owner && OwnerLogo(data?.projectGet.owner.name, data?.projectGet.owner.logo?.uris?.logo)}

                </LogoDiv>
                <MainTitleWrapper sidebarWidth={sidebarWidth}>
                  <Typography mb='auto'>{data?.projectGet.owner?.name ?? '-'}</Typography>
                  <ProjectWrapper>
                    <ProjectText>{data?.projectGet.name}</ProjectText>
                    <RenderIf isTrue={!!data?.projectGet?.selectId && !!selectData?.getSelect}>
                      <StyledLink to={`/selects/${selectData?.getSelect?.archived ? 'archived' : 'active'}/${data.projectGet.selectId}/sub-select`} direction='row'
                        onClick={() => navigate()}>
                        <SelectIcon src={Images.selectsIconActive} />
                        <SelectText>{selectData?.getSelect?.name}</SelectText>
                      </StyledLink>
                    </RenderIf>
                  </ProjectWrapper>
                </MainTitleWrapper>
              </>
              : <Stack direction='row'>
                <Skeleton variant='rounded' width={64} height={64} sx={{ borderRadius: '10px', marginRight: '20px' }} />
                <Stack justifyContent='space-between'>
                  <Skeleton width={160} height={25} />
                  <Skeleton width={250} height={45} />
                </Stack>
              </Stack>}
            <RenderIf isTrue={!!data?.projectGet}>
              <Typography
                textTransform='capitalize'
                height='fit-content'
                padding='5px 8px'
                border='1px solid black'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                ml='auto'>
                {data?.projectGet.status?.replaceAll('_', ' ')}
              </Typography>
            </RenderIf>
          </Stack>
          <Stack mt='auto' height='50px' direction='row'>
            {!loading && data?.projectGet && MenuList.map((item, index) => (
              <TabItem
                onClick={() => updateActiveSelect(item?.key, index)}
                isActive={activeTabKey === item?.key}
                key={item.id}>
                <Typography
                  fontSize={16}
                  textTransform="capitalize"
                  color={activeTabKey === item?.key ? Colors.black : Colors.blackOpacity}>
                  {item?.name}
                </Typography>
              </TabItem>
            ))}
            <button className={classNames(loading ? style['ProjectDetail__HeaderShareButton--Hidden'] : style.ProjectDetail__HeaderShareButton)} onClick={() => { void handleProjectShare(id) }}><img src={ShareIcon} />Share</button>
          </Stack>
        </div>
        <DetailWrapper
          projectId={id}
          activeTab={activeTabKey}
          detail={{ ...data?.projectGet, isLoading: loading }}
          refetchProjectOverviewData={refetch}
          isTikTokProject={isTikTokProject}
        />
      </ProjectDetailContainer>
    </div>
  )
}

export default ProjectDetail
