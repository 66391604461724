import { useState } from 'react'
import classNames from 'classnames'

import { Drawer } from '../Drawer'
import { SpinnerSmall } from '../Progress'
import PhotoIcon from '../../assets/images/UGCProject/PhotoIcon.svg'
import Play from '../../assets/images/UGCProject/Play.svg'
import style from '../UGCPublishProject/UGCPublishProject.module.scss'

interface ProjectSpecificationRowProps {
  title: string
  value: string | number
  imgSource: string
}

const ProjectSpecificationRow = (props: ProjectSpecificationRowProps): JSX.Element => {
  const { title, value, imgSource } = props
  return (
    <div className={style.Drawer__SpecificationRow}>
      <img src={imgSource} />
      <p className={style.Drawer__SpecificationRowText}>{title}: {value}</p>
    </div>
  )
}
interface UGCWrapProjectProps {
  advertiser: string
  projectName: string
  handlePublish: (cb: () => void) => void
  loading: boolean
  published: boolean
  primaryVideoCount: number
  introVideoCount: number
  stillPhotoCount: number
  bRoll: number
  audition: boolean
}

export const UGCWrapProjectDrawer = (props: UGCWrapProjectProps): JSX.Element => {
  const { advertiser, projectName, handlePublish, loading, published, primaryVideoCount, introVideoCount, stillPhotoCount, bRoll, audition } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = (): void => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <button className={classNames('primary-button', published ? 'primary-button-Disabled' : '')} onClick={published ? undefined : toggleDrawer}><p className={style.Button__Text}>{published ? 'Wrapped' : 'Wrap Project'}</p></button>
      <Drawer open={isOpen} onClose={toggleDrawer}>
        <div className={style.Drawer}>
          <header className={style.Drawer__Header}>
            <h1 className={style.Drawer__HeaderText}>Ready to Wrap ?</h1>
            <p className={style.Drawer__HeaderDescription}>After this, all talent will be wrapped and won&apos;t be able to upload new assets and versions. You can continue to Download your final assets from the project page.</p>
          </header>
          <p className={style.Drawer__AdvertiserText}>{advertiser}</p>
          <p className={style.Drawer__ProjectNameText}>{projectName}</p>
          <section className={style.Drawer__ProjectSpecifications}>
            <ProjectSpecificationRow imgSource={Play} title="Primary Video" value={primaryVideoCount} />
            <ProjectSpecificationRow imgSource={Play} title="Intro Videos" value={introVideoCount} />
            <ProjectSpecificationRow imgSource={PhotoIcon} title="Still Photos" value={stillPhotoCount} />
            <ProjectSpecificationRow imgSource={Play} title="B-Roll" value={bRoll} />
            <ProjectSpecificationRow imgSource={Play} title="Audition" value={audition ? 'Yes' : 'No'} />
          </section>
        </div>
        <footer className={style.Footer}>
          <button onClick={toggleDrawer} className={'primary-text-button'}>Nevermind</button>
          <button onClick={(e) => { handlePublish(() => { toggleDrawer() }) }} className={'primary-button'}>{loading && <SpinnerSmall />}Yes, Wrap Project</button>
        </footer>
      </Drawer>
    </>
  )
}
