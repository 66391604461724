import { useMutation } from '@apollo/client'
import AdvertiserUserAPI from '../../../services/AdvertiserUser/AdvertiserUserAPI'

export default function userUpdateMutation () {
  const [updateUserMutation, { loading }] = useMutation(
    AdvertiserUserAPI.updateUser(),
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )
  return [updateUserMutation, { loading }]
}
