import { useQuery } from '@apollo/client'
import SelectAPI from '../../../services/Select/SelectAPI'

function useFetchChildTalentQuery (id) {
  const { data, loading, error, refetch } = useQuery(SelectAPI.getChildSelectList(), {
    variables: {
      parentId: id,
      limit: 1000
    },
    fetchPolicy: 'no-cache'
  })

  return { data, loading, error, refetch }
}

export default useFetchChildTalentQuery
