import { type Dispatch } from 'react'
import { useContext } from 'react'
import { SectionHeader } from '../UGCSectionHeader'
import style from './UGCDeliverablesDirectionsV2.module.scss'
import { InputBox } from '../InputBox'
import { RadioDefault as Radio } from '../Radio'
import { directionOptions } from '../../constants/UGCConstants'
import { DeliverablesContext, type IDeliverables, type IDeliverablesActions } from '../../contexts/deliverables'

type IDeliverablesError = Record<string, string | undefined>
type DirectionType = 'wardrobeSpecs' | 'lightingSpecs' | 'locationSpecs' | 'framingSpecs'

interface UGCDeliverablesDirectionsV2Props {
  errorsData: [IDeliverablesError, Dispatch<IDeliverablesError>]
}

export function UGCDeliverablesDirectionsV2 (props: UGCDeliverablesDirectionsV2Props): JSX.Element {
  const { errorsData } = props
  const [errors, setErrors] = errorsData

  const [{ directions }, updateDeliverableData] = useContext<[IDeliverables, Dispatch<IDeliverablesActions>]>(DeliverablesContext)

  const handleDirectionSelect = (newValue: string, directionType: DirectionType): void => {
    setErrors({ ...errors, [directionType]: undefined })
    updateDeliverableData({ directions: { ...directions, [directionType]: { ...directions[directionType], value: newValue } } })
  }

  const handleDirectionNoteChange = (newValue: string, directionType: DirectionType): void => {
    setErrors({ ...errors, [directionType]: undefined })
    updateDeliverableData({ directions: { ...directions, [directionType]: { ...directions[directionType], additionalNote: newValue } } })
  }

  return (
    <main className={style.Directions}>
      <p className={style.Directions__Title}>Directions</p>
      <div className={style.Directions__Inputs} >
        <section className={style.Directions__Direction} >
          <SectionHeader headerText='Wardrobe' descriptionText='Choose your preferred clothing style for the shots.' />
          <div className={style.Directions__RadioContainer} >
            {directionOptions.wardrobe.map((threeDirectionItems, index) => {
              return <div key={`wardrobe${index}`} className={style.Directions__ThreeRadioGroup} > {
                threeDirectionItems.map((singleDirectionItem) => {
                  return <Radio key={singleDirectionItem.id} name='wardrobe' value={directions.wardrobeSpecs.value} onChange={ (e) => { handleDirectionSelect(e.target.value, 'wardrobeSpecs') } } options={[singleDirectionItem]} />
                })}
              </div>
            })}
          </div>
          <InputBox isMultiline value={directions.wardrobeSpecs.additionalNote} disabled={directions.wardrobeSpecs.value === 'No Preference'} onChange={(e): void => { handleDirectionNoteChange(e.target.value, 'wardrobeSpecs') }} placeholder='Provide any optional wardrobe direction notes for talent to follow.' isError={directions.wardrobeSpecs.value === 'Other' && errors.wardrobeSpecs === 'ADDITIONAL_NOTE_REQUIRED'} errorText='This field is required when Other is selected.' acceptedLength={2048} withLengthSuffix />
        </section>

        <section className={style.Directions__Direction} >
          <SectionHeader headerText='Location' descriptionText='Choose your preferred setting for the shots.' />
          <div className={style.Directions__RadioContainer} >
            {directionOptions.location.map((threeDirectionItems, index) => {
              return <div key={`location${index}`} className={style.Directions__ThreeRadioGroup} > {
                threeDirectionItems.map((singleDirectionItem, index) => {
                  return <Radio key={singleDirectionItem.id} name='location' value={directions.locationSpecs.value} onChange={ (e) => { handleDirectionSelect(e.target.value, 'locationSpecs') } } options={[singleDirectionItem]} />
                })}
              </div>
            })}
          </div>
          <InputBox isMultiline value={directions.locationSpecs.additionalNote} disabled={directions.locationSpecs.value === 'No Preference'} onChange={(e): void => { handleDirectionNoteChange(e.target.value, 'locationSpecs') }} placeholder='Provide any optional location direction notes for talent to follow.' isError={directions.locationSpecs.value === 'Other' && errors.locationSpecs === 'ADDITIONAL_NOTE_REQUIRED'} errorText='This field is required when Other is selected.' acceptedLength={2048} withLengthSuffix />
        </section>

        <section className={style.Directions__Direction} >
          <SectionHeader headerText='Lighting' descriptionText='Choose your preferred lighting for the shots.' />
          <div className={style.Directions__RadioContainer} >
            {directionOptions.lighting.map((threeDirectionItems, index) => {
              return <div key={`lighting${index}`} className={style.Directions__ThreeRadioGroup} > {
                threeDirectionItems.map((singleDirectionItem, index) => {
                  return <Radio key={singleDirectionItem.id} name='lighting' value={directions.lightingSpecs.value} onChange={ (e) => { handleDirectionSelect(e.target.value, 'lightingSpecs') } } options={[singleDirectionItem]} />
                })}
              </div>
            })}
          </div>
          <InputBox isMultiline value={directions.lightingSpecs.additionalNote} disabled={directions.lightingSpecs.value === 'No Preference'} onChange={(e): void => { handleDirectionNoteChange(e.target.value, 'lightingSpecs') }} placeholder='Provide any optional lighting direction notes for talent to follow.' isError={directions.lightingSpecs.value === 'Other' && errors.lightingSpecs === 'ADDITIONAL_NOTE_REQUIRED'} errorText='This field is required when Other is selected.' acceptedLength={2048} withLengthSuffix />
        </section>

        <section className={style.Directions__Direction} >
          <SectionHeader headerText='Framing' descriptionText='Choose your preferred framing for the shots.' />
          <div className={style.Directions__RadioContainer} >
            {directionOptions.framing.map((threeDirectionItems, index) => {
              return <div key={`framing${index}`} className={style.Directions__ThreeRadioGroup} > {
                threeDirectionItems.map((singleDirectionItem, index) => {
                  return <Radio key={singleDirectionItem.id} name='framing' value={directions.framingSpecs.value} onChange={ (e) => { handleDirectionSelect(e.target.value, 'framingSpecs') } } options={[singleDirectionItem]} />
                })}
              </div>
            })}
          </div>
          <InputBox isMultiline value={directions.framingSpecs.additionalNote} disabled={directions.framingSpecs.value === 'No Preference'} onChange={(e): void => { handleDirectionNoteChange(e.target.value, 'framingSpecs') }} placeholder='Provide any optional framing direction notes for talent to follow.' isError={directions.framingSpecs.value === 'Other' && errors.framingSpecs === 'ADDITIONAL_NOTE_REQUIRED'} errorText='This field is required when Other is selected.' acceptedLength={2048} withLengthSuffix />
        </section>
      </div>
    </main>
  )
}
