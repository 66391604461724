import { type Dispatch, useContext, type ChangeEvent, useState } from 'react' // PREFER SPLITTING INTO 2 IMPORTS: 1 FOR TYPES LIKE import type { ... } from 'react' AND THE SECOND ONE FOR REGULAR IMPORTS

import * as z from 'zod'
import { Link, useNavigate } from 'react-router-dom'
import style from './CreateProject.module.scss'

import { Images } from '../../../../assets'
import { InputBox } from '../../../InputBox'
import Close from '../../../../assets/images/UGCProject/Close.svg'
import { type IProjectDetail, type IProjectDetailActions, UGCProjectDetailContext } from '../../../../contexts/projectDetail'
import { UGCSidebarContext, UGCSidebarInitialData } from '../../../../contexts/ugcSidebar'

const validationSchema = z.object({
  name: z.string().nonempty('Project name is required').max(256)
})
interface CreateProjectProps {
  userName: string
}

type IValidationError = Record<string, string | undefined>

export function CreateProject (props: CreateProjectProps): JSX.Element {
  const { userName } = props
  const navigate = useNavigate()

  const [{ name }, updateHandler] = useContext<[IProjectDetail, Dispatch<IProjectDetailActions>]>(UGCProjectDetailContext)
  const updateSidebarData = useContext(UGCSidebarContext)[1]

  const [errors, setErrors] = useState<IValidationError>({})

  const handleChangeProjectName = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    updateHandler({ [name]: value.trim() })

    const result = validationSchema.safeParse({ name: value })

    if (result.success) {
      setErrors({})
    }
  }

  const handleCreateProject = (): void => {
    const result = validationSchema.safeParse({ name })

    if (result.success) {
      setErrors({})
      updateSidebarData([...UGCSidebarInitialData.map((item) => ({ ...item, isCompleted: false, isError: false }))])
      navigate('/projects/ugc/details')
    } else {
      const errValues: IValidationError = {}
      result.error.issues.forEach((item) => {
        if (item.path[0] === 'name') {
          errValues[String(item.path[0])] = item.message
        }
      })
      setErrors({ ...errors, ...errValues })
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleCreateProject()
    }
  }

  return (
    <section className={style.UGCProject}>
      <div className={style.UGCProject__ExitButton}>
        <Link to={'/projects'}>
          <div className={style.UGCProject__ExitIcon}><img src={Close} /></div>
        </Link>
      </div>
      <div className={style.UGCProject__GreetingsWrapper}>
        <img src={Images.ugcGreetings} />
        <div className={style.UGCProject__GreetingsTextWrapper}>
          <h1 className={style.UGCProject__WelcomeText}>Welcome, {userName}</h1>
          <p className={style.UGCProject__DescriptionText}>Provide details about your UGC project on the following screens and find the perfect talent. Let&apos;s get started!</p>
          <InputBox errorText={errors?.name} isError={errors?.name !== undefined} name={'name'} onChange={handleChangeProjectName} onKeyPress={handleKeyPress} label='Project Name' acceptedLength={255} />
        </div>
      </div>
      <button onClick={handleCreateProject} className={style.UGCProject__Button}><span className={style.UGCProject__ButtonText}>Let&apos;s get started</span></button>
    </section>
  )
}
