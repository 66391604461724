import { useState } from 'react'

import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Images } from '../../../../assets'
import { Colors } from '../../../../constants'
import { Typography } from '../../../../components'

const ActionsWrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const DetailButton = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.disabled ? 'rgba(0,0,0, 0.38)' : 'rgba(255, 255, 255, 0.24)'};
  display: flex;
  backdrop-filter: blur(42px);
  justify-content: center;
  align-items: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`
const TalentDetailWrapper = styled.div`
  height: 56px;
  box-sizing: border-box;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;

`
const TalentDetail = styled.div`
  height: 100%;
  width: 77%;
  box-sizing: border-box;
  padding-left: 16px;
  display: flex;
  align-items: flex-end;
`
const MediaCountWrapper = styled.div`
  width: 23%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 8;
`
const MediaToggleBox = styled.div`
  box-sizing: border-box;
  height: 52px;
  width: 28px;
  background: ${Colors.backgroundGrayOpacity};
  backdrop-filter: blur(44px);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 0;
`
const ToggleArrow = styled.img`
  opacity: ${props => (!props.isAllowed ? 0.2 : 1)};
  height: 6px;
  cursor: ${props => (!props.isAllowed ? 'not-allowed' : 'pointer')};
`

const TalentImage = styled.img`
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: 2;
  object-fit: contain;
  display: ${props => props.isImageLoaded ? 'block' : 'none'};
`

const DummyFixedImage = styled.img`
  width: 100%;
  cursor: pointer;
  opacity: 0;
  object-fit: contain;
`

const TalentCard = props => {
  const { item, handelToggleSidebar } = props
  const isAddedtoRole = item?.isAddedtoRole
  // states
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isImageFailed, setIsImageFailed] = useState(false)
  const [thumbnailImageIndex, setThumbnailImageIndex] = useState(0)

  const handelIncrement = e => {
    setIsImageFailed(false)
    setThumbnailImageIndex(thumbnailImageIndex + 1)
  }
  const handelDecrement = e => {
    setIsImageFailed(false)
    setThumbnailImageIndex(thumbnailImageIndex - 1)
  }

  const addToRoleHandler = e => {
    e.stopPropagation()
    e.preventDefault()
    handelToggleSidebar(item.id)
  }

  return (
    <Box position="relative">
      <TalentImage
        key={isImageLoaded}
        isImageLoaded={isImageLoaded}
        onError={() => setIsImageFailed(true)}
        src={
          (item?.media?.length > 0)
            ? isImageFailed ? Images.tlyntPlaceholder : item?.media[thumbnailImageIndex]?.uris?.card
            : Images.tlyntPlaceholder
        }
        onLoad={() => setIsImageLoaded(true)}
      />
      <Box position='relative' height={'fit-content'}>
        <DummyFixedImage
         src={Images.tlyntPlaceholder}
      />
      <Link to={`/talent/${item.id}`} target='_blank'>
        <Box
          position="absolute"
          bottom={0}
          width="100%"
          zIndex={6}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          sx={{ cursor: 'pointer', background: 'linear-gradient( 180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.64) 95% )' }}
        >
          <TalentDetailWrapper>
            <TalentDetail>
              <Typography
                fontSize={24}
                fontWeight="bold"
                lineHeight="32px"
                color={Colors.white}>
                {item?.firstname} {item?.lastname?.charAt(0).toUpperCase()}{' '}
                {item?.lastname && '.'}
              </Typography>
            </TalentDetail>
            {item?.media?.length > 0 && (
              <MediaCountWrapper>
                <MediaToggleBox>
                  <Box
                  onClick={ (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (thumbnailImageIndex > 0) {
                      handelDecrement(e)
                    }
                  }}
                  width={'100%'} display='flex' flexGrow={1} justifyContent='center' alignItems='center'>
                  <ToggleArrow
                    isAllowed={thumbnailImageIndex > 0}
                    src={Images.chevronUp}
                  />
                  </Box>
                  <Box
                  onClick={ (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (thumbnailImageIndex < item?.media?.length - 1) {
                      handelIncrement()
                    }
                  }}
                  width={'100%'} display='flex' flexGrow={1} justifyContent='center' alignItems='center'>
                  <ToggleArrow
                    isAllowed={thumbnailImageIndex < item?.media?.length - 1}
                    src={Images.chevronDown}
                    />
                  </Box>
                </MediaToggleBox>
              </MediaCountWrapper>
            )}
          </TalentDetailWrapper>
          <ActionsWrapper>
            <DetailButton
              onClick={!isAddedtoRole ? addToRoleHandler : (e) => e.preventDefault()} disabled={isAddedtoRole}>
              <Typography
                fontSize={15}
                fontWeight="bold"
                letterSpacing="0.45px"
                color={isAddedtoRole ? Colors.dashedBorderGray : Colors.white}
              >
                {isAddedtoRole ? 'SELECTED' : 'ADD TO ROLE'}
              </Typography>
            </DetailButton>
          </ActionsWrapper>
        </Box>
      </Link>
    </Box>
    </Box>
  )
}

TalentCard.propTypes = {
  item: PropTypes.object,
  handelToggleSidebar: PropTypes.func,
  loading: PropTypes.bool
}

export default TalentCard
