import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from '../../../../constants'
import { Button, Typography } from '../../../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  height: 100%;
  z-index: 100;
`

const BottomWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
`

const ButtonTray = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DeleteProject = ({
  handleToggleDeleteProjectSidebar,
  onDeleteHandler,
  loading
}) => {
  return (
    <Wrapper>
      <Typography fontSize={34} fontWeight="bold">
        Are you sure you want to delete this Project?
      </Typography>
      <BottomWrapper>
        <ButtonTray>
          <Button
            height="40px"
            minWidth="96px"
            backgroundColor={Colors.white}
            onClick={handleToggleDeleteProjectSidebar}>
            <Typography fontSize={15} fontWeight={'700'}>
              CANCEL
            </Typography>
          </Button>
          <Button
            height="40px"
            minWidth="96px"
            onClick={onDeleteHandler}
            loading={loading}>
            <Typography fontSize={15} fontWeight={'700'} color={Colors.white}>
              DELETE
            </Typography>
          </Button>
        </ButtonTray>
      </BottomWrapper>
    </Wrapper>
  )
}

export default DeleteProject

DeleteProject.propTypes = {
  handleToggleDeleteProjectSidebar: PropTypes.func,
  loading: PropTypes.bool,
  onDeleteHandler: PropTypes.func
}
